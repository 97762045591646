import React, { useState } from 'react';

//antd
import { Popover, Button, Card, message } from 'antd';
import { CheckOutlined, SwapOutlined } from '@ant-design/icons';

export default function SwapLessonPopover({ lessons = [], selectedLessonId, onRefresh, onSwapLesson }) {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const handleSwapOrder = async (e, targetLessonId) => {
        e.stopPropagation();
        try {
            setLoading(true);
            //selectedLessonId is id we want to swap
            //targetLessonId is id of target lesson we want to swap to
            const response = await onSwapLesson(selectedLessonId, targetLessonId);
            if (response) {
                if (response.errorCode === 0) {
                    message.success(`Successfully swapped order.`);
                    setLoading(false);
                    onRefresh();
                    return;
                } else {
                    message.error(`Couldn't swap lesson order. ${response}`);
                    setLoading(false);
                    return;
                }
            } else {
                message.error(`Couldn't swap lesson order. Response is undefined.`);
                setLoading(false);
                return;
            }
        } catch (error) {
            message.error(`Couldn't swap lesson order. ${error.message}`);
            setLoading(false);
        }
    };

    return (
        <Popover
            trigger="click"
            title={<div style={{ fontWeight: 'bold' }}>Please choose a lesson you want to swap order</div>}
            placement="left"
            visible={visible}
            onVisibleChange={(visible) => setVisible(visible)}
            content={() => {
                let swapable = lessons.filter((lesson) => lesson.id !== selectedLessonId);
                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            overflowY: 'scroll',
                            minHeight: '200px',
                            maxHeight: '300px',
                        }}
                    >
                        {swapable.map((s) => (
                            <Card size="small" key={s.id}>
                                <div style={{ display: 'flex', alignContent: 'center' }}>
                                    <div style={{ width: '80%', fontWeight: 'bold' }}>{s.name}</div>
                                    <div style={{ width: '20%', textAlign: 'right' }}>
                                        <Button
                                            style={{ backgroundColor: '#65C12C' }}
                                            size="small"
                                            onClick={(e) => {
                                                handleSwapOrder(e, s.id);
                                                setVisible(false);
                                            }}
                                            loading={loading}
                                        >
                                            <CheckOutlined style={{ color: '#fff' }} />
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        ))}
                    </div>
                );
            }}
        >
            <Button
                type="primary"
                onClick={(e) => {
                    e.stopPropagation();
                    setVisible(true);
                }}
                icon={<SwapOutlined />}
            ></Button>
        </Popover>
    );
}
