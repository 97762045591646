import React, { useEffect, useMemo, useState, useCallback, useRef, useContext } from 'react';
import { useLocation, useHistory, Route, Switch } from 'react-router-dom';
import { Container, LodoshaButton } from '../../../components/UI/ui.style';

import AppContext from '../../../contexts/AppContext';

//ant design
import { Space, Button, Modal, message, Col, Row } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, ImportOutlined } from '@ant-design/icons';

//api
import { getChaptersByMenuID, deleteChapter, updateChapterOrder } from '../../../utils/api/E-Learning/conversation';

//constant && utils
import { ELEARNING_CONVERSATION, ELEARNING } from '../../../constants/routes';
import { menuToText } from '../../../utils/data';
import { getNewLastOrder, getELearningItems } from '../../../utils';

//components
import Breadcrumb from '../E-LearningComponents/Breadcrumb';
import LessonList from './LessonList';
import ContentList from './ContentList';
import ContentDetail from './ContentDetail';
import ChapterModal from './ChapterModal';
import SortableTable from '../E-LearningComponents/SortableTable';
import DragHandler from '../E-LearningComponents/DragHandler';
import ImportModal from './ImportModal';

export default function IndexPage() {
    const { pathname } = useLocation();
    const history = useHistory();

    const isSubscribed = useRef(true);
    const { handleUnauthorizedUser } = useContext(AppContext);

    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [importVisible, setImportVisible] = useState(false);
    const [chapters, setChapters] = useState([]);
    const [postData, setPostData] = useState([]);

    //main menu table will hide when lesson is selected
    const shouldMainMenuShow = pathname.split('/')[3] ? false : true;

    //extract menuid from url
    const menuid = pathname?.split('/')[2];

    const data = useMemo(
        () =>
            chapters &&
            chapters.map((chapter, index) => ({
                ...chapter,
                key: index,
                no: index + 1,
                name: chapter.name,
                menu: chapter.menu,
                menuName: menuToText[chapter.menu - 1],
            })),
        [chapters]
    );

    const columns = [
        { title: '#', dataIndex: 'no', align: 'center', width: '5%', render: () => <DragHandler /> },
        {
            title: 'TITLE',
            dataIndex: 'name',
        },
        { title: 'MENU', dataIndex: 'menuName', align: 'center', width: '13%' },
        {
            title: '',
            dataIndex: 'actions',
            align: 'center',
            width: '15%',
            render: (text, row, index) => (
                <Space size="small">
                    <Button type="primary" title="Edit" onClick={(e) => handleEdit(e, row)} icon={<EditOutlined />} />
                    <Button
                        type="danger"
                        title="Delete"
                        onClick={(e) => handleDelete(e, row.id)}
                        icon={<DeleteOutlined />}
                    />
                </Space>
            ),
        },
    ];

    const handleFetchChapter = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getChaptersByMenuID(menuid);

            if (response) {
                if (response.errorCode !== 0) {
                    setLoading(false);
                    message.error(`Could not get chapters , ${response.message}`);
                    return;
                }

                if (response?.data?.status === 401) {
                    message.error(`Unauthorized`);
                    handleUnauthorizedUser();
                    return;
                }

                setChapters(response && response.data);
                setLoading(false);
            } else {
                setLoading(false);
                message.error(`Could not get chapters. Response is undefined`);
                return;
            }
        } catch (error) {
            setLoading(false);
            message.error(`Could not get chapters.`);
            return;
        }
    }, [menuid, handleUnauthorizedUser]);

    const handleRow = (record, rowIndex) => {
        return {
            onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                //save chapters to localstorage
                try {
                    const eLearningItems = getELearningItems(chapters, 'name');
                    localStorage.setItem('e-learning-chapters', JSON.stringify(eLearningItems));
                    history.push(`${ELEARNING_CONVERSATION}/${record.id}`, record);
                } catch (error) {
                    message.error('Set to local storage failed');
                }
            }, // click row
        };
    };

    const handleEdit = (e, row) => {
        e.stopPropagation();
        setUpdateVisible(true);
        setPostData(row);
    };

    const handleDelete = (e, id) => {
        e.stopPropagation();
        Modal.confirm({
            title: 'Are you sure you want to delete this post?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await deleteChapter(id);
                    if (response && response.errorCode === 0) {
                        message.success(`Deleted successfully!`);
                        handleFetchChapter();
                    } else {
                        message.error(`Failed, ${response.data.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request to delete chapter failed!`);
                }
            },
            onCancel() {},
        });
    };

    const ImportConversation = useCallback(
        () =>
            importVisible && (
                <ImportModal
                    visible={importVisible}
                    setVisible={setImportVisible}
                    onRefresh={handleFetchChapter}
                    postData={postData}
                    setPostData={setPostData}
                />
            ),
        [handleFetchChapter, postData, importVisible]
    );

    const Upsert = useCallback(
        () =>
            visible && (
                <ChapterModal
                    title="Add New Conversation Chapter"
                    visible={visible}
                    setVisible={setVisible}
                    postData={postData}
                    setPostData={setPostData}
                    onRefresh={handleFetchChapter}
                    isCreate={true}
                />
            ),
        [visible, handleFetchChapter, postData]
    );

    const Update = useCallback(
        () =>
            updateVisible && (
                <ChapterModal
                    title="Update Conversation Chapter"
                    visible={updateVisible}
                    setVisible={setUpdateVisible}
                    postData={postData}
                    setPostData={setPostData}
                    onRefresh={handleFetchChapter}
                    isUpdate={true}
                />
            ),
        [updateVisible, handleFetchChapter, postData]
    );

    //auto order
    useEffect(() => {
        let lastOrder = getNewLastOrder(chapters);
        setPostData((prev) => ({ ...prev, order: lastOrder, menu: menuid }));
    }, [chapters, visible, menuid, importVisible]);

    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchChapter();
        }
        return () => {
            isSubscribed.current = false;
        };
    }, [handleFetchChapter]);
    return (
        <div>
            <Breadcrumb menuid={menuid} />
            {shouldMainMenuShow && (
                <Container>
                    <Row justify="space-between">
                        <Col>
                            <LodoshaButton
                                type="primary"
                                className="btn-margin-bottom"
                                onClick={() => setVisible(true)}
                            >
                                <PlusOutlined />
                                Add New
                            </LodoshaButton>
                        </Col>
                        <Col>
                            <LodoshaButton
                                type="ghost"
                                className="btn-margin-bottom"
                                onClick={() => setImportVisible(true)}
                            >
                                <ImportOutlined />
                                Import
                            </LodoshaButton>
                            <ImportConversation />
                        </Col>
                    </Row>

                    <SortableTable
                        columns={columns}
                        data={data}
                        loading={loading}
                        handleRow={handleRow}
                        setLoading={setLoading}
                        onUpdateOrder={updateChapterOrder}
                        scrollX={992}
                    />

                    <Upsert />
                    <Update />
                </Container>
            )}
            <Switch>
                <Route exact path={`${ELEARNING}/:menuid/:chapterid`} component={LessonList} />
                <Route exact path={`${ELEARNING}/:menuid/:chapterid/:lessonid`} component={ContentList} />
                <Route exact path={`${ELEARNING}/:menuid/:chapterid/:lessonid/:contentid`} component={ContentDetail} />
            </Switch>
        </div>
    );
}
