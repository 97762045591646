import React, { useContext } from 'react';
import moment from 'moment';

//components
import Search from '../../components/Search';
import AppContext from '../../contexts/AppContext';
import { callActionTypes } from '../../utils/data';

const CandidateCallSearch = ({
    keyword,
    callerId,
    createdAt,
    followUpDate,
    onSearch,
    onFilter,
    type = null,
    fromDate = '',
    toDate = '',
}) => {
    const { dataStaffs } = useContext(AppContext);

    const items = [
        { label: 'Search:', name: 'search', type: 'text', value: keyword, onSearch },
        {
            label: 'By Caller:',
            name: 'caller',
            type: 'select',
            options: dataStaffs,
            value: callerId,
            onSelect: (value) => onFilter('caller', value),
        },
        {
            label: 'By Type',
            name: 'type',
            type: 'select',
            options: callActionTypes,
            value: type,
            onSelect: (value) => onFilter('type', value),
        },
        {
            label: 'By Call Date:',
            name: 'created_at',
            type: 'date',
            value: createdAt ? moment(createdAt, 'YYYY-MM-DD') : null,
            onSelect: (value) => onFilter('created_at', value),
        },
        {
            label: 'By Follow Up Date:',
            name: 'follow_up_date',
            type: 'date',
            value: followUpDate ? moment(followUpDate, 'YYYY-MM-DD') : null,
            onSelect: (value) => onFilter('follow_up_date', value),
        },
        {
            label: 'From Date',
            name: 'from_date',
            type: 'date',
            value: fromDate ? moment(fromDate, 'YYYY-MM-DD') : null,
            onSelect: (value) => onFilter('from_date', value),
        },
        {
            label: 'To Date',
            name: 'to_date',
            type: 'date',
            value: toDate ? moment(toDate, 'YYYY-MM-DD') : null,
            onSelect: (value) => onFilter('to_date', value),
        },
    ];

    return <Search items={items} span={4} />;
};

export default CandidateCallSearch;
