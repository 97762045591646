import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import styled from '@emotion/styled';
import { message, Spin, Descriptions, Tag, Row, Col, Space } from 'antd';
import AppContext from '../../../contexts/AppContext';
import ModalStatus from '../../../components/ModalStatus';
import { LodoshaButton } from '../../../components/UI/ui.style';
import ModalUpsert from '../ModalUpsert';
import FileComponent from '../../../components/FileComponent/FileComponent';

import moment from 'moment';

import API, { baseURL } from '../../../utils/api';
import getTextDisplay, { dataType } from '../../../utils/data';
import { dataEmployeeStatus } from '../../../utils/data';
import { getAutoDescriptions } from '../../../utils/api/auto_description';
import { getAutoDescriptionTextDisplay } from '../../../utils/data';

const Image = styled.div`
    background-color: #fafafa;
    border: 1px solid #fafafa;
    border-radius: 4px;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Detail = ({employee, setEmployee, handleFetchEmployee, loading}) => {
    const params = useParams();

    const { dataProvinces } = useContext(AppContext);
    const [visibleUpsert, setVisibleUpsert] = useState(false);
    const [visibleStatus, setVisibleStatus] = useState(false);
    const [autoDescriptions, setAutoDescriptions] = useState({});

    const handleFetchSelect = useCallback(async () => {
        try {
            const response = await getAutoDescriptions();
            if (response) {
                if (response.errorCode === 0) {
                    setAutoDescriptions(response.data);
                    // console.log('index aurodescription', response.data);
                }
            }
        } catch (error) {
            message.error(error?.message);
        }
    }, []);

    useEffect(() => {
        window.localStorage.setItem('employeeId', params.id);
    }, [params, handleFetchEmployee]);

    useEffect(() => {
        handleFetchSelect();
    }, [employee]);

    const handleSaveStatus = async (status) => {
        const id = employee.id;
        await API().patch(`/employee/status/${id}`, { status });
        setEmployee((prevState) => ({ ...prevState, status }));
        setVisibleStatus(false);
    };

    const personalItems = [
        {
            label: 'Name',
            name: 'name',
            value: `${employee?.name ? employee?.name : '-'} (${employee?.japan ? employee?.japan?.name : ''})`,
            span: 2,
        },
        { label: 'Code', name: 'code', value: employee?.code },
        {
            label: 'Gender',
            name: 'gender',
            value: employee?.gender ? getTextDisplay(employee?.gender, dataType.GENDER) : '',
        },
        {
            label: 'Nationality',
            name: 'country',
            value: employee?.nationality ? getTextDisplay(employee?.nationality, dataType.COUNTRY) : '',
        },
        { label: 'Phone', name: 'phone', value: employee?.phone },
        {
            label: 'Birth Date',
            name: 'birth_date',
            value: employee?.birth_date ? moment(employee.birth_date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '',
        },
        {
            label: 'Birth Place',
            name: 'birth_place',
            value: employee?.birth_place
                ? dataProvinces.find((province) => province.id === employee.birth_place)?.name_en
                : '',
            span: 2,
        },
        {
            label: 'Japanese Level',
            name: 'japanese_level',
            value: employee?.japanses_level ? getTextDisplay(employee.japanses_level, dataType.JAPANESE_LEVEL) : '',
        },
        {
            label: 'JFT Basic',
            name: 'jft_basic_level',
            value: employee?.jft_basic_level ? getTextDisplay(employee.jft_basic_level, dataType.JFT_BASIC_LEVEL) : '',
        },
        {
            label: 'Address',
            name: 'address',
            value: `${employee?.address ? employee?.address : '-'} (${
                employee?.japan ? employee?.japan?.address : ''
            })`,
            span: 1,
        },
        {
            label: 'Facebook',
            name: 'facebook',
            value: (
                <a href={employee?.facebook ? employee?.facebook : '#'} target="_blank" rel="noopener noreferrer">
                    {employee?.facebook ? employee?.facebook : '#'}
                </a>
            ),
            span: 1,
        },
        { label: 'Other Phone', name: 'other_phone', value: employee?.other_phone },
        { label: 'Sibling Count', name: 'sibling_count', value: employee?.sibling_count },
        { label: 'Sibling Index', name: 'sibling_index', value: employee?.sibling_index },
        { label: 'Sibling Girl', name: 'sibling_girl_count', value: employee?.sibling_girl_count },
    ];

    const basicItems = [
        {
            label: 'Study Level',
            name: 'study_level',
            value: employee?.study_level ? getTextDisplay(employee.study_level, dataType.STUDY_LEVEL) : '',
        },
        { label: 'Blood', name: 'blood', value: employee?.blood ? getTextDisplay(employee.blood, dataType.BLOOD) : '' },
        {
            label: 'Use Hand',
            name: 'use_hand',
            value: employee?.use_hand ? getTextDisplay(employee.use_hand, dataType.USE_HAND) : '',
        },
        {
            label: 'Status',
            name: 'wedding_status',
            value: employee?.wedding_status ? getTextDisplay(employee.wedding_status, dataType.WEDDING_STATUS) : '',
        },
        { label: 'Height', name: 'height', value: employee?.height },
        { label: 'Weight', name: 'weight', value: employee?.weight },
        { label: 'Waist Size', name: 'waist_size', value: employee?.waist_size },
        { label: 'Shirt Size', name: 'shirt_size', value: employee?.shirt_size },
        {
            label: 'Characteristic',
            name: 'characteristic',
            value: `${employee?.characteristic ? employee?.characteristic : '-'} (${
                employee?.japan ? employee?.japan?.characteristic : ''
            })`,
            span: 3,
        },
    ];

    const generalItem = [
        {
            label: 'Result Math',
            name: 'result_math',
            value: employee?.result_math ? getTextDisplay(employee.result_math, dataType.RESULT_MATH) : '',
        },
        {
            label: 'Eye Problem',
            name: 'eye_problem',
            value: employee?.eye_problem ? getTextDisplay(employee.eye_problem, dataType.YES_NO) : '',
        },
        {
            label: 'Conversation in Japanese',
            name: 'conversation_japan',
            value: employee?.conversation_japan ? getTextDisplay(employee.conversation_japan, dataType.YES_NO) : '',
        },
        {
            label: 'Ever to Japan',
            name: 'ever_to_japan',
            value: employee?.ever_to_japan ? getTextDisplay(employee?.ever_to_japan, dataType.EVER_TO_JAPAN) : '',
        },
        {
            label: 'Health Situation',
            name: 'heal_situation',
            value: `${employee?.heal_situation ? employee?.heal_situation : '-'} (${
                employee?.japan ? employee?.japan?.heal_situation : ''
            })`,
            span: 3,
        },
        {
            label: 'Favorite',
            name: 'favorite',
            value: `${employee?.favorite ? employee?.favorite : '-'} (${
                employee?.japan ? employee?.japan?.favorite : ''
            })`,
            span: 4,
        },
        {
            label: 'Reason and Goal',
            name: 'reason_and_goal',
            value: `${employee?.reason_and_goal ? employee?.reason_and_goal : '-'} (${
                employee?.japan ? employee?.japan?.reason_and_goal : ''
            })`,
            span: 4,
        },
        {
            label: 'Back From Japan Goal',
            name: 'back_from_japan_goal',
            value: `${employee?.back_from_japan_goal ? employee?.back_from_japan_goal : '-'} (${
                employee?.japan ? employee?.japan?.back_from_japan_goal : ''
            })`,
            span: 3,
        },
        {
            label: 'Looking Jobs',
            name: 'looking_jobs',
            value: `${
                employee?.looking_jobs
                    ? getTextDisplay(employee?.looking_jobs, dataType.LOOKING_JOBS)
                    : ''
            }`,
        },
    ];

    const financialItems = [
        {
            label: 'Financial Status',
            name: 'financial_status',
            value: employee?.financial_status
                ? getTextDisplay(employee.financial_status, dataType.FINANCIAL_STATUS)
                : '',
        },
        {
            label: 'Collateral Status',
            name: 'collateral_status',
            value: employee?.collateral_status
                ? getTextDisplay(employee.collateral_status, dataType.COLLATERAL_STATUS)
                : '',
        },
        {
            label: 'Guaranteer Status',
            name: 'guaranteer_status',
            value: employee?.guaranteer_status
                ? getTextDisplay(employee.guaranteer_status, dataType.GUARANTEER_STATUS)
                : '',
        },
        {
            label: 'Paid For Interview (30$)',
            name: 'interview_payment',
            value: employee?.interview_payment
                ? getTextDisplay(employee.interview_payment, dataType.INTERVIEW_PAYMENT)
                : '',
        },
    ];

    const autoDescription = [
        {
            label: 'Characteristic',
            value: getAutoDescriptionTextDisplay(autoDescriptions, employee && employee["characteristic_option"]),
        },
        {
            label: 'Body problem',
            value: getAutoDescriptionTextDisplay(autoDescriptions, employee && employee['body_problem_option']),
        },
        {
            label: 'Favorite',
            value: getAutoDescriptionTextDisplay(autoDescriptions, employee && employee['favorite_option']),
        },
        {
            label: 'Health Situation',
            value: getAutoDescriptionTextDisplay(autoDescriptions, employee && employee['heal_situation_option']),
        },
        {
            label: 'Reason and Goal to go to Japan',
            value: getAutoDescriptionTextDisplay(autoDescriptions, employee && employee['reason_and_goal_option']),
        },
        {
            label: 'Back From Japan Goal',
            value: getAutoDescriptionTextDisplay(autoDescriptions, employee && employee['back_from_japan_goal_option']),
        },
        {
            label: 'Tattoo',
            value: getAutoDescriptionTextDisplay(autoDescriptions, employee && employee['tattoo_option']),
        },
        {
            label: 'Eyes broplem',
            value: getAutoDescriptionTextDisplay(autoDescriptions, employee && employee['eyes_problem_option']),
        },
    ];

    return (
        <Spin tip="Loading..." spinning={loading}>
            <Descriptions key="photo" title="Photo and Jobs" column={1}>
                <Descriptions.Item>
                    {employee?.photo ? (
                        <img src={baseURL + employee.photo} alt="" style={{ width: 'auto', height: 150 }} />
                    ) : (
                        <Image>No Photo</Image>
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="Interesting Jobs">
                    {employee?.jobWants?.map((jobWant) => (
                        <Tag key={jobWant.id}>{jobWant?.job.name}</Tag>
                    ))}
                </Descriptions.Item>
                <Descriptions.Item label="Type">
                    {getTextDisplay(employee && employee.type, dataType.CANDIDATE_RCORD_TYPE)}
                </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Personal Information" bordered style={{ marginTop: 24 }}>
                {personalItems.map((item) => (
                    <Descriptions.Item key={item.name} label={item.label}>
                        {item.value}
                    </Descriptions.Item>
                ))}
            </Descriptions>
            <Descriptions title="Basic Information" bordered style={{ marginTop: 24 }}>
                {basicItems.map((item) => (
                    <Descriptions.Item key={item?.name} label={item.label}>
                        {item.value}
                    </Descriptions.Item>
                ))}
            </Descriptions>
            <Descriptions title="General Information Language" bordered style={{ marginTop: 24 }}>
                {generalItem.map((item) => (
                    <Descriptions.Item key={item?.name} label={item.label}>
                        {item.value}
                    </Descriptions.Item>
                ))}
            </Descriptions>
            <Descriptions title="General Information Description" bordered style={{ marginTop: 24 }}>
                {autoDescription.map((item) => (
                    <Descriptions.Item key={item?.name} label={item.label}>
                        {item.value}
                    </Descriptions.Item>
                ))}
            </Descriptions>
            <Descriptions title="Financial Information Language" bordered style={{ marginTop: 24 }}>
                {financialItems.map((item) => (
                    <Descriptions.Item key={item?.name} label={item.label}>
                        {item.value}
                    </Descriptions.Item>
                ))}
            </Descriptions>
            <Row style={{ marginTop: 24 }}>
                <Col span={24}>
                    <FileComponent
                        title="Attachments"
                        id={params.id}
                        type="employee"
                        handleProfileRefresh={handleFetchEmployee}
                    />
                </Col>
            </Row>

            {/* end of section */}
            <Row
                style={{
                    position: 'sticky',
                    bottom: 0,
                    marginTop: 40,
                    padding: 20,
                    background: '#FAFAFA',
                }}
            >
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Space direction="horizontal" size="middle">
                        <LodoshaButton type="primary" onClick={() => setVisibleUpsert(true)}>
                            Update
                        </LodoshaButton>
                        {/* <LodoshaButton onClick={() => setVisibleStatus(true)}>
                            {getTextDisplay(employee?.status, dataType.EMPLOYEE_STATUS)}
                        </LodoshaButton> */}
                        {visibleUpsert && (
                            <ModalUpsert
                                visible={visibleUpsert}
                                setVisible={setVisibleUpsert}
                                employee={employee}
                                setEmployee={setEmployee}
                                autoDescriptions={autoDescriptions}
                                onRefresh={() => {
                                    handleFetchEmployee();
                                }}
                            />
                        )}
                        {visibleStatus && (
                            <ModalStatus
                                visible={visibleStatus}
                                setVisible={setVisibleStatus}
                                status={employee.status}
                                steps={dataEmployeeStatus}
                                onSave={handleSaveStatus}
                            />
                        )}
                    </Space>
                </Col>
            </Row>
        </Spin>
    );
};

export default Detail;
