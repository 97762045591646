export const SIGN_IN = '/login';
export const HOME = '/';

export const FAVORITES = '/favorites';
export const FAVORITES_DETAIL = '/favorites/detail';

export const CANDIDATES = '/candidates';
export const CANDIDATES_DETAIL = '/candidates/detail';
export const CANDIDATES_CALL_HISTORY = '/candidates/detail/call-history';
export const CANDIDATES_APP_HISTORY = '/candidates/detail/app-history';
export const CANDIDATES_MOBILE_APP_HISTORY = '/candidates/detail/mobile-app-history';
export const CANDIDATES_RATING = '/candidates/detail/rating';

export const CANDIDATE_CALL = '/candidate-call';

export const COMPANIES = '/companies';
export const COMPANIES_DETAIL = '/companies/detail';
export const COMPANY_CANDIDATES = '/companies/company-employees';

export const PARTNERS = '/partners';
export const PARTNERS_DETAIL = '/partners/detail';
export const PARTNERS_DETAIL_PRODUCT = '/partners/detail/product';
export const PARTNER_PRODUCTS = '/partners/partner-products';
export const PARTNER_CANDIDATES = '/partners/partner-employees';
export const PARTNER_FEES = '/partners/partner-fee';

export const HOSPITALS = '/hospitals';
export const HOSPITAL_DETAIL = '/hospitals/detail';
export const HOSPITAL_EMPLOYEES = '/hospitals/hospital-employees';
export const HOSPITAL_FEES = '/hospitals/hospital-fees';

export const POSTJOBS = '/post-jobs';
export const POSTJOBS_DETAIL = '/post-jobs/detail';
export const POSTJOB_CANDIDATES = '/post-job-employees';
export const POSTJOB_SUGGESTED_CANDIDATES = '/post-jobs/suggested-candidates';

export const JOBS = '/jobs';

export const CAREER = '/career';
export const CAREER_DETAIL = '/career/detail';

export const CHANGE_PASSWORD = '/change-password';

export const MANAGE_AUTO_DESCRIPTION = "/manage-auto-description";

export const MESSAGES_CANDIDATES = '/messages/candidates';
export const MESSAGES_GENERAL = '/messages/general';

export const ADVANCE_SEARCH = '/advance-search';

export const STAFFS = '/staffs';
export const STAFFS_DETAIL = '/staffs/detail';

export const MEMBERSHIPS = '/memberships';

export const USERS = '/users';

//E-Learning url
export const ELEARNING = '/e-learning';
export const ELEARNING_JAPANESE_CLASS = '/e-learning/1';
export const ELEARNING_14SKILLS = '/e-learning/2';
export const ELEARNING_CONVERSATION = '/e-learning/3';
export const ELEARNING_EXAM = '/e-learning/4';
export const ELEARNING_CULTURE = '/e-learning/5';
export const ELEARNING_PRE_INTERVIEW = '/e-learning/6';
export const ELEARNING_JLPT = '/e-learning/7';
export const ELEARNING_NEWS_MANAGEMENT_USERS = '/e-learning/news-management/users';

export const ELEARNING_NEWS_MANAGEMENT = '/e-learning/news-management';
export const ELEARNING_FURIGANA_GENERATOR = '/e-learning/furigana-generator';
export const ELEARNING_MARKDOWN_SAMPLE = '/e-learning/markdown-sample'


export const BLOGS = '/blogs';
