import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Table, Input, Row, Col } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import ModalCareer from './ModalCareer';
import { LodoshaButton } from '../../components/UI/ui.style';

function List({
    career,
    loading,
    data,
    handleNew,
    handleUpdate,
    handleDelete,
    handleSearch,
    handleRow,
    handlePageChange,
    keyWord,
    visibleUpsert,
    setVisibleUpsert,
    visibleUpdate,
    setVisibleUpdate,
    handleFetchData,
}) {
    const columns = [
        { title: 'TITLE', dataIndex: 'job_title', key: 'job-title', width: '54%' },
        { title: 'HIRING', dataIndex: 'hiring', key: 'hiring', width: '6%', align: 'center' },
        { title: 'POST DATE', dataIndex: 'post_date', key: 'post-date', width: '15%', align: 'center' },
        { title: 'CLOSING DATE', dataIndex: 'expiration_date', key: 'expiration-date', width: '15%', align: 'center' },
        {
            title: '',
            key: 'actions',
            width: '10%',
            align: 'center',
            render: (record) => (
                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button
                        title="Update"
                        type="primary"
                        icon={<EditOutlined />}
                        className="action-button-margin"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleUpdate(record);
                        }}
                    />
                    <Button
                        title="Delete"
                        type="danger"
                        icon={<DeleteOutlined />}
                        className="action-button-margin"
                        onClick={(e) => handleDelete(e, record)}
                    />
                </span>
            ),
        },
    ];

    const dataSource =
        data &&
        data.data.map((partner) => {
            return { ...partner, key: partner.id };
        });

    const onRow = (record, rowIndex) => {
        return {
            onClick: (event) => {
                event.preventDefault();
                event.stopPropagation();
                handleRow(record);
            }, // click row
        };
    };

    const Upsert = useCallback(
        () =>
            visibleUpsert && (
                <ModalCareer
                    isCreate
                    title="Create Career"
                    isVisible={visibleUpsert}
                    setVisible={setVisibleUpsert}
                    data={[]}
                    onRefresh={handleFetchData}
                />
            ),
        [visibleUpsert, handleFetchData, setVisibleUpsert]
    );

    const Update = useCallback(
        () =>
            visibleUpdate && (
                <ModalCareer
                    isUpdate
                    title="Update Career"
                    isVisible={visibleUpdate}
                    setVisible={setVisibleUpdate}
                    data={career}
                    onRefresh={handleFetchData}
                />
            ),
        [visibleUpdate, handleFetchData, setVisibleUpdate, career]
    );

    return (
        <div className="list-container-career">
            <Row type="flex" justify="start" style={{ marginBottom: '40px' }}>
                <Col md={8}>
                    <Row style={{ alignItems: 'center' }} gutter={16}>
                        <Col>Search:</Col>
                        <Col>
                            <Input.Search
                                size="small"
                                placeholder="Keyword"
                                onChange={(value) => handleSearch(value)}
                                value={keyWord}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Upsert />
                <Update />
                <LodoshaButton type="primary" style={{ height: '40px' }} icon={<PlusOutlined />} onClick={handleNew}>
                    New
                </LodoshaButton>
            </div>
            <Table
                rowClassName={(_, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
                bordered
                loading={loading}
                dataSource={dataSource}
                columns={columns}
                onRow={onRow}
                pagination={{
                    defaultCurrent: 1,
                    defaultPageSize: 15,
                    total: data && data.total,
                    onChange: handlePageChange,
                }}
            />
        </div>
    );
}

export default withRouter(List);
