import React, { useState } from 'react';
import { Col, message, Modal, Row, Form, Space } from 'antd';

import { LodoshaButton, LodoshaInput } from '../../components/UI/ui.style';

//utils
import { createAutoDescription, updateAutoDescription } from '../../utils/api/auto_description';

export default function ModalUpsert({
    isCreate,
    visible,
    setVisible,
    autoDescription,
    setAutoDescription,
    onRefresh,
    order,
    type,
    setAutoDescriptions,
}) {
    const [form] = Form.useForm();
    const [data, setData] = useState({ ...autoDescription, order });
    const [loading, setLoading] = useState(false);
    const formItems = [
        {
            label: 'Name EN',
            name: 'name',
            value: data.name,
            required: true,
            span: 24,
        },
        {
            label: 'Name KH',
            name: 'name_kh',
            value: data.name_kh,
            required: true,
            span: 24,
        },
        {
            label: 'Name JP',
            name: 'name_jp',
            value: data.name_jp,
            required: true,
            span: 24,
        },
    ];

    const handleCancel = () => {
        setVisible(false);
        setAutoDescription({});
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            if (isCreate) {
                data['type'] = type;
                data['order'] = order;
            }
            const response = isCreate ? await createAutoDescription(data) : await updateAutoDescription(data);
            if (response) {
                if (response.errorCode === 0) {
                    message.success(`Successfully ${isCreate ? 'Created' : 'Updated'}`);
                    if (isCreate) {
                        setAutoDescriptions((prev) => [...prev, response.data]);
                    } else {
                        setAutoDescriptions((prev) => {
                            const updateItemIndex = prev.findIndex((item) => item.id === response.data.id);
                            prev[updateItemIndex] = response.data;
                            return [...prev];
                        });
                    }
                    setVisible(false);
                } else {
                    message.error(`Something went wrong: ${response?.message}`);
                }
            } else {
                message.error('Response is undefined');
            }
            setLoading(false);
        } catch (error) {
            message.error(`Something went wrong: ${error?.message}`);
            setLoading(false);
        }
    };
    return (
        <Modal
            visible={visible}
            keyboard={false}
            maskClosable={false}
            onCancel={handleCancel}
            title={isCreate ? 'Create' : 'Update'}
            footer={null}
        >
            <Form form={form} onFinish={handleSubmit} layout="vertical">
                <Row gutter={[16, 16]}>
                    {formItems.map((item, index) => (
                        <Col span={item.span} key={index}>
                            <Form.Item
                                label={item.label}
                                rules={[{ message: 'Data Required!', required: item.required }]}
                                initialValue={item.value}
                                name={item.name}
                            >
                                <LodoshaInput onChange={handleChange} name={item.name} />
                            </Form.Item>
                        </Col>
                    ))}
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Space direction="horizontal" size="middle" align="center">
                            <LodoshaButton type="ghost" onClick={handleCancel}>
                                Cancel
                            </LodoshaButton>
                            <LodoshaButton type="primary" htmlType="submit" loading={loading}>
                                Save
                            </LodoshaButton>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
