import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Space, Table, Button, message, Row, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Container, LodoshaButton } from '../../components/UI/ui.style';
import { PlusOutlined } from '@ant-design/icons';
import { getBlogs, deleteBlog, visibleBlog } from '../../utils/api/blog';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import ModalUpsert from './ModalUpsert';
import queryString from 'query-string';

import * as ROUTES from '../../constants/routes';
import moment from 'moment';

const Blogs = () => {
    const location = useLocation();
    const history = useHistory();

    const page = location.search
        ? queryString.parse(location.search).page
            ? Number(queryString.parse(location.search).page)
            : 1
        : 1;

    const [loading, setLoading] = useState(false);
    const [blogs, setBlogs] = useState([]);
    const [total, setTotal] = useState(0);
    const [visibleUpsert, setVisibleUpsert] = useState(false);

    const [blog, setBlog] = useState();

    const handleFetchBlogs = useCallback(async (page = 1) => {
        setLoading(true);
        try {
            const response = await getBlogs(page);
            console.log(response.data.total);
            setBlogs(response.data);
            setTotal(response.total);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error(`Uh oh, request failed!`);
        }
    }, []);

    useEffect(() => {
        handleFetchBlogs(page);
    }, [handleFetchBlogs, page]);

    const handleUpdate = (record) => {
        setBlog(record);
        setVisibleUpsert(true);
    };

    const handleHideBlog = async (e, record) => {
        e.stopPropagation();
        setLoading(true);

        const visible = !record.is_visible;

        const newArr = blogs.map((b) => {
            if (b.id === record.id) return { ...b, is_visible: visible };
            return b;
        });

        setBlogs(newArr);

        try {
            const response = await visibleBlog(record.id, visible);

            if (response && response.errorCode === 0) {
                message.success((visible ? 'Visible' : 'Hide') + ' Blog Successful.');
                setLoading(false);
            } else {
                message.error('Update not success.');
                setLoading(false);
            }
        } catch (error) {
            message.error(`Uh oh, request failed!`);
        }
    };

    const handleDelete = (e, item) => {
        e.stopPropagation();
        Modal.confirm({
            title: `Are you sure delete start ${item.title}?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await deleteBlog(item.id);

                    if (response && response.errorCode === 0) {
                        setBlogs((prevState) => prevState.filter((state) => state.id !== item.id));
                        message.success(`Deleted successfully!`);
                    } else {
                        message.error(`Failed! ${response.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request failed!`);
                }
            },
            onCancel() {},
        });
    };

    const handleRow = (record, rowIndex) => {
        return {
            onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                // history.push(`${ROUTES.STAFFS_DETAIL}/${record.id}`);
            }, // click row
        };
    };

    const handleChangePagination = (page) => {
        let endPoint = `${ROUTES.BLOGS}?page=${page}`;
        history.replace(endPoint);
    };

    const dataSource = useMemo(
        () =>
            blogs && blogs.length
                ? blogs.map((blog, index) => {
                      return { ...blog, key: blog.id, no: index + 1 + (page - 1) * 15 };
                  })
                : [],
        [blogs, page]
    );

    const columns = [
        { title: '#', dataIndex: 'no', key: 'no', width: 50 },
        { title: 'TITLE', dataIndex: 'title', key: 'title', width: '60%' },
        {
            title: 'CREATED DATE',
            dataIndex: 'created_at',
            key: 'created_at',
            align: 'center',
            render: (item, row, index) => <span>{moment(item).format('DD-MM-YYYY, h:mm:ss a')}</span>,
        },
        {
            title: '',
            key: 'actions',
            render: (record) => (
                <Space direction="horizontal" size="small">
                    <Button
                        type="primary"
                        icon={record?.is_visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        onClick={(e) => handleHideBlog(e, record)}
                    />
                    <Button
                        type="primary"
                        icon={<EditOutlined />}
                        className="action-button-margin"
                        onClick={() => handleUpdate(record)}
                    />
                    <Button
                        type="danger"
                        icon={<DeleteOutlined />}
                        className="action-button-margin"
                        onClick={(e) => handleDelete(e, record)}
                    />
                </Space>
            ),
        },
    ];

    const Upsert = React.useCallback(
        () =>
            visibleUpsert && (
                <ModalUpsert
                    visible={visibleUpsert}
                    setVisible={setVisibleUpsert}
                    blog={blog}
                    setBlog={setBlog}
                    page={page}
                    onRefresh={handleFetchBlogs}
                />
            ),
        [visibleUpsert, blog, handleFetchBlogs, page]
    );

    return (
        <Container>
            <Row style={{ marginTop: 24, marginBottom: 32 }}>
                <LodoshaButton type="primary" icon={<PlusOutlined />} onClick={() => setVisibleUpsert(true)}>
                    New
                </LodoshaButton>
                <Upsert />
            </Row>
            <Table
                bordered
                loading={loading}
                dataSource={dataSource}
                columns={columns}
                onRow={handleRow}
                pagination={{
                    current: page,
                    defaultCurrent: 1,
                    defaultPageSize: 15,
                    total: total,
                    onChange: handleChangePagination,
                }}
            />
        </Container>
    );
};

export default Blogs;
