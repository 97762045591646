import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Row, Col, List, Space, Empty, Spin, message } from 'antd';
import moment from 'moment';
import getTextDisplay, { dataType } from '../utils/data';
import { DateFollowUp, ListItem, DateTime } from '../containers/Employees/Employee/call-history.style';
import { getCallHistory } from '../utils/api/candidate';
import { LodoshaButton } from './UI/ui.style';

const dateFormat = 'DD-MM-YYYY h:mm A';

export default function ModalCallHistory({ visible, setVisible, employee = {} }) {
    const [loading, setLoading] = useState(false);
    const [callHistories, setCallHistories] = useState([]);

    const handleFetchCallHistories = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getCallHistory(1, employee?.id);
            if (response && response.errorCode !== 0) {
                message.error(`Couldn't get lastest call histories,${response.message}.Code: ${response.errorCode}`);
                setLoading(false);
            }
            setCallHistories(response ? response.data : []);
            setLoading(false);
        } catch (error) {
            console.log(error);
            message.error(`handleFetchCallHistories : ${error.message}`);
            setLoading(false);
        }
    }, [employee?.id]);

    const handleOnCancel = () => {
        setVisible(false);
    };
    useEffect(() => {
        let isSubscribed = true;
        isSubscribed && handleFetchCallHistories();
        return () => (isSubscribed = false);
    }, [handleFetchCallHistories]);

    return (
        <Modal
            visible={visible}
            maskClosable={false}
            destroyOnClose={true}
            onCancel={handleOnCancel}
            title="Lastest Call History"
            footer={[
                <LodoshaButton key="back" onClick={() => setVisible(false)}>
                    Done
                </LodoshaButton>,
            ]}
        >
            <Spin spinning={loading}>
                <Row>
                    <Col span={24}>
                        {callHistories?.length ? (
                            <List
                                itemLayout="horizontal"
                                split={false}
                                pagination={false}
                                dataSource={callHistories}
                                renderItem={(item) => (
                                    <ListItem>
                                        <List.Item.Meta
                                            title={
                                                <Space align="baseline" direction="horizontal" size="middle">
                                                    <h3>{item.caller_name}</h3>
                                                    {item?.created_at ? (
                                                        <DateTime>
                                                            {moment(item.created_at).format(dateFormat)}
                                                        </DateTime>
                                                    ) : null}
                                                    <i>{getTextDisplay(item?.type, dataType.CALL_TYPE)}</i>

                                                    {item?.follow_up_date ? (
                                                        <span>
                                                            Follow Up:{' '}
                                                            <DateFollowUp>
                                                                {moment(item.follow_up_date).format('DD-MM-YYYY')}
                                                            </DateFollowUp>
                                                        </span>
                                                    ) : null}
                                                </Space>
                                            }
                                            description={item.summary}
                                        />
                                    </ListItem>
                                )}
                            />
                        ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
}
