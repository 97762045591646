import { createAPIELearning } from '../../api';

export const importConversation = (data, file) => {
    const formData = new FormData();

    for (const key in data) {
        formData.append(key, data[key]);
    }

    formData.append('csv-file', file);

    return createAPIELearning()
        .post('/admin-chapter/import-conversation', formData)
        .then((response) => response.data)
        .catch((err) => console.log(`importConversation: `, err));
};

//chapter
export const getChaptersByMenuID = (menuID) => {
    return createAPIELearning()
        .get(`/admin-chapter/${menuID}`)
        .then((response) => response.data)
        .catch((error) => console.log('getChaptersByMenuID', error));
};

export const postNewChapter = (menuid, formData) => {
    const config = { 'content-type': 'multipart/form-data' };
    return createAPIELearning()
        .post(`/admin-chapter/create/${menuid}`, formData, config)
        .then((response) => response.data)
        .catch((err) => console.log('addNewChapter', err));
};

export const updateChapter = (id, formData) => {
    const config = { 'content-type': 'multipart/form-data' };
    return createAPIELearning()
        .patch(`/admin-chapter/${id}`, formData, config)
        .then((response) => response.data)
        .catch((err) => console.log('updateJapaneseChapter', err));
};

export const deleteChapter = (id) => {
    return createAPIELearning()
        .delete(`/admin-chapter/${id}`)
        .then((response) => response.data)
        .catch((err) => console.log('deleteJapaneseChapter', err));
};

export const updateChapterOrder = (info) => {
    return createAPIELearning()
        .patch(`/admin-chapter/update-order`, info)
        .then((response) => response.data)
        .catch((err) => `updateChapterOrder,${err.message}`);
};

//lesson
export const getLessonsByChapter = (chapterid) => {
    return createAPIELearning()
        .get(`/admin-lesson/${chapterid}`)
        .then((response) => response.data)
        .catch((err) => console.log('getLessonsByChapter', err));
};

export const postNewLesson = (formData) => {
    const config = { 'content-type': 'multipart/form-data' };
    return createAPIELearning()
        .post(`/admin-lesson/create`, formData, config)
        .then((response) => response.data)
        .catch((err) => console.log('postNewJapaneseLesson', err));
};

export const updateLesson = (id, formData) => {
    const config = { 'content-type': 'multipart/form-data' };
    return createAPIELearning()
        .patch(`/admin-lesson/${id}`, formData, config)
        .then((response) => response.data)
        .catch((err) => console.log('updateLesson', err));
};

export const deleteLesson = (id) => {
    return createAPIELearning()
        .delete(`/admin-lesson/${id}`)
        .then((response) => response.data)
        .catch((err) => console.log('deletLesson', err));
};

export const updateLessonOrder = (info) => {
    return createAPIELearning()
        .patch(`/admin-lesson/update-order`, info)
        .then((response) => response.data)
        .catch((err) => console.log(`updateLessonOrder,${err}`));
};

//content
export const getConversationContentsByLessonID = (lessonId) => {
    return createAPIELearning()
        .get(`/admin-lesson-contents/conversation/${lessonId}`)
        .then((response) => {
            return response.data;
        })
        .catch((err) => console.log('getConversationContentsByLessonID', err));
};

export const postNewConversationContent = (data) => {
    return createAPIELearning()
        .post(`/admin-lesson-contents/create-conversation`, data)
        .then((response) => {
            return response.data;
        })
        .catch((err) => console.log('postNewConversationContent', err));
};

export const updateConversationContent = (data) => {
    return createAPIELearning()
        .patch(`/admin-lesson-contents/conversation/${data.id}`, data)
        .then((response) => response.data)
        .catch((err) => console.log('updateContent', err));
};

export const deleteConversationContent = (id) => {
    return createAPIELearning()
        .delete(`/admin-lesson-contents/conversation/${id}`)
        .then((response) => response.data)
        .catch((err) => console.log('deleteContent', err));
};

export const getConversationDetailByContentId = (contentid) => {
    return createAPIELearning()
        .get(`/admin-lesson-contents/conversation/detail/${contentid}`)
        .then((response) => {
            return response.data;
        })
        .catch((err) => console.log('getConversationDetailByContentId', err));
};

export const updateConversationContentOrder = (info) => {
    return createAPIELearning()
        .patch(`/admin-lesson-contents/conversation/update-order`, info)
        .then((response) => response.data)
        .catch((err) => console.log(`updateConversationContentOrder,${err}`));
};
