import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Form, Select, Modal, message, Space, Input } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment';

//components
import {
    LodoshaSelect,
    LodoshaButton,
    LodoshaInput,
    LodoshaDatePicker,
    LodoshaTextArea,
    LodoshaSwitch,
} from '../../components/UI/ui.style';

//utils and constants
import API from '../../utils/api';
import {
    dataJftBasicLevel,
    dataGender,
    dataBlood,
    dataWeddingStatus,
    dataShirtSize,
    dataUseHands,
    dataJapaneseLevel,
    dataMath,
    dataStudyLevel,
    dataTagStatus,
    dataReligion,
    dataFinancialStatus,
    dataCollateralStatus,
    dataGuaranteerStatus,
    dataInterviewPayment,
    dataLookingJobs,
} from '../../utils/data';
import AppContext from '../../contexts/AppContext';
import { autoDescriptionTypes } from '../../utils/data';

const { Option } = Select;
const dateFormatToServer = 'DD-MM-YYYY';
const dateFormatFromServer = 'YYYY-MM-DD';

const initialState = {
    nationality: '855',
    ever_to_japan: 0,
    eye_problem: 0,
    conversation_japan: 0,
    is_work: 0,
};

const Upsert = ({
    visible,
    setVisible,
    page,
    employee,
    setEmployee,
    setEmployees,
    onRefresh,
    autoDescriptions = {},
}) => {
    const [form] = Form.useForm();
    const { dataProvinces } = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [partners, setPartners] = useState([]);
    const [oldSelectOptions, setOldSelectOptions] = useState({});

    console.log('autoDescriptions', autoDescriptions);

    useEffect(() => {
        let isSubscribed = true;
        const handleFetchPartners = (page = 1) => {
            const url = page === 1 ? '/partner' : `/partner?offset=${(page - 1) * 15}`;
            API()
                .get(url)
                .then((res) => res.data)
                .then((result) => {
                    isSubscribed && setPartners((prevState) => [...prevState, ...result.data]);
                    if (result.hasNext) return handleFetchPartners(page + 1);
                })
                .catch((error) => {
                    console.log(error);
                });
        };

        handleFetchPartners();

        return () => (isSubscribed = false);
    }, []);

    const handleFilterAutoDescriptionOption = (data, type) => {
        let options = data[type];
        if (options) {
            options = options?.map((option, index) => ({
                ...option,
                key: option.id,
                value: option.id,
                text: option.name,
            }));
            return options;
        } else {
            return [];
        }
    };

    useEffect(() => {
        form.setFieldsValue({
            name: employee.name || '',
            gender: employee.gender ? Number(employee.gender) : null,
            phone: employee?.phone || '',
            partner: employee?.partner || '',
            japanses_level: employee.japanses_level ? Number(employee.japanses_level) : null,
            looking_jobs: employee.looking_jobs ? Number(employee.looking_jobs) : null,
            birth_date: employee.birth_date ? moment(employee.birth_date, dateFormatFromServer) : null,
            jft_basic_level: employee.jft_basic_level ? Number(employee.jft_basic_level) : null,
            address: employee.address || '',
            study_level: employee.study_level ? Number(employee.study_level) : null,
            email: employee.email || '',
            birth_place: employee.birth_place ? Number(employee.birth_place) : null,
            blood: employee.blood ? Number(employee.blood) : null,
            wedding_status: employee.wedding_status ? Number(employee.wedding_status) : null,
            height: employee.height,
            favorite_option: employee?.favorite_option,
            back_from_japan_goal_option: employee?.back_from_japan_goal_option,
            reason_and_goal_option: employee?.reason_and_goal_option,
            characteristic_option: employee?.characteristic_option,
            heal_situation_option: employee?.heal_situation_option,
            body_problem_option: employee?.body_problem_option,
            tattoo_option: employee?.tattoo_option,
            eyes_problem_option: employee?.eyes_problem_option,
            weight: employee.weight,
            waist_size: employee.waist_size,
            shirt_size: employee.shirt_size ? Number(employee.shirt_size) : null,
            sibling_index: employee.sibling_index,
            sibling_count: employee.sibling_count,
            sibling_girl_count: employee.sibling_girl_count,
            use_hand: employee.use_hand ? Number(employee.use_hand) : null,
            result_math: employee.result_math ? Number(employee.result_math) : null,
            religion: employee.religion ? Number(employee.religion) : null,
            financial_status: employee.financial_status ? Number(employee.financial_status) : null,
            collateral_status: employee.collateral_status ? Number(employee.collateral_status) : null,
            guaranteer_status: employee.guaranteer_status ? Number(employee.guaranteer_status) : null,
            facebook: employee.facebook || '',
            other_phone: employee.other_phone || '',
            tag: typeof employee.tag === 'string' ? employee.tag.split(',') : [],
            ever_to_japan: employee.ever_to_japan ? employee.ever_to_japan : 0,
            eye_problem: employee.eye_problem ? employee.eye_problem : 0,
            conversation_japan: employee.conversation_japan ? employee.conversation_japan : 0,
            is_tattoo: employee.is_tattoo ? employee.is_tattoo : 0,
            favorite: employee.favorite || '',
            heal_situation: employee.heal_situation || '',
            characteristic: employee.characteristic || '',
            reason_and_goal: employee.reason_and_goal || '',
            back_from_japan_goal: employee.back_from_japan_goal || '',
            self_skill: employee.self_skill || '',
            note: employee.note || '',
            interview_payment: employee.interview_payment,
        });
        setOldSelectOptions({
            favorite_option_old: employee?.favorite_option,
            back_from_japan_goal_option_old: employee?.back_from_japan_goal_option,
            reason_and_goal_option_old: employee?.reason_and_goal_option,
            characteristic_option_old: employee?.characteristic_option,
            heal_situation_option_old: employee?.heal_situation_option,
            body_problem_option_old: employee?.body_problem_option,
            tattoo_option_old: employee?.tattoo_option,
            eyes_problem_option_old: employee?.eyes_problem_option,
        });
    }, [form, employee]);

    const handleCancel = () => {
        setEmployees && setEmployee(initialState);
        setVisible(false);
    };

    const handleSubmit = async (fieldsValue) => {
        const values = {
            ...fieldsValue,
            ...oldSelectOptions,
            birth_date: fieldsValue['birth_date'].format(dateFormatToServer),
        };
        console.log('values', values);
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        const formData = new FormData();
        for (const key in values) {
            if (values[key] || values[key] === '') {
                formData.append(key, values[key]);
            }
        }
        formData.append('nationality', 855);
        setLoading(true);
        try {
            const response = employee.id
                ? await API().patch(`/employee/${employee.id}`, formData, config)
                : await API().post(`/employee/create`, formData, config);

            if (response?.data?.errorCode === 0) {
                message.success(`${employee.id ? `Updated` : `Created`} successfully!`);

                if (setEmployees) {
                    !employee.id
                        ? setEmployees((prevState) => [response.data.data, ...prevState])
                        : setEmployees((prevState) =>
                              prevState.map((prev) =>
                                  prev.id === response.data.data.id ? { ...prev, ...response.data.data } : prev
                              )
                          );
                }
                setEmployee(response.data.data);
                setLoading(false);
                setVisible(false);
                onRefresh(page);
            } else {
                setLoading(false);
                message.error(`Failed! ${response.data.message}`);
            }
        } catch (error) {
            setLoading(false);
            message.error(`Uh oh, request failed!`);
        }
    };

    console.log("employee", employee)

    const items = [
        { label: 'Name', type: 'text', name: 'name', required: true },
        { label: 'Gender', type: 'select', name: 'gender', options: dataGender, required: true },
        { label: 'Phone Number', type: 'text', name: 'phone', required: true },
        { label: 'Email', type: 'text', name: 'email' },
        {
            label: 'Partner',
            type: 'select',
            name: 'partner',
            options: partners.map((data) => ({ value: data.id, text: data.name })),
        },
        { label: 'Address', type: 'text', name: 'address', span: 8 },
        { label: 'Japanese Level', type: 'select', name: 'japanses_level', options: dataJapaneseLevel, required: true },
        { label: 'JFT Basic Level', type: 'select', name: 'jft_basic_level', options: dataJftBasicLevel },
        { label: 'Study Level', type: 'select', name: 'study_level', options: dataStudyLevel },
        { label: 'Birth Date', type: 'date', name: 'birth_date', required: true },
        { label: 'Birth Place', type: 'select', name: 'birth_place', options: dataProvinces },
        { label: 'Wedding Status', type: 'select', name: 'wedding_status', options: dataWeddingStatus },
        { label: 'Looking Jobs', type: 'select', name: 'looking_jobs', options: dataLookingJobs },

        { label: 'Blood', type: 'select', name: 'blood', options: dataBlood },
        { label: 'Height', type: 'number', name: 'height' },
        { label: 'Weight', type: 'number', name: 'weight' },
        { label: 'Waist Size', type: 'number', name: 'waist_size' },
        { label: 'Shirt Size', type: 'select', name: 'shirt_size', options: dataShirtSize },
        { label: 'Sibling Index', type: 'number', name: 'sibling_index' },
        { label: 'Sibling Count', type: 'number', name: 'sibling_count' },
        { label: 'Sibling Girl Count', type: 'number', name: 'sibling_girl_count' },
        { label: 'Use Hand', type: 'select', name: 'use_hand', options: dataUseHands },
        { label: 'Result Math', type: 'select', name: 'result_math', options: dataMath },
        { label: 'Religion', type: 'select', name: 'religion', options: dataReligion },
        { label: 'Financial', type: 'select', name: 'financial_status', options: dataFinancialStatus },
        { label: 'Collateral', type: 'select', name: 'collateral_status', options: dataCollateralStatus },
        { label: 'Guaranteer', type: 'select', name: 'guaranteer_status', options: dataGuaranteerStatus },
        { label: 'Paid For Interview (30$)', type: 'select', name: 'interview_payment', options: dataInterviewPayment },
        { label: 'Tags', type: 'select', name: 'tag', options: dataTagStatus, mode: 'multiple', span: 24 },
        { label: 'Facebook', type: 'text', name: 'facebook', span: 12 },
        { label: 'Other Phone (070353568,077498555...)', type: 'text', name: 'other_phone', span: 12 },

        { label: 'Ever Go to Japan', type: 'switch', name: 'ever_to_japan' },
        { label: 'Eye Problem', type: 'switch', name: 'eye_problem' },
        { label: 'Conversation Japan', type: 'switch', name: 'conversation_japan' },
        { label: 'Tattoo', type: 'switch', name: 'is_tattoo' },

        {
            label: 'Introduction Video',
            type: 'text',
            name: 'introduction_video',
            span: 24,
        },
        {
            label: 'Body problem',
            type: 'select',
            name: 'body_problem_option',
            span: 24,
            options: handleFilterAutoDescriptionOption(autoDescriptions, autoDescriptionTypes.BODY_PROBLEM),
            mode: 'multiple',
        },
        {
            label: 'Favorite',
            type: 'select',
            name: 'favorite_option',
            span: 24,
            options: handleFilterAutoDescriptionOption(autoDescriptions, autoDescriptionTypes.FAVORITE),
            mode: 'multiple',
            existData: employee?.favorite && {
                label: 'Favorite (Additional Note)',
                type: 'textarea',
                name: 'favorite',
                span: 24,
            },
        },
        {
            label: 'Health Situation',
            type: 'select',
            name: 'heal_situation_option',
            mode: 'multiple',
            span: 24,
            options: handleFilterAutoDescriptionOption(autoDescriptions, autoDescriptionTypes.HEALTH_SITUATION),
            existData: employee?.heal_situation && {
                label: 'Health Situation (Additional Note)',
                type: 'textarea',
                name: 'heal_situation',
                span: 24,
            },
        },

        {
            label: 'Characteristic',
            type: 'select',
            name: 'characteristic_option',
            span: 24,
            options: handleFilterAutoDescriptionOption(autoDescriptions, autoDescriptionTypes.CHARACTERISTIC),
            mode: 'multiple',
            existData: employee?.characteristic && {
                label: 'Characteristic (Additional Note)',
                type: 'textarea',
                name: 'characteristic',
                span: 24,
            },
        },

        {
            label: 'Reason and Goal to Japan',
            type: 'select',
            name: 'reason_and_goal_option',
            span: 24,
            options: handleFilterAutoDescriptionOption(autoDescriptions, autoDescriptionTypes.REASON_AND_GOAL),
            mode: 'multiple',
            existData: employee?.reason_and_goal && {
                label: 'Reason and Goal to Japan (Additional Note)',
                type: 'textarea',
                name: 'reason_and_goal',
                span: 24,
            },
        },
        {
            label: 'Back from Japan Goal',
            type: 'select',
            name: 'back_from_japan_goal_option',
            span: 24,
            options: handleFilterAutoDescriptionOption(autoDescriptions, autoDescriptionTypes.BACK_JAPAN_GOAL),
            mode: 'multiple',
            existData: employee?.back_from_japan_goal && {
                label: 'Back from Japan Goal (Additional Note)',
                type: 'textarea',
                name: 'back_from_japan_goal',
                span: 24,
            },
        },
        {
            label: 'Tattoo',
            type: 'select',
            name: 'tattoo_option',
            span: 24,
            options: handleFilterAutoDescriptionOption(autoDescriptions, autoDescriptionTypes.TATTOO),
            mode: 'multiple',
        },
        {
            label: 'Eyes Problem',
            type: 'select',
            name: 'eyes_problem_option',
            span: 24,
            options: handleFilterAutoDescriptionOption(autoDescriptions, autoDescriptionTypes.EYES_PROBLEM),
            mode: 'multiple',
        },

        { label: 'Note', type: 'textarea', name: 'note', span: 24 },
    ];

    return (
        <Modal
            visible={visible}
            maskClosable={false}
            title={employee?.id ? 'Update' : 'Create'}
            onCancel={handleCancel}
            width={768}
            footer={null}
            getContainer={false}
        >
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Row gutter={24}>
                    {items.map((item) => (
                        <>
                            <Col key={item.name} span={item.span ? item.span : 8}>
                                <Form.Item
                                    name={item.name}
                                    label={item.label + ':'}
                                    rules={[
                                        { required: item.required, message: 'Data required!' },
                                        ({ getFieldValue }) => ({
                                            validator: (formInfo, value) => {
                                                if (formInfo?.field === 'email') {
                                                    const email = getFieldValue('email');
                                                    if (email && email.includes(' ')) {
                                                        return Promise.reject(new Error('Email cannot contain space'));
                                                    }
                                                }

                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                    valuePropName={item.type === 'switch' ? 'checked' : 'value'}
                                >
                                    {item.type === 'select' ? (
                                        <LodoshaSelect
                                            mode={item.mode}
                                            allowClear={!!item.mode}
                                            placeholder="Select an option"
                                            maxTagCount={2}
                                        >
                                            {item?.options?.map(({ value, text }) => (
                                                <Option key={value} value={item.name === 'tag' ? text : value}>
                                                    {text}
                                                </Option>
                                            ))}
                                        </LodoshaSelect>
                                    ) : item.type === 'date' ? (
                                        <LodoshaDatePicker />
                                    ) : item.type === 'switch' ? (
                                        <LodoshaSwitch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                        />
                                    ) : item.type === 'textarea' ? (
                                        <LodoshaTextArea placeholder={'Enter ' + item.label.toLowerCase()} />
                                    ) : (
                                        <LodoshaInput
                                            type={item.type}
                                            placeholder={'Enter ' + item.label.toLowerCase()}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            {item.existData && (
                                <Col span={item.existData.span}>
                                    <Form.Item label={item.existData.label} name={item.existData.name}>
                                        <Input.TextArea autoSize={{ minRows: 4 }} />
                                    </Form.Item>
                                </Col>
                            )}
                        </>
                    ))}
                    <Col span={24}>
                        <Form.Item style={{ textAlign: 'center', marginTop: 16 }}>
                            <Space direction="horizontal" size="middle">
                                <LodoshaButton key="cancel" htmlType="button" onClick={handleCancel}>
                                    Cancel
                                </LodoshaButton>
                                <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                                    {employee.id ? 'Update' : 'Create'}
                                </LodoshaButton>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default Upsert;
