
import API from '../api';

export const getStaffs = (page = 1) => {
    const url = page === 1 ? `/staff` : `/staff?offset=${(page - 1) * 15}`;
    return API().get(url)
            .then(response => {
                return response.data;
            })
            .catch(err => { 
                console.log('err: ', err) 
            });
};

export const createStaff = (data) => {
    return API().post(`/staff/create`, data)
            .then(response => {
                return response.data;
            }).catch(err => {
                console.log('err: ', err) 
            })
}

export const deleteStaff = (id) => {
    return API().delete(`/staff/${id}`)
            .then(response => {
                return response.data;
            }).catch(err => {
                console.log('err: ', err) 
            })
}

export const updateStaff = (data, id) => {
    return API().patch(`/staff/${id}`, data)
            .then(response => {
                return response.data;
            }).catch(err => {
                console.log('err: ', err) 
            })
}

export const updateStatus = (id, status) => {
    const url = `/staff/status/${id}`;
    return API().patch(url, { status: status })
            .then(response => {
                return response.data;
            })
            .catch(err => { 
                console.log('err: ', err) 
            });
};

export const getStaffsWithoutBOD = () => {
    const url = `/staff/staff-only`;
    return API().get(url)
            .then(response => {
                return response.data;
            })
            .catch(err => { 
                console.log('err: ', err) 
            });
};
