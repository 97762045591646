import { getIdFromFilePath } from '../..';
import { createAPIELearning } from '../../api';

export const getQuestionDetail = (questionId) => {
    return createAPIELearning()
        .get(`/admin-question/detail/${questionId}`)
        .then((response) => response.data)
        .catch((err) => console.log(`getQuestionDetail,${err}`));
};

export const postNewExamQuestion = (data, files) => {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    const formData = new FormData();

    for (const key in data) {
        formData.append(key, data[key]);
    }

    for (const file of files) {
        //file.originFileObj.fieldname is custom (not original from file)
        formData.append(file.originFileObj.fieldname, file.originFileObj);
    }

    //  check values in formdata
    // for (let [key, value] of formData.entries()) {
    //     console.log(key, value);
    // }

    return createAPIELearning()
        .post(`/admin-question/create/exam`, formData, config)
        .then((response) => response.data)
        .catch((err) => console.log(`createQuestion,${err}`));
};

export const postNewJLPTExercise = (data) => {
    return createAPIELearning()
        .post(`/admin-question/create/jlpt`, data)
        .then((response) => response.data)
        .catch((err) => console.log(`postNewJLPTExercise`, err));
};

export const updateQuestion = (data, files) => {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    const formData = new FormData();
    for (const key in data) {
        switch (key) {
            case 'sound': {
                const extractID = getIdFromFilePath(data, key);
                formData.append(key, extractID);
                break;
            }
            case 'image': {
                const extractID = getIdFromFilePath(data, key);
                formData.append(key, extractID);
                break;
            }
            default:
                formData.append(key, data[key]);
        }
    }

    if (files){
        for (const file of files) {
            //file.originFileObj.fieldname is custom (not original from file)
            formData.append(file.originFileObj.fieldname, file.originFileObj);
        }
    }
    
    return createAPIELearning()
        .patch(`/admin-question/${data.id}`, formData, config)
        .then((response) => response.data)
        .catch((err) => console.log(`updateQestion,${err}`));
};

export const deleteQuestion = (id) => {
    return createAPIELearning()
        .delete(`/admin-question/${id}`)
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

export const updateQuestionOrder = (info) => {
    return createAPIELearning()
        .patch(`/admin-question/update-order`, info)
        .then((response) => response.data)
        .catch((err) => console.log('updateSectionOrder failed', err));
};
