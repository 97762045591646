import React, { useState, useMemo } from 'react';

//api
import { postNewExamQuestion, updateQuestion } from '../../../utils/api/E-Learning/question';

//ant design
import { Modal, Row, Col, Form, message, Input, Upload, Card } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

//components
import ModalWrapper from '../E-LearningComponents/modal.style';
import { LodoshaButton } from '../../../components/UI/ui.style';
import MarkdownGenerator from '../../../components/MarkdownGenerator';

export default function QuestionModal({
    title,
    visible,
    setVisible,
    onRefresh,
    setPostData,
    postData,
    isCreate,
    isUpdate,
}) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(postData);
    const [showDragger, setShowDragger] = useState({ questionImage: false });
    const [questionImage, setQuestionImage] = useState([]);
    const [questionSound, setQuestionSound] = useState([]);

    const { Dragger } = Upload;

    const { TextArea } = Input;

    const questionFormFields = useMemo(
        () => [
            {
                label: 'Choice One',
                type: 'text',
                name: 'choice_one',
                value: data?.choice_one,
                span: 24,
                required: true,
            },
            {
                label: 'Choice Two',
                type: 'text',
                name: 'choice_two',
                value: data?.choice_two,
                span: 24,
                required: true,
            },
            {
                label: 'Choice Three',
                type: 'text',
                name: 'choice_three',
                value: data?.choice_three,
                span: 24,
                required: true,
            },
            {
                label: 'Choice Four',
                type: 'text',
                name: 'choice_four',
                value: data?.choice_four,
                span: 24,
                required: true,
            },
            {
                label: 'Answer',
                type: 'text',
                name: 'answer',
                value: data?.answer,
                span: 24,
                required: true,
            },
        ],
        [data]
    );

    const handleOnMouseLeave = (key) => {
        setShowDragger((prev) => ({
            ...prev,
            [key]: false,
        }));
    };

    const handleOnMouseEnter = (key) => {
        setShowDragger((prev) => ({
            ...prev,
            [key]: true,
        }));
    };

    //close modal
    const handleCancel = (e) => {
        setVisible(false);
        setPostData({});
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        setData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleChangeFile = (info, fieldname) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        fileList = fileList.map((file) => {
            if (file.response) {
                file.url = file.response.url;
            }
            return file;
        });
        // important when uploading to server
        if (fileList.length > 0) {
            fileList[0].originFileObj.fieldname = fieldname;
        }

        switch (fieldname) {
            case 'question-image': {
                setQuestionImage(fileList);
                break;
            }
            case 'question-sound': {
                setQuestionSound(fileList);
                break;
            }
            default:
        }
    };

    const handleSubmit = async () => {
        let tempFiles = [];
        tempFiles = tempFiles.concat(questionImage).concat(questionSound);
        if (tempFiles.length === 0 && !data?.title && !isUpdate) {
            message.info('At least one field from Question should have value.');
            return;
        }
        setLoading(true);
        try {
            const response = isCreate
                ? await postNewExamQuestion(data, tempFiles)
                : isUpdate
                ? await updateQuestion(data, tempFiles)
                : {};

            if (response && response.errorCode !== 0) {
                setLoading(false);
                message.error(`Oh oh, something went wrong. ${response.message}`);
                return;
            }

            if (response) {
                response && message.success(`Successfully ${isCreate ? 'create' : 'update'} question`);
                setLoading(false);
                onRefresh();
                handleCancel();
            } else {
                message.error(`Uh oh, ${isCreate ? 'create' : 'update'} question failed!.`);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
            message.error(`Uh oh, ${isCreate ? 'create' : isUpdate ? 'update' : ''} content failed!`);
        }
    };

    const beforeUpload = () => {
        return false;
    };

    return (
        <Modal
            width={768}
            title={title}
            visible={visible}
            centered
            onCancel={handleCancel}
            maskClosable={false}
            footer={null}
        >
            <ModalWrapper>
                <Form layout="vertical" form={form} onFinish={() => handleSubmit()}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Card
                                style={{ marginBottom: '1rem' }}
                                size="small"
                                title={<h3 style={{ margin: '0', fontWeight: 'bold' }}>Question</h3>}
                            >
                                <Form.Item
                                    rules={[{ required: false, message: 'Data required!' }]}
                                    name="title"
                                    initialValue={data?.title}
                                >
                                    <TextArea
                                        type="text"
                                        onChange={handleChange}
                                        value={data?.title}
                                        name="title"
                                        placeholder="Question Title"
                                    />
                                </Form.Item>

                                <Row gutter={[16]}>
                                    <Col span={12}>
                                        <Form.Item label="Question Image">
                                            {data?.image ? (
                                                <div
                                                    className="image-preview-container"
                                                    onMouseLeave={() => handleOnMouseLeave('questionImage')}
                                                    onMouseEnter={() => handleOnMouseEnter('questionImage')}
                                                >
                                                    <img src={data.image} className="content-image" alt="preview" />
                                                    <div
                                                        className={`${
                                                            (showDragger.questionImage || questionImage.length > 0) &&
                                                            `show-upload`
                                                        } image-upload`}
                                                    >
                                                        <Dragger
                                                            name="question-image"
                                                            beforeUpload={beforeUpload}
                                                            fileList={questionImage}
                                                            onChange={(info) =>
                                                                handleChangeFile(info, 'question-image')
                                                            }
                                                            accept="image/*"
                                                        >
                                                            <p className="ant-upload-drag-icon">
                                                                <InboxOutlined />
                                                            </p>
                                                            <p className="ant-upload-text">
                                                                Click or drag file to this area to upload
                                                            </p>
                                                            <p className="ant-upload-hint">
                                                                Support for a single or bulk upload.
                                                            </p>
                                                        </Dragger>
                                                    </div>
                                                </div>
                                            ) : (
                                                <Dragger
                                                    name="question-image"
                                                    beforeUpload={beforeUpload}
                                                    fileList={questionImage}
                                                    onChange={(info) => handleChangeFile(info, 'question-image')}
                                                    accept="image/*"
                                                >
                                                    <p className="ant-upload-drag-icon">
                                                        <InboxOutlined />
                                                    </p>
                                                    <p className="ant-upload-text">
                                                        Click or drag file to this area to upload
                                                    </p>
                                                    <p className="ant-upload-hint">
                                                        Support for a single or bulk upload.
                                                    </p>
                                                </Dragger>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Question Sound">
                                            <Dragger
                                                name="question-sound"
                                                beforeUpload={beforeUpload}
                                                fileList={questionSound}
                                                onChange={(info) => handleChangeFile(info, 'question-sound')}
                                                accept="audio/mp3"
                                            >
                                                <p className="ant-upload-drag-icon">
                                                    <InboxOutlined />
                                                </p>
                                                <p className="ant-upload-text">
                                                    Click or drag file to this area to upload
                                                </p>
                                                <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                            </Dragger>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        {questionFormFields?.map((field, index) => {
                            const { type, label, name, value, required, disabled, span } = field;
                            return (
                                <Col span={span} key={index}>
                                    <Form.Item
                                        rules={[{ required: required, message: 'Data required!' }]}
                                        label={`${label}:`}
                                        name={name}
                                        initialValue={value}
                                    >
                                        <TextArea
                                            type={type}
                                            disabled={disabled && true}
                                            onChange={handleChange}
                                            value={value}
                                            name={name}
                                        />
                                    </Form.Item>
                                </Col>
                            );
                        })}

                        <Col span={24}>
                            <MarkdownGenerator data={data} setData={setData} isUpdate={isUpdate} />
                        </Col>

                        <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
                            <LodoshaButton
                                key="cancel"
                                style={{ marginRight: 16 }}
                                htmlType="button"
                                onClick={handleCancel}
                            >
                                Cancel
                            </LodoshaButton>
                            <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                                {isCreate ? 'Create' : isUpdate ? 'Update' : ''}
                            </LodoshaButton>
                        </Col>
                    </Row>
                </Form>
            </ModalWrapper>
        </Modal>
    );
}
