import React, { useState, useRef, useEffect, useCallback } from "react";
import { Row, Col, Modal, Button, Table, message, Popconfirm } from "antd";
import { PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons";
import { LodoshaSearch } from "../../components/UI/ui.style";
import { searchEmployees, createPartnerEmployee, deletePartnerEmployee } from "../../utils/api/partner_employee";

const timeout_typing_state = { typingTimeout: 0 };

const ModalEmployees = ({ visible, setVisible, partnerId, handleAddEmployee, handleRemoveEmployee }) => {
  const isSubscribed = useRef(true);
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [totalEmployee, setTotalEmployee] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [timeoutTyping, setTimeoutTyping] = useState(timeout_typing_state);

  const handleFetchData = useCallback(
    async (page = 1, keyword = "") => {
      isSubscribed.current && setLoading(true);
      try {
        searchEmployees(page, keyword, "name, code, phone, id", partnerId).then((response) => {
          if (response.data && isSubscribed.current) {
            setTotalEmployee(response.total);
            setEmployees(response.data);
          }
          isSubscribed.current && setLoading(false);
        });
      } catch (error) {
        isSubscribed.current && setLoading(false);
        message.error(`Uh oh, request failed!`);
      }
    },
    [partnerId]
  );

  useEffect(() => {
    handleFetchData();
    return () => {
      isSubscribed.current = false;
    };
  }, [handleFetchData]);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setKeyword(value);

    if (timeoutTyping.typingTimeout) {
      clearTimeout(timeoutTyping.typingTimeout);
    }

    setTimeoutTyping({
      typingTimeout: setTimeout(function () {
        handleFetchData(1, value);
      }, 1000),
    });
  };

  const handleAdd = (e, record) => {
    e.stopPropagation();
    const data = { partner_id: partnerId, employee_id: record.id };
    createPartnerEmployee(data).then((response) => {
      if (response && response.errorCode === 0) {
        message.info("Added successfully!");
        setEmployees(
          employees.map((item) => (item.id === record.id ? { ...item, partner_employee_id: response.data.id } : item))
        );

        // send to main page
        handleAddEmployee(response.data);
      } else {
        message.error(response && response.message);
      }
    });
  };

  const handleDelete = (e, record) => {
    e.stopPropagation();
    deletePartnerEmployee(record.partner_employee_id).then((response) => {
      if (response && response.errorCode === 0) {
        message.success("Removed successfully!");
        setEmployees(employees.map((item) => (item.id === record.id ? { ...item, partner_employee_id: "" } : item)));
        handleRemoveEmployee(record.partner_employee_id);
      } else {
        message.error(response && response.message);
      }
    });
  };

  const handlePageChange = (page) => {
    handleFetchData(page, keyword);
  };

  const dataSource = employees.length
    ? employees.map((employee, index) => {
        return {
          ...employee,
          key: employee.id,
          no: index + 1,
        };
      })
    : [];

  const columns = [
    { title: "CODE", dataIndex: "code", key: "code", width: "20%" },
    { title: "NAME", dataIndex: "name", key: "name", width: "40%" },
    { title: "PHONE", dataIndex: "phone", key: "phone", width: "20%" },
    {
      title: "",
      key: "actions",
      width: "5%",
      render: (record) => (
        <span style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
          {record.partner_employee_id === "" ? (
            <Button
              title="Add"
              type={record.partner_employee_id === "" ? "primary" : "danger"}
              className="action-button-margin"
              icon={record.partner_employee_id === "" ? <PlusSquareOutlined /> : <MinusSquareOutlined />}
              onClick={(e) => handleAdd(e, record)}
            />
          ) : (
            <Popconfirm
              placement="topLeft"
              title={"Do you want to delete?"}
              onConfirm={(e) => handleDelete(e, record)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type={record.partner_employee_id === "" ? "primary" : "danger"}
                icon={record.partner_employee_id === "" ? <PlusSquareOutlined /> : <MinusSquareOutlined />}
              />
            </Popconfirm>
          )}
        </span>
      ),
    },
  ];

  return (
    <Modal visible={visible} maskClosable={false} onCancel={handleCancel} width={576} footer={null}>
      <Row>
        <Col span={24}>
          <h2>Search Candidates</h2>
        </Col>
        <Col span={24} style={{ marginBottom: 24 }}>
          <LodoshaSearch placeholder="Keyword" value={keyword} onChange={handleChange} />
        </Col>
        <Col span={24}>
          <Table
            bordered
            loading={loading}
            dataSource={dataSource}
            columns={columns}
            pagination={{
              defaultCurrent: 1,
              defaultPageSize: 15,
              total: totalEmployee,
              onChange: handlePageChange,
            }}
          />
        </Col>
      </Row>
    </Modal>
  );
};
export default ModalEmployees;
