import React from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Menu } from 'antd';
import {
    HomeOutlined,
    IdcardOutlined,
    UserOutlined,
    NotificationOutlined,
    TeamOutlined,
    SearchOutlined,
    SolutionOutlined,
    SettingOutlined,
    KeyOutlined,
    PoweroffOutlined,
    ThunderboltOutlined,
    ShopOutlined,
    PhoneOutlined,
    FileDoneOutlined,
    ReadOutlined,
    StarOutlined,
    PlusCircleOutlined,
    TagsOutlined
} from '@ant-design/icons';
import Cookies from 'js-cookie';
import * as ROUTES from '../constants/routes';

const Sider = () => {
    const history = useHistory();
    const location = useLocation();
    var keys = location.pathname.split('/');
    const key = keys[1] === '' ? 'dashboard' : keys[1];

    const handleLogOut = (event) => {
        Cookies.remove('backoffice_token');
        Cookies.remove('middleware_token');
        localStorage.removeItem('lodosha_user_name');
        localStorage.removeItem('lodosha_position');

        history.push('/login');
    };

    return (
        <Menu mode="inline" defaultSelectedKeys={[key]}>
            <Menu.Item key="dashboard" style={{ background: 'none' }}>
                <Link to={ROUTES.HOME}>
                    <HomeOutlined />
                    <span>Dashboard</span>
                </Link>
            </Menu.Item>
            {/* <Menu.Item key="favorites" style={{ background: 'none' }}>
                <Link to={ROUTES.FAVORITES}>
                    <StarOutlined />
                    <span>Favorites Request</span>
                </Link>
            </Menu.Item> */}
            {/* <Menu.SubMenu
                key="messages"
                title={
                    <>
                        <MessageOutlined />
                        <span>Messages</span>
                    </>
                }
            >
                <Menu.Item key="candidates" style={{ background: 'none' }}>
                    <Link to={ROUTES.MESSAGES_CANDIDATES}>
                        <IdcardOutlined />
                        <span>Candidates</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="general" style={{ background: 'none' }}>
                    <Link to={ROUTES.MESSAGES_GENERAL}>
                        <UsergroupAddOutlined />
                        <span>General</span>
                    </Link>
                </Menu.Item>
            </Menu.SubMenu> */}

            <Menu.Item key="candidates" style={{ background: 'none' }}>
                <Link to={ROUTES.CANDIDATES}>
                    <IdcardOutlined />
                    <span>Candidates</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="users" style={{ background: 'none' }}>
                <Link to={ROUTES.USERS}>
                    <TeamOutlined />
                    <span>Users</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="candidate-calls" style={{ background: 'none' }}>
                <Link to={ROUTES.CANDIDATE_CALL}>
                    <PhoneOutlined />
                    <span>Candidate Calls</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="companys" style={{ background: 'none' }}>
                <Link to={ROUTES.COMPANIES}>
                    <ShopOutlined />
                    <span>Companies</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="post-jobs" style={{ background: 'none' }}>
                <Link to={ROUTES.POSTJOBS}>
                    <NotificationOutlined />
                    <span>Post Jobs</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="partners" style={{ background: 'none' }}>
                <Link to={ROUTES.PARTNERS}>
                    <TeamOutlined />
                    <span>Partners</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="hospital" style={{ background: 'none' }}>
                <Link to={ROUTES.HOSPITALS}>
                    <PlusCircleOutlined />
                    <span>Hospitals</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="advance-search" style={{ background: 'none' }}>
                <Link to={ROUTES.ADVANCE_SEARCH}>
                    <SearchOutlined />
                    <span>Advance Search</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="jobs" style={{ background: 'none' }}>
                <Link to={ROUTES.JOBS}>
                    <ThunderboltOutlined />
                    <span>Jobs</span>
                </Link>
            </Menu.Item>
            {localStorage.getItem('lodosha_position') === 'admin' ? (
                <Menu.Item key="career" style={{ background: 'none' }}>
                    <Link to={ROUTES.CAREER}>
                        <SolutionOutlined />
                        <span>Careers</span>
                    </Link>
                </Menu.Item>
            ) : (
                <div />
            )}
            {localStorage.getItem('lodosha_position') === 'admin' ? (
                <Menu.Item key="staff" style={{ background: 'none' }}>
                    <Link to={ROUTES.STAFFS}>
                        <UserOutlined />
                        <span>Staffs</span>
                    </Link>
                </Menu.Item>
            ) : (
                <div />
            )}

            <Menu.Item key="memberships" style={{ background: 'none' }}>
                <Link to={ROUTES.MEMBERSHIPS}>
                    <StarOutlined />
                    <span>Memberships</span>
                </Link>
            </Menu.Item>

            <Menu.Item key="blogs" style={{ background: 'none' }}>
                <Link to={ROUTES.BLOGS}>
                    <FileDoneOutlined />
                    <span>Blogs</span>
                </Link>
            </Menu.Item>

            <Menu.Item key="e-learning" style={{ background: 'none' }}>
                <Link to={ROUTES.ELEARNING}>
                    <ReadOutlined />
                    <span>E-Learning</span>
                </Link>
            </Menu.Item>

            {/* <Menu.SubMenu
                key="messages"
                title={
                    <>
                        <MessageOutlined />
                        <span>Messages</span>
                    </>
                }
            >
                <Menu.Item key="candidates" style={{ background: 'none' }}>
                    <Link to={ROUTES.MESSAGES_CANDIDATES}>
                        <IdcardOutlined />
                        <span>Candidates</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="general" style={{ background: 'none' }}>
                    <Link to={ROUTES.MESSAGES_GENERAL}>
                        <UsergroupAddOutlined />
                        <span>General</span>
                    </Link>
                </Menu.Item>
            </Menu.SubMenu> */}

            <Menu.SubMenu
                key="settings"
                title={
                    <span>
                        <SettingOutlined />
                        <span>Settings</span>
                    </span>
                }
            >
                <Menu.Item key="change-password">
                    <Link to={ROUTES.CHANGE_PASSWORD}>
                        <span>
                            <KeyOutlined />
                            <span>Password</span>
                        </span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="manage-auto-description">
                    <Link to={ROUTES.MANAGE_AUTO_DESCRIPTION}>
                        <span>
                            <TagsOutlined />
                            <span>Manage Auto Description</span>
                        </span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="logout" onClick={handleLogOut}>
                    <span>
                        <PoweroffOutlined />
                        <span>Log Out</span>
                    </span>
                </Menu.Item>
            </Menu.SubMenu>
        </Menu>
    );
};

export default Sider;
