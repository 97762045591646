import createAPI from '../api';

export const getMemberships = async () => {
    return createAPI()
        .get('/membership')
        .then((response) => response.data)
        .catch((err) => {
            console.log('getMemberships err:', err);
        });
};

export const addNewMembershipType = async (info) => {
    return createAPI()
        .post('/membership/create', info)
        .then((response) => response.data)
        .catch((err) => console.log('addNewMembershipType err :', err));
};

export const updateMembershipType = async (info, id) => {
    return createAPI()
        .patch(`/membership/${id}`, info)
        .then((response) => response.data)
        .catch((err) => console.log('updateMembershipType err :', err));
};

export const updateMembershipOrder = async (info) => {
    return createAPI()
        .patch('/membership/update-order', info)
        .then((response) => response.data)
        .catch((err) => `updateChapterOrder,${err.message}`);
};

export const deleteMembershipType = async (id) => {
    return createAPI()
        .delete(`/membership/${id}`)
        .then((response) => response.data)
        .catch((err) => console.log('deleteMembershipType err :', err));
};
