import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import AppContext from '../../../contexts/AppContext';

//api
import { getMarkdowns } from '../../../utils/api/E-Learning/markdown';

//antd
import { Card, List, message, Dropdown, Menu, Space, Button } from 'antd';
import { PlusOutlined, AppstoreOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

//components
import MarkdownSampleCreateModal from './MarkdownSampleCreateModal';
import { Container, LodoshaButton } from '../../../components/UI/ui.style';
import { MarkdownSampleCreatorWrapper } from './markdown_sample_creator.styles';

//utils
import { getNewLastOrder } from '../../../utils';
import IFrameMarkdown from '../E-LearningComponents/IFrameMarkdown';
import { markdownTypeToText } from '../../../utils/data';

export default function MarkdownSampleCreator() {
    const { handleUnauthorizedUser } = useContext(AppContext);
    const [markdowns, setMarkdowns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [upsertVisible, setUpsertVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [postData, setPostData] = useState([]);
    const isSubscribed = useRef(true);

    const dataSource =
        markdowns &&
        markdowns.map((markdown, index) => ({ ...markdown, typeToText: markdownTypeToText[markdown.type - 1] }));

    const handleFetchMarkdowns = useCallback(
        async (kw) => {
            try {
                setLoading(true);
                const response = await getMarkdowns(kw);
                if (response) {
                    if (response.data.status === 401) {
                        message.error('Unauthorized.');
                        handleUnauthorizedUser();
                        return;
                    }

                    if (response.errorCode === 0) {
                        setMarkdowns(response.data);
                        setLoading(false);
                    } else {
                        message.error(`Uh oh, couldn't fetch markdowns, err: ${response}`);
                        setLoading(false);
                        return;
                    }
                } else {
                    message.error(`Uh oh, couldn't fetch markdowns. Response is undefined`);
                    setLoading(false);
                    return;
                }
            } catch (error) {
                message.error(`Uh oh, couldn't fetch markdowns. err: ${error}`);
                setLoading(false);
            }
        },
        [handleUnauthorizedUser]
    );

    const Upsert = useCallback(
        () =>
            upsertVisible && (
                <MarkdownSampleCreateModal
                    isCreate
                    postData={postData}
                    setVisible={setUpsertVisible}
                    visible={upsertVisible}
                    onRefresh={handleFetchMarkdowns}
                    title="Create New Markdown Sample"
                    setPostData={setPostData}
                />
            ),
        [postData, upsertVisible, handleFetchMarkdowns]
    );

    const Update = useCallback(
        () =>
            updateVisible && (
                <MarkdownSampleCreateModal
                    isUpdate
                    postData={postData}
                    setVisible={setUpdateVisible}
                    visible={updateVisible}
                    onRefresh={handleFetchMarkdowns}
                    title="Update Markdown Sample"
                    setPostData={setPostData}
                />
            ),
        [postData, updateVisible, handleFetchMarkdowns]
    );

    const handleFilter = (filterBy) => {
        let type = '';
        if (filterBy === 'general') {
            type = '1';
        } else if (filterBy === 'layout') {
            type = '2';
        } else {
            type = '';
        }
        return handleFetchMarkdowns(type);
    };

    const handleEdit = (item) => {
        console.log(item);
        setPostData(item);
        setUpdateVisible(true);
    };

    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchMarkdowns();
        }
        return () => (isSubscribed.current = false);
    }, [handleFetchMarkdowns]);

    useEffect(() => {
        let lastOrder = getNewLastOrder(markdowns);
        console.log('lastOrder', lastOrder);
        setPostData((prev) => ({ ...prev, order: lastOrder }));
    }, [markdowns, upsertVisible]);

    return (
        <MarkdownSampleCreatorWrapper>
            <Container>
                <div>
                    <LodoshaButton type="primary" onClick={() => setUpsertVisible(true)}>
                        <PlusOutlined />
                        Add New
                    </LodoshaButton>
                    <span className="filter-icon-container">
                        <Dropdown
                            overlay={() => (
                                <Menu>
                                    <Menu.Item
                                        style={{ width: '100px', opacity: 0.7 }}
                                        onClick={() => handleFilter('all')}
                                    >
                                        Show all
                                    </Menu.Item>
                                    <Menu.Item
                                        style={{ width: '100px', opacity: 0.7 }}
                                        onClick={() => handleFilter('general')}
                                    >
                                        General
                                    </Menu.Item>
                                    <Menu.Item
                                        style={{ width: '100px', opacity: 0.7 }}
                                        onClick={() => handleFilter('layout')}
                                    >
                                        Layout
                                    </Menu.Item>
                                </Menu>
                            )}
                        >
                            <AppstoreOutlined className="filter-icon" />
                        </Dropdown>
                    </span>
                </div>
                <Upsert />
                <Update />
                <List
                    style={{ marginTop: '1.5rem' }}
                    grid={{ column: 3, gutter: 32 }}
                    dataSource={dataSource}
                    loading={loading}
                    renderItem={(item) => (
                        <List.Item style={{ marginBottom: '2rem' }}>
                            <Card title="" hoverable style={{ minHeight: '100px' }} bodyStyle={{ padding: 0 }}>
                                <div className="card-header">
                                    <div className="card-type-text">{item.typeToText}</div>
                                    <div className="card-action-button-container">
                                        <Space size="small">
                                            <Button
                                                icon={<EditOutlined />}
                                                type="primary"
                                                onClick={() => handleEdit(item)}
                                            />
                                            <Button icon={<DeleteOutlined />} type="danger" />
                                        </Space>
                                    </div>
                                </div>
                                <div>
                                    <IFrameMarkdown css={item.css} markdown={item.markdown} key={item.id} />
                                </div>
                            </Card>
                        </List.Item>
                    )}
                />
            </Container>
        </MarkdownSampleCreatorWrapper>
    );
}
