import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Dropdown, Table, Menu, Modal, message, Space, Select } from 'antd';
import {
    LaptopOutlined,
    HistoryOutlined,
    UsergroupAddOutlined,
    MoreOutlined,
    KeyOutlined,
    GlobalOutlined,
    EditOutlined,
    DeleteOutlined,
    BookOutlined,
    RedoOutlined,
    PhoneOutlined,
} from '@ant-design/icons';

//components
import { LodoshaSelect } from '../../components/UI/ui.style';

//constants and utils
import * as ROUTES from '../../constants/routes';
import getTextDisplay, { dataType, dataEmployeeStatus, dataInterviewPayment, dataLookingJobs } from '../../utils/data';
import { dateDisplay } from '../../utils';
import {
    updateStatus,
    deleteCandidate,
    recoverCandidate,
    updateInterviewPaymentStatus,
    UpdateLookingjobs,
} from '../../utils/api/candidate';

const EmployeeTable = ({
    loading,
    page,
    total,
    employees,
    setEmployees,
    setEmployee,
    setVisibleUpsert,
    setVisibleInterest,
    setVisibleBackground,
    setVisibleRelative,
    setVisibleCallHistory,
    setVisibleNote,
    setVisiblePassword,
    setVisibleTranslate,
    handleFetchSearch,
    keyword,
    jobId,
    everToJapan,
    status,
    percentage,
    recoverMode,
    noCall,
    lookingJobs,
}) => {
    const history = useHistory();

    const { Option } = Select;

    const handleSaveStatus = React.useCallback(
        async (id, status) => {
            if (status === 4) {
                message.error('Not allow update here, please update at his interview status');
                return;
            }
            updateStatus(id, status)
                .then((data) => {
                    if (data.errorCode === 0) {
                        setEmployees(employees.map((item) => (item.id === id ? { ...item, status: status } : item)));
                        message.success('Saved successfully!');
                    } else {
                        message.error(`Failed, ${data.message}`);
                    }
                })
                .catch((error) => {
                    //   setLoading(false);
                    message.error(`Uh oh, request failed: ${error}`);
                });
        },
        [employees, setEmployees]
    );

    const handleUpdateLookingJobs = useCallback(
        async (id, looking_jobs) => {
            try {
                UpdateLookingjobs(id, looking_jobs)
                .then((data) => {
                    if(data.errorCode === 0){
                        setEmployees(employees.map((item) => (item.id === id ? {...item, looking_jobs: looking_jobs} : item)));
                        message.success('Updated successfully!');
                    }else{
                        message.error(`failed, ${data.message}`);
                    }
                }).catch((err) => {
                    message.error(`Uh oh, request failed: ${err}`);
                });
            } catch (error) {
                message.error(`Couldn't update Looking-jobs status. ${error}`);
            }
        },[employees, setEmployees]
    );

    const handleSaveInterviewPayment = useCallback(
        async (id, interviewPaymentStatus) => {
            try {
                const response = await updateInterviewPaymentStatus(id, interviewPaymentStatus);
                if (response) {
                    if (response.errorCode === 0) {
                        setEmployees((prev) =>
                            prev.map((employee) =>
                                employee.id === id
                                    ? { ...employee, interview_payment: interviewPaymentStatus }
                                    : employee
                            )
                        );
                        message.success(`Successfully updated interview status.`)
                    } else {
                        message.error(`Couldn't update interview payment status. ${response.message}.`);
                    }
                } else {
                    message.error(`Couldn't update interview payment status. Response is undefined.`);
                }
            } catch (error) {
                message.error(`Couldn't update interview payment status. ${error}`);
            }
        },
        [setEmployees]
    );

    const handleRecover = (e, item) => {
        e.stopPropagation();
        Modal.confirm({
            title: `Are you sure recover employee ${item.name}?`,
            okText: 'Yes',
            okType: 'primary',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await recoverCandidate(item.id);
                    if (response && response.errorCode === 0) {
                        setEmployees((prevState) => prevState.filter((state) => state.id !== item.id));
                        message.success(`Recover successfully!`);
                    } else {
                        message.error(`Failed! ${response.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request failed!`);
                }
            },
            onCancel() {},
        });
    };

    const handleNote = (e, item) => {
        e.stopPropagation();
        setEmployee(item);
        setVisibleNote(true);
    };

    const handlePassword = (e, item) => {
        e.stopPropagation();
        setEmployee({ ...item, type: 'employee' });
        setVisiblePassword(true);
    };

    const handleTranslate = (e, item) => {
        e.stopPropagation();
        setEmployee(item);
        setVisibleTranslate(true);
    };

    const handleEdit = (e, item) => {
        e.stopPropagation();
        setEmployee(item);
        setVisibleUpsert(true);
    };

    const handleDelete = async (e, item) => {
        e.stopPropagation();
        Modal.confirm({
            title: `Are you sure delete employee ${item.name}?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await deleteCandidate(item.id);
                    if (response && response.errorCode === 0) {
                        setEmployees((prevState) => prevState.filter((state) => state.id !== item.id));
                        message.success(`Deleted successfully!`);
                    } else {
                        message.error(`Failed! ${response.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request failed!`);
                }
            },
            onCancel() {},
        });
    };

    const handleRow = (record, rowIndex) => {
        return {
            onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                history.push(`${ROUTES.CANDIDATES_DETAIL}/${record.id}`, record);
            }, // click row
        };
    };

    const handleChangePagination = (page) => {
        let endPoint = `${ROUTES.CANDIDATES}?page=${page}`;

        if (keyword) {
            endPoint = `${endPoint}&keyword=${keyword}`;
        }

        if (jobId) {
            endPoint = `${endPoint}&job=${jobId}`;
        }

        if (everToJapan) {
            endPoint = `${endPoint}&ever_to_japan=${everToJapan}`;
        }

        if (status) {
            endPoint = `${endPoint}&status=${status}`;
        }

        if (percentage) {
            endPoint = `${endPoint}&percentage=${percentage}`;
        }

        if (recoverMode) {
            endPoint = `${endPoint}&recover_mode=${recoverMode}`;
        }

        if (noCall) {
            endPoint = `${endPoint}&no_call=${noCall}`;
        }

        if(lookingJobs){
            endPoint = `${endPoint}&looking_jobs=${lookingJobs}`;
        }
        
        history.replace(endPoint);
        handleFetchSearch(page, keyword, jobId, everToJapan, status, percentage, recoverMode, noCall, lookingJobs);
    };

    const onTableChange = (_pagination, _filters, sorter) => {
        if (sorter['order']) {
            const field = sorter ? sorter.columnKey : '';
            const order = sorter ? sorter.order : '';
            handleFetchSearch(1, keyword, jobId, everToJapan, status, percentage, recoverMode, noCall, lookingJobs, order, field);
        }
    };

    const handleMenuClick = (event, item) => {
        event.domEvent.stopPropagation();

        switch (event.key) {
            case 'interest':
                setEmployee(item);
                setVisibleInterest(true);
                break;
            case 'background':
                setEmployee(item);
                setVisibleBackground(true);
                break;
            case 'relative':
                setEmployee(item);
                setVisibleRelative(true);
                break;
            case 'call-history':
                setEmployee(item);
                setVisibleCallHistory(true);
                break;
            default:
                break;
        }
    };

    const menu = (item) => (
        <Menu onClick={(event) => handleMenuClick(event, item)}>
            <Menu.Item key="interest">
                <LaptopOutlined />
                Interested Jobs
            </Menu.Item>
            <Menu.Item key="background">
                <HistoryOutlined />
                Background
            </Menu.Item>
            <Menu.Item key="relative">
                <UsergroupAddOutlined />
                Relative
            </Menu.Item>
            <Menu.Item key="call-history">
                <PhoneOutlined />
                Call History
            </Menu.Item>
        </Menu>
    );

    const dataSource = useMemo(
        () =>
            employees?.length
                ? employees.map((employee, index) => {
                      return {
                          ...employee,
                          no: index + 1 + (page - 1) * 15,
                          key: employee.id,
                          birth_date: employee.birth_date,
                          created_date: dateDisplay(employee.created_at),
                          partner: employee.partner ? employee.partner : '',
                      };
                  })
                : [],
        [employees, page]
    );

    const columns = [
        { title: '#', dataIndex: 'no', key: 'updated_at', width: 50, fixed: 'left', sorter: () => {} },
        { title: 'NAME', dataIndex: 'name', key: 'name', fixed: 'left', sorter: () => {} },
        { title: 'CODE', dataIndex: 'code', key: 'code', sorter: () => {} },
        {
            title: 'GENDER',
            dataIndex: 'gender',
            key: 'gender',
            render: (item) => <span>{getTextDisplay(item, dataType.GENDER)}</span>,
            sorter: () => {},
        },
        { title: 'PHONE', dataIndex: 'phone', key: 'phone', sorter: () => {} },
        {
            title: 'CALL',
            dataIndex: 'employee_call_histories',
            key: 'employee_call_histories',
            width: 80,
            align: 'center',
        },
        { title: 'APPLY', dataIndex: 'post_job_employees', key: 'post_job_employees', width: 80 },
        {
            title: 'LOOKING JOBS', key: 'looking_jobs', width: 175,
            render: (item) =>(
                <LodoshaSelect
                    style={{width:145}}
                    value={item.looking_jobs}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    onChange={(value) => handleSelectChangeFromTable(item.key, value, 'looking_jobs')}
                    >   
                    <Option value={0}>
                        -
                    </Option>
                    {dataLookingJobs.map(({value, text}) =>{
                        return <Option key={value} value={value}>
                            {text}
                        </Option>
                    })}
                </LodoshaSelect>
            ),
            sorter: () => {},
        },
        {
            title: 'STATUS',
            width: 175,
            key: 'status',
            render: (item) => (
                <LodoshaSelect
                    style={{ width: 150 }}
                    value={item.status}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    onChange={(value) => handleSelectChangeFromTable(item.key, value, 'status')}
                >
                    {dataEmployeeStatus.map(({ value, text }) => (
                        <Option key={value} value={value}>
                            {text}
                        </Option>
                    ))}
                </LodoshaSelect>
            ),
            sorter: () => {},
        },
        {
            width: 175,
            title: 'INTERVIEW PAYMENT',
            dataIndex: 'interview_payment',
            key: 'interview_payment',
            render: (item, row, index) => {
                return (
                    <LodoshaSelect
                        style={{ width: 150 }}
                        value={row.interview_payment}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        onChange={(value) => handleSelectChangeFromTable(row.id, value, 'interview-payment')}
                    >
                        {dataInterviewPayment.map(({ value, text }) => (
                            <Option key={value} value={value}>
                                {text}
                            </Option>
                        ))}
                    </LodoshaSelect>
                );
            },
            sorter: () => {},
        },
        { title: 'REGISTER DATE', dataIndex: 'created_date', key: 'created_date', width: 120 },
        {
            title: 'INFO',
            dataIndex: 'percentage',
            width: 80,
            key: 'percentage',
            render: (item) => <span>{`${item ? item : 0}%`}</span>,
            sorter: () => {},
        },
        {
            title: '',
            key: 'actions',
            width: 300,
            render: (item) => {
                if (recoverMode === 1) {
                    return <Button type="primary" icon={<RedoOutlined />} onClick={(e) => handleRecover(e, item)} />;
                } else {
                    return (
                        <Space direction="horizontal" size="small">
                            <Dropdown overlay={menu(item)}>
                                <Button
                                    icon={<MoreOutlined />}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                />
                            </Dropdown>
                            <Button type="primary" icon={<BookOutlined />} onClick={(e) => handleNote(e, item)} />
                            <Button type="primary" icon={<KeyOutlined />} onClick={(e) => handlePassword(e, item)} />
                            <Button
                                type="primary"
                                icon={<GlobalOutlined />}
                                onClick={(event) => handleTranslate(event, item)}
                            />
                            <Button type="primary" icon={<EditOutlined />} onClick={(e) => handleEdit(e, item)} />
                            <Button type="danger" icon={<DeleteOutlined />} onClick={(e) => handleDelete(e, item)} />
                        </Space>
                    );
                }
            },
        },
    ];

    const handleSelectChangeFromTable = (id, value, type) => {
        if (type === 'status') {
            handleSaveStatus(id, value);
        } else if (type === 'interview-payment') {
            handleSaveInterviewPayment(id, value);
        } if(type === 'looking_jobs'){
            handleUpdateLookingJobs(id, value);
        }
    };

    return (
        <Table
            bordered
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            scroll={{ x: 1700, y: 500 }}
            onRow={handleRow}
            onChange={onTableChange}
            pagination={{
                onChange: handleChangePagination,
                current: page,
                defaultCurrent: 1,
                defaultPageSize: 15,
                total, 
            }}
        />
    );
};

export default EmployeeTable;
