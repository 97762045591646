import React from 'react';
import { useParams } from 'react-router-dom';
import { Row, Descriptions, Col, message, Spin } from 'antd';
import { Container, LodoshaButton } from '../../components/UI/ui.style';

import ModalUpsert from './ModalUpsert';
import ModalStatus from '../../components/ModalStatus';
import API from '../../utils/api';
import getTextDisplay, { dataType, dataPostStatus } from '../../utils/data';
import moment from 'moment';

const dateFormat = 'DD-MM-YYYY';

const PostJobPage = () => {
    const params = useParams();
    const [loading, setLoading] = React.useState(false);
    const [postJob, setPostJob] = React.useState();
    const [visibleUpsert, setVisibleUpsert] = React.useState(false);
    const [visibleStatus, setVisibleStatus] = React.useState(false);

    const handleFecthPostJob = React.useCallback(async () => {
        setLoading(true);
        try {
            const response = await API().get(`/postjob/detail/${params.id}`);
            if (response?.data.data) {
                const data = response.data.data;
                data.language = data.localizes[0];
                setPostJob(data);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            message.error('Uh oh, request failed!');
        }
    }, [params]);

    React.useEffect(() => {
        handleFecthPostJob();

        return () => {
            // isSubscribed = false;
        };
    }, [handleFecthPostJob]);

    const handleSaveStatus = async (status, text) => {
        const response = await API().patch(`/postjob/status/${postJob.id}`, { status, reason: text });
        if (response.data.errorCode === 0) {
            message.success('Saved successfully!');
            setPostJob((prevState) => ({ ...prevState, status }));
        } else {
            message.error(`Failed, ${response.data.message}`);
        }
        setVisibleStatus(false);
    };

    const items = [
        { label: 'Job Title', name: 'job_title', value: postJob?.job_title }, //, span: 4
        { label: 'Salary From', name: 'salary_from', value: postJob?.salary_from },
        { label: 'Salary To', name: 'salary_to', value: postJob?.salary_to },
        { label: 'Hiring', name: 'hiring', value: postJob?.hiring },

        {
            label: 'Expiration Date',
            name: 'expiration_date',
            value: postJob?.expiration_date ? moment(postJob.expiration_date, 'YYYY-MM-DD').format(dateFormat) : '',
        },
        {
            label: 'Interview Date',
            name: 'interview_date',
            value: postJob?.interview_date ? moment(postJob.interview_date, 'YYYY-MM-DD').format(dateFormat) : '',
        },
        {
            label: 'Candidate Type',
            name: 'candidate_type',
            value: postJob?.candidate_type ? getTextDisplay(postJob.candidate_type, dataType.CANDIDATE_TYPE) : '',
        },
        {
            label: 'Gender',
            name: 'gender',
            value: postJob?.gender ? getTextDisplay(postJob.gender, dataType.GENDER_JOB) : '',
        },
        {
            type: 'item',
            label: 'Working From',
            name: 'working_from',
            value: postJob?.working_from ? moment(postJob.working_from, 'hh:mm:ss').format('hh:mm a') : '',
        },
        {
            type: 'item',
            label: 'Working To',
            name: 'working_to',
            value: postJob?.working_to ? moment(postJob.working_to, 'hh:mm:ss').format('hh:mm a') : '',
        },
        {
            type: 'item',
            label: 'Working Day',
            name: 'working_day',
            value: postJob
                ? postJob.working_day && postJob.working_day === 6
                    ? postJob.working_day_description
                    : getTextDisplay(postJob.working_day, dataType.WORKING_DAY)
                : '',
        }, // span: 2,

        { label: 'From Age', name: 'from_age', value: postJob?.from_age }, //, span: 2
        { label: 'To Age', name: 'to_age', value: postJob?.to_age }, //, span: 2
        {
            label: 'Work Duration',
            name: 'work_duration',
            value: postJob?.work_duration ? getTextDisplay(postJob.work_duration, dataType.WORK_DURATION) : '',
        },
        {
            label: 'Finish Education',
            name: 'finish_education',
            value: postJob?.finish_education ? getTextDisplay(postJob.finish_education, dataType.FINISH_EDUCATION) : '',
        },
        {
            label: 'Japanese Level',
            name: 'japanese_level',
            value: postJob?.japanese_level ? getTextDisplay(postJob.japanese_level, dataType.JAPANESE_LEVEL) : '',
        },
        {
            label: 'Location',
            name: 'location_description',
            value: postJob?.location_description, // span: 2
        },
        {
            label: 'Country',
            name: 'country',
            value: postJob ? getTextDisplay(postJob?.country, dataType.COUNTRY) : '', // span: 2
        },

        {
            label: 'Company/Partner',
            name: '',
            value: postJob?.employer ? postJob?.employer.company_name : postJob?.partner ? postJob.partner?.name : '',
        },
        { label: 'Job', name: 'job_id', value: postJob?.job?.name },
        {
            label: 'Document',
            name: 'document',
            value: postJob?.document, //  span: 4,
        },
        {
            label: 'Other Condition',
            name: 'job_condition',
            value: postJob?.job_condition,
        }, // span: 4,
    ];

    const translateItems = [
        { type: 'item', label: 'Job Title', name: 'job_title', value: postJob?.language?.job_title, span: 4 },
        {
            type: 'item',
            label: 'Working Day',
            name: 'working_day',
            value: postJob
                ? postJob.working_day === 6
                    ? postJob.language && postJob.language.working_day_description
                    : getTextDisplay(postJob.working_day, dataType.WORKING_DAY)
                : '',
            span: 4,
        },
        {
            type: 'item',
            label: 'Document',
            name: 'document',
            value: postJob?.language?.document,
            span: 4,
        },
        {
            type: 'item',
            label: 'Other Requirement',
            name: 'job_requirement',
            value: postJob?.language?.job_requirement,
            span: 4,
        },
    ];

    console.log('postJob', postJob);

    return (
        <Container>
            <Spin tip="Loading..." spinning={loading}>
                <Row>
                    <Col span={24}>
                        <Descriptions title="Base Information" bordered column={4}>
                            {items.map((item) => (
                                <Descriptions.Item key={item.name} label={item.label} span={item.span}>
                                    {item.value}
                                </Descriptions.Item>
                            ))}
                        </Descriptions>
                    </Col>
                    <Col span={24} style={{ marginTop: 24 }}>
                        <Descriptions title="Language Information" bordered column={4}>
                            {translateItems.map((item) => (
                                <Descriptions.Item key={item.name} label={item.label} span={item.span}>
                                    {item.value}
                                </Descriptions.Item>
                            ))}
                        </Descriptions>
                    </Col>
                </Row>
                <Row
                    style={{
                        position: 'sticky',
                        bottom: '0',
                        marginTop: '40px',
                        padding: '20px 0',
                        background: '#FAFAFA',
                    }}
                >
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <LodoshaButton
                            type="primary"
                            style={{ marginRight: 16 }}
                            onClick={() => setVisibleUpsert(true)}
                        >
                            Update
                        </LodoshaButton>
                        <LodoshaButton onClick={() => setVisibleStatus(true)}>
                            {getTextDisplay(postJob?.status, dataType.POST_STATUS)}
                        </LodoshaButton>
                        {visibleUpsert && (
                            <ModalUpsert
                                visible={visibleUpsert}
                                setVisible={setVisibleUpsert}
                                postJob={postJob}
                                onRefresh={handleFecthPostJob}
                            />
                        )}
                        {visibleStatus && (
                            <ModalStatus
                                visible={visibleStatus}
                                setVisible={setVisibleStatus}
                                status={postJob.status}
                                rejection={postJob.rejection}
                                steps={dataPostStatus}
                                onSave={handleSaveStatus}
                            />
                        )}
                    </Col>
                </Row>
            </Spin>
        </Container>
    );
};

export default PostJobPage;
