import React, { useState } from 'react';

import { Modal, Form, message, Row, Col, Select } from 'antd';

//utils and constant
import { addNewMembershipType, updateMembershipType } from '../../utils/api/membership';
import { LodoshaButton, LodoshaInput } from '../../components/UI/ui.style';
import { membershipType } from '../../utils/data';

export default function MembershipModal({
    isUpdate,
    isCreate,
    visible,
    setVisible,
    postData,
    onRefresh,
    title,
    setPostData,
}) {
    const [form] = Form.useForm();
    const { Option } = Select;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(postData);

    const membershipFormFields = [
        {
            type: 'text',
            label: 'Name',
            name: 'name',
            value: data?.name,
            span: 24,
            required: true,
        },
        {
            type: 'text',
            label: 'Type',
            name: 'type',
            value: data?.type,
            span: 24,
            required: true,
            optionList: membershipType,
            isSelect: true,
        },
        {
            type: 'number',
            label: 'Price',
            name: 'price',
            value: data?.price,
            span: 24,
            required: true,
        },
        {
            type: 'number',
            label: 'View Candidate Detail',
            name: 'view_count',
            value: data?.view_count,
            span: 24,
            required: true,
        },
        {
            type: 'number',
            label: 'Interview',
            name: 'interview_count',
            value: data?.interview_count,
            span: 24,
            required: true,
        },
    ];

    const handleSubmit = async (e) => {
        console.log(data);
        try {
            setLoading(true);
            const response = isCreate
                ? await addNewMembershipType(data)
                : isUpdate
                ? await updateMembershipType(data, data?.id)
                : null;
            if (response && response.errorCode !== 0) {
                setLoading(false);
                message.error(
                    `Couldn't ${isCreate ? 'create new type of' : 'update'} membership.errorCode: ${
                        response.errorCode
                    }, message: ${response.message}`
                );
                return;
            }
            if (response) {
                setLoading(false);
                setVisible(false);
                message.success(`Successfully ${isCreate ? 'created new type of' : 'updated'} membership.`);
                onRefresh();
            } else {
                setLoading(false);
                message.error(`Response is undefined`);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            message.error(`Something went wrong.errorCode: ${error.errorCode}, message: ${error.message}`);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleChangeMembershipType = (value) => {
        setData((prev) => ({
            ...prev,
            type: value,
        }));
    };

    const handleCancel = () => {
        setVisible(false);
        setPostData([]);
    };
    return (
        <Modal visible={visible} onCancel={handleCancel} title={title} maskClosable={false} centered footer={false}>
            <Form form={form} onFinish={handleSubmit} layout="vertical">
                <Row gutter={[16]}>
                    {membershipFormFields.map((field, index) => (
                        <Col span={field.span} key={index}>
                            <Form.Item
                                rules={[{ required: field.required, message: 'Data required!' }]}
                                label={`${field.label}:`}
                                name={field.name}
                                initialValue={field.value}
                            >
                                {field.isSelect ? (
                                    <Select
                                        placeholder="Please select: "
                                        onChange={handleChangeMembershipType}
                                        name={field.name}
                                        size="large"
                                    >
                                        {field.optionList?.map((type, index) => (
                                            <Option key={index} value={type.value}>
                                                {type.text}
                                            </Option>
                                        ))}
                                    </Select>
                                ) : (
                                    <LodoshaInput
                                        type={field.type}
                                        disabled={field.disabled && true}
                                        onChange={handleChange}
                                        value={field.value}
                                        name={field.name}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    ))}
                    <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
                        <LodoshaButton
                            key="cancel"
                            style={{ marginRight: 16 }}
                            htmlType="button"
                            onClick={handleCancel}
                        >
                            Cancel
                        </LodoshaButton>
                        <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                            {isCreate ? 'Add New' : isUpdate ? 'Update' : ''}
                        </LodoshaButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
