import styled from "@emotion/styled";

const TitleWrapper = styled.div`
  border-bottom: 2px solid #f1f1f1;
  /* padding-top: 10px; */
  padding-bottom: 8px;
  margin-bottom: 8px;

  h2 {
    margin: 0;
  }
`;

export { TitleWrapper };
