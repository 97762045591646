import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Descriptions, Row, Col, Button, Spin, Empty } from "antd";
import { getComponents } from "../../components/Components";
import getTextDisplay, { dataFavoriteStatus, dataType } from "../../utils/data";
import moment from "moment";
import API from "../../utils/api";
import StatusPopUp from "../../components/StatusPopUp";

const Favorite = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [favorite, setFavorite] = useState();
  const [visibleStatusModel, setVisibleStatusModel] = useState(false);
  const [status, setStatus] = useState();

  useEffect(() => {
    const handleFetchFavorite = async () => {
      setLoading(true);
      const response = await API().post(`/favorite/${params.id}`);
      // console.log(response);
      if (response.data.data) {
        setFavorite(response.data.data);
        setStatus(response.data.data.status);
      }
      setLoading(false);
    };

    handleFetchFavorite();
  }, [params.id]);

  const employee = favorite?.employee;
  const postJob = favorite?.post_job;
  const employer = favorite?.employer;

  const jobItems = [
    { type: "item", label: "Job Title", name: "job_title", value: postJob ? postJob.job_title : "", span: 4 },
    { type: "item", label: "Salary", name: "salary", value: postJob ? postJob.salary : "", span: 2 },
    { type: "item", label: "Hiring", name: "hiring", value: postJob ? postJob.hiring : "" },
    {
      type: "item",
      label: "Expiration Date",
      name: "expiration_date",
      value: postJob ? moment(postJob.expiration_date, "YYYY-MM-DD").format("DD-MM-YYYY") : "",
    },
    {
      type: "item",
      label: "Job Type",
      name: "job_type",
      value: postJob ? getTextDisplay(postJob.job_type, dataType.JOB_TYPE) : "",
    },
    {
      type: "item",
      label: "Job Level",
      name: "job_level",
      value: postJob ? getTextDisplay(postJob.job_level, dataType.JOB_LEVEL) : "",
    },
    { type: "item", label: "Working Hour", name: "working_hour", value: postJob ? postJob.working_hour : "" },
    { type: "item", label: "Working Day", name: "working_day", value: postJob ? postJob.working_day : "" },
    {
      type: "item",
      label: "Job Description",
      name: "job_description",
      value: postJob ? postJob.job_description : "",
      span: 4,
    },
    {
      type: "item",
      label: "Job Resposibility",
      name: "job_resposibility",
      value: postJob ? postJob.job_resposibility : "",
      span: 4,
    },
    {
      type: "item",
      label: "Job Requirement",
      name: "job_requirement",
      value: postJob ? postJob.job_requirement : "",
      span: 4,
    },
    { type: "item", label: "Email", name: "email", value: postJob ? postJob.email : "" },
    { type: "item", label: "Job", name: "job_id", value: postJob ? postJob.job.name : "" },
  ];

  const employerItems = [
    {
      type: "item",
      label: "Company Name",
      name: "company_name",
      value: employer ? employer.company_name : "",
      isLanguage: true,
    },
    {
      type: "item",
      label: "Representation Name",
      name: "representation_name",
      value: employer ? employer.representation_name : "",
      isLanguage: true,
    },
    { type: "item", label: "Gender", name: "gender", value: employer ? employer.gender : "", isLanguage: true },
    { type: "item", label: "Phone", name: "phone", value: employer ? employer.phone : "" },
    { type: "item", label: "Email", name: "email", value: employer ? employer.email : "" },
    { type: "item", label: "Country", name: "country", value: employer ? employer.country : "", isLanguage: true },
    { type: "item", label: "Employee Count", name: "employee_count", value: employer ? employer.employee_count : "" },
    { type: "item", label: "Created Year", name: "created_year", value: employer ? employer.created_year : "" },
    {
      type: "item",
      label: "Industry Type",
      name: "industry_type",
      value: employer ? employer.industry_type : "",
      isLanguage: true,
    },
    { type: "item", label: "Address", name: "address", value: employer ? employer.address : "", isLanguage: true },
  ];

  const employeeItems = [
    { type: "item", label: "Name", name: "name", value: employee ? employee.name : "", span: 2 },
    { type: "item", label: "Gender", name: "gender", value: employee ? employee.gender : "" },
    { type: "item", label: "Nationality", name: "country", value: employee ? employee.nationality : "" },
    { type: "item", label: "Phone", name: "phone", value: employee ? employee.phone : "" },
    {
      type: "item",
      label: "Birth Date",
      name: "birth_date",
      value: employee ? moment(employee.birth_date, "YYYY-MM-DD").format("DD-MM-YYYY") : "",
    },
    { type: "item", label: "Birth Place", name: "birth_place", value: employee ? employee.birth_place : "", span: 2 },
    { type: "item", label: "Address", name: "address", value: employee ? employee.address : "", span: 4 },
  ];

  const handleUpdateStatus = async (id, status) => {
    id && (await API().patch(`/favorite/status/${id}`, { status: status }));
    // fetchData();
  };

  const handleSaveStatus = (newStatus) => {
    setVisibleStatusModel(false);
    handleUpdateStatus(favorite.id, newStatus);
  };

  if (!favorite && !loading) {
    return (
      <div
        style={{
          background: "#fff",
          padding: 24,
          minHeight: 380,
          marginBottom: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Empty />
      </div>
    );
  }

  return (
    <div style={{ background: "#fff", padding: 24, minHeight: 380, marginBottom: "24px" }}>
      <Spin tip="Loading..." spinning={loading}>
        <StatusPopUp
          name={employee ? employee.name : "N/A"}
          status={status}
          data={dataFavoriteStatus}
          errorStatueStep={7}
          setStatus={setStatus}
          visible={visibleStatusModel}
          setVisible={setVisibleStatusModel}
          handleSaveStatus={handleSaveStatus}
        />

        <Descriptions title="Employee Information" bordered column={4}>
          {employeeItems.map((data) => getComponents(data))}
        </Descriptions>

        {postJob ? (
          <Descriptions style={{ marginTop: "25px" }} title="Job Information" bordered column={4}>
            {jobItems.map((data) => getComponents(data))}
          </Descriptions>
        ) : (
          <div />
        )}

        {employer ? (
          <Descriptions style={{ marginTop: "25px" }} title="Employer Information" bordered column={4}>
            {employerItems.map((data) => getComponents(data))}
          </Descriptions>
        ) : (
          <div />
        )}

        <Row
          type="flex"
          justify="center"
          style={{ position: "sticky", bottom: "0", marginTop: "40px", padding: "20px", background: "#FAFAFA" }}
        >
          <Col>
            <Button
              type="primary"
              style={{ height: 38, width: 108 }}
              onClick={() => {
                setVisibleStatusModel(true);
              }}
            >
              {getTextDisplay(status, dataType.FAVORITE_STATUS)}
            </Button>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default Favorite;
