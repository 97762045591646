import React, { useState, useEffect, useRef, useCallback } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { message, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Container, LodoshaButton, LodoshaSwitch } from '../../components/UI/ui.style';
import { useHistory } from 'react-router-dom';
import API from '../../utils/api';
import Search from './Search';
import Table from './Table';
import ModalBackground from './ModalBackground';
import ModalInterest from './ModalInterest';
import ModalRelative from './ModalRelative';
import ModalNote from './ModalNote';
import ModalPassword from './ModalPassword';
import ModalTranslate from './ModalTranslate';
import ModalUpsert from './ModalUpsert';
import ModalCallHistory from '../../components/ModalCallHistory';
import { getAutoDescriptions } from '../../utils/api/auto_description';

const timeout_typing_state = { typingTimeout: 0 };

const initialState = {
    nationality: '855',
    ever_to_japan: 0,
    eye_problem: 0,
    conversation_japan: 0,
    is_work: 0,
};

const EmployeesPage = () => {
    const location = useLocation();
    const history = useHistory();

    const page = location.search
        ? queryString.parse(location.search).page
            ? Number(queryString.parse(location.search).page)
            : 1
        : 1;
    const qKeyword = location.search ? queryString.parse(location.search).keyword : '';
    const qJobId = location.search ? queryString.parse(location.search).jobId : '';
    const qEverToJapan = location.search
        ? queryString.parse(location.search).everToJapan
            ? Number(queryString.parse(location.search).everToJapan)
            : null
        : null;
    const qStatus = location.search
        ? queryString.parse(location.search).status
            ? Number(queryString.parse(location.search).status)
            : null
        : null;
    const qPercentage = location.search
        ? queryString.parse(location.search).percentage
            ? Number(queryString.parse(location.search).percentage)
            : null
        : null;
    const qRecoverMode = location.search
        ? queryString.parse(location.search).recover_mode
            ? Number(queryString.parse(location.search).recover_mode)
            : 0
        : 0;
    const qNoCall = location.search
        ? queryString.parse(location.search).no_call
            ? Number(queryString.parse(location.search).no_call)
            : null
        : null;
    const qLookingjobs = location.search
        ? queryString.parse(location.search).looking_jobs
            ? Number(queryString.parse(location.search).looking_jobs)
            : null
        : null;

    const isSubscribed = useRef(true);
    const [loading, setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [autoDescriptions, setAutoDescriptions] = useState({});
    const [total, setTotal] = useState(0);
    const [employee, setEmployee] = useState(initialState);
    const [visibleUpsert, setVisibleUpsert] = useState(false);
    const [visibleTranslate, setVisibleTranslate] = useState(false);
    const [visibleInterest, setVisibleInterest] = useState(false);
    const [visibleBackground, setVisibleBackground] = useState(false);
    const [visibleRelative, setVisibleRelative] = useState(false);
    const [visibleNote, setVisibleNote] = useState(false);
    const [visiblePassword, setVisiblePassword] = useState(false);
    const [visibleCallHistory, setVisibleCallHistory] = useState(false);
    const [loadingAutoDescription, setLoadingAutoDescription] = useState(false);
    const [timeoutTyping, setTimeoutTyping] = useState(timeout_typing_state);

    const [keyword, setKeyword] = useState(qKeyword);
    const [jobId, setJobId] = useState(qJobId);
    const [everToJapan, setEverToJapan] = useState(qEverToJapan);
    const [status, setStatus] = useState(qStatus);
    const [percentage, setPercentage] = useState(qPercentage);
    const [recoverMode, setRecoverMode] = useState(qRecoverMode);
    const [noCall, setNoCall] = useState(qNoCall);
    const [lookingJobs, setLookingJobs] = useState(qLookingjobs);

    const handleFetchSelect = useCallback(async () => {
        setLoadingAutoDescription(true);
        try {
            const response = await getAutoDescriptions();
            if (response) {
                if (response.errorCode === 0) {
                    setAutoDescriptions(response.data);
                    setLoadingAutoDescription(false);
                    console.log("index aurodescription", response.data)
                }
            }
        } catch (error) {
            message.error(error?.message);
            setLoadingAutoDescription(false);
        }
    }, []);

    const handleSearch = (value) => {
        setKeyword(value);

        if (timeoutTyping.typingTimeout) {
            clearTimeout(timeoutTyping.typingTimeout);
        }

        setTimeoutTyping({
            typingTimeout: setTimeout(() => {
                handleFetchSearch(1, value, jobId, everToJapan, status, percentage, recoverMode, noCall, lookingJobs);
            }, 1000),
        });
    };

    const handleFilter = (name, value) => {
        if (name === 'job') {
            setJobId(value);
            handleFetchSearch(1, keyword, value, everToJapan, status, percentage, recoverMode, noCall, lookingJobs);
        } else if (name === 'ever_to_japan') {
            setEverToJapan(value);
            handleFetchSearch(1, keyword, jobId, value, status, percentage, recoverMode, noCall, lookingJobs);
        } else if (name === 'status') {
            handleFetchSearch(1, keyword, jobId, everToJapan, value, percentage, recoverMode, noCall, lookingJobs);
            setStatus(value);
        } else if (name === 'percentage') {
            handleFetchSearch(1, keyword, jobId, everToJapan, status, value, recoverMode, noCall, lookingJobs);
            setPercentage(value);
        } else if (name === 'no_call') {
            handleFetchSearch(1, keyword, jobId, everToJapan, status, percentage, recoverMode, value, lookingJobs);
            setNoCall(value);
        } else if(name === 'looking_jobs'){
            handleFetchSearch(1, keyword, jobId, everToJapan, status, percentage, recoverMode, noCall, value);
            setLookingJobs(value);
        }

        initQueryURL(name, value);
    };

    const initQueryURL = useCallback(
        (name, value, page = 1) => {
            let currentQuery = {};
            currentQuery['page'] = page;

            if (keyword) {
                currentQuery['keyword'] = keyword;
            }

            if (jobId) {
                currentQuery['job'] = jobId;
            }

            if (everToJapan) {
                currentQuery['ever_to_japan'] = everToJapan;
            }

            if (status) {
                currentQuery['status'] = status;
            }

            if (percentage) {
                currentQuery['percentage'] = percentage;
            }

            if (recoverMode) {
                currentQuery['recover_mode'] = recoverMode;
            }

            if (noCall) {
                currentQuery['no_call'] = noCall;
            }

            if(lookingJobs){
                currentQuery['looking_jobs'] = lookingJobs;
            }

            if (value) {
                currentQuery[name] = value;
            } else {
                delete currentQuery[name];
            }

            let endPoint = history.location;
            endPoint.search = queryString.stringify(currentQuery);

            history.replace(endPoint);
        },
        [everToJapan, history, jobId, keyword, percentage, status, recoverMode, noCall, lookingJobs]
    );

    const handleFetchSearch = useCallback(
        async (
            page = 1,
            keyWord,
            jobId,
            everToJapan,
            status,
            percentage,
            recover_mode,
            no_call,
            looking_jobs,
            order = '',
            field = '',
        ) => {
            setLoading(true);
            const endPoint =
                page === 1
                    ? `/employee/search?keyword=${keyWord}&job_id=${jobId}&ever_to_japan=${everToJapan}&status=${status}&percentage=${percentage}&recover_mode=${recover_mode}&order=${order}&field=${field}&no_call=${no_call}&looking_jobs=${looking_jobs}`
                    : `/employee/search?keyword=${keyWord}&job_id=${jobId}&ever_to_japan=${everToJapan}&status=${status}&percentage=${percentage}&recover_mode=${recover_mode}&no_call=${no_call}&looking_jobs=${looking_jobs}&offset=${
                          (page - 1) * 15
                      }&order=${order}&field=${field}`;
            const response = await API().get(endPoint);
            setEmployees(response.data?.data);
            setTotal(response.data?.total);
            setLoading(false);
        },
        []
    );
    useEffect(() => {
        handleFetchSelect();
    }, [handleFetchSelect]);

    useEffect(() => {
        initQueryURL('', '', page);
    }, [page, initQueryURL]);

    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchSearch(page, qKeyword, qJobId, qEverToJapan, qStatus, qPercentage, qRecoverMode, qNoCall, qLookingjobs);
        }
        return () => {
            isSubscribed.current = false;
        };
    }, [ page, qEverToJapan, qJobId, qStatus, qKeyword, qPercentage, qRecoverMode, qNoCall, qLookingjobs, handleFetchSearch, initQueryURL ]);

    const handleCreate = () => {
        setEmployee(initialState);
        setVisibleUpsert(true);
    };

    const onSwitchDeleteChange = (value) => {
        setRecoverMode(value);
        initQueryURL('recover_mode', value);
        handleFetchSearch(1, keyword, jobId, everToJapan, status, percentage, value, noCall, lookingJobs);
    };

    return (
        <Container>
            <Search
                onSearch={handleSearch}
                onFilter={handleFilter}
                keyword={keyword}
                jobId={jobId}
                everToJapan={everToJapan}
                status={status}
                percentage={percentage}
                noCall={noCall}
                lookingJobs={lookingJobs}
            />
            <Row style={{ marginTop: 24, marginBottom: 32 }} align="middle">
                <LodoshaButton
                    type="primary"
                    size="large"
                    icon={<PlusOutlined />}
                    onClick={handleCreate}
                    loading={loadingAutoDescription}
                >
                    New
                </LodoshaButton>

                <div style={{ marginLeft: 40 }}>
                    <p style={{ margin: 0 }}>Recover Mode</p>{' '}
                    <LodoshaSwitch checked={recoverMode} onChange={onSwitchDeleteChange} />
                </div>

                <ModalUpsert
                    visible={visibleUpsert}
                    setVisible={setVisibleUpsert}
                    page={page}
                    employee={employee}
                    setEmployee={setEmployee}
                    setEmployees={setEmployees}
                    onRefresh={handleFetchSearch}
                    autoDescriptions={autoDescriptions}
                />
            </Row>
            <ModalInterest visible={visibleInterest} setVisible={setVisibleInterest} employee={employee} />
            <ModalBackground visible={visibleBackground} setVisible={setVisibleBackground} employee={employee} />
            <ModalRelative visible={visibleRelative} setVisible={setVisibleRelative} employee={employee} />
            <ModalCallHistory visible={visibleCallHistory} setVisible={setVisibleCallHistory} employee={employee} />
            <ModalNote
                visible={visibleNote}
                setVisible={setVisibleNote}
                employee={employee}
                onRefresh={handleFetchSearch}
            />
            <ModalPassword visible={visiblePassword} setVisible={setVisiblePassword} data={employee} />
            <ModalTranslate
                visible={visibleTranslate}
                setVisible={setVisibleTranslate}
                employee={employee}
                onRefresh={handleFetchSearch}
            />

            <Table
                loading={loading}
                page={page}
                total={total}
                employees={employees}
                setEmployees={setEmployees}
                setEmployee={setEmployee}
                setVisibleUpsert={setVisibleUpsert}
                setVisibleInterest={setVisibleInterest}
                setVisibleBackground={setVisibleBackground}
                setVisibleRelative={setVisibleRelative}
                setVisibleCallHistory={setVisibleCallHistory}
                setVisibleNote={setVisibleNote}
                setVisiblePassword={setVisiblePassword}
                setVisibleTranslate={setVisibleTranslate}
                handleFetchSearch={handleFetchSearch}
                keyword={keyword}
                jobId={jobId}
                everToJapan={everToJapan}
                status={status}
                percentage={percentage}
                recoverMode={recoverMode}
                noCall={noCall}
                lookingJobs={lookingJobs}
            />
        </Container>
    );
};

export default EmployeesPage;
