import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Table, Button, Space, Modal, message } from 'antd';
import {
    PlusOutlined,
    FileImageOutlined,
    TeamOutlined,
    KeyOutlined,
    GlobalOutlined,
    EditOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import { Container, LodoshaButton } from '../../components/UI/ui.style';
import Search from '../../components/Search';
import ModalStatus from '../../components/ModalStatus';
import ModalPassword from '../../components/ModalPassword';
import ModalUpsert from './ModalUpsert';
import ModalGallery from './ModalGallary';
import ModalTranslate from './ModalTranslate';
import API from '../../utils/api';
import queryString from 'query-string';
import getTextDisplay, { dataType, dataIndustry, dataEmployerStatus } from '../../utils/data';

import * as ROUTES from '../../constants/routes';

const timeout_typing_state = { typingTimeout: 0 };

const Employers = () => {
    const history = useHistory();
    const location = useLocation();
    const page = location.search ? Number(queryString.parse(location.search).page) : 1;

    const [loading, setLoading] = useState(false);
    const [employers, setEmployers] = useState([]);
    const [total, setTotal] = useState(0);
    const [employer, setEmployer] = useState();
    const [timeoutTyping, setTimeoutTyping] = useState(timeout_typing_state);
    const [keyword, setKeyword] = useState('');
    const [industryCode, setIndustryCode] = useState();
    const [visibleUpsert, setVisibleUpsert] = useState(false);
    const [visibleStatus, setVisibleStatus] = useState(false);
    const [visibleGallery, setVisibleGallery] = useState(false);
    const [visiblePassword, setVisiblePassword] = useState(false);
    const [visibleTranslate, setVisibleTranslate] = useState(false);

    const handleFetchEmployers = async (page = 1, order = '', field = '') => {
        setLoading(true);
        try {
            const response =
                page === 1
                    ? await API().get(`/employer?order=${order}&field=${field}`)
                    : await API().get(`/employer?order=${order}&field=${field}&offset=${(page - 1) * 15}`);

            response.data?.data && setEmployers(response.data.data);
            setTotal(response.data.total);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error('Uh oh, request failed!');
        }
    };

    useEffect(() => {
        handleFetchEmployers(page);
    }, [page]);

    const startSearch = async (keyWord, industryCode) => {
        setLoading(true);
        const response = await API().get(
            `/employer/search?keyword=${keyWord ? keyWord : ''}&industry_code=${industryCode}`
        );
        response.data?.data && setEmployers(response.data.data);
        setLoading(false);
    };

    const handleSearch = (value) => {
        setKeyword(value);

        if (timeoutTyping.typingTimeout) {
            clearTimeout(timeoutTyping.typingTimeout);
        }

        setTimeoutTyping({
            typingTimeout: setTimeout(function () {
                startSearch(value, industryCode);
            }, 1000),
        });
    };

    const handleFilter = (value) => {
        setIndustryCode(value);
        startSearch(keyword, value);
    };

    const handleNew = () => {
        setEmployer();
        setVisibleUpsert(true);
    };

    const handleGallery = (e, item) => {
        e.stopPropagation();
        setEmployer(item);
        setVisibleGallery(true);
    };

    const handleEmployerEmployees = (e, item) => {
        e.stopPropagation();
        history.push(`${ROUTES.COMPANY_CANDIDATES}/${item.id}`, item);
    };

    const handlePassword = (e, item) => {
        e.stopPropagation();
        setEmployer({ ...item, type: 'employer' });
        setVisiblePassword(true);
    };

    const handleStatus = (e, item) => {
        e.stopPropagation();
        setEmployer(item);
        setVisibleStatus(true);
    };

    const handleSaveStatus = async (status) => {
        await API().patch(`/employer/status/${employer.id}`, { status });
        setVisibleStatus(false);
        handleFetchEmployers();
    };

    const handleTranslate = (e, item) => {
        setEmployer(item);
        setVisibleTranslate(true);
    };

    const handleEdit = (e, item) => {
        e.stopPropagation();
        setEmployer(item);
        setVisibleUpsert(true);
    };

    const handleDelete = async (e, item) => {
        e.stopPropagation();
        Modal.confirm({
            title: `Are you sure delete ${item.company_name}?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const response = await API().delete(`/employer/${item.id}`);

                if (response.data.errorCode === 0) {
                    message.success(`Deleted successfully!`);
                    handleFetchEmployers();
                } else {
                    message.error(`Failed! ${response.data.message}`);
                }
            },
            onCancel() {},
        });
    };

    const handleRow = (record) => {
        return {
            onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                history.push(`${ROUTES.COMPANIES_DETAIL}/${record.id}`, record);
            }, // click row
        };
    };

    const handleChangePagination = (page) => {
        history.replace(`${ROUTES.COMPANIES}${page !== 1 ? `?page=${page}` : ''}`);
    };

    const handleChangeTable = (_pagination, _filters, sorter) => {
        if (sorter) {
            const field = sorter ? sorter.columnKey : "";
            const order = sorter ? sorter.order : "";
            handleFetchEmployers(page, order, field);
        }
    };
    const items = [
        { label: 'Search:', type: 'text', value: keyword, onSearch: handleSearch },
        {
            label: 'Filter by Industry:',
            type: 'select',
            options: dataIndustry,
            onSelect: (value) => handleFilter(value),
        },
    ];

    const dataSource = useMemo(() => {
        return employers.length
            ? employers.map((employer, index) => ({ ...employer, key: employer.id, no: index + 1 + (page - 1) * 15 }))
            : [];
    }, [employers, page]);

    const columns = [
        { title: '#', dataIndex: 'no', key: 'updated_at', width: 50, fixed: 'left', sorter: () => {} },
        { title: 'COMPANY', dataIndex: 'company_name', key: 'company_name', fixed: 'left', sorter: () => {} },
        {
            title: 'REPRESENTATION',
            dataIndex: 'representation_name',
            key: 'representation_name',
            sorter: () => {},
        },
        {
            title: 'GENDER',
            dataIndex: 'gender',
            key: 'gender',
            width: 100,
            sorter: () => {},
            render: (value) => getTextDisplay(Number(value), dataType.GENDER),
        },
        { title: 'PHONE', dataIndex: 'phone', key: 'phone', width: 120, sorter: () => {} },
        { title: 'EMAIL', dataIndex: 'email', key: 'email', sorter: () => {} },
        {
            title: 'STATUS',
            key: 'status',
            width: 120,
            render: (item) => (
                <Button type="link" onClick={(e) => handleStatus(e, item)}>
                    <u>{getTextDisplay(item.status, dataType.EMPLOYER_STATUS)}</u>
                </Button>
            ),
            sorter: () => {},
        },
        {
            title: '',
            key: 'actions',
            width: 300,
            render: (item) => (
                <Space direction="horizontal" size="small">
                    <Button type="primary" icon={<FileImageOutlined />} onClick={(e) => handleGallery(e, item)} />
                    <Button type="primary" icon={<TeamOutlined />} onClick={(e) => handleEmployerEmployees(e, item)} />
                    <Button type="primary" icon={<KeyOutlined />} onClick={(e) => handlePassword(e, item)} />
                    <Button type="primary" icon={<GlobalOutlined />} onClick={(e) => handleTranslate(e, item)} />
                    <Button type="primary" icon={<EditOutlined />} onClick={(e) => handleEdit(e, item)} />
                    <Button type="danger" icon={<DeleteOutlined />} onClick={(e) => handleDelete(e, item)} />
                </Space>
            ),
        },
    ];

    return (
        <Container>
            <Search items={items} />
            <Row style={{ marginTop: 24, marginBottom: 32 }}>
                <LodoshaButton type="primary" icon={<PlusOutlined />} onClick={handleNew}>
                    New
                </LodoshaButton>
                {visibleUpsert && (
                    <ModalUpsert
                        visible={visibleUpsert}
                        setVisible={setVisibleUpsert}
                        employer={employer}
                        setEmployer={setEmployer}
                        setEmployers={setEmployers}
                        onRefresh={handleFetchEmployers}
                        page={page}
                    />
                )}
            </Row>
            {visibleStatus && (
                <ModalStatus
                    visible={visibleStatus}
                    setVisible={setVisibleStatus}
                    status={employer.status}
                    steps={dataEmployerStatus}
                    onSave={handleSaveStatus}
                />
            )}
            {visibleGallery && (
                <ModalGallery visible={visibleGallery} setVisible={setVisibleGallery} employer={employer} />
            )}
            {visiblePassword && (
                <ModalPassword visible={visiblePassword} setVisible={setVisiblePassword} data={employer} />
            )}
            {visibleTranslate && (
                <ModalTranslate visible={visibleTranslate} setVisible={setVisibleTranslate} employer={employer} />
            )}
            <Table
                bordered
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                onRow={handleRow}
                scroll={{ x: 1300 }}
                onChange={handleChangeTable}
                pagination={{
                    onChange: handleChangePagination,
                    current: page,
                    defaultCurrent: 1,
                    defaultPageSize: 15,
                    total,
                }}
            />
        </Container>
    );
};

export default Employers;

// import React, { Fragment, useState, useEffect, useRef } from 'react';
// import getTextDisplay, { dataType, dataEmployerStatus } from '../../utils/data';

// import { withRouter, Link } from 'react-router-dom';
// import { Modal, message, Button, Table, Select, Input, Icon, Col, Row } from 'antd';
// import { dataIndustry } from '../../utils/data';
// import { withAuthSync } from '../../contexts/AuthContext';

// import API from '../../utils/api';
// import StatusPopUp from '../../components/StatusPopUp';
// import GallaryPopUp from './GallaryPopUp';
// import PasswordPopUp from '../../components/PasswordPopUp';

// import './style.less';

// function Employer(props) {

//     const timeout_typing_state = {
//         typingTimeout: 0
//     }

//     const [loading, setLoading] = useState(false);
//     const [data, setData] = useState();
//     const [timeoutTyping, setTimeoutTyping] = useState(timeout_typing_state);
//     const [keyWord, setKeyWord] = useState('');
//     const [industryCode, setIndustryCode] = useState(null);
//     const [visiblePasswords, setVisiblePasswords] = useState(false);
//     const [sendPasswordData, setSendPasswordData] = useState();
//     const [page, setPage] = useState(1);
//     const [visibleStatusModel, setVisibleStatusModel] = useState(false);
//     const [visibleGalleryModel, setVisibleGalleryModel] = useState(false);
//     const [itemStatus, setItemStatus] = useState({});
//     const [status, setStatus] = useState(1);
//     const [employerId, setEmployerId] = useState();

//     const isSubscribed = useRef(true);

//     useEffect(() => {
//         if (isSubscribed.current) {
//             fetchData();
//         };
//         return () => {
//             isSubscribed.current = false;
//         };
//     }, [isSubscribed]);

//     const fetchData = async (page=1, order="", field="") => {
//         isSubscribed.current && setLoading(true);
//         try {
//             var response;

//             if (page === 1) {
//                 response = await API().get(`/employer?order=${order}&field=${field}`);
//             } else {
//                 response = await API().get(`/employer?order=${order}&field=${field}&offset=${(page - 1) * 15}`);
//             }
//             const { data } = response;
//             isSubscribed.current && setData(data);
//         } catch (error) {
//             console.error('Error:', error);
//         }
//         isSubscribed.current && setLoading(false);
//     }

//     const handleUpdateStatus = async (id, status) => {
//         id && await API().patch(`/employer/status/${id}`, { status: status });
//         fetchData();
//     }

//     const handleFilter = async (value) => {
//         setIndustryCode(value);
//         startSearch(keyWord, value);
//     }

//     const handleSearch = async (keyword) => {

//         var kw = keyword.target.value;
//         setKeyWord(kw);

//         if (timeoutTyping.typingTimeout) {
//             clearTimeout(timeoutTyping.typingTimeout);
//         }

//         setTimeoutTyping({
//         typingTimeout: setTimeout(function () {
//             startSearch(kw, industryCode);
//             }, 1000)
//         });
//     }

//     const startSearch = async(keyWord, industryCode) => {
//         setLoading(true);
//         const response = await API().get(`/employer/search?keyword=${keyWord ? keyWord:""}&industry_code=${industryCode}`);
//         setData(response.data);
//         setLoading(false);
//     }

//     const handleDelete = async record => {

//         Modal.confirm({
//             title: `Are you sure delete ${record.company_name}?`,
//             content: '',
//             okText: 'Yes',
//             cancelText: 'No',
//             async onOk() {
//                 const response = await API().delete(`/employer/${record.id}`);

//                 if (response.data.errorCode === 0) {
//                     message.success(`Deleted successfully!`);
//                     fetchData();
//                 } else {
//                     message.error(`Failed! ${response.data.message}`)
//                 }
//             },
//             onCancel() {
//             },
//         });

//     }

//     const handleRow = async record => {
//         props.history.push(`/employer/detail/${record.id}`);
//     }

//     const handleSendPassword = async (data) => {

//         setLoading(true);
//         const response = await API().post(`/user/sendPassword`, data);

//         setLoading(false);

//         if (response && response.data.errorCode === 0) {
//             message.success('Account and Password sent.');
//             setVisiblePasswords(false);
//         } else if (response && response.data.errorCode === 403){
//             message.error('Account login with facebook not allow to sent.');
//         } else {
//             message.error('Account and Password not sent.');
//         }
//     }

//     const handleGotoUpdate = (e, record) => {
//         e.stopPropagation();
//         props.history.push(`/employer/update/${record.id}`);
//     };

//     const handleGotoTranslate = (e, record) => {
//         e.stopPropagation();
//         props.history.push(`/employer/translate/${record.id}`);
//     };

//     const handleGotoEmployerEmployee = (e, record) => {
//         e.stopPropagation();
//         props.history.push(`/employer/employer-employee/${record.id}`, record);
//     };

//     const columns = [
//         { title: '#', dataIndex: 'no', key: 'updated_at', width: '3%', sorter: () => { } },
//         { title: 'COMPANY', dataIndex: 'company_name', key: 'company_name', width: '23%', sorter: () => { } },
//         { title: 'REPRESENTATION', dataIndex: 'representation_name', key: 'representation_name', width: '20%', sorter: () => { } },
//         { title: 'GENDER', dataIndex: 'gender', key: 'gender', width: '8%', render: (value) => getTextDisplay(value, dataType.GENDER), sorter: () => { } },
//         { title: 'PHONE', dataIndex: 'phone', key: 'phone', sorter: () => { } },
//         { title: 'EMAIL', dataIndex: 'email', key: 'email', width: '10%', className: "col", sorter: () => { } },
//         {
//             title: 'STATUS', key: 'status', width: '6%', className: "col", render: item => (
//                 <span>
//                     <Button type='link' style={{ width: 100 }} onClick={e => showModal(item)}>
//                         <u>{getTextDisplay(item.status, dataType.EMPLOYER_STATUS)}</u>
//                     </Button>
//                 </span>
//             ), sorter: () => { }
//         },
//         {
//             title: '', key: 'actions', render: item => (
//                 <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
//                     <Button title="" type="primary" icon='file-image' className='action-button-margin' onClick={() => showGalleryModal(item.id)} />
//                     <Button title="Candidates" type="primary" className='action-button-margin' icon='team' onClick={e => handleGotoEmployerEmployee(e, item)} />
//                     <Button type="primary" icon='key' className='action-button-margin' onClick={() => {
//                         setVisiblePasswords(true);
//                         setSendPasswordData({ id: item.id, type: 'employer', name: item.company_name });
//                     }} />
//                     <Button title="Translate" type="primary" icon='global' className='action-button-margin' onClick={(e) => handleGotoTranslate(e, item)} />
//                     <Button title="Update" type="primary" className='action-button-margin' icon='edit' onClick={e => handleGotoUpdate(e, item)} />
//                     <Button title="Delete" type="danger" onClick={() => handleDelete(item)} icon='delete' />
//                 </span>
//             )
//         },
//     ];

//     const dataSource = data && data.data.map((employer, index) => {
//         return {
//             ...employer,
//             no: (index + 1) + ((page - 1) * 15),
//             key: employer.id
//         };
//     });

//     const onRow = (record, _) => {
//         return {
//             onDoubleClick: event => {
//                 event.preventDefault();
//                 event.stopPropagation();
//                 handleRow(record);
//             }, // click row
//         };
//     };

//     const onTableChange = (pagination, filters, sorter) => {
//         if (sorter) {
//             const page = pagination.current;
//             const field = sorter ? sorter.columnKey : "";
//             const order = sorter ? sorter.order : "";

//             setPage(page);
//             fetchData(page, order, field);
//         }
//     };

//     /* *
//     * Status Dialog Handler
//     * */

//     const showModal = item => {
//         setItemStatus(item);
//         setStatus(item.status);
//         setVisibleStatusModel(true);
//     };

//     const showGalleryModal = id => {
//         setEmployerId(id);
//         setVisibleGalleryModel(true);
//     };

//     const handleSaveStatus = newStatus => {
//         setVisibleStatusModel(false);
//         const id = itemStatus.id;
//         handleUpdateStatus(id, newStatus);
//     };

//     return (

//         <div className='list-container-employer'>
//             <Row gutter={[16]} type="flex" style={{ marginBottom: '40px' }}>
//                 <Col md={8}>
//                     <Row>
//                         <Col>Search:</Col>
//                         <Col>
//                             <Input.Search placeholder="Keyword" value={keyWord} onChange={(value) => handleSearch(value)} />
//                         </Col>
//                     </Row>
//                 </Col>
//                 <Col md={8}>
//                     <Col>Filter by Industry:</Col>
//                     <Col>
//                         <Select
//                             className='select-box'
//                             defaultValue={industryCode}
//                             onChange={handleFilter}>
//                             {dataIndustry ? dataIndustry.map((item) => {
//                                 const { value, text } = item;
//                                 return (<Select.Option value={value} key={value}>{text}</Select.Option>);
//                             }) : null}
//                         </Select>
//                     </Col>
//                 </Col>
//             </Row>

//             <div style={{ marginTop: '20px', marginBottom: '20px' }}>
//                 <Link to="/employer/create">
//                     <Button type="primary" style={{ height: '40px' }} icon="plus">New</Button>
//                 </Link>
//             </div>
//             <div>
//                 <StatusPopUp
//                     name={itemStatus && itemStatus.name ? itemStatus.name : 'N/A'}
//                     status={status}
//                     setStatus={setStatus}
//                     data={dataEmployerStatus}
//                     errorStatueStep={0}
//                     visible={visibleStatusModel}
//                     setVisible={setVisibleStatusModel}
//                     handleSaveStatus={handleSaveStatus}
//                 />

//                 <GallaryPopUp
//                     visible={visibleGalleryModel}
//                     employerId={employerId}
//                     onCancel={() => setVisibleGalleryModel(false)}
//                 />

//                 <PasswordPopUp
//                     visible={visiblePasswords}
//                     loading={loading}
//                     onCancel={() => setVisiblePasswords(false)}
//                     data={sendPasswordData}
//                     handleSendPassword={handleSendPassword}
//                 />
//             </div>
//             <Table
//                 rowClassName={(_, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
//                 bordered
//                 loading={loading}
//                 rowKey={dataSource => dataSource.key}
//                 dataSource={dataSource}
//                 columns={columns}
//                 onRow={onRow}
//                 onChange={onTableChange}
//                 pagination={{
//                     defaultCurrent: 1,
//                     defaultPageSize: 15,
//                     total: data && data.total,
//                 }}
//             />
//         </div>

//         // <Fragment>
//         //     <Route
//         //         exact
//         //         path={`${props.match.url}/create`}
//         //         render={() => (
//         //             <Information
//         //                 employer={employer}
//         //                 INITIAL_STATE_EMPLOYER={INITIAL_STATE_EMPLOYER}
//         //                 fileList={fileList}
//         //                 handleChange={handleChange}
//         //                 handleSubmit={handleSubmit}
//         //                 handleSelectChange={handleSelectChange}
//         //                 handleUpload={handleUpload}
//         //             />
//         //         )}
//         //     />

//         //     <Route
//         //         exact
//         //         path={`${props.match.url}/update/:id`}
//         //         render={() => (
//         //         <Information
//         //             employer={employer}
//         //             setEmployer={setEmployer}
//         //             handleChange={handleChange}
//         //             handleSubmit={handleSubmit}
//         //             handleSelectChange={handleSelectChange}
//         //             fileList={fileList}
//         //             handleUpload={handleUpload}
//         //         />
//         //         )}
//         //     />

//         //     <Route
//         //         exact
//         //         path={`${props.match.url}/translate/:id`}
//         //         render={() => (
//         //             <Spin tip="Loading..." spinning={spinning} style={{ maxHeight: '100%' }}>
//         //                 <Translate
//         //                     employer={employer}
//         //                     setEmployer={setEmployer}
//         //                     language={language}
//         //                     setLanguage={setLanguage}
//         //                     handleChangeLanguage={handleChangeLanguage}
//         //                     handleTranslate={handleTranslate}
//         //                 />
//         //             </Spin>
//         //         )}
//         //     />

//         //     <Route
//         //         path={`${props.match.url}/detail/:id`}
//         //         render={() => (
//         //         <Detail
//         //             detail={detail}
//         //             setDetail={setDetail}
//         //             setEmployer={setEmployer}
//         //             handleUpdateStatus={handleUpdateStatus}
//         //         />
//         //         )}
//         //     />

//         //     <Route
//         //         exact
//         //         path={props.match.url}
//         //         render={() => (
//         //         <Spin tip="Loading..." spinning={spinning}>
//         //             <List
//         //                 data={data}
//         //                 industries={dataIndustry}
//         //                 keyWord={keyWord}
//         //                 industryCode={industryCode}
//         //                 loading={loading}
//         //                 setEmployer={setEmployer}
//         //                 visiblePasswords={visiblePasswords}
//         //                 setVisiblePasswords={setVisiblePasswords}
//         //                 INITIAL_STATE_EMPLOYER= {INITIAL_STATE_EMPLOYER}
//         //                 handleUpdate={handleUpdate}
//         //                 handleUpdateStatus={handleUpdateStatus}
//         //                 handleDelete={handleDelete}
//         //                 handleSearch={handleSearch}
//         //                 handleFilter={handleFilter}
//         //                 handleRow={handleRow}
//         //                 handleFetchLanguage={handleFetchLanguage}
//         //                 handleFetchData={fetchData}
//         //                 handleSendPassword={handleSendPassword}
//         //             />
//         //         </Spin>
//         //         )}
//         //     />
//         // </Fragment>
//     )
// }

// export default withAuthSync(Employer);
