import React, { useState } from 'react';

//antd
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

//components
import ImageUploaderWithPreviewContainer from './ImageUploaderWithPreview.style';

//utils and constants
import { loadImageMiddleware } from '../utils';

export default function ImageUploaderWithPreview({ name, image = '', fileList = [], onFileChange }) {
    const [showDragger, setShowDragger] = useState(false);

    const { Dragger } = Upload;

    const handleOnMouseEnter = () => {
        setShowDragger(true);
    };
    const handleOnMouseLeave = () => {
        setShowDragger(false);
    };

    const beforeUpload = () => {
        return false;
    };

    return (
        <ImageUploaderWithPreviewContainer>
            {image ? (
                <div
                    className="image-preview-container"
                    onMouseLeave={() => handleOnMouseLeave()}
                    onMouseEnter={() => handleOnMouseEnter()}
                >
                    <img src={loadImageMiddleware(image)} className="content-image" alt="preview" />
                    <div className={`${(showDragger || fileList.length > 0) && `show-upload`} image-upload`}>
                        <Dragger
                            name={name}
                            beforeUpload={beforeUpload}
                            fileList={fileList}
                            onChange={(info) => onFileChange(info, name)}
                            accept="image/*"
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                        </Dragger>
                    </div>
                </div>
            ) : (
                <Dragger
                    name={name}
                    beforeUpload={beforeUpload}
                    fileList={fileList}
                    onChange={(info) => onFileChange(info, name)}
                    accept="image/*"
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                </Dragger>
            )}
        </ImageUploaderWithPreviewContainer>
    );
}
