import React, { useState } from 'react';
import { Modal, Form, Row, Col, Select, Upload, message, Space, Tooltip, Switch } from 'antd';
import { InboxOutlined, InfoCircleOutlined } from '@ant-design/icons';

//components
import { LodoshaInput, LodoshaSelect, LodoshaTextArea, LodoshaButton } from '../../components/UI/ui.style';

//utils and constants
import { dataCountry, dataGender, dataPartnerCategory } from '../../utils/data';
import API from '../../utils/api';

const { Option } = Select;
const { Dragger } = Upload;

const Upsert = ({ visible, setVisible, partner, setPartner, onRefresh, page }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ ...partner });
    const [fileList, setFileList] = useState([]);

    const handleCancel = () => {
        setVisible(false);
        setData();
        setPartner();
    };

    const handleChange = (name, value) => {
        setData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleChangeSelect = (name, value) => {
        // console.log(name, value);
        setData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleChangeSwitch = (check, name) => {
        setData((prev) => ({ ...prev, [name]: check }));
    };

    const beforeUpload = () => {
        // Avoid after drop, it auto upload to server
        return false;
    };

    const handleChangeFile = (info) => {
        let fileList = [...info.fileList];
        // 1. Allow in list file only one
        fileList = fileList.slice(-1);
        // 2. Read from response and show file link
        fileList = fileList.map((file) => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
            }
            return file;
        });

        setFileList(fileList);
    };

    const handleSubmit = async (value) => {
        setLoading(true);

        const config = { headers: { 'content-type': 'multipart/form-data' } };

        const formData = new FormData();

        if (data['id_token'] && data['id_token'].length < 8) {
            setLoading(false);
            message.error('Id Token must be at least 8 character!');
            return;
        }

        for (const key in data) {
            if (data[key]) {
                formData.append(key, data[key]);
            }
        }

        const files = fileList ? Object.values(fileList) : [];
        files.length > 0 && formData.append('image', files[0].originFileObj);

        try {
            const response = data?.id
                ? await API().patch(`/partner/${data.id}`, formData, config)
                : await API().post(`/partner/create`, formData, config);

            if (response?.data?.errorCode === 0) {
                message.success(`${data.id ? `Updated` : `Created`} successfully!`);
                setFileList([]);
                setData();
                setPartner();
                setVisible(false);
                setLoading(false);
                onRefresh(page);
            } else {
                message.error(`Failed, ${response.data.message}`);
                setLoading(false);
            }
        } catch (error) {
            message.error(`Uh oh, request failed!`);
            setLoading(false);
        }
    };

    const items = [
        { type: 'text', label: 'Company Name', name: 'name', value: partner?.name, required: true },
        { type: 'text', label: 'Contact Person', name: 'contact_name', value: partner?.contact_name, required: true },
        {
            type: 'select',
            label: 'Gender',
            name: 'gender',
            value: partner?.gender,
            options: dataGender,
            required: true,
        },
        { type: 'text', label: 'Phone', name: 'phone', value: partner?.phone, required: true },
        { type: 'text', label: 'Email', name: 'email', value: partner?.email, required: true },
        {
            type: 'select',
            label: 'Country',
            name: 'country',
            value: partner?.country,
            options: dataCountry,
            required: true,
        },
        {
            type: 'select',
            label: 'Category',
            name: 'category',
            value: partner?.category,
            options: dataPartnerCategory,
            required: true,
        },
        {
            type: 'text',
            label: 'URL',
            name: 'url',
            value: partner?.url,
            span: 16,
            support: (
                <Tooltip placement="top" title={<div>Example: https://www.facebook.com/lodosha</div>}>
                    <InfoCircleOutlined />
                </Tooltip>
            ),
        },
        {
            type: 'switch',
            label: 'MOU Signed',
            name: 'is_mou_signed',
            value: partner?.is_mou_signed,
            required: false,
            span: 8,
        },
        {
            type: 'switch',
            label: 'Verified',
            name: 'is_verified',
            value: partner?.is_verified,
            required: false,
            span: 8,
        },
        // { type: 'number', label: 'Register Fee', name: 'register_fee', value: partner?.register_fee },
        // { type: 'number', label: 'Trainee Fee', name: 'trainee_fee', value: partner?.trainee_fee },
        // { type: 'number', label: 'Specific Skill Fee', name: 'specific_skill_fee', value: partner?.specific_skill_fee },
        {
            type: 'text',
            label: 'IdTokenLink [Please start with partner name]',
            name: 'id_token',
            value: partner?.id_token,
            span: 24,
        },
        {
            type: 'textarea',
            label: 'Address',
            name: 'address',
            value: partner?.address,
            required: true,
            span: 24,
        },
        {
            type: 'textarea',
            label: 'Note',
            name: 'note',
            value: partner?.note,
            span: 24,
        },
    ];

    const onFinishFailed = (err, value) => {
        console.log('error', err, value);
        // form.setFields({});
    };

    return (
        <Modal
            visible={visible}
            maskClosable={false}
            title={`${partner && partner.id ? 'Update' : 'Create'}`}
            onCancel={handleCancel}
            width={768}
            footer={null}
        >
            <Form form={form} layout="vertical" onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
                <Row gutter={24}>
                    {items.map((item) => (
                        <Col key={item.name} span={item.span ? item.span : 8}>
                            <Form.Item
                                name={item.name}
                                label={
                                    <div>
                                        {item.support ? (
                                            <div>
                                                {item.label} {item.support} :
                                            </div>
                                        ) : (
                                            item.label + ':'
                                        )}
                                    </div>
                                }
                                initialValue={item.value}
                                rules={[
                                    { required: item.required, message: 'Data required!' },
                                    ({ getFieldValue }) => ({
                                        validator: (formInfo, value) => {
                                            if (formInfo?.field === 'email') {
                                                const email = getFieldValue('email');
                                                if (email && email.includes(' ')) {
                                                    return Promise.reject(new Error('Email cannot contain spaces'));
                                                }
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                {item.type === 'select' ? (
                                    <LodoshaSelect
                                        value={String(item.value)}
                                        onChange={(value) => handleChangeSelect(item.name, value)}
                                    >
                                        {item.options.map(({ value, text }) => (
                                            <Option key={value} value={value}>
                                                {text}
                                            </Option>
                                        ))}
                                    </LodoshaSelect>
                                ) : item.type === 'textarea' ? (
                                    <LodoshaTextArea
                                        placeholder={`Enter ${item.label.toLocaleLowerCase()}...`}
                                        onChange={(e) => handleChange(item.name, e.target.value)}
                                    />
                                ) : item.type === 'switch' ? (
                                    <Switch
                                        onChange={(value) => handleChangeSwitch(value, item.name)}
                                        defaultChecked={item.value ? item.value : false}
                                        name={item.name}
                                    />
                                ) : (
                                    <LodoshaInput
                                        type={item.type}
                                        placeholder={`Enter ${item.label.toLocaleLowerCase()}`}
                                        onChange={(e) => handleChange(item.name, e.target.value)}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    ))}
                    <Col span={24}>
                        <Form.Item label="Photo">
                            <Dragger
                                name="file"
                                beforeUpload={beforeUpload}
                                fileList={fileList}
                                onChange={handleChangeFile}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                            </Dragger>
                        </Form.Item>
                    </Col>
                    <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
                        <Space align="center" direction="horizontal" size="middle">
                            <LodoshaButton key="cancel" htmlType="button" onClick={handleCancel}>
                                Cancel
                            </LodoshaButton>
                            <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                                {data?.id ? 'Update' : 'Create'}
                            </LodoshaButton>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default Upsert;
