import API from '../api';

export const getEmployeeReport = () => {
    return API()
        .get('/employee/report')
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log('err: ', err);
        });
};

export const getCallHistory = (page, employeeId) => {
    const endpoint =
        page === 1
            ? `/employee-history/call/employee/${employeeId}`
            : `/employee-history/call/employee/${employeeId}?offset=${(page - 1) * 15}`;

    return API()
        .get(endpoint)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
};

export const createCallHistory = (data) => {
    return API()
        .post('/employee-history/call/create', data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
};

export const updateCallHistory = (id, data) => {
    return API()
        .patch(`/employee-history/call/${id}`, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
};

export const deleteCallHistory = (callId) => {
    return API()
        .delete(`/employee-history/call/${callId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getApplicationHistory = (employeeId) => {
    const endpoint = `/employee-history/applications-history?employee_id=${employeeId}`;

    return API()
        .get(endpoint)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
};

export const updateStatus = (id, status) => {
    const url = `/employee/status/${id}`;
    return API()
        .patch(url, { status: status })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log('err: ', err);
        });
};

export const UpdateLookingjobs = (id, looking_jobs) =>{
    const url = `/employee/looking-jobs/${id}`;
    return API()
        .patch( url, {looking_jobs: looking_jobs})
        .then((response) => {
            return response.data;
        }).catch((err) => {
            console.log("Update looking-jobs failed", err)
        });
}

export const updateInterviewPaymentStatus = (id, status) => {
    const url = `/employee/interview-payment/${id}`;
    return API()
        .patch(url, { interview_payment: status })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log('err:', err);
        });
};

export const deleteCandidate = (id) => {
    return API()
        .delete(`/employee/${id}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
};

export const recoverCandidate = (id) => {
    return API()
        .put(`/employee/${id}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
};
