import ReactQuill from 'react-quill';
import React, { useState, useMemo, useEffect, useRef, useContext } from 'react';
import { Modal, Form, Row, Col, Space, Upload, Avatar, message, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import 'react-quill/dist/quill.snow.css';

import { AuthContext } from '../../contexts/AuthContext';

//components
import { LodoshaButton } from '../../components/UI/ui.style';
import { UpSertContainer } from './blog.style';
import { LodoshaInput } from '../../components/UI/ui.style';

//utils and constants
import { createBlog, updateBlog } from '../../utils/api/blog';
import { isNullOrUndefined, loadImage } from '../../utils';
import { uploadImage, deleteImages } from '../../utils/api/media';
import { shareToFacebookPage } from '../../utils/api/facebook_graphAPI';

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

const Upsert = ({ visible, setVisible, blog, setBlog, onRefresh, page }) => {
    const { fbPageAccessToken } = useContext(AuthContext);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [photo, setPhoto] = useState();
    const [file, setFile] = useState();
    const [shareTo, setShareTo] = useState({ facebook: false, instagram: false });
    const [postCaption, setPostCaption] = useState('');

    const [originalImages, setOriginalImages] = useState([]);
    const [images, setImages] = useState([]);

    const quillRef = useRef(null);

    const handleCancel = () => {
        setVisible(false);
        form.resetFields();
        setBlog({});
    };

    useEffect(() => {
        form.setFieldsValue({
            title: blog ? blog.title || '' : '',
            content: blog ? blog.content || '' : '',
            tag: blog ? blog.tag || '' : '',
        });

        if (blog) {
            setImages(getImageIds(blog.content));
            setOriginalImages(getImageIds(blog.content));
        }
    }, [form, blog]);

    const handleSubmit = async (value) => {
        const formData = new FormData();

        for (const key in value) {
            if (value[key]) {
                formData.append(key, value[key]);
            }
        }

        if (file) {
            formData.append('image', file);
        }
        setLoading(true);
        if (blog && blog.id) {
            updateBlog(blog.id, formData).then((response) => {
                if (response && response.errorCode === 0) {
                    let link = `${process.env.BASE_URL_WEBSITE}blog/${response?.data?.id}`;
                    if (shareTo.facebook) {
                        shareToFacebookPage(link, postCaption, fbPageAccessToken, (fbResponse) => {
                            console.log("link", link, "response", response)
                            if (fbResponse.id) {
                                message.success('Successfully shared blog to facebook page');
                            }
                        });
                    }
                    setLoading(false);
                    removeImageFromServer();
                    handleCancel();
                    onRefresh(page);
                } else {
                    message.error(`Failed! ${response && response.message}`);
                }
            });
        } else {
            createBlog(formData).then((response) => {
                let link = `${process.env.BASE_URL_WEBSITE}blog/${response?.data?.id}`;
                
                if (response && response.errorCode === 0) {
                    if (shareTo.facebook) {
                        shareToFacebookPage(link, postCaption, fbPageAccessToken, (fbResponse) => {
                            if (fbResponse.id) {
                                message.success('Successfully shared blog to facebook page');
                            }
                        });
                    }
                    setLoading(false);
                    removeImageFromServer();
                    handleCancel();
                    onRefresh(page);
                } else {
                    message.error(`Failed! ${response && response.message}`);
                }
            });
        }
        setPostCaption('');
    };

    const handleUploadPhoto = async (info) => {
        if (info.fileList.length > 0) {
            getBase64(info.fileList[0].originFileObj, (imageUrl) => {
                setPhoto(imageUrl);
                setFile(info.fileList[0].originFileObj);
            });
        }
    };

    const removeImageFromServer = (isCancel = false) => {
        var results = [];

        if (isCancel) {
            results = images.filter((id1) => !originalImages.some((id2) => id2 === id1));
        } else {
            const text = quillRef.current.getEditor().root.innerHTML;
            const currentIds = getImageIds(text);
            results = images.filter((id1) => !currentIds.some((id2) => id2 === id1));
        }
        if (results && results.length > 0) {
            deleteImages(results.join(','));
        }
    };

    const handleChangeShareSocialMedia = (name, value) => {
        setShareTo((prev) => ({ ...prev, [name]: value }));
    };

    const handleChangePostCaption = (e) => {
        setPostCaption(e.target.value);
    };

    const imageHandler = async (info) => {
        const input = document.createElement('input');

        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async function () {
            const quill = quillRef.current.getEditor();
            const file = input.files[0];

            const formData = new FormData();
            formData.append('image', file);

            const response = await uploadImage(formData);
            const range = quill.getSelection();
            console.log(response);
            if (response?.errorCode === 0) {
                const imageId = response.data.id;
                setImages((images) => [...images, imageId]);

                const link = loadImage(imageId);
                quill.insertEmbed(range.index, 'image', link);
                quill.setSelection(range.index + 1);
            }
        }; //.bind(this);
    };

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [{ header: '1' }, { header: '2' }, { font: [] }],
                    [{ size: [] }],
                    [{ color: [] }, { background: [] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
                    [{ align: [] }],
                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                    ['link', 'image', 'video'],
                    ['clean'],
                ],
                handlers: {
                    image: imageHandler,
                },
            },
            clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false,
            },
        };
    }, []);

    const formats = useMemo(() => {
        return [
            'header',
            'font',
            'size',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'indent',
            'link',
            'image',
            'video',
            'color',
            'background',
            'code-block',
            'align',
        ];
    }, []);

    const getImageIds = (str) => {
        if (isNullOrUndefined(str)) {
            return [];
        }

        var retArray = [];
        var regexPattern = /src="([^"]+)/gi;
        var matches = str.match(regexPattern);
        if (matches) {
            for (var i = 0; i < matches.length; i++) {
                var match = matches[i].replace('src="', '');
                var id = match.substring(match.lastIndexOf('/') + 1);
                retArray.push(id);
            }
        } else {
            return [];
        }
        return retArray;
    };

    const beforeUpload = () => {
        return false;
    };

    return (
        <Modal
            visible={visible}
            maskClosable={false}
            title={blog && blog.id ? 'Update Blog' : 'Create Blog'}
            onCancel={() => {
                removeImageFromServer(true);
                handleCancel();
            }}
            width={1000}
            bodyStyle={{ height: '100%' }}
            footer={null}
        >
            <UpSertContainer>
                <Form form={form} layout="vertical" onFinish={handleSubmit}>
                    <Row>
                        <Col span={10}>
                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[{ required: true, message: 'Data required!' }]}
                                style={{ width: '100%', margin: 0 }}
                            >
                                <LodoshaInput />
                            </Form.Item>
                            <Form.Item
                                label="Tag (split tag by , )"
                                name="tag"
                                style={{ width: '100%', margin: 0, marginTop: 16, marginBottom: 16 }}
                            >
                                <LodoshaInput />
                            </Form.Item>
                            <div style={{ marginBottom: '1rem' }}>
                                <div style={{ display: 'flex' }}>
                                    <p style={{ fontSize: '14px', marginRight: '1rem' }}>Share to:</p>
                                    <Form.Item
                                        label="Facebook"
                                        name="facebook"
                                        style={{ margin: 0, marginBottom: 16, marginRight: '2rem' }}
                                        initialValue={shareTo.facebook}
                                    >
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            onChange={(value) => handleChangeShareSocialMedia('facebook', value)}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Instagram"
                                        name="instagram"
                                        style={{ margin: 0, marginBottom: 16 }}
                                        initialValue={shareTo.instagram}
                                    >
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            disabled
                                            onChange={(value) => handleChangeShareSocialMedia('instagram', value)}
                                        />
                                    </Form.Item>
                                </div>
                                <LodoshaInput
                                    placeholder="Enter post caption (optional)"
                                    disabled={shareTo.facebook || shareTo.instagram ? false : true}
                                    onChange={handleChangePostCaption}
                                />
                            </div>
                        </Col>
                        <Col span={10} push={2}>
                            <Form.Item label="Banner Image" style={{ margin: 0 }}>
                                <Row justify="center">
                                    <Upload
                                        name="photo"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        onChange={handleUploadPhoto}
                                        beforeUpload={beforeUpload}
                                    >
                                        <Avatar
                                            shape="square"
                                            size={128}
                                            src={photo ? photo : blog && blog.image_id ? loadImage(blog.image_id) : ''}
                                            style={{ border: '1px solid #e8e8e8', cursor: 'pointer' }}
                                        />
                                    </Upload>
                                </Row>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        style={{ margin: 0 }}
                        label="Content"
                        name="content"
                        rules={[{ required: true, message: 'Data required!' }]}
                    >
                        <ReactQuill theme="snow" ref={quillRef} modules={modules} formats={formats} />
                    </Form.Item>
                    <Form.Item>
                        <Row justify="center" style={{ marginTop: 20 }}>
                            <Space direction="horizontal" size="middle">
                                <LodoshaButton
                                    key="cancel"
                                    htmlType="button"
                                    onClick={() => {
                                        removeImageFromServer(true);
                                        handleCancel();
                                    }}
                                >
                                    Cancel
                                </LodoshaButton>
                                <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                                    Save
                                </LodoshaButton>
                            </Space>
                        </Row>
                    </Form.Item>
                </Form>
            </UpSertContainer>
        </Modal>
    );
};

export default Upsert;
