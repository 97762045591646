import styled from '@emotion/styled';

const NextPrevActionButtonGroupStyles = styled.div`
    .action-buttons-container {
        display: flex;
        justify-content: space-between;
    }

    .current-item-title {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
    }

    .left-action-button-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
            color: gray;
        }
        color: #152688;
        .left-button {
            font-size: 24px;
        }
    }

    .right-action-button-container {
        cursor: pointer;

        &:hover {
            color: gray;
        }
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #152688;

        .right-button {
            font-size: 24px;
        }
    }
`;

export default NextPrevActionButtonGroupStyles;
