import React from "react";
import { Modal, Form, Input } from "antd";
import { LodoshaButton } from "../../components/UI/ui.style";
import API from "../../utils/api";

const Note = ({ visible, setVisible, employee = {}, onRefresh }) => {
  const employeeNote = employee.note ? employee.note : "";
  const [note, setNote] = React.useState(employeeNote);
  // console.log(employeeNote, note);

  const handleSaveNote = async () => {
    await API().patch(`/employee/note/${employee.id}`, { note });
    setVisible(false);
    onRefresh();
  };

  return (
    <Modal
      width={768}
      maskClosable={false}
      visible={visible}
      title=""
      onCancel={() => setVisible(false)}
      footer={[
        <LodoshaButton style={{ height: 38, width: 108 }} key="back" onClick={handleSaveNote}>
          Save
        </LodoshaButton>,
      ]}
    >
      <>
        <h2 style={{ marginBottom: 16 }}>Note of Candidate</h2>
        <Form>
          <Form.Item>
            <Input.TextArea
              rows={10}
              name="note"
              placeholder="Enter your note..."
              value={note}
              onChange={(event) => setNote(event.target.value)}
            />
          </Form.Item>
        </Form>
      </>
    </Modal>
  );
};

export default Note;
