import React, { useState, useMemo } from 'react';

//api
import { postNewCulture, updateCulture } from '../../../utils/api/E-Learning/culture';

//ant design
import { Modal, Row, Col, Form, message, Select } from 'antd';

//components
import BannerUploader from '../E-LearningComponents/BannerUploader';

//utils and constants
import { cultureTypes } from '../../../utils/data';
import { LodoshaInput, LodoshaButton } from '../../../components/UI/ui.style';
import { cultureTypeToText } from '../../../utils/data';

export default function CultureModal({
    title,
    visible,
    setVisible,
    onRefresh,
    setPostData,
    postData,
    isUpdate,
    isCreate,
}) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(postData);
    const [file, setFile] = useState();

    const { Option } = Select;

    const cultureFormFields = useMemo(
        () => [
            {
                label: 'Culture Name',
                type: 'text',
                name: 'name',
                value: data?.name,
                span: 12,
                required: true,
            },
            {
                label: 'Type',
                type: 'text',
                name: 'type',
                value: cultureTypeToText[data?.type - 1],
                span: 12,
                required: true,
                isSelect: true,
            },
        ],
        [data]
    );

    //close modal
    const handleCancel = (e) => {
        setVisible(false);
        setPostData({});
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        setData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleChangeOpt = (value) => {
        console.log(value);
        setData((prev) => ({ ...prev, type: value }));
    };

    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            for (const key in data) {
                formData.append(key, data[key]);
            }
            formData.append('chapter-image', file);
            setLoading(true);
            const response = isCreate
                ? await postNewCulture(formData)
                : isUpdate
                ? await updateCulture(data.id, formData)
                : {};
            if (response && response.errorCode !== 0) {
                setLoading(false);
                console.log(response);
                message.error(`Oh oh, something went wrong.`, response.message);
                return;
            }
            if (response) {
                response &&
                    message.success(isCreate ? 'New culture added.' : isUpdate ? 'Successfully updated content' : '');
                setLoading(false);
                handleCancel();
                onRefresh();
            } else {
                setLoading(false);
                message.error(`Uh oh, ${isCreate ? 'add new culture' : isUpdate ? 'updating culture' : ''} failed!`);
            }
        } catch (error) {
            console.log("error",error)
            setLoading(false);
            message.error(`Uh oh, ${isCreate ? 'add new content' : isUpdate ? 'updating content' : ''} failed!`);
        }
    };
    return (
        <Modal
            width={768}
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
                    <div>{title}</div>
                    <BannerUploader imageId={data && data.image_id} setFile={setFile} />
                </div>
            }
            visible={visible}
            onCancel={handleCancel}
            maskClosable={false}
            footer={null}
        >
            <Form layout="vertical" form={form} onFinish={() => handleSubmit()}>
                <Row gutter={24}>
                    {cultureFormFields?.map((field, index) => {
                        const { type, label, name, value, required, disabled, span, isSelect } = field;
                        return (
                            <Col span={span} key={index}>
                                <Form.Item
                                    rules={[{ required: required, message: 'Data required!' }]}
                                    label={`${label}:`}
                                    name={name}
                                    initialValue={value}
                                >
                                    {isSelect ? (
                                        <Select
                                            onChange={handleChangeOpt}
                                            placeholder="Please select a type."
                                            size="large"
                                            value={data?.type}
                                        >
                                            {cultureTypes.map((type, index) => (
                                                <Option key={index} value={type.value}>
                                                    {type.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    ) : (
                                        <LodoshaInput
                                            type={type}
                                            disabled={disabled && true}
                                            onChange={handleChange}
                                            value={value}
                                            name={name}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        );
                    })}
                    <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
                        <LodoshaButton
                            key="cancel"
                            style={{ marginRight: 16 }}
                            htmlType="button"
                            onClick={handleCancel}
                        >
                            Cancel
                        </LodoshaButton>
                        <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                            {isCreate ? 'Add New' : isUpdate ? 'Update' : ''}
                        </LodoshaButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
