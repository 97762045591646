import React from 'react';

//components
import Search from '../../components/Search';

export default function SearchUser({ keyword, onSearch }) {
    const items = [{ label: 'Search:', name: 'search', type: 'text', value: keyword, onSearch }];

    return <Search items={items} />;
}
