import React from "react";
import { Button, Row, Col } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "./file_item.less";

const FileItem = ({ id, name, category, mime_type, is_delete, handleDelete }) => {
  return (
    <Row className="FileItem">
      <Col span={20}>
        <h3>{name}</h3>
        <h4>
          {category} ({is_delete ? "deleted" : "ok"})
        </h4>
      </Col>
      <Col span={4}>
        <Button
          id="btn-delete"
          icon={<CloseOutlined />}
          onClick={(e) => {
            e.preventDefault();
            handleDelete(id);
          }}
        />
      </Col>
    </Row>
  );
};

export default FileItem;
