import styled from '@emotion/styled';

export const MarkdownSampleCreatorWrapper = styled.div`
    .filter-icon-container {
        float: right;
        transform: translateY(25%);
    }
    .filter-icon {
        font-size: 24px;
        opacity: 0.7;
        cursor: pointer;
        &:hover {
            opacity: 1;
        }
    }

    .text-area {
        border-radius: 0.5rem;
        height: 350px !important;
        border: 2px solid #152688;
        padding: 1rem;
    }
    .div-as-text-area {
        background-color: #f4f4f4;
        cursor: text;
        overflow-y: scroll;
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .card-type-text {
        opacity: 0.9;
        font-size: 18px;
        padding: 0.5rem 2rem 0rem 0.5rem;
        color: #fff;
        background-color: #152688;
        border-top-right-radius: 100px;
    }
    .card-action-button-container {
        margin-right: .5rem;
    }
`;
