import React, { useEffect, useMemo, useState, useCallback, useRef, useContext } from 'react';
import { useHistory, useLocation, Switch, Route } from 'react-router-dom';

import AppContext from '../../../contexts/AppContext';

//ant design
import { Space, Button, message, Modal, Row, Col } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, ImportOutlined } from '@ant-design/icons';

//api
import { getCulture, deleteCulture, updateCultureOrder } from '../../../utils/api/E-Learning/culture';

//constant
import { ELEARNING } from '../../../constants/routes';
import { getNewLastOrder, getELearningItems } from '../../../utils';

//components
import Breadcrumb from '../E-LearningComponents/Breadcrumb';
import { Container, LodoshaButton } from '../../../components/UI/ui.style';
import CultureContent from './CultureContent';
import CultureModal from './CultureModal';
import CultureDetail from './CultureDetail';
import SortableTable from '../E-LearningComponents/SortableTable';
import DragHandler from '../E-LearningComponents/DragHandler';
import { cultureTypeToText } from '../../../utils/data';
import ImportModal from './ImportModal';

const Culture = (props) => {
    const { handleUnauthorizedUser } = useContext(AppContext);
    const history = useHistory();
    const { pathname } = useLocation();

    const isSubscribed = useRef(true);

    const [cultures, setCultures] = useState([]);
    const [postData, setPostData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [importVisible, setImportVisible] = useState(false);

    //extract menuid from url
    const menuid = pathname?.split('/')[2];

    //used to hide main menu and show lesson list
    const shouldMainMenuShow = pathname.split('/')[3] ? false : true;

    const columns = [
        {
            title: '#',
            dataIndex: 'no',
            width: '5%',
            align: 'center',
            render: () => <DragHandler />,
        },
        {
            title: 'CULTURE',
            dataIndex: 'name',
        },
        {
            title: 'TYPE',
            dataIndex: 'typeName',
            align: 'center',
            width: '20%',
            render: (text, row, index) => (
                <div style={{ textTransform: 'capitalize' }}>{cultureTypeToText[row?.type - 1]}</div>
            ),
        },
        {
            title: 'ACTIONS',
            align: 'center',
            width: '10%',
            render: (text, row, index) => (
                <Space size="small">
                    <Button type="primary" title="Edit" onClick={(e) => handleEdit(e, row)} icon={<EditOutlined />} />
                    <Button
                        type="danger"
                        title="Delete"
                        onClick={(e) => handleDelete(e, row.id)}
                        icon={<DeleteOutlined />}
                    />
                </Space>
            ),
        },
    ];
    const data = useMemo(
        () =>
            cultures &&
            cultures.map((culture, index) => ({
                ...culture,
                no: index + 1,
                key: culture.id,
                name: culture.name,
                typeName: culture.type,
                type: culture.type,
            })),
        [cultures]
    );

    const handleFetchCulture = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getCulture();

            if (response.data.status === 401) {
                message.error(`Unauthorized`);
                handleUnauthorizedUser();
                return;
            }

            if (response) {
                if (response.errorCode === 0) {
                    setCultures(response?.data);
                    setLoading(false);
                    return;
                } else {
                    setLoading(false);
                    message.error(`Couldn't get culture,${response.message}`);
                    return;
                }
            } else {
                message.error(`Couldn't get culture, Response is undefined`);
                setLoading(false);
                return;
            }
        } catch (error) {
            setLoading(false);
            message.error(`Uh oh, request failed!`);
        }
    }, [handleUnauthorizedUser]);

    const Upsert = useCallback(
        () =>
            visible && (
                <CultureModal
                    postData={postData}
                    setPostData={setPostData}
                    setVisible={setVisible}
                    visible={visible}
                    onRefresh={handleFetchCulture}
                    isCreate
                    title="ADD NEW CULTURE"
                />
            ),
        [visible, postData, handleFetchCulture]
    );

    const Update = useCallback(
        () =>
            updateVisible && (
                <CultureModal
                    postData={postData}
                    setPostData={setPostData}
                    visible={updateVisible}
                    setVisible={setUpdateVisible}
                    onRefresh={handleFetchCulture}
                    isUpdate
                    title="UPDATE CULTURE"
                />
            ),
        [handleFetchCulture, updateVisible, postData]
    );

    const ImportCulture = useCallback(
        () =>
            importVisible && (
                <ImportModal
                    visible={importVisible}
                    setVisible={setImportVisible}
                    postData={postData}
                    setPostData={setPostData}
                    onRefresh={handleFetchCulture}
                />
            ),
        [importVisible, postData, handleFetchCulture]
    );

    const handleDelete = (e, id) => {
        e.stopPropagation();
        Modal.confirm({
            title: 'Are you sure delete this culture?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await deleteCulture(id);
                    if (response && response.errorCode === 0) {
                        message.success(`Deleted successfully!`);
                        handleFetchCulture();
                    } else {
                        message.error(`Oh uh, request to delete failed. ${response && response.data.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request to delete chapter failed!`);
                }
            },
            onCancel() {},
        });
    };

    const handleRow = (record, rowIndex) => {
        return {
            onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                localStorage.setItem('culture-type', JSON.stringify(`${record?.typeName}`.toLowerCase()));
                try {
                    if (record?.typeName && record?.typeName === 1) {
                        let cultureQnA = cultures && cultures.length > 0 && cultures.filter((c) => c.type === 1);
                        const eLearningItems = getELearningItems(cultureQnA, 'name');
                        localStorage.setItem('e-learning-chapters', JSON.stringify(eLearningItems));
                    }
                } catch (error) {
                    message.error('Set to localstorage failed');
                }
                return history.push(`${ELEARNING}/${menuid}/${record.id}`, record);
            }, // click row
        };
    };

    const handleEdit = (e, row) => {
        e.stopPropagation();
        setPostData(row);
        setUpdateVisible(true);
    };

    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchCulture();
        }
        return () => {
            isSubscribed.current = false;
        };
    }, [handleFetchCulture]);

    useEffect(() => {
        const getLastOrder = () => {
            let lastOrder = getNewLastOrder(cultures);
            setPostData((prev) => ({ ...prev, order: lastOrder, menu: menuid }));
        };
        getLastOrder();
    }, [cultures, visible, menuid, importVisible]);

    return (
        <div>
            <Breadcrumb menuid={menuid} />
            {shouldMainMenuShow && (
                <Container>
                    <Row justify="space-between">
                        <Col>
                            <LodoshaButton
                                type="primary"
                                className="btn-margin-bottom"
                                onClick={() => setVisible(true)}
                            >
                                <PlusOutlined />
                                Add New
                            </LodoshaButton>
                        </Col>
                        <Col>
                            <LodoshaButton
                                type="ghost"
                                className="btn-margin-bottom"
                                onClick={() => setImportVisible(true)}
                            >
                                <ImportOutlined />
                                Import
                            </LodoshaButton>
                            <ImportCulture />
                        </Col>
                    </Row>

                    <SortableTable
                        columns={columns}
                        data={data}
                        loading={loading}
                        handleRow={handleRow}
                        setLoading={setLoading}
                        onUpdateOrder={updateCultureOrder}
                        onRefresh={handleFetchCulture}
                    />
                </Container>
            )}
            <Upsert />
            <Update />
            <Switch>
                <Route exact path={`${ELEARNING}/:menuid/:cultureid`} component={CultureContent} />
                <Route exact path={`${ELEARNING}/:menuid/:cultureid/:contentid`} component={CultureDetail} />
            </Switch>
        </div>
    );
};
export default Culture;
