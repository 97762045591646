import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';

//utils and constants
import API from '../utils/api';
import useFacebookLogin from '../utils/custom_hook/useFacebookLogin';
import { generateFbPageAccessToken } from '../utils/api/facebook_graphAPI';

const AuthContext = React.createContext();

const AuthProvider = (props) => {
    const token = Cookies.get('backoffice_token');

    const [isAuth, setAuth] = useState(token);
    const [fbAccessToken, setfbAccessToken] = useState('');
    const [fbPageAccessToken, setFBPageAccessToken] = useState('');
    const { isFBInitialized, initFb } = useFacebookLogin();

    useEffect(() => {
        // React Hook useEffect will give a warning has a missing dependency
        const token = Cookies.get('backoffice_token');
        if (!token) {
            return <Redirect to="/login" />;
        }
        setAuth(token);
    }, []);

    const login = async (user) => {
        const { data } = await API().post(`/account/login`, user);
        const domain = process.env.DOMAIN;
        if (data.token) {
            Cookies.set('backoffice_token', data.token, { expires: 1, domain: domain });
            Cookies.set('middleware_token', data.middleware_token, { expires: 1, domain: domain });
            setAuth(data.token);
            return true;
        }
        return false;
    };

    const logout = () => {
        Cookies.remove('backoffice_token');
        Cookies.remove('middleware_token');
        setAuth();
    };

    //fb login
    const handleFacebookLogin = (cb) => {
        if (!isFBInitialized) {
            initFb();
            window.FB.login((response) => {
                setfbAccessToken(response?.authResponse?.accessToken);
                cb(true);
            });
            return;
        }

        window.FB.login((response) => {
            setfbAccessToken(response?.authResponse?.accessToken);
            cb(true);
        });
    };

    const handleFacebookLogout = (cb) => {
        window.FB.logout((response) => {
            setfbAccessToken('');
            cb(true);
        });
    };
    useEffect(() => {
        console.log('isFbInit', isFBInitialized);
        if (isFBInitialized) {
            window.FB.getLoginStatus((response) => {
                console.log('loginStatus', response?.status);
                setfbAccessToken(response?.authResponse?.accessToken);
            });
        }
    }, [isFBInitialized]);

    useEffect(() => {
        if (fbAccessToken) {
            generateFbPageAccessToken(fbAccessToken, (response) => {
                if (response.access_token) {
                    setFBPageAccessToken(response.access_token);
                }
            });
        }
    }, [fbAccessToken]);

    return (
        <AuthContext.Provider
            value={{
                isAuth,
                login,
                logout,
                handleFacebookLogin,
                handleFacebookLogout,
                fbAccessToken,
                fbPageAccessToken,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

const AuthConsumer = AuthContext.Consumer;

export { AuthContext, AuthProvider, AuthConsumer };

export const auth = (ctx, history) => {
    const token = Cookies.get('backoffice_token');
    const JWT_SECRET = process.env.JWT_SECRET;
    const payload = jwt.verify(token, JWT_SECRET);
    // If there's no token, it means the user is not logged in.
    if (!token) {
        if (typeof window === 'undefined') {
            ctx.res.writeHead(302, { Location: '/login' });
            ctx.res.end();
        } else {
            history.push('/login');
        }
    } else if (payload.error) {
        Cookies.remove('backoffice_token');
        Cookies.remove('middleware_token');
        history.push('/login');
    }

    return token;
};

export const withAuthSync = (WrappedComponent, history) => {
    const Wrapper = (props) => {
        const syncLogout = (event) => {
            if (event.key === 'logout') {
                history.push('/login');
            }
        };

        useEffect(() => {
            window.addEventListener('storage', syncLogout);
            return () => {
                window.removeEventListener('storage', syncLogout);
                window.localStorage.removeItem('logout');
            };
        }, []);

        return <WrappedComponent {...props} />;
    };

    Wrapper.getInitialProps = async (ctx) => {
        const token = auth(ctx);
        const componentProps = WrappedComponent.getInitialProps && (await WrappedComponent.getInitialProps(ctx));

        return { ...componentProps, token };
    };

    return Wrapper;
};
