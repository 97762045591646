import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Modal, message, Spin } from 'antd';

import { withAuthSync } from '../../contexts/AuthContext';

import API from '../../utils/api';

import List from './List';
import Detail from './Detail';
import Information from './Info';

import './style.less';

function Career(props) {
    const timeout_typing_state = {
        typingTimeout: 0,
    };
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [career, setCareer] = useState({});
    const [timeoutTyping, setTimeoutTyping] = useState(timeout_typing_state);
    const [keyWord, setKeyWord] = useState('');
    const [detail, setDetail] = useState({});
    const [fileList, setFileList] = useState();
    const [spinning, setSpinning] = useState(false);
    const [visibleUpsert, setVisibleUpsert] = useState(false);
    const [visibleUpdate, setVisibleUpdate] = useState(false);

    const isSubscribed = useRef(true);

    useEffect(() => {
        isSubscribed.current && fetchData();

        return () => {
            isSubscribed.current = false;
        };
    }, []);

    const fetchData = async (page = 1) => {
        isSubscribed.current && setLoading(true);
        try {
            var response;
            if (page === 1) {
                response = await API().get(`/career`);
            } else {
                response = await API().get(`/career?offset=${(page - 1) * 15}`);
            }
            const { data } = response;
            isSubscribed.current && setData(data);
        } catch (error) {
            message.error(`Uh oh, request failed!`);
        }
        isSubscribed.current && setLoading(false);
    };

    const handleUpdate = (record) => {
        setCareer(record);
        setVisibleUpdate(true);
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setCareer({ ...career, [name]: value });
    };

    const handleNew = () => {
        setCareer({});
        setVisibleUpsert(true);
    };

    const handleSelectChange = (name, value) => {
        setCareer({ ...career, [name]: value });
    };

    const handleUpload = (e) => {
        let fileList = [...e.fileList];

        // 1. Allow in list file only one
        fileList = fileList.slice(-1);

        // 2. Read from response and show file link
        fileList = fileList.map((file) => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
            }
            return file;
        });

        setFileList(fileList);
    };

    const handleSubmit = async (values) => {
        let response = null;

        if (career && career.id) {
            response = await API().patch(`/career/${career.id}`, career);

            if (response.data.errorCode === 0) {
                message.success(`Updated successfully!`);
                response && response.data.errorCode === 0 && fetchData();
                history.push('/career');
            } else {
                message.error(`Failed! ${response.data.message}`);
            }
        } else {
            response = await API().post(`/career/create`, career);
            if (response && response.data.errorCode === 0) {
                message.success(`Created successfully!`);
                response && response.data.errorCode === 0 && fetchData();
                history.push('/career');
            } else {
                message.error(`Failed! ${response.data.message}`);
            }
        }
    };

    const handleDelete = async (e, record) => {
        e.stopPropagation();
        Modal.confirm({
            title: `Are you sure delete ${record.job_title}?`,
            content: '',
            okText: 'Yes',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await API().delete(`/career/${record.id}`);
                    if (response.data.errorCode === 0) {
                        message.success(`Deleted successfully!`);
                        fetchData();
                    } else {
                        message.error(`Failed! ${response.data.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request failed!`);
                }
            },
            onCancel() {},
        });
    };

    const handleRow = async (record) => {
        setSpinning(true);
        setDetail(record);
        setSpinning(false);
        history.push(`/career/detail/${record.id}`);
    };

    const handleSearch = async (keyword) => {
        var kw = keyword.target.value;
        setKeyWord(kw);

        if (timeoutTyping.typingTimeout) {
            clearTimeout(timeoutTyping.typingTimeout);
        }
        setTimeoutTyping({
            typingTimeout: setTimeout(function () {
                startSearch(kw);
            }, 1000),
        });
    };

    const startSearch = async (keyWord) => {
        setLoading(true);
        const response = await API().get(`/career/search?keyword=${keyWord}`);
        setData(response.data);
        setLoading(false);
    };

    const handlePageChange = (page) => {
        fetchData(page);
    };

    return (
        <Fragment>
            <Switch>
                <Route
                    exact
                    path={`${props?.match?.url}/create`}
                    render={() => (
                        <Information
                            career={career}
                            handleSubmit={handleSubmit}
                            handleChange={handleChange}
                            handleSelectChange={handleSelectChange}
                            fileList={fileList}
                            handleUpload={handleUpload}
                        />
                    )}
                />

                <Route
                    exact
                    path={`${props?.match?.url}/update/:id`}
                    render={() => (
                        <Information
                            career={career}
                            setCareer={setCareer}
                            handleSubmit={handleSubmit}
                            handleChange={handleChange}
                            handleSelectChange={handleSelectChange}
                            fileList={fileList}
                            handleUpload={handleUpload}
                        />
                    )}
                />

                <Route
                    exact
                    path={`/career/detail/:id`}
                    render={() => <Detail detail={detail} setDetail={setDetail} setCareer={setCareer} />}
                />

                <Route
                    path={props?.match?.url}
                    render={() => (
                        <Spin tip="Loading..." spinning={spinning}>
                            <List
                                career={career}
                                loading={loading}
                                data={data}
                                keyWord={keyWord}
                                handleNew={handleNew}
                                handleUpdate={handleUpdate}
                                handleDelete={handleDelete}
                                handleSearch={handleSearch}
                                handleRow={handleRow}
                                handlePageChange={handlePageChange}
                                visibleUpdate={visibleUpdate}
                                setVisibleUpdate={setVisibleUpdate}
                                visibleUpsert={visibleUpsert}
                                setVisibleUpsert={setVisibleUpsert}
                                handleFetchData={fetchData}
                            />
                        </Spin>
                    )}
                />
            </Switch>
        </Fragment>
    );
}

export default withAuthSync(Career);
