import createAPI from '../api';

export const getHospitalFees = (page, hospitalId, type) => {
    const url = (page = 1 ? `/partner-fee/${hospitalId}` : `/partner-fee/${hospitalId}?offset=${(page - 1) * 15}`);
    return createAPI()
        .get(url)
        .then((response) => response.data)
        .catch((err) => console.log(`getHospitalFees err: `, err));
};

export const createHospitalFee = (info) => {
    return createAPI()
        .post('/partner-fee', info)
        .then((response) => response.data)
        .catch((err) => {});
};

export const updateHospitalFee = (info) => {
    console.log('info', info);
    return createAPI()
        .patch(`/partner-fee/${info?.id}`, info)
        .then((response) => response.data)
        .catch((err) => {});
};

export const deleteHospitalFee = (id) => {
    return createAPI()
        .delete(`/partner-fee/${id}`)
        .then((response) => response.data)
        .catch((err) => {});
};
