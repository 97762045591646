import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Switch, Route, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { message, Tabs } from 'antd';

//compopnents
import Detail from './Detail';
import CallHistory from './CallHistory';
import ApplicationHistory from './ApplicationHistory';
import MobileAppHistory from './MobileAppHistory';
import Rating from './RatingCandidate';
import { Container } from '../../../components/UI/ui.style';

import API from '../../../utils/api';

//constant and utils
import * as ROUTES from '../../../constants/routes';

const { TabPane } = Tabs;

const Employee = () => {
    let history = useHistory();
    let location = useLocation();
    const { path } = useRouteMatch();
    const [employee , setEmployee] = useState();
    const isSubscribed = useRef(true);
    const [loading, setLoading] = useState(false);

    const pathnames = location.pathname.split('/');
    const employeeId = window.localStorage.getItem('employeeId');
    
    const activeKey = ['call-history', 'app-history', 'mobile-app-history', 'rating'].includes(pathnames[4])
    ? pathnames[4]
    : 'detail';
    const handleTabClick = (key, e) => {
        e.preventDefault();
        key === 'detail'
            ? history.push({ pathname: `${path}/${employeeId}` })
            : history.push({ pathname: `${path}/${key}/${employeeId}` });
    };

    const handleFetchEmployee = useCallback( async() =>{
        isSubscribed && setLoading(true);
        try {
            const response = await API().get(`/employee/detail/${employeeId}`);

            if(response.data){
                isSubscribed.current && setEmployee(response?.data?.data);
            }else{
                message.info(response.data.message);
            }
            
            isSubscribed.current && setLoading(false);
        } catch (error) {
            console.log("failed", error)
            isSubscribed.current && setLoading(false);
        }
    },[employeeId])


    useEffect(() =>{
        handleFetchEmployee();
    },[employeeId])

    return (
        <Container>
            <Tabs type="card" onTabClick={handleTabClick} defaultActiveKey={activeKey}>
                <TabPane tab="Detail" key="detail"></TabPane>
                <TabPane tab="Call History" key="call-history" />
                <TabPane tab="Application History" key="app-history" />
                <TabPane tab="App History" key="mobile-app-history" />
                <TabPane tab="Rating" key="rating"/>
            </Tabs>

            <Switch>
                <Route exact path={`${ROUTES.CANDIDATES_MOBILE_APP_HISTORY}/:id`}>
                    <MobileAppHistory />
                </Route>
                <Route exact path={`${ROUTES.CANDIDATES_CALL_HISTORY}/:id`}>
                    <CallHistory />
                </Route>
                <Route path={`${ROUTES.CANDIDATES_APP_HISTORY}/:id`}>
                    <ApplicationHistory />
                </Route>
                <Route path={`${ROUTES.CANDIDATES_RATING}/:id`}>
                    <Rating employee={employee} onRefresh={handleFetchEmployee}/>
                </Route>
                <Route path={`${path}/:id`}>
                    <Detail employee={employee} setEmployee={setEmployee} handleFetchEmployee={handleFetchEmployee} loading={loading}/>
                </Route>
            </Switch>
        </Container>
    );
};

export default Employee;
