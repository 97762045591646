import React, { useCallback, useEffect, useState } from 'react';

//antd
import { Col, Input, Row, Popover } from 'antd';

//component
import MarkdownPreviewWrapper from './markdown_it.style';
import { LodoshaButton } from '../UI/ui.style';
import ImagePicker from './ImagePicker';
import MarkdownGeneralSample from './MarkdownGeneralSample';
import MarkdownLayoutSample from './MarkdownLayoutSample';

//utils
import IFrameMarkdown from '../../containers/E-Learning/E-LearningComponents/IFrameMarkdown';

export default function Index({ data = {}, setData, isUpdate }) {
    const { TextArea } = Input;

    const [markdownVisibile, setMarkdownVisibile] = useState(false);
    const [imagePickerVisible, setImagePickerVisible] = useState(false);
    const [sampleVisible, setSampleVisible] = useState(false);
    const [layoutVisible, setLayoutVisible] = useState(false);
    const [isActive, setIsActive] = useState({
        markdown: true,
        css: false,
    });

    const handleChangeMarkdownFormat = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({ ...prev, [name]: value }));
    };

    const handleCloseMarkdownGenerator = () => {
        setMarkdownVisibile(!markdownVisibile);
    };

    const handlePopoverVisible = (visible, key) => {
        switch (key) {
            case 'image':
                setImagePickerVisible(visible);
                break;
            case 'sample':
                setSampleVisible(visible);
                break;
            case 'layout':
                setLayoutVisible(visible);
                break;
            default:
                return;
        }
    };

    const handleHidePopover = (key) => {
        switch (key) {
            case 'image':
                setImagePickerVisible(false);
                break;
            case 'sample':
                setSampleVisible(false);
                break;
            case 'layout':
                setLayoutVisible(false);
                break;
            default:
                return;
        }
    };

    const handleSetInitialState = useCallback(() => {
        if (isUpdate) {
            setMarkdownVisibile(true);
        }
    }, [isUpdate]);

    const handleToggleMarkdownCSS = (key) => {
        if (key === 'markdown') {
            setIsActive((prev) => ({ ...prev, markdown: true, css: false }));
        }
        if (key === 'css') {
            setIsActive((prev) => ({ ...prev, markdown: false, css: true }));
        }
    };

    useEffect(() => {
        handleSetInitialState();
        fetch("test",{
            
        })
    }, [handleSetInitialState]);

    return (
        <MarkdownPreviewWrapper>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                <div>
                    <a href="#footer">
                        <LodoshaButton
                            size="small"
                            style={{ marginRight: '1rem' }}
                            onClick={handleCloseMarkdownGenerator}
                            type={markdownVisibile && 'danger'}
                        >
                            {markdownVisibile ? 'Close Markdown' : 'Add Markdown'}
                        </LodoshaButton>
                    </a>
                    {markdownVisibile && (
                        <Popover
                            content={<ImagePicker onClose={handleHidePopover} />}
                            placement="right"
                            trigger="click"
                            color="#f4f4f4"
                            visible={imagePickerVisible}
                            onVisibleChange={(visible) => handlePopoverVisible(visible, 'image')}
                        >
                            <LodoshaButton size="small" style={{ marginRight: '1rem' }}>
                                Add Image
                            </LodoshaButton>
                        </Popover>
                    )}
                    {markdownVisibile && (
                        <Popover
                            placement="right"
                            trigger="click"
                            content={<MarkdownGeneralSample onClose={handleHidePopover} setData={setData} />}
                            visible={sampleVisible}
                            onVisibleChange={(visible) => handlePopoverVisible(visible, 'sample')}
                        >
                            <LodoshaButton size="small" style={{ marginRight: '1rem' }}>
                                Sample
                            </LodoshaButton>
                        </Popover>
                    )}
                    {markdownVisibile && (
                        <Popover
                            placement="right"
                            trigger="click"
                            content={<MarkdownLayoutSample onClose={handleHidePopover} setData={setData} />}
                            visible={layoutVisible}
                            onVisibleChange={(visible) => handlePopoverVisible(visible, 'layout')}
                        >
                            <LodoshaButton size="small">Layout</LodoshaButton>
                        </Popover>
                    )}
                </div>
            </div>
            {markdownVisibile && (
                <Row align="middle" gutter={[16]}>
                    <Col span={12} style={{ height: '400px' }}>
                        <div style={{ display: 'flex' }}>
                            <div
                                className={`text-area-title ${isActive.markdown ? 'active' : ''}`}
                                style={{ marginLeft: '1rem' }}
                                onClick={() => handleToggleMarkdownCSS('markdown')}
                            >
                                Markdown
                            </div>
                            <span style={{ margin: '0 .5rem', opacity: 0.8 }}>|</span>
                            <div
                                className={`text-area-title ${isActive.css ? 'active' : ''}`}
                                onClick={() => handleToggleMarkdownCSS('css')}
                            >
                                Style
                            </div>
                        </div>
                        {isActive.markdown ? (
                            <TextArea
                                className="text-area"
                                placeholder="Write markdown here..."
                                onChange={handleChangeMarkdownFormat}
                                name="markdown"
                                value={data.markdown}
                            />
                        ) : (
                            <TextArea
                                className="text-area"
                                placeholder="Write your style here..."
                                onChange={handleChangeMarkdownFormat}
                                name="css"
                                value={data.css ? data.css : ""}
                            />
                        )}
                    </Col>
                    <Col span={12} style={{ height: '400px' }}>
                        <div style={{ opacity: 0.8, marginLeft: '1rem', marginBottom: '.5rem' }}>Preview</div>
                        <div className="text-area div-as-text-area">
                            <IFrameMarkdown markdown={data.markdown ? data.markdown: ""} css={data.css} width="100%" height="100%" />
                        </div>
                    </Col>
                </Row>
            )}
            <div id="footer" />
        </MarkdownPreviewWrapper>
    );
}
