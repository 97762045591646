import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

//api
import {
    getJLPTContentSentences,
    updateSentenceOrder,
    deleteSentence,
} from '../../../../utils/api/E-Learning/sentence';

//antd
import { message, Space, Button, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

//components
import { LodoshaButton } from '../../../../components/UI/ui.style';
import SortableTable from '../../E-LearningComponents/SortableTable';
import DragHandler from '../../E-LearningComponents/DragHandler';
import SentenceModal from './SentenceModal';
import { getNewLastOrder } from '../../../../utils';

export default function SentenceList() {
    const { contentid } = useParams();
    const [loading, setLoading] = useState(false);
    const [sentences, setSentences] = useState([]);
    const [visible, setVisible] = useState(false);
    const [postData, setPostData] = useState({});
    const [updateVisible, setUpdateVisible] = useState(false);
    const isSubscribed = useRef(true);

    const handleFetchJLPTSentences = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getJLPTContentSentences(contentid);
            if (response && response.errorCode !== 0) {
                message.error(`Uh oh, couldn't yet JLPT sentences, ${response.message}`);
                setLoading(false);
                return;
            }
            setSentences(response?.data);
            setLoading(false);
        } catch (error) {
            message.error("Uh oh, couldn't yet JLPT sentences, please try again!");
            setLoading(false);
        }
    }, [contentid]);

    const data = useMemo(
        () =>
            sentences &&
            sentences.map((sentence) => ({
                ...sentence,
                key: sentence.id,
                khmer: sentence.khmer,
                japan: sentence.japan,
                explanation: sentence.explanation,
            })),
        [sentences]
    );

    const columns = [
        { title: '', width: '5%', render: () => <DragHandler />, align: 'center' },
        { title: 'JAPANESE', dataIndex: 'japan', width: '30%' },
        { title: 'KHMER', dataIndex: 'khmer', width: '30%' },
        { title: 'EXPLANATION', dataIndex: 'explanation', width: '25%' },
        {
            title: 'ACTIONS',
            dataIndex: 'actions',
            align: 'center',
            width: '10%',
            render: (text, row, index) => {
                return (
                    <Space size="small">
                        <Button title="Edit" type="primary" icon={<EditOutlined />} onClick={() => handleEdit(row)} />
                        <Button
                            title="Delete"
                            type="danger"
                            icon={<DeleteOutlined />}
                            onClick={(e) => handleDelete(row.id)}
                        />
                    </Space>
                );
            },
        },
    ];

    const handleEdit = (row) => {
        setUpdateVisible(true);
        setPostData(row);
    };

    const handleDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this sentence?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await deleteSentence(id);
                    if (response && response.errorCode === 0) {
                        message.success(`Deleted successfully!`);
                        handleFetchJLPTSentences();
                    } else {
                        message.error(`Couldn't delete JLPT content sentence, ${response.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request failed!`, error);
                }
            },
            onCancel() {},
        });
    };

    const Upsert = useCallback(
        () =>
            visible && (
                <SentenceModal
                    postData={postData}
                    visible={visible}
                    setPostData={setPostData}
                    isCreate
                    title="Add New Sentence"
                    onRefresh={handleFetchJLPTSentences}
                    setVisible={setVisible}
                />
            ),
        [visible, postData, handleFetchJLPTSentences]
    );

    const Update = useCallback(
        () =>
            updateVisible && (
                <SentenceModal
                    postData={postData}
                    visible={updateVisible}
                    setPostData={setPostData}
                    isUpdate
                    title="Update Sentence"
                    onRefresh={handleFetchJLPTSentences}
                    setVisible={setUpdateVisible}
                />
            ),
        [updateVisible, handleFetchJLPTSentences, postData]
    );

    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchJLPTSentences();
        }
        return () => (isSubscribed.current = false);
    }, [handleFetchJLPTSentences]);

    useEffect(() => {
        let lastOrder = getNewLastOrder(sentences);
        setPostData((prev) => ({ ...prev, order: lastOrder, jlptContentId: contentid }));
    }, [sentences, contentid]);

    return (
        <div>
            <LodoshaButton size="small" type="primary" className="new-sentence-btn" onClick={() => setVisible(true)}>
                <PlusOutlined /> Add Sentence
            </LodoshaButton>
            <Upsert />
            <Update />
            <SortableTable
                data={data}
                columns={columns}
                setLoading={setLoading}
                loading={loading}
                onUpdateOrder={updateSentenceOrder}
                size="small"
            />
        </div>
    );
}
