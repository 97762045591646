import { Col, Form, InputNumber, message, Modal, Row, Select } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';

//components
import {
    LodoshaButton,
    LodoshaDatePicker,
    LodoshaInput,
    LodoshaSelect,
    LodoshaTextArea,
} from '../../components/UI/ui.style';

//utils and constants
import API from '../../utils/api';
import { dataJobLevels, dataJobTypes } from '../../utils/data';

export default function ModalCareer({ isCreate, isUpdate, title, isVisible, setVisible, data = [], onRefresh }) {
    const [form] = Form.useForm();
    const { Option } = Select;
    const [postData, setPostData] = useState(data);
    const [loading, setLoading] = useState(false);

    const items = [
        {
            type: 'text',
            label: 'Job Title',
            name: 'job_title',
            value: postData?.job_title,
            required: true,
        },
        {
            type: 'select',
            label: 'Job Type',
            name: 'job_type',
            value: postData?.job_type,
            required: true,
            span: 12,
            options: dataJobTypes,
        },
        {
            type: 'select',
            label: 'Job Level',
            name: 'job_level',
            value: postData?.job_level,
            required: true,
            span: 12,
            options: dataJobLevels,
        },

        {
            type: 'number',
            label: 'Hiring',
            name: 'hiring',
            value: postData?.hiring,
            required: true,
        },
        {
            type: 'number',
            label: 'Job Salary From',
            name: 'salary_from',
            value: postData?.salary_from,
            // required: true,
            span: 12,
        },
        {
            type: 'number',
            label: 'Job Salary To',
            name: 'salary_to',
            value: postData?.salary_to,
            // required: true,
            span: 12,
        },
        {
            type: 'date',
            label: 'Post Date',
            name: 'post_date',
            value: postData?.post_date ? moment(postData?.post_date, 'YYYY-MM-DD') : '',
            required: true,
            span: 12,
        },
        {
            type: 'date',
            label: 'Expiration Date',
            name: 'expiration_date',
            value: postData?.expiration_date ? moment(postData?.expiration_date, 'YYYY-MM-DD') : '',
            required: true,
            span: 12,
        },
        {
            type: 'textarea',
            label: 'Job Description',
            name: 'job_description',
            value: postData?.job_description,
            required: true,
        },
        {
            type: 'textarea',
            label: 'Job Responsibility',
            name: 'job_resposibility',
            value: postData?.job_resposibility,
            required: true,
        },
        {
            type: 'textarea',
            label: 'Job Requiremment',
            name: 'job_requirement',
            value: postData?.job_requirement,
            required: true,
        },
    ];

    const handleClose = () => {
        setVisible(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPostData((prev) => ({ ...prev, [name]: value }));
    };

    const handleChangeNumberDateSelect = (value, name) => {
        console.log('value', value, 'name', name);
        setPostData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (value) => {
        console.log('postData', postData);
        setLoading(true);
        try {
            const response = isCreate
                ? await API().post(`/career/create`, postData)
                : await API().patch(`/career/${postData?.id}`, postData);
            //server error
            console.log('response', response);
            if (response) {
                if (response.data.errorCode !== 0) {
                    setLoading(false);
                    message.error(`Uh oh, something went wrong, ${response.data?.message}`);
                    return;
                } else {
                    //success
                    setVisible(false);
                    setLoading(false);
                    message.success(`Successfully ${isUpdate ? 'Update' : 'Create'} career`);
                    onRefresh && onRefresh();
                    return;
                }
            } else {
                //response undefined
                setLoading(false);
                message.error('Uh oh, something went wrong, response is undefined');
                return;
            }
        } catch (error) {
            message.error('Uh oh, something went wrong');
            setLoading(false);
        }
    };

    return (
        <Modal visible={isVisible} title={title} maskClosable={false} footer={null} onCancel={handleClose} width={768}>
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Row gutter={[16, 16]}>
                    {items.map((item, index) => (
                        <Col span={item.span ? item.span : 24} key={index}>
                            <Form.Item
                                label={item.label + ':'}
                                initialValue={item.value}
                                rules={[{ required: item.required, message: 'Data Required' }]}
                                name={item.name}
                            >
                                {item.type === 'select' ? (
                                    <LodoshaSelect
                                        name={item.name}
                                        onChange={(value) => handleChangeNumberDateSelect(value, item.name)}
                                    >
                                        {item.options.map((optionItem, index) => (
                                            <Option value={optionItem.value} key={index}>
                                                {optionItem.text}
                                            </Option>
                                        ))}
                                    </LodoshaSelect>
                                ) : item.type === 'date' ? (
                                    <LodoshaDatePicker
                                        name={item.name}
                                        onChange={(date, dateString) =>
                                            handleChangeNumberDateSelect(dateString, item.name)
                                        }
                                        placeholder={`Enter ${item.label.toLowerCase()}`}
                                    />
                                ) : item.type === 'textarea' ? (
                                    <LodoshaTextArea
                                        onChange={handleChange}
                                        name={item.name}
                                        placeholder={`Enter ${item.label.toLowerCase()}`}
                                    />
                                ) : item.type === 'number' ? (
                                    <InputNumber
                                        min={0}
                                        style={{ width: '100%' }}
                                        size="large"
                                        onChange={(value) => handleChangeNumberDateSelect(value, item.name)}
                                        placeholder={`Enter ${item.label.toLowerCase()}`}
                                    />
                                ) : (
                                    <LodoshaInput
                                        value={item.value}
                                        onChange={handleChange}
                                        name={item.name}
                                        placeholder={`Enter ${item.label.toLowerCase()}`}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    ))}
                    <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <LodoshaButton htmlType="submit" type="primary" loading={loading}>
                            {isUpdate ? 'Update' : 'Create'}
                        </LodoshaButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
