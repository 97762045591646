import styled from "@emotion/styled";

const RatingWrapper = styled.div`
    h1{
        color: #1c1c1c;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 17px;
        margin-bottom: 15px;
    }
    p{
        margin-bottom: 0;
        font-size: 14px;
    }

    .btn-style{
        background: #192c81;
        color: white;
        height: 40px;
        width: 120px;
        border-radius: 5px;
    }
`
export {RatingWrapper};