import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, LodoshaButton } from '../../../components/UI/ui.style.js';

//ant design
import { Button, Space, Modal, message } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

//api
import { getLessonsByChapter, deleteLesson, updateLessonOrder } from '../../../utils/api/E-Learning/skills';

//components
import LessonModal from './LessonModal.js';

//constants & utils
import { ELEARNING } from '../../../constants/routes.js';
import { lessonTypeToText } from '../../../utils/data.js';
import DragHandler from '../E-LearningComponents/DragHandler.js';
import SortableTable from '../E-LearningComponents/SortableTable.js';
import { getELearningItems, getNewLastOrder } from '../../../utils/index.js';
import NextPrevActionButtonGroup from '../E-LearningComponents/NextPrevActionButtonGroup.js';

const LessonList = () => {
    const { menuid, chapterid } = useParams();
    const history = useHistory();

    const isSubscribed = useRef(true);

    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [lessons, setLessons] = useState([]);
    const [postData, setPostData] = useState([]);

    const handleFetchLessons = useCallback(
        async (clickedItemId) => {
            try {
                setLoading(true);
                const response = await getLessonsByChapter(clickedItemId ? clickedItemId : chapterid);

                if (response) {
                    if (response.errorCode !== 0) {
                        setLoading(false);
                        message.error(`Could not get lessons, ${response.message}`);
                        return;
                    }

                    setLessons(response && response.data);
                    setLoading(false);
                    return;
                } else {
                    setLoading(false);
                    message.error(`Could not get lessons, Response is undefined`);
                    return;
                }
            } catch (error) {
                setLoading(false);
                message.error('Something went wrong');
            }
        },
        [chapterid]
    );

    const handleRow = (record, index) => {
        return {
            onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                //save lessons to localstorage
                try {
                    const eLearningItems = getELearningItems(lessons, 'name');
                    localStorage.setItem('e-learning-lessons', JSON.stringify(eLearningItems));
                    history.push(`${ELEARNING}/${menuid}/${chapterid}/${record.id}`);
                } catch (error) {
                    message.error('Set to local storage failed');
                }
            },
        };
    };

    const handleEdit = (e, row) => {
        e.stopPropagation();
        setUpdateVisible(true);
        setPostData(row);
    };

    const handleDelete = (e, id) => {
        e.stopPropagation();
        Modal.confirm({
            title: 'Are you sure you want to delete this post?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await deleteLesson(id);
                    if (response && response.errorCode === 0) {
                        message.success(`Deleted successfully!`);
                        handleFetchLessons();
                    } else {
                        message.error(`Failed, ${response && response.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request failed!`);
                }
            },
            onCancel() {},
        });
    };

    let columns = [
        { title: '#', dataIndex: 'no', align: 'center', width: '5%', fixed: 'left', render: () => <DragHandler /> },
        {
            title: 'NAME',
            dataIndex: 'name',
            fixed: 'left',
        },
        { title: 'NAME_JP', dataIndex: 'name_jp' },
        { title: 'TYPE', dataIndex: 'typeName', align: 'center', width: '12%' },
        { title: 'SUB-TYPE', dataIndex: 'sub_type', align: 'center', width: '15%' },
        {
            title: '',
            dataIndex: 'actions',
            align: 'center',
            width: '10%',
            render: (text, row, index) => (
                <Space>
                    <Button type="primary" title="Edit" onClick={(e) => handleEdit(e, row)} icon={<EditOutlined />} />
                    <Button
                        type="danger"
                        title="Delete"
                        onClick={(e) => handleDelete(e, row.id)}
                        icon={<DeleteOutlined />}
                    />
                </Space>
            ),
        },
    ];
    let data = useMemo(() => {
        return (
            lessons &&
            lessons?.map((lesson, index) => ({
                ...lesson,
                key: index,
                no: index + 1,
                name: lesson.name,
                name_jp: lesson.name_jp,
                type: lesson.type,
                typeName: lessonTypeToText[lesson.type - 1],
                sub_type: lesson.sub_type,
            }))
        );
    }, [lessons]);

    const Upsert = useCallback(
        () =>
            visible && (
                <LessonModal
                    title="Add New Lesson"
                    visible={visible}
                    setVisible={setVisible}
                    postData={postData}
                    setPostData={setPostData}
                    onRefresh={handleFetchLessons}
                    isCreate={true}
                />
            ),
        [visible, handleFetchLessons, postData]
    );

    const Update = useCallback(() => {
        return (
            updateVisible && (
                <LessonModal
                    title="Update Lesson"
                    visible={updateVisible}
                    setVisible={setUpdateVisible}
                    onRefresh={handleFetchLessons}
                    setPostData={setPostData}
                    postData={postData}
                    isUpdate={true}
                />
            )
        );
    }, [updateVisible, handleFetchLessons, postData]);

    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchLessons();
        }
        return () => {
            isSubscribed.current = false;
        };
    }, [menuid, handleFetchLessons]);

    //auto order and set chapterid to postData
    useEffect(() => {
        let lastOrder = getNewLastOrder(lessons);
        console.log(lastOrder);
        setPostData((prev) => ({ ...prev, order: lastOrder, chapter_id: chapterid, type: 2 }));
    }, [lessons, visible, chapterid]);

    return (
        <Container>
            <div className="btn-margin-bottom">
                <NextPrevActionButtonGroup
                    onClickAction={handleFetchLessons}
                    currentItemId={chapterid}
                    localStorageKey="e-learning-chapters"
                />
            </div>
            <LodoshaButton type="primary" className="btn-margin-bottom" onClick={() => setVisible(true)}>
                <PlusOutlined />
                Add New
            </LodoshaButton>
            <Upsert />
            <Update />
            <SortableTable
                data={data}
                loading={loading}
                setLoading={setLoading}
                onUpdateOrder={updateLessonOrder}
                columns={columns}
                handleRow={handleRow}
            />
        </Container>
    );
};

export default LessonList;
