import React, { useContext, useState } from 'react';

import { Form, Modal, Col, Row, Input, message } from 'antd';

import { LodoshaButton } from '../../components/UI/ui.style';
import API from '../../utils/api';
import AppContext from '../../contexts/AppContext';

export default function ModalPassword({ visible, setVisible, user, setUser }) {
    const { handleUnauthorizedUser } = useContext(AppContext);
    const [newPassword, setNewPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSendPassword = async (newData) => {
        try {
            setLoading(true);
            const response = await API().post(`/user/sendPassword-userid`, newData);
            setLoading(false);
            if (response && response.data.errorCode === 0) {
                setVisible(false);
                message.success('Account and Password sent.');
            } else if (response && response.data.errorCode === 403) {
                message.error('Account login with facebook not allow to sent.');
            } else {
                message.error('Account and Password not sent.');
            }
        } catch (error) {
            if (error.response.data.status === 401) {
                message.error(`Unauthorized`);
                handleUnauthorizedUser();
                return;
            }
            message.error('Not able to send password.');
            setLoading(false);
        }
    };

    const handleChangePassword = (event) => {
        event.preventDefault();
        let newData = user;
        newData['new_password'] = newPassword;
        newData['type'] = newData['role'];
        handleSendPassword(newData);
        setNewPassword('');
    };

    const handleCancel = () => {
        setVisible(false);
        setUser([]);
    };

    return (
        <Modal visible={visible} footer={null} onCancel={handleCancel} maskClosable={false}>
            <Form layout="vertical">
                <h2>Send Password</h2>
                <Form.Item label="New Password:">
                    <Row gutter={16}>
                        <Col span={20}>
                            <Input
                                type="text"
                                placeholder="Password"
                                style={{ marginRight: 16 }}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </Col>
                    </Row>
                </Form.Item>

                <LodoshaButton type="primary" disabled={!newPassword} loading={loading} onClick={handleChangePassword}>
                    {'Change'}
                </LodoshaButton>
            </Form>
        </Modal>
    );
}
