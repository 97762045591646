import React, { useState } from 'react';

//antd
import { Modal, Input, Col, Row, message } from 'antd';

//components
import { LodoshaButton } from '../../components/UI/ui.style';

//utils and constants
import { changeUserEmail, changeUserPhone } from '../../utils/api/user';

export default function ModalInfo({ user = {}, visible = false, setVisible, setUser }) {
    const [data, setData] = useState(user);
    const [loading, setLoading] = useState({
        changePhone: false,
        changeEmail: false,
    });

    const handleCancel = () => {
        setUser([]);
        setVisible(false);
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        setData((prev) => ({ ...prev, [name]: value }));
    };

    const handleChangeUserPhone = async () => {
        setLoading((prev) => ({ ...prev, changePhone: true }));
        try {
            const response = await changeUserPhone(user.id, data.phone);
            if (response) {
                if (response.errorCode === 0) {
                    message.success(`Successfully changed phone number.`);
                    user.phone = data.phone;
                    setLoading((prev) => ({ ...prev, changePhone: false }));
                    handleCancel();
                } else {
                    setLoading((prev) => ({ ...prev, changePhone: false }));
                    message.error(`Uh oh, couldn't change user phone: ${response.message}`);
                    return;
                }
            } else {
                setLoading((prev) => ({ ...prev, changePhone: false }));
                message.error(`Uh oh, couldn't change user phone. Response is undefined`);
            }
        } catch (error) {
            message.error(`Uh oh, couldn't change user phone, error: ${error}`);
        }
    };

    const handleChangeUserEmail = async () => {
        setLoading((prev) => ({ ...prev, changeEmail: true }));
        try {
            const response = await changeUserEmail(user.id, data.email);
            if (response) {
                if (response.errorCode === 0) {
                    message.success(`Successfully changed email address.`);
                    user.email = data.email;
                    setLoading((prev) => ({ ...prev, changeEmail: false }));
                    handleCancel();
                } else {
                    setLoading((prev) => ({ ...prev, changeEmail: false }));
                    message.error(`Uh oh, couldn't change email address: ${response.message}`);
                    return;
                }
            } else {
                setLoading((prev) => ({ ...prev, changeEmail: false }));
                message.error(`Uh oh, couldn't change email address. Response is undefined`);
            }
        } catch (error) {
            message.error(`Uh oh, couldn't change email address, error: ${error}`);
        }
    };

    return (
        <Modal visible={visible} onCancel={handleCancel} footer={null} maskClosable={false}>
            <h2>User Information</h2>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <span style={{ display: 'block', marginBottom: '.2rem' }}>Phone:</span>
                    <Input
                        type="text"
                        placeholder="Phone"
                        style={{ marginRight: 8, width: '75%' }}
                        name="phone"
                        value={data.phone}
                        onChange={handleChange}
                        size="large"
                    />

                    <LodoshaButton type="primary" onClick={handleChangeUserPhone} loading={loading.changePhone}>
                        Change
                    </LodoshaButton>
                </Col>
                <Col span={24}>
                    <span style={{ display: 'block', marginBottom: '.2rem' }}>Email:</span>
                    <Input
                        type="text"
                        placeholder="Email"
                        style={{ marginRight: 8, width: '75%' }}
                        name="email"
                        value={data.email}
                        onChange={handleChange}
                        size="large"
                    />
                    <LodoshaButton type="primary" onClick={handleChangeUserEmail} loading={loading.changeEmail}>
                        Change
                    </LodoshaButton>
                </Col>
            </Row>
        </Modal>
    );
}
