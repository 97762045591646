import React, { useState, useMemo } from 'react';

//api
import { postNewExamLevel, updateExamLevel } from '../../../utils/api/E-Learning/exam';

//ant design
import { Modal, Row, Col, Form, message, Select, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

//utils and constants
import { examType } from '../../../utils/data';

//components
import { LodoshaInput, LodoshaButton } from '../../../components/UI/ui.style';
import BannerUploader from '../E-LearningComponents/BannerUploader';

export default function ExamLevelModal({
    title,
    visible,
    setVisible,
    onRefresh,
    setPostData,
    postData,
    isUpdate,
    isCreate,
}) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(postData);
    const [pdfFile, setPdfFile] = useState([]);
    const [file, setFile] = useState();

    const { Option } = Select;
    const { Dragger } = Upload;

    const examFormFields = useMemo(
        () => [
            {
                label: 'Title',
                type: 'text',
                name: 'title',
                value: data?.title,
                required: true,
                span: 24,
            },
            {
                label: 'Level',
                type: 'number',
                name: 'level',
                value: data?.level,
                span: 24,
                required: true,
            },
            {
                label: 'Possible Question Count',
                type: 'number',
                name: 'question_count',
                value: data?.question_count,
                span: 24,
                required: true,
            },
            {
                label: 'Type',
                type: 'text',
                name: 'type',
                value: data?.type,
                required: true,
                span: 24,
                isSelect: true,
            },
        ],
        [data]
    );

    //close modal
    const handleCancel = (e) => {
        setVisible(false);
        setPostData({});
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        console.log(name, value);
        setData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    //select
    const handleChangeType = (value) => {
        setData((prev) => ({ ...prev, type: value }));
    };

    const beforeUpload = () => {
        return false;
    };

    const handleChangeFile = (info, fieldname) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        fileList = fileList.map((file) => {
            if (file.response) {
                file.url = file.response.url;
            }
            return file;
        });
        // important when uploading to server
        if (fileList.length > 0) {
            fileList[0].originFileObj.fieldname = fieldname;
        }
        setPdfFile(fileList);
    };

    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            for (const key in data) {
                formData.append(key, data[key]);
            }
            formData.append(pdfFile[0]?.originFileObj.fieldname, pdfFile[0]?.originFileObj);
            formData.append('chapter-image', file);

            setLoading(true);
            const response = isCreate
                ? await postNewExamLevel(formData)
                : isUpdate
                ? await updateExamLevel(data?.id, formData)
                : {};

            if (response && response.errorCode !== 0) {
                setLoading(false);
                message.error(`Oh oh, something went wrong.`, response.message);
                return;
            }

            if (response) {
                response && message.success(isCreate ? 'New exam added.' : isUpdate ? 'Successfully updated exam' : '');
                setLoading(false);
                onRefresh();
                handleCancel();
            } else {
                message.error(`Uh oh, ${isCreate ? 'add new exam' : isUpdate ? 'updating exam' : ''} failed!`);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
            message.error(`Uh oh, ${isCreate ? 'add new exam' : isUpdate ? 'updating exam' : ''} failed!`);
        }
    };

    return (
        <Modal
            width={768}
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
                    <div>{title}</div>
                    <BannerUploader imageId={data && data.image_id} setFile={setFile} />
                </div>
            }
            visible={visible}
            onCancel={handleCancel}
            maskClosable={false}
            footer={null}
        >
            <Form layout="vertical" form={form} onFinish={() => handleSubmit()}>
                <Row gutter={24}>
                    {examFormFields?.map((field, index) => {
                        const { type, label, name, value, required, disabled, span, isSelect } = field;
                        return (
                            <Col span={span} key={index}>
                                <Form.Item
                                    rules={[{ required: required, message: 'Data required!' }]}
                                    label={`${label}:`}
                                    name={name}
                                    initialValue={value}
                                >
                                    {isSelect ? (
                                        <Select onChange={handleChangeType} size="large">
                                            {examType.map((type, index) => (
                                                <Option value={type} key={index}>
                                                    {type}
                                                </Option>
                                            ))}
                                        </Select>
                                    ) : (
                                        <LodoshaInput
                                            type={type}
                                            disabled={disabled && true}
                                            onChange={handleChange}
                                            value={value}
                                            name={name}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        );
                    })}
                    <Col span={24}>
                        <Form.Item>
                            {data?.type === 'pdf file' && (
                                <Dragger
                                    name="pdf"
                                    beforeUpload={beforeUpload}
                                    fileList={pdfFile}
                                    onChange={(info) => handleChangeFile(info, 'pdf')}
                                    accept="application/pdf"
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                </Dragger>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
                        <LodoshaButton
                            key="cancel"
                            style={{ marginRight: 16 }}
                            htmlType="button"
                            onClick={handleCancel}
                        >
                            Cancel
                        </LodoshaButton>
                        <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                            {isCreate ? 'Add New' : isUpdate ? 'Update' : ''}
                        </LodoshaButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
