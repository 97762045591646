import React, { useState } from 'react';
import { Form, Row, Col, message } from 'antd';
import {
  Container,
  LodoshaButton,
  LodoshaInputPassword
} from '../../components/UI/ui.style';
import { changePassword } from '../../utils/api/account'

const ChangePassword = () => {

  const [form] = Form.useForm();

  const INITIAL_STATE = {
    old_password: '',
    new_password: '',
    re_new_password: ''
  };

  const [spinning, setSpinning] = useState(false);

  const items = [
    {type: 'input_password', label:'Old Password', name:'old_password', required: true },
    {type: 'input_password', label:'New Password', name:'new_password', required: true},
    {type: 'input_password', label:'Confirm New Password', name:'re_new_password', required: true },
  ];

  const handleSubmit = async (values) => {

    if (values['new_password'] !== values['re_new_password']) {
      message.error('New Password and Confirm Password not same!')
      return;
    }

    setSpinning(true);

    const response = await changePassword(values);
    if (response && response.errorCode === 0) {
      form.setFieldsValue(INITIAL_STATE);
      message.success(`Change successfully!`);
    } else {
      message.error(`Failed! ${response.message}`);
    }
    
    setSpinning(false);
  }

  return (
    <Container>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={24}  style={{width: '40%'}}>
          {items.map((item) => (
              <Col key={item.name} span={item.span ? item.span : 24}>
                  <Form.Item
                      name={item.name}
                      label={item.label + ':'}
                      rules={
                        [
                          { 
                            required: item.required,
                            min: 6, 
                            message: 'Password must be minimum 6 characters!' 
                          }
                        ]}
                      
                      valuePropName={'value'}
                  >
                      {
                        <LodoshaInputPassword type={item.type} placeholder={'Enter ' + item.label.toLowerCase()} />
                      }
                  </Form.Item>
              </Col>
          ))}
        </Row>
        <Form.Item>
          <LodoshaButton type="primary" htmlType="submit" loading={spinning} className="lodosha-button">
            Change password
          </LodoshaButton>
        </Form.Item>
      </Form>
    </Container>
  );
}

export default ChangePassword;
