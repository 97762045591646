import React from 'react';
import { Modal, Spin, Select, message } from 'antd';
import { LodoshaButton, LodoshaSelect } from '../../components/UI/ui.style';
import API from '../../utils/api';

const { Option } = Select;

const Interest = ({ visible, setVisible, employee = {} }) => {
    const [loading, setLoading] = React.useState(false);
    const [jobs, setJobs] = React.useState([]);
    const [selectedJobs, setSelectedJobs] = React.useState([]);

    React.useEffect(() => {
        const handleFetchJobs = async () => {
            setLoading(true);
            const response = await API().get(`/job/all`);
            // console.log(response.data);
            setJobs(response.data.data);
            setLoading(false);
        };

        visible && handleFetchJobs();
    }, [visible]);

    React.useEffect(() => {
        const handleFetchInterestedJobs = async () => {
            setLoading(true);
            const response = await API().get(`/employee/wantjob?employee_id=${employee.id}`);
            // console.log(response);
            const jobIds = response.data.map((res) => res.job.id);
            setSelectedJobs(jobIds);
            setLoading(false);
        };

        !!employee.id && visible && handleFetchInterestedJobs();
    }, [employee, visible]);

    const handleChange = (value) => {
        // console.log(`selected ${value}`);
        setSelectedJobs(value);
    };

    const handleSelect = async (value) => {
        // console.log(`selected ${value}`);
        try {
            await API().post(`/employee/wantjob/create`, { employee_id: employee.id, job_id: value });
        } catch (error) {
            message.error(`Error, Add Interesing Job not worked.`);
        }
    };

    const handleDeselect = async (value) => {
        // console.log(`deselected ${value}`);
        try {
            await API().post(`/employee/wantjob/delete`, { employee_id: employee.id, job_id: value });
        } catch (error) {
            message.error(`Error, Add Interesing Job not worked.`);
        }
    };

    const handleSelectAll = async () => {
        const selectedAll = jobs.filter((job) => !selectedJobs.includes(job.id));
        // console.log(jobs, selectedAll);
        selectedAll.map((data) => {
            return handleSelect(data.id);
        });
        setSelectedJobs(jobs.map((data) => data.id));
    };

    return (
        <Modal
            width={576}
            maskClosable={false}
            visible={visible}
            title="Interesting Jobs"
            onCancel={() => setVisible(false)}
            footer={[
                <LodoshaButton key="cancel" onClick={() => setVisible(false)}>
                    Done
                </LodoshaButton>,
            ]}
        >
            <Spin tip="Loading..." spinning={loading}>
                <LodoshaSelect
                    mode="multiple"
                    placeholder="Interesting Jobs"
                    style={{ width: '100%', marginBottom: 16 }}
                    value={selectedJobs}
                    onChange={handleChange}
                    onSelect={handleSelect}
                    onDeselect={handleDeselect}
                >
                    {jobs.map(({ id: value, name }) => (
                        <Option key={value} value={value}>
                            {name}
                        </Option>
                    ))}
                </LodoshaSelect>

                <LodoshaButton size="large" type="primary" htmlType="button" onClick={handleSelectAll}>
                    All interesting jobs
                </LodoshaButton>
            </Spin>
        </Modal>
    );
};

export default Interest;
