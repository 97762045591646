
import API from '../api';

export const getBlogs = (page = 1) => {
    const url = page === 1 ? `/blog` : `/blog?offset=${(page - 1) * 15}`;
    return API().get(url)
            .then(response => {
                return response.data;
            })
            .catch(err => { 
                console.log('err: ', err) 
            });
};

export const createBlog = async (formData) => {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    return API()
        .post(`/blog/create`, formData, config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
};

export const updateBlog = async (id, formData) => {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    return API()
        .patch(`/blog/${id}`, formData, config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
};

export const deleteBlog = (id) => {
    return API().delete(`/blog/${id}`)
            .then(response => {
                return response.data;
            }).catch(err => {
                console.log('err: ', err) 
            })
}

export const visibleBlog = async (id, visible) => {

    const data = { visible };

    return API().put(`/blog/${id}`, data)
            .then(response => {
                return response.data;
            }).catch(err => {
                console.log('err: ', err) 
            })
};