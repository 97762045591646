import React, { useState, useEffect, useCallback } from "react";
import { Modal, Form, Row, Col, Space } from "antd";
import { LodoshaButton, LodoshaInput } from "../../components/UI/ui.style";
import API from "../../utils/api";

const Translate = ({ visible, setVisible, employer }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState();
  const [language, setLanguage] = useState();

  const handleFetchTranslate = useCallback(async () => {
    setLoading(true);
    const responseLanguage = await API().get(`/language/name?country=japan`);
    const languageId = responseLanguage.data && responseLanguage.data.data.id;

    const response = await API().get(
      `/employer/language/getEmployerLanguage?language_id=${languageId}&employer_id=${employer.id}`
    );

    if (response.data.errorCode === 404) {
      setLanguage((prevState) => ({ ...prevState, language_id: languageId, employer_id: employer.id }));
    } else {
      setLanguage(response.data.data);
      form.setFieldsValue(response.data.data);
    }
    setLoading(false);
  }, [employer, form]);

  useEffect(() => {
    handleFetchTranslate();
  }, [handleFetchTranslate]);

  const handleCancel = (e) => {
    setVisible(false);
  };

  const handleChange = (name, value) => {
    // console.log(name, value);
    setLanguage((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    language.employer_id
      ? await API().post(`/employer/language/create`, language)
      : await API().patch(`/employer/language/${language.id}`, language);

    await API().patch(`/employer/status/${employer.id}`, { status: 3 }); // Publish
    setLanguage();
    setVisible(false);
  };

  const items = [
    {
      type: "text",
      label: "Company Name",
      name: "company_name",
      value: employer && employer.company_name,
    },
    {
      type: "text",
      label: "Representation Name",
      name: "representation_name",
      value: employer && employer.representation_name,
    },
    { type: "text", label: "Address", name: "address", value: employer && employer.address },
    { type: "text", label: "Message", name: "message", value: employer && employer.message },
  ];

  const translateItems = [
    { type: "text", label: "Company Name", name: "company_name", value: language?.company_name, required: true },
    {
      type: "text",
      label: "Representation Name",
      name: "representation_name",
      value: language?.representation_name,
      required: true,
    },
    { type: "text", label: "Address", name: "address", value: language?.address, required: true },
    { type: "text", label: "Message", name: "message", value: language?.message },
  ];

  return (
    <Modal visible={visible} maskClosable={false} title="Translate" onCancel={handleCancel} width={768} footer={null}>
      <Row gutter={24}>
        <Col span={12}>
          <Form layout="vertical">
            <h2 style={{ marginBottom: 16 }}>Base Information</h2>

            {items.map((item) => (
              <Form.Item key={item.name} name={item.name} label={item.label} initialValue={item.value}>
                <LodoshaInput disabled />
              </Form.Item>
            ))}
          </Form>
        </Col>
        <Col span={12}>
          <Form form={form} layout="vertical">
            <h2 style={{ marginBottom: 16 }}>Translate Information</h2>

            {translateItems.map((item) => (
              <Form.Item
                key={item.name}
                name={item.name}
                label={item.label}
                initialValue={item.value}
                rules={[{ required: item.required, message: "Data required!" }]}
              >
                <LodoshaInput onChange={(e) => handleChange(item.name, e.target.value)} />
              </Form.Item>
            ))}
          </Form>
        </Col>
        <Col span={24} style={{ textAlign: "center", marginTop: 16 }}>
          <Space direction="horizontal" size="middle">
            <LodoshaButton key="cancel" htmlType="button" onClick={handleCancel}>
              Cancel
            </LodoshaButton>
            <LodoshaButton key="submit" loading={loading} type="primary" htmlType="button" onClick={handleSubmit}>
              {language?.id ? "Update" : "Create"}
            </LodoshaButton>
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};

export default Translate;
