import { FacebookFilled } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';

export default function FBLoginButton() {
    const [fbLoginLoading, setFBLoginLoading] = useState(false);
    const { handleFacebookLogin, handleFacebookLogout, fbAccessToken } = useContext(AuthContext);
    return (
        <Button
            type="primary"
            icon={<FacebookFilled />}
            loading={fbLoginLoading}
            style={{ borderRadius: '5px' }}
            onClick={() => {
                setFBLoginLoading(true);
                if (fbAccessToken) {
                    handleFacebookLogout((cb) => {
                        cb && setFBLoginLoading(false);
                    });
                } else {
                    handleFacebookLogin((cb) => {
                        cb && setFBLoginLoading(false);
                    });
                }
            }}
        >
            {fbAccessToken ? 'Logout' : 'Login'}
        </Button>
    );
}
