import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Space, Table, Button, message } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import ModalProducts from "./ModalProducts";
import { Container, LodoshaButton } from "../../components/UI/ui.style";
import getTextDisplay, { dataType } from "../../utils/data";
import { getPartnerProducts } from "../../utils/api/partner_product";

const PartnerProducts = () => {
  const location = useLocation();
  const partner = location.state;
  const isSubscribed = useRef(true);
  const [loading, setLoading] = useState(false);
  const [partnerProducts, setPartnerProducts] = useState([]);
  const [product, setProduct] = useState();
  const [visibleProducts, setVisibleProducts] = useState(false);

  const handleFetchData = useCallback(
    async (page = 1) => {
      setLoading(true);
      try {
        getPartnerProducts(partner.id).then((response) => {
          if (isSubscribed.current) {
            response.data && setPartnerProducts(response.data);
            setLoading(false);
          }
        });
      } catch (error) {
        setLoading(false);
        message.error(`Uh oh, request failed!`);
      }
      isSubscribed.current && setLoading(false);
    },
    [partner]
  );

  useEffect(() => {
    handleFetchData();

    return () => {
      isSubscribed.current = false;
    };
  }, [handleFetchData]);

  const handleRow = (record) => {
    return {
      onClick: (e) => {
        e.stopPropagation();
      }, // click row
    };
  };

  const handleUpdate = (e, record) => {
    e.stopPropagation();
    setProduct(record);
    setVisibleProducts(true);
  };

  const handleDelete = (e, record) => {
    e.stopPropagation();
  };

  const dataSource = partnerProducts.length
    ? partnerProducts.map((prod, index) => {
        return {
          ...prod,
          key: prod.id,
          no: index + 1,
        };
      })
    : [];

  const columns = [
    { title: "NO", dataIndex: "no", key: "no", width: 50, sorter: () => {} },
    { title: "NAME", dataIndex: "name", key: "name" },
    {
      title: "TYPE",
      dataIndex: "type",
      key: "gender",
      sorter: () => {},
      render: (type) => <span>{getTextDisplay(type, dataType.PARTNER_PRODUCT_TYPE)}</span>,
    },
    { title: "PRICE FROM", dataIndex: "price_from", key: "price_from", sorter: () => {} },
    { title: "PRICE TO", dataIndex: "price_to", key: "price_to", sorter: () => {} },
    { title: "NOTE", dataIndex: "note", key: "note", sorter: () => {} },
    {
      title: "",
      key: "actions",
      render: (record) => (
        <Space>
          <Button type="primary" icon={<EditOutlined />} onClick={(e) => handleUpdate(e, record)} />
          <Button type="danger" icon={<DeleteOutlined />} onClick={(e) => handleDelete(e, record)} />
        </Space>
      ),
    },
  ];

  return (
    <Container>
      <Row justify="space-between" align="middle" style={{ marginBottom: 32 }}>
        <Col>
          <LodoshaButton type="primary" icon={<PlusOutlined />} onClick={() => setVisibleProducts(true)}>
            New
          </LodoshaButton>
          {visibleProducts && (
            <ModalProducts
              visible={visibleProducts}
              setVisible={setVisibleProducts}
              partner={partner}
              product={product}
              setPartnerProducts={setPartnerProducts}
            />
          )}
        </Col>
        <Col>
          <h1 style={{ margin: 0 }}>Partner's Name: {partner?.name || "N/A"}</h1>
        </Col>
        <Col>
          <h1 style={{ margin: 0 }}>Contact Person: {partner?.contact_name || "N/A"}</h1>
        </Col>
        <Col>
          <h1 style={{ margin: 0 }}>Tel: {partner?.phone || "N/A"}</h1>
        </Col>
      </Row>
      <Table bordered loading={loading} columns={columns} dataSource={dataSource} onRow={handleRow} />
    </Container>
  );
};

export default PartnerProducts;
