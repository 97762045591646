import React, { useEffect, useContext, useMemo, useCallback } from 'react';
import { Modal, Form, Row, Col, Select, Space } from 'antd';
import { LodoshaSelect, LodoshaButton, LodoshaInput } from './UI/ui.style';
import {
    dataJftBasicLevel,
    dataGender,
    dataWeddingStatus,
    dataJapaneseLevel,
    dataStudyLevel,
    dataReligion,
    dataEverToJapan,
    dataTagStatus,
    dataFinancialStatus,
    dataCandidateRecordType,
    dataPhoneCompany,
    dataNoOutboundCall,
} from '../utils/data';
import AppContext from '../contexts/AppContext';

const { Option } = Select;

const AdvanceSearch = ({ visible, setVisible, onSearch, entity, value, biRef }) => {
    const { dataJobs } = useContext(AppContext);
    const [form] = Form.useForm();
    const items = useMemo(() => {
        switch (entity) {
            case 'candidate':
                return [
                    { label: 'Name Or Phone Or Code', type: 'text', name: 'keyword', span: 24 },
                    { label: 'Job', type: 'select', name: 'job_id', options: dataJobs },
                    { label: 'Gender', type: 'select', name: 'gender', options: dataGender },
                    { label: 'Type', type: 'select', name: 'type', options: dataCandidateRecordType },
                    { label: 'Phone Company', type: 'select', name: 'phone_company', options: dataPhoneCompany },
                    { label: 'From Age', type: 'number', name: 'from_age' },
                    { label: 'To Age', type: 'number', name: 'to_age' },
                    { label: 'Japanese Level', type: 'select', name: 'japanses_level', options: dataJapaneseLevel },
                    { label: 'JFT Basic Level', type: 'select', name: 'jft_basic', options: dataJftBasicLevel },
                    { label: 'Wedding Status', type: 'select', name: 'wedding_status', options: dataWeddingStatus },
                    {
                        label: 'Have been in Japan before?',
                        type: 'select',
                        name: 'ever_to_japan',
                        options: dataEverToJapan,
                    },
                    { label: 'Study Level', type: 'select', name: 'study_level', options: dataStudyLevel },
                    { label: 'Religion', type: 'select', name: 'religion', options: dataReligion },
                    { label: 'Tag', type: 'select', name: 'tag', options: dataTagStatus },
                    { label: 'Financial', type: 'select', name: 'financial_status', options: dataFinancialStatus },
                    { label: 'No Outboud Call', type: 'select', name: 'no_call', options: dataNoOutboundCall },
                ];
            case 'employer':
                return [];

            case 'partner':
                return [];
            default:
                return [];
        }
    }, [entity, dataJobs]);

    const handleSubmit = async (fieldsValue) => {
        localStorage.setItem('advance-search', 'candidate');
        localStorage.setItem('advance-search-value', JSON.stringify(fieldsValue));

        onSearch(fieldsValue);
        setVisible(false);
    };

    const handleCancel = useCallback(() => {
        setVisible(false);
        form.resetFields();
        localStorage.removeItem('advance-search');
        localStorage.removeItem('advance-search-value');
        onSearch(null);
    }, [form, onSearch, setVisible]);

    useEffect(() => {
        visible && value && form.setFieldsValue(value);
        //assign handleCancel to parent component
        if (biRef && form) {
            biRef.handleCancel = handleCancel;
        }
    }, [visible, form, value, biRef, handleCancel]);

    return (
        <Modal
            width={768}
            maskClosable={false}
            visible={visible}
            title=""
            onCancel={() => handleCancel()}
            footer={null}
            centered
        >
            <>
                <h2 style={{ marginBottom: 16 }}>Filter of Candidate</h2>
                <Form form={form} layout="vertical" onFinish={handleSubmit}>
                    <Row gutter={24}>
                        {items &&
                            items.map((item) => (
                                <Col key={item.name} span={item.span ? item.span : 8}>
                                    <Form.Item
                                        name={item.name}
                                        label={item.label + ':'}
                                        rules={[{ required: item.required, message: 'Data required!' }]}
                                        valuePropName={item.type === 'switch' ? 'checked' : 'value'}
                                    >
                                        {item.type === 'select' ? (
                                            <LodoshaSelect
                                                mode={item.mode}
                                                allowClear={!!item.mode}
                                                placeholder="Select an option"
                                            >
                                                <Option key={'none'} value={null}>
                                                    None
                                                </Option>
                                                {item.options.map(({ value, text }) => (
                                                    <Option key={value} value={value}>
                                                        {text}
                                                    </Option>
                                                ))}
                                            </LodoshaSelect>
                                        ) : (
                                            <LodoshaInput
                                                type={item.type}
                                                placeholder={'Enter ' + item.label.toLowerCase()}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            ))}
                        <Col span={24}>
                            <Form.Item style={{ textAlign: 'center', marginTop: 16 }}>
                                <Space direction="horizontal" size="middle">
                                    <LodoshaButton key="cancel" htmlType="button" onClick={handleCancel}>
                                        Cancel
                                    </LodoshaButton>
                                    <LodoshaButton key="submit" type="primary" htmlType="submit">
                                        Search
                                    </LodoshaButton>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </>
        </Modal>
    );
};

export default AdvanceSearch;
