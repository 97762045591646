import { createAPIELearning } from '../../api';

export const importCulture = (data, file) => {
    console.log(data, file);
    const formData = new FormData();

    for (const key in data) {
        formData.append(key, data[key]);
    }

    formData.append('csv-file', file);

    return createAPIELearning()
        .post('/admin-chapter/import-culture', formData)
        .then((response) => response.data)
        .catch((err) => console.log(`importCulture: `, err));
};

//culture
export const getCulture = () => {
    return createAPIELearning()
        .get('/admin-culture')
        .then((response) => response.data)
        .catch((err) => err.response);
};

export const postNewCulture = (formData) => {
    const config = { 'content-type': 'multipart/form-data' }
    return createAPIELearning()
        .post('/admin-culture', formData, config)
        .then((response) => response.data)
        .catch((err) => console.log('postNewCulture', err));
};

export const updateCulture = (id, formData) => {
    const config = { 'content-type': 'multipart/form-data' }
    return createAPIELearning()
        .patch(`/admin-culture/${id}`, formData, config)
        .then((response) => response.data)
        .catch((err) => console.log('updateCulture', err));
};

export const deleteCulture = (id) => {
    return createAPIELearning()
        .delete(`/admin-culture/${id}`)
        .then((response) => response.data)
        .catch((err) => console.log('deleteCulture', err));
};
export const updateCultureOrder = (info) => {
    return createAPIELearning()
        .patch(`/admin-culture/update-order`, info)
        .then((response) => response.data)
        .catch((err) => console.log(`updateCultureOrder,${err.message}`));
};
export const getCultureBlogs = () => {
    return createAPIELearning()
        .get('/admin-culture-detail/')
        .then((response) => response.data)
        .catch((err) => console.log(`getCultureBlogs: `, err));
};

//culture detail
export const getCultureDetails = (cultureid) => {
    return createAPIELearning()
        .get(`/admin-culture-detail/all/${cultureid}`)
        .then((response) => response.data)
        .catch((err) => console.log('getCultureDetails', err));
};

export const getCultureDetail = (detailId) => {
    return createAPIELearning()
        .get(`/admin-culture-detail/${detailId}`)
        .then((response) => response.data)
        .catch((err) => console.log('getCultureDetail', err));
};

export const postNewCultureDetail = (formData) => {
    // for (let [key, value] of formData.entries()) {
    //     console.log(key, value);
    // }
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    return createAPIELearning()
        .post(`/admin-culture-detail/create`, formData, config)
        .then((response) => response.data)
        .catch((err) => console.log('postNewCultureDetail', err));
};

export const updateCultureDetail = (blogId, formData) => {
    // for (let [key, value] of formData.entries()) {
    //     console.log(key, value);
    // }
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    return createAPIELearning()
        .patch(`/admin-culture-detail/${blogId}`, formData, config)
        .then((response) => response.data)
        .catch((err) => console.log('updateCultureDetail', err));
};

export const deleteCultureDetail = (id) => {
    return createAPIELearning()
        .delete(`/admin-culture-detail/${id}`)
        .then((response) => response.data)
        .catch((err) => console.log('deleteCultureDetail', err));
};

export const updateCultureDetailOrder = (info) => {
    return createAPIELearning()
        .patch(`/admin-culture-detail/update-order`, info)
        .then((response) => response.data)
        .catch((err) => console.log(`updateCultureDetailOrder,${err}`));
};
