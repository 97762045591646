import React, { useState } from 'react';

//antd
import { Col, Row } from 'antd';

//components
import SectionTypeList from './SectionTypeList';
import QuestionList from './QuestionList';

export default function Manage() {
    const [selectedSectionTypeId, setSelectedSectionTypeId] = useState('');
    return (
        <Row>
            <Col span={24}>
                <SectionTypeList
                    isSectionType
                    setSelectedSectionTypeId={setSelectedSectionTypeId}
                    selectedSectionTypeId={selectedSectionTypeId}
                />
            </Col>
            <Col span={24}>
                <QuestionList selectedSectionTypeId={selectedSectionTypeId} />
            </Col>
        </Row>
    );
}
