import React from 'react';
import { Switch, Route, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { Tabs } from 'antd';
import { Container } from '../../../components/UI/ui.style';

import Detail from './Detail';
import FeesTable from './FeesTable';

const { TabPane } = Tabs;

const HospitalPage = () => {
    let history = useHistory();
    let location = useLocation();
    const { path } = useRouteMatch();

    const pathnames = location.pathname.split('/');
    const activeKey = ['fees'].includes(pathnames[3]) ? pathnames[3] : 'detail';

    const handleTabClick = (key, e) => {
        e.preventDefault();
        const partnerId = window.localStorage.getItem('partnerId');
        switch (key) {
            case 'detail':
                history.push({ pathname: `${path}/${partnerId}` });
                break;
            case 'fees':
                history.push({ pathname: `${path}/fees/${partnerId}` });
                break;
            default:
        }
    };

    return (
        <Container>
            <Tabs type="card" onTabClick={handleTabClick} defaultActiveKey={activeKey}>
                <TabPane tab="Detail" key="detail"></TabPane>
                <TabPane tab="Fees" key="fees" />
            </Tabs>

            <Switch>
                <Route exact path={`${path}/:id`}>
                    <Detail />
                </Route>
                <Route exact path={`${path}/fees/:id`}>
                    <FeesTable />
                </Route>
            </Switch>
        </Container>
    );
};

export default HospitalPage;
