import { createAPIELearning } from '../../api';

//used in index.js
export const getExamLevel = () => {
    return createAPIELearning()
        .get('/admin-exam')
        .then((response) => response.data)
        .catch((err) => console.log(`getExamLevel + ${err}`));
};

export const postNewExamLevel = (formData) => {
    const config = { headers: { 'content-type': 'multipart/form-data' } };

    return createAPIELearning()
        .post('/admin-exam/create', formData, config)
        .then((response) => response.data)
        .catch((err) => `postNewExamlevel,${err}`);
};

export const updateExamLevel = (id, formData) => {
    const config = { headers: { 'content-type': 'multipart/form-data' } };

    return createAPIELearning()
        .patch(`/admin-exam/${id}`, formData, config)
        .then((response) => response.data)
        .catch((err) => `updateExamLevel,${err}`);
};

export const deleteExamLevel = (examId) => {
    return createAPIELearning()
        .delete(`/admin-exam/${examId}`)
        .then((response) => response.data)
        .catch((err) => `deleteExamLevel,${err}`);
};

export const updateExamLevelOrder = (info) => {
    return createAPIELearning()
        .patch(`/admin-exam/update-order`, info)
        .then((response) => response.data)
        .catch((err) => console.log(`updateExamLevelOrder , ${err}`));
};

export const importExam = (data, file) => {
    console.log(data, file);
    const formData = new FormData();

    for (const key in data) {
        formData.append(key, data[key]);
    }

    formData.append('csv-file', file);

    return createAPIELearning()
        .post('/admin-exam/import', formData)
        .then((response) => response.data)
        .catch((err) => console.log(`importExam: `, err));
};

//section type
export const getSectionType = () => {
    return createAPIELearning()
        .get(`/admin-section-type/all-section-types`)
        .then((response) => response.data)
        .catch((err) => console.log(`getSectionType + ${err}`));
};

export const postNewSectionType = (data) => {
    return createAPIELearning()
        .post(`/admin-section-type/create`, data)
        .then((response) => response.data)
        .catch((err) => console.log(`postNewSectionType,${err}`));
};

export const updateSectionType = (data) => {
    return createAPIELearning()
        .patch(`/admin-section-type/${data.id}`, data)
        .then((response) => response.data)
        .catch((err) => console.log(`updateSectionType + ${err}`));
};

export const deleteSectionType = (id) => {
    return createAPIELearning()
        .delete(`/admin-section-type/${id}`)
        .then((response) => response.data)
        .catch((err) => console.log(`deleteSectionType,${err}`));
};

export const updateSectionTypeOrder = (info) => {
    return createAPIELearning()
        .patch(`/admin-section-type/update-order`, info)
        .then((response) => response.data)
        .catch((err) => console.log('updateSectionTypeOrder', err));
};

export const getExamQuestions = (externalId, sectionTypeId) => {
    return createAPIELearning()
        .get(`/admin-question-section-type/all-questions/${externalId}/${sectionTypeId}`)
        .then((response) => response.data)
        .catch((err) => console.log(err));
};
