import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import Cookies from 'js-cookie';
import Layout from './Layout';

import { AppProvider } from '../contexts/AppContext';
import { AuthProvider } from '../contexts/AuthContext';

const PrivateRoute = ({ children, ...rest }) => {
    const token = Cookies.get('backoffice_token');

    return (
        <Route
            {...rest}
            render={({ location }) =>
                token ? (
                    <AppProvider>
                        <AuthProvider>
                            <Layout>{children}</Layout>
                        </AuthProvider>
                    </AppProvider>
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
