import React, { useState } from 'react';

//antd
import { Form, Modal, message, Row, Col, Input, Select } from 'antd';

//api
import { createMarkdown, updateMarkdown } from '../../../utils/api/E-Learning/markdown';

//components
import { LodoshaButton } from '../../../components/UI/ui.style';
import { MarkdownSampleCreatorWrapper } from './markdown_sample_creator.styles';

//utils and constants
import { markdownTypes } from '../../../utils/data';
import IFrameMarkdown from '../E-LearningComponents/IFrameMarkdown';

export default function MarkdownSampleCreateModal({
    visible,
    setVisible,
    postData,
    setPostData,
    onRefresh,
    isCreate,
    isUpdate,
    title,
}) {
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const { Option } = Select;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(postData);
    const [markdownCss, setMarkdownCss] = useState({
        markdown: data?.markdown ? data?.markdown : '',
        css: data?.css ? data?.css : '',
    });

    const handleSubmit = async (value) => {
        try {
            const response = isCreate
                ? await createMarkdown(data)
                : isUpdate
                ? await updateMarkdown(data.id, data)
                : null;
            if (response) {
                if (response.errorCode === 0) {
                    message.success(`Successfully ${isCreate ? 'create' : 'updae'} markdown sample`);
                    handleCancel();
                    onRefresh();
                    setLoading(false);
                } else {
                    message.error(
                        `Uh oh, couldn't ${isUpdate ? 'update' : 'create'}  markdowns, err: ${response.message}`
                    );
                    setLoading(false);
                    return;
                }
            } else {
                message.error(`Uh oh, couldn't ${isUpdate ? 'update' : 'create'} markdowns. Response is undefined`);
                setLoading(false);
                return;
            }
        } catch (error) {
            console.log(error);
            message.error(`Uh oh, couldn't ${isUpdate ? 'update' : 'create'}  markdowns. err: ${error}`);
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        setData((prev) => ({ ...prev, [name]: value }));
    };

    const handleCancel = () => {
        setVisible(false);
        setPostData({});
    };

    const handleChangeType = (value) => {
        setData((prev) => ({ ...prev, type: value }));
    };

    const handlePreview = () => {
        setMarkdownCss({
            markdown: data.markdown,
            css: data.css,
        });
    };

    console.log(data)

    return (
        <Modal
            visible={visible}
            title={title}
            footer={null}
            onCancel={handleCancel}
            centered
            maskClosable={false}
            width={768}
        >
            <MarkdownSampleCreatorWrapper>
                <Form form={form} onFinish={handleSubmit}>
                    <Row gutter={[16]}>
                        <Col span={24}>
                            <Form.Item
                                rules={[{ required: true, message: 'Data required!' }]}
                                initialValue={data?.typeToText}
                                name="type"
                            >
                                <Select
                                    name="type"
                                    value={data?.type}
                                    onChange={handleChangeType}
                                    placeholder="Please select a type: "
                                    size="large"
                                >
                                    {markdownTypes.map((type, index) => (
                                        <Option value={type.value} key={index}>
                                            {type.text}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div style={{ opacity: 0.8, marginLeft: '1rem', marginBottom: '.5rem' }}>Markdown</div>
                            <Form.Item
                                rules={[{ required: true, message: 'Data required!' }]}
                                initialValue={data?.markdown}
                                name="markdown"
                            >
                                <TextArea
                                    name="markdown"
                                    className="text-area"
                                    value={data?.markdown}
                                    onChange={handleChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div style={{ opacity: 0.8, marginLeft: '1rem', marginBottom: '.5rem' }}>Css</div>
                            <Form.Item
                                rules={[{ required: true, message: 'Data required!' }]}
                                initialValue={data?.css}
                                name="css"
                            >
                                <TextArea name="css" className="text-area" value={data?.css} onChange={handleChange} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <LodoshaButton type="dashed" style={{ marginBottom: '1rem' }} onClick={handlePreview}>
                                Preview
                            </LodoshaButton>
                            <div
                                className="div-as-text-area"
                                style={{ width: '100%', height: '350px', borderRadius: '1rem', overflowY: 'scroll' }}
                            >
                                <IFrameMarkdown
                                    height="350px"
                                    markdown={markdownCss.markdown}
                                    css={markdownCss.css}
                                    key="iframe-markdown"
                                />
                            </div>
                        </Col>
                        {/* *********** */}
                        <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
                            <LodoshaButton
                                key="cancel"
                                style={{ marginRight: 16 }}
                                htmlType="button"
                                onClick={handleCancel}
                            >
                                Cancel
                            </LodoshaButton>
                            <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                                {isCreate ? 'Add New' : isUpdate ? 'Update' : ''}
                            </LodoshaButton>
                        </Col>
                    </Row>
                </Form>
            </MarkdownSampleCreatorWrapper>
        </Modal>
    );
}
