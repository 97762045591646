import React, { useState, useMemo, useContext } from 'react';
import { LodoshaInput, LodoshaButton } from '../../../components/UI/ui.style';

//api
import { updateChapter, postNewChapter } from '../../../utils/api/E-Learning/japanese';

//ant design
import { Modal, Row, Col, Form, message, Select, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { chapterType, menuToText } from '../../../utils/data';
import AppContext from '../../../contexts/AppContext';
import BannerUploader from '../E-LearningComponents/BannerUploader';

/*
used by Japanese Class,Conversation and JLPT
*/

export default function ChapterModal({
    title,
    visible,
    setVisible,
    onRefresh,
    setPostData,
    postData,
    isUpdate,
    isCreate,
}) {
    const { handleUnauthorizedUser } = useContext(AppContext);

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(postData);
    const [pdfFile, setPdfFile] = useState([]);
    const [file, setFile] = useState();

    const { Option } = Select;
    const { Dragger } = Upload;

    const chapterFormFields = useMemo(
        () => [
            {
                type: 'text',
                label: 'Name',
                name: 'name',
                value: data?.name,
                required: true,
                span: 12,
            },
            {
                type: 'text',
                label: 'Menu',
                name: 'menu',
                value: data.menuName ? data.menuName : menuToText[data?.menu - 1],
                disabled: true,
                span: 12,
            },
            {
                type: 'text',
                label: 'Type',
                name: 'type',
                value: data?.type,
                required: true,
                span: 24,
                isSelect: true,
                optionList: chapterType,
            },
        ],
        [data]
    );

    const handleCancel = (e) => {
        setVisible(false);
        setPostData([]);
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        setData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleChangeType = (value) => {
        setData((prev) => ({ ...prev, type: value }));
    };

    const beforeUpload = () => {
        return false;
    };

    const handleChangeFile = (info, fieldname) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        fileList = fileList.map((file) => {
            if (file.response) {
                file.url = file.response.url;
            }
            return file;
        });
        // important when uploading to server
        if (fileList.length > 0) {
            fileList[0].originFileObj.fieldname = fieldname;
        }
        setPdfFile(fileList);
    };

    const handleSubmit = async () => {
        try {
            const response = isCreate
                ? await postNewChapter(data.name, data.type, data.order, data?.menu, pdfFile, file)
                : isUpdate
                ? await updateChapter(
                      data.id,
                      {
                          name: data.name,
                          type: data.type,
                          order: data.order,
                          menu: data.menu,
                          pdf: data.pdf,
                      },
                      pdfFile,
                      file
                  )
                : {};
            if (response && response.errorCode !== 0) {
                if (response.data.status === 401) {
                    message.error(`Unauthorized`);
                    handleUnauthorizedUser();
                    return;
                }
                setLoading(false);
                message.error(`Oh oh, something went wrong. ${response.message}`);
                return;
            }
            if (response) {
                setLoading(false);
                message.success(isCreate ? 'New chapter added.' : isUpdate ? 'Successfully updated chapter' : '');
                handleCancel();
                onRefresh();
            } else {
                setLoading(false);
                message.error(`Uh oh, ${isCreate ? 'add new chapter' : isUpdate ? 'updated chapter' : ''} failed!`);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            message.error(`Uh oh, ${isCreate ? 'add new chapter' : isUpdate ? 'updated chapter' : ''} failed!`);
        }
    };

    return (
        <Modal
            width={768}
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
                    <div>{title}</div>
                    <BannerUploader imageId={data && data.image_id} setFile={setFile} />
                </div>
            }
            visible={visible}
            onCancel={handleCancel}
            maskClosable={false}
            footer={null}
        >
            <Form layout="vertical" form={form} onFinish={() => handleSubmit()}>
                <Row gutter={24}>
                    {chapterFormFields?.map((field, index) => {
                        const { type, label, name, value, required, disabled, span, isSelect, optionList } = field;
                        return (
                            <Col span={span} key={index}>
                                <Form.Item
                                    rules={[{ required: required, message: 'Data required!' }]}
                                    label={`${label}`}
                                    name={name}
                                    initialValue={value}
                                >
                                    {isSelect ? (
                                        <Select
                                            placeholder="Please select: "
                                            name={name}
                                            size="large"
                                            disabled={disabled}
                                            onChange={handleChangeType}
                                        >
                                            {optionList?.map((option, index) => (
                                                <Option value={option.value} key={index}>
                                                    {option.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    ) : (
                                        <LodoshaInput
                                            type={type}
                                            disabled={disabled && true}
                                            onChange={handleChange}
                                            value={value}
                                            name={name}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        );
                    })}

                    <Col span={24}>
                        <Form.Item>
                            {data?.type === 'pdf' && (
                                <Dragger
                                    name="chapter-pdf"
                                    beforeUpload={beforeUpload}
                                    fileList={pdfFile}
                                    onChange={(info) => handleChangeFile(info, 'chapter-pdf')}
                                    accept="application/pdf"
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                </Dragger>
                            )}
                        </Form.Item>
                    </Col>

                    <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
                        <LodoshaButton
                            key="cancel"
                            style={{ marginRight: 16 }}
                            htmlType="button"
                            onClick={handleCancel}
                        >
                            Cancel
                        </LodoshaButton>
                        <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                            {isCreate ? 'Add New' : isUpdate ? 'Update' : ''}
                        </LodoshaButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
