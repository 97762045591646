import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Empty, List, message, Spin } from 'antd';
import { getPartnerProducts } from "../../../utils/api/partner_product";
import { formatCurrency } from '../../../utils'

import styled from '@emotion/styled';

const ListItem = styled(List.Item)`

    #item-container {
        border: 1px solid ${(props) => props.theme.lightgrey};
        border-radius: 4px;
        padding: 16px;
        margin-bottom: 8px;
        background: #FDFDFD;
        height: 100%;
    }

    p {
        font-size: 17px;
        white-space: pre-line;
    }

    .description {
        font-size: 16px;
    }
`;

const Product = () => { 

    let { id: parnerId } = useParams();

    const isSubscribed = useRef(true);

    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);

    const handleFetchData = useCallback(
        async () => {
            setLoading(true);
            try {
                getPartnerProducts(parnerId).then((response) => {
                if (isSubscribed.current) {
                    response.data && setProducts(response.data);
                    setLoading(false);
                }
                });
            } catch (error) {
                setLoading(false);
                message.error(`Uh oh, request failed!`);
            }
            isSubscribed.current && setLoading(false);
        }, [parnerId]
    );

    useEffect(() => {
        handleFetchData();
        return () => {
            isSubscribed.current = false;
          };
    }, [handleFetchData]);

    return (
        <Spin tip="Loading..." spinning={loading}>
            <Row>
                <Col span={24}>
                    {products.length ? (
                        <List
                            grid={{ gutter: 16, column: 2 }}
                            dataSource={products}
                            renderItem={(item) => (
                                <ListItem>
                                    <List.Item>
                                        <div id='item-container'>
                                            <h2>{item.name} <span>({item.type === 1 ? 'សិក្ខាកាម' : 'ពលករជំនាញ'})</span></h2>
                                            <Row>
                                                {
                                                    item.price_from === item.price_to ? 
                                                    (<Col span={24}>
                                                        <p><b>តម្លៃ : <span>{formatCurrency(item.price_from)}</span></b></p>
                                                    </Col>
                                                    ) 
                                                    :  
                                                    (<div><Col span={12}>
                                                        <p><b>តម្លៃពី : <span>{formatCurrency(item.price_from)}</span></b></p>
                                                    </Col>
                                                    <Col span={12}>
                                                        <p><b>តម្លៃដល់ : <span>{formatCurrency(item.price_to)}</span></b></p>
                                                    </Col></div>)
                                                }

                                                <Col span={12}>
                                                    <p><b>បង់លើកទីមួយ : <span>{formatCurrency(item.first_payment)}</span></b></p>
                                                    <p><span className='description'>{item.first_payment_description}</span></p>
                                                </Col>
                                                <Col span={12}>
                                                    <p><b>បង់លើកទីពីរ : <span>{formatCurrency(item.second_payment)}</span></b></p>
                                                    <p><span className='description'>{item.second_payment_description}</span></p>
                                                </Col>
                                                <Col span={12}>
                                                    <p><b>បង់លើកទីបី : <span>{item.third_payment ? formatCurrency(item.third_payment)  : '-'}</span></b></p>
                                                    <p><span className='description'>{item.third_payment_description}</span></p>
                                                </Col>
                                                <Col span={12}>
                                                    <p><b>បង់លើកទីបួន : <span>{item.four_payment ? formatCurrency(item.four_payment): '-'}</span></b></p>
                                                    <p><span className='description'>{item.four_payment_description}</span></p>
                                                </Col>
                                                <Col span={12}>
                                                    <p><b>ថ្លៃស្នាក់នៅ ហូបចុក : <span>{item.accomondation_payment ? formatCurrency(item.accomondation_payment) : '-'}</span></b></p>
                                                    <p><span className='description'>{item.accomondation_note}</span></p>
                                                </Col>
                                                <Col span={24}>
                                                    <p><b>លក្ខខណ្ឌផ្សេង :</b></p>
                                                    <p><span>{item.condition}</span></p>
                                                </Col>
                                                <Col span={24}>
                                                    <p><b>ចំណាំ :</b></p>
                                                    <p><span>{item.note}</span></p>
                                                </Col>
                                                
                                            </Row>
                                        </div>
                                
                                    </List.Item>
                                </ListItem>
                            )}
                        />
                    ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                </Col>
            </Row>
        </Spin>
    );

}
export default Product;