import API from '../api';

export const getApplication = (filter) => {
    const endpoint = `/application?filter=${filter}`
    return API()
        .get(endpoint)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
};