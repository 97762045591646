import API from '../api';

export const getAccount = (data) => {
    return API().get(`/user/account?id=${data.id}&role=${data.type}`)
            .then(response => {
                return response.data;
            })
            .catch(err => { 
                console.log('err: ', err) 
            });
};

export const createStaffAccount = (data) => {
    return API().post(`/user/account-staff`, data)
            .then(response => {
                return response.data;
            }).catch(err => {
                console.log('err: ', err) 
            })
}


export const sendPassword = (data) => {
    return API().post(`/user/sendPassword`, data)
            .then(response => {
                return response.data;
            }).catch(err => {
                console.log('err: ', err) 
            })
}

export const changePassword = (data) => {
    return API().post(`/user/changePassword`, data)
            .then(response => {
                return response.data;
            }).catch(err => {
                console.log('err: ', err) 
            })
}
