import React, { useContext } from 'react';
import AppContext from '../../contexts/AppContext';

//components
import Search from '../../components/Search';

import { dataEmployeeHaveNotCalled, dataEmployeeStatus, dataLookingJobs, dataPercentage } from '../../utils/data';

const EmployeeSearch = ({ onSearch, onFilter, keyword, jobId, status, percentage, noCall, lookingJobs }) => {
    const { dataJobs } = useContext(AppContext);

    const items = [
        { label: 'Search:', name: 'search', type: 'text', value: keyword, onSearch },
        {
            label: 'Filter by Jobs:',
            value: jobId,
            name: 'jobs',
            type: 'select',
            options: dataJobs,
            onSelect: (value) => onFilter('job', value),
        },
        {
            label: 'Filter by Status:',
            value: status,
            name: 'status',
            type: 'select',
            options: dataEmployeeStatus,
            onSelect: (value) => onFilter('status', value),
        },
        {
            label: 'Filter by Percentage:',
            value: percentage,
            name: 'percentage',
            type: 'select',
            options: dataPercentage,
            onSelect: (value) => onFilter('percentage', value),
        },
        {
            label: `Filter by No Outbound:`,
            value: noCall,
            name: 'no_call',
            type: 'select',
            options: dataEmployeeHaveNotCalled,
            onSelect: (value) => onFilter('no_call', value),
        },
        {
            label: `Filter by LookingJobs:`,
            value: lookingJobs,
            name: 'looking_jobs',
            type: 'select',
            options: dataLookingJobs,
            onSelect: (value) => onFilter('looking_jobs', value),
        }
    ];

    return <Search items={items} />;
};

export default EmployeeSearch;
