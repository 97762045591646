import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Layout, Button, PageHeader, Row, Col, Menu, Dropdown, Badge } from 'antd';
import { isUndefined } from 'util';
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined, PhoneFilled, ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import styled from '@emotion/styled';

//static files
import logo1 from '../assets/logo1.png';
import logo2 from '../assets/logo2.png';

//components
import { LodoshaMenu } from '../components/UI/ui.style';
import Sider from './Sider';
import AppContext from '../contexts/AppContext';
import FBLoginButton from './SocialMediaButtons/FBLoginButton';

const CustomLayout = (props) => {
    const { dataFollowUp } = React.useContext(AppContext);
    const [collapsed, setCollapsed] = useState(false);

    const BadgeDiv = styled.div`
        margin-top: 5px;
        margin-right: 30px;

        #icon-badge {
            font-size: 25px;
            color: #ccc;
        }

        #icon-badge:hover {
            color: #0f237c;
        }
    `;

    const handleCollapsed = () => setCollapsed(!collapsed);

    const breadcrumbNameMap = {
        // Favorite
        '/favorites': 'Favorites Request',
        '/favorites/create': 'Create',
        '/favorites/update': 'Update',
        '/favorites/detail': 'Detail',

        // Candidate
        '/candidates': 'Candidates',
        '/candidates/create': 'Create',
        '/candidates/update': 'Update',
        '/candidates/detail': 'Detail',
        '/candidates/translate': 'Translate',

        // Employee
        '/candidate-call': 'Candidates Call',

        // Company
        '/companies': 'Companies',
        '/companies/create': 'Create',
        '/companies/update': 'Update',
        '/companies/detail': 'Detail',
        '/companies/translate': 'Translate',
        '/companies/company-candidates': 'Company Candidates',

        // Post
        '/post-jobs': 'Post Jobs',
        '/post-jobs/create': 'Create',
        '/post-jobs/update': 'Update',
        '/post-jobs/translate': 'Translate',
        '/post-jobs/detail': 'Detail',
        '/post-jobs/postjob-employee': 'PostJobEmployee',
        '/post-jobs/suggested-candidates': 'Suggested Candidates',

        // Agency
        '/partners': 'Partners',
        '/partners/create': 'Create',
        '/partners/update': 'Update',
        '/partners/detail': 'Detail',
        '/partners/partner-employees': 'PartnerEmployee',

        // Hospital
        '/hospitals': 'Hospitals',
        '/hospitals/hospital-employees': 'Employees',
        '/hospitals/hospital-fees': 'Fees',

        // Job
        '/jobs': 'Jobs',
        '/jobs/create': 'Create',
        '/jobs/update': 'Update',

        // Career
        '/careers': 'Careers',
        '/careers/create': 'Create',
        '/careers/update': 'Update',
        '/careers/detail': 'Detail',

        // Staff
        '/staffs': 'Staffs',

        // Blog
        '/blogs': 'Blogs',

        //Memberships
        '/memberships': 'Memberships',

        //Users
        '/users': 'Users',

        //E-learning
        '/e-learning': 'E-Learning',
    };

    const pathSnippets = props.location.pathname.split('/').filter((i) => i);
    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        return {
            path: url,
            breadcrumbName: breadcrumbNameMap[url],
        };
    });

    //change from Home/E-Learning to Arrow once topic is selected
    const routes =
        extraBreadcrumbItems[1]?.path.split('/')[1] === 'e-learning' &&
        extraBreadcrumbItems[1]?.path.split('/')[2] !== undefined
            ? [
                  {
                      path: '/e-learning',
                      breadcrumbName: (
                          <div>
                              <ArrowLeftOutlined style={{ marginRight: '1.5rem' }} />
                              Back To E-Learning Menu
                          </div>
                      ),
                  },
              ]
            : [
                  {
                      path: '/',
                      breadcrumbName: 'Home',
                  },
              ].concat(extraBreadcrumbItems);

    const itemRender = (route, params, routes, paths) => {
        if (isUndefined(route.breadcrumbName)) {
            return null;
        }

        const last = routes.indexOf(route) === routes.length - 1;
        return last ||
            route.breadcrumbName === 'Detail' ||
            route.breadcrumbName === 'Update' ||
            route.breadcrumbName === 'Translate' ? (
            <Link to={route.path} style={{ color: '#182088' }}>
                {route.breadcrumbName}
            </Link>
        ) : (
            <Link to={route.path}>{route.breadcrumbName}</Link>
        );
    };
    const menu = (
        <LodoshaMenu>
            <Menu style={{ margin: 0 }}>
                {dataFollowUp.map((followUp, index) => {
                    return (
                        <Menu.Item key={index} style={{ borderBottom: '1px solid #EEE', height: 'auto', margin: 0 }}>
                            <Link to={{ pathname: `/candidates/detail/${followUp.employee['id']}` }}>
                                <div style={{ paddingLeft: 10, paddingRight: 10, minWidth: 300 }}>
                                    <p style={{ fontSize: 16, color: '#0F237C', padding: 0, margin: 0 }}>
                                        {followUp.employee['name']}
                                    </p>
                                    <p style={{ fontSize: 13, color: '#888', marginTop: '-12px' }}>
                                        Phone: {followUp.employee['phone']}
                                        <span style={{ float: 'right' }}>
                                            Gender: {followUp.employee['gender'] === 1 ? 'Male' : 'Female'}
                                        </span>
                                    </p>
                                    <p style={{ fontSize: 14, color: '#888', marginTop: '-30px', marginBottom: '5px' }}>
                                        Last Call Date: {moment(followUp.created_at).format('DD-MM-YYYY HH:MM:SS')}
                                    </p>
                                </div>
                            </Link>
                        </Menu.Item>
                    );
                })}
            </Menu>
        </LodoshaMenu>
    );

    return (
        <Layout style={{ width: '100%', height: '100%' }}>
            <Layout.Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                style={{
                    position: 'relative',
                    width: '100%',
                    background: 'white',
                    boxShadow: '#eee 1px 1px 10px 10px',
                    height: '100%',
                    overflow: 'scroll',
                }}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: 'white',
                        padding: '10px 0 10px 0',
                    }}
                >
                    {collapsed ? (
                        <img src={logo2} alt="" style={{ width: '70%', height: '70%' }} />
                    ) : (
                        <img src={logo1} alt="" style={{ width: '70%', height: '70%' }} />
                    )}
                </div>
                <Sider props={props} />
            </Layout.Sider>
            <Layout>
                <Layout.Header style={{ background: 'white' }}>
                    <Row type="flex" justify="space-between" align="middle">
                        <Col>
                            <Button onClick={handleCollapsed} type="link" style={{ marginLeft: '-15px' }}>
                                {collapsed ? (
                                    <MenuUnfoldOutlined style={{ fontSize: 20 }} />
                                ) : (
                                    <MenuFoldOutlined style={{ fontSize: 20 }} />
                                )}
                            </Button>
                        </Col>
                        <Row type="flex" justify="center" align="middle">
                            <Dropdown overlay={menu} placement="bottomRight">
                                <BadgeDiv>
                                    <Badge count={dataFollowUp.length}>
                                        <PhoneFilled id="icon-badge" />
                                    </Badge>
                                </BadgeDiv>
                            </Dropdown>
                            <div
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    backgroundColor: '#ccc',
                                    border: '1px solid white',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: 'white',
                                }}
                            >
                                <UserOutlined />
                            </div>

                            <div
                                style={{
                                    marginLeft: '10px',
                                }}
                            >
                                {localStorage.getItem('lodosha_user_name') ? (
                                    <strong>{localStorage.getItem('lodosha_user_name')}</strong>
                                ) : (
                                    'N/A'
                                )}
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <FBLoginButton />
                            </div>
                        </Row>
                    </Row>
                </Layout.Header>
                <Layout.Content style={{ padding: '0 50px' }}>
                    <PageHeader
                        breadcrumb={{ itemRender, routes }}
                        style={{
                            margin: '16px 0',
                            borderRadius: 10,
                            boxShadow: '#eee 2px 2px 1px 1px',
                            background: '#fff',
                        }}
                    />
                    {props.children}
                </Layout.Content>
            </Layout>
        </Layout>
    );
};

export default withRouter(CustomLayout);
