import React, { useState } from "react";
import { Row, Col, Modal, Form, Space, Select, message } from "antd";
import { LodoshaInput, LodoshaTextArea, LodoshaButton, LodoshaSelect } from "../../components/UI/ui.style";
import { dataPartnerProductType } from "../../utils/data";
import { createPartnerProduct, updatePartnerProduct } from "../../utils/api/partner_product";
import { TitleWrapper } from "./products.style";

const { Option } = Select;

const ModalProducts = ({ visible, setVisible, partner, product, setPartnerProducts }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(product);

  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  const handleChange = (name, value) => {
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeSelect = (name, value) => {
    // console.log(name, value);
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    data.partner_id = partner.id;

    if (data.id) {
      updatePartnerProduct(data, data.id)
        .then((response) => {
          if (response?.errorCode === 0) {
            message.info("Product updated!");
            setPartnerProducts((prevState) =>
              prevState.map((state) => (state.id === data.id ? { ...state, ...data } : state))
            );
            setLoading(false);
            setVisible(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      createPartnerProduct(data)
        .then((response) => {
          if (response?.errorCode === 0) {
            message.info("Product added!");
            setPartnerProducts((prevState) => [response.data, ...prevState]);
            setLoading(false);
            setVisible(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const items = [
    { type: "text", label: "Name", name: "name", value: data && data.name, required: true },
    {
      type: "select",
      label: "Type",
      name: "type",
      value: data && data.type,
      options: dataPartnerProductType,
      required: true,
    },
    {
      type: "number",
      label: "Price From",
      name: "price_from",
      value: data && data.price_from,
      required: true,
    },
    {
      type: "number",
      label: "Price To",
      name: "price_to",
      value: data && data.price_to,
      required: true,
    },
  ];

  const itemPayments = [
    {
      type: "number",
      label: "First Payment",
      name: "first_payment",
      value: data && data.first_payment,
    },
    {
      type: "number",
      label: "Second Payment",
      name: "second_payment",
      value: data && data.second_payment,
    },
    {
      type: "textarea",
      label: "First Payment Description",
      name: "first_payment_description",
      value: data && data.first_payment_description,
    },
    {
      type: "textarea",
      label: "Second Payment Description",
      name: "second_payment_description",
      value: data && data.second_payment_description,
    },
    {
      type: "number",
      label: "Third Payment",
      name: "third_payment",
      value: data && data.third_payment,
    },
    {
      type: "number",
      label: "Four Payment",
      name: "four_payment",
      value: data && data.four_payment,
    },
    {
      type: "textarea",
      label: "Third Payment Description",
      name: "third_payment_description",
      value: data && data.third_payment_description,
    },
    {
      type: "textarea",
      label: "Four Payment Description",
      name: "four_payment_description",
      value: data && data.four_payment_description,
    },
    {
      type: "number",
      label: "Accomondation Payment",
      name: "accomondation_payment",
      value: data && data.accomondation_payment,
    },
    {
      type: "textArea",
      label: "Accomondation Note",
      name: "accomondation_note",
      value: data && data.accomondation_note,
    },
  ];

  const itemOthers = [
    {
      type: "textarea",
      label: "Condition",
      name: "condition",
      value: data && data.condition,
    },
    { type: "textarea", label: "Note", name: "note", value: data && data.note },
  ];

  return (
    <Modal
      visible={visible}
      maskClosable={false}
      title={data?.id ? "Update" : "Create"}
      onCancel={handleCancel}
      width={768}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <TitleWrapper>
          <h2 className="header-line">Required</h2>
        </TitleWrapper>
        <Row gutter={24}>
          {items.map((item) => (
            <Col key={item.name} span={12}>
              <Form.Item
                name={item.name}
                label={item.label + ":"}
                initialValue={item.value}
                rules={[{ required: item.required, message: "Data required!" }]}
              >
                {item.type === "select" ? (
                  <LodoshaSelect onChange={(value) => handleChangeSelect(item.name, value)}>
                    {item.options.map(({ value, text }) => (
                      <Option key={value} value={value}>
                        {text}
                      </Option>
                    ))}
                  </LodoshaSelect>
                ) : (
                  <LodoshaInput type={item.type} onChange={(e) => handleChange(item.name, e.target.value)} />
                )}
              </Form.Item>
            </Col>
          ))}
        </Row>
        <TitleWrapper>
          <h2 className="header-line">Payments</h2>
        </TitleWrapper>
        <Row gutter={24}>
          {itemPayments.map((item) => (
            <Col key={item.name} span={12}>
              <Form.Item
                name={item.name}
                label={item.label + ":"}
                initialValue={item.value}
                rules={[{ required: item.required, message: "Data required!" }]}
              >
                {item.type === "textarea" ? (
                  <LodoshaTextArea onChange={(e) => handleChange(item.name, e.target.value)} />
                ) : (
                  <LodoshaInput type={item.type} onChange={(e) => handleChange(item.name, e.target.value)} />
                )}
              </Form.Item>
            </Col>
          ))}
        </Row>
        <TitleWrapper>
          <h2 className="header-line">Others</h2>
        </TitleWrapper>
        <Row gutter={24}>
          {itemOthers.map((item) => (
            <Col key={item.name} span={12}>
              <Form.Item
                name={item.name}
                label={item.label + ":"}
                initialValue={item.value}
                rules={[{ required: item.required, message: "Data required!" }]}
              >
                <LodoshaTextArea onChange={(e) => handleChange(item.name, e.target.value)} />
              </Form.Item>
            </Col>
          ))}
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: "center", marginTop: 16 }}>
            <Space direction="horizontal" size="middle">
              <LodoshaButton key="cancel" htmlType="button" onClick={handleCancel}>
                Cancel
              </LodoshaButton>
              <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                {data?.id ? "Update" : "Create"}
              </LodoshaButton>
            </Space>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalProducts;
