import styled from '@emotion/styled';

export const FilteredUsersContainer = styled.div`
    .logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 400px;
        opacity: 0.5;
    }

    .vertical-line {
        border-left: 2px solid #f4f4f4;
        min-height: 150px;
        margin: 0 1rem;
    }

    @media screen and (max-width: 768px) {
        .vertical-line {
            display: none;
        }
    }
`;
