import styled from '@emotion/styled';

const UpsertContainer = styled.div`
    .ql-editor {
        height: 440px;
    }

    .ql-snow .ql-editor pre.ql-syntax {
        background-color: #f3f6f9;
        color: #151f23;
        overflow: visible;
    }
`;

export { UpsertContainer };
