import styled from "@emotion/styled";

const PostJobContainer = styled.div`
    .text-expire{
       color: red;
    }
    
    .text-pending{
        color: #F29339
    }

    .text-publish{
        color: #006400
    }

    .avatar-uploader img{
        object-fit: contain
    }
`

export default PostJobContainer