import API from '../api';

export const getPostJobEmployees = (postJobId, page = 1) => {
    const url =
        page === 1
            ? `/post-job-employee?post_job_id=${postJobId}`
            : `/post-job-employee?post_job_id=${postJobId}&offset=${(page - 1) * 15}`;
    return API()
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log('err: ', err);
        });
};

export const getSuggestedPostJobEmployees = (
    from_age,
    to_age,
    japanese_level,
    finish_education,
    gender,
    job_id,
    offset
) => {
    const url = `/post-job-employee/suggested-employees?from_age=${from_age}&&to_age=${to_age}&&japanese_level=${japanese_level}&&finish_education=${finish_education}&&gender=${gender}&&job_id=${job_id}&&offset=${offset}`;
    return API()
        .get(url)
        .then((response) => response.data)
        .catch((err) => console.log(`getSuggestedPostJobEmployees`, err));
};

export const createPostJobEmployee = (data) => {
    return API()
        .post(`/post-job-employee`, data)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log('err: ', err);
        });
};

export const deletePostJobEmployee = (id) => {
    return API()
        .delete(`/post-job-employee/${id}`)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log('err: ', err);
        });
};
export const searchEmployees = (page = 1, keyword, fields, postjob_id) => {
    const url =
        page === 1
            ? `/post-job-employee/search-fields?keyword=${keyword}&fields=${fields}&post_job_id=${postjob_id}`
            : `/post-job-employee/search-fields?offset=${
                  (page - 1) * 15
              }&keyword=${keyword}&fields=${fields}&post_job_id=${postjob_id}`;

    return API()
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log('err: ', err);
        });
};

export const updateStatus = (id, status) => {
    const url = `/post-job-employee/status/${id}`;
    return API()
        .patch(url, { status: status })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log('err: ', err);
        });
};

export const updatePostJobEmployee = (id, data) => {
    return API()
        .patch(`/post-job-employee/${id}`, data)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            console.log('err: ', err);
        });
};
