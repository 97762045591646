import React, { useCallback, useEffect, useRef, useState } from 'react';

//utils and constants
import { getFullHtmlFormat, md } from '../../../utils';

export default function IFrameMarkdown({ css = '', markdown = '' }) {
    const [htmlDocs, setHtmlDocs] = useState();
    const [iframeHeight, setIFrameHeight] = useState(0);

    const iframeRef = useRef();

    const handleSetHtmlDocs = useCallback(() => {
        const markdownToHtml = md().render(markdown ? markdown : '');
        const fullHtmlFormat = getFullHtmlFormat({ css: css, html: markdownToHtml });
        setHtmlDocs(fullHtmlFormat);
    }, [css, markdown]);

    const handleOnLoad = () => {
        let iframeScrolllHeight = iframeRef.current?.contentWindow.document.body.scrollHeight;
        if (iframeScrolllHeight < 50) {
            iframeScrolllHeight = 50;
        }
        setIFrameHeight(iframeScrolllHeight);
    };

    useEffect(() => {
        handleSetHtmlDocs();
    }, [handleSetHtmlDocs]);

    return (
        <iframe
            ref={iframeRef}
            onLoad={handleOnLoad}
            height={iframeHeight}
            style={{
                width: '100%',
                height: iframeHeight > 0 ? iframeHeight + 50 + 'px' : '100%',
                border: 'none',
            }}
            srcDoc={htmlDocs}
            title="markdownpreview"
        />
    );
}
