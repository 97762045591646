import React, { useEffect, useState, useMemo, useCallback, useRef, useContext } from 'react';
import { useLocation, useHistory, Route, Switch } from 'react-router-dom';

import AppContext from '../../../contexts/AppContext';

//api
import { deleteChapter, getChaptersByMenuID, updateChapterOrder } from '../../../utils/api/E-Learning/jlpt';

//ant design
import { Space, Button, Modal, message } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

//constants and utils
import { ELEARNING } from '../../../constants/routes';
import { jlptTypeToText, menuToText } from '../../../utils/data';
import { getNewLastOrder, getELearningItems } from '../../../utils';

//components
import { LodoshaButton, Container } from '../../../components/UI/ui.style';
import Breadcrumb from '../E-LearningComponents/Breadcrumb';
import ChapterModal from './ChapterModal';
import DragHandler from '../E-LearningComponents/DragHandler';
import SortableTable from '../E-LearningComponents/SortableTable';
import LessonList from './LessonList';
import ContentList from './ContentList';
import ContentDetail from './ContentDetail';

const JLPTPage = (props) => {
    const { pathname } = useLocation();
    const history = useHistory();

    //extract menuID from pathname
    const menuid = pathname.split('/')[2];

    //main menu table will hide when chapter is selected
    const shouldMainMenuShow = pathname.split('/')[3] ? false : true;

    const [loading, setLoading] = useState(false);
    const [chapters, setChapters] = useState([]);
    const [visible, setVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [postData, setPostData] = useState();

    const isSubscribed = useRef(true);
    const { handleUnauthorizedUser } = useContext(AppContext);

    const columns = [
        {
            title: '#',
            dataIndex: 'no',
            width: '5%',
            align: 'center',
            render: () => <DragHandler />,
        },
        {
            title: 'TITLE',
            dataIndex: 'name',
            width: '50%',
        },
        {
            title: 'MENU',
            dataIndex: 'menuName',
            width: '10%',
            align: 'center',
        },
        {
            title: 'TYPE',
            dataIndex: 'typeName',
            width: '20%',
            align: 'center',
        },
        {
            title: 'ACTIONS',
            dataIndex: 'actions',
            align: 'center',
            width: '15%',
            render: (text, row, index) => (
                <Space size="small" direction="horizontal">
                    <Button title="Edit" type="primary" icon={<EditOutlined />} onClick={(e) => handleEdit(e, row)} />
                    <Button
                        title="Delete"
                        type="danger"
                        icon={<DeleteOutlined />}
                        onClick={(e) => handleDelete(e, row.id)}
                    />
                </Space>
            ),
        },
    ];

    const data = useMemo(
        () =>
            chapters &&
            chapters.map((chapter, index) => ({
                ...chapter,
                key: index,
                no: index + 1,
                name: chapter.name,
                menuName: menuToText[chapter.menu - 1],
                typeName: jlptTypeToText[chapter.type],
                menu: chapter.menu,
            })),
        [chapters]
    );

    const handleFetchChapter = useCallback(async () => {
        try {
            setLoading(true);
            const response = await getChaptersByMenuID(menuid);
            if (response) {
                if (response?.data?.status === 401) {
                    message.error(`Unauthorized`);
                    handleUnauthorizedUser();
                    return;
                }

                if (response.errorCode !== 0) {
                    setLoading(false);
                    message.error(`Could not get JLPT level ${response.message}`);
                    return;
                }

                setChapters(response ? response.data : []);
                setLoading(false);
                return;
            } else {
                setLoading(false);
                message.error(`Could not get JLPT level. Response is undefined`);
                return;
            }
        } catch (error) {
            setLoading(false);
            message.error(`Could not get JLPT level.`);
            return;
        }
    }, [menuid, handleUnauthorizedUser]);

    const handleRow = (record, index) => {
        return {
            onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                switch (record.name) {
                    case 'n1':
                    case 'N1':
                    case '1':
                        localStorage.setItem('selected_level', JSON.stringify(1));
                        break;
                    case 'n2':
                    case 'N2':
                    case '2':
                        localStorage.setItem('selected_level', JSON.stringify(2));
                        break;
                    case 'n3':
                    case 'N3':
                    case '3':
                        localStorage.setItem('selected_level', JSON.stringify(3));
                        break;
                    case 'n4':
                    case 'N4':
                    case '4':
                        localStorage.setItem('selected_level', JSON.stringify(4));
                        break;
                    case 'n5':
                    case 'N5':
                    case '5':
                        localStorage.setItem('selected_level', JSON.stringify(5));
                        break;
                    default:
                        localStorage.setItem('selected_level', JSON.stringify(5));
                }

                try {
                    const eLearningItems = getELearningItems(chapters, 'name');
                    localStorage.setItem('e-learning-chapters', JSON.stringify(eLearningItems));
                } catch (error) {
                    message.error('Set to local storage failed');
                }

                history.push(`${ELEARNING}/${menuid}/${record.id}`, record);
            }, // click row
        };
    };

    const handleEdit = (e, row) => {
        e.stopPropagation();
        setPostData(row);
        setUpdateVisible(true);
    };

    const handleDelete = (e, id) => {
        e.stopPropagation();
        Modal.confirm({
            title: 'Are you sure delete this post?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await deleteChapter(id);
                    if (response && response.errorCode === 0) {
                        message.success(`Deleted successfully!`);
                        handleFetchChapter();
                    } else {
                        message.error(`Couldn't delete JLPT chapters, ${response.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request failed!`);
                }
            },
            onCancel() {},
        });
    };

    const Upsert = useCallback(
        () =>
            visible && (
                <ChapterModal
                    title="Add New Level"
                    visible={visible}
                    setVisible={setVisible}
                    postData={postData}
                    setPostData={setPostData}
                    onRefresh={handleFetchChapter}
                    isCreate={true}
                />
            ),
        [visible, handleFetchChapter, postData]
    );

    const Update = useCallback(() => {
        return (
            updateVisible && (
                <ChapterModal
                    title="Update Level Info"
                    visible={updateVisible}
                    setVisible={setUpdateVisible}
                    onRefresh={handleFetchChapter}
                    setPostData={setPostData}
                    postData={postData}
                    isUpdate={true}
                />
            )
        );
    }, [updateVisible, handleFetchChapter, postData]);

    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchChapter();
        }
        return () => {
            isSubscribed.current = false;
        };
    }, [handleFetchChapter]);

    //auto order when user add new level
    //avoid latest order undefined
    useEffect(() => {
        let lastOrder = getNewLastOrder(chapters);
        setPostData((prev) => ({ ...prev, order: lastOrder, menu: menuid }));
    }, [chapters, visible, menuid]);

    return (
        <div>
            <Breadcrumb menuid={menuid} />
            {shouldMainMenuShow && (
                <Container>
                    <LodoshaButton type="primary" className="btn-margin-bottom" onClick={() => setVisible(true)}>
                        <PlusOutlined />
                        Add New
                    </LodoshaButton>
                    <SortableTable
                        loading={loading}
                        columns={columns}
                        data={data}
                        handleRow={handleRow}
                        setLoading={setLoading}
                        onUpdateOrder={updateChapterOrder}
                    />
                    <Upsert />
                    <Update />
                </Container>
            )}
            <Switch>
                <Route component={LessonList} exact path={`${ELEARNING}/:menuid/:chapterid`} />
                <Route component={ContentList} exact path={`${ELEARNING}/:menuid/:chapterid/:lessonid`} />
                <Route component={ContentDetail} exact path={`${ELEARNING}/:menuid/:chapterid/:lessonid/:contentid`} />
            </Switch>
        </div>
    );
};

export default JLPTPage;
