import styled from '@emotion/styled';

const TitleLabel = styled.span`
    font-size: 18px;
    margin: 16px;
`;

const LoadingTop = styled.div`
    position: relative;

    .ant-spin-spinning {
        position: absolute;
        top: 45%;
        margin-right: 20px;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
        background: transparent;
    }

`

export { TitleLabel, LoadingTop };
