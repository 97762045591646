import React, { useState } from 'react';
import moment from 'moment';
import { Row, Col, Form, Modal, Select, message, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

//state
import AppContext from '../../contexts/AppContext';
import { AuthContext } from '../../contexts/AuthContext';

//components
import {
    LodoshaButton,
    LodoshaInput,
    LodoshaSelect,
    LodoshaDatePicker,
    LodoshaTimePicker,
    LodoshaTextArea,
    LodoshaSwitch,
} from '../../components/UI/ui.style';
import PostJobBannerUploader from './PostJobBannerUploader';

//utils and data
import API from '../../utils/api';
import {
    dataWorkingDay,
    dataGenderJob,
    dataCandidateType,
    dataWorkDuration,
    dataFinishEducation,
    dataJapaneseLevel,
    dataCountry,
} from '../../utils/data';
import { shareToFacebookPage } from '../../utils/api/facebook_graphAPI';

const { Option } = Select;
const timeFormat = 'hh:mm a';

const Upsert = ({ visible, setVisible, page, postJob, setPostJob, onRefresh }) => {
    const [form] = Form.useForm();
    const { dataJobs } = React.useContext(AppContext);
    const { fbPageAccessToken } = React.useContext(AuthContext);
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState(postJob);
    const [file, setFile] = useState([]);
    const [employers, setEmployers] = React.useState([{ id: 'none', company_name: 'None' }]);
    const [partners, setPartners] = React.useState([{ id: 'none', name: 'None' }]);

    const [shareTo, setShareTo] = useState({ facebook: false, instagam: false });

    React.useEffect(() => {
        const handleFetchEmployers = (page = 1) => {
            const url = page === 1 ? '/employer' : `/employer?offset=${(page - 1) * 15}`;
            API()
                .get(url)
                .then((res) => res.data)
                .then((result) => {
                    setEmployers((prevState) => [...prevState, ...result.data]);
                    if (result.hasNext) return handleFetchEmployers(page + 1);
                })
                .catch((error) => {
                    console.log(error);
                });
        };

        const handleFetchPartners = (page = 1) => {
            const url = page === 1 ? '/partner' : `/partner?offset=${(page - 1) * 15}`;
            API()
                .get(url)
                .then((res) => res.data)
                .then((result) => {
                    setPartners((prevState) => [...prevState, ...result.data]);
                    if (result.hasNext) return handleFetchPartners(page + 1);
                })
                .catch((error) => {
                    console.log(error);
                });
        };

        handleFetchEmployers();
        handleFetchPartners();
    }, []);

    const handleCancel = () => {
        setVisible(false);
        setPostJob && setPostJob({});
    };

    const handleChange = (name, value) => {
        setData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleChangeSwitch = (name, value) => {
        setShareTo((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (values) => {
        // setLoading(true);
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        const formData = new FormData();
        for (const key in data) {
            if (data[key]) {
                formData.append(key, data[key]);
            }
        }

        if (file) {
            formData.append('image', file);
        }

        try {
            const response =
                data && data.id
                    ? await API().patch(`/postjob/${data.id}`, formData, config)
                    : await API().post(`/postjob/create`, formData, config);
            if (response?.data?.errorCode === 0) {
                message.success(`${data.id ? `Updated` : `Created`} successfully!`);
                setFile([]);
                setData();
                setPostJob && setPostJob({});

                //if shareTo social media is enable, post will be automatically shared
                if (shareTo.facebook) {
                    const postJobURL = `${process.env.BASE_URL_WEBSITE}job-detail/${response?.data?.data?.id}`;
                    shareToFacebookPage(
                        postJobURL,
                        data.post_caption ? data.post_caption : '',
                        fbPageAccessToken,
                        (response) => {
                            if (response.id) {
                                message.success('Successfully shared to facebook page');
                            }
                        }
                    );
                }

                setVisible(false);
                onRefresh(page);
            } else {
                message.error(`Failed, ${response.data.message}`);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            message.error(`Uh oh, request failed!`);
        }
    };

    const items = React.useMemo(
        () => [
            { type: 'text', label: 'Title', name: 'job_title', value: data?.job_title, require: true },
            {
                type: 'select',
                label: 'From Employer',
                name: 'employer_id',
                value: data?.employer?.id,
                options: employers.map((data) => ({
                    value: data.id,
                    text: data.company_name,
                })),
            },
            {
                type: 'select',
                label: 'From Partner',
                name: 'partner_id',
                value: data?.partner_id,
                options: partners.map((data) => ({
                    value: data.id,
                    text: data.name,
                })),
            },
            {
                type: 'select',
                label: 'Job Category',
                name: 'job_id',
                value: data?.job?.id,
                options: dataJobs,
                require: true,
            },
            {
                type: 'select',
                label: 'Candidate Type',
                name: 'candidate_type',
                value: data?.candidate_type,
                options: dataCandidateType,
                require: true,
            },
            {
                type: 'select',
                label: 'Gender',
                name: 'gender',
                value: data?.gender,
                options: dataGenderJob,
                require: true,
            },
            { type: 'number', label: 'Hiring', name: 'hiring', value: data?.hiring, require: true },
            {
                type: 'date',
                label: 'Expiration Date',
                name: 'expiration_date',
                value: data?.expiration_date ? moment(data.expiration_date, 'YYYY-MM-DD') : '',
                require: false,
            },
            {
                type: 'date',
                label: 'Interview Date',
                name: 'interview_date',
                value: data?.interview_date ? moment(data.interview_date, 'YYYY-MM-DD') : '',
                require: false,
            },

            { type: 'number', label: 'Salary From', name: 'salary_from', value: data?.salary_from, require: true },
            { type: 'number', label: 'Salary To', name: 'salary_to', value: data?.salary_to, require: true },
            {
                type: 'select',
                label: 'Work Duration',
                name: 'work_duration',
                value: data?.work_duration,
                options: dataWorkDuration,
                require: true,
            },
            {
                type: 'time',
                label: 'Working From',
                name: 'working_from',
                value: data?.working_from ? moment(data.working_from, timeFormat) : moment('08:00 am', timeFormat),
            },
            {
                type: 'time',
                label: 'Working To',
                name: 'working_to',
                value: data?.working_to ? moment(data.working_to, timeFormat) : moment('17:00 pm', timeFormat),
            },
            {
                type: 'text',
                label: 'Location',
                name: 'location_description',
                value: data?.location_description ? data.location_description : null,
            },
            {
                type: 'select',
                label: 'Country',
                name: 'country',
                value: data?.country ? data.country : null,
                options: dataCountry,
                require: true,
            },
            { type: 'number', label: 'From Age', name: 'from_age', value: data?.from_age },
            { type: 'number', label: 'To Age', name: 'to_age', value: data?.to_age },
            {
                type: 'select',
                label: 'Finish Education',
                name: 'finish_education',
                value: data?.finish_education,
                options: dataFinishEducation,
            },
            {
                type: 'select',
                label: 'Japanese Level',
                name: 'japanese_level',
                value: data?.japanese_level,
                options: dataJapaneseLevel,
            },
            { type: 'text', label: 'Document', name: 'document', value: data?.document },
            {
                type: 'select',
                label: 'Working Day',
                name: 'working_day',
                value: data?.working_day,
                options: dataWorkingDay,
            },
            {
                span: 24,
                type: 'textarea',
                label: 'Working day description',
                name: 'working_day_description',
                value: data?.working_day_description,
            },
            {
                span: 24,
                type: 'textarea',
                label: 'Other Requirement',
                name: 'job_condition',
                value: data?.job_condition,
            },
        ],
        [employers, data, dataJobs, partners]
    );

    return (
        <Modal
            visible={visible}
            maskClosable={false}
            title={
                <div>
                    {
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
                            <div> {data?.id ? 'Update' : 'Create'}</div>
                            <PostJobBannerUploader photo={data && data?.photo} setFile={setFile} />
                        </div>
                    }
                </div>
            }
            onCancel={handleCancel}
            width={768}
            footer={null}
        >
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Row gutter={24}>
                    {items.map((item) => (
                        <Col key={item.name} span={item.span ? item.span : 8}>
                            <Form.Item
                                name={item.name}
                                label={item.label + ':'}
                                initialValue={item.value}
                                rules={[{ required: item.require, message: 'Data required!' }]}
                            >
                                {item.type === 'select' ? (
                                    <LodoshaSelect
                                        value={item.value}
                                        onChange={(value) => handleChange(item.name, value)}
                                    >
                                        {item.options.map(({ value, text }, index) => (
                                            <Option key={index} value={value}>
                                                {text}
                                            </Option>
                                        ))}
                                    </LodoshaSelect>
                                ) : item.type === 'date' ? (
                                    <LodoshaDatePicker
                                        value={item.value}
                                        onChange={(date, dateString) => handleChange(item.name, dateString)}
                                    />
                                ) : item.type === 'time' ? (
                                    <LodoshaTimePicker
                                        value={item.value}
                                        onChange={(time, timeString) => handleChange(item.name, timeString)}
                                    />
                                ) : item.type === 'switch' ? (
                                    <LodoshaSwitch
                                        checked={item.value}
                                        onChange={(checked) => handleChange(item.name, checked)}
                                    />
                                ) : item.type === 'textarea' ? (
                                    <LodoshaTextArea
                                        placeholder={`Enter your ${item.label.toLocaleLowerCase()}...`}
                                        onChange={(e) => handleChange(item.name, e.target.value)}
                                    />
                                ) : (
                                    <LodoshaInput
                                        type={item.type}
                                        placeholder={`Enter your ${item.label.toLocaleLowerCase()}`}
                                        onChange={(e) => handleChange(item.name, e.target.value)}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    ))}
                    <Col span={24}>
                        <p style={{ fontSize: '14px' }}>Share to:</p>
                        <Row>
                            <Col span={24} style={{ display: 'flex' }}>
                                <Form.Item label="Facebook" style={{ marginBottom: '8px', marginRight: '2rem' }}>
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        onChange={(value) => handleChangeSwitch('facebook', value)}
                                    />
                                </Form.Item>
                                <Form.Item label="Instagram" style={{ marginBottom: '8px' }}>
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        onChange={(value) => handleChangeSwitch('instagram', value)}
                                        disabled={true}
                                    />
                                </Form.Item>
                            </Col>
                            {(shareTo.facebook || shareTo.instagram) && (
                                <Col span={24}>
                                    <Form.Item name="post_caption" initialValue="">
                                        <LodoshaTextArea
                                            placeholder="Input post caption..."
                                            onChange={(e) => handleChange('post_caption', e.target.value)}
                                            style={{ marginTop: 0 }}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
                        <LodoshaButton
                            key="cancel"
                            style={{ marginRight: 16 }}
                            htmlType="button"
                            onClick={handleCancel}
                        >
                            Cancel
                        </LodoshaButton>
                        <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                            {data?.id ? 'Update' : 'Create'}
                        </LodoshaButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default Upsert;
