import React, { useState, useEffect } from 'react';
import { Row, Modal, Steps } from 'antd';
import { LodoshaTextArea } from '../components/UI/ui.style';

const { Step } = Steps;

const errorStatus = 3;

const Status = ({ textarea, visible, setVisible, status = 1, rejection = '', steps = [], onSave }) => {
    const [currentStatus, setCurrentStatus] = useState(0);
    const [reason, setReason] = useState(rejection);

    useEffect(() => {
        setCurrentStatus(status - 1);
    }, [status]);

    useEffect(() => {
        setReason(rejection);
    }, [rejection]);

    const handleChange = (current) => {
        setCurrentStatus(current);
        // console.log(current);
        // if (current !== 0) {
        // }
    };

    const handleSave = (e) => {
        if (currentStatus === errorStatus) {
            // console.log("textarea");
            onSave(currentStatus + 1, reason);
        } else {
            setReason('');
            onSave(currentStatus + 1, '');
        }
    };

    const handleStatusType = (status) => {
        if (status === errorStatus) {
            return 'error';
        } else if (status === steps.length - 1) {
            return 'finish';
        } else {
            return 'process';
        }
    };

    return (
        <Modal
            visible={visible}
            title="Update Status"
            onCancel={() => setVisible(false)}
            onOk={handleSave}
            okText="Save"
            width={736}
        >
            <Steps
                size="small"
                status={handleStatusType(currentStatus)}
                current={currentStatus}
                onChange={handleChange}
            >
                {steps.map(({ value, text }) => (
                    <Step key={value} title={text} />
                ))}
            </Steps>
            {Number(currentStatus) === errorStatus && textarea && (
                <Row style={{ marginTop: 40 }}>
                    <LodoshaTextArea
                        placeholder="Reason of rejection..."
                        name="reject_reason"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                    />
                </Row>
            )}
        </Modal>
    );
};

export default Status;
