import React, { useCallback, useEffect, useState } from 'react';

//antd

import { LeftOutlined, RightOutlined } from '@ant-design/icons';

//components
import NextPrevActionButtonGroupStyles from './nextprevactionbutton.style';
import { useHistory } from 'react-router-dom';
import { getFromLocalStoage } from '../../../utils';
import { Row, Col } from 'antd';

/**
 * *onClickAction accept function we use to fetch data and return next or prev item id
 * 
 * *currentItemId accept Chapter Id, Lesson Id, Content Id
 * 
 * *localStorageKey accept key @e-learning-chapters @e-learning-lessons @e-learning-contents
 * @example
 * ```javascript
 *  <NextPrevActionButtunGroup 
 *      currentItemId={chapterid} 
 *      localStorageKey="e-learning-chapters" 
 *      onClickAction={handleFetchChapters} 
 *  />
 *
 * ```
 */

export default function NextPrevActionButtonGroup({ currentItemId = '', localStorageKey = '', onClickAction }) {
    const history = useHistory();

    const [nextItem, setNextItem] = useState([]);
    const [currentItem, setCurrentItem] = useState([]);
    const [prevItem, setPrevItem] = useState([]);

    const handleActionButtonClick = (action = '') => {
        let slicedPathname = '';

        const pathname = history.location.pathname ? history.location.pathname : '';

        //remove last id from path
        let pathnameToArray = pathname.split('/');
        if (pathnameToArray.length > 0) {
            slicedPathname = pathnameToArray.slice(0, pathnameToArray.length - 1).join('/');
        }

        const savedELearningItems = getFromLocalStoage(localStorageKey);
        if (savedELearningItems && savedELearningItems.length > 0) {
            const curItem = savedELearningItems.find((sEI) => sEI.id === currentItemId);
            if (curItem) {
                setCurrentItem(curItem);
                switch (action) {
                    case 'prev':
                        if (prevItem && prevItem.id) {
                            history.push(`${slicedPathname}/${prevItem?.id}`);
                            onClickAction && onClickAction(prevItem?.id);
                        }
                        break;
                    case 'next':
                        if (nextItem && nextItem.id) {
                            history.replace(`${slicedPathname}/${nextItem?.id}`);
                            onClickAction && onClickAction(nextItem?.id);
                        }
                        break;
                    default:
                        break;
                }
            }
        }
    };

    const handleSetButtonTitle = useCallback(() => {
        let currentItemIndex = 0;
        const savedELearningItems = getFromLocalStoage(localStorageKey);
        if (savedELearningItems && savedELearningItems.length > 0) {
            const curItem = savedELearningItems.find((sEI) => sEI.id === currentItemId);
            if (curItem) {
                setCurrentItem(curItem);
                currentItemIndex = savedELearningItems.indexOf(curItem);
                //prevItem
                const pItem = savedELearningItems[currentItemIndex - 1];
                //nextItem
                const nItem = savedELearningItems[currentItemIndex + 1];

                setPrevItem(pItem ? pItem : []);
                setNextItem(nItem ? nItem : []);
            }
        }
    }, [currentItemId, localStorageKey]);

    useEffect(() => {
        handleSetButtonTitle();
    }, [handleSetButtonTitle]);

    return (
        <NextPrevActionButtonGroupStyles>
            <Row className="action-buttons-container">
                <Col span={8}>
                    <div className="left-action-button-container">
                        <LeftOutlined
                            className="action-button left-button"
                            onClick={() => handleActionButtonClick('prev')}
                        />
                        <div onClick={() => handleActionButtonClick('prev')}>
                            <span>{prevItem.title ? prevItem.title : ''}</span>
                        </div>
                    </div>
                </Col>

                <Col span={8} className="current-item-title">
                    {currentItem ? currentItem.title : ''}
                </Col>

                <Col span={8} className="right-action-button-container">
                    <div onClick={() => handleActionButtonClick('next')}>
                        <span> {nextItem.title ? nextItem.title : ''}</span>
                    </div>
                    <RightOutlined
                        type="text"
                        className="action-button right-button"
                        onClick={() => handleActionButtonClick('next')}
                    />
                </Col>
            </Row>
        </NextPrevActionButtonGroupStyles>
    );
}
