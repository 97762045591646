import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { LodoshaButton, Container } from '../../../components/UI/ui.style';

//antd
import { message, Space, Button, Modal } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

//componetns
import QuestionModal from './QuestionModal';
import SortableTable from '../E-LearningComponents/SortableTable';
import DragHandler from '../E-LearningComponents/DragHandler';

//utils and constants
import { getExamQuestions } from '../../../utils/api/E-Learning/exam';
import { deleteQuestion, updateQuestionOrder } from '../../../utils/api/E-Learning/question';
import { ELEARNING } from '../../../constants/routes';
import { ExternalTypes } from '../../../utils/data';
import { getFromLocalStoage, getNewLastOrder } from '../../../utils';

export default function QuestionList({ selectedSectionTypeId }) {
    const [visible, setVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [questionSectionTypeId, setQuestionSectionTypeId] = useState('');
    const [questions, setQuestions] = useState([]);
    const [postData, setPostData] = useState({});

    const { menuid, examId } = useParams();

    const history = useHistory();

    const data = useMemo(
        () =>
            questions &&
            questions?.map((data, index) => ({
                ...data,
                key: data.id,
                no: index + 1,
                title: data.title,
                choice_one: data.choice_one,
                choice_two: data.choice_two,
                choice_three: data.choice_three,
                choice_four: data.choice_four,
                answer: data.answer,
            })),
        [questions]
    );

    const columns = [
        { title: '#', dataIndex: 'no', width: '5%', align: 'center', fixed: 'left', render: () => <DragHandler /> },
        { title: 'Title', dataIndex: 'title', fixed: 'left', width: '15%' },
        {
            title: 'Media',
            dataIndex: data?.sound ? 'sound' : data?.image ? 'image' : '',
            align: 'center',
            width: '10%',
            render: (text, row, index) =>
                row.image || row.sound ? (
                    <img
                        alt="existing"
                        src={row?.image ? row.image : row.sound ? row.sound : null}
                        style={{ width: '75px', height: '50px' }}
                    />
                ) : null,
        },
        { title: 'Choice One', dataIndex: 'choice_one', width: '12%', align: 'center' },
        { title: 'Choice Two', dataIndex: 'choice_two', width: '12%', align: 'center' },
        { title: 'Choice Three', dataIndex: 'choice_three', width: '12%', align: 'center' },
        { title: 'Choice Four', dataIndex: 'choice_four', width: '12%', align: 'center' },
        { title: 'Answer', dataIndex: 'answer', width: '12%', align: 'center' },
        {
            title: '',
            dataIndex: 'actions',
            align: 'center',
            width: '7%',
            fixed: 'right',
            render: (text, row, index) => (
                <Space size="small" direction="horizontal">
                    <Button title="Edit" type="primary" icon={<EditOutlined />} onClick={(e) => handleEdit(e, row)} />
                    <Button
                        title="Delete"
                        type="danger"
                        icon={<DeleteOutlined />}
                        onClick={(e) => handleDelete(e, row.id)}
                    />
                </Space>
            ),
        },
    ];

    const handleFetchQuestions = useCallback(async () => {
        setLoading(true);
        const response = await getExamQuestions(examId, selectedSectionTypeId);
        if (response && response.errorCode !== 0) {
            setLoading(false);
            message.error(`Couldn't get questions, please try again,${response.errorCode}`);
            return;
        }

        //needed when create new question
        setQuestionSectionTypeId(response && response?.data[0]?.id);

        setQuestions(response && response?.data[0]?.questions);
        setLoading(false);
    }, [selectedSectionTypeId, examId]);

    const handleEdit = (e, row) => {
        e.stopPropagation();
        setPostData(row);
        setUpdateVisible(true);
    };

    const handleRow = (record, index) => {
        return {
            onClick: (e) => {
                history.push(`${ELEARNING}/${menuid}/${examId}/${record.id}`);
            },
        };
    };

    const handleDelete = (e, id) => {
        e.stopPropagation();
        Modal.confirm({
            title: 'Are you sure you want to delete this question?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await deleteQuestion(id);

                    if (response && response.errorCode === 0) {
                        message.success(`Successfully deleted!`);
                        handleFetchQuestions();
                    } else {
                        message.error(`Couldn't delete question, ${response.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request failed!`);
                }
            },
            onCancel() {},
        });
    };

    const Upsert = useCallback(
        () =>
            visible && (
                <QuestionModal
                    postData={postData}
                    setPostData={setPostData}
                    setVisible={setVisible}
                    visible={visible}
                    onRefresh={handleFetchQuestions}
                    title="Add New Question"
                    isCreate
                />
            ),
        [visible, postData, handleFetchQuestions]
    );

    const Update = useCallback(
        () =>
            updateVisible && (
                <QuestionModal
                    postData={postData}
                    setPostData={setPostData}
                    setVisible={setUpdateVisible}
                    visible={updateVisible}
                    isUpdate
                    onRefresh={handleFetchQuestions}
                    title="Update Question"
                />
            ),
        [updateVisible, postData, handleFetchQuestions]
    );

    //fetch question when section type is selected
    useEffect(() => {
        selectedSectionTypeId && handleFetchQuestions();
    }, [handleFetchQuestions, selectedSectionTypeId]);

    //auto order multiply by 100
    useEffect(() => {
        const getLastOrder = () => {
            let lastOrder = getNewLastOrder(questions);

            let level = getFromLocalStoage('selected_level');
            setPostData((prev) => ({
                ...prev,
                order: lastOrder,
                question_section_type_id: questionSectionTypeId,
                section_type_id: selectedSectionTypeId,
                external_id: examId,
                level: level,
                external_type: ExternalTypes.EXAM_QUESTION,
            }));
        };
        getLastOrder();
    }, [questions, visible, menuid, questionSectionTypeId, examId, selectedSectionTypeId]);

    return (
        <Container id="question_table">
            <Space className="new-sentence-btn" align="center">
                <LodoshaButton
                    type="primary"
                    onClick={() => setVisible(true)}
                    disabled={!selectedSectionTypeId && true}
                >
                    <PlusOutlined />
                    Add New
                </LodoshaButton>
                <a href="#section-type-table">
                    <LodoshaButton type="dashed">Back to Section Type</LodoshaButton>
                </a>
            </Space>
            <SortableTable
                handleRow={handleRow}
                data={data}
                columns={columns}
                loading={loading}
                setLoading={setLoading}
                scrollX={1440}
                onUpdateOrder={updateQuestionOrder}
            />
            <Upsert />
            <Update />
        </Container>
    );
}
