import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Container, LodoshaButton } from '../../../components/UI/ui.style';

//api
import { getConversationDetailByContentId } from '../../../utils/api/E-Learning/conversation';

//ant design
import { Descriptions, Row, Col, Spin, message } from 'antd';

//components
import ContentModal from './ContentModal';
import IFrameMarkdown from '../E-LearningComponents/IFrameMarkdown';
import NextPrevActionButtonGroup from '../E-LearningComponents/NextPrevActionButtonGroup';

export default function ItemDetail() {
    const { contentid } = useParams();
    const [contentDetail, setContentDetail] = useState({});
    const [postData, setPostData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);

    const isSubscribed = useRef(true);

    const handleFetchContentDetail = useCallback(
        async (clickedItemId) => {
            try {
                setLoading(true);
                const response = await getConversationDetailByContentId(clickedItemId ? clickedItemId : contentid);

                if (response) {
                    if (response.errorCode !== 0) {
                        setLoading(false);
                        message.error(`Could not get content datail , ${response.message}`);
                        return;
                    }

                    setContentDetail(response ? response?.data : {});
                    setLoading(false);
                    return;
                } else {
                    setLoading(false);
                    message.error(`Could not get content datail, Response is undefined`);
                    return;
                }
            } catch (error) {
                setLoading(false);
                message.error(`Could not get content datail.`);
                return;
            }
        },
        [contentid]
    );

    const Upsert = useCallback(
        () =>
            updateVisible && (
                <ContentModal
                    visible={updateVisible}
                    postData={postData}
                    setVisible={setUpdateVisible}
                    setPostData={setPostData}
                    isUpdate
                    onRefresh={handleFetchContentDetail}
                    title="Update Content Detail"
                />
            ),
        [updateVisible, postData, handleFetchContentDetail]
    );

    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchContentDetail();
        }
        return () => {
            isSubscribed.current = false;
        };
    }, [contentid, handleFetchContentDetail]);

    useEffect(() => {
        setPostData(contentDetail);
    }, [updateVisible, contentDetail]);

    const { japan, kanji, khmer, person, hiragana } = contentDetail;

    return (
        <Container>
            <Spin spinning={loading}>
                <div className='btn-margin-bottom'>
                    <NextPrevActionButtonGroup 
                        onClickAction={handleFetchContentDetail}
                        currentItemId={contentid}
                        localStorageKey="e-learning-contents"
                    />
                </div>
                <Descriptions bordered column={1} title="Detail">
                    <Descriptions.Item label="Person">{person}</Descriptions.Item>
                    <Descriptions.Item label="Japan">{japan}</Descriptions.Item>
                    <Descriptions.Item label="Hiragana">{hiragana}</Descriptions.Item>
                    <Descriptions.Item label="Kanji">{kanji}</Descriptions.Item>
                    <Descriptions.Item label="Khmer">{khmer}</Descriptions.Item>
                </Descriptions>
                <br />
                <IFrameMarkdown
                    css={contentDetail.css ? contentDetail.css : ''}
                    markdown={contentDetail.markdown ? contentDetail.markdown : ''}
                />
                <Row
                    style={{
                        position: 'sticky',
                        bottom: '0',
                        marginTop: '0px',
                        padding: '20px 0',
                        background: '#FAFAFA',
                    }}
                >
                    <Upsert />
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <LodoshaButton
                            type="primary"
                            style={{ marginRight: 16 }}
                            onClick={() => setUpdateVisible(true)}
                        >
                            Update
                        </LodoshaButton>
                    </Col>
                </Row>
            </Spin>
        </Container>
    );
}
