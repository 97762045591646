import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Table, message, Popconfirm, Button, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import ModalPartnerFee from './../ModalPartnerFee';
import { deletePartnerFee, getPartnerFees } from '../../../utils/api/partner';

const PartnerFees = () => {
    const location = useLocation();
    const params = useParams();

    const partner = location.state;
    const partnerId = partner?.id || params.id;
    const isSubscribed = useRef(true);

    const [loading, setLoading] = useState(false);
    const [partnerFees, setPartnerFees] = useState([]);
    const [partnerFee, setPartnerFee] = useState([]);
    const [visibleUpdate, setVisibleUpdate] = useState(false);
    const [totalPartnerFees, setTotalPartnerFees] = useState(0);

    console.log('this componnet got called');

    const handleFetchData = useCallback(
        async (page = 1) => {
            isSubscribed.current && setLoading(true);
            try {
                getPartnerFees(page, partnerId).then((response) => {
                    if (response?.data && isSubscribed.current) {
                        setPartnerFees(response.data);
                        setTotalPartnerFees(response && response.total);
                    }
                    isSubscribed.current && setLoading(false);
                });
            } catch (error) {
                isSubscribed.current && setLoading(false);
                message.error(`Uh oh, request failed!`);
            }
        },
        [partnerId]
    );

    useEffect(() => {
        handleFetchData();
        return () => {
            isSubscribed.current = false;
        };
    }, [handleFetchData]);

    const handlePageChange = (page) => {
        handleFetchData(page);
    };

    const handleRemoveFee = (id) => {
        // removeItem(id);
        setPartnerFees((prevState) => prevState.filter((item) => item.id !== id));
        setTotalPartnerFees(partnerFees.length);
    };

    const handleEditPartnerFee = (e, record) => {
        e.stopPropagation();
        setPartnerFee(record);
        setVisibleUpdate(true);
    };

    const dataSource = useMemo(
        () =>
            partnerFees.length
                ? partnerFees.map((fee, index) => {
                      return {
                          ...fee,
                          key: fee.id,
                          no: index + 1,
                      };
                  })
                : [],
        [partnerFees]
    );

    const columns = [
        { title: 'NO', dataIndex: 'no', key: 'no', width: '2%', align: 'center' },
        { title: 'SERVICE', dataIndex: 'type', key: 'type', width: '20%' },
        { title: 'PRICE', dataIndex: 'price', key: 'price', width: '10%' },
        { title: 'DESCRIPTION', dataIndex: 'description', key: 'description', width: '20%' },
        {
            title: '',
            key: 'actions',
            width: '5%',
            align: 'center',
            render: (record) => (
                <Space size="small" align="center" direction="horizontal">
                    <Button icon={<EditOutlined />} onClick={(e) => handleEditPartnerFee(e, record)} type="primary" />
                    <Popconfirm
                        placement="topLeft"
                        title={'Do you want to delete?'}
                        onConfirm={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleDelete(record);
                        }}
                        onCancel={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            title="Delete"
                            type="danger"
                            className="action-button-margin"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            icon={<DeleteOutlined />}
                        />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const Update = useCallback(
        () =>
            visibleUpdate && (
                <ModalPartnerFee
                    isUpdate
                    setPostData={setPartnerFee}
                    postData={partnerFee}
                    visible={visibleUpdate}
                    setVisible={setVisibleUpdate}
                    onRefresh={handleFetchData}
                />
            ),
        [partnerFee, visibleUpdate, handleFetchData]
    );

    const handleDelete = async (record) => {
        // Delete
        deletePartnerFee(record.id).then((response) => {
            if (response && response.errorCode === 0) {
                message.success('Successfully deleted hospital fee');
                handleRemoveFee(record.id);
            } else {
                message.error(response && response.message);
            }
        });
    };

    return (
        <React.Fragment>
            <Table
                size="medium"
                bordered
                loading={loading}
                dataSource={dataSource}
                columns={columns}
                pagination={{
                    defaultCurrent: 1,
                    defaultPageSize: 15,
                    total: totalPartnerFees,
                    onChange: handlePageChange,
                }}
            />
            <Update />
        </React.Fragment>
    );
};
export default PartnerFees;
