import React, { useState } from 'react';
import { LodoshaButton } from '../../../components/UI/ui.style';
import { sectionTypeList, sectionList } from '../../../utils/data';

//api
import { updateSectionType, postNewSectionType } from '../../../utils/api/E-Learning/exam';

//ant design
import { Modal, Row, Col, Form, message, Select } from 'antd';

export default function SectionTypeModal({
    title,
    visible,
    setVisible,
    onRefresh,
    setPostData,
    postData,
    isCreate,
    isUpdate,
}) {
    const [form] = Form.useForm();

    const { Option } = Select;

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(postData);

    const handleChangeType = (value) => {
        setData((prev) => ({ ...prev, type: value }));
    };

    const handleChangeSection = (value) => {
        console.log(value);
        setData((prev) => ({ ...prev, section: value }));
    };

    //close modal
    const handleCancel = (e) => {
        setVisible(false);
        setPostData({});
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);

            const response = isCreate ? await postNewSectionType(data) : isUpdate ? await updateSectionType(data) : {};
            console.log(response);
            if (response && response.errorCode !== 0) {
                setLoading(false);
                switch (response.errorCode) {
                    case 403:
                        message.info(`${response.message}`);
                        break;
                    default:
                        message.error(`Oh oh, something went wrong. ${response.message}`);
                }

                return;
            }

            if (response) {
                response &&
                    message.success(`Successfully ${isCreate ? 'creating' : isUpdate ? 'updating' : ''} section type`);
            } else {
                message.error(`Uh oh, ${isCreate ? 'creating' : isUpdate ? 'updating' : ''} section type failed!`);
            }

            setLoading(false);
            onRefresh();
            handleCancel();
        } catch (error) {
            setLoading(false);
            message.error(`Uh oh, something went wrong!`);
        }
    };

    return (
        <Modal width={768} title={title} visible={visible} onCancel={handleCancel} maskClosable={false} footer={null}>
            <Form layout="vertical" form={form} onFinish={() => handleSubmit()}>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            rules={[{ required: true, message: 'Data required!' }]}
                            label="Section"
                            name="section"
                            initialValue={data?.section}
                        >
                            <Select onChange={handleChangeSection} placeholder="Please select a type: "> 
                                {sectionList.map((type, index) => (
                                    <Option value={type.value} name={type.name} key={index}>
                                        {type.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[{ required: true, message: 'Data required!' }]}
                            label="Type"
                            name="type"
                            initialValue={data?.type}
                        >
                            <Select onChange={handleChangeType} placeholder="Please select a section: ">
                                {sectionTypeList.map((type, index) => (
                                    <Option value={type.value} name={type.name} key={index}>
                                        {type.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
                        <LodoshaButton
                            key="cancel"
                            style={{ marginRight: 16 }}
                            htmlType="button"
                            onClick={handleCancel}
                        >
                            Cancel
                        </LodoshaButton>
                        <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                            Update
                        </LodoshaButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
