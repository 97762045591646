import React, { useState, useMemo } from 'react';

//antd
import { Modal, Upload, Button, Col, Row, message, Form, Select } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

//constant & utils
import { menuToText, skillTypes } from '../../../utils/data';
import { importSkill } from '../../../utils/api/E-Learning/skills';

//components
import { LodoshaInput } from '../../../components/UI/ui.style';

export default function ImportModal({ visible, setVisible, postData, setPostData, onRefresh }) {
    const [fileList, setFileList] = useState();
    const [data, setData] = useState(postData);
    const [loading, setLoading] = useState(false);

    const { form } = Form.useForm();

    const { Option } = Select;

    const examFormFields = useMemo(
        () => [
            {
                type: 'text',
                label: 'Name',
                name: 'name',
                value: data?.name,
                required: true,
                span: 24,
            },
            {
                type: 'text',
                label: 'Menu',
                name: 'menu',
                value: data.menuName ? data.menuName : menuToText[data?.menu - 1],
                disabled: true,
                span: 24,
            },
            {
                type: 'text',
                label: 'Type',
                name: 'type',
                value: data?.type,
                span: 24,
                optionList: skillTypes,
                isSelect: true,
                required: true,
            },
        ],
        [data]
    );

    const handleImport = async () => {
        try {
            setLoading(true);
            const response = await importSkill(data, fileList);
            if (response) {
                if (response.errorCode === 0) {
                    message.success('Successfully imported Skill.');
                    onRefresh();
                    handleCancel();
                } else {
                    message.error(`Couldn't import, please try again: ${response.message}`);
                    setLoading(false);
                }
            } else {
                message.error(`Couldn't import, please try again.`);
                setLoading(false);
            }
        } catch (error) {
            message.error(`Couldn't import ${error.message}`);
            setLoading(false);
        }
    };

    const handleChangeSkillType = (value) => {
        setData((prev) => ({ ...prev, type: value }));
    };

    const handleChooseFile = (file) => {
        setFileList(file.file);
    };

    const handleCancel = () => {
        setFileList();
        setPostData({});
        setVisible(false);
    };

    const handleFileRemove = () => {
        setFileList();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({ ...prev, [name]: value }));
    };

    const beforeUpload = () => {
        return false;
    };

    return (
        <Modal footer={null} title="Import Skill" visible={visible} onCancel={handleCancel} maskClosable={false}>
            <Row>
                <Col span={24}>
                    <Form form={form} layout="vertical">
                        <Row>
                            {examFormFields?.map((field, index) => {
                                const { type, label, name, value, required, span, disabled, isSelect, optionList } =
                                    field;
                                return (
                                    <Col span={span} key={index}>
                                        <Form.Item
                                            rules={[{ required: required, message: 'Data required!' }]}
                                            label={`${label}:`}
                                            name={name}
                                            initialValue={value}
                                        >
                                            {isSelect ? (
                                                <Select
                                                    placeholder="Please select: "
                                                    name={name}
                                                    size="large"
                                                    disabled={disabled}
                                                    onChange={handleChangeSkillType}
                                                >
                                                    {optionList?.map((option, index) => (
                                                        <Option value={option.value} key={index}>
                                                            {option.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            ) : (
                                                <LodoshaInput
                                                    type={type}
                                                    disabled={disabled && true}
                                                    onChange={handleChange}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Form>
                </Col>
                <Col span={24}>
                    <Upload
                        accept=".csv"
                        beforeUpload={beforeUpload}
                        onChange={handleChooseFile}
                        onRemove={handleFileRemove}
                    >
                        <Button icon={<UploadOutlined />}>Select CSV File</Button>
                    </Upload>
                    <a
                        href={`https://docs.google.com/uc?export=download&id=1GyiYAsRn1HPMIG4uT2_HIDkOwVmFB7OU`}
                        download
                        style={{ textDecoration: 'underline' }}
                    >
                        <p style={{ marginTop: '1rem' }}>Download CSV sample for Skill.</p>
                    </a>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button type="primary" disabled={!fileList && true} onClick={handleImport} loading={loading}>
                            Import
                        </Button>
                    </div>
                </Col>
            </Row>
        </Modal>
    );
}
