export const dataType = {
    COUNTRY: 'country',
    GENDER: 'gender',
    INDUSTRY: 'industry_type',
    EMPLOYER_STATUS: 'employer_status',
    EMPLOYEE_STATUS: 'employee_status',
    JOB_TYPE: 'job_type',
    JOB_LEVEL: 'job_level',
    FAVORITE_STATUS: 'favorite_status',
    POST_STATUS: 'post_status',
    SHIRT_SIZE: 'shirt_size',
    WEDDING_STATUS: 'wedding_status',
    BLOOD: 'blood',
    JAPANESE_LEVEL: 'japanese_level',
    USE_HAND: 'use_hand',
    RESULT_MATH: 'result_math',
    STUDY_LEVEL: 'study_level',
    RELATIVE: 'relative',
    WORKING_DAY: 'working_day',
    EVER_TO_JAPAN: 'ever_to_japan',
    PARTNER_CATEGORY: 'partner_category',
    TAG_STATUS: 'tag_status',
    JFT_BASIC_LEVEL: 'jft_basic_level',
    APPLICATION_STATUS: 'application_status',
    FILE_CATEGORY: 'file_category',
    PARTNER_PRODUCT_TYPE: 'partner_product_type',
    RELIGION: 'religion',
    GENDER_JOB: 'gender_job',
    YES_NO: 'yes_no',
    FINANCIAL_STATUS: 'financial_status',
    COLLATERAL_STATUS: 'collateral_status',
    GUARANTEER_STATUS: 'guaranteer_status',
    CANDIDATE_TYPE: 'candidate_type',
    FINISH_EDUCATION: 'finish_education',
    WORK_DURATION: 'work_duration',
    CANDIDATE_RCORD_TYPE: 'candidate_record_type',
    CALL_TYPE: 'call_type',
    INTERVIEW_PAYMENT: 'interview_payment',
    PARTNER_EMPLOYEE_STATUS: 'partner_employee_status',
    LOOKING_JOBS: 'looking_jobs',
};

export const CandidateStatus = {
    REMOVE: 0,
    RECRUIT: 1,
    TRANSLATING: 2,
    PUBLISH: 3,
    PASS_INTERVIEW: 4,
    SENT_JAPAN: 5,
    BACK_JAPAN: 6,
    FOLLOW_UP: 7,
};

export const ApplicationStatus = {
    REMOVE: 0,
    PENDING: 1,
    WILL_INTERVIEW: 2,
    PASSED: 3,
    FAILED: 4,
    REJECTED: 5,
    PAID: 6,
    UNAVAILABLE: 7,
};

export default function getTextDisplay(value, type, isLanguage = false) {
    var data;
    switch (type) {
        case dataType.COUNTRY:
            data = dataCountry;
            break;
        case dataType.GENDER:
            data = dataGender;
            break;
        case dataType.INDUSTRY:
            data = dataIndustry;
            break;
        case dataType.EMPLOYER_STATUS:
            data = dataEmployerStatus;
            break;
        case dataType.PARTNER_EMPLOYEE_STATUS:
            data = dataPartnerEmployeeStatus;
            break;
        case dataType.EMPLOYEE_STATUS:
            data = dataEmployeeStatus;
            break;
        case dataType.LOOKING_JOBS:
            data = dataLookingJobs;
            break;
        case dataType.JOB_TYPE:
            data = dataJobTypes;
            break;
        case dataType.JOB_LEVEL:
            data = dataJobLevels;
            break;
        case dataType.FAVORITE_STATUS:
            data = dataFavoriteStatus;
            break;
        case dataType.POST_STATUS:
            data = dataPostStatus;
            break;
        case dataType.SHIRT_SIZE:
            data = dataShirtSize;
            break;
        case dataType.WEDDING_STATUS:
            data = dataWeddingStatus;
            break;
        case dataType.BLOOD:
            data = dataBlood;
            break;
        case dataType.JAPANESE_LEVEL:
            data = dataJapaneseLevel;
            break;
        case dataType.USE_HAND:
            data = dataUseHands;
            break;
        case dataType.RESULT_MATH:
            data = dataMath;
            break;
        case dataType.STUDY_LEVEL:
            data = dataStudyLevel;
            break;
        case dataType.RELATIVE:
            data = dataRelative;
            break;
        case dataType.WORKING_DAY:
            data = dataWorkingDay;
            break;
        case dataType.EVER_TO_JAPAN:
            data = dataEverToJapan;
            break;
        case dataType.PARTNER_CATEGORY:
            data = dataPartnerCategory;
            break;
        case dataType.TAG_STATUS:
            data = dataTagStatus;
            break;
        case dataType.JFT_BASIC_LEVEL:
            data = dataJftBasicLevel;
            break;
        case dataType.APPLICATION_STATUS:
            data = dataApplicationStatus;
            break;
        case dataType.FILE_CATEGORY:
            data = dataFileCategory;
            break;
        case dataType.PARTNER_PRODUCT_TYPE:
            data = dataPartnerProductType;
            break;
        case dataType.RELIGION:
            data = dataReligion;
            break;
        case dataType.GENDER_JOB:
            data = dataGenderJob;
            break;
        case dataType.YES_NO:
            data = dataYesOrNo;
            break;
        case dataType.FINANCIAL_STATUS:
            data = dataFinancialStatus;
            break;
        case dataType.COLLATERAL_STATUS:
            data = dataCollateralStatus;
            break;
        case dataType.GUARANTEER_STATUS:
            data = dataGuaranteerStatus;
            break;
        case dataType.INTERVIEW_PAYMENT:
            data = dataInterviewPayment;
            break;
        case dataType.CANDIDATE_TYPE:
            data = dataCandidateType;
            break;
        case dataType.FINISH_EDUCATION:
            data = dataFinishEducation;
            break;
        case dataType.WORK_DURATION:
            data = dataWorkDuration;
            break;
        case dataType.CANDIDATE_RCORD_TYPE:
            data = dataCandidateRecordType;
            break;
        case dataType.CALL_TYPE:
            data = dataCallType;
            break;
        default:
            break;
    }
    var displayData = data
        ? data.find((d) => {
              return d.value === value;
          })
        : null;

    if (isLanguage) {
        return displayData ? displayData.langauge : null;
    }
    return displayData ? displayData.text : null;
}
export const dataPartnerEmployeeStatus = [
    { value: 0, text: 'Remove' },
    { value: 1, text: 'Pending' },
    { value: 2, text: 'Will Interview' },
    { value: 3, text: 'Passed' },
    { value: 4, text: 'Failed' },
    { value: 5, text: 'Rejected' },
    { value: 6, text: 'Paid' },
    { value: 7, text: 'Not Available' },
    { value: 8, text: 'Contacting' },
];
export const dataCountry = [
    { value: 81, langauge: 'Japan', text: 'Japan' },
    { value: 855, langauge: 'Cambodia', text: 'Cambodia' },
];
export const dataGender = [
    { value: 1, langauge: 'Male', text: 'Male' },
    { value: 2, langauge: 'Female', text: 'Female' },
];
export const dataIndustry = [
    { value: 1, langauge: 'Service', text: 'Service' },
    { value: 2, langauge: 'Hotel', text: 'Hotel' },
    { value: 3, langauge: 'Agriculture', text: 'Agriculture' },
    { value: 4, langauge: 'Industry', text: 'Industry' },
    { value: 5, langauge: 'Nurse', text: 'Nurse' },
];

export const dataEmployerStatus = [
    { value: 1, text: 'Pending' },
    { value: 2, text: 'Updating' },
    { value: 3, text: 'Publish' },
    { value: 4, text: 'Unpublish' },
];

export const dataPostStatus = [
    { value: 1, text: 'Pending' },
    { value: 2, text: 'Translating' },
    { value: 3, text: 'Publish' },
    { value: 4, text: 'Reject' },
    { value: 5, text: 'Expired' },
];

export const dataFavoriteStatus = [
    { value: 1, text: 'Pending' },
    { value: 2, text: 'Will Interview' },
    { value: 3, text: 'Passed' },
    { value: 4, text: 'Failed' },
    { value: 5, text: 'Rejected' },
    { value: 6, text: 'Paid' },
    { value: 7, text: 'Unavailable' },
    { value: 8, text: 'Contacting' },
];

export const dataJapaneseLevel = [
    { value: 1, text: 'N1' },
    { value: 2, text: 'N2' },
    { value: 3, text: 'N3' },
    { value: 4, text: 'N4' },
    { value: 5, text: 'N5' },
    { value: 6, text: 'Learning' },
    { value: 7, text: 'Nothing' },
];

export const dataJftBasicLevel = [
    { value: 1, text: 'A1' },
    { value: 2, text: 'A2' },
    { value: 3, text: 'B1' },
    { value: 4, text: 'B2' },
    { value: 5, text: 'C1' },
    { value: 6, text: 'C2' },
    { value: 7, text: 'Not Yet Test' },
];

export const dataBlood = [
    { value: 1, text: 'A+' },
    { value: 2, text: 'B+' },
    { value: 3, text: 'AB+' },
    { value: 4, text: 'O+' },
    { value: 10, text: 'A-' },
    { value: 20, text: 'B-' },
    { value: 30, text: 'AB-' },
    { value: 40, text: 'O-' },
];

export const dataUseHands = [
    { value: 1, text: 'Left' },
    { value: 2, text: 'Right' },
    { value: 3, text: 'Both' },
];

export const dataWeddingStatus = [
    { value: 1, text: 'Single' },
    { value: 2, text: 'Married' },
    { value: 3, text: 'Break' },
];

export const dataShirtSize = [
    { value: 1, text: 'XS' },
    { value: 2, text: 'S' },
    { value: 3, text: 'M' },
    { value: 4, text: 'L' },
    { value: 5, text: 'XL' },
    { value: 6, text: 'XXL' },
    { value: 7, text: 'XXXL' },
];

export const dataLookingJobs = [
    { value: 1, text: 'local Jobs' },
    { value: 2, text: 'Oversea Jobs' },
    { value: 3, text: 'All Jobs' },
    { value: 4, text: 'Stop Looking any Jobs' },
];

export const dataEmployeeStatus = [
    { value: CandidateStatus.REMOVE, text: 'Remove' },
    { value: CandidateStatus.RECRUIT, text: 'Recruit' },
    { value: CandidateStatus.FOLLOW_UP, text: 'Can Not Contact' },
    { value: CandidateStatus.TRANSLATING, text: 'Translating' },
    { value: CandidateStatus.PUBLISH, text: 'Publish' },
    { value: CandidateStatus.PASS_INTERVIEW, text: 'Pass Interview' },
    { value: CandidateStatus.SENT_JAPAN, text: 'Sent Japan' },
    { value: CandidateStatus.BACK_JAPAN, text: 'Back Japan' },
];

export const dataJobTypes = [
    { value: 1, text: 'Full Time' },
    { value: 2, text: 'Part Time' },
    { value: 3, text: 'Freelance' },
];

export const dataJobLevels = [
    { value: 1, text: 'No Experience' },
    { value: 2, text: 'Junior' },
    { value: 3, text: 'Supervisor' },
    { value: 4, text: 'Top Executive' },
];

export const dataMath = [
    { value: 1, text: 'A' },
    { value: 2, text: 'B' },
    { value: 3, text: 'C' },
    { value: 4, text: 'D' },
    { value: 5, text: 'E' },
    { value: 6, text: 'F' },
];

export const dataStudyLevel = [
    { value: 1, text: 'Primary School' },
    { value: 2, text: 'BAC I (grade 9)' },
    { value: 3, text: 'BAC II (grade 12)' },
    { value: 4, text: 'Associate Degree' },
    { value: 5, text: 'Bachelor Degree' },
    { value: 6, text: 'Master Degree' },
];

export const dataRelative = [
    { value: 1, text: 'Brother' },
    { value: 2, text: 'Sister' },
    { value: 3, text: 'Daughter' },
    { value: 4, text: 'Son' },
    { value: 5, text: 'Mother' },
    { value: 6, text: 'Father' },
    { value: 7, text: 'Uncle' },
    { value: 8, text: 'Aunt' },
    { value: 9, text: 'Grand Mother' },
    { value: 10, text: 'Grand Father' },
    { value: 11, text: 'Wife' },
    { value: 12, text: 'Husband' },
];

export const dataWorkingDay = [
    { value: 1, text: 'Monday-Friday' },
    { value: 2, text: 'Monday-Saturday' },
    { value: 3, text: 'Monday-Saturday (Half)' },
    { value: 4, text: 'Saturday-Sunday' },
    { value: 5, text: 'Holiday' },
    { value: 6, text: 'Other' },
];

export const dataEverToJapan = [
    { value: 0, text: 'Never' },
    { value: 1, text: 'Ever' },
];

export const dataPartnerCategory = [
    { value: 1, text: 'School' },
    { value: 2, text: 'College' },
    { value: 3, text: 'Dispatching Company' },
    { value: 4, text: 'Ministry' },
    { value: 5, text: 'Job Agency' },
    { value: 6, text: 'Company' },
    { value: 7, text: 'Association' },
    { value: 8, text: 'Super Market' },
    { value: 30, text: 'Other' },
];

export const dataTagStatus = [
    { value: 'Work', text: 'Work' },
    { value: 'Skill Worker', text: 'Skill Worker' },
    { value: 'Potential Skill Worker', text: 'Potential Skill Worker' },
    { value: 'Trainee', text: 'Trainee' },
    { value: 'Potential Trainee', text: 'Potential Trainee' },
    { value: 'Student', text: 'Student' },
    { value: 'Potential Student', text: 'Potential Student' },
    { value: 'Staff', text: 'Staff' },
    { value: 'Potential Staff', text: 'Potential Staff' },
    { value: 'Need Financial Support', text: 'Need Financial Support' },
    { value: 'Not Enough Document', text: 'Not Enough Document' },
];

export const dataApplicationStatus = [
    { value: 9, text: 'Apply' },
    { value: 1, text: 'Pending' },
    { value: 8, text: 'Contacting' },
    { value: 2, text: 'Will Interview' },
    { value: 3, text: 'Passed' },
    { value: 4, text: 'Failed' },
    { value: 5, text: 'Rejected' },
    { value: 6, text: 'Paid' },
    { value: 7, text: 'Unavailable' },
];

export const dataFileCategory = [
    { value: 'profile', text: 'Profile' },
    { value: 'photo', text: 'Photo' },
    { value: 'introduction_video', text: 'Introduction Video' },
    { value: 'pension', text: 'Pension' },
    { value: 'attachment', text: 'Attachment' },
];

export const dataPartnerProductType = [
    { value: 1, text: 'Trainee' },
    { value: 2, text: 'Special Trainee' },
];

export const dataReligion = [
    { value: 0, text: '-' },
    { value: 1, text: 'Buddish' },
    { value: 2, text: 'Christian' },
    { value: 3, text: 'Muslim' },
    { value: 4, text: 'Other' },
];

export const dataSearchByLatestCall = [{ value: true, text: 'Yes' }];

export const dataGenderJob = [
    { value: 1, langauge: 'Male', text: 'Male' },
    { value: 2, langauge: 'Female', text: 'Female' },
    { value: 3, langauge: 'Both', text: 'Both' },
];

export const dataOption = [
    { value: true, text: 'Yes' },
    { value: false, text: 'No' },
];

export const dataYesOrNo = [
    { value: 0, text: 'No' },
    { value: 1, text: 'Yes' },
];

export const dataFinancialStatus = [
    { value: 1, text: "Don't Have" },
    { value: 2, text: 'Have All' },
    { value: 3, text: 'Have First Payment' },
    { value: 4, text: 'Have Some' },
];

export const dataCollateralStatus = [
    { value: 3, text: 'None' },
    { value: 1, text: "Don't Have" },
    { value: 2, text: 'Have' },
];

export const dataUserType = [
    { value: 1, text: 'Candidate' },
    { value: 2, text: 'E-Learning' },
];

export const dataGuaranteerStatus = [
    { value: 3, text: 'None' },
    { value: 1, text: "Don't Have" },
    { value: 2, text: 'Have' },
];

export const dataInterviewPayment = [
    { value: 1, text: 'Paid' },
    { value: 0, text: 'Unpaid' },
];

export const dataNoOutboundCall = [{ value: 1, text: 'Yes' }];

export const dataEmployeeHaveNotCalled = [
    { value: 1, text: 'All' },
    { value: 2, text: 'Candidate' },
    { value: 3, text: 'E-Learning' },
];

export const dataPercentage = [
    { value: 0, text: '0%' },
    { value: 20, text: '1%-20%' },
    { value: 40, text: '21%-40%' },
    { value: 60, text: '41%-60%' },
    { value: 80, text: '61%-80%' },
    { value: 100, text: '81%-100%' },
];

export const dataCandidateType = [
    { value: 1, text: 'Trainee' },
    { value: 2, text: 'Skill Worker' },
    { value: 3, text: 'Student' },
];

export const dataWorkDuration = [
    { value: 1, text: '1 Years' },
    { value: 2, text: '2 Years' },
    { value: 3, text: '3 Years' },
    { value: 4, text: '4 Years' },
    { value: 5, text: '5 Years' },
];

export const dataFinishEducation = [
    { value: 1, text: 'Primary School' },
    { value: 2, text: 'Secondary School' },
    { value: 3, text: 'High School' },
    { value: 4, text: 'Know read and write' },
    { value: 5, text: 'Bachelor Degree' },
];

export const dataCandidateRecordType = [
    { value: 1, text: 'Candidate' },
    { value: 2, text: 'E-Learning' },
];

export const dataPhoneCompany = [
    { value: 'smart', text: 'Smart' },
    { value: 'cellcard', text: 'Cellcard' },
    { value: 'metfone', text: 'Metfone' },
];

export const dataCallType = [
    { value: 1, text: 'Called' },
    { value: 2, text: 'Not Response' },
    { value: 3, text: "Can't Contact" },
];

export const membershipType = [
    {
        value: 'employee',
        text: 'Employee',
    },
    {
        value: 'partner',
        text: 'Partner',
    },
    {
        value: 'company',
        text: 'Company',
    },
];

export const membershipTypeToText = {
    employee: 'Employee',
    partner: 'Partner',
    company: 'Company',
};

export const userTypeToText = ['', 'Candidate', 'E-Learning'];

export const autoDescriptionTypeToText = [
    {
        name: 'Favorite Option',
        type: 1,
    },
    {
        name: 'Health Situation Option',
        type: 2,
    },
    {
        name: 'Characteristic Option',
        type: 3,
    },
    {
        name: 'Reason And Goal Option',
        type: 4,
    },
    {
        name: 'Back Japan Goal Option',
        type: 5,
    },
    {
        name: 'Body Problem Option',
        type: 6,
    },
    {
        name: 'Tattoo Option',
        type: 7,
    },
    {
        name: 'Eyes Problem Option',
        type: 8,
    },
];

export const autoDescriptionTypes = {
    FAVORITE: 1,
    HEALTH_SITUATION: 2,
    CHARACTERISTIC: 3,
    REASON_AND_GOAL: 4,
    BACK_JAPAN_GOAL: 5,
    BODY_PROBLEM: 6,
    TATTOO: 7,
    EYES_PROBLEM: 8,
};

export function getAutoDescriptionTextDisplay(autoDescriptions = {}, autoDescriptionId = [], cb) {
    try {
        const existAutoDescriptions = [];
        Object.keys(autoDescriptions).map((value, index) => {
            const keyValues = autoDescriptions[value];
            if (keyValues && keyValues.length > 0) {
                keyValues.map((value) => {
                    const existAutoDescription = autoDescriptionId.includes(value.id);
                    console.log('existAutoDescription', existAutoDescription);
                    if (existAutoDescription) {
                        existAutoDescriptions.push(value.name ? value?.name : '');
                    }
                });
            }
        });
        return existAutoDescriptions;
    } catch (error) {
        return [];
    }
}

export const callActionTypesToText = ['Called', 'Missed Call', "Can't Contact"];
export const callActionTypes = [
    {
        text: 'Called',
        value: 1,
    },
    {
        text: 'Missed',
        value: 2,
    },
    {
        text: "Can't Contact",
        value: 3,
    },
];

//related to E-Learning Feature
export const menuToText = ['Japanese', '14 Skills', 'Conversation', 'Exam', 'Culture,', 'Pre-Interview', 'JLPT'];

export const cultureTypes = [
    { name: 'Q&A', value: 1 },
    { name: 'General', value: 2 },
    { name: 'Rituals and Beliefs', value: 3 },
    { name: 'Cultures and Traditions', value: 4 },
    { name: 'Japanese Way of Living and Working', value: 5 },
    { name: 'Tourism Sites', value: 6 },
    { name: 'Foods', value: 7 },
    { name: 'History of Anime', value: 8 },
    { name: 'Latest News', value: 9 },
];

export const cultureTypeToText = [
    'Q&A',
    'General',
    'Rituals and Beliefs',
    'Cultures and Traditions',
    'Japanese Way of Living and Wokring',
    'Tourism Sites',
    'Foods',
    'History of Anime',
    'Latest News',
];

export const chapterType = [
    { name: 'Text', value: 'text' },
    { name: 'PDF', value: 'pdf' },
];

export const lessonTypeToText = ['Alphabet', 'Skill', 'Conversation', '', '', '', 'JLPT'];

export const jlptSubTypeToText = {
    Reading_Long: 'Reading - Long',
    Reading_Short: 'Reading - Short',
    Readng_Medium: 'Reading - Medium',
    Grammar: 'Grammar',
    Reading: 'Reading',
    Kanji: 'Kanji',
    Vocabulary: 'Vocabulary',
    Listening: 'Listening',
};

export const lessonTypeSubTypeList = [
    { name: 'Alphabet', value: 1, subTypes: [] },
    { name: 'Skill', value: 2, subTypes: [] },
    {
        name: 'Conversation',
        value: 3,
        subTypes: [
            { value: 'Dialy Conversation', name: 'Dialy Conversation' },
            { value: 'Business Conversation', name: 'Business Conversation' },
        ],
    },
    {
        name: 'JLPT',
        value: 7,
        subTypes: [
            { value: 'Grammar', name: 'Grammar' },
            { value: 'Listening', name: 'Listening' },
            { value: 'Reading_Long', name: 'Reading - Long' },
            { value: 'Reading_Medium', name: 'Reading - Medium' },
            { value: 'Reading_Short', name: 'Reading - Short' },
            { value: 'Vocabulary', name: 'Vocabulary' },
            { value: 'Kanji', name: 'Kanji' },
        ],
    },
];

export const ExternalTypes = { EXAM_QUESTION: 'exam question', LESSON_QUESTION: 'lesson question' };

export const examType = ['text', 'pdf file'];

export const sectionList = [
    {
        name: 'Vocabulary',
        value: 'vocabulary',
    },
    { name: 'Grammar', value: 'grammar' },
    {
        name: 'Reading',
        value: 'reading',
    },
    {
        name: 'Listening',
        value: 'listening',
    },
];

//14skills
export const skillTypes = [
    { name: 'Care Worker', value: 'care_worker' },
    { name: 'Machine Parts and Tooling Industries', value: 'machine_parts_and_tooling_industries' },
    {
        name: 'Electric Electronics and Information Industries',
        value: 'electric_electronics_and_information_industries',
    },
    { name: 'Automobile Repair and Maintenance', value: 'automobile_repair_and_maintenance' },
    { name: 'Accomodation Industry', value: 'accomodation_industry' },
    { name: 'Fishery and Aquaculture', vaue: 'fishery_and_aquaculture' },
    { name: 'Food Service Industry', value: 'food_service_industry' },
    { name: 'Building Cleaning Management', value: 'building_cleaning_management' },
    { name: 'Industrial Machinery Industry', value: 'industrial_machinery_industry' },
    { name: 'Construction Industry', value: 'construction_industry' },
    { name: 'Shipbuilding and Ship Machinery Industry', value: 'shipbuilding_and_ship_machinery_industry' },
    { name: 'Aviation Industry', value: 'aviation_industry' },
    { name: 'Agriculture', value: 'agriculture' },
    { name: 'Manufacture of Food and Beverage', value: 'manufacture_of_food_and_beverage' },
];

export const skillTypeToText = {
    care_worker: 'Care Worker',
    machine_parts_and_tooling_industries: 'Machine Parts and Tooling Industries',
    electric_electronics_and_information_industries: 'Electric Electronics and Information Industries',
    automobile_repair_and_maintenance: 'Automobile Repair and Maintenance',
    accomodation_industry: 'Accomodation Industry',
    fishery_and_aquaculture: 'Fishery and Aquaculture',
    food_service_industry: 'Food Service Industry',
    building_cleaning_management: 'Building Cleaning Management',
    industrial_machinery_industry: 'Industrial Machinery Industry',
    construction_industry: 'Construction Industry',
    shipbuilding_and_ship_machinery_industry: 'Shipbuilding and Ship Machinery Industry',
    aviation_industry: 'Aviation Industry',
    agriculture: 'Agriculture',
    manufacture_of_food_and_beverage: 'Manufacture of Food and Beverage',
};

export const jlptTypes = [
    { name: 'JLPT N5', value: 'jlpt_n5' },
    { name: 'JLPT N4', value: 'jlpt_n4' },
    { name: 'JLPT N3', value: 'jlpt_n3' },
    { name: 'JLPT N2', value: 'jlpt_n2' },
    { name: 'JLPT N1', value: 'jlpt_n1' },
];

export const jlptTypeToText = {
    jlpt_n5: 'JLPT N5',
    jlpt_n4: 'JLPT N4',
    jlpt_n3: 'JLPT N3',
    jlpt_n2: 'JLPT N2',
    jlpt_n1: 'JLPT N1',
};

export const sectionTypeList = [
    { name: 'See picture and choose the correct word', value: 'see picture and choose the correct word' },
    { name: 'Choose and fill the gap', value: 'choose and fill the gap' },
    { name: 'Choose the similar meaning', value: 'choose the similar meaning' },
    { name: 'How to read underline Kanji and Hiragana', value: 'how to read underline Kanji and Hiragana' },
    { name: 'Choose the correct word', value: 'choose the correct word' },
    { name: 'Read and choose the correct answer', value: 'read and choose the correct answer' },
    {
        name: '(Image) Listen to the question and listen to conversation and choose the correct answer',
        value: '(image) listen to the question and listen to conversation and choose the correct answer',
    },
    {
        name: '(Sentence) Listen to the question and listen to conversation then choose the correct answer',
        value: '(sentence) listen to the question and listen to conversation then choose the correct answer',
    },
];

export const notificationTypes = [
    { name: 'General', value: 'general' },
    { name: 'Lesson', value: 'lesson' },
    { name: 'Exam', value: 'exam' },
    { name: 'Post Job', value: 'post_job' },
    { name: 'Blog', value: 'blog' },
];

export const notificationTypeToText = {
    general: 'General',
    lesson: 'Lesson',
    exam: 'Exam',
    post_job: 'Post Job',
    blog: 'Blog',
};

export const markdownTypes = [
    {
        value: 1,
        text: 'General',
    },
    {
        value: 2,
        text: 'Layout',
    },
];

export const markdownTypeToText = ['General', 'Layout'];

//related to E-learning ends here
