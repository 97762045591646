import styled from '@emotion/styled';

const MarkdownPreviewWrapper = styled.div`
    ul {
        list-style: none;
        padding-left: 0;
    }

    a {
        color: #000;
    }

    p {
        margin-bottom: 0;
    }

    .text-area {
        border-radius: 0.5rem;
        height: 350px;
        border: 2px solid #152688;
        padding: 1rem;
    }
    .div-as-text-area {
        background-color: #f4f4f4;
        cursor: text;
        overflow-y: scroll;
    }

    .text-area-title {
        opacity: 0.8;
        margin-bottom: 0.5rem;
        cursor: pointer;
    }

    .text-area-title.active {
        opacity: 1;
        font-weight: bold;
    }

    .upload-btn {
        width: 100%;
        margin-top: 0.5rem;
        span .ant-upload.ant-upload-select {
            display: inline;
            span button {
                width: 100%;
            }
        }
    }

    .images-container {
        width: 350px;
        height: 400px;
        overflow-y: scroll;
        .image-container {
            position: relative;
            cursor: pointer;
            .image {
                height: 100px;
                min-height: 100px;
                max-height: 100px;
                width: 100%;
                border: 2px solid #fff;
                border-radius: 0.3rem;
                object-fit: contain;
            }
            .image-mask {
                height: 100px;
                width: 100%;
                top: 0;
                position: absolute;
                background-color: #000;
                opacity: 0.7;
            }
            .select-text {
                top: 0;
                height: 100px;
                width: 100%;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-size: 12px;
            }
        }
    }

    .sample-container {
        width: 350px;
        height: 400px;
        overflow-y: scroll;
        font-size: 16px;
        .sample {
            padding: 0.5rem;
            cursor: pointer;
        }
        .sample:hover {
            opacity: 0.8;
        }
    }

    //style for markdown
    .text-center {
        text-align: center;
    }

    .qna ul {
        padding-left: 0;
    }

    .qna ul li {
        margin-top: 0.5rem;
        cursor: pointer;
        padding: 0.5rem;
        border: 1px solid black;
        border-radius: 0.5rem;
    }

    .qna ul li:hover {
        background-color: #152688;
        border: 1px solid #fff;
        a {
            color: #f4f4f4;
        }
    }

    //color and background
    .dark-blue {
        color: #313552;
    }
    .bg-dark-blue {
        color: #fff;
        background-color: #313552;
        padding: 0 0.2rem;
    }
    .blue-sky {
        color: #08d9d6;
    }
    .bg-blue-sky {
        color: #fff;
        background-color: #08d9d6;
        padding: 0 0.2rem;
    }
    .black {
        color: #252a34;
    }
    .bg-black {
        color: #fff;
        background-color: #252a34;
        padding: 0 0.2rem;
    }
    .orange {
        color: #f08a5d;
    }
    .bg-orange {
        color: #fff;
        background-color: #f08a5d;
        padding: 0 0.2rem;
    }
    .pink {
        color: #fa4eab;
    }
    .bg-pink {
        color: #fff;
        background-color: #fa4eab;
        padding: 0 0.2rem;
    }
    .red {
        color: #dd4a48;
    }
    .bg-red {
        color: #fff;
        background-color: #dd4a48;
        padding: 0 0.2rem;
    }
    .dark-cyan {
        color: #008b8b;
    }
    .bg-dark-cyan {
        background-color: #008b8b;
        color: #fff;
        padding: 0 0.2rem;
    }
    .purple {
        color: #800080;
    }
    .bg-purple {
        color: #fff;
        background-color: #800080;
        padding: 0 0.2rem;
    }
    .green {
        color: #2eb086;
    }
    .bg-green {
        color: #fff;
        background-color: #2eb086;
        padding: 0 0.2rem;
    }
    .navy {
        color: #313552;
    }
    .bg-navy {
        color: #fff;
        background-color: #313552;
        padding: 0 0.2rem;
    }

    .image-small {
        display: flex;
        justify-content: center;
        width: 100%;
        p {
            margin-bottom: 0;
        }
        img {
            width: 12.5rem;
            height: 150px;
        }
    }

    .image-medium {
        display: flex;
        justify-content: center;
        width: 100%;
        p {
            margin-bottom: 0;
        }
        img {
            width: 300px;
            height: 200px;
        }
    }

    .image-large {
        width: 100%;
        p {
            margin-bottom: 0;
        }
        img {
            width: 100%;
            height: 230px;
            object-fit: fill;
        }
    }

    .embed-responsive {
        .embed-responsive-item {
            width: 100% !important;
            height: 230px;
        }
    }

    .layout-one {
        border: 1px solid black;
    }
    .row {
        display: flex;
        border: 1px solid black;
        align-items: center;
        width: 100%;
    }
    .title {
        font-size: 16px;
        width: 20%;
        text-align: center;
        padding: 0.2rem 0;
        border-right: 1px solid black;
    }
    .subtitle {
        font-size: 16px;
        text-align: center;
        width: 80%;
    }
    .sound {
        text-align: center;
        padding: 0.3rem 0;
    }

    .layout-two {
    }
    .container {
        display: flex;
        margin-bottom: 1rem;
        width: 100%;
    }
    .image-small {
        width: 50%;
    }
    .text-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        text-align: center;
    }
    .text {
        width: 50%;
    }
    .header {
    }
    .body {
        min-height: 30px;
    }
    .image-small {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    p {
        margin-bottom: 0;
    }
    img {
        width: 12.5rem;
        height: 150px;
    }
    .bg-dark-cyan {
        background-color: #008b8b;
        color: #fff;
        padding: 0 0.2rem;
    }
    .bg-navy {
        color: #fff;
        background-color: #313552;
        padding: 0 0.2rem;
    }
    .bg-green {
        color: #fff;
        background-color: #2eb086;
        padding: 0 0.2rem;
    }
    .bg-black {
        color: #fff;
        background-color: #252a34;
        padding: 0 0.2rem;
    }
    p {
        margin: 0;
    }
`;

export default MarkdownPreviewWrapper;
