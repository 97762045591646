import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Modal, message, Space } from 'antd';
import { updatePostJobEmployee } from '../utils/api/post_job_employee'
import moment from 'moment';
import {
    LodoshaInput,
    LodoshaButton,
    LodoshaDatePicker
} from './UI/ui.style';


const ModalHistoryDate = ({ visible, setVisible, data, type, onRefresh }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const dateFormatToServer = 'DD-MM-YYYY';
    const dateFormatFromServer = 'YYYY-MM-DD';

    useEffect(() => {
        form.setFieldsValue({
            pass_interview_date: data && data.pass_interview_date ? moment(data.pass_interview_date, dateFormatFromServer) : null,
            paid_date: data && data.paid_date ? moment(data.paid_date, dateFormatFromServer) : null,
        });
    }, [form, data]);

    const handleCancel = () => {
        setVisible(false);
    };

    const handleSubmit = async (fieldsValue) => {

        const values = {
            ...fieldsValue,
            pass_interview_date: fieldsValue['pass_interview_date'] ? fieldsValue['pass_interview_date'].format(dateFormatToServer) : null,
            paid_date: fieldsValue['paid_date'] ? fieldsValue['paid_date'].format(dateFormatToServer) : null,
        };

        // const config = { headers: { 'content-type': 'multipart/form-data' } };
        // const formData = new FormData();
        // for (const key in values) {
        //     if (!isNullOrUndefined(values[key])) {
        //         formData.append(key, values[key]);
        //     }
        // }
        setLoading(true);
        try {
            let response;
            
            switch(type) {
                case 'post-job':
                    response = await updatePostJobEmployee(data.id, values);
                    break;
                default:
                    break;
            }


            if (response?.errorCode === 0) {
                message.success(`Updated successfully!`);
                onRefresh();
                setLoading(false);
                setVisible(false);
            } else {
                console.log('response: ', response);
                setLoading(false);
                message.error(`Failed! ${response.message}`);
            }
        } catch (error) {
            console.log('error: ',error);
            setLoading(false);
            message.error(`Uh oh, request failed!`);
        }
    };

    const items = [
        { label: 'Pass Interview Date', type: 'date', name: 'pass_interview_date' },
        { label: 'Paid Date', type: 'date', name: 'paid_date' },
    ];

    return (
        <Modal
            visible={visible}
            maskClosable={false}
            title={'Update'}
            onCancel={handleCancel}
            width={400}
            footer={null}
            getContainer={false}
        >
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Row gutter={24}>
                    {items.map((item) => (
                        <Col key={item.name} span={item.span ? item.span : 24}>
                            <Form.Item
                                name={item.name}
                                label={item.label + ':'}
                                rules={[{ required: item.required, message: 'Data required!' }]}
                                valuePropName={item.type === 'switch' ? 'checked' : 'value'}
                            >
                                { item.type === 'date' ? (
                                    <LodoshaDatePicker />
                                ) : (
                                    <LodoshaInput type={item.type} placeholder={'Enter ' + item.label.toLowerCase()} />
                                )}
                            </Form.Item>
                        </Col>
                    ))}
                    <Col span={24}>
                        <Form.Item style={{ textAlign: 'center', marginTop: 16 }}>
                            <Space direction="horizontal" size="middle">
                                <LodoshaButton key="cancel" htmlType="button" onClick={handleCancel}>
                                    Cancel
                                </LodoshaButton>
                                <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                                    {'Update'}
                                </LodoshaButton>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ModalHistoryDate;
