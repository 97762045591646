import React, { useCallback, useEffect, useMemo, useState } from 'react';
import queryString from 'query-string';

//antd
import { message, Table } from 'antd';
import { getTrackHistories } from '../../../utils/api/E-Learning/user_tracking';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';

export default function MobileAppHistory() {
    const [trackHistories, setTrackHistories] = useState([]);
    const [totalHistories, setTotalHistories] = useState(0);
    const [loading, setLoading] = useState(false);
    const params = useParams();
    const location = useLocation();
    const history = useHistory();

    const page = location.search
        ? queryString.parse(location.search).page
            ? Number(queryString.parse(location.search).page)
            : 1
        : 1;

    const columns = [
        {
            title: 'Date Time',
            dataIndex: 'time_in_ms',
            width: '25%',
            render: (text, row, index) => {
                return moment(row.open, 'x').format('dddd, MMMM Do YYYY');
            },
        },
        {
            title: 'Open',
            dataIndex: 'open',
            width: '15%',
            align: 'center',
            render: (text, row, index) => {
                return moment(row.open, 'x').format('h:mm:ss a');
            },
        },
        {
            title: 'Close',
            dataIndex: 'close',
            width: '15%',
            align: 'center',
            render: (text, row, index) => {
                return row.close ? moment(row.close, 'x').format('h:mm:ss a') : '';
            },
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            width: '15%',
            align: 'center',
            render: (text, row, index) => {
                console.log(row);
                return row.duration ? moment(row?.duration, 'x').format('mm:ss') : '';
            },
        },
    ];

    const dataSource = useMemo(
        () =>
            trackHistories.length > 0 &&
            trackHistories.map((h) => ({
                ...h,
                time_in_ms: h.open,
                close: h.close,
                open: h.open,
                duration: h.duration,
            })),
        [trackHistories]
    );

    console.log(dataSource);

    const handleGetTrackHistories = useCallback(
        async (page) => {
            setLoading(true);
            try {
                const response = await getTrackHistories(params.id ? params.id : '', page);
                if (response && response.errorCode !== 0) {
                    setLoading(false);
                    message.error(`Could not get tracking histories ${response.message}`);
                    return;
                }
                setTrackHistories(response?.data ? response.data : []);
                setTotalHistories(response?.total);
                setLoading(false);
            } catch (error) {
                message.error(`Could not get tracking histories`);
                setLoading(false);
            }
        },
        [params.id]
    );

    const handleChangePagination = (page) => {
        handleGetTrackHistories(page);
        history.push(`/candidates/detail/mobile-app-history/${params.id}?page=${page}`);
    };

    useEffect(() => {
        handleGetTrackHistories();
    }, [handleGetTrackHistories]);

    return (
        <Table
            columns={columns}
            bordered
            loading={loading}
            dataSource={dataSource}
            pagination={{
                onChange: handleChangePagination,
                current: page,
                defaultCurrent: 1,
                total: totalHistories,
                pageSize: 15,
            }}
        />
    );
}
