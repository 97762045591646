import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Row, Table, Space, Button, Modal, message } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Container, LodoshaButton } from '../../components/UI/ui.style';
import API from '../../utils/api';
import ModalUpsert from './ModalUpsert';
import * as ROUTES from '../../constants/routes';

const Jobs = () => {
    let history = useHistory();
    let location = useLocation();
    const page = location.search ? Number(queryString.parse(location.search).page) : 1;

    const [loading, setLoading] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [job, setJob] = useState();
    const [totalJobs, setTotalJobs] = useState(0);
    const [visibleUpsert, setVisibleUpsert] = useState(false);

    const handleFetchJobs = useCallback(async (page = 1) => {
        setLoading(true);
        const endpoint = page === 1 ? `/job` : `/job?offset=${(page - 1) * 15}`;
        const response = await API().get(endpoint);
        setJobs(response.data.data);
        setTotalJobs(response.data.total);
        setLoading(false);
    }, []);

    useEffect(() => {
        handleFetchJobs(page);
    }, [page, handleFetchJobs]);

    const handleCreate = () => {
        setJob();
        setVisibleUpsert(true);
    };

    const handleEdit = (e, record) => {
        e.stopPropagation();
        setJob(record);
        setVisibleUpsert(true);
    };

    const handleDelete = (e, record) => {
        e.stopPropagation();
        Modal.confirm({
            title: `Are you sure delete ${record.name}?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const response = await API().delete(`/job/${record.id}`);

                if (response.data.errorCode === 0) {
                    message.success(`Deleted successfully!`);
                    handleFetchJobs();
                } else {
                    message.error(response.data.message);
                }
            },
            onCancel() {},
        });
    };

    const handleChangePagination = (page) => {
        history.replace(`${ROUTES.JOBS}${page !== 1 ? `?page=${page}` : ''}`);
    };

    const dataSource = useMemo(
        () => jobs.map((job, index) => ({ ...job, key: job.id, no: index + 1 + (page - 1) * 15 })),
        [jobs, page]
    );

    const columns = [
        { title: '#', dataIndex: 'no', key: 'no', width: 50 },
        { title: 'JOB TITLE', dataIndex: 'name', key: 'job-title' },
        { title: 'JOB TITLE IN JAPANESE', dataIndex: 'name_jp', key: 'japanese-job-title' },
        {
            title: '',
            key: 'actions',
            render: (record) => (
                <Space direction="horizontal" size="small">
                    <Button
                        type="primary"
                        className="action-button-margin"
                        icon={<EditOutlined />}
                        onClick={(e) => handleEdit(e, record)}
                    />
                    <Button
                        type="danger"
                        className="action-button-margin"
                        icon={<DeleteOutlined />}
                        onClick={(e) => handleDelete(e, record)}
                    />
                </Space>
            ),
        },
    ];

    const Upsert = useCallback(
        () => (
            <ModalUpsert
                visible={visibleUpsert}
                setVisible={setVisibleUpsert}
                job={job}
                setJob={setJob}
                onFetchJobs={handleFetchJobs}
            />
        ),
        [visibleUpsert, job, handleFetchJobs]
    );

    return (
        <Container>
            <Row style={{ marginBottom: 32 }}>
                <LodoshaButton type="primary" icon={<PlusOutlined />} onClick={handleCreate}>
                    New
                </LodoshaButton>
                <Upsert />
            </Row>
            <Table
                bordered
                loading={loading}
                dataSource={dataSource}
                columns={columns}
                pagination={{
                    onChange: handleChangePagination,
                    current: page,
                    defaultCurrent: 1,
                    defaultPageSize: 15,
                    total: totalJobs,
                }}
            />
        </Container>
    );
};

export default Jobs;
