import React from 'react';

//components
import AdvanceSearchCmp from './AdvanceSearchCmp';

export default function AdvanceSearch() {
    return (
        <React.Fragment>
            <AdvanceSearchCmp />
        </React.Fragment>
    );
}
