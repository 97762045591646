import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

import API from '../utils/api';

import { getStaffsWithoutBOD } from '../utils/api/staff';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
    const history = useHistory();

    const [dataJobs, setDataJobs] = useState([]);
    const [dataLocations, setDataLocations] = useState([]);
    const [dataProvinces, setDataProvinces] = useState([]);
    const [dataStaffs, setDataStaffs] = useState([]);
    const [dataFollowUp, setDataFollowUp] = useState([]);

    const handleUnauthorizedUser = () => {
        Cookies.remove('backoffice_token');
        Cookies.remove('middleware_token');
        localStorage.removeItem('lodosha_user_name');
        localStorage.removeItem('lodosha_position');
        window.location.reload();
        history.push('/login');
    };

    useEffect(() => {
        const handleFetchData = async () => {
            const responseJobs = await API().get(`/job/all`);
            const jobs = responseJobs?.data?.data?.map((data) => ({
                value: data.id,
                text: data.name,
            }));
            responseJobs?.data?.data && setDataJobs(jobs);

            const responseLocations = await API().get(`/location?offset=0&limit=50`);
            const locations = responseLocations.data?.data?.map((data) => ({
                value: data.id,
                text: data.name_en,
            }));
            responseLocations?.data?.data && setDataLocations(locations);

            const responseProvinces = await API().get(`/location?country=855`);
            const provinces = responseProvinces.data?.data?.map((data) => ({
                ...data,
                value: data.id,
                text: data.name_en,
            }));
            responseProvinces.data?.data && setDataProvinces(provinces);

            const responseStaffs = await getStaffsWithoutBOD();
            const staffs = responseStaffs?.data?.map((data) => ({
                value: data.user_id,
                text: data.name,
            }));
            responseStaffs?.data && setDataStaffs(staffs);

            const responseFollowUp = await API().get(`/employee-history/follow-up`);
            responseFollowUp?.data?.data && setDataFollowUp(responseFollowUp?.data?.data);
        };

        handleFetchData();
    }, []);

    return (
        <AppContext.Provider
            value={{ dataJobs, dataLocations, dataProvinces, dataStaffs, dataFollowUp, handleUnauthorizedUser }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppContext;

export { AppProvider };
