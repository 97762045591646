
import API from '../api';

export const getLanguage = (country) => {
    const url = `/language/name?country=${country}`
    return API().get(url)
            .then(response => {
                return response.data;
            })
            .catch(err => { 
                console.log('err: ', err) 
            });
};