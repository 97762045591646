import React, { useState } from 'react';

//antd
import { Modal, Form, Row, Col, message, Input } from 'antd';

//components
import { LodoshaButton } from '../../../../components/UI/ui.style';
import { postNewSentence, updateSentence } from '../../../../utils/api/E-Learning/sentence';

export default function SentenceModal({
    postData,
    setVisible,
    setPostData,
    isCreate,
    isUpdate,
    visible,
    onRefresh,
    title,
}) {
    const [form] = Form.useForm();
    const [data, setData] = useState(postData);
    const [loading, setLoading] = useState(false);

    const { TextArea } = Input;

    const sentenceFormFields = [
        { span: 12, type: 'text', value: data?.japan, name: 'japan', label: 'Japanese ', required: true },
        { span: 12, type: 'text', value: data?.khmer, name: 'khmer', label: 'Khmer ', required: true },
        {
            span: 24,
            type: 'text',
            value: data?.explanation,
            name: 'explanation',
            label: 'Explanation ',
            required: false,
        },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({ ...prev, [name]: value }));
    };

    const handleCancel = () => {
        setPostData({});
        setVisible(false);
    };

    const handleSubmit = async (value) => {
        setLoading(true);
        try {
            const response = isCreate
                ? await postNewSentence(data, [])
                : isUpdate
                ? await updateSentence(data, [])
                : {};
            if (response && response.errorCode !== 0) {
                setLoading(false);
                message.error(`Uh oh, something went wrong, ${response.message}`);
                return;
            }

            if (response) {
                response &&
                    message.success(isCreate ? 'New sentence added.' : isUpdate ? 'Successfully updated sentence' : '');
                setLoading(false);
                onRefresh();
                handleCancel();
            } else {
                setLoading(false);
                message.error(`Uh oh, ${isCreate ? 'add new sentence' : isUpdate ? 'update sentence' : ''} failed!`);
            }
        } catch (error) {
            setLoading(false);
            message.error(`Uh oh, ${isCreate ? 'add new sentence' : isUpdate ? 'update sentence' : ''} failed!`);
        }
    };

    return (
        <Modal onCancel={handleCancel} visible={visible} title={title} footer={null} width={768}>
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Row gutter={24}>
                    {sentenceFormFields.map((field, index) => {
                        const { span, type, value, name, required, label, disabled } = field;
                        return (
                            <Col span={span} key={index}>
                                <Form.Item
                                    rules={[{ required: required, message: 'Data required!' }]}
                                    label={`${label}:`}
                                    name={name}
                                    initialValue={value}
                                >
                                    <TextArea
                                        type={type}
                                        disabled={disabled && true}
                                        onChange={handleChange}
                                        value={value}
                                        name={name}
                                    />
                                </Form.Item>
                            </Col>
                        );
                    })}
                    <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
                        <LodoshaButton
                            key="cancel"
                            style={{ marginRight: 16 }}
                            htmlType="button"
                            onClick={handleCancel}
                        >
                            Cancel
                        </LodoshaButton>
                        <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                            {isCreate ? 'Add New' : isUpdate ? 'Update' : ''}
                        </LodoshaButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
