
import API from '../api';

export const getPartnerProducts = (partnerId) => {
    const url = `/partner-product?partner_id=${partnerId}`;
    return API().get(url)
            .then(response => {
                return response.data;
            })
            .catch(err => { 
                console.log('err: ', err) 
            });
};

export const createPartnerProduct = (data) => {
    return API().post(`/partner-product/create`, data)
            .then(response => {
                return response.data;
            }).catch(err => {
                console.log('err: ', err) 
            })
}

export const updatePartnerProduct = (data, id) => {
    return API().patch(`/partner-product/${id}`, data)
            .then(response => {
                return response.data;
            }).catch(err => {
                console.log('err: ', err) 
            })
}

export const deletePartnerProduct = (id) => {
    return API().delete(`/partner-product/${id}`)
            .then(response => {
                return response.data;
            }).catch(err => {
                console.log('err: ', err) 
            })
}

export const getPartnerProduct = (id) => {
    const url = `/partner-product/detail/${id}`;
    return API().get(url)
            .then(response => {
                return response.data;
            })
            .catch(err => { 
                console.log('err: ', err) 
            });
};