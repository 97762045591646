import React, { useState } from 'react';

//antd
import { Upload, Avatar } from 'antd';

//utils
import { loadImageMiddleware } from '../../../utils';

export default function BannerUploader({ imageId = '', setFile }) {
    const [imageUrl, setImageUrl] = useState('');

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleUploadPhoto = async (info) => {
        if (info.fileList.length > 0) {
            getBase64(info.fileList[info.fileList.length - 1].originFileObj, (url) => {
                setImageUrl(url);
                setFile(info.fileList[info.fileList.length - 1].originFileObj);
            });
        }
    };

    const beforeUpload = () => {
        return false;
    };

    return (
        <Upload
            name="photo"
            className="avatar-uploader"
            showUploadList={false}
            onChange={handleUploadPhoto}
            beforeUpload={beforeUpload}
        >
            <Avatar
                shape="square"
                size={128}
                src={imageUrl ? imageUrl : imageId ? loadImageMiddleware(imageId) : ''}
                style={{ border: '1px solid #e8e8e8', cursor: 'pointer' }}
                alt="Image"
            />
        </Upload>
    );
}
