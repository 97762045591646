
import API from '../api';

export const getPostJob = (postJobId) => {
    const url = `/postjob/detail/${postJobId}`;
    return API().get(url)
            .then(response => {
                return response.data;
            })
            .catch(err => { 
                console.log('err: ', err) 
            });
};

export const getPostJobs = (page = 1, keyWord = '', jobId = '', order = '', field = '') => {
    const url = `/postjob/search?keyword=${keyWord}&job_id=${jobId}&order=${order}&field=${field}&offset=${(page - 1) * 15}`;
    return API().get(url)
            .then(response => {
                return response.data;
            })
            .catch(err => { 
                console.log('err: ', err) 
            });
};