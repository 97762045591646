import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';

//antd
import { Spin, Descriptions, Row, Col, message } from 'antd';

//api
import { getQuestionDetail } from '../../../utils/api/E-Learning/question';

//component
import { Container, LodoshaButton } from '../../../components/UI/ui.style';
import QuestionModal from './QuestionModal';
import ReactAudioPlayer from 'react-audio-player';

export default function QuestionDetail() {
    const [loading, setLoading] = useState(false);
    const [questionDetail, setQuestionDetail] = useState({});
    const [updateVisible, setUpdateVisible] = useState(false);
    const [postData, setPostData] = useState([]);
    const isSubscribed = useRef(true);

    const { questionId } = useParams();

    const handleFetchQuestionDetail = useCallback(async () => {
        try {
            const response = await getQuestionDetail(questionId);
            if (response && response.errorCode !== 0) {
                setLoading(false);
                message.error(`Could not get culture detail , ${response.message}`);
                return;
            }
            setQuestionDetail(response ? response?.data : {});
            setLoading(false);
        } catch (error) {
            message.error(`Something went wrong.${error}`);
        }
    }, [questionId]);

    const Update = useCallback(
        () =>
            updateVisible && (
                <QuestionModal
                    isUpdate
                    title="UPDATE CULTURE DETAIL"
                    onRefresh={handleFetchQuestionDetail}
                    setPostData={setPostData}
                    postData={postData}
                    visible={updateVisible}
                    setVisible={setUpdateVisible}
                />
            ),
        [updateVisible, postData, handleFetchQuestionDetail]
    );

    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchQuestionDetail();
        }
        return () => {
            isSubscribed.current = false;
        };
    }, [handleFetchQuestionDetail]);

    useEffect(() => {
        setPostData(questionDetail);
    }, [questionDetail, updateVisible]);

    const { title, answer, choice_one, choice_two, choice_three, choice_four, sound, image } = questionDetail;

    return (
        <Container>
            <Spin spinning={loading}>
                <Descriptions bordered column={1} title="Detail">
                    <Descriptions.Item label="Title">{title}</Descriptions.Item>
                    <Descriptions.Item label="Choice One">{choice_one}</Descriptions.Item>
                    <Descriptions.Item label="Choice Two">{choice_two}</Descriptions.Item>
                    <Descriptions.Item label="Choice Three">{choice_three}</Descriptions.Item>
                    <Descriptions.Item label="Choice Four">{choice_four}</Descriptions.Item>
                    <Descriptions.Item label="Answer">{answer}</Descriptions.Item>
                    <Descriptions.Item label="Question Image">
                        {image ? (
                            <img
                                src={image}
                                alt="question"
                                style={{ width: '100px', height: '100px', objectFit: 'contain' }}
                            />
                        ) : (
                            'No image yet. Please upload one'
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Question Sound">
                        {sound ? <ReactAudioPlayer controls src={sound} /> : 'No sound yet. Please upload one'}
                    </Descriptions.Item>
                </Descriptions>
                <Row
                    style={{
                        position: 'sticky',
                        bottom: '0',
                        marginTop: '0px',
                        padding: '20px 0',
                        background: '#FAFAFA',
                    }}
                >
                    <Update />
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <LodoshaButton
                            type="primary"
                            style={{ marginRight: 16 }}
                            onClick={() => setUpdateVisible(true)}
                        >
                            Update
                        </LodoshaButton>
                    </Col>
                </Row>
            </Spin>
        </Container>
    );
}
