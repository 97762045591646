import API from '../api';

export const fetchRatings = (topic_id) => {
    return API()
        .get(`rating/by-topic/${topic_id}`)
        .then((respone) => {
            return respone.data;
        })
        .catch((err) => {
            console.log('get rating Fail', err);
            return err;
        });
};

export const updateReview = (data) => {
    return API()
        .patch(`/review`, data)
        .then((respone) => {
            return respone.data;
        })
        .catch((err) => {
            console.log('Update fail', err);
        });
};

export const createReview = (data) => {
    return API()
        .post(`/review`, data)
        .then((respone) => {
            return respone.data;
        })
        .catch((err) =>{
            console.log('Create fail', err)
        })
};

export const createStaffRating = (info, employeeId) =>{
    return API()
        .post( `/rating-employee/${employeeId}`, info )
        .then((respone) =>{ return respone.data })
        .catch(( err ) =>{console.log('ohhhhh your update fail !', err)})
}

export const fetchStaffRating = (employeeId) =>{
    return API()
        .get(`/rating-employee/?employeeId=${employeeId}`)
        .then((respone) =>{
            return respone.data;
        })
        .catch((err) =>{
            console.log('fetching data fail !',err)
        })
}

export const updateStaffRating = (employeeId, info) =>{
    return API()
        .patch(`/rating-employee/${employeeId}`, info)
        .then((respone) =>{return respone.data })
        .catch((err) =>{ console.log('ohhhhh your update fail !', err)})
}
