import React, { useState, useMemo } from 'react';
import { LodoshaInput, LodoshaButton } from '../../../../components/UI/ui.style';

//api
import { postNewCultureDetail, updateCultureDetail } from '../../../../utils/api/E-Learning/culture';

//ant design
import { Modal, Row, Col, Form, message } from 'antd';

//components
import MarkdownGenerator from '../../../../components/MarkdownGenerator';

export default function QuestionAndAnswerModal({
    title,
    visible,
    setVisible,
    onRefresh,
    setPostData,
    postData,
    isUpdate,
    isCreate,
}) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(postData);

    const cultureFormFields = useMemo(
        () => [
            {
                label: 'Title',
                type: 'text',
                name: 'title',
                value: data?.title,
                span: 24,
                required: true,
            },
            {
                label: 'Choice One',
                type: 'text',
                name: 'choice_one',
                value: data?.choice_one,
                span: 12,
                required: true,
                isSelect: true,
            },
            {
                label: 'Choice Two',
                type: 'text',
                name: 'choice_two',
                value: data?.choice_two,
                span: 12,
                required: true,
            },
            {
                label: 'Choice Three',
                type: 'text',
                name: 'choice_three',
                value: data?.choice_three,
                span: 12,
                required: true,
            },
            {
                //choice four is optional
                label: 'Choice Four',
                type: 'text',
                name: 'choice_four',
                value: data?.choice_four,
                span: 12,
            },
            {
                label: 'Answer',
                type: 'text',
                name: 'answer',
                value: data?.answer,
                span: 24,
                required: true,
            },
        ],
        [data]
    );

    //close modal
    const handleCancel = (e) => {
        setVisible(false);
        setPostData([]);
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        setData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            for (const key in data) {
                formData.append(key, data[key]);
            }
            setLoading(true);
            const response = isCreate
                ? await postNewCultureDetail(formData)
                : isUpdate
                ? await updateCultureDetail(data.id, formData)
                : {};

            if (response && response.errorCode !== 0) {
                setLoading(false);
                message.error(`Oh oh, something went wrong.`, response.message);
                return;
            }
            if (response) {
                response &&
                    message.success(isCreate ? 'New Q&A added.' : isUpdate ? 'Successfully updated culture Q&A' : '');
                setLoading(false);
                onRefresh();
                handleCancel();
            } else {
                setLoading(false);
                message.error(
                    `Uh oh, ${isCreate ? 'add new culture Q&A' : isUpdate ? 'updating culture Q&A' : ''} failed!`
                );
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            message.error(
                `Uh oh, ${isCreate ? 'add new culture Q&A' : isUpdate ? 'updating culture Q&A' : ''} failed!`
            );
        }
    };

    return (
        <Modal width={768} title={title} visible={visible} onCancel={handleCancel} maskClosable={false} footer={null}>
            <Form layout="vertical" form={form} onFinish={() => handleSubmit()}>
                <Row gutter={24}>
                    {cultureFormFields?.map((field, index) => {
                        const { type, label, name, value, required, disabled, span } = field;
                        return (
                            <Col span={span} key={index}>
                                <Form.Item
                                    rules={[{ required: required, message: 'Data required!' }]}
                                    label={`${label}:`}
                                    name={name}
                                    initialValue={value}
                                >
                                    <LodoshaInput
                                        type={type}
                                        disabled={disabled && true}
                                        onChange={handleChange}
                                        value={value}
                                        name={name}
                                    />
                                </Form.Item>
                            </Col>
                        );
                    })}
                    <Col span={24}>
                        <MarkdownGenerator data={data} setData={setData} isUpdate={isUpdate} />
                    </Col>

                    <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
                        <LodoshaButton
                            key="cancel"
                            style={{ marginRight: 16 }}
                            htmlType="button"
                            onClick={handleCancel}
                        >
                            Cancel
                        </LodoshaButton>
                        <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                            {isCreate ? 'Add New' : isUpdate ? 'Update' : ''}
                        </LodoshaButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
