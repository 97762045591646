import React from "react";
import { Row, Col, Form, Table, Modal, Select, InputNumber, Button, Popconfirm, message } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { LodoshaButton, LodoshaInput, LodoshaSelect } from "../../components/UI/ui.style";
import API from "../../utils/api";
import { dataRelative } from "../../utils/data";

const { Option } = Select;

const Relative = ({ visible, setVisible, employee = {} }) => {
  const [form] = Form.useForm();
  const [relatives, setRelatives] = React.useState([]);
  const [relative, setRelative] = React.useState();

  const handleFetchRelatives = React.useCallback(async (id) => {
    try {
      const response = await API().get(`/employee/relative?employee_id=${id}`);
      // console.log(response);
      if (response.data && response.data.data) {
        setRelatives(response.data.data);
      }
    } catch (error) {
      message.error(`Uh oh, request relative failed!`);
    }
  }, []);

  React.useEffect(() => {
    employee?.id && visible && handleFetchRelatives(employee.id);
  }, [employee, handleFetchRelatives, visible]);

  const handleEdit = (item) => {
    setRelative(item);
    form.setFieldsValue({
      name: item.name,
      relation: item.relation,
      age: item.age,
      phone: item.phone,
    });
  };

  const handleDeleteRelative = async (id) => {
    form.resetFields();
    try {
      const response = await API().delete(`/employee/relative/${id}`);
      if (response && response.data.errorCode === 0) {
        handleFetchRelatives(employee.id);
        message.success(`Deleted successfully!`);
      } else {
        message.error(`Failed! ${response.data.message}`);
      }
    } catch (error) {
      message.error(`Uh oh, request failed!`);
    }
  };

  const handleReset = () => {
    form.resetFields();
    setRelative();
  };

  const handleSubmit = async (values) => {
    // console.log("relative", values);
    try {
      let response;
      if (relative && relative.id) {
        response = await API().patch(`/employee/relative/${relative.id}`, values);

        if (response.data.errorCode === 0) {
          handleFetchRelatives(employee.id);
          form.resetFields();
          message.success(`Updated successfuly!`);
        }
      } else {
        response = await API().post(`/employee/relative/create`, { ...values, employee_id: employee.id });
        if (response.data.errorCode === 0) {
          setRelatives([...relatives, response.data.data]);
          form.resetFields();
          message.success(`Created successfuly!`);
        }
      }

      response && response.data.errorCode !== 0 && message.error(`Failed! ${response.data.message}`);
      setRelative({});
    } catch (error) {
      message.error(`Uh oh, request failed!`);
    }
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "Relation",
      dataIndex: "relation",
      key: "relation",
      render: (item) => <span>{dataRelative.find((relative) => relative.value === item).text}</span>,
    },
    { title: "Age", dataIndex: "age", key: "age" },
    { title: "Phone", dataIndex: "phone", key: "phone" },
    {
      title: "",
      key: "actions",
      render: (item) => (
        <span>
          <Button
            type="primary"
            icon={<EditOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => handleEdit(item)}
          ></Button>
          <Popconfirm
            title="Are you sure？"
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleDeleteRelative(item.id)}
          >
            <Button type="danger" htmlType="button" icon={<DeleteOutlined />} />
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <Modal
      width={768}
      maskClosable={false}
      visible={visible}
      title="Relative"
      cancelText="Cancel"
      onCancel={() => setVisible(false)}
      footer={[
        <LodoshaButton key="back" onClick={() => setVisible(false)}>
          Done
        </LodoshaButton>,
      ]}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="name" label="Name" rules={[{ required: true, message: "Data required!" }]}>
              <LodoshaInput size="large" placeholder="Enter your relative name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="relation" label="Relation" rules={[{ required: true, message: "Data required!" }]}>
              <LodoshaSelect size="large" placeholder="Select an option">
                {dataRelative.map(({ value, text }) => (
                  <Option key={value} value={value}>
                    {text}
                  </Option>
                ))}
              </LodoshaSelect>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="age" label="Age">
              <InputNumber
                size="large"
                style={{ width: "100%" }}
                min={1}
                max={100}
                placeholder="Enter your relative age"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="phone" label="Phone" rules={[{ required: true, message: "Data required!" }]}>
              <LodoshaInput size="large" placeholder="Enter your relative phone" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ textAlign: "center", marginTop: 16 }}>
          <LodoshaButton style={{ marginRight: 8, height: 38, width: 108 }} htmlType="button" onClick={handleReset}>
            Cancel
          </LodoshaButton>
          <LodoshaButton type="primary" htmlType="submit" style={{ height: 38, width: 108 }}>
            {relative?.id ? "Update" : "Create"}
          </LodoshaButton>
        </Form.Item>
      </Form>
      <Table rowKey={(dataSource) => dataSource.id} columns={columns} dataSource={relatives} pagination={false} />
    </Modal>
  );
};

export default Relative;
