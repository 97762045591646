import React, { useState, useMemo, useEffect, useContext } from 'react';

//api
import { postNewLesson, updateLesson } from '../../../utils/api/E-Learning/japanese';

//ant design
import { Modal, Row, Col, Form, message, Select } from 'antd';
import { lessonTypeSubTypeList } from '../../../utils/data';

//components
import { LodoshaInput, LodoshaButton } from '../../../components/UI/ui.style';
import BannerUploader from '../E-LearningComponents/BannerUploader';

import AppContext from '../../../contexts/AppContext';

export default function LessonModal({
    title,
    visible,
    setVisible,
    onRefresh,
    setPostData,
    postData,
    isUpdate,
    isCreate,
}) {
    const { handleUnauthorizedUser } = useContext(AppContext);

    const [form] = Form.useForm();
    const { Option } = Select;
    const [loading, setLoading] = useState(false);
    const [subTypes, setSubTypes] = useState([]);
    const [data, setData] = useState(postData);
    const [file, setFile] = useState();

    const lessonFormFields = useMemo(
        () => [
            {
                type: 'text',
                label: 'Name',
                name: 'name',
                value: data?.name,
                span: 12,
                required: true,
            },
            {
                type: 'text',
                label: 'Name JP',
                name: 'name_jp',
                value: data?.name_jp,
                span: 12,
                required: false,
            },
            {
                type: 'number',
                label: 'Type',
                name: 'type',
                //auto select type when create new lesson
                value: data?.type,
                span: 12,
                required: true,
                disabled: true,
                isSelect: true,
                optionList: lessonTypeSubTypeList,
            },
            {
                type: 'text',
                label: 'Sub-Type',
                name: 'sub_type',
                value: data?.sub_type,
                span: 12,
                required: false,
                isSelect: true,
                //sub_type is based on type
                optionList: subTypes,
            },
            {
                type: 'text',
                label: 'Youtube Key',
                name: 'youtube_key',
                value: data?.youtube_key,
                span: 12,
                required: false,
            },
        ],
        [data, subTypes]
    );

    const handleCancel = (e) => {
        setVisible(false);
        setPostData({});
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        setData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleChangeSubTypeOption = (value) => {
        setData((prev) => ({ ...prev, sub_type: value }));
    };

    const handleSubmit = async (values) => {
        try {
            const formData = new FormData();
            for (const key in data) {
                if (!(data[key] === null || data[key] === 'null')) {
                    formData.append(key, data[key]);
                }
            }
            formData.append('lesson-banner', file);
            
            setLoading(true);

            const response = isCreate
                ? await postNewLesson(formData)
                : isUpdate
                ? await updateLesson(data.id, formData)
                : {};
            if (response && response.errorCode !== 0) {
                if (response.data.status === 401) {
                    message.error(`Unauthorized`);
                    handleUnauthorizedUser();
                    return;
                }
                setLoading(false);
                message.error(`Oh oh, something went wrong. ${response.message}`);
                return;
            }

            if (response) {
                message.success(isCreate ? 'New lesson added.' : isUpdate ? 'Successfully updated lesson' : '');
                setLoading(false);
                onRefresh();
                handleCancel();
            } else {
                message.error(`Uh oh, ${isCreate ? 'add new lesson' : isUpdate ? 'updated lesson' : ''} failed!`);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            message.error(`Uh oh, ${isCreate ? 'add new lesson' : isUpdate ? 'updated lesson' : ''} failed!`);
        }
    };

    // get subtype based on type
    useEffect(() => {
        const getSubType = () => {
            const subTypes = lessonTypeSubTypeList.find((type) => type.value === data?.type)?.subTypes;
            setSubTypes(subTypes);
        };
        getSubType();
    }, [data?.type]);

    return (
        <Modal
            width={768}
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
                    <div>{title}</div>
                    <BannerUploader imageId={data && data.image_id} setFile={setFile} />
                </div>
            }
            visible={visible}
            onCancel={handleCancel}
            maskClosable={false}
            footer={null}
        >
            <Form layout="vertical" form={form} onFinish={() => handleSubmit()}>
                <Row gutter={24}>
                    {lessonFormFields?.map((field, index) => {
                        const { type, label, name, value, required, span, isSelect, disabled, optionList } = field;
                        return (
                            <Col span={span} key={index}>
                                <Form.Item
                                    rules={[{ required: required, message: 'Data required!' }]}
                                    label={`${label}:`}
                                    name={name}
                                    initialValue={value}
                                >
                                    {isSelect ? (
                                        <Select
                                            placeholder="Please select: "
                                            onChange={handleChangeSubTypeOption}
                                            name={name}
                                            size="large"
                                            disabled={disabled}
                                        >
                                            {optionList?.map((type, index) => (
                                                <Option key={index} value={type.value}>
                                                    {type.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    ) : (
                                        <LodoshaInput
                                            type={type}
                                            disabled={disabled && true}
                                            onChange={handleChange}
                                            value={value}
                                            name={name}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        );
                    })}
                    <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
                        <LodoshaButton
                            key="cancel"
                            style={{ marginRight: 16 }}
                            htmlType="button"
                            onClick={handleCancel}
                        >
                            Cancel
                        </LodoshaButton>
                        <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                            {isCreate ? 'Add New' : isUpdate ? 'Update' : ''}
                        </LodoshaButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
