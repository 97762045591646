import React, { useEffect } from 'react';
import { Form, Row, Col, Button } from 'antd';
import { getComponents, getTextArea } from '../../components/Components';
import { dataJobTypes, dataJobLevels } from '../../utils/data';
import Cookies from 'js-cookie';
import { isUndefined } from 'util';
import moment from 'moment';

const Info = ({ form, career, setCareer, handleChange, handleSubmit, handleSelectChange, fileList, handleUpload }) => {
    useEffect(() => {
        if (window.location.pathname.includes('update')) {
            if (isUndefined(career.id)) {
                setCareer(JSON.parse(Cookies.get('career_info')));
            } else {
                Cookies.set('career_info', JSON.stringify(career));
            }
        }
        return () => {
            Cookies.remove('career_info');
        };
    }, [career, setCareer]);

    const postDate = career.post_date ? moment(career.post_date, 'DD-MM-YYYY') : null;
    const expirationDate = career.expiration_date ? moment(career.expiration_date, 'DD-MM-YYYY') : null;

    const items = [
        { type: 'input', label: 'Title', name: 'job_title', value: career && career.job_title, require: true },
        { type: 'input_number', label: 'Hiring', name: 'hiring', value: career && career.hiring, require: true },
        { type: 'date_picker', label: 'Post Date', name: 'post_date', value: postDate, require: true },
        {
            type: 'date_picker',
            label: 'Expiration Date',
            name: 'expiration_date',
            value: expirationDate,
            require: true,
        },
        { type: 'select', label: 'Type', name: 'job_type', value: career && career.job_type, options: dataJobTypes },
        {
            type: 'select',
            label: 'Level',
            name: 'job_level',
            value: career && career.job_level,
            options: dataJobLevels,
        },
    ];

    const twoCols = [
        { type: 'input_number', label: 'Salary From', name: 'salary_from', value: career && career.salary_from },
        { type: 'input_number', label: 'Salary To', name: 'salary_to', value: career && career.salary_to },
    ];

    const textAreas = [
        {
            type: 'textArea',
            label: 'Job Description',
            name: 'job_description',
            value: career && career.job_description,
            require: true,
        },
        {
            type: 'textArea',
            label: 'Job Responsibility',
            name: 'job_resposibility',
            value: career && career.job_resposibility,
        },
        {
            type: 'textArea',
            label: 'Job Requirement',
            name: 'job_requirement',
            value: career && career.job_requirement,
        },
    ];

    return (
        <div className="container-career">
            <Form
                onSubmit={(event) => {
                    event.preventDefault();
                    form.validateFields((err, values) => {
                        if (!err) {
                            console.log('values: ', values);
                            handleSubmit(values);
                        }
                    });
                }}
            >
                <div>
                    <h3>Information</h3>
                    <Row gutter={24} type="flex" align="left">
                        {items.map((data) => (
                            <Col key={data.name} span={8}>
                                <Form.Item label={data.label} vertical>
                                    {data.require
                                        ? form.getFieldDecorator(data.name, {
                                              initialValue: data.value,
                                              rules: [
                                                  {
                                                      required: true,
                                                      message: 'Data Required!',
                                                  },
                                              ],
                                          })(getComponents(data, handleChange, handleSelectChange))
                                        : getComponents(data, handleChange, handleSelectChange)}
                                </Form.Item>
                            </Col>
                        ))}
                    </Row>
                    <Row gutter={24}>
                        {twoCols.map((data) => (
                            <Col key={data.name} span={12}>
                                <Form.Item label={data.label} vertical hasFeedback>
                                    {data.require
                                        ? form.getFieldDecorator(data.name, {
                                              initialValue: data.value,
                                              rules: [
                                                  {
                                                      required: true,
                                                      message: 'Data Required!',
                                                  },
                                              ],
                                          })(getComponents(data, handleChange, handleSelectChange))
                                        : getComponents(data, handleChange, handleSelectChange)}
                                </Form.Item>
                            </Col>
                        ))}
                    </Row>

                    <Row>
                        {textAreas.map((data) => (
                            <Col key={data.name}>
                                <Form.Item label={data.label}>
                                    {data.require
                                        ? form.getFieldDecorator(data.name, {
                                              initialValue: data.value,
                                              rules: [
                                                  {
                                                      required: true,
                                                      message: 'Data Required!',
                                                  },
                                              ],
                                          })(getTextArea(data, handleChange))
                                        : getTextArea(data, handleChange)}
                                </Form.Item>
                            </Col>
                        ))}
                    </Row>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Row>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="lodosha-button">
                                    {career.id ? 'Update' : 'Create'}
                                </Button>
                            </Form.Item>
                        </Row>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default Info;
