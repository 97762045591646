import { useState } from 'react';

const injectFbSDKScript = () => {
    (function (d, s, id) {
        var js,
            fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
            return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
};

const useFacebookLogin = () => {
    const [isFBInitialized, setIsFBInitialized] = useState(false);
    const initFb = () => {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: process.env.REACT_APP_FACEBOOK_APP_ID,
                cookie: true,
                xfbml: true,
                version: 'v8.0',
            });

            window.FB.AppEvents.logPageView();
            setIsFBInitialized(true);
        };
    };

    initFb();
    injectFbSDKScript();

    return { isFBInitialized, initFb };
};

export default useFacebookLogin;
