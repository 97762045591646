import styled from "@emotion/styled";

const LogInWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f0f2f5;
    .container {
        max-width: 384px;
        background: white;
        margin: 0 auto;
        padding: 50px;
        border: 1px solid #eee;
        border-radius: 10px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
        .logo {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .button {
            width: 100%;
        }
        .input {
            background-color: ${(props) => props.theme.dark};
        }
        .ant-input {
            background-color: ${(props) => props.theme.dark};
        }
    }
`;

export { LogInWrapper };
