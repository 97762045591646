import { createAPIELearning } from '../../api';

export const getNewsNotifcations = () => {
    return createAPIELearning()
        .get(`/admin-news-notification/all-notifications`)
        .then((response) => response.data)
        .catch((err) => err.response);
};

export const postNewNotification = (data) => {
    console.log(data);
    return createAPIELearning()
        .post(`/admin-news-notification/create`, data)
        .then((response) => response.data)
        .catch((err) => err.response);
};

export const updateNotification = (data) => {
    console.log(data);
    return createAPIELearning()
        .patch(`/admin-news-notification/${data.id}`, data)
        .then((response) => response.data)
        .catch((err) => err.response);
};

export const deleteNotification = (id) => {
    return createAPIELearning()
        .delete(`/admin-news-notification/${id}`)
        .then((response) => response.data)
        .catch((err) => err.response);
};

export const updateNotificationOrder = (info) => {
    return createAPIELearning()
        .patch(`/admin-news-notification/update-order`, info)
        .then((response) => response.data)
        .catch((err) => err.response);
};

export const pushNotifications = (info, userIds) => {
    return createAPIELearning()
        .post(`/admin-news-notification/push-notifications`, {
            title: info?.title,
            type: info?.type,
            body: info?.body,
            id: info?.id,
            userIds,
            attachment: info?.attachment,
            image: info?.image
        })
        .then((response) => response.data)
        .catch((err) => console.log(`pushNotifcations , ${err.message}`));
};
