import React, { useEffect, useState, useContext } from 'react';
import './sortabletable.css';

//antd
import { Table, message } from 'antd';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

//components
import { ELearningWrapper } from './e-learning.style';

import AppContext from '../../../contexts/AppContext';

export default function SortableTable({
    data,
    columns,
    handleRow,
    loading,
    setLoading,
    onUpdateOrder,
    isSectionType,
    scrollX,
    size,
}) {
    const { handleUnauthorizedUser } = useContext(AppContext);

    const [dataSource, setDataSource] = useState([]);

    const SortableItem = sortableElement((props) => <tr {...props} style={{ cursor: 'pointer' }} />);
    const SortableContainer = sortableContainer((props) => <tbody {...props} />);

    const handleSortOrder = async (info) => {
        setLoading(true);
        try {
            const response = await onUpdateOrder(info);
            if (response && response.errorCode !== 0) {
                if (response.data.status === 401) {
                    message.error(`Unauthorized`);
                    handleUnauthorizedUser();
                    return;
                }
                message.error(`Couldn't update order please try again.`, response.message);
                setLoading(false);
                return;
            }

            //change old order to new order
            dataSource.map((d) => {
                if (d.id === response.data.id) {
                    d.order = response.data.order;
                }
                return d;
            });

            //sort so table will display base on order //ASCENDING
            const sortedDataSource = dataSource.sort((a, b) => a.order - b.order);

            setDataSource(sortedDataSource);

            setLoading(false);
        } catch (error) {
            message.error(`Oh uh, could not update order,${error?.message}`);
            setLoading(false);
        }
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable([].concat(dataSource), oldIndex, newIndex).filter((el) => !!el);
            const prevIndex = newData[newIndex - 1];
            const movedData = newData[newIndex];
            const nextIndex = newData[newIndex + 1];

            setDataSource(newData);

            if (newIndex === 0) {
                //move to first
                handleSortOrder({ movedDataId: movedData.id, nextIndexOrder: nextIndex.order });
            } else if (newIndex === Number(newData.length - 1)) {
                //move to last
                handleSortOrder({ movedDataId: movedData.id, prevIndexOrder: prevIndex.order });
            } else {
                //move between first+n to last-1
                handleSortOrder({
                    movedDataId: movedData.id,
                    prevIndexOrder: prevIndex.order,
                    nextIndexOrder: nextIndex.order,
                });
            }
        }
    };

    const DraggableContainer = (props) => (
        <SortableContainer useDragHandle helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        const index = dataSource?.findIndex((x) => x.key === restProps['data-row-key']);
        return <SortableItem index={index ? index : 0} {...restProps} />;
    };

    //prevent empty dataSource
    useEffect(() => {
        setDataSource(data);
    }, [data]);

    return (
        <ELearningWrapper>
            <Table
                loading={loading}
                pagination={false}
                bordered
                dataSource={dataSource}
                columns={columns}
                rowKey="key"
                components={{ body: { wrapper: DraggableContainer, row: DraggableBodyRow } }}
                onRow={handleRow}
                scroll={{ y: isSectionType ? 300 : `calc(100vh - 15rem)`, x: scrollX && scrollX }}
                size={size}
            />
        </ELearningWrapper>
    );
}
