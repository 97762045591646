import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Button, Modal, message, Space } from 'antd';
import { TeamOutlined, EditOutlined, DeleteOutlined, GlobalOutlined, SearchOutlined } from '@ant-design/icons';
import { dateDisplay, getDifferenceDaysFromNow, textToDate } from '../../utils';
import getTextDisplay, { dataType } from '../../utils/data';
import API from '../../utils/api';
import PostJobContainer from './postjob.style';

const PostJobsTable = ({
    loading,
    page,
    total,
    postJobs,
    setPostJob,
    setVisibleUpsert,
    setVisibleTranslate,
    setVisibleStatus,
    handleFetchPostJobs,
    keyword,
    jobId,
}) => {
    const history = useHistory();

    const handleChangePagination = (page) => {
        history.replace(`/post-jobs${page !== 1 ? `?page=${page}` : ''}`);
        handleFetchPostJobs(page, keyword, jobId);
    };

    const handleEmployees = (e, item) => {
        e.stopPropagation();
        history.push(`/post-job-employees?id=${item.id}`);
    };

    const handleStatus = (e, item) => {
        e.stopPropagation();
        setPostJob(item);
        setVisibleStatus(true);
    };

    const handleSuggestCandidates = (e, item) => {
        e.stopPropagation();
        history.push(
            `/post-jobs/suggested-candidates?company_name=${item?.company_name}&&gender=${item?.gender}&&from_age=${
                item?.from_age
            }&&to_age=${item?.to_age}&&finish_education=${item?.finish_education}&&job_id=${
                item?.job.id
            }&&japanese_level=${item?.japanese_level ? item.japanese_level : 7}&&page=1`
        );
    };

    const handleTranslate = (e, item) => {
        e.stopPropagation();
        setPostJob(item);
        setVisibleTranslate(true);
    };

    const handleEdit = (e, item) => {
        e.stopPropagation();
        setPostJob(item);
        setVisibleUpsert(true);
    };

    const handleDelete = (e, item) => {
        e.stopPropagation();
        Modal.confirm({
            title: 'Are you sure delete this post?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await API().delete(`/postjob/${item.id}`);

                    if (response.data.errorCode === 0) {
                        message.success(`Deleted successfully!`);
                        handleFetchPostJobs(page);
                    } else {
                        message.error(`Failed, ${response.data.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request failed!`);
                }
            },
            onCancel() {},
        });
    };

    const handleRow = (record, rowIndex) => {
        return {
            onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                history.push(`/post-jobs/detail/${record.id}`, JSON.stringify(record));
            }, // click row
        };
    };

    const onTableChange = (_pagination, _filters, sorter) => {
        if (sorter['order']) {
            const field = sorter ? sorter.columnKey : '';
            const order = sorter ? sorter.order : '';
            handleFetchPostJobs(page, keyword, jobId, order, field);
        }
    };

    const dataSource = useMemo(
        () =>
            postJobs.length
                ? postJobs.map((post, index) => {
                      return {
                          ...post,
                          no: index + 1 + (page - 1) * 15,
                          key: post.id,
                          partner_id: post?.partner?.id,
                          company_name: post.employer
                              ? post.employer.company_name
                              : post.partner
                              ? post.partner.name
                              : '',
                          contact: post?.email,
                          created_date: dateDisplay(post.created_at),
                          interviewed_date: dateDisplay(post.interview_date),
                      };
                  })
                : [],
        [postJobs, page]
    );

    const columns = [
        { title: '#', dataIndex: 'no', key: 'updated_at', width: 50, fixed: 'left', sorter: () => {} },
        { title: 'COM/PAR', dataIndex: 'company_name', key: 'company_name', sorter: () => {} },
        {
            title: 'JOB TITLE',
            key: 'job_title',
            render: (item, row) => (
                <span
                    className={`${
                        row?.status === 5
                            ? 'text-expire'
                            : row?.status === 1
                            ? 'text-pending'
                            : row?.status === 3
                            ? 'text-publish'
                            : ''
                    }`}
                >
                    {item['job_title']}
                    {getDifferenceDaysFromNow(textToDate(item.created_at)) <= 3 ? (
                        <span
                            style={{
                                marginLeft: 10,
                                color: `${
                                    row?.status === 5
                                        ? 'text-expire'
                                        : row?.status === 1
                                        ? 'text-pending'
                                        : row?.status === 3
                                        ? 'text-publish'
                                        : ''
                                }`,
                                fontStyle: 'italic',
                            }}
                        >
                            (new)
                        </span>
                    ) : (
                        <span></span>
                    )}
                </span>
            ),
            width: 300,
            sorter: () => {},
        },
        {
            title: 'HIRING',
            dataIndex: 'hiring',
            key: 'hiring',
            width: 100,
            align: 'center',
            sorter: () => {},
        },
        {
            title: 'APPLY',
            dataIndex: 'post_job_employees',
            key: 'post_job_employees',
            width: 100,
            align: 'center',
        },
        {
            title: 'INTERVIEW',
            dataIndex: 'interviewed_date',
            key: 'interviewed_date',
            width: 130,
            align: 'center',
        },
        {
            title: 'EXPIRATION',
            dataIndex: 'expiration_date',
            key: 'expiration_date',
            width: 130,
            sorter: () => {},
        },
        {
            title: 'CREATED',
            dataIndex: 'created_date',
            key: 'created_date',
            width: 120,
        },
        {
            title: 'STATUS',
            key: 'status',
            width: 150,
            render: (item, row) => (
                <span>
                    <Button type="link" onClick={(e) => handleStatus(e, item)}>
                        <u
                            className={`${
                                row?.status === 5
                                    ? 'text-expire'
                                    : row?.status === 1
                                    ? 'text-pending'
                                    : row?.status === 3
                                    ? 'text-publish'
                                    : ''
                            }`}
                        >
                            {getTextDisplay(item.status, dataType.POST_STATUS)}
                        </u>
                    </Button>
                </span>
            ),
            sorter: () => {},
        },
        {
            title: '',
            key: 'actions',
            // width: 200,
            render: (item, row, index) => (
                <Space direction="horizontal" size="small">
                    <Button
                        title="Search"
                        type="primary"
                        icon={<SearchOutlined />}
                        onClick={(e) => handleSuggestCandidates(e, row)}
                    />
                    <Button
                        title="Candidates"
                        type="primary"
                        icon={<TeamOutlined />}
                        onClick={(e) => handleEmployees(e, item)}
                    />
                    <Button
                        title="Translate"
                        type="primary"
                        icon={<GlobalOutlined />}
                        onClick={(e) => handleTranslate(e, item)}
                    />
                    <Button
                        title="Update"
                        type="primary"
                        icon={<EditOutlined />}
                        onClick={(e) => handleEdit(e, item)}
                    />
                    <Button
                        title="Delete"
                        type="danger"
                        icon={<DeleteOutlined />}
                        onClick={(e) => handleDelete(e, item)}
                    />
                </Space>
            ),
        },
    ];

    return (
        <PostJobContainer>
            <Table
                bordered
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                scroll={{ x: 1720 }}
                onRow={handleRow}
                onChange={onTableChange}
                pagination={{
                    onChange: handleChangePagination,
                    current: page,
                    defaultCurrent: 1,
                    defaultPageSize: 15,
                    total,
                }}
            />
        </PostJobContainer>
    );
};

export default PostJobsTable;
