import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';

//antd
import { Spin, Descriptions, Row, Col, message } from 'antd';

//api
import { getCultureDetail } from '../../../utils/api/E-Learning/culture';

//component
import { Container, LodoshaButton } from '../../../components/UI/ui.style';
import CultureDetailModal from './QuestionAndAnswer/QuestionAndAnswerModal';
import NextPrevActionButtonGroup from '../E-LearningComponents/NextPrevActionButtonGroup';

export default function CultureDetail() {
    const [loading, setLoading] = useState(false);
    const [cultureDetail, setCultureDetail] = useState([]);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [postData, setPostData] = useState([]);
    const isSubscribed = useRef(true);

    const { contentid } = useParams();

    const handleFetchCultureDetail = useCallback(
        async (clickedItemId) => {
            try {
                const response = await getCultureDetail(clickedItemId ? clickedItemId : contentid);
                if (response && response.errorCode !== 0) {
                    setLoading(false);
                    message.error(`Could not get culture detail , ${response.message}`);
                    return;
                }
                setCultureDetail(response ? response?.data : {});
                setLoading(false);
            } catch (error) {
                message.error(`Something went wrong.${error}`);
            }
        },
        [contentid]
    );

    const Update = useCallback(
        () =>
            updateVisible && (
                <CultureDetailModal
                    isUpdate
                    title="UPDATE CULTURE DETAIL"
                    onRefresh={handleFetchCultureDetail}
                    setPostData={setPostData}
                    postData={postData}
                    visible={updateVisible}
                    setVisible={setUpdateVisible}
                />
            ),
        [updateVisible, postData, handleFetchCultureDetail]
    );

    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchCultureDetail();
        }
        return () => {
            isSubscribed.current = false;
        };
    }, [handleFetchCultureDetail]);

    //if user click Update button
    useEffect(() => {
        setPostData(cultureDetail);
    }, [cultureDetail, updateVisible]);

    const { title, answer, choice_one, choice_two, choice_three, choice_four } = cultureDetail;

    return (
        <Container>
            <Spin spinning={loading}>
                <div className="btn-margin-bottom">
                    <NextPrevActionButtonGroup
                        onClickAction={handleFetchCultureDetail}
                        currentItemId={contentid}
                        localStorageKey="e-learning-contents"
                    />
                </div>
                <Descriptions bordered column={1} title="Detail">
                    <Descriptions.Item label="Title">{title}</Descriptions.Item>
                    <Descriptions.Item label="Choice One">{choice_one}</Descriptions.Item>
                    <Descriptions.Item label="Choice Two">{choice_two}</Descriptions.Item>
                    <Descriptions.Item label="Choice Three">{choice_three}</Descriptions.Item>
                    <Descriptions.Item label="Choice Four">{choice_four}</Descriptions.Item>
                    <Descriptions.Item label="Answer">{answer}</Descriptions.Item>
                </Descriptions>
                <Row
                    style={{
                        position: 'sticky',
                        bottom: '0',
                        marginTop: '0px',
                        padding: '20px 0',
                        background: '#FAFAFA',
                    }}
                >
                    <Update />
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <LodoshaButton
                            type="primary"
                            style={{ marginRight: 16 }}
                            onClick={() => setUpdateVisible(true)}
                        >
                            Update
                        </LodoshaButton>
                    </Col>
                </Row>
            </Spin>
        </Container>
    );
}
