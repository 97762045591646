import React from 'react';
import { Button, Input, Select, DatePicker, TimePicker, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

const { Search, TextArea } = Input;
const dateFormat = 'DD-MM-YYYY';
const timeFormat = 'hh:mm a';

const Container = styled.div`
    background: #fff;
    padding: 24px;
    min-height: 380px;
    margin-bottom: 24px;
    border-radius: 10px;
    box-shadow: '#eee 2px 2px 1px 1px';
    
    .ant-table-row {
        cursor: pointer;
    }
    .btn-margin-bottom {
        margin-bottom: 1.5rem;
    }
    .new-sentence-btn {
        margin-bottom: 1rem;
    }
    .remove-icon:hover {
        color: red;
    }
`;

const StyledButton = styled(Button)`
    min-width: 108px;
    min-height: 40px;
    border-radius: ${(props) => (props.border ? props.border : '4px')};
`;

const LodoshaMenu = styled.div`
    box-shadow: 0 0 3px #ccc;
    ul {
        max-height: 500px;
        overflow: auto;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background: #edeff7;
    }
`;

const LodoshaButton = (props) => (
    <StyledButton size="large" {...props}>
        {props.children}
    </StyledButton>
);

const LodoshaInput = (props) => <Input size="large" {...props} />;

const LodoshaSearch = (props) => <Search size="large" {...props} />;

const LodoshaSelect = (props) => (
    <Select size="large" placeholder="Select an option" {...props} >
        {props.children}
    </Select>
);

const LodoshaDatePicker = (props) => (
    <DatePicker size="large" format={dateFormat} style={{ width: '100%' }} {...props} />
);

const LodoshaTimePicker = (props) => (
    <TimePicker size="large" style={{ width: '100%' }} minuteStep={15} use12Hours format={timeFormat} {...props} />
);

const LodoshaSwitch = (props) => (
    <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        {...props}
        onChange={(checked) => props.onChange(checked === false ? 0 : 1)}
    />
);

const LodoshaTextArea = (props) => <TextArea rows={4} {...props} />;

const LodoshaInputPassword = (props) => <Input.Password size="large" {...props} />;

export {
    LodoshaMenu,
    Container,
    LodoshaButton,
    LodoshaInput,
    LodoshaSearch,
    LodoshaSelect,
    LodoshaDatePicker,
    LodoshaTimePicker,
    LodoshaSwitch,
    LodoshaTextArea,
    LodoshaInputPassword,
};
