import React, { useState, useCallback } from 'react';
import { message, Table } from 'antd';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';

import getTextDisplay, { dataType } from '../../../utils/data';
import API from '../../../utils/api';

export default function CandidateTable() {
    const location = useLocation();
    const history = useHistory();
    const [loadingCandidates, setLoadingCandidates] = useState(false);
    const [totalCandidate, setTotalCandidate] = useState(0);
    const [employees, setEmployees] = useState();

    const page = location.search ? Number(queryString.parse(location.search).page) : 1;

    const { id } = useParams();

    const handleFetchCandidates = useCallback(
        async (page = 1) => {
            setLoadingCandidates(true);
            try {
                const response =
                    page === 1
                        ? await API()
                              .get(`/partner-employee/passed-interview/${id}`)
                              .then((response) => response.data)
                        : await API()
                              .get(`/partner-employee/passed-interview/${id}?offset=${(page - 1) * 15}`)
                              .then((response) => response.data);
                if (response) {
                    if (response.errorCode === 0) {
                        setTotalCandidate(response.total);
                        setEmployees(response.data);
                        setLoadingCandidates(false);
                        return;
                    } else {
                        message.error(`Uh oh,couldn't get users. ${response.message}`);
                        setLoadingCandidates(false);
                        return;
                    }
                } else {
                    message.error(`Uh oh, something went wrong.`);
                    setLoadingCandidates(false);
                    return;
                }
            } catch (error) {
                setLoadingCandidates(false);
                message.error(`Couldn't get passed interview candidates`);
            }
        },
        [id]
    );

    const handleRow = (item, index) => {
        return {
            onClick: (e) => {
                history.push(`/candidates/detail/${item.id}`);
            },
        };
    };

    const dataSource =
        employees &&
        employees.map((employee, index) => ({
            ...employee,
            id: employee.employee_id,
            key: employee.employee_id,
            no: (page - 1) * 15 + index + 1,
        }));

    const columns = [
        {
            title: '#',
            dataIndex: 'no',
            key: 'updated_at',
            width: 65,
            fixed: 'left',
            sorter: () => {},
            align: 'center',
        },
        { title: 'NAME', dataIndex: 'employee_name', key: 'employee_name', fixed: 'left', sorter: () => {} },
        { title: 'CODE', dataIndex: 'employee_code', key: 'employee_code', width: 110, sorter: () => {} },
        {
            title: 'GENDER',
            dataIndex: 'employee_gender',
            key: 'employee_gender',
            width: 110,
            render: (item) => <span>{getTextDisplay(item, dataType.GENDER)}</span>,
            sorter: () => {},
        },
        { title: 'PHONE', dataIndex: 'employee_phone', key: 'employee_phone', width: 130, sorter: () => {} },
        {
            title: 'TYPE',
            dataIndex: 'employee_type',
            key: 'employee_type',
            render: (item) => <span>{getTextDisplay(item, dataType.CANDIDATE_RCORD_TYPE)}</span>,
        },
        {
            title: 'LEVEL',
            dataIndex: 'employee_japanses_level',
            key: 'japanese-level',
            width: 120,
            render: (item) => <span>{getTextDisplay(item, dataType.JAPANESE_LEVEL)}</span>,
            sorter: () => {},
        },
        {
            title: 'TAG',
            dataIndex: 'employee_tag',
            key: 'employee_tag',
            render: (item) => <span>{item === 0 ? '-' : item}</span>,
            sorter: () => {},
        },
    ];

    const handleChangePagination = (page) => {
        history.replace(`/partners/detail/pass-interview/${id}?page=${page}`);
    };

    React.useEffect(() => {
        let isSubscribed = true;
        isSubscribed && handleFetchCandidates(page);

        return () => {
            isSubscribed = false;
        };
    }, [handleFetchCandidates, page]);

    return (
        <div>
            <Table
                onRow={handleRow}
                dataSource={dataSource}
                columns={columns}
                loading={loadingCandidates}
                bordered
                pagination={{
                    total: totalCandidate,
                    defaultPageSize: 15,
                    pageSize: 15,
                    current: page,
                    onChange: handleChangePagination,
                }}
            />
        </div>
    );
}
