import React, { useState } from 'react';

//antd
import { Col, Form, message, Modal, Row, Select } from 'antd';

//components
import { LodoshaButton } from '../../components/UI/ui.style';

//utils and constant
import { dataUserType, userTypeToText } from '../../utils/data';
import { changeEmployeeType } from '../../utils/api/employee';

export default function ModalUserType({ visible, setVisible, user = {}, setUser }) {
    const [form] = Form.useForm();

    const { Option } = Select;

    const [type, setType] = useState(user?.type);
    const [loading, setLoading] = useState(false);

    const handleCancel = () => {
        setType('');
        setUser([]);
        setVisible(false);
    };

    const handleChangeUserRole = (value) => {
        console.log(value);
        setType(value);
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);

            const response = await changeEmployeeType({ employeeId: user?.employeeId, type: type });

            if (response) {
                if (response.errorCode === 0) {
                    message.success('Successfully updated user type.');
                    user.type = response?.data?.type;
                    handleCancel();
                } else {
                    message.error(`Uh oh, couldn't update user type, ${response.message}`);
                    setLoading(false);
                }
            } else {
                message.error(`Uh oh, couldn't update user type, Response is undefined.`);
                setLoading(false);
            }
        } catch (error) {
            message.error("Uh oh, couldn't update user type.");
            setLoading(false);
        }
    };

    return (
        <Modal footer={null} title={''} visible={visible} onCancel={handleCancel} maskClosable>
            <Form form={form} layout="vertical">
                <Row>
                    <Col span={24}>
                        <h2>Change Type</h2>
                        <Form.Item label="Current Type: ">
                            <Select onChange={handleChangeUserRole} size="large" value={userTypeToText[type]}>
                                {dataUserType.map((type, index) => (
                                    <Option value={type['value']} key={index}>
                                        {type['text']}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <LodoshaButton onClick={handleSubmit} loading={loading} type="primary">
                            Change
                        </LodoshaButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
