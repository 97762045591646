import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from '../../components/UI/ui.style';
import ELearningWrapper from './E-LearningComponents/elearning.style';

//constants
import {
    ELEARNING_JAPANESE_CLASS,
    ELEARNING_CONVERSATION,
    ELEARNING_14SKILLS,
    ELEARNING_EXAM,
    ELEARNING_CULTURE,
    ELEARNING_PRE_INTERVIEW,
    ELEARNING_JLPT,
    ELEARNING_NEWS_MANAGEMENT,
    ELEARNING_FURIGANA_GENERATOR,
    ELEARNING_MARKDOWN_SAMPLE,
} from '../../constants/routes';

//ant design
import { Row, Col, Card, Avatar, Divider } from 'antd';

//topic icons
import conversation from '../../assets/topic_icons/conversation.svg';
import culture from '../../assets/topic_icons/culture.svg';
import exam from '../../assets/topic_icons/exam.svg';
import japanese from '../../assets/topic_icons/japanese1.png';
import job_interview from '../../assets/topic_icons/job_interview.svg';
import skills from '../../assets/topic_icons/skills.svg';
import jlpt from '../../assets/topic_icons/jlpt.svg';

export default function ELearningPage() {
    const eLearningMenu = [
        {
            topicName: 'Japanese Class',
            icon: japanese,
            pathname: `${ELEARNING_JAPANESE_CLASS}`,
            disabled: false,
            menu: 1,
        },
        {
            topicName: '14 Skills',
            icon: skills,
            pathname: `${ELEARNING_14SKILLS}`,
            disabled: false,
            menu: 2,
        },
        {
            topicName: 'Conversation',
            icon: conversation,
            pathname: `${ELEARNING_CONVERSATION}`,
            disabled: false,
            menu: 3,
        },
        {
            topicName: 'Exam',
            icon: exam,
            pathname: `${ELEARNING_EXAM}`,
            disabled: false,
            menu: 4,
        },
        {
            topicName: 'Culture',
            icon: culture,
            pathname: `${ELEARNING_CULTURE}`,
            disabled: false,
            menu: 5,
        },
        {
            topicName: 'Pre-Interview',
            icon: job_interview,
            pathname: `${ELEARNING_PRE_INTERVIEW}`,
            disabled: true,
            menu: 6,
        },
        {
            topicName: 'JLPT',
            icon: jlpt,
            pathname: `${ELEARNING_JLPT}`,
            disabled: false,
            menu: 7,
        },
    ];

    const toolMenu = [
        {
            topicName: 'News Management',
            icon: '',
            pathname: `${ELEARNING_NEWS_MANAGEMENT}`,
            disabled: false,
        },
        {
            topicName: 'Furigana Generator',
            icon: '',
            pathname: `${ELEARNING_FURIGANA_GENERATOR}`,
            disabled: false,
        },
        {
            topicName: 'Markdown Sample',
            icon: '',
            pathname: `${ELEARNING_MARKDOWN_SAMPLE}`,
            disabled: false,
        },
    ];

    return (
        <ELearningWrapper>
            <Container>
                <Row gutter={[16, 16]}>
                    {eLearningMenu.map((topic, index) => {
                        const { topicName, icon, pathname, disabled } = topic;
                        return (
                            !disabled && (
                                <Col sm={24} md={12} xl={8} key={index}>
                                    <Link to={{ pathname: pathname }}>
                                        <Card hoverable style={{ textAlign: 'center' }}>
                                            <Avatar src={icon} size={64} shape="square" className="topic-icon" />
                                            <h3 className="topic-name">{topicName}</h3>
                                        </Card>
                                    </Link>
                                </Col>
                            )
                        );
                    })}
                </Row>
                <Divider orientation="left">Tools</Divider>
                <Row gutter={[16]}>
                    {toolMenu.map((tool, index) => (
                        <Col sm={24} md={12} xl={8} key={index}>
                            <Link to={{ pathname: tool.pathname }}>
                                <Card hoverable style={{ textAlign: 'center' }}>
                                    <Avatar src={tool.icon} size={64} shape="square" className="topic-icon" />
                                    <h3 className="topic-name">{tool.topicName}</h3>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
            </Container>
        </ELearningWrapper>
    );
}
