import React, { useContext, useEffect, useState, useCallback } from 'react';

//api
import { postNewNotification, updateNotification } from '../../../utils/api/E-Learning/news_management';

//antd
import { Modal, Form, message, Row, Col, Input, Select } from 'antd';

//components
import ModalWrapper from '../E-LearningComponents/modal.style';
import { LodoshaButton } from '../../../components/UI/ui.style';
import { notificationTypes } from '../../../utils/data';

import AppContext from '../../../contexts/AppContext';

//utils and constant
import { getPostJobs } from '../../../utils/api/post_job';
import { getBlogs } from '../../../utils/api/blog';
import { getExamLevel } from '../../../utils/api/E-Learning/exam';

export default function NewsManagementModal({
    postData,
    setPostData,
    visible,
    setVisible,
    isCreate,
    isUpdate,
    onRefresh,
    title,
}) {
    const { handleUnauthorizedUser } = useContext(AppContext);

    const [form] = Form.useForm();
    const { TextArea } = Input;
    const { Option } = Select;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(postData);
    const [attachmentData, setAttachmentData] = useState([]);
    const [attachmentLoading, setAttachmentLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({ ...prev, [name]: value }));
    };

    const handleChangeType = (value) => {
        setData((prev) => ({ ...prev, type: value }));
    };

    const handleChangeAttachment = (value) => {
        const selectedPostJob = attachmentData.find((a) => a.id === value);
        console.log(selectedPostJob);
        setData((prev) => ({ ...prev, image: selectedPostJob['image'], attachment: selectedPostJob['id'] }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = isCreate
                ? await postNewNotification(data)
                : isUpdate
                ? await updateNotification(data)
                : {};

            if (response && response.errorCode !== 0) {
                if (response.data.status === 401) {
                    message.error(`Unauthorized`);
                    handleUnauthorizedUser();
                    return;
                }
                message.error(`Uh oh, couldn't create new News Notification ${response.message}`);
                setLoading(false);
                return;
            }

            if (response) {
                response &&
                    message.success(
                        isCreate ? 'New notification added.' : isUpdate ? 'Successfully updated notification.' : ''
                    );
                setLoading(false);
                onRefresh();
                handleCancel();
            } else {
                setLoading(false);
                message.error(
                    `Uh oh, ${isCreate ? 'adding new notification' : isUpdate ? 'updating notification' : ''} failed!`
                );
            }
        } catch (error) {
            message.error(`Uh oh, couldn't create new News Notification ${error.message}`);
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setVisible(false);
        setPostData({});
    };

    const handleAttachmentDataFormat = useCallback(async (fn, selectedType) => {
        try {
            setAttachmentLoading(true);
            const response = await fn();
            if (response) {
                if (response.errorCode === 0) {
                    const newFormat = response.data.map((d) => {
                        let name = '';
                        let image = '';
                        switch (selectedType) {
                            case 'post_job':
                                name = 'job_title';
                                image = 'photo';
                                break;
                            case 'blog':
                            case 'exam':
                                name = 'title';
                                image = 'image_id';
                                break;

                            default:
                        }

                        return {
                            id: d.id,
                            title: d[name],
                            image: d[image],
                        };
                    });
                    setAttachmentData(newFormat);
                    setAttachmentLoading(false);
                } else {
                    message.error(`Couldn't get latest data, ${response?.message}`);
                    setAttachmentLoading(false);
                }
            } else {
                message.error(`Couldn't get latest data, Response is undefined.`);
                setAttachmentLoading(false);
            }
        } catch (error) {
            console.log(error);
            message.error(`Couldn't get latest data,${error.message}`);
            setAttachmentLoading(false);
        }
    }, []);

    const handleFetchAttachments = useCallback(
        async (selectedType) => {
            switch (selectedType) {
                case 'post_job': {
                    handleAttachmentDataFormat(getPostJobs, selectedType);
                    break;
                }
                case 'blog': {
                    handleAttachmentDataFormat(getBlogs, selectedType);
                    break;
                }
                case 'exam': {
                    handleAttachmentDataFormat(getExamLevel, selectedType);
                    break;
                }
                default:
                    setAttachmentData([]);
            }
        },
        [handleAttachmentDataFormat]
    );

    useEffect(() => {
        setAttachmentData([]);
        if (data.type !== 'general') {
            handleFetchAttachments(data.type);
        }
    }, [data.type, handleFetchAttachments]);

    return (
        <Modal
            visible={visible}
            onCancel={handleCancel}
            footer={null}
            title={title}
            maskClosable={false}
            centered
            width={768}
        >
            <ModalWrapper>
                <Form form={form} onFinish={handleSubmit} layout="vertical">
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                initialValue={data?.title}
                                rules={[{ required: true, message: 'Data required!' }]}
                                label={`Title:`}
                                name="title"
                            >
                                <TextArea
                                    name="title"
                                    onChange={handleChange}
                                    value={data?.title}
                                    placeholder="Notification title..."
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                initialValue={data?.body}
                                rules={[{ required: true, message: 'Data required!' }]}
                                label={`Body:`}
                                name="body"
                            >
                                <TextArea
                                    name="body"
                                    onChange={handleChange}
                                    value={data?.body}
                                    placeholder="Notification body..."
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                initialValue={data?.type}
                                rules={[{ required: true, message: 'Data required!' }]}
                                label={`Type:`}
                                name="type"
                            >
                                <Select value={data?.type} onChange={handleChangeType} size="large">
                                    {notificationTypes.map((type, index) => (
                                        <Option value={type.value} key={index}>
                                            {type.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {data?.type && data?.type !== 'general' && (
                            <Col span={24}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: data?.type === 'general' ? false : true,
                                            message: 'Data required!',
                                        },
                                    ]}
                                    label={`Attachment`}
                                    name="attachment"
                                >
                                    <Select
                                        name="attachment"
                                        value={data?.attachment}
                                        onChange={handleChangeAttachment}
                                        size="large"
                                        loading={attachmentLoading}
                                    >
                                        {attachmentData.length > 0 &&
                                            attachmentData.map((attachment) => (
                                                <Option key={attachment.id} value={attachment?.id}>
                                                    {attachment?.title}
                                                </Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}

                        <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
                            <LodoshaButton
                                key="cancel"
                                style={{ marginRight: 16 }}
                                htmlType="button"
                                onClick={handleCancel}
                            >
                                Cancel
                            </LodoshaButton>
                            <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                                {isCreate ? 'Add New' : isUpdate ? 'Update' : ''}
                            </LodoshaButton>
                        </Col>
                    </Row>
                </Form>
            </ModalWrapper>
        </Modal>
    );
}
