import API from '../api';

export const advanceSearch = (page = 1, data = '', order = '', field = '') => {
    const endpoint = page === 1 ? `/employee/advance-search` : `/employee/advance-search?offset=${(page - 1) * 15}`;

    return API()
        .post(endpoint, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
};

export const advanceSearchGetAll = (keys) => {
    return API()
        .post('/employee/advance-search/get-all', keys)
        .then((response) => response.data)
        .catch((err) => console.log(err));
};
