import React, { useState, useEffect, useRef, useCallback } from 'react';
import API, { baseURL, version } from '../../utils/api';
import { List, Button, Spin, Row, Col, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { isNullOrUndefined } from 'util';

import axios from 'axios';
import Cookies from 'js-cookie';
import FileItem from './FileItem';
import FilePopUp from './FilePopUp';

const FileComponent = ({ title, id, type, handleProfileRefresh }) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [existFiles, setExistFiles] = useState();
    const isSubscribed = useRef(true);
    const request = axios.CancelToken.source();

    const handleFetchExistFiles = useCallback(async () => {
        try {
            isSubscribed.current && setLoading(true);
            API()
                .get(`/attachment?id=${id}&type=${type}`)
                .then((response) => {
                    setLoading(false);
                    response.data && isSubscribed.current && setExistFiles(response.data && response.data.data);
                });
        } catch (error) {
            setLoading(false);
            message.error(`Uh oh, request failed!`);
        }
    }, [id, type]);

    useEffect(() => {
        handleFetchExistFiles();
        return () => {
            isSubscribed.current = false;
        };
    }, [handleFetchExistFiles]);

    const handleUploadToServer = (data, files, callback) => {
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        let formData = new FormData();

        for (var key in data) {
            if (!isNullOrUndefined(data[key])) {
                formData.append(key, data[key]);
            }
        }

        formData.append('id', id);
        formData.append('type', type);
        formData.append('cancelToken', request.token);

        for (let file of files) {
            formData.append(file?.originFileObj?.fieldname, file?.originFileObj);
        }

        //check values in formdata
        // for (let [key, value] of formData.entries()) {
        //     console.log(key, value);
        // }

        setLoading(true);
        API()
            .post(`/attachment`, formData, config)
            .then((response) => {
                setLoading(false);
                if (response && response.data && response.data.errorCode === 0) {
                    if (files.filter((file) => file.originFileObj['fieldname'] === 'profile').length > 0) {
                        if (handleProfileRefresh) {
                            handleProfileRefresh();
                        }
                        handleFetchExistFiles();
                    } else {
                        let temExist = existFiles;
                        temExist.push(response.data.data);
                        setExistFiles(temExist);
                    }
                } else {
                    message.error(response.data.message);
                }
                if (callback) {
                    callback();
                }
            });
    };

    const handleDelete = (id) => {
        try {
            setLoading(true);
            API()
                .delete(`/attachment/${id}`)
                .then((response) => {
                    setLoading(false);

                    if (response && response.data && response.data.errorCode === 0) {
                        const files = existFiles.filter((item) => item.id === id);

                        if (files && files.length > 0) {
                            if (files[0].category === 'profile') {
                                if (handleProfileRefresh) {
                                    handleProfileRefresh();
                                }
                            }
                        }
                        setExistFiles(existFiles.filter((item) => item.id !== id));
                    }
                });
        } catch (error) {
            setLoading(false);
            message.error(`Uh oh, request failed!`);
        }
    };

    const onClickNew = (e) => {
        setVisible(true);
    };

    return (
        <div>
            <FilePopUp
                visible={visible}
                loading={loading}
                onCancel={() => {
                    request.cancel();
                    setVisible(false);
                }}
                handleUploadToServer={handleUploadToServer}
            />
            <Spin tip="Loading..." spinning={loading}>
                <Row>
                    <Col span={20}>
                        <h1>{title}</h1>
                    </Col>
                    <Col span={4} align="right">
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={(e) => {
                                onClickNew(e);
                            }}
                        >
                            New
                        </Button>
                    </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                    <List
                        dataSource={existFiles}
                        renderItem={(item) => (
                            <List.Item>
                                <a
                                    href={`${baseURL}${version}/attachment/view/${item.id}?uuid=${Cookies.get(
                                        'backoffice_token'
                                    )}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FileItem
                                        id={item.id}
                                        name={item.name ? item.name : 'No Name'}
                                        category={item.category}
                                        mime_type={item.mime_type}
                                        is_delete={item.is_delete}
                                        handleDelete={handleDelete}
                                    />
                                </a>
                            </List.Item>
                        )}
                    />
                </Row>
            </Spin>
        </div>
    );
};
export default FileComponent;