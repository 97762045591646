import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';

//antd
import { Col, message, Row, Spin, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

//components
import MarkdownPreviewWrapper from './markdown_it.style';
import { LodoshaButton } from '../UI/ui.style';
import { getLastestMedia } from '../../utils/api/E-Learning/media';

export default function ImagePicker({ onClose }) {
    const isSubscribed = useRef(true);
    const loader = useRef(null);

    const [files, setFiles] = useState([]);
    const [file, setFile] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleOnMouseOver = (file) => {
        if (file['mime_type'] === 'audio/mp3' || file['mime_type'] === 'audio/mpeg') {
            return setFile([]);
        }
        setFile(file);
    };

    const handleOnMouseLeave = (e) => {
        setFile([]);
    };

    const handleFileUrl = (url) => {
        navigator.clipboard.writeText(url);
        onClose('image');
    };

    const handleFetchImages = useCallback(async (offset) => {
        setLoading(true);
        try {
            const response = await getLastestMedia(offset);
            if (response) {
                if (response.errorCode !== 0) {
                    setLoading(false);
                    message.error(`Couldn't get images, please try again. ${response['message']}`);
                } else {
                    setLoading(false);
                    setFiles((prev) => [...prev, ...response['data']]);
                }
            } else {
                message.error('Response undefined, possibly server error.');
                setLoading(false);
            }
        } catch (error) {
            message.error(`Uh oh, couldn't get images. ${error['message']}`);
            setLoading(false);
        }
    }, []);

    const handleChangeFile = (info) => {
        if (info['file']['status'] === 'done') {
            const response = info['file']['response']['data'];
            files.unshift({ fullPath: response['fullPath'], id: response['id'] });
            let newFileArray = files.filter((img, index) => {
                if (index < 15) {
                    return img;
                }else{
                    return null
                }
            });
            setFiles([...newFileArray]);
        }
    };

    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchImages();
        }
        return () => {
            isSubscribed.current = false;
        };
    }, [handleFetchImages]);

    return (
        <MarkdownPreviewWrapper>
            <div>
                <Row className="images-container">
                    {files.map((f, index) => (
                        <Col
                            span={12}
                            key={index}
                            className="image-container"
                            onMouseOver={(e) => handleOnMouseOver(f)}
                            onMouseLeave={handleOnMouseLeave}
                            onClick={(e) => handleFileUrl(f['fullPath'])}
                        >
                            {f['mime_type'] === 'audio/mp3' || f['mime_type'] === 'audio/mpeg' ? (
                                <ReactAudioPlayer src={f['fullPath']} controls className="image" />
                            ) : (
                                <img src={f['fullPath']} className="image" alt={'markdown preview'} />
                            )}
                            {file['id'] === f['id'] && (
                                <>
                                    <div className="image-mask" />
                                    <div className="select-text">Select</div>
                                </>
                            )}
                        </Col>
                    ))}
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <div ref={loader} />
                        {loading && <Spin />}
                    </Col>
                </Row>

                <div className="upload-btn">
                    <Upload
                        accept="image/*, .mp3"
                        multiple
                        showUploadList={false}
                        name="markdown"
                        action={`${process.env.BASE_URL_MIDDLEWARE}admin-media/upload-markdown-images`}
                        onChange={handleChangeFile}
                    >
                        <LodoshaButton type="primary" icon={<UploadOutlined />}>
                            Upload(up to 20 )
                        </LodoshaButton>
                    </Upload>
                </div>
            </div>
        </MarkdownPreviewWrapper>
    );
}
