import React, { useMemo, useState, useCallback, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, Table, message, Select, Popconfirm, Button, Modal } from "antd";
import { PlusOutlined, DeleteOutlined, HistoryOutlined } from "@ant-design/icons";
import { Container, LodoshaButton, LodoshaSelect } from "../../components/UI/ui.style";
import { getPostJobEmployees, updateStatus, deletePostJobEmployee } from "../../utils/api/post_job_employee";
import { dataApplicationStatus, ApplicationStatus } from '../../utils/data' ;
import { dateDisplay } from '../../utils';
import { getPostJob } from '../../utils/api/post_job'
import { POSTJOB_CANDIDATES } from '../../constants/routes'

import getTextDisplay, { dataType } from "../../utils/data";
import ModalEmployees from "./ModalEmployees";
import ModalHistoryDate from "../../components/ModalHistoryDate";
import queryString from 'query-string';
import AppContext from '../../contexts/AppContext';

import * as ROUTES from '../../constants/routes';

const PostJobEmployeePage = () => {
  const history = useHistory();
  const location = useLocation();

  const page = location.search ? Number(queryString.parse(location.search).page ? queryString.parse(location.search).page : 1) : 1;
  const id = location.search ? queryString.parse(location.search).id : '';

  const { Option } = Select;
  const { dataStaffs } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [postJobEmployees, setPostJobEmployees] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleHistoryDate, setVisibleHistoryDate] = useState(false);
  const [postJobEmployee, setPostJobEmployee] = useState();
  const [total, setTotal] = useState(0);
  const [postJob, setPostJob] = useState();

  const [confirmVisible, setConfirmVisible] = useState(false);
  const [confirmData, setConfirmData] = useState({});

  const handleSaveStatus = useCallback(
    async (id, status) => {
      updateStatus(id, status)
        .then((data) => {
          if (data.errorCode === 0) {
            var today = new Date();
            var todayStr = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}` 
            
            switch(status) {
              case ApplicationStatus.PASSED:
                setPostJobEmployees(postJobEmployees.map(item => 
                  item.id === id ? {...item, pass_interview_date : todayStr, status : status } : item 
                ))
                break;
              case ApplicationStatus.PAID:
                  setPostJobEmployees(postJobEmployees.map(item => 
                    item.id === id ? {...item, paid_date : todayStr, status : status } : item 
                  ))
                  break;
              default:
                setPostJobEmployees(
                  postJobEmployees.map(item => 
                      item.id === id ? {...item, status : status } : item 
                ))
                break;
            }
            message.success('Saved successfully!');
          } else {
              message.error(`Failed, ${data.message}`);
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error(`Uh oh, request failed: ${error}`);
        });
    }, [postJobEmployees]
  );

  const handleFetchData = useCallback( 
    async (page = 1) => {
      setLoading(true);
      getPostJobEmployees(id, page)
        .then((response) => {
          if (response.data) {
            setPostJobEmployees(response.data);
            setTotal(response.total)
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error(`Uh oh, request failed!`);
        });
    }, [id]
  );

  const handleFetchPostJob = useCallback( 
    async (id) => {
      setLoading(true);
      getPostJob(id)
        .then((response) => {
          if (response.data) {
            setPostJob(response.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error(`Uh oh, request failed!`);
        });
    }, []
  );

  useEffect(() => {
    if (id) {
      handleFetchPostJob(id);
      handleFetchData(page);
    }
  }, [id, page, handleFetchData, handleFetchPostJob]);

  const handleChangePagination = (page) => {
    history.replace(`${POSTJOB_CANDIDATES}?id=${id}${page !== 1 ? `&page=${page}` : ''}`);
};

  const handleRemoveEmployee = (id) => {
    setPostJobEmployees((prevState) => prevState.filter((item) => item.id !== id));
  };

  const handleAddEmployee = (data) => {
    setPostJobEmployees((prevState) => [data, ...prevState]);
  };

  const handleRow = (record, rowIndex) => {
    return {
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        history.push(`${ROUTES.CANDIDATES_DETAIL}/${record.employee.id}`);
      }, // click row
    };
  };

  const dataSource = useMemo(
    () =>
      postJobEmployees?.length &&
      postJobEmployees.map((postJobEmployee, index) => {
        return {
          ...postJobEmployee,
          key: postJobEmployee.id,
          no: index + 1,
          name: postJobEmployee?.employee?.name,
          gender: getTextDisplay(postJobEmployee?.employee?.gender, dataType.GENDER),
          phone: postJobEmployee?.employee?.phone,
          japanese_level: getTextDisplay(postJobEmployee?.employee?.japanses_level, dataType.JAPANESE_LEVEL),
          tag: getTextDisplay(postJobEmployee?.employee.tag, dataType.TAG_STATUS),
          percentage: `${postJobEmployee?.employee ? postJobEmployee?.employee?.percentage : '-'}%`,
          note: postJobEmployee?.note,
          created_date: dateDisplay(postJobEmployee.created_at),
          sent_by: dataStaffs.find(dj=> dj.value === postJobEmployee.created_by) ? dataStaffs.find(dj=> dj.value === postJobEmployee.created_by).text : "-",
          status: postJobEmployee?.status,
        };
      }), [postJobEmployees, dataStaffs])

  const columns = [
    { title: "NO", dataIndex: "no", key: "no" },
    { title: "NAME", dataIndex: "name", key: "name" },
    { title: "GENDER", dataIndex: "gender", key: "gender" },
    { title: "PHONE", dataIndex: "phone", key: "phone" },
    { title: "JAPANESE", dataIndex: "japanese_level", key: "japanese-level" },
    { title: "SENT DATE", dataIndex: "created_date", key: "created_date" },
    { title: "SENT BY", dataIndex: "sent_by", key: "sent_by" },
    { title: "COMPLETE", dataIndex: "percentage", key: "percentage" },
    { title: "STATUS", width: 140, key: "status", render: (item) =>  
      (<LodoshaSelect style={{width: 140}} value={item.status} onClick={(e)=>{e.preventDefault(); e.stopPropagation();}} onChange={(value) => handleSelectChangeFromTable(item.key, value)}>
          {dataApplicationStatus.map(({ value, text }) => (
              <Option key={value} value={value}>
                  {text}
              </Option>
          ))}
      </LodoshaSelect>)
    },
    { title: "NOTE", dataIndex: "note", key: "note" },
    {
      title: "",
      key: "actions",
      width: "5%",
      render: (record) => (
        <span style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
          <Button style={{marginRight: 10}} title="Date" type="primary" onClick={(e)=>{e.stopPropagation(); setVisibleHistoryDate(true); setPostJobEmployee(record)}} icon={<HistoryOutlined />} />
          <Popconfirm
            placement="topLeft"
            title={"Do you want to delete?"}
            onConfirm={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleDelete(record);
            }}
            onCancel={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button title="Delete" type="danger" className="action-button-margin" onClick={(e)=>{e.preventDefault(); e.stopPropagation();}} icon={<DeleteOutlined />} />
          </Popconfirm>
        </span>
      ),
    }
  ];

  const handleDelete = async (record) => {
    // Delete
    deletePostJobEmployee(record.id).then((response) => {
      if (response && response.errorCode === 0) {
        handleRemoveEmployee(record.id);
      } else {
        message.error(response && response.message);
      }
    });
  };

  const handleSelectChangeFromTable = (id, value) => {
    if (value === ApplicationStatus.PAID) {
      setConfirmData({id: id, value: value})
      setConfirmVisible(true);
    } else {
      handleSaveStatus(id, value);;
    }
  };

  return (
    <Container>
      <Row justify="space-between" align="middle" style={{ marginBottom: 32 }}>
        <Col>
          <LodoshaButton type="primary" size="large" icon={<PlusOutlined />} onClick={() => setVisible(true)}>
            New
          </LodoshaButton>
          {visible && (
            <ModalEmployees
              visible={visible}
              setVisible={setVisible}
              postJobId={postJob && postJob.id}
              handleRemoveEmployee={handleRemoveEmployee}
              handleAddEmployee={handleAddEmployee}
            />
          )}
          {visibleHistoryDate && (
            <ModalHistoryDate
              visible={visibleHistoryDate}
              setVisible={setVisibleHistoryDate}
              data={postJobEmployee}
              type={'post-job'}
              onRefresh={()=>{handleFetchData()}}
            />
          )}
          <Modal
            title={`The status will be change to "PAID"`}
            visible={confirmVisible}
            onOk={()=>{
              handleSaveStatus(confirmData.id, confirmData.value);
              setConfirmVisible(false);
            }}
            onCancel={()=>{
              setConfirmVisible(false);
            }}
          >
            <p>All applications of this candidate will be UNAVAILABLE and can not undo, please be sure before select OK</p>
          </Modal>
        </Col>
        <Col span={8}>
          <h1 style={{ margin: 0 }}>Title: {postJob?.job_title}</h1>
        </Col>
        <Col span={8}>
          <h1 style={{ margin: 0 }}>Hiring: {postJob?.hiring}</h1>
        </Col>
      </Row>
      <Table 
        bordered 
        loading={loading} 
        onRow={handleRow} 
        columns={columns} 
        dataSource={dataSource} 
        pagination={{
          onChange: handleChangePagination,
          current: page,
          defaultCurrent: 1,
          defaultPageSize: 15,
          total,
      }}
        />
    </Container>
  );
};

export default PostJobEmployeePage;
