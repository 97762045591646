import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';

import { message, Table } from 'antd';

import { Container } from '../../components/UI/ui.style';

import { getSuggestedPostJobEmployees } from '../../utils/api/post_job_employee';
import getTextDisplay, { dataType } from '../../utils/data';

export default function SuggestedCandidates() {
    const query = new URLSearchParams(useLocation().search);
    const history = useHistory();
    const japanese_level = query.get('japanese_level');
    const from_age = query.get('from_age');
    const to_age = query.get('to_age');
    const gender = query.get('gender');
    const finish_education = query.get('finish_education'); //1:primary, 2:secondary, 3:high school, 4: know read and write, 5:bachelor degree
    const job_id = query.get(`job_id`);
    const company_name = query.get('company_name');
    const page = query.get('page');

    const [candidates, setCandidates] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const dataSource = useMemo(
        () =>
            candidates &&
            candidates.map((candidate, index) => ({
                ...candidate,
                key: Date.now().toString() + index,
                index: (page - 1) * 15 + (index + 1),
                name: candidate.Employee_name,
                gender: getTextDisplay(candidate.Employee_gender, dataType.GENDER),
                phone: candidate.Employee_phone,
                japanese_level: getTextDisplay(candidate?.Employee_japanses_level, dataType.JAPANESE_LEVEL),
                birth_date: candidate?.Employee_birth_date,
                study_level: getTextDisplay(candidate?.Employee_study_level, dataType.STUDY_LEVEL),
            })),
        [candidates, page]
    );

    const columns = [
        { title: '#', dataIndex: 'index', align: 'center', width: '5%' },
        {
            title: 'NAME',
            dataIndex: 'name',
        },
        {
            title: 'GENDER',
            dataIndex: 'gender',
            align: 'center',
            width: '8%',
        },
        {
            title: 'JAPANESE LEVEL',
            dataIndex: 'japanese_level',
            align: 'center',
            width: '14%',
        },
        {
            title: 'STUDY LEVEL',
            dataIndex: 'study_level',
            align: 'center',
            width: '13%',
        },
        {
            title: 'BIRTH DATE',
            dataIndex: 'birth_date',
            align: 'center',
            width: '12%',
            render: (text, row, index) => <span>{text ? moment(text).format("DD-MM-YYYY") : ''}</span>,
        },
        {
            title: 'PHONE',
            dataIndex: 'phone',
            align: 'center',
            width: '10%',
        },
    ];

    const handleRow = (record, rowIndex) => {
        return {
            onClick: (e) => {
                e.stopPropagation();
                history.push(`/candidates/detail/${record.Employee_id}`);
            },
        };
    };

    const handleFetchSuggestedCandidates = useCallback(
        async (page = 1) => {
            setLoading(true);
            const offset = page === 1 ? 0 : (page - 1) * 15;
            try {
                const response = await getSuggestedPostJobEmployees(
                    from_age,
                    to_age,
                    japanese_level,
                    finish_education,
                    gender,
                    job_id,
                    offset
                );
                if (response && response.errorCode !== 0) {
                    setLoading(false);
                    message.error(`Could not get suggested candidates , ${response.message}`);
                    return;
                }
                setCandidates(response && response.data);
                setTotal(response && response?.total);
                setLoading(false);
            } catch (error) {
                message.error(`Couldn't yet suggested candidates.${error?.message}`);
                setLoading(false);
            }
        },
        [from_age, to_age, japanese_level, gender, finish_education, job_id]
    );

    const handleChangePagination = (page) => {
        history.replace(
            `/post-jobs/suggested-candidates?company_name=${company_name}&&gender=${gender}&&from_age=${from_age}&&to_age=${to_age}&&finish_education=${finish_education}&&job_id=${job_id}&&japanese_level=${japanese_level}&&page=${page}`
        );
    };

    useEffect(() => {
        let isSubscribed = true;
        isSubscribed && handleFetchSuggestedCandidates(page);
        return () => {
            isSubscribed = false;
        };
    }, [handleFetchSuggestedCandidates, page]);

    return (
        <Container>
            <h2 style={{ fontWeight: 'bold' }}>Suggested Candidates for {company_name}</h2>
            <h3 style={{ fontWeight: 'bold', marginBottom: '1rem' }}>based on Age, Gender, Japanese Level, and Employee Interested Job</h3>
            <Table
                bordered
                loading={loading}
                dataSource={dataSource}
                columns={columns}
                pagination={{
                    total: total,
                    onChange: handleChangePagination,
                    pageSize: 15,
                    current: Number(page),
                    defaultPageSize: 15,
                }}
                onRow={handleRow}
            />
        </Container>
    );
}
