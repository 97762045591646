import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ReactAudioPlayer from 'react-audio-player';

import { Container, LodoshaButton } from '../../../components/UI/ui.style';

//api
import { getJapaneseDetailByContentId } from '../../../utils/api/E-Learning/japanese';

//ant design
import { Descriptions, Row, Col, Spin, Avatar, message } from 'antd';

//components
import ContentModal from './ContentModal';
import SentenceList from './Sentence/SentenceList';

import NextPrevActionButtonGroup from '../E-LearningComponents/NextPrevActionButtonGroup';

export default function ContentDetail() {
    const { contentid, chapterid } = useParams();
    const [contentDetail, setContentDetail] = useState({});
    const [postData, setPostData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);

    const isSubscribed = useRef(true);

    const handleFetchContentDetail = useCallback(
        async (clickedItemId) => {
            setLoading(true);
            try {
                const response = await getJapaneseDetailByContentId(clickedItemId ? clickedItemId : contentid);
                if (response) {
                    if (response.errorCode !== 0) {
                        setLoading(false);
                        message.error(`Could not get content detail , ${response.message}`);
                        return;
                    }
                    //chapterid is important for file upload,chapterid is required in server
                    setContentDetail({ ...response?.data, chapterId: chapterid });
                    setLoading(false);
                } else {
                    setLoading(false);
                    message.error(`Could not get content detail, Response is undefined`);
                    return;
                }
            } catch (error) {
                setLoading(false);
                message.error(`Could not get content detail.`);
                return;
            }
        },
        [contentid, chapterid]
    );

    const Upsert = useCallback(
        () =>
            updateVisible && (
                <ContentModal
                    visible={updateVisible}
                    postData={postData}
                    setVisible={setUpdateVisible}
                    setPostData={setPostData}
                    isUpdate
                    onRefresh={handleFetchContentDetail}
                    title="Update Content Detail"
                />
            ),
        [updateVisible, postData, handleFetchContentDetail]
    );

    //fetch content detail
    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchContentDetail();
        }
        return () => {
            isSubscribed.current = false;
        };
    }, [handleFetchContentDetail]);

    useEffect(() => {
        setPostData(contentDetail);
    }, [updateVisible, contentDetail]);

    const {
        character,
        word_jp,
        word_kh,
        character_sound,
        word_sound,
        //write_youtube,
        write_gif,
        character_image,
        word_image,
        how_to_read,
        hiragana,
    } = contentDetail;

    return (
        <Container>
            <Spin spinning={loading}>
                <div className="btn-margin-bottom">
                    <NextPrevActionButtonGroup
                        localStorageKey="e-learning-contents"
                        onClickAction={handleFetchContentDetail}
                        currentItemId={contentid}
                    />
                </div>
                <Descriptions bordered column={1} title="Detail" style={{ marginBottom: '1.5rem' }}>
                    <Descriptions.Item label="Character">{character}</Descriptions.Item>
                    <Descriptions.Item label="Character Image">
                        {character_image && (
                            <img
                                src={character_image}
                                alt="character"
                                style={{ objectFit: 'contain', width: '100px', height: '100px' }}
                            />
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Character Sound">
                        {character_sound && <ReactAudioPlayer src={character_sound} autoPlay={false} controls />}
                    </Descriptions.Item>
                    <Descriptions.Item label="Word JP">{word_jp}</Descriptions.Item>
                    <Descriptions.Item label="Word KH">{word_kh}</Descriptions.Item>
                    <Descriptions.Item label="Read (Kanji)">{how_to_read}</Descriptions.Item>
                    <Descriptions.Item label="Hiragana (Kanji)">{hiragana}</Descriptions.Item>
                    <Descriptions.Item label="Word Image">
                        {word_image && (
                            <img
                                src={word_image}
                                alt="word"
                                style={{ objectFit: 'contain', width: '100px', height: '100px' }}
                            />
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Word Sound">
                        {word_sound && <ReactAudioPlayer src={word_sound} controls autoPlay={false} />}
                    </Descriptions.Item>
                    <Descriptions.Item label="Write Gif">
                        {write_gif && <Avatar src={write_gif} shape="square" size={100} />}
                    </Descriptions.Item>
                    <Descriptions.Item label="Write Youtube">
                        {/* <a href={write_youtube} target="_blank" rel="noreferrer">
                            {write_youtube}
                        </a> */}
                    </Descriptions.Item>
                </Descriptions>
            </Spin>

            <div id="sentences">
                <SentenceList />
            </div>

            <Row
                style={{
                    position: 'sticky',
                    bottom: '0',
                    marginTop: '0px',
                    padding: '20px 0',
                    background: '#FAFAFA',
                }}
            >
                <Upsert />
                <Col span={24} style={{ textAlign: 'center' }}>
                    <LodoshaButton type="primary" style={{ marginRight: 16 }} onClick={() => setUpdateVisible(true)}>
                        Update Content
                    </LodoshaButton>
                    <LodoshaButton type="" style={{ marginRight: 16 }}>
                        <a href="#sentences">Jump To Sentences</a>
                    </LodoshaButton>
                </Col>
            </Row>
        </Container>
    );
}
