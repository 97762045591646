import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Descriptions, Row, Col, Button } from 'antd';
import { isUndefined } from 'util';
import Cookies from 'js-cookie';

//components
import { getComponents } from '../../components/Components';

//utils and constants
import getTextDisplay, { dataType } from '../../utils/data';
import ModalCareer from './ModalCareer';

const Detail = (props) => {
    const { detail, setDetail, setCareer } = props;

    const [visibleUpdate, setVisibleUpdate] = useState(false);

    useEffect(() => {
        if (isUndefined(detail.job_title)) {
            const data = JSON.parse(Cookies.get('career_detail'));
            setDetail(data);
        } else {
            Cookies.set('career_detail', JSON.stringify(detail));
        }
        return () => {
            Cookies.remove('career_detail');
        };
    }, [detail, setDetail]);

    useEffect(() => {
        Cookies.set('career_detail', JSON.stringify(detail));
    }, [detail]);

    const items = [
        { type: 'item', label: 'Job Title', name: 'job_title', value: detail ? detail.job_title : '', span: 4 },
        { type: 'item', label: 'Salary From', name: 'salary_from', value: detail ? detail.salary_from : '', span: 2 },
        { type: 'item', label: 'Salary To', name: 'salary_to', value: detail ? detail.salary_to : '', span: 2 },

        { type: 'item', label: 'Hiring', name: 'hiring', value: detail ? detail.hiring : '' },
        { type: 'item', label: 'Post Date', name: 'post_date', value: detail.post_date ? detail.post_date : '' },
        {
            type: 'item',
            label: 'Expiration Date',
            name: 'expiration_date',
            value: detail.expiration_date ? detail.expiration_date : '',
        },
        {
            type: 'item',
            label: 'Job Type',
            name: 'job_type',
            value: detail.job_type ? getTextDisplay(detail.job_type, dataType.JOB_TYPE) : '',
        },
        {
            type: 'item',
            label: 'Job Level',
            name: 'job_level',
            value: detail.job_level ? getTextDisplay(detail.job_level, dataType.JOB_LEVEL) : '',
        },
        {
            type: 'item',
            label: 'Job Description',
            name: 'job_description',
            value: detail ? detail.job_description : '',
            span: 4,
        },
        {
            type: 'item',
            label: 'Job Resposibility',
            name: 'job_resposibility',
            value: detail ? detail.job_resposibility : '',
            span: 4,
        },
        {
            type: 'item',
            label: 'Job Requirement',
            name: 'job_requirement',
            value: detail ? detail.job_requirement : '',
            span: 4,
        },
    ];

    const Update = useCallback(
        () =>
            visibleUpdate && (
                <ModalCareer
                    isUpdate
                    title="Update Career"
                    isVisible={visibleUpdate}
                    setVisible={setVisibleUpdate}
                    data={detail}
                />
            ),
        [visibleUpdate, detail]
    );

    return (
        <div style={{ background: '#fff', padding: 24, minHeight: 380, marginBottom: '24px' }}>
            <Descriptions title="Base Information" bordered column={4}>
                {items.map((data) => getComponents(data))}
            </Descriptions>

            <Row
                type="flex"
                justify="center"
                style={{
                    position: 'sticky',
                    bottom: '0',
                    marginTop: '40px',
                    padding: '20px',
                    background: '#FAFAFA',
                }}
            >
                <Col>
                    <Update />
                    <Button
                        type="primary"
                        style={{ marginRight: 8, height: 38, width: 108 }}
                        onClick={() => {
                            setVisibleUpdate(true);
                            setCareer(detail);
                        }}
                    >
                        Update
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default withRouter(Detail);
