import React from 'react';
import { Switch, Route, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { Tabs } from 'antd';
import { Container } from '../../../components/UI/ui.style';
import CandidateTable from './CandidateTable';
import * as ROUTES from '../../../constants/routes';

import Detail from './Detail';
import Product from './Product';
import FeesTable from './FeesTable';

const { TabPane } = Tabs;

const Partner = () => {
    let history = useHistory();
    let location = useLocation();
    const { path } = useRouteMatch();

    const pathnames = location.pathname.split('/');
    const activeKey = ['product', 'pass-interview', 'fees'].includes(pathnames[3]) ? pathnames[3] : 'detail';

    const handleTabClick = (key, e) => {
        console.log(path);
        e.preventDefault();
        const partnerId = window.localStorage.getItem('partnerId');
        switch (key) {
            case 'detail':
                history.push({ pathname: `${path}/${partnerId}` });
                break;
            case 'product':
                history.push({ pathname: `${path}/${key}/${partnerId}` });
                break;
            case 'pass-interview':
                history.push({ pathname: `${path}/pass-interview/${partnerId}` });
                break;
            case 'fees':
                console.log('this line got called');
                history.push({ pathname: `${path}/fees/${partnerId}` });
                break;
            default:
        }
    };

    return (
        <Container>
            <Tabs type="card" onTabClick={handleTabClick} defaultActiveKey={activeKey}>
                <TabPane tab="Detail" key="detail"></TabPane>
                <TabPane tab="Products" key="product" />
                <TabPane tab="Fees" key="fees" />
                <TabPane tab="Pass Interview" key="pass-interview" />
                {/*<TabPane tab="Application History" key="app-history" /> */}
            </Tabs>

            <Switch>
                <Route exact path={`${ROUTES.PARTNERS_DETAIL_PRODUCT}/:id`}>
                    {/* <CallHistory /> */}
                    <Product />
                </Route>
                <Route exact path={`${path}/pass-interview/:id`}>
                    <CandidateTable />
                </Route>
                <Route exact path={`${path}/:id`}>
                    <Detail />
                </Route>
                <Route exact path={`${path}/fees/:id`}>
                    <FeesTable />
                </Route>
            </Switch>
        </Container>
    );
};

export default Partner;
