import React, { useState, useEffect, useCallback } from 'react';

import { Row, Col } from 'antd';
import { useParams } from 'react-router-dom';
import { InfinityTable as Table } from 'antd-table-infinity';
import { TitleLabel, LoadingTop } from './application-history.style';
import { getApplicationHistory } from '../../../utils/api/candidate';
import { dateDisplay } from '../../../utils';
import getTextDisplay, { dataType } from '../../../utils/data';

const ApplicationHistory = () => {
    let { id: employeeId } = useParams();

    const [loading, setLoading] = useState(false);
    const [dataApplications, setDataApplications] = useState([]);
    const [dataParters, setDataParters] = useState([]);
    const [dataEmployers, setDataEmployers] = useState([]);

    const handleFetchDataHistory = useCallback(async () => {
        setLoading(true);
        const result = await getApplicationHistory(employeeId);
        if (result?.data) {
            console.log(result.data?.applications);
            setDataApplications(
                result.data?.applications?.map((data, index) => {
                    return {
                        no: index + 1,
                        key: index + 1 + '',
                        id: data.id,
                        status: getTextDisplay(data.status, dataType.PARTNER_EMPLOYEE_STATUS),
                        name: data?.post_job?.job_title,
                        created_date: dateDisplay(data?.post_job?.created_at),
                        expiration_date: dateDisplay(data?.post_job?.expiration_date),
                        partner_name: data?.post_job?.partner?.name,
                    };
                })
            );

            setDataParters(
                result.data.partners.map((data, index) => {
                    return {
                        no: index + 1,
                        key: index + 1 + '',
                        id: data.id,
                        status: getTextDisplay(data.status, dataType.PARTNER_EMPLOYEE_STATUS),
                        name: data?.partner?.name,
                        created_date: dateDisplay(data?.partner?.created_at),
                    };
                })
            );

            setDataEmployers(
                result.data.employers.map((data, index) => {
                    return {
                        no: index + 1,
                        key: index + 1 + '',
                        id: data.id,
                        status: getTextDisplay(data.status, dataType.PARTNER_EMPLOYEE_STATUS),
                        name: data?.employer?.company_name,
                        created_date: dateDisplay(data?.employer?.created_at),
                    };
                })
            );
        }
        setLoading(false);
    }, [employeeId]);

    useEffect(() => {
        handleFetchDataHistory();
    }, [handleFetchDataHistory]);

    const columnsApplicationHistory = [
        {
            title: '#',
            dataIndex: 'no',
            width: '6%',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: '42%',
        },
        {
            title: 'Partner',
            dataIndex: 'partner_name',
            width: '27%',
        },
        {
            title: 'Expiration Date',
            dataIndex: 'expiration_date',
            width: '12%',
        },
        {
            title: 'Application Date',
            dataIndex: 'created_date',
            width: '12%',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: '12%',
        },
    ];

    const columnsPartnerHistory = [
        {
            title: '#',
            dataIndex: 'no',
            width: '6%',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: '70%',
        },
        {
            title: 'Add Date',
            dataIndex: 'created_date',
            width: '12%',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: '12%',
        },
    ];

    const columnsEmployerHistory = [
        {
            title: '#',
            dataIndex: 'no',
            width: '6%',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: '70%',
        },
        {
            title: 'Add Date',
            dataIndex: 'created_date',
            width: '12%',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: '12%',
        },
    ];

    console.log(dataApplications);

    return (
        <div>
            <Row>
                <Col span={24}>
                    <div style={{ background: '#F1F1F1', paddingTop: 8, paddingBottom: 8 }}>
                        <TitleLabel>Application History</TitleLabel>
                    </div>
                    <LoadingTop>
                        <Table
                            style={{ marginTop: 20 }}
                            rowKey="key" // Important, otherwise scrolling page will be a proble
                            columns={columnsApplicationHistory}
                            scroll={{ y: window ? (window.innerHeight ? (window.innerHeight * 40) / 100 : 250) : 250 }}
                            dataSource={dataApplications}
                            loading={loading}
                            bordered
                        />
                    </LoadingTop>
                </Col>
                <Col span={24}>
                    <div style={{ background: '#F1F1F1', paddingTop: 8, paddingBottom: 8, marginTop: 16 }}>
                        <TitleLabel>Partner History</TitleLabel>
                    </div>
                    <LoadingTop>
                        <Table
                            style={{ marginTop: 20 }}
                            rowKey="key" // Important, otherwise scrolling page will be a proble
                            columns={columnsPartnerHistory}
                            scroll={{ y: window ? (window.innerHeight ? (window.innerHeight * 40) / 100 : 250) : 250 }}
                            dataSource={dataParters}
                            loading={loading}
                            bordered
                        />
                    </LoadingTop>
                </Col>
                <Col span={24}>
                    <div style={{ background: '#F1F1F1', paddingTop: 8, paddingBottom: 8, marginTop: 16 }}>
                        <TitleLabel>Employer History</TitleLabel>
                    </div>
                    <LoadingTop>
                        <Table
                            style={{ marginTop: 20 }}
                            rowKey="key" // Important, otherwise scrolling page will be a proble
                            columns={columnsEmployerHistory}
                            scroll={{ y: window ? (window.innerHeight ? (window.innerHeight * 40) / 100 : 250) : 250 }}
                            dataSource={dataEmployers}
                            loading={loading}
                            bordered
                        />
                    </LoadingTop>
                </Col>
            </Row>
        </div>
    );
};

export default ApplicationHistory;
