import React, { useState } from 'react';

//antd
import { Modal, Form, Row, Col, Upload, message, Input } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

//components
import { LodoshaButton } from '../../../../components/UI/ui.style';
import ModalWrapper from '../../E-LearningComponents/modal.style';

//utils and constants
import { postNewSentence, updateSentence } from '../../../../utils/api/E-Learning/sentence';

export default function SentenceModal({
    isUpdate,
    isCreate,
    visible,
    setVisible,
    title,
    postData,
    setPostData,
    onRefresh,
}) {
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const { Dragger } = Upload;
    const [data, setData] = useState(postData);
    const [loading, setLoading] = useState(false);

    const [sentenceSound, setSentenceSound] = useState([]);

    const sentenceFormFields = [
        {
            type: 'text',
            label: 'Khmer',
            name: 'khmer',
            value: data?.khmer,
            span: 24,
            required: true,
        },
        {
            type: 'text',
            label: 'Japan',
            name: 'japan',
            value: data?.japan,
            span: 24,
            required: true,
        },
    ];

    const handleSubmit = async (value) => {
        try {
            setLoading(true);
            const response = isCreate
                ? await postNewSentence(data, sentenceSound)
                : isUpdate
                ? await updateSentence(data, sentenceSound)
                : {};
            if (response && response.errorCode !== 0) {
                setLoading(false);
                console.log(response);
                message.error(`Oh oh, something went wrong, ${response.message}`);
                return;
            }

            if (response) {
                response &&
                    message.success(isCreate ? 'New sentence added.' : isUpdate ? 'Successfully updated sentence' : '');
                setLoading(false);
                onRefresh();
                handleCancel();
            } else {
                setLoading(false);
                message.error(`Uh oh, ${isCreate ? 'add new sentence' : isUpdate ? 'update sentence' : ''} failed!`);
            }
        } catch (error) {
            setLoading(false);
            message.error(`Uh oh, ${isCreate ? 'add new sentence' : isUpdate ? 'update sentence' : ''} failed!`);
        }
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        setData((prev) => ({ ...prev, [name]: value }));
    };

    const handleChangeFile = (info, fieldname) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        fileList = fileList.map((file) => {
            if (file.response) {
                file.url = file.response.url;
            }
            return file;
        });
        //important when uploading to server
        if (fileList.length > 0) {
            fileList[0].originFileObj.fieldname = fieldname;
        }

        setSentenceSound(fileList);
    };

    const handleCancel = () => {
        setPostData({});
        setVisible(false);
    };

    const beforeUpload = () => {
        return false;
    };

    return (
        <Modal visible={visible} onCancel={handleCancel} title={title} maskClosable={false} footer={false} centered>
            <ModalWrapper>
                <Form form={form} onFinish={handleSubmit} layout="vertical">
                    <Row gutter={24}>
                        {sentenceFormFields?.map((field, index) => {
                            const { type, label, name, value, required, disabled, span } = field;
                            return (
                                <Col span={span} key={index}>
                                    <Form.Item
                                        rules={[{ required: required, message: 'Data required!' }]}
                                        label={`${label}:`}
                                        name={name}
                                        initialValue={value}
                                    >
                                        <TextArea
                                            type={type}
                                            disabled={disabled && true}
                                            onChange={handleChange}
                                            value={value}
                                            name={name}
                                            rows={4}
                                        />
                                    </Form.Item>
                                </Col>
                            );
                        })}
                        <Col span={24}>
                            {/* sound */}
                            <Form.Item label={<h4 style={{ fontWeight: 'bold', marginBottom: '0' }}>Sound</h4>}>
                                <Dragger
                                    name="sentence-voice"
                                    beforeUpload={beforeUpload}
                                    fileList={sentenceSound}
                                    onChange={(info) => handleChangeFile(info, 'sentence-voice')}
                                    accept="audio/mp3"
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                </Dragger>
                            </Form.Item>
                        </Col>

                        {/* *********** */}
                        <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
                            <LodoshaButton
                                key="cancel"
                                style={{ marginRight: 16 }}
                                htmlType="button"
                                onClick={handleCancel}
                            >
                                Cancel
                            </LodoshaButton>
                            <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                                {isCreate ? 'Add New' : isUpdate ? 'Update' : ''}
                            </LodoshaButton>
                        </Col>
                    </Row>
                </Form>
            </ModalWrapper>
        </Modal>
    );
}
