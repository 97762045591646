import 'react-quill/dist/quill.snow.css';

import ReactQuill from 'react-quill';
import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { Modal, Form, Row, Col, Space, message } from 'antd';
import { useParams } from 'react-router-dom';
import { UpsertContainer } from './blog.style';
import { LodoshaInput, LodoshaButton } from '../../../../components/UI/ui.style';
import { isNullOrUndefined, loadImageMiddleware } from '../../../../utils';
import { uploadCultureImage, deleteCultureImages } from '../../../../utils/api/E-Learning/elearning-media';
import { postNewCultureDetail, updateCultureDetail } from '../../../../utils/api/E-Learning/culture';
import BannerUploader from '../../E-LearningComponents/BannerUploader';

const CultureBlogModal = ({ visible, setVisible, onRefresh, blog, setBlog }) => {
    const [form] = Form.useForm();
    const { cultureid } = useParams();
    const [loading, setLoading] = useState(false);
    const [originalImages, setOriginalImages] = useState([]);
    const [images, setImages] = useState([]);
    const [file, setFile] = useState();

    const quillRef = useRef(null);

    useEffect(() => {
        form.setFieldsValue({
            title: blog ? blog?.title || '' : '',
            content: blog ? blog?.content || '' : '',
            youtube_key: blog ? blog?.youtube_key || '' : '',
        });

        if (blog) {
            setImages(getImageIds(blog?.content));
            setOriginalImages(getImageIds(blog?.content));
        }
    }, [form, blog]);

    const handleCancel = () => {
        setVisible(false);
        form.resetFields();
        setBlog({});
    };

    const handleSubmit = async (value) => {
        if (blog) {
            blog.title = value.title;
            blog.content = value.content;
            if (value.youtube_key) {
                blog.youtube_key = value.youtube_key;
            }
            blog.culture_id = cultureid;
        }

        const formData = new FormData();

        for (const key in blog) {
            if (blog[key]) {
                formData.append(key, blog[key]);
            }
        }

        if (file) {
            formData.append('image', file);
        }

        setLoading(true);
        if (blog && blog.id) {
            updateCultureDetail(blog.id, formData)
                .then((response) => {
                    setLoading(false);
                    console.log(response);
                    if (response && response.errorCode === 0) {
                        message.success(`Successfully updated culture blog`);
                        removeImageFromServer();
                        handleCancel();
                        onRefresh();
                    } else {
                        message.error(`Update culture blog failed! ${response && response.message}`);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            postNewCultureDetail(formData).then((response) => {
                setLoading(false);
                if (response && response.errorCode === 0) {
                    message.success(`Successfully created culture blog`);
                    removeImageFromServer();
                    onRefresh();
                    handleCancel();
                } else {
                    message.error(`Create culture blog failed! ${response && response.message}`);
                }
            });
        }
    };

    const removeImageFromServer = (isCancel = false) => {
        var results = [];

        if (isCancel) {
            results = images.filter((id1) => !originalImages.some((id2) => id2 === id1));
        } else {
            const text = quillRef.current.getEditor().root.innerHTML;
            const currentIds = getImageIds(text);
            results = images.filter((id1) => !currentIds.some((id2) => id2 === id1));
        }
        if (results && results.length > 0) {
            deleteCultureImages(results.join(','));
        }
    };

    const imageHandler = useCallback(
        async (info) => {
            const input = document.createElement('input');

            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.click();

            input.onchange = async function () {
                const quill = quillRef.current.getEditor();
                const file = input.files[0];

                const formData = new FormData();
                formData.append('culture_id', cultureid);
                formData.append('image', file);

                const response = await uploadCultureImage(formData);
                const range = quill.getSelection();

                if (response?.errorCode === 0) {
                    const imageId = response.data.id;
                    setImages((images) => [...images, imageId]);

                    const link = loadImageMiddleware(imageId);
                    quill.insertEmbed(range.index, 'image', link);
                    quill.setSelection(range.index + 1);
                }
            }; //.bind(this);
        },
        [cultureid]
    );

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [{ header: '1' }, { header: '2' }, { font: [] }],
                    [{ size: [] }],
                    [{ color: [] }, { background: [] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
                    [{ align: [] }],
                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                    ['link', 'image', 'video'],
                    ['clean'],
                ],
                handlers: {
                    image: imageHandler,
                },
            },
            clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false,
            },
        };
    }, [imageHandler]);

    const formats = useMemo(() => {
        return [
            'header',
            'font',
            'size',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'indent',
            'link',
            'image',
            'video',
            'color',
            'background',
            'code-block',
            'align',
        ];
    }, []);

    const getImageIds = (str) => {
        if (isNullOrUndefined(str)) {
            return [];
        }

        var retArray = [];
        var regexPattern = /src="([^"]+)/gi;
        var matches = str.match(regexPattern);
        if (matches) {
            for (var i = 0; i < matches.length; i++) {
                var match = matches[i].replace('src="', '');
                var id = match.substring(match.lastIndexOf('/') + 1);
                retArray.push(id);
            }
        } else {
            return [];
        }
        return retArray;
    };

    return (
        <Modal
            centered
            visible={visible}
            maskClosable={false}
            title={blog && blog.id ? 'Update Blog' : 'Create Blog'}
            onCancel={() => {
                removeImageFromServer(true);
                handleCancel();
            }}
            width={1000}
            bodyStyle={{ height: '100%' }}
            footer={null}
        >
            <UpsertContainer>
                <Form form={form} layout="vertical" onFinish={handleSubmit}>
                    <Row style={{ marginBottom: '2rem' }}>
                        <Col span={12}>
                            <Row style={{ flexDirection: 'row', alignContent: 'space-between', height: '100%' }}>
                                <Col span={24}>
                                    <Form.Item
                                        label="Title"
                                        name="title"
                                        rules={[{ required: true, message: 'Data required!' }]}
                                        style={{ width: '100%', margin: 0 }}
                                    >
                                        <LodoshaInput />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="Youtube key"
                                        name="youtube_key"
                                        style={{ width: '100%', margin: 0 }}
                                    >
                                        <LodoshaInput />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={10} push={2}>
                            <Form.Item label="Banner Image" style={{ margin: 0 }}>
                                <Row justify="center">
                                    <BannerUploader setFile={setFile} imageId={blog && blog.image_id} />
                                </Row>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        style={{ margin: 0 }}
                        label="Content"
                        name="content"
                        rules={[{ required: true, message: 'Data required!' }]}
                    >
                        <ReactQuill theme="snow" ref={quillRef} modules={modules} formats={formats} />
                    </Form.Item>
                    <Form.Item>
                        <Row justify="center" style={{ marginTop: 20 }}>
                            <Space direction="horizontal" size="middle">
                                <LodoshaButton
                                    key="cancel"
                                    htmlType="button"
                                    onClick={() => {
                                        removeImageFromServer(true);
                                        handleCancel();
                                    }}
                                >
                                    Cancel
                                </LodoshaButton>
                                <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                                    Save
                                </LodoshaButton>
                            </Space>
                        </Row>
                    </Form.Item>
                </Form>
            </UpsertContainer>
        </Modal>
    );
};

export default CultureBlogModal;
