import React, { useEffect, useCallback, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

//antd
import { message, Space, Button, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

//components
import { Container, LodoshaButton } from '../../../../components/UI/ui.style';
import SortableTable from '../../E-LearningComponents/SortableTable';
import DragHandler from '../../E-LearningComponents/DragHandler';
import ExerciseModal from './ExerciseModal';

import { deleteQuestion, updateQuestionOrder } from '../../../../utils/api/E-Learning/question';
import { getJLPTLessonExercises } from '../../../../utils/api/E-Learning/jlpt';
import { getFromLocalStoage, getNewLastOrder } from '../../../../utils';
import { ExternalTypes } from '../../../../utils/data';

export default function ExerciseList({ test }) {
    const [exercises, setExercises] = useState([]);
    const [postData, setPostData] = useState({});
    const [visible, setVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const { lessonid } = useParams();

    const isSubscribed = useRef(true);

    const handleFetchLessonExercises = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getJLPTLessonExercises(lessonid);
            if (response) {
                if (response.errorCode !== 0) {
                    message.error(`Uh oh,couldn't yet lesson exercises. Please try again later.${response.message}`);
                    setLoading(false);
                    return;
                }

                setExercises(response ? response?.data?.questions : []);
                setLoading(false);
                return;
            } else {
                message.error(`Uh oh,couldn't yet lesson exercises. Resposne is undefined`);
                setLoading(false);
                return;
            }
        } catch (error) {
            message.error(`Uh oh,couldn't yet lesson exercises. Please try again later.${error.message}`);
            setLoading(false);
        }
    }, [lessonid]);

    const data = useMemo(
        () =>
            exercises &&
            exercises.map((exercise) => ({
                ...exercise,
                key: exercise.id,
                title: exercise.title,
                answer: exercise.answer,
                choice_one: exercise.choice_one,
                choice_two: exercise.choice_two,
                choice_three: exercise.choice_three,
                choice_four: exercise.choice_four,
            })),
        [exercises]
    );

    const columns = [
        { title: '#', dataIndex: 'no', width: '5%', align: 'center', fixed: 'left', render: () => <DragHandler /> },
        { title: 'Title', dataIndex: 'title', fixed: 'left', width: '20%' },
        { title: 'Choice One', dataIndex: 'choice_one', width: '13%', align: 'center' },
        { title: 'Choice Two', dataIndex: 'choice_two', width: '13%', align: 'center' },
        { title: 'Choice Three', dataIndex: 'choice_three', width: '13%', align: 'center' },
        { title: 'Choice Four', dataIndex: 'choice_four', width: '13%', align: 'center' },
        { title: 'Answer', dataIndex: 'answer', width: '13%', align: 'center' },
        {
            title: '',
            dataIndex: 'actions',
            align: 'center',
            width: '7%',
            fixed: 'right',
            render: (text, row, index) => (
                <Space size="small" direction="horizontal">
                    <Button title="Edit" type="primary" icon={<EditOutlined />} onClick={(e) => handleEdit(e, row)} />
                    <Button
                        title="Delete"
                        type="danger"
                        icon={<DeleteOutlined />}
                        onClick={(e) => handleDelete(e, row.id)}
                    />
                </Space>
            ),
        },
    ];

    const Upsert = useCallback(
        () =>
            visible && (
                <ExerciseModal
                    isCreate
                    onRefresh={handleFetchLessonExercises}
                    title="Add New Exercise."
                    visible={visible}
                    setVisible={setVisible}
                    postData={postData}
                    setPostData={setPostData}
                />
            ),
        [visible, handleFetchLessonExercises, postData]
    );

    const Update = useCallback(
        () =>
            updateVisible && (
                <ExerciseModal
                    isUpdate
                    onRefresh={handleFetchLessonExercises}
                    title="Update Exercise."
                    visible={updateVisible}
                    setVisible={setUpdateVisible}
                    postData={postData}
                    setPostData={setPostData}
                />
            ),
        [postData, updateVisible, handleFetchLessonExercises]
    );

    const handleEdit = (e, row) => {
        e.stopPropagation();
        setPostData(row);
        setUpdateVisible(true);
    };

    const handleDelete = (e, id) => {
        e.stopPropagation();
        Modal.confirm({
            title: 'Are you sure you want to delete this question?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await deleteQuestion(id);

                    if (response && response.errorCode === 0) {
                        message.success(`Deleted successfully!`);
                        handleFetchLessonExercises();
                    } else {
                        message.error(`Couldn't delete JLPT exercise, ${response.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request failed!`);
                }
            },
            onCancel() {},
        });
    };

    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchLessonExercises();
        }
        return () => (isSubscribed.current = false);
    }, [handleFetchLessonExercises, lessonid]);

    useEffect(() => {
        return () => (isSubscribed.current = true);
    }, [lessonid]);

    useEffect(() => {
        const selectedLevel = getFromLocalStoage('selected_level');
        let lastOrder = getNewLastOrder(exercises);
        setPostData((prev) => ({
            ...prev,
            order: lastOrder,
            lesson_id: lessonid,
            external_type: ExternalTypes.LESSON_QUESTION,
            level: selectedLevel,
        }));
    }, [exercises, lessonid, visible]);

    return (
        <Container id="exercise-table">
            <Space align="center" className="new-sentence-btn">
                <LodoshaButton onClick={() => setVisible(true)} type="primary">
                    <PlusOutlined />
                    Add Exercise
                </LodoshaButton>
                <a href="#lesson-table">
                    <LodoshaButton type="dashed">Back to Contents</LodoshaButton>
                </a>
            </Space>
            <Upsert />
            <Update />
            <SortableTable
                data={data}
                columns={columns}
                scrollX={1440}
                loading={loading}
                setLoading={setLoading}
                onUpdateOrder={updateQuestionOrder}
                size="small"
            />
        </Container>
    );
}
