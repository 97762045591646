import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container, LodoshaButton } from '../../../components/UI/ui.style';

//ant design
import { message, Space, Button, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

//api
import {
    deleteJapaneseContent,
    getContentsByLessonID,
    updateJapaneseContentOrder,
} from '../../../utils/api/E-Learning/japanese';

//constants and utils
import { ELEARNING } from '../../../constants/routes';
import { getELearningItems } from '../../../utils';

//components
import ContentModal from './ContentModal';
import DragHandler from '../E-LearningComponents/DragHandler';
import SortableTable from '../E-LearningComponents/SortableTable';
import NextPrevActionButtonGroup from '../E-LearningComponents/NextPrevActionButtonGroup';

const ContentList = (props) => {
    const { chapterid, menuid, lessonid } = useParams();
    const history = useHistory();

    const isSubscribed = useRef(true);

    const [loading, setLoading] = useState(false);
    const [lessonContents, setLessonContents] = useState([]);
    const [postData, setPostData] = useState();
    const [visible, setVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);

    const columns = [
        { title: '#', dataIndex: 'no', width: '5%', align: 'center', fixed: 'left', render: () => <DragHandler /> },
        { title: 'CHARACTER', dataIndex: 'item_character', width: '15%', align: 'center', fixed: 'left' },
        { title: 'WORD_JP', dataIndex: 'item_wordjp' },
        { title: 'WORD_KH', dataIndex: 'item_wordkh' },
        { title: 'READ (Kanji)', dataIndex: 'item_howToRead' },
        { title: 'HIRAGANA (Kanji)', dataIndex: 'item_hiragana' },
        {
            title: 'ACTIONS',
            width: '10%',
            dataIndex: 'item_action',
            align: 'center',
            fixed: 'right',
            render: (text, row, index) => (
                <Space>
                    <Button type="primary" title="Edit" onClick={(e) => handleEdit(e, row)} icon={<EditOutlined />} />
                    <Button
                        type="danger"
                        title="Delete"
                        onClick={(e) => handleDelete(e, row.id)}
                        icon={<DeleteOutlined />}
                    />
                </Space>
            ),
        },
    ];

    const data = useMemo(
        () =>
            lessonContents &&
            lessonContents
                .sort((a, b) => a.order - b.order)
                .map((item, index) => ({
                    ...item,
                    key: item.id,
                    no: index + 1,
                    item_character: item.character,
                    item_wordkh: item.word_kh,
                    item_wordjp: item.word_jp,
                    item_howToRead: item.how_to_read,
                    item_hiragana: item.hiragana,
                })),
        [lessonContents]
    );

    const handleFetchContents = useCallback(
        async (clickedItemId) => {
            try {
                setLoading(true);
                const response = await getContentsByLessonID(clickedItemId ? clickedItemId : lessonid);
                if (response) {
                    if (response.errorCode !== 0) {
                        setLoading(false);
                        message.error(`Could not get contents , ${response.message}`);
                        return;
                    }
                    setLessonContents(response && response.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                    message.error(`Could not get contents, Response is undefined`);
                    return;
                }
            } catch (error) {
                message.error(`Something went wrong.${error}`);
                setLoading(false);
            }
        },
        [lessonid]
    );

    const handleRow = (record, index) => {
        return {
            onClick: (e) => {
                e.preventDefault();

                //save details to localstorage
                try {
                    const eLearningItems = getELearningItems(lessonContents, 'character');
                    localStorage.setItem('e-learning-contents', JSON.stringify(eLearningItems));
                } catch (error) {
                    message.error('Set to local storage failed');
                }

                history.push(`${ELEARNING}/${menuid}/${chapterid}/${lessonid}/${record.id}`);
            },
        };
    };

    const handleEdit = (e, row) => {
        e.stopPropagation();
        setUpdateVisible(true);
        //chapterId for file path
        setPostData(() => ({ ...row, chapterId: chapterid }));
    };

    const handleDelete = (e, id) => {
        e.stopPropagation();
        Modal.confirm({
            title: 'Are you sure you want to delete this content?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await deleteJapaneseContent(id);
                    if (response && response.errorCode === 0) {
                        message.success(`Deleted successfully!`);
                        handleFetchContents();
                    } else {
                        message.error(`Couldn't delete content, ${response.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request failed!`, error);
                }
            },
            onCancel() {},
        });
    };

    const Upsert = useCallback(
        () =>
            visible && (
                <ContentModal
                    title="Add New Content"
                    visible={visible}
                    setVisible={setVisible}
                    postData={postData}
                    setPostData={setPostData}
                    onRefresh={handleFetchContents}
                    isCreate={true}
                />
            ),
        [visible, handleFetchContents, postData]
    );

    const Update = useCallback(() => {
        return (
            updateVisible && (
                <ContentModal
                    title="Update Content"
                    visible={updateVisible}
                    setVisible={setUpdateVisible}
                    onRefresh={handleFetchContents}
                    setPostData={setPostData}
                    postData={postData}
                    isUpdate={true}
                />
            )
        );
    }, [updateVisible, handleFetchContents, postData]);

    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchContents();
        }
        return () => {
            isSubscribed.current = false;
        };
    }, [lessonid, handleFetchContents]);

    //auto content order
    useEffect(() => {
        let lastOrder = (lessonContents && lessonContents.length > 0 ? lessonContents.length + 1 : 1) * 100;
        setPostData((prev) => ({ ...prev, order: lastOrder, lesson_id: lessonid, chapterId: chapterid }));
    }, [visible, lessonContents, lessonid, chapterid]);

    return (
        <Container>
            <div className="btn-margin-bottom">
                <NextPrevActionButtonGroup
                    currentItemId={lessonid}
                    onClickAction={handleFetchContents}
                    localStorageKey="e-learning-lessons"
                />
            </div>

            <LodoshaButton type="primary" className="btn-margin-bottom" onClick={() => setVisible(true)}>
                <PlusOutlined />
                Add New
            </LodoshaButton>
            <SortableTable
                columns={columns}
                data={data}
                handleRow={handleRow}
                setLoading={setLoading}
                onUpdateOrder={updateJapaneseContentOrder}
                loading={loading}
                scrollX={1280}
            />
            <Upsert />
            <Update />
        </Container>
    );
};

export default ContentList;
