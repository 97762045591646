import API from '../api';

export const getAutoDescriptions = () => {
    return API()
        .get('/auto-description')
        .then((result) => result.data)
        .catch((err) => {
            console.log('getAutoDescriptions err:', err);
            return err;
        });
};

export const getAutoDescriptionsByType = (type) => {
    return API()
        .get(`/auto-description/${type}`)
        .then((result) => result.data)
        .catch((err) => {
            console.log('getAutoDescriptionsByType err:', err);
            return err;
        });
};

export const createAutoDescription = (data) => {
    return API()
        .post(`/auto-description`, data)
        .then((response) => response.data)
        .catch((err) => {
            console.log('createAutoDescription err:', err);
            return err;
        });
};

export const updateAutoDescription = (data) => {
    return API()
        .patch(`/auto-description/${data.id}`, data)
        .then((response) => response.data)
        .catch((err) => {
            console.log('updateAutoDescription err:', err);
            return err;
        });
};

export const deleteAutoDescription = (id) => {
    return API()
        .delete(`/auto-description/${id}`)
        .then((response) => response.data)
        .catch((err) => {
            console.log('deleteAutoDescription err:', err);
            return err;
        });
};
