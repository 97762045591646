import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Row, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import queryString from 'query-string';

//components
import { Container, LodoshaButton } from '../../components/UI/ui.style';
import Search from './Search';
import Table from './Table';
import ModalUpsert from './ModalUpsert';
import ModalTranslate from './ModalTranslate';
import ModalStatus from '../../components/ModalStatus';

//utils and constants
import { dataPostStatus } from '../../utils/data';
import { getPostJobs } from '../../utils/api/post_job';
import API from '../../utils/api';

const timeout_typing_state = { typingTimeout: 0 };

const PostJobsPage = () => {
    const location = useLocation();
    const history = useHistory();

    const page = location.search ? Number(queryString.parse(location.search).page) : 1;
    const qKeyword = location.search ? queryString.parse(location.search).keyword : '';
    const qJobId = location.search ? queryString.parse(location.search).jobId : '';

    const [loading, setLoading] = useState(false);
    const [postJobs, setPostJobs] = useState([]);
    const [postJob, setPostJob] = useState();
    const [visibleUpsert, setVisibleUpsert] = useState(false);
    const [visibleTranslate, setVisibleTranslate] = useState(false);
    const [visibleStatus, setVisibleStatus] = useState(false);
    const [timeoutTyping, setTimeoutTyping] = useState(timeout_typing_state);

    const [keyword, setKeyword] = useState(qKeyword);
    const [jobId, setJobId] = useState(qJobId);
    const [total, setTotal] = useState(0);

    const isSubscribed = useRef(true);

    const handleFetchPostJobs = useCallback(async (page = 1, keyWord, jobId, order = '', field = '') => {
        setLoading(true);

        try {
            const response = await getPostJobs(page, keyWord, jobId, order, field);
            setPostJobs(response.data);
            setTotal(response.total);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error(`Uh oh, request failed!`);
        }
    }, []);

    const initQueryURL = useCallback(
        (name, value, page = 1) => {
            let currentQuery = {};
            currentQuery['page'] = page;

            if (keyword) {
                currentQuery['keyword'] = keyword;
            }

            if (jobId) {
                currentQuery['job'] = jobId;
            }

            if (value) {
                currentQuery[name] = value;
            } else {
                delete currentQuery[name];
            }

            let endPoint = history.location;
            endPoint.search = queryString.stringify(currentQuery);
            history.replace(endPoint);
        },
        [history, jobId, keyword]
    );

    useEffect(() => {
        initQueryURL('', '', page);
    }, [page, initQueryURL]);

    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchPostJobs(page, qKeyword, qJobId);
        }
        return () => {
            isSubscribed.current = false;
        };
    }, [page, qKeyword, qJobId, handleFetchPostJobs]);

    const handleSearch = (value) => {
        setKeyword(value);

        if (timeoutTyping.typingTimeout) {
            clearTimeout(timeoutTyping.typingTimeout);
        }

        setTimeoutTyping({
            typingTimeout: setTimeout(function () {
                handleFetchPostJobs(1, value, jobId);
            }, 1000),
        });
    };

    const handleFilter = (name, value) => {
        switch (name) {
            case 'job':
                setJobId(value);
                handleFetchPostJobs(1, keyword, value);
                break;
            default:
                break;
        }
        initQueryURL(name, value);
    };

    const handleSaveStatus = useCallback(
        async (status, text) => {
            // console.log(status, text);
            const response = await API().patch(`/postjob/status/${postJob.id}`, { status, reason: text });
            if (response.data.errorCode === 0) {
                message.success('Saved successfully!');
            } else {
                message.error(`Failed, ${response.data.message}`);
            }
            setVisibleStatus(false);
            handleFetchPostJobs();
        },
        [postJob, handleFetchPostJobs]
    );

    const Upsert = useCallback(
        () =>
            visibleUpsert && (
                <ModalUpsert
                    visible={visibleUpsert}
                    setVisible={setVisibleUpsert}
                    page={page}
                    postJob={postJob}
                    setPostJob={setPostJob}
                    onRefresh={handleFetchPostJobs}
                />
            ),
        [visibleUpsert, postJob, handleFetchPostJobs, page]
    );

    const Translate = useCallback(
        () =>
            visibleTranslate && (
                <ModalTranslate
                    visible={visibleTranslate}
                    setVisible={setVisibleTranslate}
                    postJob={postJob}
                    setPostJob={setPostJob}
                />
            ),
        [visibleTranslate, postJob]
    );

    const Status = useCallback(
        () =>
            visibleStatus && (
                <ModalStatus
                    textarea
                    visible={visibleStatus}
                    setVisible={setVisibleStatus}
                    steps={dataPostStatus}
                    status={postJob.status}
                    rejection={postJob.reject_reason}
                    onSave={handleSaveStatus}
                />
            ),
        [visibleStatus, postJob, handleSaveStatus]
    );

    return (
        <Container>
            <Search keyword={keyword} jobId={jobId} onSearch={handleSearch} onFilter={handleFilter} />
            <Row style={{ marginTop: 24, marginBottom: 32 }}>
                <LodoshaButton type="primary" icon={<PlusOutlined />} onClick={() => setVisibleUpsert(true)}>
                    New
                </LodoshaButton>
                <Upsert />
            </Row>
            <Translate />
            <Status />
            <Table
                loading={loading}
                page={page}
                total={total}
                postJobs={postJobs}
                setPostJob={setPostJob}
                setVisibleUpsert={setVisibleUpsert}
                setVisibleTranslate={setVisibleTranslate}
                setVisibleStatus={setVisibleStatus}
                handleFetchPostJobs={handleFetchPostJobs}
                keyword={keyword}
                jobId={jobId}
            />
        </Container>
    );
};

export default PostJobsPage;
