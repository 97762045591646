import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router';
import HtmlParser from 'html-react-parser';

//antd
import { message, Space, Button, Modal } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

//utils and constant
import {
    getJLPTContentsByLessonID,
    updateJLPTContentOrder,
    deleteJLPTContent,
} from '../../../utils/api/E-Learning/jlpt';
import { ELEARNING } from '../../../constants/routes';
import { getELearningItems, getNewLastOrder } from '../../../utils';

//componetns
import { Container, LodoshaButton } from '../../../components/UI/ui.style';
import SortableTable from '../E-LearningComponents/SortableTable';
import DragHandler from '../E-LearningComponents/DragHandler';
import ContentModal from './ContentModal';
import ExerciseList from './Exercise/ExerciseList';
import NextPrevActionButtonGroup from '../E-LearningComponents/NextPrevActionButtonGroup';

export default function ContentList() {
    const [visible, setVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [postData, setPostData] = useState({});
    const [contents, setContents] = useState([]);

    const { menuid, lessonid, chapterid } = useParams();

    const history = useHistory();

    const handleFetchJLPTContents = useCallback(
        async (clickedItemId) => {
            try {
                setLoading(true);
                const response = await getJLPTContentsByLessonID(clickedItemId ? clickedItemId : lessonid);
                if (response) {
                    if (response.errorCode !== 0) {
                        setLoading(false);
                        message.error(`Couldn't get JLPT lessons.${response.errorCode}`);
                        return;
                    }

                    setContents(response ? response.data : []);
                    setLoading(false);
                    return;
                } else {
                    setLoading(false);
                    message.error(`Couldn't get JLPT lessons. Response is undefined`);
                    return;
                }
            } catch (error) {
                message.error("Couldn't get JLPT lessons.");
                setLoading(false);
            }
        },
        [lessonid]
    );

    const columns = [
        { title: '', dataIndex: 'no', align: 'center', width: '5%', fixed: 'left', render: () => <DragHandler /> },
        {
            title: 'JAPANESE',
            dataIndex: 'japanese',
            fixed: 'left',
            width: '25%',
        },
        { title: 'KHMER', dataIndex: 'khmer', width: '25%' },
        {
            title: 'EXPLANATION',
            dataIndex: 'explanation_furigana',
            width: '30%',
            render: (text, item, index) => <span>{HtmlParser(text)}</span>,
        },
        {
            title: 'ACTIONS',
            dataIndex: 'actions',
            align: 'center',
            width: '15%',
            fixed: 'right',
            render: (text, row, index) => (
                <Space>
                    <Button type="primary" title="Edit" onClick={(e) => handleEdit(e, row)} icon={<EditOutlined />} />
                    <Button
                        type="danger"
                        title="Delete"
                        onClick={(e) => handleDelete(e, row.id)}
                        icon={<DeleteOutlined />}
                    />
                </Space>
            ),
        },
    ];

    const data = useMemo(
        () =>
            contents &&
            contents.map((content, index) => ({
                ...content,
                key: index,
                explanation_furigana: content?.explanation,
                japanese: content.japanese,
                khmer: content.khmer,
                kh_example: content.kh_example,
                order: content.order,
            })),
        [contents]
    );

    const Upsert = useCallback(
        () =>
            visible && (
                <ContentModal
                    title="Add New JLPT Content"
                    onRefresh={handleFetchJLPTContents}
                    isCreate
                    visible={visible}
                    setPostData={setPostData}
                    postData={postData}
                    setVisible={setVisible}
                />
            ),
        [visible, postData, handleFetchJLPTContents]
    );

    const Update = useCallback(
        () =>
            updateVisible && (
                <ContentModal
                    title="Update JLPT Content"
                    onRefresh={handleFetchJLPTContents}
                    isUpdate
                    visible={updateVisible}
                    setPostData={setPostData}
                    postData={postData}
                    setVisible={setUpdateVisible}
                />
            ),
        [updateVisible, postData, handleFetchJLPTContents]
    );

    const handleDelete = (e, id) => {
        e.stopPropagation();
        Modal.confirm({
            title: 'Are you sure you want to delete this post?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await deleteJLPTContent(id);
                    if (response && response.errorCode === 0) {
                        message.success(`Deleted successfully!`);
                        handleFetchJLPTContents();
                    } else {
                        message.error(`Couldn't delete content. ${response.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request failed!`);
                }
            },
            onCancel() {},
        });
    };

    const handleEdit = (e, row) => {
        e.stopPropagation();
        setUpdateVisible(true);
        setPostData(row);
    };

    const handleRow = (record, index) => {
        return {
            onClick: (e) => {
                e.stopPropagation();
                //set contents to localstorage
                try {
                    const eLearningItems = getELearningItems(contents, 'khmer');
                    localStorage.setItem('e-learning-contents', JSON.stringify(eLearningItems));
                } catch (error) {
                    message.error('Set to local storage failed');
                }

                return history.push(`${ELEARNING}/${menuid}/${chapterid}/${lessonid}/${record.id}`);
            },
        };
    };

    //auto order
    useEffect(() => {
        let lastOrder = getNewLastOrder(contents);
        setPostData((prev) => ({ ...prev, order: lastOrder, type: 7, lesson_id: lessonid }));
    }, [visible, contents, lessonid]);

    useEffect(() => {
        handleFetchJLPTContents();
    }, [handleFetchJLPTContents]);

    return (
        <React.Fragment>
            <Container id="lesson-table">
                <div className="btn-margin-bottom">
                    <NextPrevActionButtonGroup
                        onClickAction={handleFetchJLPTContents}
                        currentItemId={lessonid}
                        localStorageKey="e-learning-lessons"
                    />
                </div>
                <Space className="new-sentence-btn" align="center">
                    <LodoshaButton type="primary" onClick={() => setVisible(true)}>
                        <PlusOutlined />
                        Add Content
                    </LodoshaButton>
                    <a href="#exercise-table">
                        <LodoshaButton type="dashed">Jump to Exercises</LodoshaButton>
                    </a>
                </Space>
                <Upsert />
                <Update />
                <SortableTable
                    data={data}
                    columns={columns}
                    handleRow={handleRow}
                    loading={loading}
                    onUpdateOrder={updateJLPTContentOrder}
                    setLoading={setLoading}
                />
            </Container>
            <ExerciseList test={lessonid} />
        </React.Fragment>
    );
}
