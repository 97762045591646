import React, { useState } from 'react';
import { Row, Col, Modal, Form, Select, Space, Upload, message, Switch } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import API from '../../utils/api';
import { dataGender, dataCountry, dataIndustry } from '../../utils/data';
import { LodoshaInput, LodoshaButton, LodoshaTextArea, LodoshaSelect } from '../../components/UI/ui.style';

const { Option } = Select;
const { Dragger } = Upload;

const ModalUpsert = ({ visible, setVisible, employer, setEmployer, setEmployers, onRefresh, page }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(employer);
    const [fileList, setFileList] = useState([]);

    const handleCancel = () => {
        setVisible(false);
        setData();
    };

    const handleChange = (name, value) => {
        setData((prevState) => ({ ...prevState, [name]: value }));
    };

    const beforeUpload = () => {
        // Avoid after drop, it auto upload to server
        return false;
    };

    const handleChangeFile = (info) => {
        let fileList = [...info.fileList];
        // 1. Allow in list file only one
        fileList = fileList.slice(-1);
        // 2. Read from response and show file link
        fileList = fileList.map((file) => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
            }
            return file;
        });
        setFileList(fileList);
    };

    const handleChangeSwitch = (check, name) => {
        setData((prev) => ({ ...prev, [name]: check }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        const formData = new FormData();
        for (const key in data) {
            if (data[key]) {
                formData.append(key, data[key]);
            }
        }

        const files = fileList ? Object.values(fileList) : [];
        if (files.length > 0) {
            formData.append('image', files[0].originFileObj);
        }

        // formData.append('country', 81); //JAPAN

        try {
            const response =
                data && data.id
                    ? await API().patch(`/employer/${employer.id}`, formData, config)
                    : await API().post(`/employer/create`, formData, config);

            if (response?.data?.errorCode === 0) {
                setLoading(false);
                message.success(`${data.id ? `Updated` : `Created`} successfully!`);
                data?.id && setEmployer(data);
                if (setEmployers) {
                    data?.id
                        ? setEmployers((prevState) =>
                              prevState.map((state) => (state.id === data.id ? { ...state, ...data } : state))
                          )
                        : setEmployers((prevState) => [response.data.data, ...prevState]);
                }
                setFileList([]);
                setData();
                setVisible(false);
                onRefresh(page);
            } else {
                setLoading(false);
                message.error(`Failed, ${response.data.message}`);
            }
        } catch (error) {
            setLoading(false);
            message.error(`Uh oh, request failed!`);
        }
    };

    const items = [
        {
            type: 'text',
            label: 'Company Name',
            name: 'company_name',
            value: data?.company_name,
            required: true,
        },
        {
            type: 'text',
            label: 'Representation Name',
            name: 'representation_name',
            value: data?.representation_name,
            required: true,
        },
        {
            type: 'select',
            label: 'Gender',
            name: 'gender',
            value: data?.gender,
            options: dataGender,
            required: true,
        },
        { type: 'text', label: 'Phone', name: 'phone', value: data?.phone, required: true },
        { type: 'text', label: 'Email', name: 'email', value: data?.email, required: true },
        {
            type: 'select',
            label: 'Country',
            name: 'country',
            value: data?.country,
            options: dataCountry,
            disabled: true,
        },
        {
            type: 'select',
            label: 'Industry',
            name: 'industry_type',
            value: data?.industry_type,
            options: dataIndustry,
        },
        { type: 'text', label: 'Employee Count', name: 'employee_count', value: data?.employee_count },
        { type: 'text', label: 'Created Year', name: 'created_year', value: data?.created_year },
        {
            type: 'switch',
            label: 'MOU Signed',
            name: 'is_mou_signed',
            value: data?.is_mou_signed,
            required: false,
            span: 8,
        },
        {
            type: 'switch',
            label: 'Verified',
            name: 'is_verified',
            value: data?.is_verified,
            required: false,
            span: 8,
        },
        {
            type: 'textarea',
            label: 'Address',
            name: 'address',
            value: data?.address,
            span: 24,
        },
    ];

    return (
        <Modal
            visible={visible}
            maskClosable={false}
            title={data?.id ? 'Update' : 'Create'}
            cancelText="Cancel"
            onCancel={handleCancel}
            width={768}
            footer={null}
        >
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Row gutter={24}>
                    {items.map((item) => (
                        <Col key={item.name} span={item.span ? item.span : 8}>
                            <Form.Item
                                name={item.name}
                                label={item.label}
                                initialValue={item.value}
                                rules={[
                                    { required: item.required, message: 'Data required!' },
                                    ({ getFieldValue }) => ({
                                        validator: (formInfo, value) => {
                                            if (formInfo?.field === 'email') {
                                                const email = getFieldValue('email');
                                                if (email && email.includes(' ')) {
                                                    return Promise.reject(new Error('Email cannot contain space'));
                                                }
                                            }

                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                {item.type === 'select' ? (
                                    <LodoshaSelect
                                        value={item.value}
                                        onChange={(value) => handleChange(item.name, Number(value))}
                                    >
                                        {item.options.map(({ value, text }) => (
                                            <Option key={value} value={value}>
                                                {text}
                                            </Option>
                                        ))}
                                    </LodoshaSelect>
                                ) : item.type === 'textarea' ? (
                                    <LodoshaTextArea onChange={(e) => handleChange(item.name, e.target.value)} />
                                ) : item.type === 'switch' ? (
                                    <Switch
                                        onChange={(value) => handleChangeSwitch(value, item.name)}
                                        defaultChecked={item.value ? item.value : false}
                                        name={item.name}
                                    />
                                ) : (
                                    <LodoshaInput onChange={(e) => handleChange(item.name, e.target.value)} />
                                )}
                            </Form.Item>
                        </Col>
                    ))}
                    <Col span={24}>
                        <Form.Item label="Logo">
                            <Dragger
                                name="files"
                                onChange={handleChangeFile}
                                beforeUpload={beforeUpload}
                                fileList={fileList}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                            </Dragger>
                        </Form.Item>
                    </Col>
                    <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
                        <Space direction="horizontal" size="middle">
                            <LodoshaButton key="cancel" htmlType="button" onClick={handleCancel}>
                                Cancel
                            </LodoshaButton>
                            <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                                {data?.id ? 'Update' : 'Create'}
                            </LodoshaButton>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ModalUpsert;
