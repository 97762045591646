import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import HtmlParser from 'html-react-parser';

import { Container, LodoshaButton } from '../../../components/UI/ui.style';

//api
import { getJLPTContentDetail } from '../../../utils/api/E-Learning/jlpt';

//ant design
import { Descriptions, Row, Col, Spin, message } from 'antd';

//components
import ContentModal from './ContentModal';
import SentenceList from './Sentence/SentenceList';
import IFrameMarkdown from '../E-LearningComponents/IFrameMarkdown';
import NextPrevActionButtonGroup from '../E-LearningComponents/NextPrevActionButtonGroup';

export default function ItemDetail() {
    const { contentid } = useParams();
    const [contentDetail, setContentDetail] = useState({});
    const [postData, setPostData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);

    const isSubscribed = useRef(true);

    const handleFetchContentDetail = useCallback(
        async (clickedItemId) => {
            try {
                setLoading(true);
                const response = await getJLPTContentDetail(clickedItemId ? clickedItemId : contentid);
                if (response) {
                    if (response.errorCode !== 0) {
                        setLoading(false);
                        message.error(`Could not get content detail , ${response.message}`);
                        return;
                    }

                    setContentDetail(response ? response.data : {});
                    setLoading(false);
                    return;
                } else {
                    setLoading(false);
                    message.error(`Could not get content detail. Response is undefined`);
                    return;
                }
            } catch (error) {
                setLoading(false);
                message.error(`Could not get content detail.`);
            }
        },
        [contentid]
    );

    const Upsert = useCallback(
        () =>
            updateVisible && (
                <ContentModal
                    visible={updateVisible}
                    postData={postData}
                    setVisible={setUpdateVisible}
                    setPostData={setPostData}
                    isUpdate
                    onRefresh={handleFetchContentDetail}
                    title="Update Content Detail"
                />
            ),
        [updateVisible, postData, handleFetchContentDetail]
    );

    //fetch content detail
    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchContentDetail();
        }
        return () => {
            isSubscribed.current = false;
        };
    }, [handleFetchContentDetail]);

    useEffect(() => {
        setPostData(contentDetail);
    }, [contentDetail, updateVisible]);

    const { japanese, khmer, explanation } = contentDetail;

    return (
        <Container>
            <Spin spinning={loading}>
                <div className="btn-margin-bottom">
                    <NextPrevActionButtonGroup
                        onClickAction={handleFetchContentDetail}
                        currentItemId={contentid}
                        localStorageKey="e-learning-contents"
                    />
                </div>
                <Descriptions bordered column={1} title="Detail" style={{ marginBottom: '1.5rem' }}>
                    <Descriptions.Item label="Japanese">{japanese}</Descriptions.Item>
                    <Descriptions.Item label="Khmer">{khmer}</Descriptions.Item>
                    <Descriptions.Item label="Explanation">
                        {HtmlParser(explanation ? explanation : '')}
                    </Descriptions.Item>
                </Descriptions>
            </Spin>

            <div style={{width:"50%"}}>
                <IFrameMarkdown css={postData.css && postData.css} markdown={postData.markdown && postData.markdown} />
            </div>

            <div id="sentences">
                <SentenceList />
            </div>

            <Row
                style={{
                    position: 'sticky',
                    bottom: '0',
                    marginTop: '0px',
                    padding: '20px 0',
                    background: '#FAFAFA',
                }}
            >
                <Upsert />
                <Col span={24} style={{ textAlign: 'center' }}>
                    <LodoshaButton type="primary" style={{ marginRight: 16 }} onClick={() => setUpdateVisible(true)}>
                        Update Content
                    </LodoshaButton>
                </Col>
            </Row>
        </Container>
    );
}
