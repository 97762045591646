import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Result, Button } from "antd";

const NotFoundPage = () => {
  const [redirect, setRedirect] = useState(false);

  const handleBackHome = () => setRedirect(true);

  if (redirect) {
    return <Redirect to="/" />;
  }

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button onClick={handleBackHome} type="primary" htmlType="button" size="large" style={{ borderRadius: 4 }}>
          Back Home
        </Button>
      }
    />
  );
};

export default NotFoundPage;
