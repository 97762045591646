import React from "react";
import { Row, Col, Form, Modal, Button, Input, message } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { LodoshaButton } from "../../components/UI/ui.style";
import { getAccount, createStaffAccount, sendPassword } from "../../utils/api/account"

const Password = ({ visible, setVisible, data = {} }) => {
  const [loading, setLoading] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState("");
  const [account, setAccount] = React.useState();
  const [isAccountLoading, setIsAccountLoading] = React.useState(false);

  React.useEffect(() => {
    const handleFetchAccount = async () => {
      try {
        const response = await getAccount(data);
        if (response.errorCode === 0) {
          setAccount(response.data);
        } else {
          setAccount();
        }
      } catch (error) {
        // console.log(error);
      }
    };

    data.id && visible && handleFetchAccount();
  }, [data, visible]);

  const handleSendPassword = async (newData) => {
    setLoading(true);
    const response = await sendPassword(newData);
    // console.log('response: ', response);
    setLoading(false);
    if (response && response.errorCode === 0) {
      message.success("Account and Password sent.");
      setVisible(false);
    } else if (response && response.errorCode === 403) {
      message.error("Account login with facebook not allow to sent.");
    } else {
      message.error("Account and Password not sent.");
    }
  };

  const handleCreateAccount = async (event) => {
    event.preventDefault();
    setIsAccountLoading(true);
    try {
      const response = await createStaffAccount(data);
      // console.log("response: ", response);
      if (response && response.errorCode === 0) {
        setAccount(response.data);
        message.success("Create successfully!");
      } else {
        message.error(`${response.message}`);
      }
      setIsAccountLoading(false);
    } catch (error) {
      // console.log(error);
      setIsAccountLoading(false);
    }
  };

  const handleChangePasswordAndSend = (event) => {
    event.preventDefault();
    let newData = data;
    newData["new_password"] = newPassword;
    newData["send"] = true;
    handleSendPassword(newData);
    setNewPassword("");
  };

  const handleChangePassword = (event) => {
    event.preventDefault();
    let newData = data;
    newData["new_password"] = newPassword;
    handleSendPassword(newData);
    setNewPassword("");
  };

  const handleRandomPassword = (event) => {
    event.preventDefault();
    visible && setNewPassword(Math.random().toString(36).substring(6));
  };

  const handleCancel = () => {
    setNewPassword("");
    setVisible(false);
  };

  return (
    <Modal width={768} maskClosable={false} visible={visible} title="" onCancel={handleCancel} footer={null}>
      <Row gutter={[40, 40]}>
        <Col span={12} style={{ flex: 1 }}>
          <h2>Account Information</h2>
          <p>Email: {account?.email || "N/A"}</p>
          <p>Phone: {account?.phone || "N/A"}</p>
          <p>Facebook ID: {account?.fb_id || "N/A"}</p>
          <div>
            <LodoshaButton
              type="primary"
              loading={isAccountLoading}
              disabled={account?.email || account?.phone || account?.fb_id}
              onClick={handleCreateAccount}
            >
              {"Create Account"}
            </LodoshaButton>
          </div>
        </Col>

        <Col span={12} style={{ flex: 1 }}>
          <h2>Send Password</h2>
          <Form layout="vertical">
            <Form.Item label="New Password:">
              <Row gutter={16}>
                <Col span={20}>
                  <Input
                    type="text"
                    placeholder="Password"
                    style={{ marginRight: 16 }}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </Col>
                <Col span={4}>
                  <Button title="New Password" type="primary" icon={<SyncOutlined />} onClick={handleRandomPassword} />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item style={{ position: "absolute", bottom: 0 }}>
              <LodoshaButton
                type="primary"
                disabled={!newPassword}
                loading={loading}
                onClick={handleChangePasswordAndSend}
              >
                {"Change & Send"}
              </LodoshaButton>
              <LodoshaButton
                type="primary"
                style={{ marginLeft: 16 }}
                disabled={!newPassword}
                loading={loading}
                onClick={handleChangePassword}
              >
                {"Change"}
              </LodoshaButton>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default Password;
