import styled from "@emotion/styled";

const FavoritesWrapper = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 380px;
  margin-bottom: 24px;
  border-radius: 10px;
  box-shadow: "#eee 2px 2px 1px 1px";
  .ant-table {
    overflow-x: auto;
  }
`;

export { FavoritesWrapper };
