import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

//antd
import { Modal, Form, Row, Col, InputNumber, Input, message, Space } from 'antd';

import { LodoshaButton, LodoshaInput } from '../../components/UI/ui.style';

import { createPartnerFee, updatePartnerFee } from '../../utils/api/partner';

export default function ModalPartnerFee({
    visible,
    setVisible,
    postData = [],
    setPostData,
    isCreate,
    isUpdate,
    onRefresh,
}) {
    const params = useParams();
    const [form] = Form.useForm();
    const { TextArea } = Input;

    const [data, setData] = useState({ partnerId: params?.id, ...postData });
    const [loading, setLoading] = useState(false);

    const handleOnCancel = () => {
        setPostData([]);
        setVisible(false);
    };

    const handleSubmit = async () => {
        if (data?.price <= 0) {
            message.warning('Price is invalid.');
            return;
        }
        setLoading(true);
        try {
            const response = isCreate ? await createPartnerFee(data) : isUpdate ? await updatePartnerFee(data) : {};
            if (response) {
                if (response.errorCode === 0) {
                    message.success('Successfully created hospital fee.');
                    setLoading(false);
                    handleOnCancel();
                    onRefresh();
                } else {
                    message.error(`Couldn't create hospital fee, ${response?.message}`);
                    setLoading(false);
                }
            } else {
                message.error(`Couldn't create hospital fee, Response is undefined`);
                setLoading(false);
            }
        } catch (error) {
            message.error(`Uh oh, couldn't create hospital fee, ${error.message}`);
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({ ...prev, [name]: value }));
    };

    const handleChangePrice = (value) => {
        setData((prev) => ({ ...prev, price: value }));
    };

    const hospitalFeeFields = [
        {
            name: 'type',
            label: 'Service',
            type: 'text',
            required: true,
            value: data?.type,
        },
        {
            name: 'price',
            label: 'Price',
            type: 'number',
            required: true,
            value: data?.price,
        },
        {
            name: 'description',
            label: 'Description',
            type: 'textarea',
            required: true,
            value: data?.description,
        },
    ];

    return (
        <Modal
            visible={visible}
            title={isCreate ? 'Create' : 'Update'}
            onCancel={handleOnCancel}
            maskClosable={false}
            width={768}
            footer={null}
        >
            <Form form={form} onFinish={handleSubmit} layout="vertical">
                <Row gutter={24}>
                    {hospitalFeeFields.map((item, index) => (
                        <Col span={24} key={index}>
                            <Form.Item
                                name={item.name}
                                label={item.label + ':'}
                                initialValue={item.value}
                                rules={[{ required: item.required, message: 'Data required!' }]}
                            >
                                {item.type === 'number' ? (
                                    <InputNumber
                                        onChange={handleChangePrice}
                                        type={item.type}
                                        style={{ width: '100%' }}
                                        size="large"
                                        min="0"
                                        step="any"
                                    />
                                ) : item.type === 'textarea' ? (
                                    <TextArea onChange={handleChange} cols={6} name={item.name} />
                                ) : (
                                    <LodoshaInput onChange={handleChange} type={item.type} name={item.name} />
                                )}
                            </Form.Item>
                        </Col>
                    ))}
                    <Col span={24}>
                        <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
                            <Space align="center" direction="horizontal" size="middle">
                                <LodoshaButton key="cancel" htmlType="button" onClick={handleOnCancel}>
                                    Cancel
                                </LodoshaButton>
                                <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                                    {isUpdate ? 'Update' : 'Create'}
                                </LodoshaButton>
                            </Space>
                        </Col>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
