import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Row, Col, Table, message, Popconfirm, Button } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import { getPartnerEmployees, deletePartnerEmployee } from '../../utils/api/partner_employee';
import getTextDisplay, { dataType } from '../../utils/data';

import { Container, LodoshaButton } from '../../components/UI/ui.style';
import ModalEmployees from './ModalEmployees';

const HospitalEmployees = () => {
    const history = useHistory();
    const location = useLocation();
    const params = useParams();

    const partner = location.state;
    const partnerId = partner?.id || params.id;
    const isSubscribed = useRef(true);

    const [loading, setLoading] = useState(false);
    const [partnerEmployees, setPartnerEmployees] = useState([]);
    const [visibleEmployees, setVisibleEmployees] = useState(false);
    const [totalPartnerEmployees, setTotalPartnerEmployees] = useState(0);

    const handleFetchData = useCallback(
        async (page = 1) => {
            isSubscribed.current && setLoading(true);
            try {
                getPartnerEmployees(partnerId, page).then((response) => {
                    if (response?.data && isSubscribed.current) {
                        setPartnerEmployees(response.data);
                        setTotalPartnerEmployees(response && response.total);
                    }
                    isSubscribed.current && setLoading(false);
                });
            } catch (error) {
                isSubscribed.current && setLoading(false);
                message.error(`Uh oh, request failed!`);
            }
        },
        [partnerId]
    );

    useEffect(() => {
        isSubscribed.current && handleFetchData();
        return () => {
            isSubscribed.current = false;
        };
    }, [handleFetchData]);

    const handlePageChange = (page) => {
        handleFetchData(page);
    };

    const handleRemoveEmployee = (id) => {
        // removeItem(id);
        console.log('id: ', id);
        setPartnerEmployees((prevState) => prevState.filter((item) => item.id !== id));
        setTotalPartnerEmployees(partnerEmployees.length);
    };

    const handleAddEmployee = (data) => {
        setPartnerEmployees((prevState) => [data, ...prevState]);
        setTotalPartnerEmployees(partnerEmployees.length);
    };

    const handleRow = (record, _) => {
        return {
            onClick: (event) => {
                event.stopPropagation();
                history.push(`/candidates/detail/${record.employee.id}`);
            }, // click row
        };
    };

    const dataSource = useMemo(
        () =>
            partnerEmployees.length
                ? partnerEmployees.map((partnerEmployee, index) => {
                      return {
                          ...partnerEmployee,
                          key: partnerEmployee.id,
                          no: index + 1,
                          name: partnerEmployee.employee && partnerEmployee.employee.name,
                          gender:
                              partnerEmployee.employee &&
                              getTextDisplay(partnerEmployee.employee.gender, dataType.GENDER),
                          phone: partnerEmployee.employee && partnerEmployee.employee.phone,
                          japanese_level:
                              partnerEmployee.employee &&
                              getTextDisplay(partnerEmployee.employee.japanses_level, dataType.JAPANESE_LEVEL),
                          tag:
                              partnerEmployee.employee &&
                              getTextDisplay(partnerEmployee.employee.tag, dataType.TAG_STATUS),
                          status: partnerEmployee.status,
                          // note: partnerEmployee.note,
                      };
                  })
                : [],
        [partnerEmployees]
    );

    const columns = [
        { title: 'NO', dataIndex: 'no', key: 'no' },
        { title: 'NAME', dataIndex: 'name', key: 'name' },
        { title: 'GENDER', dataIndex: 'gender', key: 'gender' },
        { title: 'PHONE', dataIndex: 'phone', key: 'phone' },
        { title: 'JAPANESE', dataIndex: 'japanese_level', key: 'japanese-level' },
        { title: 'NOTE', dataIndex: 'note', key: 'note' },
        {
            title: '',
            key: 'actions',
            width: '5%',
            render: (record) => (
                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Popconfirm
                        placement="topLeft"
                        title={'Do you want to delete?'}
                        onConfirm={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleDelete(record);
                        }}
                        onCancel={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            title="Delete"
                            type="danger"
                            className="action-button-margin"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            icon={<DeleteOutlined />}
                        />
                    </Popconfirm>
                </span>
            ),
        },
    ];

    const handleDelete = async (record) => {
        // Delete
        deletePartnerEmployee(record.id).then((response) => {
            if (response && response.errorCode === 0) {
                handleRemoveEmployee(record.id);
            } else {
                message.error(response && response.message);
            }
        });
    };

    return (
        <Container>
            <Row justify="space-between" align="middle" style={{ marginBottom: 20 }}>
                <Col>
                    <LodoshaButton type="primary" icon={<PlusOutlined />} onClick={() => setVisibleEmployees(true)}>
                        New
                    </LodoshaButton>
                    {visibleEmployees && (
                        <ModalEmployees
                            visible={visibleEmployees}
                            setVisible={setVisibleEmployees}
                            partnerId={partnerId}
                            handleAddEmployee={handleAddEmployee}
                            handleRemoveEmployee={handleRemoveEmployee}
                        />
                    )}
                </Col>
                <Col>
                    <h3 style={{ margin: 0 }}>Hospital Name: {partner && partner.name}</h3>
                </Col>
                <Col>
                    <h3 style={{ margin: 0 }}>Person In Charge: {partner && partner.contact_name}</h3>
                </Col>
                <Col>
                    <h3 style={{ margin: 0 }}>Phone: {partner && partner.phone}</h3>
                </Col>
            </Row>
            <Table
                bordered
                loading={loading}
                onRow={handleRow}
                dataSource={dataSource}
                columns={columns}
                pagination={{
                    defaultCurrent: 1,
                    defaultPageSize: 15,
                    total: totalPartnerEmployees,
                    onChange: handlePageChange,
                }}
            />
        </Container>
    );
};
export default HospitalEmployees;
