import styled from "@emotion/styled";

const DashboardWrapper = styled.div`
  margin: 16px 0;
  .item-container {
    border-radius: 10px;
    box-shadow: #eee 6px 6px 1px 1px;
    .icon {
      margin-top: 8px;
      text-align: center;
      height: 40px;
      width: 40px;
      border-radius: 20px;
      margin: 0 auto;
    }
    .star {
      background-color: #e6f2fc;
    }
    .employee {
      background-color: #f4f1ff;
    }
    .employer {
      background-color: #fff9e4;
    }
    .post-job {
      background-color: #ebfaea;
    }
  }

  .total-all {
    h1 {
      margin: 0;
      font-size: 55px;
      font-weight: bold;
      text-align: center;
      color: #152688;
    }

    p {
      margin: 0;
      color: #555;
      text-align: center;
    }
  }

  .lable-title {
    font-size: 16px;
    text-align: center;
    margin: 0;
  }

  .lable-value {
      margin: 0;
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      color: #152688;
  }

  .container-detail {
    margin-top: 20px;

    .title-detail {
      color: #000;
      text-align: center;
      font-weight: 600px;
      font-size: 12px;
      margin: 0;
    }

    .number-detail {
      font-size: 24px;
      text-align: center;
      color: #152688;
      font-weight: 800px;
      margin: 0;
    }
  }
`;

export { DashboardWrapper };
