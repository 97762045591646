import React from "react";
import { Input, Select, Descriptions, InputNumber, DatePicker, Switch, TimePicker } from "antd";
import getTextDisplay from "./../utils/data";
import moment from "moment";
import "./component.less";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";

export function getComponents(data, onChangeHandler, onSelectChangeHandler = null, handleSeachSelect = null) {
  const { type } = data;

  switch (type) {
    case "input":
      return getInput(data, onChangeHandler);
    case "select":
      return getSelect(data, onSelectChangeHandler);
    case "textArea":
      return getTextArea(data, onChangeHandler);
    case "item":
      return getDescriptorItem(data);
    case "select_autocomplete":
      return getSelectAutoComplete(data, handleSeachSelect, onSelectChangeHandler);
    case "input_number":
      return getInputNumber(data, onSelectChangeHandler);
    case "date_picker":
      return getDatePicker(data, onSelectChangeHandler);
    case "switch":
      return getSwitch(data, onSelectChangeHandler);
    case "input_password":
      return getInputPassword(data, onChangeHandler);
    case "time_picker":
      return getTimePicker(data, onSelectChangeHandler);
    default:
      return null;
  }
}

export function getInput(data, onChangeHandler) {
  const { label, name, value, isDisable, require } = data;

  return require ? (
    <Input
      placeholder={isDisable === true ? "" : label}
      key={name}
      type="text"
      name={name}
      disabled={isDisable}
      onChange={onChangeHandler}
    />
  ) : (
    <Input
      placeholder={isDisable === true ? "" : label}
      key={name}
      type="text"
      name={name}
      value={value}
      disabled={isDisable}
      onChange={onChangeHandler}
    />
  );
}

export function getSelect(data, onSelectChangeHandler) {
  const { label, name, value, options, isDisable, require } = data;
  return require ? (
    <div className="select-box">
      <Select
        style={{ height: "100%" }}
        placeholder={label}
        key={name}
        value={value}
        name={name}
        disabled={isDisable}
        onChange={(e) => onSelectChangeHandler(name, e)}
      >
        {options.map(({ value, text }) => (
          <Select.Option key={value} name={name} value={value}>
            {text}
          </Select.Option>
        ))}
      </Select>
    </div>
  ) : (
    <div className="select-box">
      <Select
        style={{ height: "100%" }}
        placeholder={label}
        key={name}
        name={name}
        value={value}
        disabled={isDisable}
        onChange={(e) => onSelectChangeHandler(name, e)}
      >
        {options.map(({ value, text }) => (
          <Select.Option key={value} name={name} value={value}>
            {text}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export function getTextArea(data, onChangeHandler) {
  const { label, name, value, isDisable, require, row } = data;
  return require ? (
    <Input.TextArea
      placeholder={isDisable === true ? "" : label}
      key={name}
      name={name}
      disabled={isDisable}
      onChange={onChangeHandler}
      rows={row ? row : 4}
      autosize={{ minRows: row ? row : 4 }}
    />
  ) : (
    <Input.TextArea
      placeholder={isDisable === true ? "" : label}
      key={name}
      name={name}
      value={value}
      disabled={isDisable}
      onChange={onChangeHandler}
      rows={row ? row : 4}
      autosize={{ minRows: row ? row : 4 }}
    />
  );
}

export function getDescriptorItem(data) {
  const { name, label, value, isImage, isLanguage, span, is_link } = data;
  var displayValue = getTextDisplay(value, name, isLanguage);
  var spanValue = span ? span : 1;

  if (is_link && is_link) {
    return (
      <Descriptions.Item key={label} label={label} span={spanValue}>
        <a href={displayValue === null ? value : displayValue} target="_blank" rel="noopener noreferrer">
          {displayValue === null ? value : displayValue}
        </a>
      </Descriptions.Item>
    );
  }

  return isImage ? (
    <Descriptions.Item key={label} label={label} span={spanValue}>
      <img style={{ width: "auto", height: "150px" }} src={displayValue === null ? value : displayValue} alt="" />
    </Descriptions.Item>
  ) : (
    <Descriptions.Item key={label} label={label} span={spanValue}>
      {displayValue === null ? value : displayValue}
    </Descriptions.Item>
  );
}

export function getSelectAutoComplete(data, onSearch, onSelect) {
  const { Option } = Select;
  const { label, name, value, isDisable, require, options } = data;

  const dataOptions = options.map((d) => <Option key={d.value}>{d.text}</Option>);

  return require ? (
    <div className="select-box">
      <Select
        style={{ height: "100%" }}
        showSearch
        key={name}
        name={name}
        disabled={isDisable}
        placeholder={label}
        defaultActiveFirstOption={false}
        showArrow={false}
        value={value}
        filterOption={false}
        onSearch={(e) => onSearch(name, e)}
        onSelect={(e) => onSelect(name, e)}
        notFoundContent={null}
      >
        {dataOptions}
      </Select>
    </div>
  ) : (
    <div className="select-box">
      <Select
        style={{ height: "100%" }}
        showSearch
        name={name}
        key={name}
        disabled={isDisable}
        value={value}
        placeholder={label}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={(e) => onSearch(name, e)}
        onSelect={(e) => onSelect(name, e)}
        notFoundContent={null}
      >
        {dataOptions}
      </Select>
    </div>
  );
}

export function getInputNumber(data, onSelectChangeHandler) {
  const { label, name, value, isDisable, require } = data;

  return require ? (
    <div>
      <InputNumber
        type="number"
        style={{ height: "100%", width: "100%" }}
        placeholder={label}
        key={name}
        min={1}
        name={name}
        value={value}
        disabled={isDisable}
        onChange={(e) => onSelectChangeHandler(name, e)}
      />
    </div>
  ) : (
    <div>
      <InputNumber
        type="number"
        style={{ height: "100%", width: "100%" }}
        placeholder={label}
        key={name}
        min={1}
        name={name}
        value={value}
        disabled={isDisable}
        onChange={(e) => onSelectChangeHandler(name, e)}
      />
    </div>
  );
}

// const disabledPast = (endValue) => {
//   const now = Date.now();
//   return endValue < now;
// };

export function getDatePicker(data, onSelectChangeHandler) {
  const { name, value, isDisable, require, isDisablePast } = data;
  const dateFormat = "DD-MM-YYYY";
  const defaultValue = moment(value, dateFormat);

  return require ? (
    <DatePicker
      format={dateFormat}
      key={name}
      disabledDate={isDisablePast && isDisablePast}
      name={name}
      disabled={isDisable}
      dateFormat
      onChange={(date, dateStr) => onSelectChangeHandler(name, dateStr)}
    />
  ) : (
    <DatePicker
      format={dateFormat}
      key={name}
      disabledDate={isDisablePast && isDisablePast}
      name={name}
      disabled={isDisable}
      defaultValue={defaultValue}
      dateFormat
      onChange={(date, dateStr) => onSelectChangeHandler(name, dateStr)}
    />
  );
}

export function getSwitch(data, onSelectChangeHandler) {
  const { name, value, isDisable } = data;

  return (
    <Switch
      style={{ margin: "0 auto" }}
      name={name}
      key={name}
      disabled={isDisable}
      checked={value === 0 ? false : true}
      // defaultChecked={value===0 ? false : true}
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      onChange={(checked) => onSelectChangeHandler(name, checked === false ? 0 : 1)}
    />
  );
}

export function getInputPassword(data, onChangeHandler) {
  const { label, name, value, isDisable, require } = data;

  return require ? (
    <Input.Password
      style={{ height: 40, width: "100%" }}
      placeholder={label}
      key={name}
      name={name}
      disabled={isDisable}
      onChange={onChangeHandler}
    />
  ) : (
    <Input.Password
      style={{ height: 40, width: "100%" }}
      placeholder={label}
      key={name}
      name={name}
      value={value}
      disabled={isDisable}
      onChange={onChangeHandler}
    />
  );
}

export function getTimePicker(data, onSelectChangeHandler) {
  const { name, value, isDisable, require } = data;
  const timeFormat = "hh:mm a";
  const defaultValue = value ? moment(value, timeFormat) : null;
  return require ? (
    <TimePicker
      minuteStep={15}
      use12Hours
      format={timeFormat}
      key={name}
      name={name}
      disabled={isDisable}
      onChange={(time, timeStr) => {
        onSelectChangeHandler(name, timeStr);
      }}
    />
  ) : (
    <TimePicker
      minuteStep={15}
      use12Hours
      format={timeFormat}
      key={name}
      name={name}
      disabled={isDisable}
      defaultValue={defaultValue}
      timeFormat
      onChange={(time, timeStr) => {
        onSelectChangeHandler(name, timeStr);
      }}
    />
  );
}
