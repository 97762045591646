import React from 'react';
import { useParams } from 'react-router-dom';

import { Descriptions, Spin, message } from 'antd';

import { Container } from '../../../components/UI/ui.style';
import getTextDisplay, { dataType } from '../../../utils/data';
import API, { baseURL } from '../../../utils/api';

const Detail = () => {
    const params = useParams();
    const [loading, setLoading] = React.useState(false);
    const [partner, setPartner] = React.useState();

    React.useEffect(() => {
        let isSubscribed = true;
        setLoading(true);

        window.localStorage.setItem('partnerId', params.id);

        const handleFetchPartner = async () => {
            try {
                const response = await API().get(`/partner/detail/${params.id}`);
                if (response.data.data) {
                    isSubscribed && setPartner(response.data.data);
                    console.log('response.data.data: ', response.data.data);
                } else {
                    // User not exists
                    message.info(response.data.message);
                }
                isSubscribed && setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        handleFetchPartner();

        return () => {
            isSubscribed = false;
        };
    }, [params]);

    const items = React.useMemo(() => {
        return [
            { label: 'Partner Name', name: 'name', value: partner?.name },
            { label: 'Contact Name', name: 'contact_name', value: partner?.contact_name },
            {
                label: 'Gender',
                name: 'gender',
                value: partner?.gender && getTextDisplay(partner.gender, dataType.GENDER),
            },
            { label: 'Email', name: 'email', value: partner?.email },
            { label: 'Phone', name: 'phone', value: partner?.phone },
            {
                label: 'Category',
                name: 'partner_category',
                value: partner?.category && getTextDisplay(partner.category, dataType.PARTNER_CATEGORY),
            },
            { label: 'Register Fee', name: 'register_fee', value: partner?.register_fee },
            // { label: 'Trainee Fee', name: 'trainee_fee', value: partner?.trainee_fee },
            // { label: 'Specific Skill Fee', name: 'specific_skill_fee', value: partner?.specific_skill_fee },
            {
                label: 'Country',
                name: 'country',
                value: partner?.country && getTextDisplay(partner.country, dataType.COUNTRY),
            },
            { label: 'Address', name: 'address', value: partner?.address },
            { label: 'URL', name: 'url', value: partner?.url, isLink: true },
            { label: 'Register URL', name: 'register_url', value: partner?.register_url, isLink: true },
            { label: 'Post Job URL', name: 'id_token', value: partner?.id_token, isLink: true },
            { label: 'Note', name: 'note', value: partner?.note },
        ];
    }, [partner]);

    return (
        <Container>
            <Spin tip="Loading..." spinning={loading}>
                <Descriptions title="Base Information" bordered column={3}>
                    {items.map((item) => (
                        <Descriptions.Item key={item.name} label={item.label}>
                            {item.isLink ? (
                                <a href={item.value} target="_blank" rel="noopener noreferrer">
                                    {item.value}
                                </a>
                            ) : (
                                item.value
                            )}
                        </Descriptions.Item>
                    ))}
                </Descriptions>
                {partner?.logo && (
                    <Descriptions title="Logo" column={{ xs: 8 }} style={{ marginTop: 24 }}>
                        <Descriptions.Item>
                            <img
                                style={{ width: 'auto', height: '150px', border: '1px solid #d9d9d9', padding: 16 }}
                                src={baseURL + partner.logo}
                                alt="Logo"
                            />
                        </Descriptions.Item>
                    </Descriptions>
                )}
            </Spin>
        </Container>
    );
};

export default Detail;
