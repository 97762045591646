import createAPI from '../api';

export const getPartnerFees = (page, partnerId, type) => {
    const url = (page = 1 ? `/partner-fee/${partnerId}` : `/partner-fee/${partnerId}?offset=${(page - 1) * 15}`);
    return createAPI()
        .get(url)
        .then((response) => response.data)
        .catch((err) => console.log(`getHospitalFees err: `, err));
};

export const createPartnerFee = (info) => {
    return createAPI()
        .post('/partner-fee', info)
        .then((response) => response.data)
        .catch((err) => {});
};

export const updatePartnerFee = (info) => {
    console.log('info', info);
    return createAPI()
        .patch(`/partner-fee/${info?.id}`, info)
        .then((response) => response.data)
        .catch((err) => {});
};

export const deletePartnerFee = (id) => {
    return createAPI()
        .delete(`/partner-fee/${id}`)
        .then((response) => response.data)
        .catch((err) => {});
};
