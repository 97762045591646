import React, { useEffect, useCallback, useState } from 'react';

import { Button, Col, message, Row, Spin } from 'antd';

import MarkdownPreviewWrapper from './markdown_it.style';

//components
import IFrameMarkdown from '../../containers/E-Learning/E-LearningComponents/IFrameMarkdown';

//utils and constants
import { getMarkdowns } from '../../utils/api/E-Learning/markdown';

export default function MarkdownLayoutSample({ onClose, setData }) {
    const [loading, setLoading] = useState(false);
    const [markdowns, setMarkdowns] = useState([]);

    const handleSelectSample = (e, markdown) => {
        navigator.clipboard.writeText(markdown.markdown);
        setData((prev) => {
            if (prev.css) {
                return { ...prev, css: prev.css?.concat(markdown.css) };
            } else {
                return { ...prev, css: markdown.css };
            }
        });
        onClose('layout');
    };

    const handleFetchMarkdown = useCallback(async () => {
        //type 2 = layout
        let type = 2;
        try {
            setLoading(true);
            const response = await getMarkdowns(type);
            if (response) {
                if (response.errorCode === 0) {
                    setMarkdowns(response.data);
                    setLoading(false);
                } else {
                    message.error(`Uh oh, couldn't get layout sample, err: ${response.message}`);
                    setLoading(false);
                    return;
                }
            } else {
                message.error(`Uh oh, couldn'tget layout sample. Response is undefined`);
                setLoading(false);
                return;
            }
        } catch (error) {
            setLoading(false);
            message.error(`Uh oh, couldn't get layout sample. err: ${error}`);
        }
    }, []);

    useEffect(() => {
        let isSubscribed = true;
        isSubscribed && handleFetchMarkdown();
        return () => (isSubscribed = false);
    }, [handleFetchMarkdown]);

    return (
        <MarkdownPreviewWrapper>
            <Row>
                <Col span={24} className="sample-container">
                    <Spin spinning={loading}>
                        {markdowns.map((d, index) => (
                            <div key={d.id} style={{ marginBottom: '2rem', width: '100%' }}>
                                <div style={{ width: '90%' }}>
                                    <IFrameMarkdown markdown={d.markdown} css={d.css} />
                                    <Button
                                        type="primary"
                                        style={{ width: '100%' }}
                                        onClick={(e) => handleSelectSample(e, d)}
                                    >
                                        Select
                                    </Button>
                                </div>
                                <div />
                            </div>
                        ))}
                    </Spin>
                </Col>
            </Row>
        </MarkdownPreviewWrapper>
    );
}
