import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Row, Col, Table, message, Popconfirm, Button, Space } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { Container, LodoshaButton } from '../../../components/UI/ui.style';

import { getHospitalFees, deleteHospitalFee } from '../../../utils/api/hospital';
import ModalHospitalFee from './ModalHospitalFee';

const HospitalFees = () => {
    const location = useLocation();
    const params = useParams();

    const hospital = location.state;
    const hospitalId = hospital?.id || params.id;
    const isSubscribed = useRef(true);

    const [loading, setLoading] = useState(false);
    const [fees, setFees] = useState([]);
    const [fee, setFee] = useState([]);
    const [visibleHospitalFee, setVisibleHospitalFee] = useState(false);
    const [visibleUpdateHospitalFee, setVisibleUpdateHospitalFee] = useState(false);
    const [totalFee, setTotalFee] = useState(0);

    const handleFetchData = useCallback(
        async (page = 1) => {
            isSubscribed.current && setLoading(true);
            try {
                getHospitalFees(page, hospitalId).then((response) => {
                    console.log(response);
                    if (response?.data && isSubscribed.current) {
                        setFees(response.data);
                        setTotalFee(response && response.total);
                    }
                    isSubscribed.current && setLoading(false);
                });
            } catch (error) {
                isSubscribed.current && setLoading(false);
                message.error(`Uh oh, request failed!`);
            }
        },
        [hospitalId]
    );

    useEffect(() => {
        handleFetchData();
        return () => {
            isSubscribed.current = false;
        };
    }, [handleFetchData]);

    const handlePageChange = (page) => {
        handleFetchData(page);
    };

    const handleRemoveFee = (id) => {
        // removeItem(id);
        setFees((prevState) => prevState.filter((item) => item.id !== id));
        setTotalFee(fees.length);
    };

    const handleEditHospitalFee = (e, record) => {
        console.log(record);
        e.stopPropagation();
        setFee(record);
        setVisibleUpdateHospitalFee(true);
    };

    const dataSource = useMemo(
        () =>
            fees.length
                ? fees.map((fee, index) => {
                      return {
                          ...fee,
                          key: fee.id,
                          no: index + 1,
                      };
                  })
                : [],
        [fees]
    );

    const columns = [
        { title: 'NO', dataIndex: 'no', key: 'no', width: '2%', align: 'center' },
        { title: 'SERVICE', dataIndex: 'type', key: 'type', width: '20%' },
        { title: 'PRICE', dataIndex: 'price', key: 'price', width: '10%' },
        { title: 'DESCRIPTION', dataIndex: 'description', key: 'description', width: '20%' },
        {
            title: '',
            key: 'actions',
            width: '5%',
            align: 'center',
            render: (record) => (
                <Space size="small" align="center" direction="horizontal">
                    <Button icon={<EditOutlined />} onClick={(e) => handleEditHospitalFee(e, record)} type="primary" />
                    <Popconfirm
                        placement="topLeft"
                        title={'Do you want to delete?'}
                        onConfirm={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleDelete(record);
                        }}
                        onCancel={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            title="Delete"
                            type="danger"
                            className="action-button-margin"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            icon={<DeleteOutlined />}
                        />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const Upsert = useCallback(
        () =>
            visibleHospitalFee && (
                <ModalHospitalFee
                    isCreate
                    setPostData={setFee}
                    postData={fee}
                    visible={visibleHospitalFee}
                    setVisible={setVisibleHospitalFee}
                    onRefresh={handleFetchData}
                />
            ),
        [fee, visibleHospitalFee, handleFetchData]
    );

    const Update = useCallback(
        () =>
            visibleUpdateHospitalFee && (
                <ModalHospitalFee
                    isUpdate
                    setPostData={setFee}
                    postData={fee}
                    visible={visibleUpdateHospitalFee}
                    setVisible={setVisibleUpdateHospitalFee}
                    onRefresh={handleFetchData}
                />
            ),
        [fee, visibleUpdateHospitalFee, handleFetchData]
    );

    const handleDelete = async (record) => {
        // Delete
        deleteHospitalFee(record.id).then((response) => {
            if (response && response.errorCode === 0) {
                message.success('Successfully deleted hospital fee');
                handleRemoveFee(record.id);
            } else {
                message.error(response && response.message);
            }
        });
    };

    return (
        <Container>
            <Row justify="space-between" align="middle" style={{ marginBottom: 20 }}>
                <Col>
                    <LodoshaButton type="primary" icon={<PlusOutlined />} onClick={() => setVisibleHospitalFee(true)}>
                        New
                    </LodoshaButton>
                    <Update />
                    <Upsert />
                </Col>
                <Col>
                    <h3 style={{ margin: 0 }}>Hospital Name: {hospital && hospital.name}</h3>
                </Col>
                <Col>
                    <h3 style={{ margin: 0 }}>Person In Charge: {hospital && hospital.contact_name}</h3>
                </Col>
                <Col>
                    <h3 style={{ margin: 0 }}>Phone: {hospital && hospital.phone}</h3>
                </Col>
            </Row>
            <Table
                size="medium"
                bordered
                loading={loading}
                dataSource={dataSource}
                columns={columns}
                pagination={{
                    defaultCurrent: 1,
                    defaultPageSize: 15,
                    total: totalFee,
                    onChange: handlePageChange,
                }}
            />
        </Container>
    );
};
export default HospitalFees;
