import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Space, Table, Button, message, Row, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, KeyOutlined } from '@ant-design/icons';
import { Container, LodoshaButton } from '../../components/UI/ui.style';
import { getStaffs, deleteStaff } from '../../utils/api/staff';
import { PlusOutlined } from '@ant-design/icons';

import ModalUpsert from './ModalUpsert';
import ModalPassword from './ModalPassword';

const Staffs = () => {
    const page = 1;

    const [loading, setLoading] = useState(false);
    const [staffs, setStaffs] = useState([]);
    const [total, setTotal] = useState(0);
    const [visibleUpsert, setVisibleUpsert] = useState(false);
    const [visiblePassword, setVisiblePassword] = useState(false);

    const [staff, setStaff] = useState();
    const [passwordData, setPasswordData] = useState();

    const handleFetchStaffs = useCallback(async (page = 1) => {
        setLoading(true);
        try {
            const response = await getStaffs(page);
            setStaffs(response.data);
            setTotal(response.data.total);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error(`Uh oh, request failed!`);
        }
    }, []);

    useEffect(() => {
        handleFetchStaffs();
    }, [handleFetchStaffs]);

    const handleUpdate = (record) => {
        setStaff(record);
        setVisibleUpsert(true);
    };

    const handleDelete = (e, item) => {
        e.stopPropagation();
        Modal.confirm({
            title: `Are you sure delete start ${item.name}?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await deleteStaff(item.id);

                    if (response && response.errorCode === 0) {
                        setStaffs((prevState) => prevState.filter((state) => state.id !== item.id));
                        message.success(`Deleted successfully!`);
                    } else {
                        message.error(`Failed! ${response.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request failed!`);
                }
            },
            onCancel() {},
        });
    };

    const handleRow = (record, rowIndex) => {
        return {
            onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                // history.push(`${ROUTES.STAFFS_DETAIL}/${record.id}`);
            }, // click row
        };
    };

    const handleChangePagination = (page) => {};

    const handlePassword = (e, item) => {
        e.stopPropagation();
        setPasswordData({ 
            id: item.id,
            type: 'staff'
        });
        setVisiblePassword(true);
    };

    const dataSource = useMemo(
        () =>
            staffs && staffs.length
                ? staffs.map((staff, index) => {
                      return { ...staff, key: staff.id, no: index + 1 + (page - 1) * 15 };
                  })
                : [],
            [staffs, page]
    );

    const columns = [
        { title: '#', dataIndex: 'no', key: 'no', width: 50 },
        { title: 'NAME', dataIndex: 'name', key: 'name' },
        { title: 'POSITION', dataIndex: 'position', key: 'position' },
        { title: 'EMAIL', dataIndex: 'email', key: 'email' },
        { title: 'DESCRIPTION', dataIndex: 'description', key: 'description' },
        {
            title: '',
            key: 'actions',
            render: (record) => (
                <Space direction="horizontal" size="small">
                    <Button
                        type="primary"
                        icon={<EditOutlined />}
                        className="action-button-margin"
                        onClick={() => handleUpdate(record)}
                    />
                    <Button type="primary" icon={<KeyOutlined />} onClick={(e) => handlePassword(e, record)} />
                    <Button
                        type="danger"
                        icon={<DeleteOutlined />}
                        className="action-button-margin"
                        onClick={(e) => handleDelete(e, record)}
                    />
                </Space>
            ),
        },
    ];

    const Upsert = React.useCallback(
        () =>
            visibleUpsert && (
                <ModalUpsert
                    visible={visibleUpsert}
                    setVisible={setVisibleUpsert}
                    staff={staff}
                    setStaff={setStaff}
                    onRefresh={handleFetchStaffs}
                />
            ),
        [visibleUpsert, staff, handleFetchStaffs]
    );

    return (
        <Container>
             <Row style={{ marginTop: 24, marginBottom: 32 }}>
                <LodoshaButton type="primary" icon={<PlusOutlined />} onClick={() => setVisibleUpsert(true)}>
                    New
                </LodoshaButton>
                <Upsert />
                <ModalPassword visible={visiblePassword} setVisible={setVisiblePassword} data={passwordData} />
            </Row>
            <Table
                bordered
                loading={loading}
                dataSource={dataSource}
                columns={columns}
                onRow={handleRow}
                pagination={{
                    current: page,
                    defaultCurrent: 1,
                    defaultPageSize: 15,
                    total,
                    onChange: handleChangePagination,
                }}
            />
        </Container>
    );
};

export default Staffs;
