import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useLocation, Route, Switch, useHistory } from 'react-router-dom';

//api
import { getExamLevel, deleteExamLevel, updateExamLevelOrder } from '../../../utils/api/E-Learning/exam';

//ant design
import { Space, Button, Modal, message, Row, Col } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, FilePdfOutlined, ImportOutlined } from '@ant-design/icons';

//constants and utils
import { ELEARNING } from '../../../constants/routes';
import { getNewLastOrder } from '../../../utils';

//components
import { LodoshaButton, Container } from '../../../components/UI/ui.style';
import Breadcrumb from '../E-LearningComponents/Breadcrumb';
import ExamLevelModal from './ExamLevelModal';
import SortableTable from '../E-LearningComponents/SortableTable';
import Manage from './Manage';
import QuestionDetail from './QuestionDetail';
import DragHandler from '../E-LearningComponents/DragHandler';
import ImportModal from './ImportModal';

export default function IndexPage() {
    const { pathname } = useLocation();

    const [visible, setVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [examLevel, setExamLevel] = useState([]);
    const [importVisible, setImportVisible] = useState(false);
    const [postData, setPostData] = useState({});

    const history = useHistory();

    //extract menuid from url
    const menuid = pathname?.split('/')[2];

    //main menu table will hide when chapter is selected
    const shouldMainMenuShow = pathname.split('/')[3] ? false : true;

    const isSubscribed = useRef(true);

    const columns = [
        {
            title: '',
            width: '5%',
            render: () => <DragHandler />,
            fxied: 'left',
        },
        {
            title: 'TITLE',
            dataIndex: 'title',
            render: (text, row, index) =>
                row.file && row.type === 'pdf file' ? (
                    <div className="pdf-column">
                        {row.title}
                        <FilePdfOutlined className="pdf-icon" />
                    </div>
                ) : (
                    row.title
                ),
        },
        {
            title: 'LEVEL',
            dataIndex: 'level',
            width: '10%',
            align: 'center',
        },
        {
            title: 'QUESTIONS',
            dataIndex: 'question_count',
            width: '12%',
            align: 'center',
        },
        {
            title: 'TYPE',
            dataIndex: 'type',
            width: '10%',
            align: 'center',
        },
        {
            title: 'ACTIONS',
            dataIndex: 'actions',
            align: 'center',
            width: '15%',
            render: (text, row, index) => (
                <Space size="small" direction="horizontal">
                    <Button title="Edit" type="primary" icon={<EditOutlined />} onClick={(e) => handleEdit(e, row)} />
                    <Button
                        title="Delete"
                        type="danger"
                        icon={<DeleteOutlined />}
                        onClick={(e) => handleDelete(e, row.id)}
                    />
                </Space>
            ),
        },
    ];

    const data = useMemo(
        () =>
            examLevel &&
            examLevel.map((exam, index) => ({
                ...exam,
                key: exam.id,
                level: exam.level,
            })),
        [examLevel]
    );

    const handleFetchExamLevel = useCallback(async () => {
        setLoading(true);
        const response = await getExamLevel();
        console.log(response);
        if (response && response.errorCode !== 0) {
            setLoading(false);
            message.error(`Couldn't get exam level,${response?.message}`);
        }
        setExamLevel(response && response.data);
        setLoading(false);
    }, []);

    const handleEdit = (e, row) => {
        e.stopPropagation();
        setPostData(row);
        setUpdateVisible(true);
    };

    const handleDelete = (e, examId) => {
        e.stopPropagation();
        Modal.confirm({
            title: 'Are you sure you want to delete this post?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await deleteExamLevel(examId);
                    if (response && response.errorCode === 0) {
                        message.success(`Deleted successfully!`);
                        handleFetchExamLevel();
                    } else {
                        message.error(`Couldn't delete exam level. ${response.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request failed!`);
                }
            },
            onCancel() {},
        });
    };

    const handleRow = (record, index) => {
        return {
            onClick: (e) => {
                e.stopPropagation();
                //if record contain pdf file, open new tab for preview
                if (record.file && record.type === 'pdf file') {
                    const win = window.open(record.file, '_blank');
                    win.focus();
                    return;
                }
                //save exam level and use it for question section type creation
                window.localStorage.setItem('selected_level', JSON.stringify(record.level));

                return history.push(`${ELEARNING}/${menuid}/${record.id}`);
            },
        };
    };

    const Upsert = useCallback(
        () =>
            visible && (
                <ExamLevelModal
                    visible={visible}
                    setVisible={setVisible}
                    postData={postData}
                    setPostData={setPostData}
                    isCreate
                    title="Add New Exam"
                    onRefresh={handleFetchExamLevel}
                />
            ),
        [visible, postData, handleFetchExamLevel]
    );

    const Update = useCallback(
        () =>
            updateVisible && (
                <ExamLevelModal
                    isUpdate
                    visible={updateVisible}
                    setVisible={setUpdateVisible}
                    postData={postData}
                    setPostData={setPostData}
                    title="Update Exam"
                    onRefresh={handleFetchExamLevel}
                />
            ),
        [updateVisible, postData, handleFetchExamLevel]
    );

    const ImportExam = useCallback(
        () =>
            importVisible && (
                <ImportModal
                    visible={importVisible}
                    setVisible={setImportVisible}
                    postData={postData}
                    setPostData={setPostData}
                    onRefresh={handleFetchExamLevel}
                />
            ),
        [importVisible, postData, handleFetchExamLevel]
    );

    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchExamLevel();
        }
        return () => (isSubscribed.current = false);
    }, [handleFetchExamLevel]);

    useEffect(() => {
        const getLastOrder = () => {
            let lastOrder = getNewLastOrder(examLevel);
            setPostData((prev) => ({ ...prev, order: lastOrder, menu: menuid }));
        };
        getLastOrder();
    }, [examLevel, visible, importVisible, menuid]);

    return (
        <div>
            <Breadcrumb menuid={menuid} />
            {shouldMainMenuShow && (
                <Container>
                    <Row justify="space-between">
                        <Col>
                            <LodoshaButton
                                type="primary"
                                className="btn-margin-bottom"
                                onClick={() => setVisible(true)}
                            >
                                <PlusOutlined />
                                Add New
                            </LodoshaButton>
                        </Col>
                        <Col>
                            <LodoshaButton
                                type="ghost"
                                className="btn-margin-bottom"
                                onClick={() => setImportVisible(true)}
                            >
                                <ImportOutlined />
                                Import
                            </LodoshaButton>
                            <ImportExam />
                        </Col>
                    </Row>

                    <SortableTable
                        data={data}
                        loading={loading}
                        setLoading={setLoading}
                        columns={columns}
                        handleRow={handleRow}
                        onUpdateOrder={updateExamLevelOrder}
                    />
                    <Upsert />
                    <Update />
                </Container>
            )}
            <Switch>
                <Route exact component={QuestionDetail} path={`${ELEARNING}/:menuid/:examId/:questionId`} />
                <Route component={Manage} exact path={`${ELEARNING}/:menuid/:examId`} />
            </Switch>
        </div>
    );
}
