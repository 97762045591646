import { createAPIELearning } from '../../api';

//chapter
export const getChaptersByMenuID = (menuID) => {
    return createAPIELearning()
        .get(`/admin-chapter/${menuID}`)
        .then((response) => response.data)
        .catch((error) => console.log('getChaptersByMenuID', error));
};

export const postNewChapter = (menuId, formData) => {
    const config = { 'content-type': 'multipart/form-data' };
    return createAPIELearning()
        .post(`/admin-chapter/create/${menuId}`, formData, config)
        .then((response) => response.data)
        .catch((err) => console.log('addNewChapter', err));
};

export const updateChapter = (id, formData) => {
    const config = { 'content-type': 'multipart/form-data' };
    return createAPIELearning()
        .patch(`/admin-chapter/${id}`, formData, config)
        .then((response) => response.data)
        .catch((err) => console.log('updateJapaneseChapter', err));
};

export const deleteChapter = (id) => {
    return createAPIELearning()
        .delete(`/admin-chapter/${id}`)
        .then((response) => response.data)
        .catch((err) => console.log('deleteJapaneseChapter', err));
};

export const updateChapterOrder = (info) => {
    return createAPIELearning()
        .patch(`/admin-chapter/update-order`, info)
        .then((response) => response.data)
        .catch((err) => `updateChapterOrder,${err.message}`);
};

//lesson
export const getLessonsByChapter = (chapterid) => {
    return createAPIELearning()
        .get(`/admin-lesson/${chapterid}`)
        .then((response) => response.data)
        .catch((err) => console.log('getLessonsByChapter', err));
};

export const postNewLesson = (formData) => {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    return createAPIELearning()
        .post(`/admin-lesson/create`, formData, config)
        .then((response) => response.data)
        .catch((err) => console.log('postNewJapaneseLesson', err));
};

export const updateLesson = (id, formData) => {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    return createAPIELearning()
        .patch(`/admin-lesson/${id}`, formData, config)
        .then((response) => response.data)
        .catch((err) => console.log('updateLesson', err));
};

export const deleteLesson = (id) => {
    return createAPIELearning()
        .delete(`/admin-lesson/${id}`)
        .then((response) => response.data)
        .catch((err) => console.log('deletLesson', err));
};

export const updateLessonOrder = (info) => {
    return createAPIELearning()
        .patch(`/admin-lesson/update-order`, info)
        .then((response) => response.data)
        .catch((err) => console.log(`updateLessonOrder,${err}`));
};

export const swapLessonOrder = (lessonId, targetLessonId) => {
    return createAPIELearning()
        .patch(`/admin-lesson/swap-order`, {
            lessonId: lessonId,
            targetLessonId: targetLessonId,
        })
        .then((response) => response.data)
        .catch((err) => console.log(`swapLessonOrder err: ${err.response}`));
};

//content
export const getJLPTContentsByLessonID = (lessonid) => {
    return createAPIELearning()
        .get(`/admin-jlpt-content/all/${lessonid}`)
        .then((response) => response.data)
        .catch((err) => console.log('getJLPTContentsByLessonId', err));
};

export const getJLPTContentDetail = (contentid) => {
    return createAPIELearning()
        .get(`/admin-jlpt-content/${contentid}`)
        .then((response) => response.data)
        .catch((err) => console.log('getJLPTContentDetail', err));
};

export const postNewJLPTContent = (info) => {
    console.log(info);
    return createAPIELearning()
        .post(`/admin-jlpt-content/create`, info)
        .then((response) => response.data)
        .catch((err) => console.log('postNewJLPTContent', err));
};

export const updateJLPTContent = (info) => {
    return createAPIELearning()
        .patch(`/admin-jlpt-content/${info.id}`, info)
        .then((response) => response.data)
        .catch((err) => console.log('updateContentDetail', err));
};

export const updateJLPTContentOrder = (info) => {
    return createAPIELearning()
        .patch(`/admin-jlpt-content/update-order`, info)
        .then((response) => response.data)
        .catch((err) => console.log('updateContentDetailOrder', err));
};

export const deleteJLPTContent = (contentid) => {
    return createAPIELearning()
        .delete(`/admin-jlpt-content/${contentid}`)
        .then((response) => response.data)
        .catch((err) => console.log('deleteJLPTContent', err));
};

export const getJLPTLessonExercises = (lessonid) => {
    return createAPIELearning()
        .get(`/admin-question-section-type/all-jlpt-exercises/${lessonid}`)
        .then((response) => response.data)
        .catch((err) => console.log('getJLPTLessonExercises', err));
};
