import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import getTextDisplay, { dataType, dataFavoriteStatus, ApplicationStatus } from "../../utils/data";
import ModalEmployerEmployees from "./ModalEmployerEmployees";

import { useLocation } from "react-router-dom";
import { Row, Col, Table, Button, message, Popconfirm, Select, Modal } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { getEmployerEmployees, deleteEmployerEmployee, updateStatus } from "../../utils/api/employer_employee";
import { Container, LodoshaButton, LodoshaSelect } from "../../components/UI/ui.style";

const EmployerEmployee = (props) => {
  const location = useLocation();
  const employer = location.state;
  const isSubscribed = useRef(true);

  const { Option } = Select;

  const [loading, setLoading] = useState(false);
  const [createVisible, setCreateVisible] = useState(false);
  const [employerEmployees, setEmployerEmployees] = useState([]);
  const [totalEmployerEmployees, setTotalEmployerEmployees] = useState(0);

  const [confirmVisible, setConfirmVisible] = useState(false);
  const [confirmData, setConfirmData] = useState({});

  const handleSaveStatus = React.useCallback(
    async (id, status) => {
      
      updateStatus(id, status)
        .then((data) => {
          if (data.errorCode === 0) {
            setEmployerEmployees(
              employerEmployees.map(item => 
                  item.id === id ? {...item, status : status } : item 
            ))
            message.success('Saved successfully!');
          } else {
              message.error(`Failed, ${data.message}`);
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error(`Uh oh, request failed: ${error}`);
        });
    }, [employerEmployees]
  );

  const columns = [
    { title: "NO", dataIndex: "no", key: "no", width: "4%" },
    { title: "NAME", dataIndex: "name", key: "name", width: "18%" },
    { title: "GENDER", dataIndex: "gender", key: "gender", width: "8%" },
    { title: "PHONE", dataIndex: "phone", key: "phone", width: "8%" },
    { title: "JAPANESE", dataIndex: "japanese_level", key: "japanese-level", width: "12%" },
    { title: "TAG", dataIndex: "tag", key: "tag", width: "15%" },
    { title: "STATUS", width: 140, key: "status", render: (item) =>  
    (<LodoshaSelect style={{width: 140}} value={item.status} onClick={(e)=>{e.preventDefault(); e.stopPropagation();}} onChange={(value) => handleSelectChangeFromTable(item.key, value)}>
      {dataFavoriteStatus.map(({ value, text }) => (
        <Option key={value} value={value}>
          {text}
        </Option>
      ))}
      </LodoshaSelect>)
    },
    { title: "NOTE", dataIndex: "note", key: "note", width: "20%" },
    {
      title: "",
      key: "actions",
      width: "5%",
      render: (record) => (
        <span style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
          <Popconfirm
            placement="topLeft"
            title={"Do you want to delete?"}
            onConfirm={(e) => {
              handleDelete(e, record);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button title="Delete" type="danger" className="action-button-margin" icon={<DeleteOutlined />} />
          </Popconfirm>
        </span>
      ),
    },
  ];

  const dataSource = useMemo(() => employerEmployees.length ?
    employerEmployees.map((employerEmployee, index) => {
      return {
        ...employerEmployee,
        key: employerEmployee.id,
        no: index + 1,
        name: employerEmployee.employee && employerEmployee.employee.name,
        gender: getTextDisplay(employerEmployee.employee && employerEmployee.employee.gender, dataType.GENDER),
        phone: employerEmployee.employee && employerEmployee.employee.phone,
        japanese_level: getTextDisplay(
          employerEmployee.employee && employerEmployee.employee.japanses_level,
          dataType.JAPANESE_LEVEL
        ),
        tag: getTextDisplay(employerEmployee.employee && employerEmployee.employee.tag, dataType.TAG_STATUS),
        note: employerEmployee.note,
        status: employerEmployee.status,
      }
    })
    :[], [employerEmployees]);

    // console.log(employerEmployees, dataSource)

  const handleFetchData = useCallback(
    async (page = 1) => {
      setLoading(true);
      try {
        getEmployerEmployees(employer.id, page).then((response) => {
          // console.log(response);
          if (response?.data && isSubscribed.current) {
            setTotalEmployerEmployees(response && response.total);
            setEmployerEmployees(response.data);
          }
        });
      } catch (error) {
        message.error(`Uh oh, request failed!`);
      }
      isSubscribed.current && setLoading(false);
    },
    [employer]
  );

  useEffect(() => {
    isSubscribed.current && handleFetchData();
    return () => {
      isSubscribed.current = false;
    };
  }, [handleFetchData]);

  const handlePageChange = (page) => {
    handleFetchData(page);
  };

  const handleRemoveEmployee = (id) => {
    // removeItem(id);
    setEmployerEmployees((prevState) => prevState.filter((item) => item.id !== id));
    setTotalEmployerEmployees(employerEmployees.length);
  };

  const handleAddEmployee = (data) => {
    setEmployerEmployees((prevState) => [data, ...prevState]);
    setTotalEmployerEmployees(employerEmployees.length);
  };

  const onRow = (record, _) => {
    return {
      onDoubleClick: (event) => {
        event.preventDefault();
        event.stopPropagation();
        handleRow(record);
      }, // click row
    };
  };

  const handleRow = async (record) => {
    try {
      props.history.push(`/employee/detail/${record.employee.id}`);
    } catch (error) {
      message.error(`Uh oh, request failed`);
    }
  };

  const handleDelete = async (e, record) => {
    e.preventDefault();
    // Delete
    deleteEmployerEmployee(record.id).then((response) => {
      if (response && response.errorCode === 0) {
        handleRemoveEmployee(record.id);
      } else {
        message.error(response && response.message);
      }
    });
  };

  const handleSelectChangeFromTable = (id, value) => {
    if (value === ApplicationStatus.PAID) {
      setConfirmData({id: id, value: value})
      setConfirmVisible(true);
    } else {
      handleSaveStatus(id, value);;
    }
  };

  return (
    <Container>
      <ModalEmployerEmployees
        width={600}
        visible={createVisible}
        setVisible={setCreateVisible}
        employer_id={employer && employer.id}
        handleRemoveEmployee={handleRemoveEmployee}
        handleAddEmployee={handleAddEmployee}
      />

      <Modal
        title={`The status will be change to "PAID"`}
        visible={confirmVisible}
        onOk={()=>{
          handleSaveStatus(confirmData.id, confirmData.value);
          setConfirmVisible(false);
        }}
        onCancel={()=>{
          setConfirmVisible(false);
        }}
      >
        <p>All applications of this candidate will be UNAVAILABLE and can not undo, please be sure before select OK</p>
      </Modal>

      <Row justify="space-between" align="middle" style={{ marginBottom: 20 }}>
        <Col>
          <LodoshaButton type="primary" icon={<PlusOutlined />} onClick={() => setCreateVisible(true)}>
            New
          </LodoshaButton>
        </Col>
        <Col>
          <h3 style={{ margin: 0 }}>Employer Name: {employer && employer.company_name}</h3>
        </Col>
        <Col>
          <h3 style={{ margin: 0 }}>Contact Person: {employer && employer.representation_name}</h3>
        </Col>
        <Col>
          <h3 style={{ margin: 0 }}>Phone: {employer && employer.phone}</h3>
        </Col>
      </Row>
      <Table
        rowClassName={(_, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
        bordered
        loading={loading}
        onRow={onRow}
        dataSource={dataSource}
        columns={columns}
        pagination={{
          defaultCurrent: 1,
          defaultPageSize: 15,
          total: totalEmployerEmployees,
          onChange: handlePageChange,
        }}
      />
    </Container>
  );
};
export default EmployerEmployee;
