import React, { useState, useMemo } from 'react';
import { importCulture } from '../../../utils/api/E-Learning/culture';

//antd
import { Modal, Upload, Button, Col, Row, message, Form } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

//constant & utils

//components
import { LodoshaInput } from '../../../components/UI/ui.style';

export default function ImportModal({ visible, setVisible, postData, setPostData, onRefresh }) {
    const [fileList, setFileList] = useState();
    const [data, setData] = useState(postData);
    const [loading, setLoading] = useState(false);

    const { form } = Form.useForm();

    const examFormFields = useMemo(
        () => [
            {
                label: 'Culture Name',
                type: 'text',
                name: 'name',
                value: data?.name,
                span: 24,
                required: true,
            },
            {
                label: 'Type',
                type: 'text',
                name: 'type',
                value: 'Q&A',
                span: 24,
                required: true,
                disabled: true,
            },
        ],
        [data]
    );

    const handleImport = async () => {
        try {
            setLoading(true);
            data.type = 1;
            const response = await importCulture(data, fileList);
            if (response) {
                if (response.errorCode === 0) {
                    message.success('Successfully imported Exam.');
                    handleCancel();
                    onRefresh();
                } else {
                    message.error(`Couldn't import, please try again: ${response.message}`);
                    setLoading(false);
                }
            } else {
                message.error(`Couldn't import, please try again.`);
                setLoading(false);
            }
        } catch (error) {
            message.error(`Couldn't import ${error.message}`);
            setLoading(false);
        }
    };

    const handleChooseFile = (file) => {
        setFileList(file.file);
    };

    const handleCancel = () => {
        setFileList();
        setPostData({});
        setVisible(false);
    };

    const handleFileRemove = () => {
        setFileList();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({ ...prev, [name]: value }));
    };

    const beforeUpload = () => {
        return false;
    };

    return (
        <Modal footer={null} title="Import Culture" visible={visible} onCancel={handleCancel} maskClosable={false}>
            <Row>
                <Col span={24}>
                    <Form form={form} layout="vertical">
                        <Row>
                            {examFormFields?.map((field, index) => {
                                const { type, label, name, value, required, disabled, span } = field;
                                return (
                                    <Col span={span} key={index}>
                                        <Form.Item
                                            rules={[{ required: required, message: 'Data required!' }]}
                                            label={`${label}:`}
                                            name={name}
                                            initialValue={value}
                                        >
                                            <LodoshaInput
                                                type={type}
                                                disabled={disabled && true}
                                                onChange={handleChange}
                                                value={value}
                                                name={name}
                                            />
                                        </Form.Item>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Form>
                </Col>
                <Col span={24}>
                    <Upload
                        accept=".csv"
                        beforeUpload={beforeUpload}
                        onChange={handleChooseFile}
                        onRemove={handleFileRemove}
                    >
                        <Button icon={<UploadOutlined />}>Select CSV File</Button>
                    </Upload>
                    <a
                        href={`https://docs.google.com/uc?export=download&id=1_-a4Z0BnD8LytCURuNLKUJ5a8KRYX8-i`}
                        download
                        style={{ textDecoration: 'underline' }}
                    >
                        <p style={{ marginTop: '1rem' }}>Download CSV sample for Culture.</p>
                    </a>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button type="primary" disabled={!fileList && true} onClick={handleImport} loading={loading}>
                            Import
                        </Button>
                    </div>
                </Col>
            </Row>
        </Modal>
    );
}
