import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';

//api
import { deleteCultureDetail, updateCultureDetailOrder } from '../../../../utils/api/E-Learning/culture';

//ant-design
import { Space, Button, message, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

//components
import { Container, LodoshaButton } from '../../../../components/UI/ui.style';
import QuestionAndAnswerModal from './QuestionAndAnswerModal';
import SortableTable from '../../E-LearningComponents/SortableTable';
import DragHandler from '../../E-LearningComponents/DragHandler';

import { ELEARNING } from '../../../../constants/routes';
import { getELearningItems, getNewLastOrder } from '../../../../utils';
import NextPrevActionButtonGroup from '../../E-LearningComponents/NextPrevActionButtonGroup';

export default function QuestionAndAnswerList({ data = [], handleFetchCultureDetails, loading, setLoading }) {
    const { cultureid, menuid } = useParams();
    const history = useHistory();

    const isSubscribed = useRef(true);

    const [visible, setVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [postData, setPostData] = useState([]);

    let columns = [
        {
            title: '',
            dataIndex: 'no',
            align: 'center',
            fixed: 'left',
            width: '5%',

            render: (text, row, index) => <DragHandler />,
        },
        { title: 'TITLE', dataIndex: 'title', width: '20%', fixed: 'left' },
        { title: 'CHOICE ONE', dataIndex: 'choice_one', width: '15%', align: 'center' },
        { title: 'CHOICE TWO', dataIndex: 'choice_two', width: '15%', align: 'center' },
        { title: 'CHOICE THREE', dataIndex: 'choice_three', width: '15%', align: 'center' },
        { title: 'CHOICE FOUR', dataIndex: 'choice_four', width: '15%', align: 'center' },
        { title: 'ANSWER', dataIndex: 'answer', width: '15%', align: 'center' },
        {
            title: 'ACTIONS',
            dataIndex: 'actions',
            align: 'center',
            width: '10%',
            fixed: 'right',
            render: (text, row, index) => (
                <Space>
                    <Button type="primary" onClick={(e) => handleEdit(e, row)} icon={<EditOutlined />} />
                    <Button type="danger" onClick={(e) => handleDelete(e, row.id)} icon={<DeleteOutlined />} />
                </Space>
            ),
        },
    ];

    const Upsert = useCallback(
        () =>
            visible && (
                <QuestionAndAnswerModal
                    isCreate
                    postData={postData}
                    setPostData={setPostData}
                    onRefresh={handleFetchCultureDetails}
                    title="ADD NEW CULTURE DETAIL"
                    visible={visible}
                    setVisible={setVisible}
                />
            ),
        [visible, postData, handleFetchCultureDetails]
    );

    const Update = useCallback(
        () =>
            updateVisible && (
                <QuestionAndAnswerModal
                    isUpdate
                    setPostData={setPostData}
                    postData={postData}
                    title="UPDATE CULTURE DETAIL"
                    onRefresh={handleFetchCultureDetails}
                    visible={updateVisible}
                    setVisible={setUpdateVisible}
                />
            ),
        [postData, updateVisible, handleFetchCultureDetails]
    );

    const handleEdit = (e, row) => {
        e.stopPropagation();
        setUpdateVisible(true);
        setPostData(row);
    };

    const handleRow = (record) => {
        return {
            onClick: (e) => {
                try {
                    let cloneData = [...data];
                    
                    cloneData = cloneData.map((cD) => ({
                        ...cD,
                        nav_title: `${cD.title.length > 25 ? `${cD.title.slice(0, 25)}...` : cD.title}`,
                    }));

                    const eLearningItems = getELearningItems(cloneData, 'nav_title');

                    localStorage.setItem('e-learning-contents', JSON.stringify(eLearningItems));
                    return history.push(`${ELEARNING}/${menuid}/${cultureid}/${record.id}`);
                } catch (error) {
                    message.error('Set to localstorage failed');
                }
            },
        };
    };

    const handleDelete = (e, id) => {
        e.stopPropagation();
        Modal.confirm({
            title: 'Are you sure you want to delete this?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await deleteCultureDetail(id);
                    if (response && response.errorCode === 0) {
                        message.success(`Deleted successfully!`);
                        handleFetchCultureDetails();
                    } else {
                        message.error(`Failed, ${response.data.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request to delete culture failed!`);
                }
            },
            onCancel() {},
        });
    };

    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchCultureDetails();
        }
        return () => {
            isSubscribed.current = false;
        };
    }, [handleFetchCultureDetails]);

    //auto order
    useEffect(() => {
        const getLastOrder = () => {
            let lastOrder = getNewLastOrder(data);
            setPostData((prev) => ({ ...prev, order: lastOrder, culture_id: cultureid }));
        };
        getLastOrder();
    }, [visible, cultureid, data]);

    return (
        <Container>
            <div className="btn-margin-bottom">
                <NextPrevActionButtonGroup
                    currentItemId={cultureid}
                    onClickAction={handleFetchCultureDetails}
                    localStorageKey="e-learning-chapters"
                />
            </div>
            <LodoshaButton type="primary" className="btn-margin-bottom" onClick={() => setVisible(true)}>
                <PlusOutlined />
                Add New
            </LodoshaButton>
            <Upsert />
            <Update />
            <SortableTable
                handleRow={handleRow}
                loading={loading}
                setLoading={setLoading}
                data={data}
                columns={columns}
                onUpdateOrder={updateCultureDetailOrder}
                scrollX={1440}
            />
        </Container>
    );
}
