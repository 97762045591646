import React from 'react';
import { Form, Row, Col, Select } from 'antd';
import { LodoshaSelect, LodoshaSearch, LodoshaDatePicker } from '../components/UI/ui.style';

const { Option } = Select;

const Search = ({ items = [], span = 4 }) => {
    return (
        <Form layout="vertical">
            <Row gutter={24} style={{ flexWrap: 'nowrap', overflow: 'scroll', overflowY: "hidden" }}>
                {items.map((item) => (
                    <Col key={item.label} md={span}>
                        <Form.Item label={item.label}>
                            {item.type === 'select' ? (
                                <LodoshaSelect
                                    placeholder="Select an option"
                                    value={item.value ? item.value : null}
                                    onSelect={(value) => item.onSelect(value)}
                                >
                                    <Option value={null}>None</Option>
                                    {item.options.map(({ value, text }) => (
                                        <Option key={value} value={value}>
                                            {text}
                                        </Option>
                                    ))}
                                </LodoshaSelect>
                            ) : item.type === 'date' ? (
                                <LodoshaDatePicker value={item.value} onChange={(value) => item.onSelect(value)} />
                            ) : (
                                <LodoshaSearch
                                    placeholder="Keyword"
                                    value={item.value ? item.value : null}
                                    // onKeyDown={(e) => console.log('typing...')}
                                    // onKeyUp={(e) => console.log('stop')}
                                    onChange={(e) => item.onSearch(e.target.value)}
                                />
                            )}
                        </Form.Item>
                    </Col>
                ))}
            </Row>
        </Form>
    );
};

export default Search;
