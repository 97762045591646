import React, { useState } from "react";
import { Modal, Form, Row, Col, Select, message, Space } from "antd";
import { LodoshaInput, LodoshaSelect, LodoshaTextArea, LodoshaButton } from "../../components/UI/ui.style";
import { createStaff, updateStaff } from '../../utils/api/staff';

const { Option } = Select;
// const { Dragger } = Upload;

const Upsert = ({ visible, setVisible, staff, setStaff, onRefresh }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(staff);
  // const [fileList, setFileList] = useState([]);

  const handleCancel = () => {
    setVisible(false);
    setData();
    setStaff();
  };

  const handleChange = (name, value) => {
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeSelect = (name, value) => {
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  // const beforeUpload = () => {
  //   // Avoid after drop, it auto upload to server
  //   return false;
  // };

  // const handleChangeFile = (info) => {
  //   let fileList = [...info.fileList];
  //   // 1. Allow in list file only one
  //   fileList = fileList.slice(-1);
  //   // 2. Read from response and show file link
  //   fileList = fileList.map((file) => {
  //     if (file.response) {
  //       // Component will show file.url as link
  //       file.url = file.response.url;
  //     }
  //     return file;
  //   });

  //   setFileList(fileList);
  // };

  const handleSubmit = async (value) => {
    setLoading(true);

    // const config = { headers: { "content-type": "multipart/form-data" } };

    // console.log('data: ', data);
    // const formData = new FormData();
    // for (const key in data) {
    //   if (data[key]) {
    //     formData.append(key, data[key]);
    //   }
    // }

    // const files = fileList ? Object.values(fileList) : [];
    // files.length > 0 && formData.append("image", files[0].originFileObj);

    try {
      // const response = data?.id
      //   ? await API().patch(`/partner/${data.id}`, formData, config)
      //   : await API().post(`/partner/create`, formData, config);
      
      const response = data?.id
        ? await updateStaff(data, data.id)
        : await createStaff(data)

      if (response?.errorCode === 0) {
        message.success(`${data.id ? `Updated` : `Created`} successfully!`);
        // setFileList([]);
        setData();
        setStaff();
        setVisible(false);
        onRefresh();
      } else {
        message.error(`Failed, ${response.message}`);
      }
    } catch (error) {
      message.error(`Uh oh, request failed!`);
    }
  };

  const items = [
    { type: "text", label: "Name", name: "name", value: staff?.name, required: true },
    { type: "text", label: "Position", name: "position", value: staff?.position, required: true },
    { type: "text", label: "Email", name: "email", value: staff?.email, required: true, disabled:  data?.id ? true : false},
    { type: "text", label: "Phone", name: "phone", value: staff?.phone },
    {
      type: "textarea",
      label: "Description",
      name: "description",
      value: staff?.description,
      span: 24,
    },
  ];

  return (
    <Modal visible={visible} maskClosable={false} title={"Create"} onCancel={handleCancel} width={768} footer={null}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={24}>
          {items.map((item) => (
            <Col key={item.name} span={item.span ? item.span : 12}>
              <Form.Item
                name={item.name}
                label={item.label + ":"}
                initialValue={item.value}
                rules={[{ required: item.required, message: "Data required!" }]}
              >
                {item.type === "select" ? (
                  <LodoshaSelect value={String(item.value)} onChange={(value) => handleChangeSelect(item.name, value)}>
                    {item.options.map(({ value, text }) => (
                      <Option key={value} value={value}>
                        {text}
                      </Option>
                    ))}
                  </LodoshaSelect>
                ) : item.type === "textarea" ? (
                  <LodoshaTextArea
                    placeholder={`Enter ${item.label.toLocaleLowerCase()}...`}
                    onChange={(e) => handleChange(item.name, e.target.value)}
                  />
                ) : (
                  <LodoshaInput
                    disabled={item.disabled ? item.disabled : false}
                    placeholder={`Enter ${item.label.toLocaleLowerCase()}`}
                    onChange={(e) => handleChange(item.name, e.target.value)}
                  />
                )}
              </Form.Item>
            </Col>
          ))}
          {/* <Col span={24}>
            <Form.Item label="Photo">
              <Dragger name="file" beforeUpload={beforeUpload} fileList={fileList} onChange={handleChangeFile}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">Support for a single or bulk upload.</p>
              </Dragger>
            </Form.Item>
          </Col> */}
          <Col span={24} style={{ textAlign: "center", marginTop: 16 }}>
            <Space align="center" direction="horizontal" size="middle">
              <LodoshaButton key="cancel" htmlType="button" onClick={handleCancel}>
                Cancel
              </LodoshaButton>
              <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                {data?.id ? "Update" : "Create"}
              </LodoshaButton>
            </Space>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Upsert;
