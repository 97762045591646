import React from "react";
import { Row, Col, Modal, Table, Popconfirm, Button, message } from "antd";
import { PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons";
import { LodoshaSearch } from "../../components/UI/ui.style";
import { searchEmployees, deletePostJobEmployee, createPostJobEmployee } from "../../utils/api/post_job_employee";

const timeout_typing_state = { typingTimeout: 0 };

const ModalEmployees = ({ visible, setVisible, postJobId, handleRemoveEmployee, handleAddEmployee }) => {
  const isSubscribed = React.useRef(true);
  const [loading, setLoading] = React.useState(false);
  const [employees, setEmployees] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [keyword, setKeyword] = React.useState("");
  const [timeoutTyping, setTimeoutTyping] = React.useState(timeout_typing_state);

  const handleFetchEmployees = React.useCallback(
    async (page = 1, keyword = "") => {
      setLoading(true);
      try {
        searchEmployees(page, keyword, "name, code, phone, id", postJobId).then((response) => {
        
          isSubscribed.current && setLoading(false);

          if (response.data && isSubscribed.current) {
            setEmployees(response.data);
            setTotal(response.total);
          }
        });
      } catch (error) {
        isSubscribed.current && setLoading(false);
        message.error(`Uh oh, request failed!`);
      }
    },
    [postJobId]
  );

  React.useEffect(() => {
    handleFetchEmployees();
    return () => {
      isSubscribed.current = false;
    };
  }, [handleFetchEmployees]);

  const handleCancel = (e) => {
    setVisible(false);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setKeyword(value);

    if (timeoutTyping.typingTimeout) {
      clearTimeout(timeoutTyping.typingTimeout);
    }

    setTimeoutTyping({
      typingTimeout: setTimeout(function () {
        handleFetchEmployees(1, value);
      }, 1000),
    });
  };

  const handleAdd = async (e, record) => {
    e.stopPropagation();
    // console.log("Adding", record);
    const data = { post_job_id: postJobId, employee_id: record.id };
    createPostJobEmployee(data).then((response) => {
      // console.log(response);
      if (response && response.errorCode === 0) {
        message.info("Added successfully!");
        setEmployees((prevState) =>
          prevState.map((item) => (item.id === record.id ? { ...item, post_job_employee_id: response.data.id } : item))
        );
        // send to main page
        handleAddEmployee(response.data);
      } else {
        message.error(response?.message);
      }
    });
  };

  const handleDelete = async (e, record) => {
    e.stopPropagation();
    // console.log("delete", record);
    deletePostJobEmployee(record.post_job_employee_id).then((response) => {
      if (response && response.errorCode === 0) {
        message.success("Removed successfully!");
        setEmployees((prevState) =>
          prevState.map((item) => (item.id === record.id ? { ...item, post_job_employee_id: "" } : item))
        );
        handleRemoveEmployee(record.post_job_employee_id);
      } else {
        message.error(response?.message);
      }
    });
  };

  const handlePageChange = (page) => {
    handleFetchEmployees(page, keyword);
  };

  const dataSource = employees.length
    ? employees.map((employee, index) => {
        return {
          ...employee,
          key: employee.id,
          no: index + 1,
          name: employee.name,
          phone: employee.phone,
          code: employee.code,
        };
      })
    : [];

  const columns = [
    { title: "CODE", dataIndex: "code", key: "code", width: "20%" },
    { title: "NAME", dataIndex: "name", key: "name", width: "40%" },
    { title: "PHONE", dataIndex: "phone", key: "phone", width: "20%" },
    {
      title: "",
      width: "5%",
      key: "actions",
      render: (record) => (
        <span style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
          {record.post_job_employee_id === "" ? (
            <Button
              title="Add"
              type={record.post_job_employee_id === "" ? "primary" : "danger"}
              className="action-button-margin"
              icon={record.post_job_employee_id === "" ? <PlusSquareOutlined /> : <MinusSquareOutlined />}
              onClick={(e) => handleAdd(e, record)}
            />
          ) : (
            <Popconfirm
              placement="topLeft"
              title={"Do you want to delete?"}
              onConfirm={(e) => handleDelete(e, record)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                title="Add"
                type={record.post_job_employee_id === "" ? "primary" : "danger"}
                icon={record.post_job_employee_id === "" ? <PlusSquareOutlined /> : <MinusSquareOutlined />}
              />
            </Popconfirm>
          )}
        </span>
      ),
    },
  ];

  return (
    <Modal visible={visible} maskClosable={false} onCancel={handleCancel} width={576} footer={null}>
      <Row>
        <Col span={24}>
          <h2>Search Candidates</h2>
        </Col>
        <Col span={24} style={{ marginBottom: 24 }}>
          <LodoshaSearch value={keyword} onChange={handleChange} />
        </Col>
        <Col span={24}>
          <Table
            bordered
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            pagination={{
              defaultCurrent: 1,
              defaultPageSize: 15,
              total,
              onChange: handlePageChange,
            }}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalEmployees;
