import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Table, Button, message, Space, Modal, Dropdown, Menu } from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    KeyOutlined,
    MoreOutlined,
    PlusOutlined,
    TableOutlined,
    DollarCircleOutlined,
    TeamOutlined,
    ProjectOutlined,
    PaperClipOutlined,
} from '@ant-design/icons';
import queryString from 'query-string';

//components
import ModalUpsert from './ModalUpsert';
import { Container, LodoshaButton } from '../../components/UI/ui.style';
import Search from '../../components/Search';
import ModalPassword from '../../components/ModalPassword';
import ModalPartnerJob from './ModalPartnerJob';
import ModalGenarateLike from '../../components/ModalGenarateLike';

//utils and constatns
import API from '../../utils/api';
import getTextDisplay, { dataType } from '../../utils/data';

const timeout_typing_state = { typingTimeout: 0 };

const PartnersPage = () => {
    const history = useHistory();
    const location = useLocation();
    const page = location.search ? Number(queryString.parse(location.search).page) : 1;

    const isSubscribed = useRef(true);
    const [loading, setLoading] = useState(false);
    const [partners, setPartners] = useState([]);
    const [partner, setPartner] = useState();
    const [total, setTotal] = useState(0);
    const [timeoutTyping, setTimeoutTyping] = useState(timeout_typing_state);
    const [keyword, setKeyword] = useState('');
    const [visibleUpsert, setVisibleUpsert] = useState(false);
    const [visiblePassword, setVisiblePassword] = useState(false);
    const [visiblePartnerJobs, setVisiblePartnerJobs] = useState(false);
    const [visibleGenerateLink, setVisitableGenerateLink] = useState(false);

    const handleFetchPartners = React.useCallback(async (page = 1, order = '', field = '') => {
        isSubscribed.current && setLoading(true);
        try {
            const response =
                page === 1
                    ? await API().get(`/partner?order=${order}&field=${field}&except=9`)
                    : await API().get(`/partner?order=${order}&field=${field}&offset=${(page - 1) * 15}&except=9`);

            isSubscribed.current && response?.data?.data && setPartners(response.data.data);
            isSubscribed.current && setLoading(false);
            setTotal(response.data.total);
        } catch (error) {
            isSubscribed.current && setLoading(false);
            message.error(`Uh oh, request failed!`);
        }
    }, []);

    useEffect(() => {
        handleFetchPartners(page);
    }, [page, handleFetchPartners]);

    const handleSearch = (value) => {
        setKeyword(value);
        if (timeoutTyping.typingTimeout) {
            clearTimeout(timeoutTyping.typingTimeout);
        }

        setTimeoutTyping({
            typingTimeout: setTimeout(async () => {
                setLoading(true);
                const response = await API().get(`/partner/search?keyword=${value}`);
                console.log(response);
                response?.data?.data && setPartners(response.data.data);
                setLoading(false);
            }, 1000),
        });
    };

    const handleHidePartner = async (e, record) => {
        e.stopPropagation();
        setLoading(true);
        const visible = !record.is_visible;
        const data = { visible };

        const newArr = partners.map((p) => {
            if (p.id === record.id) return { ...p, is_visible: visible };
            return p;
        });

        setPartners(newArr);

        const response = await API().put(`/partner/${record.id}`, data);
        if (response && response.data.errorCode === 0) {
            message.success((data.visible ? 'Visible' : 'Hide') + ' Partner Successful.');
            setLoading(false);
        } else {
            message.error('Update not success.');
            setLoading(false);
        }
    };

    const handlePassword = (e, record) => {
        e.stopPropagation();
        setPartner({ ...record, type: 'partner' });
        setVisiblePassword(true);
    };

    const handleGenerateLink = (e, record) => {
        e.stopPropagation();
        setPartner({ ...record });
        setVisitableGenerateLink(true);
    };

    const handleClickMenu = (event, item) => {
        event.domEvent.stopPropagation();

        switch (event.key) {
            case 'fees':
                handleDispatchFees(item);
                break;
            case 'products':
                handlePartnerProducts(item);
                break;
            case 'job':
                setVisiblePartnerJobs(true);
                setPartner(item);
                break;
            default:
                break;
        }
    };

    const handlePartnerProducts = (record) => {
        history.push(`/partners/partner-products/${record.id}`, record);
    };

    const handleDispatchFees = (record) => {
        history.push(`/partners/partner-fee/${record.id}`, record);
    };

    const handlePartnerEmployees = (e, record) => {
        e.stopPropagation();
        history.push(`/partners/partner-employees/${record.id}`, record);
    };

    const handleEdit = (e, record) => {
        e.stopPropagation();
        setPartner(record);
        setVisibleUpsert(true);
    };

    const handleDelete = (e, record) => {
        e.stopPropagation();
        Modal.confirm({
            title: `Are you sure delete ${record.name}?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await API().delete(`/partner/${record.id}`);
                    if (response.data.errorCode === 0) {
                        message.success(`Deleted successfully!`);
                        handleFetchPartners(page);
                    } else {
                        message.error(`Failed! ${response.data.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request failed!`);
                }
            },
            onCancel() {},
        });
    };

    const handleRow = (record, rowIndex) => {
        return {
            onClick: (e) => {
                e.stopPropagation();
                history.push(`/partners/detail/${record.id}`, record);
            }, // click row
        };
    };

    const handleChangePagination = (page) => {
        history.replace(`/partners${page !== 1 ? `?page=${page}` : ''}`);
    };

    const handleChangeTable = (_pagination, _filters, sorter) => {
        if (sorter) {
            const field = sorter ? sorter.columnKey : '';
            const order = sorter ? sorter.order : '';
            handleFetchPartners(page, order, field);
        }
    };

    const items = [{ label: 'Search:', type: 'text', value: keyword, onSearch: handleSearch }];

    const menu = (item) => (
        <Menu onClick={(event) => handleClickMenu(event, item)}>
            <Menu.Item key="fees">
                <DollarCircleOutlined />
                Fee
            </Menu.Item>
            <Menu.Item key="products">
                <TableOutlined />
                Products
            </Menu.Item>
            <Menu.Item key="job">
                <ProjectOutlined />
                Job
            </Menu.Item>
        </Menu>
    );

    const dataSource =
        partners.length &&
        partners.map((partner) => {
            return { ...partner, key: partner.id };
        });

    const columns = [
        { title: 'COMPANY NAME', dataIndex: 'name', key: 'name', fixed: 'left', sorter: () => {}, width: '15%' },
        {
            title: 'CONTACT PERSON',
            dataIndex: 'contact_name',
            key: 'contact-name',
            fixed: 'left',
            sorter: () => {},
            width: '18%',
        },
        {
            title: 'GENDER',
            dataIndex: 'gender',
            key: 'gender',
            width: 100,
            render: (value) => getTextDisplay(value, dataType.GENDER),
            sorter: () => {},
        },
        { title: 'PHONE', dataIndex: 'phone', key: 'phone', width: 120, sorter: () => {} },
        { title: 'EMAIL', dataIndex: 'email', key: 'email', sorter: () => {}, width: '15%' },
        { title: 'POST JOBS', dataIndex: 'post_jobs', key: 'post_jobs', width: '12%' },
        { title: 'PASS', dataIndex: 'pass_interview', key: 'pass_interview', width: '7%' },
        {
            title: '',
            key: 'actions',
            render: (record) => (
                <Space align="center" direction="horizontal" size="small">
                    <Dropdown overlay={menu(record)}>
                        <Button
                            icon={<MoreOutlined />}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        />
                    </Dropdown>
                    <Button
                        type="primary"
                        icon={record?.is_visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        onClick={(e) => handleHidePartner(e, record)}
                    />
                    <Button type="primary" icon={<KeyOutlined />} onClick={(e) => handlePassword(e, record)} />
                    <Button type="primary" icon={<TeamOutlined />} onClick={(e) => handlePartnerEmployees(e, record)} />
                    <Button
                        type="primary"
                        icon={<PaperClipOutlined />}
                        onClick={(e) => handleGenerateLink(e, record)}
                    />
                    <Button type="primary" icon={<EditOutlined />} onClick={(e) => handleEdit(e, record)} />
                    <Button type="danger" icon={<DeleteOutlined />} onClick={(e) => handleDelete(e, record)} />
                </Space>
            ),
            width: '25%',
        },
    ];

    return (
        <Container>
            <Search items={items} />
            <Row style={{ marginTop: 24, marginBottom: 32 }}>
                <LodoshaButton type="primary" icon={<PlusOutlined />} onClick={() => setVisibleUpsert(true)}>
                    New
                </LodoshaButton>
            </Row>
            {visibleUpsert && (
                <ModalUpsert
                    visible={visibleUpsert}
                    setVisible={setVisibleUpsert}
                    partner={partner}
                    setPartner={setPartner}
                    onRefresh={handleFetchPartners}
                    page={page}
                />
            )}
            {visiblePassword && (
                <ModalPassword visible={visiblePassword} setVisible={setVisiblePassword} data={partner} />
            )}

            {visibleGenerateLink && (
                <ModalGenarateLike
                    visible={visibleGenerateLink}
                    setVisible={setVisitableGenerateLink}
                    partner={partner}
                />
            )}

            {visiblePartnerJobs && (
                <ModalPartnerJob visible={visiblePartnerJobs} setVisible={setVisiblePartnerJobs} partner={partner} />
            )}
            <Table
                bordered
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                onRow={handleRow}
                onChange={handleChangeTable}
                pagination={{
                    onChange: handleChangePagination,
                    current: page,
                    defaultCurrent: 1,
                    defaultPageSize: 15,
                    total,
                }}
                scroll={{ x: 1200 }}
            />
        </Container>
    );
};

export default PartnersPage;
