import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

//api
import { deleteCultureDetail, updateCultureDetailOrder } from '../../../../utils/api/E-Learning/culture';

//antd
import { Space, Button, message, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

//components
import { Container, LodoshaButton } from '../../../../components/UI/ui.style';
import CultureBlogModal from './CultureBlogModal';
import SortableTable from '../../E-LearningComponents/SortableTable';
import DragHandler from '../../E-LearningComponents/DragHandler';

//utils and constants
import { getNewLastOrder } from '../../../../utils';

export default function CultureBlogList({ data, handleFetchCultureDetails, loading, setLoading }) {
    const { cultureid } = useParams();
    console.log("cukture blog call")
    const [visible, setVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [blog, setBlog] = useState([]);

    let columns = [
        {
            title: '',
            dataIndex: 'no',
            align: 'center',
            fixed: 'left',
            width: '5%',

            render: (text, row, index) => <DragHandler />,
        },
        { title: 'TITLE', dataIndex: 'title', width: '85%', fixed: 'left' },
        {
            title: 'ACTIONS',
            dataIndex: 'actions',
            align: 'center',
            width: '10%',
            fixed: 'right',
            render: (text, row, index) => (
                <Space>
                    <Button type="primary" onClick={(e) => handleEdit(e, row)} icon={<EditOutlined />} />
                    <Button type="danger" onClick={(e) => handleDelete(e, row.id)} icon={<DeleteOutlined />} />
                </Space>
            ),
        },
    ];

    const Upsert = useCallback(
        () =>
            visible && (
                <CultureBlogModal
                    blog={blog}
                    setBlog={setBlog}
                    onRefresh={handleFetchCultureDetails}
                    visible={visible}
                    setVisible={setVisible}
                />
            ),
        [visible, blog, handleFetchCultureDetails]
    );

    const Update = useCallback(
        () =>
            updateVisible && (
                <CultureBlogModal
                    setBlog={setBlog}
                    blog={blog}
                    onRefresh={handleFetchCultureDetails}
                    visible={updateVisible}
                    setVisible={setUpdateVisible}
                />
            ),
        [blog, updateVisible, handleFetchCultureDetails]
    );

    const handleEdit = (e, row) => {
        e.stopPropagation();
        setBlog(row);
        setUpdateVisible(true);
    };

    const handleDelete = (e, id) => {
        e.stopPropagation();
        Modal.confirm({
            title: 'Are you sure you want to delete this?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await deleteCultureDetail(id);
                    if (response && response.errorCode === 0) {
                        message.success(`Deleted successfully!`);
                        handleFetchCultureDetails();
                    } else {
                        message.error(`Failed, ${response.data.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request to delete culture failed!`);
                }
            },
            onCancel() {},
        });
    };

    //auto order
    useEffect(() => {
        const getLastOrder = () => {
            let lastOrder = getNewLastOrder(data);
            setBlog((prev) => ({ ...prev, order: lastOrder, culture_id: cultureid }));
        };
        getLastOrder();
    }, [visible, cultureid, data]);

    return (
        <Container>
            <LodoshaButton type="primary" className="btn-margin-bottom" onClick={() => setVisible(true)}>
                <PlusOutlined />
                Add New
            </LodoshaButton>
            <Upsert />
            <Update />
            <SortableTable
                loading={loading}
                setLoading={setLoading}
                data={data && data}
                columns={columns}
                onUpdateOrder={updateCultureDetailOrder}
            />
        </Container>
    );
}
