import { useContext, useState } from 'react';
import AppContext from '../../../contexts/AppContext';
import {
    dataJftBasicLevel,
    dataGender,
    dataWeddingStatus,
    dataJapaneseLevel,
    dataStudyLevel,
    dataReligion,
    dataEverToJapan,
    dataTagStatus,
    dataFinancialStatus,
    dataCandidateRecordType,
    dataPhoneCompany,
    dataNoOutboundCall,
} from '../../../utils/data';

export const useBtnList = () => {
    const [btnList, setBtnList] = useState([]);
    const { dataJobs } = useContext(AppContext);

    //prevent duplicate btn from the same datatype(gender,job....)
    const checkExistAndSet = (selectedType, key) => {
        const isTheSameKey = btnList.find((btn) => btn.key === key);
        if (isTheSameKey) {
            const isExist = btnList.includes(selectedType);
            //change value
            if (!isExist) {
                selectedType.key = key;
                setBtnList((prev) => [...prev.filter((btn) => btn.key !== key), selectedType]);
            }
        } else {
            selectedType.key = key;
            setBtnList((prev) => [...prev, selectedType]);
        }
    };

    const setSelectedValue = (values) => {
        const {
            job_id,
            gender,
            type,
            phone_company,
            from_age,
            to_age,
            japanses_level,
            jft_basic,
            wedding_status,
            ever_to_japan,
            study_level,
            religion,
            financial_status,
            tag,
            keyword,
            no_call,
        } = values;
        if (keyword) {
            const selectedKeyword = {
                value: keyword,
                text: keyword,
            };
            checkExistAndSet(selectedKeyword, 'keyword');
        }
        if (job_id) {
            const selectedJob = dataJobs && dataJobs.find((job) => job.value === job_id);
            checkExistAndSet(selectedJob, 'job_id');
        }
        if (gender) {
            const selectedGender = dataGender.find((d) => d.value === gender);
            checkExistAndSet(selectedGender, 'gender');
        }
        if (type) {
            const selectedCandidateRecordType = dataCandidateRecordType.find((d) => d.value === type);
            checkExistAndSet(selectedCandidateRecordType, 'type');
        }
        if (phone_company) {
            const selectedPhoneCompany = dataPhoneCompany.find((d) => d.value === phone_company);
            checkExistAndSet(selectedPhoneCompany, 'phone_company');
        }
        if (from_age) {
            const selectedAge = {
                value: from_age,
                text: from_age,
            };
            checkExistAndSet(selectedAge, 'from_age');
        }
        if (to_age) {
            const selectedAge = {
                value: to_age,
                text: to_age,
            };
            checkExistAndSet(selectedAge, 'to_age');
        }
        if (japanses_level) {
            const selectedJapaneseLevel = dataJapaneseLevel.find((d) => d.value === japanses_level);

            checkExistAndSet(selectedJapaneseLevel, 'japanses_level');
        }
        if (jft_basic) {
            const selectedJftLevel = dataJftBasicLevel.find((d) => d.value === jft_basic);
            checkExistAndSet(selectedJftLevel, 'jft_basic');
        }
        if (wedding_status) {
            const selectedWeddingStatus = dataWeddingStatus.find((d) => d.value === wedding_status);
            checkExistAndSet(selectedWeddingStatus);
        }
        if (ever_to_japan) {
            const selectedEverToJapan = dataEverToJapan.find((d) => d.value === ever_to_japan);
            checkExistAndSet(selectedEverToJapan, 'ever_to_japan');
        }
        if (study_level) {
            const selectedStudyLevel = dataStudyLevel.find((d) => d.value === study_level);
            checkExistAndSet(selectedStudyLevel, 'study_level');
        }
        if (religion) {
            const selectedReligion = dataReligion.find((d) => d.value === religion);
            checkExistAndSet(selectedReligion, 'religion');
        }
        if (financial_status) {
            const selectedFinancialStatus = dataFinancialStatus.find((d) => d.value === financial_status);
            checkExistAndSet(selectedFinancialStatus, 'financial_status');
        }
        if (tag) {
            const selectedTag = dataTagStatus.find((d) => d.value === tag);
            checkExistAndSet(selectedTag, 'tag');
        }
        if (no_call) {
            const selectedNoOutbound = dataNoOutboundCall.find((d) => d.value === no_call);
            checkExistAndSet({ value: selectedNoOutbound.value, text: 'No Outbound Call' }, 'no_call');
        }
    };

    return [btnList, setBtnList, setSelectedValue];
};
