import styled from '@emotion/styled';

const UpSertContainer = styled.div`
    .ql-editor {
        height: 440px;
    }

    .ql-snow .ql-editor pre.ql-syntax {
        background-color: #F3F6F9;
        color: #151F23;
        overflow: visible;
    }
`

export { UpSertContainer };
