import * as ROUTES from '../../constants/routes';

import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Table, Space, Select, message, Button } from 'antd';
import { Container, LodoshaButton, LodoshaSelect } from '../../components/UI/ui.style';
import { PhoneOutlined, SearchOutlined } from '@ant-design/icons';
import { getApplication } from '../../utils/api/application';
import { advanceSearch } from '../../utils/api/advance_search';
import { isNullOrUndefined } from '../../utils';

import moment from 'moment';
import queryString from 'query-string';
import getTextDisplay, { dataType } from '../../utils/data';
import ModalAdvanceSearch from '../../components/ModalAdvanceSearch';
import AppContext from '../../contexts/AppContext';
import ModalCallHistory from '../../components/ModalCallHistory';

const AdvanceSearchCmp = () => {
    const history = useHistory();
    const location = useLocation();
    const page = location.search ? Number(queryString.parse(location.search).page) : 1;
    // const qKeyword = location.search ? queryString.parse(location.search).keyword : '';
    // const qJob = location.search ? queryString.parse(location.search).job : '';
    // const qEverToJapan = location.search ? queryString.parse(location.search).ever_to_japan : '';
    // const qGender = location.search ? queryString.parse(location.search).gender : '';
    // const qWeddingStatus = location.search ? queryString.parse(location.search).wedding_status : '';

    const { dataJobs } = useContext(AppContext);

    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [entity, setEntity] = useState('');
    const [value, setValue] = useState();
    const [visibleSearch, setVisibleSearch] = useState(false);
    const [visibleCallHistory, setVisibleCallHistory] = useState(false);
    const [employee, setEmployee] = useState();

    const { Option } = Select;

    console.log(value)

    const handleFetchEmployees = useCallback(async (page = 1, data = '', order = '', field = '') => {
        if (isNullOrUndefined(data)) {
            return;
        }
        setLoading(true);

        try {
            let application = null;
            try {
                let dataJSON = JSON.parse(data);
                application = dataJSON['application'];
            } catch (e) {
                application = null;
            }

            if (!isNullOrUndefined(application)) {
                const response = await getApplication(application);
                response?.data && setItems(response.data);
                setTotal(response.total);
            } else {
                const response = await advanceSearch(page, data);
                response.data && setItems(response.data);
                setTotal(response.total);
            }

            setLoading(false);
        } catch (error) {
            console.log('error: ', error);
            setLoading(false);
            message.error('Uh oh, request failed!');
        }
    }, []);

    const handleCallHistories = (e, item) => {
        e.stopPropagation();
        setVisibleCallHistory(true);
        setEmployee(item);
    };

    useEffect(() => {
        const advanceSearchType = localStorage.getItem('advance-search');
        const advanceSearchValue = localStorage.getItem('advance-search-value');

        advanceSearchType && setEntity(advanceSearchType);
        advanceSearchValue && setValue(advanceSearchValue);

        switch (entity) {
            case 'candidate':
                handleFetchEmployees(page, advanceSearchValue);
                break;
            default:
                break;
        }
    }, [page, entity, handleFetchEmployees]);

    const columns = useMemo(() => {
        switch (entity) {
            case 'candidate':
                return [
                    {
                        title: '#',
                        dataIndex: 'no',
                        key: 'updated_at',
                        width: 65,
                        fixed: 'left',
                        sorter: () => {},
                        align: 'center',
                    },
                    { title: 'NAME', dataIndex: 'name', key: 'name', fixed: 'left', sorter: () => {} },
                    { title: 'CODE', dataIndex: 'code', key: 'code', width: 110, sorter: () => {} },
                    {
                        title: 'GENDER',
                        dataIndex: 'gender',
                        key: 'gender',
                        width: 110,
                        render: (item) => <span>{getTextDisplay(item, dataType.GENDER)}</span>,
                        sorter: () => {},
                    },
                    { title: 'PHONE', dataIndex: 'phone', key: 'phone', width: 130, sorter: () => {} },
                    {
                        title: 'TYPE',
                        dataIndex: 'type',
                        key: 'type',
                        render: (item) => <span>{getTextDisplay(item, dataType.CANDIDATE_RCORD_TYPE)}</span>,
                    },
                    {
                        title: 'LEVEL',
                        dataIndex: 'japanses_level',
                        key: 'japanese-level',
                        width: 120,
                        render: (item) => <span>{getTextDisplay(item, dataType.JAPANESE_LEVEL)}</span>,
                        sorter: () => {},
                    },
                    {
                        title: 'CALL',
                        dataIndex: 'employee_call_histories',
                        key: 'employee_call_histories',
                        width: 80,
                    },
                    {
                        title: 'DONE',
                        dataIndex: 'percentage',
                        key: 'percentage',
                        width: 130,
                        render: (item) => <span>{`${item ? item : 0}%`}</span>,
                        sorter: () => {},
                    },
                    {
                        title: 'TAG',
                        dataIndex: 'tag',
                        key: 'tag',
                        render: (item) => <span>{item === 0 ? '-' : item}</span>,
                        sorter: () => {},
                    },
                    {
                        title: 'STATUS',
                        key: 'status',
                        render: (item) => <span>{getTextDisplay(item.status, dataType.EMPLOYEE_STATUS)}</span>,
                        sorter: () => {},
                    },
                    {
                        title: '',
                        align: 'center',
                        width: 70,
                        key: 'call-histories',
                        fixed: 'right',
                        render: (text, row, index) => (
                            <Space size="small">
                                <Button
                                    type="primary"
                                    title="Delete"
                                    onClick={(e) => handleCallHistories(e, row)}
                                    icon={<PhoneOutlined />}
                                />
                            </Space>
                        ),
                    },
                ];
            case 'employer':
                return [];

            case 'partner':
                return [];
            default:
                return [];
        }
    }, [entity]);

    const dataSource = useMemo(
        () =>
            items.length
                ? items.map((item, index) => {
                      switch (entity) {
                          case 'candidate':
                              return {
                                  ...item,
                                  no: index + 1 + (page - 1) * 15,
                                  key: item['id'],
                                  birth_date: item['birth_date']
                                      ? moment(item['birth_date'], 'YYYY-MM-DD').format('DD-MM-YYYY')
                                      : '',
                                  partner: item['partner'] ? item['partner']['id'] : '',
                                  tag: item['tag'] ? item['tag'] : 0,
                              };
                          case 'employer':
                              return {};
                          case 'partner':
                              return {};
                          default:
                              return {};
                      }
                  })
                : [],
        [items, page, entity]
    );

    const handleChangePagination = (page) => {
        history.replace(`/advance-search${page !== 1 ? `?page=${page}` : ''}`);
    };

    const handleSearchCallback = (data) => {
        if (data) {
            handleFetchEmployees(page, data);
            setValue(JSON.stringify(data));
        } else {
            setItems([]);
            setTotal(0);
            setValue();
        }

        history.replace(`/advance-search`);
    };

    const handleChange = (value) => {
        setEntity(value);
    };

    const handleRow = (record, rowIndex) => {
        return {
            onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                switch (entity) {
                    case 'candidate':
                        history.push(`${ROUTES.CANDIDATES_DETAIL}/${record.id}`, record);
                        break;
                    default:
                        break;
                }
            }, // click row
        };
    };

    return (
        <Container>
            <Row style={{ marginTop: 24, marginBottom: 32 }}>
                <Space size={'large'}>
                    <LodoshaSelect style={{ width: 250 }} value={entity} onChange={(value) => handleChange(value)}>
                        <Option key="candidate" value="candidate">
                            Candidate
                        </Option>
                    </LodoshaSelect>
                    <LodoshaButton
                        type="primary"
                        disabled={entity === ''}
                        icon={<SearchOutlined />}
                        onClick={() => {
                            setVisibleSearch(true);
                        }}
                    >
                        Setting
                    </LodoshaButton>

                    <p>
                        <span>
                            Filter Information: [{' '}
                            {value
                                ? Object.keys(JSON.parse(value))
                                      .map((v) => {
                                          let valueJson = JSON.parse(value);
                                          if (v === 'job_id') {
                                              let job = dataJobs.find((dj) => dj.value === valueJson[v]);
                                              return `${v} = ${job ? job.text : ''}`;
                                          } else {
                                              return `${v} = ${valueJson[v]}`;
                                          }
                                      })
                                      .join(' & ')
                                : ''}{' '}
                            ]
                        </span>
                    </p>
                </Space>

                <ModalAdvanceSearch
                    visible={visibleSearch}
                    setVisible={setVisibleSearch}
                    onSearch={handleSearchCallback}
                    entity={entity}
                    value={value}
                />
                <ModalCallHistory visible={visibleCallHistory} setVisible={setVisibleCallHistory} employee={employee} />

                <Table
                    style={{ marginTop: 20 }}
                    bordered
                    loading={loading}
                    columns={columns}
                    dataSource={dataSource}
                    onRow={handleRow}
                    scroll={{ x: 1300 }}
                    onChange={() => {}}
                    pagination={{
                        onChange: handleChangePagination,
                        current: page,
                        defaultCurrent: 1,
                        defaultPageSize: 15,
                        total,
                    }}
                />
            </Row>
        </Container>
    );
};

export default AdvanceSearchCmp;
