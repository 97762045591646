
import { createAPIMedia } from '../api';

export const uploadImage = async (formData) => {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    return createAPIMedia()
        .post(`/image/upload-image`, formData, config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
};

export const deleteImages = async (data) => {
    return createAPIMedia()
        .delete(`/image/delete-image/${data}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
};