import styled from '@emotion/styled';

const ModalWrapper = styled.div`
    .image-preview-container {
        position: relative;
    }
    .image-upload {
        position: absolute;
        display: none;
        width: 100%;
        height: 160px;
        top: 0;
    }
    .content-image {
        width: 100%;
        height: 160px;
    }
    .show-upload {
        display: block;
    }
`;

export default ModalWrapper;
