import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, LodoshaButton } from '../../../components/UI/ui.style.js';

//ant design
import { Button, Space, Modal, message, Table } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

//api
import { getLessonsByChapter, deleteLesson, swapLessonOrder } from '../../../utils/api/E-Learning/japanese';

//components
import LessonModal from './LessonModal';
import SwapLessonPopover from '../E-LearningComponents/SwapLessonPopover.js';

//constants & utils
import { ELEARNING } from '../../../constants/routes.js';
import { lessonTypeToText } from '../../../utils/data.js';
import { ELearningWrapper } from '../E-LearningComponents/e-learning.style.js';
import NextPrevActionButtonGroup from '../E-LearningComponents/NextPrevActionButtonGroup.js';
import { getELearningItems } from '../../../utils/index.js';

export default function LessonList() {
    const { menuid, chapterid } = useParams();
    const history = useHistory();

    const isSubscribed = useRef(true);

    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [lessons, setLessons] = useState([]);
    const [postData, setPostData] = useState();

    const columns = [
        {
            title: 'NAME',
            dataIndex: 'name',
            fixed: 'left',
            width: '15%',
        },
        { title: 'NAME_JP', dataIndex: 'name_jp', width: '15%' },
        { title: 'TYPE', dataIndex: 'typeName', align: 'center', width: '10%' },
        { title: 'SUB-TYPE', dataIndex: 'sub_type', align: 'center', width: '15%' },
        { title: 'YOUTUBE KEY', dataIndex: 'youtube_key', align: 'center', width: '10%' },
        {
            title: 'ACTIONS',
            dataIndex: 'actions',
            align: 'center',
            width: '10%',
            fixed: 'right',
            render: (text, row, index) => (
                <Space>
                    <Button type="primary" title="Edit" onClick={(e) => handleEdit(e, row)} icon={<EditOutlined />} />
                    <SwapLessonPopover
                        lessons={lessons}
                        selectedLessonId={row.id}
                        onRefresh={handleFetchItems}
                        onSwapLesson={swapLessonOrder}
                    />
                    <Button
                        type="danger"
                        title="Delete"
                        onClick={(e) => handleDelete(e, row.id)}
                        icon={<DeleteOutlined />}
                    />
                </Space>
            ),
        },
    ];

    const data = useMemo(
        () =>
            lessons &&
            lessons.map((lesson, index) => ({
                ...lesson,
                key: index,
                name: lesson.name,
                name_jp: lesson.name_jp,
                type: lesson.type,
                typeName: lessonTypeToText[lesson.type - 1],
                sub_type: lesson.sub_type,
                order: lesson.order,
                youtube_key: lesson.youtube_key,
            })),
        [lessons]
    );

    const handleFetchItems = useCallback(
        async (clickedItemId) => {
            try {
                setLoading(true);
                const response = await getLessonsByChapter(clickedItemId ? clickedItemId : chapterid);
                if (response) {

                    if (response.errorCode !== 0) {
                        setLoading(false);
                        message.error(`Could not get lessons. ${response.message}`);
                        return;
                    }
                    setLessons(response && response.data);
                    setLoading(false);
                    return;
                } else {
                    setLoading(false);
                    message.error(`Could not get lessons. Response is undefined`);
                    return;
                }
            } catch (error) {
                setLoading(false);
                message.error('Something went wrong');
            }
        },
        [chapterid]
    );

    const handleRow = (record) => {
        return {
            onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();

                //set lessons to localstorage
                try {
                    const eLearningItems = getELearningItems(lessons, 'name');
                    localStorage.setItem('e-learning-lessons', JSON.stringify(eLearningItems));
                } catch (error) {
                    message.error('Set to local storage failed');
                }

                history.push(`${ELEARNING}/${menuid}/${chapterid}/${record.id}`);
            },
        };
    };

    const handleEdit = (e, row) => {
        e.stopPropagation();
        setUpdateVisible(true);
        setPostData(row);
    };

    const handleDelete = (e, id) => {
        e.stopPropagation();
        Modal.confirm({
            title: 'Are you sure you want to delete this post?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await deleteLesson(id);
                    if (response && response.errorCode === 0) {
                        message.success(`Deleted successfully!`);
                        handleFetchItems();
                    } else {
                        message.error(`Couldn't delete lesson, ${response.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request failed!`);
                }
            },
            onCancel() {},
        });
    };

    const Upsert = useCallback(
        () =>
            visible && (
                <LessonModal
                    title="Add New Lesson"
                    visible={visible}
                    setVisible={setVisible}
                    postData={postData}
                    setPostData={setPostData}
                    onRefresh={handleFetchItems}
                    isCreate={true}
                />
            ),
        [visible, handleFetchItems, postData]
    );

    const Update = useCallback(() => {
        return (
            updateVisible && (
                <LessonModal
                    title="Update Lesson"
                    visible={updateVisible}
                    setVisible={setUpdateVisible}
                    onRefresh={handleFetchItems}
                    setPostData={setPostData}
                    postData={postData}
                    isUpdate={true}
                />
            )
        );
    }, [updateVisible, handleFetchItems, postData]);

    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchItems();
        }
        return () => {
            isSubscribed.current = false;
        };
    }, [handleFetchItems, menuid]);

    //auto order and set chapterid to postData
    useEffect(() => {
        let lastOrder = lessons.length > 0 ? lessons[lessons.length - 1]?.order + 1 : 1;
        console.log(lastOrder, lessons[lessons.length - 1]?.order);
        setPostData((prev) => ({ ...prev, order: lastOrder, chapter_id: chapterid, type: 1 }));
    }, [chapterid, lessons, visible]);

    return (
        <Container>
            <ELearningWrapper>
                <div className="btn-margin-bottom">
                    <NextPrevActionButtonGroup
                        currentItemId={chapterid}
                        onClickAction={handleFetchItems}
                        localStorageKey="e-learning-chapters"
                    />
                </div>

                <LodoshaButton type="primary" onClick={() => setVisible(true)} className="btn-margin-bottom">
                    <PlusOutlined />
                    Add New
                </LodoshaButton>
                <Upsert />
                <Update />
                <Table
                    loading={loading}
                    pagination={false}
                    bordered
                    dataSource={data}
                    columns={columns}
                    rowKey="key"
                    onRow={handleRow}
                />
            </ELearningWrapper>
        </Container>
    );
}
