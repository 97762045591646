import createAPI, { createAPIELearning } from '../api';

export const getUsers = (page = 1, keyword) => {
    const limit = 15;
    const offset = (page - 1) * limit;
    return createAPI()
        .get(`/user?offset=${offset}&&keyword=${keyword}`)
        .then((response) => response.data)
        .catch((err) => err.response);
};

export const getUsersUsingApp = (employeeIds, notificationType) => {
    return createAPIELearning()
        .post(`/admin-user/get-users-using-app`, { employeeIds, notificationType })
        .then((response) => response.data)
        .catch((err) => console.log(`getUsersUsingApp ${err.message}`));
};

export const changeUserPhone = (id, phone) => {
    return createAPI()
        .patch(`/user/change-phone/${id}`, { phone: phone })
        .then((response) => response.data)
        .catch((err) => console.log(`changeUserPhone err: ${err}`));
};

export const changeUserEmail = (id, email) => {
    return createAPI()
        .patch(`/user/change-email/${id}`, { email: email })
        .then((response) => response.data)
        .catch((err) => console.log(`changeUserEmail err: ${err}`));
};
