/**
 *
 * @param link - link of article that will be automatically shared to facebook's page
 * @param message - post's caption
 * @param cb - response callback
 * @param generatedPageAccessToken - auto generated when user login with fb, can get from AuthContext
 */
export const shareToFacebookPage = (link = '', message = '', generatedPageAccessToken = '', cb) => {
    window.FB.api(
        `/${process.env.REACT_APP_FACEBOOK_PAGE_ID}/feed`,
        'POST',
        {
            link: link,
            message: message,
            access_token: generatedPageAccessToken,
        },
        (response) => {
            cb(response);
        }
    );
};

/**
 * @param cb - return response from facebook api which contains page access token if success
 * @param fbAccessToken - access token of logged in use
 */
export const generateFbPageAccessToken = (fbAccessToken, cb) => {
    window.FB.api(
        `/${process.env.REACT_APP_FACEBOOK_PAGE_ID}?fields=access_token&access_token=${fbAccessToken}`,
        (response) => cb(response)
    );
};
