import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { RatingWrapper } from './rating-style';

import { updateStaffRating, createStaffRating } from '../../../utils/api/rating';

import {Row,Col, Button, Rate, Input, message} from 'antd';


const RATE_VALUE = {
    communication: '',
    commitment: '',
    finance: '',
    responsibility: '',
    struggle: '',
    comment: '',
    employee: '',
    user_id: ''
};

const Rating = ({employee, onRefresh}) => {

    const [data, setData] = useState(RATE_VALUE);
    const { TextArea } = Input;

    const onSelectRate = (name, value) => {
        console.log("name value", name, value)
        setData({ ...data, [name]: value });
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setData((prev) => ({ ...prev, [name]: value }));
    };

    useEffect(() => {
        setData(employee?.rating_employee)
    },[employee])

    const handleSubmit = async () => {
        try {
            if (employee?.rating_employee) {
                const response = await updateStaffRating(employee.id, data);
                if (response && response.errorCode === 0) {
                    message.success(`Updated successfully!`);
                    onRefresh();
                } else {
                    message.error(`Fail, Something went wrong!`);
                }
            }
             else {
                const response = await createStaffRating(data,employee.id);
                console.log("respon", response)
                if (response && response.id) {
                    message.success('Create rating success');
                    onRefresh();
                } else {
                    message.error(`Fail, Something went wrong!`);
                }
            }
        } catch (err) {
            console.log("erorr", err)
            message.error( data && data.employee ? 'Uh oh, Update rating failed!' : 'Uh oh, Create rating failed!')
        }
    };

    console.log("DATA", data)

    const staffRating = useMemo(() =>[
        {
            name: 'communication',
            label: 'Communication',
            value: data?.communication ? data?.communication : null,
            type: 'rate',
        },
        {
            name: 'commitment',
            label: 'Commitment',
            value: data?.commitment ? data?.commitment : null,
            type: 'rate',
        },
        {
            name: 'finance',
            label: 'Finance',
            value:  data?.finance ? data?.finance : null,
            type: 'rate',
        },
        {
            name: 'responsibility',
            label: 'Responsibility',
            value: data?.responsibility ? data?.responsibility : null,
            type: 'rate',
        },
        {
            name: 'struggle',
            label: 'Struggle',
            value: data?.struggle ? data?.struggle : null,
            type: 'rate',
        },
        {
            name: 'comment',
            label: 'Comment',
            value: data?.comment ? data?.comment : "",
            type: 'text',
        }
    ],[data])
    
    return (
        <RatingWrapper>
            <h1>
                Rating Candidate
            </h1>
            {staffRating && staffRating.map((item, index) =>(
                item && item.type === "rate" ? 
                    <Row >
                        <Col span={24} style={{marginBottom: '15px'}}>
                            <p style={{marginBottom: '0px', fontSize: '17px'}}>{item.label}</p>
                            <Rate
                                name={item?.name}
                                value={item?.value}
                                style={{ color: '#DCDE3B', fontSize:'23px' }}
                                allowClear={false}
                                onChange={(value) => onSelectRate(item?.name, value)}
                            /> 
                        </Col>
                    </Row>:
                    <Row>
                        <Col span={12} style={{marginBottom: '15px'}}>
                            <p style={{marginBottom: '0px', fontSize: '17px'}}>{item.label}</p>
                            <TextArea name={item.name} onChange={handleChange} rows={4} value={item?.value}/>
                        </Col>
                    </Row>
                ))}
                    <Row>
                        <Button onClick={handleSubmit} className="btn-style">{ employee && employee?.rating_employee ? <p>Update</p> : <p>Submit</p>}</Button>
                    </Row>
        </RatingWrapper>
    )
}

export default Rating;