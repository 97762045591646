import React, { useState, useEffect, useRef, useCallback } from "react";
import { Modal, Upload, Spin, message } from "antd";
import API, { baseURL } from "../../utils/api";

const ModalGallery = ({ visible, setVisible, employer }) => {
  const isSubscribed = useRef(true);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [originalFileList, setOriginalFileList] = useState([]);
  const [error, setError] = useState(false);

  const handleFetchGalleries = useCallback(async () => {
    isSubscribed.current && setLoading(true);
    try {
      const response = await API().get(`/employer/gallery?employer_id=${employer.id}`);
      const galleries = response.data?.data.map((gallery, index) => {
        gallery["url"] = baseURL + gallery.url;
        gallery["uid"] = String(index + 1);
        return gallery;
      });

      if (isSubscribed.current) {
        setFileList(galleries);
        setOriginalFileList(galleries);
        setLoading(false);
      }
    } catch (error) {
      isSubscribed.current && setLoading(false);
      message.error("Uh oh, request failed!");
    }
  }, [employer]);

  useEffect(() => {
    handleFetchGalleries();

    return () => {
      isSubscribed.current = false;
    };
  }, [handleFetchGalleries]);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleBeforeUpload = (file, fileList) => {
    if (fileList.length + originalFileList.length >= 12) {
      fileList.forEach((data) => {
        data.status = "error";
      });
      setFileList(originalFileList);
      setError(true);
      return false;
    }

    setError(false);
    return true;
  };

  const handleCustomRequest = (options) => {
    const data = new FormData();
    data.append("image", options.file);

    API()
      .post(`/employer/gallery/${employer.id}`, data)
      .then((res) => {
        options.onSuccess(res.data, options.file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (info) => {
    const { status } = info.file;
    setFileList(info.fileList);

    const uploadFileList = info.fileList
      .filter((filter) => filter.status === "done")
      .map((data, index) => {
        if (data.response) {
          data.response.data["uid"] = String(index + 1);
          data.response.data["status"] = "done";
          data.response.data["url"] = baseURL + data.response.data["image"];
        }
        return data.response ? data.response.data : data;
      });

    if (status === "done") {
      if (uploadFileList.length === fileList.length) {
        setFileList(uploadFileList);
        // setOriginalFileList(fileList);
      }

      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleDelete = async (file) => {
    setLoading(true);

    if (file.originFileObj && file.originFileObj.status === "error") return;

    try {
      const response = await API().delete(`/employer/gallery/${file.id}`);
      if (response.data.errorCode === 0) {
        const tempFileList = fileList;
        const index = tempFileList.indexOf(file);

        if (index !== -1) {
          tempFileList.splice(index, 1);
          setFileList(tempFileList);
        }
        setLoading(false);
        message.success(`Deleted successfully!`);
      } else {
        setLoading(false);
        message.error(`Failed! ${response.data.message}`);
      }
    } catch (error) {
      setLoading(false);
      message.error("Uh oh, request failed!");
    }
  };

  return (
    <Modal visible={visible} maskClosable={false} onCancel={handleCancel} footer={null}>
      <Spin spinning={loading}>
        <div style={{ marginBottom: 16 }}>
          <h3>Please upload activity photos</h3>
        </div>
        <Upload
          name="file"
          multiple
          showUploadList={{ showPreviewIcon: false, showDownloadIcon: false }}
          listType="picture-card"
          fileList={fileList}
          beforeUpload={handleBeforeUpload}
          customRequest={handleCustomRequest}
          onChange={handleChange}
          onRemove={handleDelete}
        >
          {fileList && fileList.length >= 12 ? null : (
            <div style={{ padding: 8, color: "rgba(0, 0, 0, 0.65)" }}>Drop photos here or Click to Upload</div>
          )}
        </Upload>
        <div style={{ marginTop: 16 }}>
          <p style={{ color: error ? "red" : "inherit" }}>
            12 maximum photos allowance and photos will auto upload after drop.
          </p>
        </div>
      </Spin>
    </Modal>
  );
};

export default ModalGallery;
