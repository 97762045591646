import styled from '@emotion/styled';

const ELearningWrapper = styled.div`
    .card-container {
    }
    .menu-disabled {
        cursor: unset;
        opacity: 0.7;
    }
    .topic-icon {
        margin-bottom: 1rem;
    }
`;

export default ELearningWrapper;
