import React, { useCallback, useEffect, useState } from 'react';
import LodoshaLogo from '../../../assets/logo2.png';

//api
import { pushNotifications } from '../../../utils/api/E-Learning/news_management';

//antd
import { Space, Select, Row, Col, Descriptions, message, Spin, Button } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';

//components
import ModalAdvanceSearch from '../../../components/ModalAdvanceSearch';
import { Container, LodoshaButton, LodoshaSelect } from '../../../components/UI/ui.style';
import { FilteredUsersContainer } from './filterusers.style';

//constants and utils
import { advanceSearchGetAll } from '../../../utils/api/advance_search';
import { getUsersUsingApp } from '../../../utils/api/user';

import { useBtnList } from './useBtnList';

export default function FilteredUsers() {
    const [visible, setVisible] = useState(false);
    const [entity, setEntity] = useState('');
    const [value, setValue] = useState();
    const [notificationInfo, setNotificationInfo] = useState([]);
    const [totalUsers, setTotalUsers] = useState([]);
    const [totalUsersUsingApp, setTotalUsersUsingApp] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sendLoading, setSendLoading] = useState(false);
    const [btnList, setBtnList, setSelectedValue] = useBtnList();

    const { Option } = Select;

    const handleChange = (value) => {
        setEntity(value);
    };

    const handleFetchUsers = useCallback(async (keys) => {
        setLoading(true);
        try {
            const response = await advanceSearchGetAll(keys);
            if (response && response.errorCode !== 0) {
                setLoading(false);
                message.error(`Uh oh couldn't get users. ${response.message}`);
                return;
            }
            //savedValue.type is needed for notification permission
            const savedValue = JSON.parse(localStorage.getItem('notification-info'));

            //if there are at least one or more than 1 with current query, send query for users using app
            let responseUsersUsingApp;
            if (response?.data?.length > 0) {
                responseUsersUsingApp = await getUsersUsingApp(response?.data, savedValue?.type);
                if (responseUsersUsingApp && responseUsersUsingApp.errorCode !== 0) {
                    setLoading(false);
                    message.error(`Uh oh couldn't get users that are using app. ${responseUsersUsingApp.message}`);
                    return;
                }
            }
            if(responseUsersUsingApp?.data){
                responseUsersUsingApp = responseUsersUsingApp?.data.map((user) => user.id);
            }
            console.log(responseUsersUsingApp)
            setTotalUsersUsingApp(responseUsersUsingApp ? responseUsersUsingApp : []);
            setTotalUsers(response ? response.data : []);
            setLoading(false);
        } catch (error) {
            message.error(`Uh oh couldn't get users. ${error.message}`);
            setLoading(false);
        }
    }, []);

    const handleSearchCallback = (fieldValues) => {
        if (fieldValues) {
            setSelectedValue(fieldValues);
            handleFetchUsers(fieldValues);
            setValue(fieldValues);
        } else {
            setValue();
            handleFetchUsers();
            setBtnList([]);
        }
    };

    //get function from children component and call it here
    let biRef = {
        handleCancel: null,
    };

    const handleClear = () => {
        if (value) {
            setLoading(true);
            setBtnList([]);
            biRef.handleCancel();
            handleFetchUsers({});
        }
    };

    const handleClearButton = (clickedBtn) => {
        //remove value from localStorage and save the remaining back to localstorage
        const savedAdvanceSearchValue = JSON.parse(localStorage.getItem('advance-search-value'));
        for (const key in savedAdvanceSearchValue) {
            clickedBtn.key === key && delete savedAdvanceSearchValue[key];
        }

        localStorage.setItem('advance-search-value', JSON.stringify(savedAdvanceSearchValue));
        const remainingButtons = btnList.filter((btn) => clickedBtn.key !== btn.key);
        setBtnList(remainingButtons);
        handleFetchUsers(savedAdvanceSearchValue);
    };

    const handlePushNotifications = async () => {
        if (notificationInfo?.is_sent === true) {
            message.warning('You cannot send this notification again.');
            return;
        }
        setSendLoading(true);
        try {
            const response = await pushNotifications(notificationInfo, totalUsersUsingApp);
            if (response && response.errorCode !== 0) {
                message.error(`Send notifications failed ${response.message}`);
                setSendLoading(false);
                return;
            }
            if (response) {
                setNotificationInfo((prev) => ({
                    ...prev,
                    is_sent: response?.data?.is_sent,
                    failed: response?.data?.failed,
                    success: response?.data.success,
                }));
                response && message.success(`Successfully sent notifcations to ${totalUsersUsingApp?.length} users`);
                setSendLoading(false);
            } else {
                message.error(`Couldn't send notifications`);
                setSendLoading(false);
            }
        } catch (error) {
            message.error(`Send notifications failed ${error.message}`);
            setSendLoading(false);
        }
    };

    useEffect(() => {
        const savedAdvanceSearchType = localStorage.getItem('advance-search');
        const savedNotificationInfo = JSON.parse(localStorage.getItem('notification-info'));

        savedAdvanceSearchType && setEntity(savedAdvanceSearchType);
        savedNotificationInfo && setNotificationInfo(savedNotificationInfo);

        switch (entity) {
            case 'candidate':
                handleFetchUsers();
                break;
            default:
                return;
        }
    }, [handleFetchUsers, entity]);

    return (
        <Container>
            <FilteredUsersContainer>
                {/* search query */}
                <Row>
                    <Space size={'large'}>
                        <LodoshaSelect style={{ width: 250 }} value={entity} onChange={(value) => handleChange(value)}>
                            <Option key="candidate" value="candidate">
                                Candidate
                            </Option>
                        </LodoshaSelect>
                        <LodoshaButton
                            type="primary"
                            disabled={entity === ''}
                            icon={<SearchOutlined />}
                            onClick={() => {
                                setVisible(true);
                            }}
                        >
                            Setting
                        </LodoshaButton>
                    </Space>
                </Row>
                <Row style={{ marginTop: '2rem', marginBottom: '1rem' }}>
                    <Col span={12}>
                        <h2>{notificationInfo?.text}</h2>
                    </Col>
                </Row>

                {/* notifction detail and search results */}
                <Row justify="space-between" gutter={[16, 16]}>
                    <Col sm={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                        <Descriptions
                            bordered
                            title="About This Notification"
                            column={1}
                            style={{ marginBottom: '1.5rem' }}
                        >
                            <Descriptions.Item label="Success: ">
                                <div style={{ textAlign: 'center' }}>
                                    {notificationInfo?.success ? notificationInfo?.success : '_'}
                                </div>
                            </Descriptions.Item>
                            <Descriptions.Item label="Failed: ">
                                <div style={{ textAlign: 'center' }}>
                                    {notificationInfo?.failed ? notificationInfo?.failed : '_'}
                                </div>
                            </Descriptions.Item>
                            <Descriptions.Item label="Status: ">
                                <div style={{ textAlign: 'center' }}>
                                    {notificationInfo?.is_sent ? 'Sent' : 'Not yet send'}
                                </div>
                            </Descriptions.Item>
                        </Descriptions>

                        <Spin spinning={loading}>
                            <Descriptions bordered title="Search Results" column={1} colon={false}>
                                <Descriptions.Item label="Total: ">
                                    <div style={{ textAlign: 'center' }}>
                                        {totalUsers?.length > 0 ? totalUsers.length : '_'}
                                    </div>
                                </Descriptions.Item>
                                <Descriptions.Item label="Total User Using App: ">
                                    <div style={{ textAlign: 'center' }}>
                                        {totalUsersUsingApp?.length > 0 ? totalUsersUsingApp.length : '_'}
                                    </div>
                                </Descriptions.Item>
                            </Descriptions>
                            <Space style={{ marginTop: '1.5rem', display: 'flex', justifyContent: 'end' }}>
                                <LodoshaButton key="cancel" htmlType="button" onClick={handleClear}>
                                    Clear
                                </LodoshaButton>
                                <LodoshaButton
                                    key="submit"
                                    loading={sendLoading}
                                    type="primary"
                                    htmlType="submit"
                                    onClick={handlePushNotifications}
                                    disabled={totalUsersUsingApp?.length > 0 ? false : true}
                                >
                                    Send Notification
                                </LodoshaButton>
                            </Space>
                        </Spin>
                    </Col>

                    {/* selected value buttons */}
                    <Col sm={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }} lg={{ span: 12, order: 2 }}>
                        <Row>
                            {btnList.length > 0 ? (
                                btnList.map((btn, index) => (
                                    <Col style={{ margin: '.5rem 1rem 0 0' }} key={index}>
                                        <Button>
                                            {btn?.text}
                                            <CloseOutlined
                                                style={{ fontSize: '12px' }}
                                                className="remove-icon"
                                                onClick={() => handleClearButton(btn)}
                                            />
                                        </Button>
                                    </Col>
                                ))
                            ) : (
                                <Col span={24} className="logo-container">
                                    <img src={LodoshaLogo} width="300" height="250" alt="lodosha-logo" />
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
                <ModalAdvanceSearch
                    visible={visible}
                    setVisible={setVisible}
                    entity={entity}
                    value={value}
                    onSearch={handleSearchCallback}
                    biRef={biRef}
                />
            </FilteredUsersContainer>
        </Container>
    );
}
