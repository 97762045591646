
import API from '../api';

export const getEmployeeLanguage = (employeeId, languageId) => {
    const url = `/employee/language/getEmployeeLanguage?language_id=${languageId}&employee_id=${employeeId}`
    return API().get(url)
            .then(response => {
                return response.data;
            })
            .catch(err => { 
                console.log('err: ', err) 
            });
};

export const createEmployeeLanguage = (data) => {
    return API().post(`/employee/language/create`, data)
            .then(response => {
                return response.data;
            }).catch(err => {
                console.log('err: ', err) 
            })
}

export const updateEmployeeLanguage = (id, data) => {
    return API().patch(`/employee/language/${id}`, data)
            .then(response => {
                return response.data;
            }).catch(err => {
                console.log('err: ', err) 
            })
}