import styled from '@emotion/styled';

const FuriganaGeneratorWrapper = styled.div`
    .text-area {
        border-radius: 0.5rem;
        height: 250px;
        border: 2px solid #152688;
        font-size: 16px;
        padding: 1rem;
        white-space: pre-wrap;
    }
    
    .div-as-text-area {
        background-color: #fff;
        cursor: text;
        overflow-y: scroll;
        white-space: pre-wrap; 
    }
    p {
        margin: 0;
    }
`;

export default FuriganaGeneratorWrapper;
