import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getFromLocalStoage } from '../../../utils';

//antd
import { message } from 'antd';

//api
import { getCultureDetails } from '../../../utils/api/E-Learning/culture';

//components
import CultureBlogList from './CultureBlog/CultureBlogList';
import QuestionAndAnswerList from './QuestionAndAnswer/QuestionAndAnswerList';

export default function CultureContent() {
    const [cultureType, setCultureType] = useState(0);
    const [loading, setLoading] = useState(false);
    const [cultureDetails, setCultureDetails] = useState([]);
    const { cultureid } = useParams();

    const isSubscribed = useRef(true);

    let data = useMemo(() => {
        let finalData = [];
        //remove undefined data
        for (const cultureDetail of cultureDetails) {
            let detail;
            for (const d in cultureDetail) {
                detail = { ...detail, [d]: cultureDetail[d] };
            }
            //avoid missing key warning from react
            detail['key'] = detail.id;

            finalData.push(detail);
        }
        return finalData;
    }, [cultureDetails]);

    const handleFetchCultureDetails = useCallback(
        async (clickedItemId) => {
            setLoading(true);
            try {
                const response = await getCultureDetails(clickedItemId ? clickedItemId : cultureid);
                if (response) {
                    if (response.errorCode !== 0) {
                        setLoading(false);
                        message.error(`Couldn't get culture details,${response.message}`);
                        return;
                    }

                    setCultureDetails(response ? response.data : []);
                    setLoading(false);
                    return;
                } else {
                    setLoading(false);
                    message.error(`Couldn't get culture details, Response is undefined`);
                    return;
                }
            } catch (error) {
                setLoading(false);
                message.error(`Couldn't get culture details :${error.message}`);
                return;
            }
        },
        [cultureid]
    );

    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchCultureDetails();
        }
        return () => {
            isSubscribed.current = false;
        };
    }, [handleFetchCultureDetails]);

    useEffect(() => {
        const getCultureType = () => {
            const savedCultureType = getFromLocalStoage('culture-type');
            setCultureType(savedCultureType ? Number(savedCultureType) : 0);
        };
        getCultureType();
    }, []);
    return (
        <div>
            {cultureType === 1 ? (
                <QuestionAndAnswerList
                    data={data}
                    handleFetchCultureDetails={handleFetchCultureDetails}
                    loading={loading}
                    setLoading={setLoading}
                />
            ) : cultureType !== 1 ? (
                <CultureBlogList
                    data={data}
                    handleFetchCultureDetails={handleFetchCultureDetails}
                    loading={loading}
                    setLoading={setLoading}
                />
            ) : null}
        </div>
    );
}
