import moment from 'moment';
import markdownIt from 'markdown-it';
import furigana from 'furigana-markdown-it';
import markdownItAttrs from 'markdown-it-attrs';
import markdownItSpan from 'markdown-it-span';
import markdownItDiv from 'markdown-it-div';
import markdownItVideo from 'markdown-it-video';
import markdownItMark from 'markdown-it-mark';

export function md() {
    return markdownIt({ html: true })
        .use(furigana())
        .use(markdownItAttrs)
        .use(markdownItDiv)
        .use(markdownItSpan)
        .use(markdownItVideo)
        .use(markdownItMark);
}

export function textToDate(dateText) {
    const dateFormat = 'YYYY-MM-DD';

    if (isNullOrUndefined(dateText) || dateText === '') {
        return moment();
    }

    return moment(dateText, dateFormat);
}

export function dateDisplay(dateText) {
    const dateFormat = 'YYYY-MM-DD';

    if (isNullOrUndefined(dateText) || dateText === '') {
        return moment();
    }
    return moment(dateText, dateFormat).format('DD-MM-YYYY');
}

export function getDifferenceDaysFromNow(date) {
    return moment(moment()).diff(date, 'days');
}

export const Action = {
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
    VIEW: 'view',
};

export function isNullOrUndefined(value) {
    return value === null || value === undefined || value === '';
}

export function formatCurrency(value) {
    return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function loadImage(path) {
    if (path === '' || isNullOrUndefined(path)) {
        return '';
    }
    return `${process.env.BASE_URL}image/photo/${path}`;
}

export function loadImageByFilename(filename) {
    if (filename === '' || isNullOrUndefined(filename)) {
        return '';
    }
    return `${process.env.BASE_URL}image/${filename}`;
}

export function loadImageMiddleware(path) {
    if (path === '' || isNullOrUndefined(path)) {
        return '';
    }
    return `${process.env.BASE_URL_MIDDLEWARE}admin-media/photo/${path}`;
}

export function getIdFromFilePath(data, key) {
    return data[key] ? data[key].split('/').slice(data[key].split('/').length - 1, data[key].split('/').length)[0] : '';
}

export function getFromLocalStoage(key) {
    return JSON.parse(window.localStorage.getItem(key));
}

export function getELearningItems(data = [], titleKey = '') {
    console.log("data", data, titleKey)
    const items = data.map((d) => ({ id: d.id ? d.id : '', title: d[titleKey] ? d[titleKey] : '' }));
    return items;
}

export function getNewLastOrder(data) {
    const lastIndex = data && data[data.length - 1];
    const lastIndexOrder = lastIndex && lastIndex.order ? lastIndex.order : 0;
    //270 -> 300
    let lastOrder = Math.ceil(lastIndexOrder / 100) * 100;
    //prevent duplicate order
    if (lastIndexOrder === lastOrder) {
        lastOrder = lastOrder + 100;
    }
    return lastOrder;
}

//id here is menuid
export function getBreadcrumbName(url, id, breadcrumbNameMap) {
    let breadcrumbName = '';

    switch (url.split('/').length) {
        case 3:
            return breadcrumbNameMap[url];
        case 4:
            switch (id) {
                case '1':
                    breadcrumbName = 'Lessons';
                    break;
                case '2':
                    breadcrumbName = 'Lessons';
                    break;
                case '3':
                    breadcrumbName = 'Lessons';
                    break;
                case '4':
                    breadcrumbName = 'Manage';
                    break;
                case '5':
                    breadcrumbName = 'Culture List';
                    break;
                case '7':
                    breadcrumbName = 'Lessons';
                    break;
                case 'news-management':
                    breadcrumbName = 'Users';
                    break;
                default:
            }
            return breadcrumbName;
        case 5:
            switch (id) {
                case '1':
                    breadcrumbName = 'Contents';
                    break;
                case '2':
                    breadcrumbName = 'Contents';
                    break;
                case '3':
                    breadcrumbName = 'Contents';
                    break;
                case '4':
                    breadcrumbName = 'Question Detail';
                    break;
                case '5':
                    breadcrumbName = 'Detail';
                    break;
                case '7':
                    breadcrumbName = 'Contents';
                    break;
                default:
            }
            return breadcrumbName;
        case 6:
            switch (id) {
                case '1':
                    breadcrumbName = 'Detail';
                    break;
                case '2':
                    breadcrumbName = 'Detail';
                    break;
                case '3':
                    breadcrumbName = 'Detail';
                    break;
                case '4':
                    breadcrumbName = '';
                    break;
                // case "5":
                //     breadcrumbName = '';
                //     break;
                case '7':
                    breadcrumbName = 'Detail';
                    break;
                default:
            }
            return breadcrumbName;
        default:
            return breadcrumbName;
    }
}

export const getFullHtmlFormat = (value = {}) => {
    return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Document</title>
    </head>
    <body>
        <style>
            body{
                margin: 0
            }

            ${value.css}
        </style>
        <main>
            ${value.html}
        </main>
    </body>
    </html>
    `;
};
