import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Input, Select, Button, Table, Row, Col, Modal, Form, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import API from "../../utils/api";
import getTextDisplay, { dataFavoriteStatus, dataType } from "../../utils/data";
import StatusPopUp from "../../components/StatusPopUp";
import { FavoritesWrapper } from "./favorites.style";

const { Option } = Select;

const favoriteDataOptions = dataFavoriteStatus.map((d) => <Option key={d.value}>{d.text}</Option>);

const FavoritesRequest = () => {
  const history = useHistory();

  const timeout_typing_state = {
    typingTimeout: 0,
  };
  const [timeoutTyping, setTimeoutTyping] = useState(timeout_typing_state);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [employers, setEmployers] = useState([]);
  const [searchEmployers, setSearchEmployers] = useState([]);
  const [keyWord, setKeyWord] = useState("");
  const [employer, setEmpoyer] = useState(null);
  const [status, setStatus] = useState(null);
  const [page, setPage] = useState(1);
  const [visibleStatusModel, setVisibleStatusModel] = useState(false);
  const [itemStatus, setItemStatus] = useState({});

  const isSubscribed = useRef(true);

  useEffect(() => {
    if (isSubscribed.current) {
      fetchData();
      fetchEmployer();
    }
    return () => {
      isSubscribed.current = false;
    };
  }, [isSubscribed]);

  const fetchData = async (page = 1, order = "", field = "") => {
    isSubscribed.current && setLoading(true);
    try {
      var response;
      if (page === 1) {
        response = await API().get(`/favorite?order=${order}&field=${field}`);
      } else {
        response = await API().get(`/favorite?order=${order}&field=${field}&offset=${(page - 1) * 15}`);
      }
      const { data } = response;
      isSubscribed.current && setData(data);
    } catch (error) {
      message.error("Uh oh, request failed!");
    }
    isSubscribed.current && setLoading(false);
  };

  const fetchEmployer = async () => {
    const response = await API().get(`/employer`);
    isSubscribed.current && setEmployers(response.data.data);
    isSubscribed.current && setSearchEmployers(response.data.data);
  };

  const handleSearch = async (keyword) => {
    var kw = keyword.target.value;
    setKeyWord(kw);

    if (timeoutTyping.typingTimeout) {
      clearTimeout(timeoutTyping.typingTimeout);
    }

    setTimeoutTyping({
      typingTimeout: setTimeout(function () {
        startSearch(kw, employer, status);
      }, 1000),
    });
  };

  const onTableChange = (pagination, _, sorter) => {
    if (sorter) {
      const page = pagination.current;
      const field = sorter ? sorter.columnKey : "";
      const order = sorter ? sorter.order : "";

      setPage(page);
      fetchData(page, order, field);
    }
  };

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        event.preventDefault();
        event.stopPropagation();
        history.push({ pathname: `/favorites/detail/${record.id}`, state: record });
      }, // click row
    };
  };

  const handleSeachSelect = (keyWord) => {
    if (keyWord) {
      if (timeoutTyping.typingTimeout) {
        clearTimeout(timeoutTyping.typingTimeout);
      }

      setTimeoutTyping({
        typingTimeout: setTimeout(function () {
          startSearchEmployer(keyWord);
        }, 1000),
      });
    } else {
      setSearchEmployers(employers);
    }
  };

  const startSearchEmployer = async (keyWord) => {
    const response = await API().get(`/employer/searchName?keyword=${keyWord ? keyWord : ""}`);
    setSearchEmployers(response.data.data);
  };

  const handleSelectChange = (name, value) => {
    if (name === "status") {
      setStatus(value);
      startSearch(keyWord, employer, value);
    } else {
      setEmpoyer(value);
      startSearch(keyWord, value, status);
    }
  };

  const startSearch = async (keyWord, employerId, status) => {
    setLoading(true);
    const response = await API().get(
      `/favorite/search?keyword=${keyWord ? keyWord : ""}&employer_id=${employerId}&status=${status}`
    );
    setData(response.data);
    setLoading(false);
  };

  const handleUpdateStatus = async (id, status) => {
    id && (await API().patch(`/favorite/status/${id}`, { status: status }));
    fetchData();
  };

  const handleSaveStatus = (newStatus) => {
    setVisibleStatusModel(false);
    const id = itemStatus.id;
    handleUpdateStatus(id, newStatus);
  };

  const handleDelete = async (id) => {
    Modal.confirm({
      title: `Are you sure delete?`,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        try {
          const response = await API().delete(`/favorite/${id}`);
          if (response && response.data.errorCode === 0) {
            fetchData();
            message.success(`Deleted successfully!`);
          } else {
            message.error(`Failed! ${response.data.message}`);
          }
        } catch (error) {
          message.error(`Uh oh, request failed!`);
        }
      },
      onCancel() {},
    });
  };

  const showModal = (item) => {
    setItemStatus(item);
    setStatus(item.status);
    setVisibleStatusModel(true);
  };

  const employersData = searchEmployers
    ? searchEmployers.map((data) => {
        return { value: data.id, text: data.company_name };
      })
    : [];

  const employerDataOptions = employersData.map((d) => <Option key={d.value}>{d.text}</Option>);

  const dataSource =
    data &&
    data.data.map((favorite, index) => {
      const { employer, employee, post_job } = favorite;
      return {
        ...favorite,
        no: index + 1 + (page - 1) * 15,
        key: favorite.id,
        company_name: employer ? employer.company_name : "-",
        employee_name: employee && employee.name,
        job_title: post_job ? post_job.job_title : "-",
        employee_phone: employee && employee.phone,
        employer_email: employer ? employer.email : post_job ? post_job.email : "-",
        // employerContact: employer && employer.phone,
      };
    });

  const columns = [
    { title: "#", dataIndex: "no", key: "updated_at", width: 50, sorter: () => {} },
    { title: "COMPANY NAME", dataIndex: "company_name", key: "company_name", sorter: () => {} },
    { title: "EMPLOYEE NAME", dataIndex: "employee_name", key: "employee_name", sorter: () => {} },
    { title: "JOB TITTLE", dataIndex: "job_title", key: "job_title", sorter: () => {} },
    { title: "PHONE", dataIndex: "employee_phone", key: "employee_phone", sorter: () => {} },
    { title: "EMAIL", dataIndex: "employer_email", key: "employer_email", sorter: () => {} },
    {
      title: "STATUS",
      key: "status",
      sorter: () => {},
      render: (item) => (
        <span>
          <Button type="link" style={{ width: 100 }} onClick={(e) => showModal(item)}>
            <u>{getTextDisplay(item.status, dataType.FAVORITE_STATUS)}</u>
          </Button>
        </span>
      ),
    },
    {
      title: "",
      key: "actions",
      width: 100,
      fixed: "right",
      render: (item) => (
        <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Button title="Delete" type="danger" icon={<DeleteOutlined />} onClick={() => handleDelete(item.id)} />
        </span>
      ),
    },
  ];

  const scroll = window.screen <= 768 && { x: 1300 };

  return (
    <FavoritesWrapper>
      <Form layout="vertical">
        <Row gutter={[40, 16]} style={{ marginBottom: "40px" }}>
          <Col md={8}>
            <Form.Item label="Search:">
              <Input.Search
                size="large"
                placeholder="Keyword"
                value={keyWord}
                onChange={(value) => handleSearch(value)}
              />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item label="Company Filter:">
              <Select
                className="select-box"
                showSearch
                size="large"
                name="company_filter"
                defaultValue={employer}
                placeholder="Company name"
                defaultActiveFirstOption={false}
                showArrow={true}
                filterOption={false}
                onSearch={(e) => handleSeachSelect(e)}
                onSelect={(e) => handleSelectChange("company_name", e)}
                notFoundContent={null}
              >
                <Option key={"none"} value={null}>
                  {"None"}
                </Option>
                {employerDataOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item label="Status:">
              <Select
                className="select-box"
                size="large"
                placeholder="Status"
                defaultValue={null}
                onSelect={(value) => handleSelectChange("status", value)}
              >
                <Select.Option key={"none"} value={null}>
                  {"None"}
                </Select.Option>
                {favoriteDataOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        rowClassName={(_, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        scroll={scroll}
        onRow={onRow}
        onChange={onTableChange}
        bordered
        pagination={{
          defaultCurrent: 1,
          defaultPageSize: 15,
          total: data && data.total,
        }}
      />
      <StatusPopUp
        name={itemStatus && itemStatus.name ? itemStatus.name : "N/A"}
        status={status}
        setStatus={setStatus}
        data={dataFavoriteStatus}
        errorStatueStep={7}
        visible={visibleStatusModel}
        setVisible={setVisibleStatusModel}
        handleSaveStatus={handleSaveStatus}
      />
    </FavoritesWrapper>
  );
};

export default FavoritesRequest;

// <Fragment>
//       <Route
//         path={`${props.match.url}/detail/:id`}
//         render={() => <Detail detail={detail} setDetail={setDetail} handleUpdateStatus={handleUpdateStatus} />}
//       />

//       <Route
//         exact
//         path={props.match.url}
//         render={() => (
//           <List
//             loading={loading}
//             data={data}
//             keyWord={keyWord}
//             employer={employer}
//             statusFilter={status}
//             searchEmployers={searchEmployers}
//             handleSeachSelect={handleSeachSelect}
//             handleSelectChange={handleSelectChange}
//             handleSearch={handleSearch}
//             handleRow={handleRow}
//             handleFetchData={fetchData}
//             handleUpdateStatus={handleUpdateStatus}
//             handleDelete={handleDelete}
//           />
//         )}
//       />
//     </Fragment>
