import axios from 'axios';
import Cookies from 'js-cookie';

export const baseURL = process.env.BASE_URL;
export const version = process.env.VERSION;

export const baseURLELearning = process.env.E_LEARNING_URL;
export const versionELearning = process.env.E_LEARNING_VERSION;

export const headers = () => {
    const token = Cookies.get('backoffice_token');
    return {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
};

export const headersELearning = () => {
    const middlewareToken = Cookies.get('middleware_token');
    return {
        Authorization: `Bearer ${middlewareToken}`,
        'Content-Type': 'application/json',
    };
};

export function createAPIMedia() {
    return axios.create({
        baseURL: baseURL,
        headers: headers(),
    });
}

export function createAPIELearningMedia() {
    return axios.create({ baseURL: baseURLELearning, headers: headersELearning() });
}

export function createAPIELearning() {
    return axios.create({
        baseURL: baseURLELearning + versionELearning,
        headers: headersELearning(),
    });
}

export default function createAPI() {
    return axios.create({
        baseURL: baseURL + version,
        headers: headers(),
    });
}
