import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import markdownit from 'markdown-it';
import furigana from 'furigana-markdown-it';
import HtmlParser from 'html-react-parser';

//antd
import { Col, Input, Row } from 'antd';

//components
import Breadcrumb from '../E-LearningComponents/Breadcrumb';
import FuriganaGeneratorWrapper from './furigana-generator-wrapper.style';
import { LodoshaButton } from '../../../components/UI/ui.style';

export default function FuriganaGeneratorPage() {
    const { pathname } = useLocation();

    const [inputFormat, setInputFormat] = useState('');
    const [furiganaFormat, setFuriganaFormat] = useState('');

    const { TextArea } = Input;
    const md = markdownit().use(furigana());
    const menuid = pathname?.split('/')[2];

    const handleChangeInputFormat = (e) => {
        const { value } = e.target;
        setInputFormat(value);
    };

    const handleGenerate = () => {
        const generatedFormat = md.render(inputFormat);
        setFuriganaFormat(generatedFormat);
    };

    return (
        <FuriganaGeneratorWrapper>
            <Breadcrumb menuid={menuid} />
            <Row>
                <Col span={24} style={{ height: '300px' }}>
                    <div style={{ opacity: 0.8, marginLeft: '1rem', marginBottom: '.5rem' }}>Higarina / Katakana</div>
                    <TextArea
                        className="text-area"
                        placeholder="Write Hiragana or Katakana here..."
                        onChange={handleChangeInputFormat}
                        name="higarina-katakana"
                        value={inputFormat}
                    />
                </Col>

                <Col span={24} style={{ textAlign: 'center' }}>
                    <LodoshaButton type="primary" onClick={handleGenerate} disabled={!inputFormat && true}>
                        Generate
                    </LodoshaButton>
                </Col>

                <Col span={24} style={{ height: '300px' }}>
                    <div style={{ opacity: 0.8, marginLeft: '1rem', marginBottom: '.5rem' }}>Furigana</div>
                    <div className="text-area div-as-text-area" placeholder="Result...">
                        {HtmlParser(furiganaFormat)}
                        {furiganaFormat && <div style={{ marginBottom: '1rem' }}>Please copy the line below:</div>}
                        {furiganaFormat}
                    </div>
                </Col>
            </Row>
        </FuriganaGeneratorWrapper>
    );
}
