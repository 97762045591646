import { createAPIELearning } from '../../api';

export const importSkill = (data, file) => {
    const formData = new FormData();

    for (const key in data) {
        formData.append(key, data[key]);
    }

    formData.append('csv-file', file);

    return createAPIELearning()
        .post('/admin-chapter/import-skill', formData)
        .then((response) => response.data)
        .catch((err) => console.log(`importSkill: `, err));
};

//chapter
export const getChaptersByMenuID = (menuID) => {
    return createAPIELearning()
        .get(`/admin-chapter/${menuID}`)
        .then((response) => response.data)
        .catch((error) => console.log('getChaptersByMenuID', error));
};

export const postNewChapter = (menuid, formData) => {
    const config = {
        'content-type': 'multipart/form-data',
    };
    return createAPIELearning()
        .post(`/admin-chapter/create/${menuid}`, formData, config)
        .then((response) => response.data)
        .catch((err) => console.log('addNewChapter', err));
};

export const updateChapter = (id, formData) => {
    const config = {
        'content-type': 'multipart/form-data',
    };
    return createAPIELearning()
        .patch(`/admin-chapter/${id}`, formData, config)
        .then((response) => response.data)
        .catch((err) => console.log('updateJapaneseChapter', err));
};

export const deleteChapter = (id) => {
    return createAPIELearning()
        .delete(`/admin-chapter/${id}`)
        .then((response) => response.data)
        .catch((err) => console.log('deleteJapaneseChapter', err));
};

export const updateChapterOrder = (info) => {
    return createAPIELearning()
        .patch(`/admin-chapter/update-order`, info)
        .then((response) => response.data)
        .catch((err) => `updateChapterOrder,${err.message}`);
};

//lesson
export const getLessonsByChapter = (chapterid) => {
    return createAPIELearning()
        .get(`/admin-lesson/${chapterid}`)
        .then((response) => response.data)
        .catch((err) => console.log('getLessonsByChapter', err));
};

export const postNewLesson = (formData) => {
    const config = { 'content-type': 'multipart/form-data' };
    return createAPIELearning()
        .post(`/admin-lesson/create`, formData, config)
        .then((response) => response.data)
        .catch((err) => console.log('postNewJapaneseLesson', err));
};

export const updateLesson = (id, formData) => {
    const config = { 'content-type': 'multipart/form-data' };
    return createAPIELearning()
        .patch(`/admin-lesson/${id}`, formData, config)
        .then((response) => response.data)
        .catch((err) => console.log('updateLesson', err));
};

export const deleteLesson = (id) => {
    return createAPIELearning()
        .delete(`/admin-lesson/${id}`)
        .then((response) => response.data)
        .catch((err) => console.log('deletLesson', err));
};

export const updateLessonOrder = (info) => {
    return createAPIELearning()
        .patch(`/admin-lesson/update-order`, info)
        .then((response) => response.data)
        .catch((err) => console.log(`updateLessonOrder,${err}`));
};

//content
export const getSkillContentsByLessonID = (lessonId) => {
    return createAPIELearning()
        .get(`/admin-skill-content/all/${lessonId}`)
        .then((response) => response.data)
        .catch((err) => console.log('getSkillContentsByLessonId'));
};

export const getSkillContentDetail = (id) => {
    return createAPIELearning()
        .get(`/admin-skill-content/${id}`)
        .then((response) => response.data)
        .catch((err) => console.log('getSkillContentDetail', err.message));
};

export const postNewSkillContent = (data, file) => {
    // const config = { headers: { 'content-type': 'multipart/form-data' } };
    const formData = new FormData();
    for (const key in data) {
        formData.append(key, data[key]);
        console.log(key, data[key])
    }
    if (file) {
        formData.append(file[0].originFileObj.fieldname, file[0].originFileObj);
        console.log(file[0].originFileObj.fieldname, file[0].originFileObj)
    }

    return createAPIELearning()
        .post(`/admin-skill-content/create`, formData)
        .then((response) => response.data)
        .catch((err) => console.log('getSkillContentDetail', err.message));
};

export const updateSkillContent = (data, file) => {
  //  const config = { headers: { 'content-type': 'multipart/form-data' } };
    const formData = new FormData();
    for (const key in data) {
        formData.append(key, data[key]);
    }

    if (file) {
        formData.append(file[0].originFileObj.fieldname, file[0].originFileObj);
    }

    return createAPIELearning()
        .patch(`/admin-skill-content/${data.id}`, formData)
        .then((response) => response.data)
        .catch((err) => console.log('getSkillContentDetail', err.message));
};

export const updateSkillContentOrder = (data) => {
    return createAPIELearning()
        .patch(`/admin-skill-content/update-order`, data)
        .then((response) => response.data)
        .catch((err) => console.log('getSkillContentDetail', err.message));
};

export const deleteSkillCoentnt = (id) => {
    return createAPIELearning()
        .delete(`/admin-skill-content/${id}`)
        .then((response) => response.data)
        .catch((err) => console.log('getSkillContentDetail', err.message));
};
