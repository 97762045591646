import { createAPIELearning } from '../../api';

export const createMarkdown = (info) => {
    return createAPIELearning()
        .post(`/admin-markdown`, info)
        .then((response) => response.data)
        .catch((err) => console.log(`createMarkdown err: ${err}`));
};

export const updateMarkdown = (id, info) => {
    return createAPIELearning()
        .patch(`/admin-markdown/${id}`, info)
        .then((response) => response.data)
        .catch((err) => console.log(`updateMarkdown err: ${err}`));
};

export const deleteMarkdown = (id) => {
    return createAPIELearning()
        .delete(`/admin-markdown/${id}`)
        .then((response) => response.data)
        .catch((err) => console.log(`deleteMarkdown err: ${err}`));
};

export const getMarkdowns = (type) => {
    let url = type ? `/admin-markdown?type=${type}` : '/admin-markdown';
    return createAPIELearning()
        .get(url)
        .then((response) => response.data)
        .catch((err) => err.response);
};
