import React, { useState, useRef, useEffect, useCallback } from "react";
import { Modal, Button, Input, Table, message, Popconfirm } from "antd";
import { PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons";
import { searchEmployees, createEmployerEmployee, deleteEmployerEmployee } from "../../utils/api/employer_employee";

const ModalEmployerEmployees = ({
  width,
  visible,
  setVisible,
  employer_id,
  handleRemoveEmployee,
  handleAddEmployee,
}) => {
  const timeout_typing_state = {
    typingTimeout: 0,
  };

  const isSubscribed = useRef(true);
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [totalEmployee, setTotalEmployee] = useState(0);
  const [textSearch, setTextSearch] = useState("");
  const [timeoutTyping, setTimeoutTyping] = useState(timeout_typing_state);

  const columns = [
    { title: "CODE", dataIndex: "code", key: "code", width: "20%" },
    { title: "NAME", dataIndex: "name", key: "name", width: "40%" },
    { title: "PHONE", dataIndex: "phone", key: "phone", width: "20%" },
    {
      title: "",
      key: "actions",
      width: "5%",
      render: (record) => (
        <span style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
          {record.employer_employee_id === "" ? (
            <Button
              title="Add"
              type={record.employer_employee_id === "" ? "primary" : "danger"}
              className="action-button-margin"
              icon={record.employer_employee_id === "" ? <PlusSquareOutlined /> : <MinusSquareOutlined />}
              onClick={(e) => handleDelete(e, record)}
            />
          ) : (
            <Popconfirm
              placement="topLeft"
              title={"Do you want to delete?"}
              onConfirm={() => {
                handleDelete(null, record);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                title="Add"
                type={record.employer_employee_id === "" ? "primary" : "danger"}
                className="action-button-margin"
                icon={record.employer_employee_id === "" ? <PlusSquareOutlined /> : <MinusSquareOutlined />}
              />
            </Popconfirm>
          )}
        </span>
      ),
    },
  ];

  const handleFetchData = useCallback(
    async (page = 1, keyword = "") => {

      setLoading(true);
      try {
        console.log("employer_id: ", employer_id);
        searchEmployees(page, keyword, "name, code, phone, id", employer_id).then((response) => {
          isSubscribed.current && setLoading(false);

          if (response.data && isSubscribed.current) {
            setTotalEmployee(response.total);
            setEmployees(response.data);
          }
        });
      } catch (error) {
        isSubscribed.current && setLoading(false);
        message.error(`Uh oh, request failed!`);
      }
    },
    [employer_id]
  );

  useEffect(() => {
    if (visible) {
      isSubscribed.current && handleFetchData();
    }
    return () => {
      if (visible) {
        isSubscribed.current = false;
      }
    };
  }, [visible, handleFetchData]);

  const handlePageChange = (page) => {
    handleFetchData(page, textSearch);
  };

  const handleDelete = async (e, record) => {
    if (record.employer_employee_id !== "") {
      // Delete
      deleteEmployerEmployee(record.employer_employee_id).then((response) => {
        if (response && response.errorCode === 0) {
          // for refresh current page
          setEmployees(employees.map((item) => (item.id === record.id ? { ...item, employer_employee_id: "" } : item)));
          // send to main page
          handleRemoveEmployee(record.employer_employee_id);
        } else {
          message.error(response && response.message);
        }
      });
    } else {
      //Create
      const data = {
        employer_id: employer_id,
        employee_id: record.id,
      };
      createEmployerEmployee(data).then((response) => {
        if (response && response.errorCode === 0) {
          message.info("candidate added.");
          // for refresh current page
          setEmployees(
            employees.map((item) =>
              item.id === record.id ? { ...item, employer_employee_id: response.data.id } : item
            )
          );
          // send to main page
          handleAddEmployee(response.data);
        } else {
          message.error(response && response.message);
        }
      });
    }
  };

  const handleTextChange = (e) => {
    var kw = e.target.value;
    setTextSearch(kw);

    if (timeoutTyping.typingTimeout) {
      clearTimeout(timeoutTyping.typingTimeout);
    }

    setTimeoutTyping({
      typingTimeout: setTimeout(function () {
        handleFetchData(1, kw);
      }, 1000),
    });
  };

  const dataSource = employees.length
    ? employees.map((employee, index) => {
        return {
          ...employee,
          key: employee.id,
          no: index + 1,
          name: employee.name,
          phone: employee.phone,
          code: employee.code,
        };
      })
    : [];

  return (
    <Modal
      width={width}
      maskClosable={false}
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      onOk={() => {
        setVisible(false);
      } }
    >
      <div className="list-container-employer">
        <h2>Search Employee</h2>
        <Input.Search
          placeholder="Keyword"
          style={{ marginBottom: 20 }}
          onChange={(e) => {
            handleTextChange(e);
          }}
          value={textSearch}
        />
        <Table
          rowClassName={(_, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
          bordered
          loading={loading}
          dataSource={dataSource}
          columns={columns}
          pagination={{
            defaultCurrent: 1,
            defaultPageSize: 15,
            total: totalEmployee,
            onChange: handlePageChange,
          }}
        />
      </div>
    </Modal>
  );
};
export default ModalEmployerEmployees;
