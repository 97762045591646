import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

//antds
import { Row, Table, Button, message, Space, Modal } from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    KeyOutlined,
    PlusOutlined,
    TeamOutlined,
    DollarCircleOutlined,
} from '@ant-design/icons';

//utils and constant
import API from '../../utils/api';
import getTextDisplay, { dataType } from '../../utils/data';

import { Container, LodoshaButton } from '../../components/UI/ui.style';
import Search from '../../components/Search';
import ModalPassword from '../../components/ModalPassword';
import ModalUpsert from './ModalUpsert';

const timeout_typing_state = { typingTimeout: 0 };

const HospitalsPage = () => {
    const history = useHistory();
    const location = useLocation();
    const page = location.search ? Number(queryString.parse(location.search).page) : 1;

    const isSubscribed = useRef(true);
    const [loading, setLoading] = useState(false);
    const [hospitals, setHospitals] = useState([]);
    const [hospital, setHospital] = useState();
    const [total, setTotal] = useState(0);
    const [timeoutTyping, setTimeoutTyping] = useState(timeout_typing_state);
    const [keyword, setKeyword] = useState('');
    const [visibleUpsert, setVisibleUpsert] = useState(false);
    const [visiblePassword, setVisiblePassword] = useState(false);

    const handleFetchHospitals = React.useCallback(async (page = 1, order = '', field = '') => {
        const category = 9; //hospital
        isSubscribed.current && setLoading(true);
        try {
            const response =
                page === 1
                    ? await API().get(`/partner/${category}?order=${order}&field=${field}`)
                    : await API().get(`/partner/${category}?order=${order}&field=${field}&offset=${(page - 1) * 15}`);

            isSubscribed.current && response?.data?.data && setHospitals(response.data.data);
            isSubscribed.current && setLoading(false);
            setTotal(response.data.total);
        } catch (error) {
            isSubscribed.current && setLoading(false);
            message.error(`Uh oh, request failed!`);
        }
    }, []);

    useEffect(() => {
        handleFetchHospitals(page);
    }, [page, handleFetchHospitals]);

    const handleSearch = (value) => {
        setKeyword(value);
        if (timeoutTyping.typingTimeout) {
            clearTimeout(timeoutTyping.typingTimeout);
        }

        setTimeoutTyping({
            typingTimeout: setTimeout(async () => {
                setLoading(true);
                const response = await API().get(`/partner/search?keyword=${value}`);
                response?.data?.data && setHospitals(response.data.data);
                setLoading(false);
            }, 1000),
        });
    };

    const handleHideHospital = async (e, record) => {
        e.stopPropagation();
        setLoading(true);
        const visible = !record.is_visible;
        const data = { visible };

        const newArr = hospitals.map((h) => {
            if (h.id === record.id) return { ...h, is_visible: visible };
            return h;
        });

        setHospitals(newArr);

        const response = await API().put(`/partner/${record.id}`, data);
        if (response && response.data.errorCode === 0) {
            message.success((data.visible ? 'Visible' : 'Hide') + ' Partner Successful.');
            setLoading(false);
        } else {
            message.error('Update not success.');
            setLoading(false);
        }
    };

    const handlePassword = (e, record) => {
        e.stopPropagation();
        setHospital({ ...record, type: 'partner' });
        setVisiblePassword(true);
    };

    const handleHospitalFees = (e, record) => {
        e.stopPropagation();
        history.push(`/hospitals/hospital-fees/${record?.id}`, record);
    };

    const handleHospitalEmployees = (e, record) => {
        e.stopPropagation();
        history.push(`/hospitals/hospital-employees/${record?.id}`, record);
    };

    const handleEdit = (e, record) => {
        e.stopPropagation();
        setHospital(record);
        setVisibleUpsert(true);
    };

    const handleDelete = (e, record) => {
        e.stopPropagation();
        e.stopPropagation();
        Modal.confirm({
            title: `Are you sure delete ${record.name}?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await API().delete(`/partner/${record.id}`);
                    if (response.data.errorCode === 0) {
                        message.success(`Deleted successfully!`);
                        handleFetchHospitals(page);
                    } else {
                        message.error(`Failed! ${response.data.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request failed!`);
                }
            },
            onCancel() {},
        });
    };

    const handleRow = (record, rowIndex) => {
        return {
            onClick: (e) => {
                e.stopPropagation();
                history.push(`/hospitals/detail/${record.id}`, record);
            }, // click row
        };
    };

    const handleChangePagination = (page) => {
        history.replace(`/partners${page !== 1 ? `?page=${page}` : ''}`);
    };

    const handleChangeTable = (_pagination, _filters, sorter) => {
        if (sorter) {
            const field = sorter ? sorter.columnKey : '';
            const order = sorter ? sorter.order : '';
            handleFetchHospitals(page, order, field);
        }
    };

    const items = [{ label: 'Search:', type: 'text', value: keyword, onSearch: handleSearch }];

    const dataSource =
        hospitals.length &&
        hospitals.map((partner) => {
            return { ...partner, key: partner.id };
        });

    const columns = [
        { title: 'HOSPITAL NAME', dataIndex: 'name', key: 'name', width: '15%', fixed: 'left' },
        { title: 'PERSON IN CHARGE', dataIndex: 'contact_name', key: 'contact-name', width: '15%' },
        {
            title: 'GENDER',
            dataIndex: 'gender',
            key: 'gender',
            width: 100,
            align: 'center',
            render: (value) => getTextDisplay(value, dataType.GENDER),
        },
        { title: 'PHONE', dataIndex: 'phone', key: 'phone', width: 120 },
        { title: 'EMAIL', dataIndex: 'email', key: 'email', width: '19%' },
        { title: 'CLIENTS', dataIndex: 'total_clients', key: 'total_clients', width: '11%', align: 'center' },
        {
            title: '',
            key: 'actions',
            fixed: 'right',
            render: (record) => (
                <Space align="center" direction="horizontal" size="small">
                    <Button
                        type="primary"
                        icon={record?.is_visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        onClick={(e) => handleHideHospital(e, record)}
                    />
                    <Button type="primary" icon={<KeyOutlined />} onClick={(e) => handlePassword(e, record)} />
                    <Button
                        type="primary"
                        icon={<DollarCircleOutlined />}
                        onClick={(e) => handleHospitalFees(e, record)}
                    />
                    <Button
                        type="primary"
                        icon={<TeamOutlined />}
                        onClick={(e) => handleHospitalEmployees(e, record)}
                    />
                    <Button type="primary" icon={<EditOutlined />} onClick={(e) => handleEdit(e, record)} />
                    <Button type="danger" icon={<DeleteOutlined />} onClick={(e) => handleDelete(e, record)} />
                </Space>
            ),
        },
    ];

    return (
        <Container>
            <Search items={items} />
            <Row style={{ marginTop: 24, marginBottom: 32 }}>
                <LodoshaButton type="primary" icon={<PlusOutlined />} onClick={() => setVisibleUpsert(true)}>
                    New
                </LodoshaButton>
            </Row>
            {visibleUpsert && (
                <ModalUpsert
                    visible={visibleUpsert}
                    setVisible={setVisibleUpsert}
                    hospital={hospital}
                    setHospital={setHospital}
                    onRefresh={handleFetchHospitals}
                    page={page}
                />
            )}
            {visiblePassword && (
                <ModalPassword visible={visiblePassword} setVisible={setVisiblePassword} data={hospital} />
            )}
            <Table
                scroll={{ x: 1200 }}
                bordered
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                onRow={handleRow}
                onChange={handleChangeTable}
                pagination={{
                    onChange: handleChangePagination,
                    current: page,
                    defaultCurrent: 1,
                    defaultPageSize: 15,
                    total,
                }}
            />
        </Container>
    );
};

export default HospitalsPage;
