import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, List, message, Modal, Popconfirm, Row, Select, Spin, Form } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

//components
import { LodoshaButton } from '../../components/UI/ui.style';

//utils and constants
import createAPI from '../../utils/api';
import { createPartnerJob, deletePartnerJob, getPartnerJobsByPartnerId } from '../../utils/api/partner_job';

export default function ModalPartnerJob({ visible, setVisible, partner }) {
    const { Option } = Select;
    const [form] = Form.useForm();
    const [spinning, setSpinning] = useState(false);

    const [jobs, setJobs] = useState([]);
    const [partnerJobs, setPartnerJobs] = useState([]);
    const [data, setData] = useState({ partner_id: partner ? partner.id : '' });
    const [partnerExistJobIds, setParterExistJobIds] = useState([]);
    const handleCancel = () => {
        setVisible(false);
        setData({});
    };

    const handleSelectJob = (value) => {
        setData((prev) => ({ ...prev, job_id: value }));
    };

    const handleFetchAvailableJobs = async () => {
        try {
            const response = await createAPI().get('/job/all');
            if (response) {
                if (response?.data?.errorCode === 0) {
                    setJobs(response.data?.data);
                } else {
                    message.error('Something went wrong');
                }
                setSpinning(false);
            } else {
                message.error(`Response Undefined`);
                setSpinning(false);
            }
        } catch (error) {
            setSpinning(false);
            message.error(`Error: ${error?.message}`);
        }
    };

    const handleFetchPartnerJobs = useCallback(async () => {
        setSpinning(true);
        try {
            const response = await getPartnerJobsByPartnerId(data.partner_id);
            if (response) {
                if (response.errorCode === 0) {
                    setPartnerJobs(response.data);
                    //Disable jobs that partner already have, in select
                    if (response.data.length > 0) {
                        response.data.map((item) => setParterExistJobIds((prev) => [...prev, item.job.id]));
                    }
                } else {
                    message.error(`Error: ${response?.message}`);
                }
                setSpinning(false);
            } else {
                message.error(`Response Undefined`);
                setSpinning(false);
            }
        } catch (error) {
            setSpinning(false);
            message.error(`Error: ${error?.message}`);
        }
    }, [data.partner_id]);

    const handleDeletePartnerJob = async (partnerJobID, jobID) => {
        setSpinning(true);
        try {
            const response = await deletePartnerJob(partnerJobID);
            if (response) {
                if (response.errorCode === 0) {
                    message.success('Deleted successfully');
                    setParterExistJobIds((prev) => prev.filter((prevExist) => prevExist !== jobID));

                    handleFetchPartnerJobs();
                } else {
                    message.error(`Error: ${response?.message}`);
                }
                setSpinning(false);
            } else {
                message.error(`Response Undefined`);
                setSpinning(false);
            }
        } catch (error) {
            setSpinning(false);
            message.error(`Error: ${error?.message}`);
        }
    };

    const handleAddJob = async () => {
        setSpinning(true);
        try {
            const response = await createPartnerJob(data);
            if (response) {
                if (response.errorCode === 0) {
                    message.success('Created successfully');
                    setData((prev) => ({ ...prev, job_id: '' }));
                    setParterExistJobIds([]);
                    form.resetFields(["job"])
                    handleFetchPartnerJobs();
                } else {
                    message.error(`Error: ${response.message}`);
                }
                setSpinning(false);
            } else {
                message.error(`Response Undefined`);
                setSpinning(false);
            }
        } catch (error) {
            setSpinning(false);
            message.error(`Error: ${error?.message}`);
        }
    };

    useEffect(() => {
        handleFetchAvailableJobs();
    }, []);

    useEffect(() => {
        handleFetchPartnerJobs();
    }, [handleFetchPartnerJobs]);

    console.log('partnerJob', partnerExistJobIds);

    return (
        <Modal title="Job" visible={visible} footer={null} maskClosable={false} onCancel={handleCancel}>
            <Spin spinning={spinning}>
                <Form form={form}>
                    <Row justify="space-between">
                        <Form.Item style={{ width: '75%' }} name="job">
                            <Select size="large" onChange={(value) => handleSelectJob(value)}>
                                {jobs &&
                                    jobs.length > 0 &&
                                    jobs.map((job, index) => (
                                        <Option
                                            key={index}
                                            value={job.id}
                                            disabled={partnerExistJobIds.includes(job.id) && true}
                                        >
                                            {job.name}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                        <LodoshaButton
                            icon={<PlusOutlined />}
                            style={{ width: '20%' }}
                            type="primary"
                            onClick={() => handleAddJob()}
                            disabled={!data.job_id && true}
                        >
                            Add
                        </LodoshaButton>
                    </Row>
                </Form>
                <List
                    style={{ marginTop: '1rem' }}
                    dataSource={partnerJobs}
                    renderItem={(item, index) => (
                        <List.Item
                            key={index}
                            actions={[
                                <Popconfirm
                                    onConfirm={() => handleDeletePartnerJob(item.id, item.job.id)}
                                    title="Are you sure?"
                                >
                                    <Button type="danger" icon={<DeleteOutlined />} />
                                </Popconfirm>,
                            ]}
                        >
                            {item.job.name}
                        </List.Item>
                    )}
                />
            </Spin>
        </Modal>
    );
}
