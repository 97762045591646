import { getIdFromFilePath } from '../../index';
import { createAPIELearning } from '../../api';

export const importJapanese = (data, file) => {
    const formData = new FormData();

    for (const key in data) {
        formData.append(key, data[key]);
    }

    formData.append('csv-file', file);

    return createAPIELearning()
        .post('/admin-chapter/import-japanese', formData)
        .then((response) => response.data)
        .catch((err) => console.log(`importJapanese: `, err));
};

//chapter
export const getChaptersByMenuID = (menuID) => {
    return createAPIELearning()
        .get(`/admin-chapter/${menuID}`)
        .then((response) => response.data)
        .catch((error) => error.response);
};

export const postNewChapter = (chapterName, type, chapterOrder, menuid, pdfFile, file) => {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    const formData = new FormData();

    formData.append('name', chapterName);
    formData.append('type', type);
    formData.append('order', chapterOrder);
    formData.append('menu', menuid);

    formData.append(pdfFile[0]?.originFileObj.fieldname, pdfFile[0]?.originFileObj);
    formData.append('chapter-image', file);

    return createAPIELearning()
        .post(`/admin-chapter/create/${menuid}`, formData, config)
        .then((response) => response.data)
        .catch((err) => err.response);
};

export const updateChapter = (id, values, pdfFile, file) => {
    const config = { headers: { 'content-type': 'application/pdf' } };

    const formData = new FormData();

    for (let key in values) {
        formData.append(key, values[key]);
    }

    pdfFile && formData.append(pdfFile[0]?.originFileObj?.fieldname, pdfFile[0]?.originFileObj);
    file && formData.append('chapter-image', file);

    return createAPIELearning()
        .patch(`/admin-chapter/${id}`, formData, config)
        .then((response) => response.data)
        .catch((err) => err.response);
};

export const deleteChapter = (id) => {
    return createAPIELearning()
        .delete(`/admin-chapter/${id}`)
        .then((response) => response.data)
        .catch((err) => err.response);
};

export const updateChapterOrder = (info) => {
    return createAPIELearning()
        .patch(`/admin-chapter/update-order`, info)
        .then((response) => response.data)
        .catch((err) => err.response);
};

//lesson
export const getLessonsByChapter = (chapterid) => {
    return createAPIELearning()
        .get(`/admin-lesson/${chapterid}`)
        .then((response) => response.data)
        .catch((err) => err.response);
};

export const postNewLesson = (formData) => {
    const config = { 'content-type': 'multipart/form-data' };
    return createAPIELearning()
        .post(`/admin-lesson/create`, formData, config)
        .then((response) => response.data)
        .catch((err) => err.response);
};

export const updateLesson = (id, formData) => {
    // check values in formdata
    // for (let [key, value] of formData.entries()) {
    //     console.log(key, value);
    // }
    const config = { 'content-type': 'multipart/form-data' };
    return createAPIELearning()
        .patch(`/admin-lesson/${id}`, formData, config)
        .then((response) => response.data)
        .catch((err) => err.response);
};

export const deleteLesson = (id) => {
    return createAPIELearning()
        .delete(`/admin-lesson/${id}`)
        .then((response) => response.data)
        .catch((err) => err.response);
};

export const updateLessonOrder = (info) => {
    return createAPIELearning()
        .patch(`/admin-lesson/update-order`, info)
        .then((response) => response.data)
        .catch((err) => err.response);
};

export const swapLessonOrder = (lessonId, targetLessonId) => {
    return createAPIELearning()
        .patch(`/admin-lesson/swap-order`, {
            lessonId: lessonId,
            targetLessonId: targetLessonId,
        })
        .then((response) => response.data)
        .catch((err) => console.log(`swapLessonOrder err: ${err.response}`));
};

//content
export const getContentsByLessonID = (lessonid) => {
    return createAPIELearning()
        .get(`/admin-lesson-contents/japanese/${lessonid}`)
        .then((response) => {
            return response.data;
        })
        .catch((err) => err.response);
};

export const postNewJapaneseContent = (data, tempFiles) => {
    const formData = new FormData();
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    for (const key in data) {
        formData.append(key, data[key]);
    }
    for (const file of tempFiles) {
        //file.originFileObj.pathname is custom (not original from file)
        formData.append(file.originFileObj.fieldname, file.originFileObj);
    }

    return createAPIELearning()
        .post(`/admin-lesson-contents/create-japanese`, formData, config)
        .then((response) => {
            return response.data;
        })
        .catch((err) => err.response);
};

export const updateJapaneseContent = (data, tempFiles) => {
    const formData = new FormData();
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    for (const key in data) {
        switch (key) {
            case 'character_image': {
                const extractID = getIdFromFilePath(data, key);
                formData.append(key, extractID);
                break;
            }
            case 'character_sound': {
                const extractID = getIdFromFilePath(data, key);
                formData.append(key, extractID);
                break;
            }
            case 'word_sound': {
                const extractID = getIdFromFilePath(data, key);
                formData.append(key, extractID);
                break;
            }
            case 'word_image': {
                const extractID = getIdFromFilePath(data, key);
                formData.append(key, extractID);
                break;
            }
            case 'write_gif': {
                const extractID = getIdFromFilePath(data, key);
                formData.append(key, extractID);
                break;
            }
            default:
                formData.append(key, data[key] ? data[key] : '');
        }
    }

    for (const file of tempFiles) {
        //file.originFileObj.pathname is custom (not original from file)
        formData.append(file.originFileObj.fieldname, file.originFileObj);
    }

    // check values in formdata
    // for (let [key, value] of formData.entries()) {
    //     console.log(key, value);
    // }

    return createAPIELearning()
        .patch(`/admin-lesson-contents/japanese/${data.id}`, formData, config)
        .then((response) => response.data)
        .catch((err) => err.response);
};

export const deleteJapaneseContent = (id) => {
    return createAPIELearning()
        .delete(`/admin-lesson-contents/japanese/${id}`)
        .then((response) => response.data)
        .catch((err) => console.log('deleteContent', err));
};

export const getJapaneseDetailByContentId = (contentid) => {
    return createAPIELearning()
        .get(`/admin-lesson-contents/japanese/detail/${contentid}`)
        .then((response) => response.data)
        .catch((err) => err.response);
};

export const updateJapaneseContentOrder = (info) => {
    return createAPIELearning()
        .patch(`/admin-lesson-contents/japanese/update-order`, info)
        .then((response) => response.data)
        .catch((err) => err.response);
};
