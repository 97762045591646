import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Spin, Descriptions, message } from 'antd';
import ModalStatus from '../../components/ModalStatus';
import { Container, LodoshaButton } from '../../components/UI/ui.style';
import ModalUpsert from './ModalUpsert';
import API, { baseURL } from '../../utils/api';
import getTextDisplay, { dataType, dataEmployerStatus } from '../../utils/data';

const Employer = () => {
    const params = useParams();
    const [loading, setLoading] = useState();
    const [employer, setEmployer] = useState();
    const [visibleUpsert, setVisibleUpsert] = useState(false);
    const [visibleStatus, setVisibleStatus] = useState(false);

    useEffect(() => {
        let isSubscribed = true;

        const handleFetchEmployer = async () => {
            try {
                const response = await API().get(`/employer/detail/${params.id}`);
                if (response.data.data && isSubscribed) {
                    setEmployer(response.data.data);
                } else {
                    // User not exists
                    message.info(response.data.message);
                }
                isSubscribed && setLoading(false);
            } catch (error) {
                isSubscribed && setLoading(false);
            }
        };

        handleFetchEmployer();

        return () => {
            isSubscribed = false;
        };
    }, [params]);

    const handleSaveStatus = async (status) => {
        await API().patch(`/employer/status/${employer.id}`, { status });
        setEmployer({ ...employer, status });
        setVisibleStatus(false);
    };

    const baseItems = [
        {
            label: 'Company Name',
            name: 'company_name',
            value: employer?.company_name,
        },
        {
            label: 'Representation Name',
            name: 'representation_name',
            value: employer?.representation_name,
        },
        { label: 'Gender', name: 'gender', value: employer?.gender },
        { label: 'Phone', name: 'phone', value: employer?.phone },
        { label: 'Email', name: 'email', value: employer?.email },
        { label: 'Country', name: 'country', value: employer?.country },
        { label: 'Employee Count', name: 'employee_count', value: employer?.employee_count },
        { label: 'Created Year', name: 'created_year', value: employer?.created_year },
        {
            label: 'Industry Type',
            name: 'industry_type',
            value: employer?.industry_type,
        },
        { label: 'Address', name: 'address', value: employer?.address },
    ];

    const languageItems = [
        { label: 'Company Name', name: 'company_name', value: employer?.khmer?.company_name || 'N/A' },
        {
            label: 'Representation Name',
            name: 'representation_name',
            value: employer?.khmer?.representation_name || 'N/A',
        },
        { label: 'Gender', name: 'gender', value: employer?.gender },
        { label: 'Phone', name: 'phone', value: employer?.phone },
        { label: 'Email', name: 'email', value: employer?.email },
        { label: 'Country', name: 'country', value: employer?.country },
        { label: 'Employee Count', name: 'employee_count', value: employer?.employee_count },
        { label: 'Created Year', name: 'created_year', value: employer?.created_year },
        { label: 'Industry Type', name: 'industry_type', value: employer?.industry_type },
        { label: 'Address', name: 'address', value: employer?.khmer?.address || 'N/A' },
    ];

    return (
        <Container>
            <Spin tip="Loading..." spinning={loading}>
                <Row>
                    <Col span={24}>
                        <Descriptions title="Base Information" bordered column={4}>
                            {baseItems.map((item) => (
                                <Descriptions.Item key={item.name} label={item.label} span={item.span}>
                                    {item.name === 'gender'
                                        ? getTextDisplay(item.value, dataType.GENDER)
                                        : item.name === 'industry_type'
                                        ? getTextDisplay(item.value, dataType.INDUSTRY)
                                        : item.name === 'country'
                                        ? getTextDisplay(item.value, dataType.COUNTRY)
                                        : item.value}
                                </Descriptions.Item>
                            ))}
                        </Descriptions>
                    </Col>
                    <Col span={24} style={{ marginTop: 24 }}>
                        <Descriptions title="Language Information" bordered column={4}>
                            {languageItems.map((item) => (
                                <Descriptions.Item key={item.name} label={item.label} span={item.span}>
                                    {item.name === 'gender'
                                        ? getTextDisplay(item.value, dataType.GENDER)
                                        : item.name === 'industry_type'
                                        ? getTextDisplay(item.value, dataType.INDUSTRY)
                                        : item.name === 'country'
                                        ? getTextDisplay(item.value, dataType.COUNTRY)
                                        : item.value}
                                </Descriptions.Item>
                            ))}
                        </Descriptions>
                    </Col>
                </Row>
                {employer?.logo ? (
                    <Row>
                        <Col style={{ marginTop: 24 }}>
                            <Descriptions title="Logo">
                                <Descriptions.Item>
                                    <img src={baseURL + employer.logo} alt="Logo" style={{ height: 150 }} />
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                ) : null}
                {employer?.photos ? (
                    <Row>
                        <Col style={{ marginTop: 24 }}>
                            <Descriptions title="Activity Photo" column={{ xxl: 4, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}>
                                {employer.photos.map((photo, index) => (
                                    <Descriptions.Item key={photo.id} span={1}>
                                        <img
                                            style={{ width: 'auto', height: '200px' }}
                                            src={baseURL + photo.image}
                                            alt={'Photo ' + index}
                                        />
                                    </Descriptions.Item>
                                ))}
                            </Descriptions>
                        </Col>
                    </Row>
                ) : null}

                <Row
                    style={{
                        position: 'sticky',
                        bottom: '0',
                        marginTop: '40px',
                        padding: '20px 0',
                        background: '#FAFAFA',
                    }}
                >
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <LodoshaButton
                            type="primary"
                            style={{ marginRight: 16 }}
                            onClick={() => setVisibleUpsert(true)}
                        >
                            Update
                        </LodoshaButton>
                        <LodoshaButton onClick={() => setVisibleStatus(true)}>
                            {getTextDisplay(employer?.status, dataType.EMPLOYER_STATUS)}
                        </LodoshaButton>
                        {visibleUpsert && (
                            <ModalUpsert
                                visible={visibleUpsert}
                                setVisible={setVisibleUpsert}
                                employer={employer}
                                setEmployer={setEmployer}
                            />
                        )}
                        {visibleStatus && (
                            <ModalStatus
                                visible={visibleStatus}
                                setVisible={setVisibleStatus}
                                status={employer.status}
                                steps={dataEmployerStatus}
                                onSave={handleSaveStatus}
                            />
                        )}
                    </Col>
                </Row>
            </Spin>
        </Container>
    );
};

export default Employer;
