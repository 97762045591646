import { createAPIELearning } from '../../api';
import { getIdFromFilePath } from '../../index';

export const getJapaneseContentSentences = (contentId) => {
    return createAPIELearning()
        .get(`/admin-sentence/japanese-class/${contentId}`)
        .then((response) => response.data)
        .catch((err) => err.response);
};

export const getSkillContentSentences = (contentId) => {
    return createAPIELearning()
        .get(`/admin-sentence/skill/${contentId}`)
        .then((response) => response.data)
        .catch((err) => console.log(`getJapaneseContentSentences,${err}`));
};

export const getJLPTContentSentences = (contentId) => {
    return createAPIELearning()
        .get(`/admin-sentence/jlpt/${contentId}`)
        .then((response) => response.data)
        .catch((err) => console.log('getJLPTCOntentSentences', err));
};

export const postNewSentence = (data, files) => {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    const formData = new FormData();
    for (const key in data) {
        formData.append(key, data[key]);
    }
    for (const file of files) {
        //file.originFileObj.fieldname is custom (not original from file)
        formData.append(file.originFileObj.fieldname, file.originFileObj);
    }
    // check values in formdata
    // for (let [key, value] of formData.entries()) {
    //     console.log(key, value);
    // }
    return createAPIELearning()
        .post(`/admin-sentence/create`, formData, config)
        .then((response) => response.data)
        .catch((err) => err.response);
};

export const updateSentence = (data, files) => {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    const formData = new FormData();
    console.log('data', data);
    for (const key in data) {
        switch (key) {
            case 'sound': {
                const extractID = getIdFromFilePath(data, key);
                formData.append(key, extractID);
                break;
            }
            case 'image': {
                const extractID = getIdFromFilePath(data, key);
                formData.append(key, extractID);
                break;
            }
            default:
                formData.append(key, data[key]);
        }
    }

    for (const file of files) {
        //file.originFileObj.fieldname is custom (not original from file)
        formData.append(file.originFileObj.fieldname, file.originFileObj);
    }
    // check values in formdata
    // for (let [key, value] of formData.entries()) {
    //     console.log(key, value);
    // }
    return createAPIELearning()
        .patch(`/admin-sentence/${data.id}`, formData, config)
        .then((response) => response.data)
        .catch((err) => err.response);
};

export const deleteSentence = (id) => {
    return createAPIELearning()
        .delete(`/admin-sentence/${id}`)
        .then((response) => response.data)
        .catch((err) => err.response);
};

export const updateSentenceOrder = (info) => {
    return createAPIELearning()
        .patch(`/admin-sentence/update-order`, info)
        .then((response) => response.data)
        .catch((err) => err.response);
};
