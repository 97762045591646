import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, Divider, Spin, message } from 'antd';
import {
    PhoneOutlined,
    IdcardOutlined,
    UserOutlined,
    NotificationOutlined,
    DoubleRightOutlined,
} from '@ant-design/icons';
import { DashboardWrapper } from './dashboard.style';
import { Doughnut, Pie, Bar, HorizontalBar } from 'react-chartjs-2';
import { Link, useHistory } from 'react-router-dom';
import { CANDIDATE_CALL, CANDIDATES, COMPANIES, POSTJOBS, ADVANCE_SEARCH } from '../../constants/routes';

import 'chartjs-plugin-datalabels';

import API from '../../utils/api';

const Dashboard = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [dataJapaneseLevel, setDataJapaneseLevel] = useState({});
    const [dataGender, setDataGender] = useState({});
    const [dataInterestingJob, setDataInterestingJob] = useState({});
    const [dataPercentage, setDataPercentage] = useState({});
    const [dataPartnerPassedCandidate, setDataPartnerPassedCandidate] = useState([]);

    const [dataInterestingJobIds, setDataInterestingJobIds] = useState([]);

    const history = useHistory();

    const handleFetchReports = useCallback(async () => {
        setLoading(true);
        try {
            const response = await API().get(`/reports`);
            const { data } = response.data;

            if (data) {
                setData(data);
                console.log(data)
                // Japanese Level Report
                const japaneseLevel = data?.employee?.japanese_level;
                const japaneseLevelKeys = Object.keys(japaneseLevel);
                const japaneseLevelValues = japaneseLevelKeys.map((each) => japaneseLevel[each]);

                setDataJapaneseLevel({
                    labels: ['Nothing', 'Learning', 'N1', 'N2', 'N3', 'N4', 'N5'],
                    datasets: [
                        {
                            label: '# of Japanese Level',
                            data: japaneseLevelValues,
                            backgroundColor: [
                                'rgba(204, 204, 204, 0.8)',
                                'rgba(255, 99, 132, 0.8)',
                                'rgba(255, 206, 86, 0.8)',
                                'rgba(75, 192, 192, 0.8)',
                                'rgba(153, 102, 255, 0.8)',
                                'rgba(255, 159, 64, 0.8)',
                                'rgba(54, 162, 235, 0.8)',
                            ],
                            borderColor: [
                                'rgba(204, 204, 204, 0.9)',
                                'rgba(255, 99, 132, 0.9)',
                                'rgba(255, 206, 86, 0.9)',
                                'rgba(75, 192, 192, 0.9)',
                                'rgba(153, 102, 255, 0.9)',
                                'rgba(255, 159, 64, 0.9)',
                                'rgba(54, 162, 235, 0.9)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                });

                // Japanese Level Report
                const gender = data?.employee?.gender;
                const genderKeys = Object.keys(gender);
                const genderValues = genderKeys.map((each) => gender[each]);

                setDataGender({
                    labels: ['None', 'Male', 'Female'],
                    datasets: [
                        {
                            label: '# of Gender',
                            data: genderValues,
                            backgroundColor: [
                                'rgba(204, 204, 204, 0.8)',
                                'rgba(54, 79, 173, 0.8)',
                                'rgba(255, 159, 64, 0.8)',
                            ],
                            borderColor: [
                                'rgba(204, 204, 204, 0.9)',
                                'rgba(54, 79, 173, 0.9)',
                                'rgba(255, 159, 64, 0.9)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                });

                // Candidate complete info percentage
                const percentage = data?.employee?.percentage;
                const percentageKeys = Object.keys(percentage);
                const percentageValues = percentageKeys.map((each) => percentage[each]);

                setDataPercentage({
                    labels: ['0%', '20%', '40%', '60%', '80%', '100%'],
                    datasets: [
                        {
                            label: '# of Completion Information',
                            data: percentageValues,
                            backgroundColor: [
                                'rgba(204, 204, 204, 0.8)',
                                'rgba(75, 192, 192, 0.8)',
                                'rgba(153, 102, 255, 0.8)',
                                'rgba(255, 159, 64, 0.8)',
                                'rgba(54, 162, 235, 0.8)',
                                'rgba(19, 43, 136, 0.8)',
                            ],
                            borderColor: [
                                'rgba(204, 204, 204, 0.9)',
                                'rgba(75, 192, 192, 0.9)',
                                'rgba(153, 102, 255, 0.9)',
                                'rgba(255, 159, 64, 0.9)',
                                'rgba(54, 162, 235, 0.9)',
                                'rgba(19, 43, 136, 0.9)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                });
                // graph postjob
                const graphPostjobs = data?.postJobs;
                const interestingJobs = data?.interesting_job;

                let graphpostKeys = [];
                let graphpostValue = [];
                let interestingJobKeys = [];
                let interestingJobValue = [];

                graphPostjobs?.forEach((postJob) => {
                    graphpostValue.push(postJob.total);
                    graphpostKeys.push(postJob.name);

                    if (interestingJobs.length > 0) {
                        const interestingjob = interestingJobs.find((job) => job.id === postJob.id);
                        if (interestingjob) {
                            interestingJobKeys.push(interestingjob.name);
                            interestingJobValue.push(interestingjob.job_want);
                        }
                    }
                });

                // Interesting Job Report

                let interestingJobIds = [];

                interestingJobs.forEach((job) => {
                    interestingJobIds.push(job.id);
                });

                setDataInterestingJobIds(interestingJobIds);

                setDataInterestingJob({
                    labels: interestingJobKeys,
                    datasets: [
                        {
                            label: '# of Interesting Job',
                            data: interestingJobValue,
                            backgroundColor: 'rgba(19, 43, 136, 0.2)',
                            borderColor: 'rgba(19, 43, 136, 0.6)',
                            borderWidth: 1,
                        },
                        {
                            label: '# of Postjob Category',
                            data: graphpostValue,
                            backgroundColor: '#FCFFA6',
                            borderColor: 'rgba(19, 43, 136, 0.6)',
                            borderWidth: 1,
                        },
                    ],
                });

                let partnersName = [];
                let noOfCandidates = [];
                const noOfCandidatePassInteviewOfEachPartner = data.noOfCandidatePassInteviewOfEachPartner
                    ? data.noOfCandidatePassInteviewOfEachPartner
                    : [];

                noOfCandidatePassInteviewOfEachPartner.forEach((p) => {
                    partnersName.push(p.name ? p.name : '');
                    noOfCandidates.push(p.pass ? p.pass : 0);
                });

                console.log(partnersName)
                console.log(noOfCandidates)

                setDataPartnerPassedCandidate({
                    labels: partnersName,
                    datasets: [
                        {
                            label: '# of Passed Candidate',
                            data: noOfCandidates,
                            backgroundColor: 'rgba(19, 43, 136, 0.2)',
                            borderColor: 'rgba(19, 43, 136, 0.6)',
                            borderWidth: 1,
                        },
                    ],
                });

                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            message.error(`Uh oh, request failed!`);
        }
    }, []);

    useEffect(() => {
        handleFetchReports();
    }, [handleFetchReports]);

    const chartOption = (color, display = true) => {
        return {
            plugins: {
                datalabels: {
                    display: display,
                    color: color,
                    labels: {
                        title: {
                            font: {
                                weight: 'bold',
                            },
                        },
                    },
                },
            },
        };
    };

    return (
        <Spin tip="Loading..." spinning={loading}>
            <DashboardWrapper>
                <Row gutter={[16, 16]}>
                    <Col md={12} xl={6}>
                        <Card className="item-container">
                            <Link to={{ pathname: `${CANDIDATE_CALL}` }}>
                                <div>
                                    <div className="icon star">
                                        <PhoneOutlined style={{ color: '#157BFF', fontSize: 20, marginTop: 10 }} />
                                    </div>
                                    <div className="total-all">
                                        <h1>{data?.call ? data.call.total : 0}</h1>
                                        <p>Calls</p>
                                    </div>
                                </div>
                                <Row type="flex" justify="center" align="bottom" className="container-detail">
                                    <Col span={7}>
                                        <p className="title-detail">Today</p>
                                        <p className="number-detail">{data?.call ? data.call.totalDay : 0}</p>
                                    </Col>
                                    <Col span={1}>
                                        <Divider type="vertical" />
                                    </Col>
                                    <Col span={7}>
                                        <p className="title-detail">This Week</p>
                                        <p className="number-detail">{data?.call ? data.call.totalWeek : 0}</p>
                                    </Col>
                                    <Col span={1}>
                                        <Divider type="vertical" />
                                    </Col>
                                    <Col span={7}>
                                        <p className="title-detail">This Month</p>
                                        <p className="number-detail">{data?.call ? data.call.totalMonth : 0}</p>
                                    </Col>
                                </Row>
                            </Link>
                        </Card>
                    </Col>
                    <Col md={12} xl={6}>
                        <Card className="item-container">
                            <Link to={{ pathname: `${CANDIDATES}` }}>
                                <div>
                                    <div className="icon employee">
                                        <IdcardOutlined style={{ color: '#8967FA', fontSize: 20, marginTop: 10 }} />
                                    </div>
                                    <div className="total-all">
                                        <h1>{data?.employee ? data.employee.total : 0}</h1>
                                        <p>
                                            <span style={{ color: '#247924', fontWeight: 'bold' }}>
                                                {data?.employee ? data.employee.totalNonDelete : 0}
                                            </span>{' '}
                                            Candidates
                                        </p>
                                    </div>
                                </div>
                                <Row type="flex" justify="center" align="bottom" className="container-detail">
                                    <Col span={7}>
                                        <p className="title-detail">Today</p>
                                        <p className="number-detail">{data?.employee ? data.employee.totalDay : 0}</p>
                                    </Col>
                                    <Col span={1}>
                                        <Divider type="vertical" />
                                    </Col>
                                    <Col span={7}>
                                        <p className="title-detail">This Week</p>
                                        <p className="number-detail">{data?.employee ? data.employee.totalWeek : 0}</p>
                                    </Col>
                                    <Col span={1}>
                                        <Divider type="vertical" />
                                    </Col>
                                    <Col span={7}>
                                        <p className="title-detail">This Month</p>
                                        <p className="number-detail">{data?.employee ? data.employee.totalMonth : 0}</p>
                                    </Col>
                                </Row>
                            </Link>
                        </Card>
                    </Col>

                    <Col md={12} xl={6}>
                        <Card className="item-container">
                            <Link to={{ pathname: `${COMPANIES}` }}>
                                <div>
                                    <div className="icon employer">
                                        <UserOutlined style={{ color: '#FCB900', fontSize: 20, marginTop: 10 }} />
                                    </div>
                                    <div className="total-all">
                                        <h1>{data?.employer ? data?.employer.total : 0}</h1>
                                        <p>Employers</p>
                                    </div>
                                </div>
                                <Row type="flex" justify="center" align="bottom" className="container-detail">
                                    <Col span={7}>
                                        <p className="title-detail">Today</p>
                                        <p className="number-detail">{data?.employer ? data.employer.totalDay : 0}</p>
                                    </Col>
                                    <Col span={1}>
                                        <Divider type="vertical" />
                                    </Col>
                                    <Col span={7}>
                                        <p className="title-detail">This Week</p>
                                        <p className="number-detail">{data?.employer ? data.employer.totalWeek : 0}</p>
                                    </Col>
                                    <Col span={1}>
                                        <Divider type="vertical" />
                                    </Col>
                                    <Col span={7}>
                                        <p className="title-detail">This Month</p>
                                        <p className="number-detail">{data?.employer ? data.employer.totalMonth : 0}</p>
                                    </Col>
                                </Row>
                            </Link>
                        </Card>
                    </Col>
                    <Col md={12} xl={6}>
                        <Card className="item-container">
                            <Link to={{ pathname: `${POSTJOBS}` }}>
                                <div>
                                    <div className="icon post-job">
                                        <NotificationOutlined
                                            style={{ color: '#33C434', fontSize: 20, marginTop: 10 }}
                                        />
                                    </div>
                                    <div className="total-all">
                                        <h1>{data?.postJob ? data.postJob.total : 0}</h1>
                                        <p>Post Jobs</p>
                                    </div>
                                </div>
                                <Row type="flex" justify="center" align="bottom" className="container-detail">
                                    <Col span={7}>
                                        <p className="title-detail">Today</p>
                                        <p className="number-detail">{data?.postJob ? data.postJob.totalDay : 0}</p>
                                    </Col>
                                    <Col span={1}>
                                        <Divider type="vertical" />
                                    </Col>
                                    <Col span={7}>
                                        <p className="title-detail">This Week</p>
                                        <p className="number-detail">{data?.postJob ? data.postJob.totalWeek : 0}</p>
                                    </Col>
                                    <Col span={1}>
                                        <Divider type="vertical" />
                                    </Col>
                                    <Col span={7}>
                                        <p className="title-detail">This Month</p>
                                        <p className="number-detail">{data?.postJob ? data.postJob.totalMonth : 0}</p>
                                    </Col>
                                </Row>
                            </Link>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col md={4} xl={4}>
                        <Card
                            className="item-container"
                            hoverable
                            onClick={() => {
                                localStorage.setItem('advance-search', 'candidate');
                                localStorage.setItem(
                                    'advance-search-value',
                                    JSON.stringify({ application: 'sent-to-interview' })
                                );
                                history.push(ADVANCE_SEARCH);
                            }}
                        >
                            <p className="lable-title">Sent To Interview</p>
                            <p className="lable-value">{data?.interview ? data.interview.sent_interview : 0}</p>
                            <p style={{ textAlign: 'center', marginTop: 10, marginBottom: '-10px' }}>
                                <DoubleRightOutlined style={{ fontSize: 20, color: '#192C81' }} />
                            </p>
                        </Card>
                    </Col>
                    <Col md={4} xl={4}>
                        <Card
                            className="item-container"
                            hoverable
                            onClick={() => {
                                localStorage.setItem('advance-search', 'candidate');
                                localStorage.setItem('advance-search-value', JSON.stringify({ application: 'passed' }));
                                history.push(ADVANCE_SEARCH);
                            }}
                        >
                            <p className="lable-title">Passed</p>
                            <p className="lable-value">{data?.interview ? data.interview.passed_interview : 0}</p>
                            <p style={{ textAlign: 'center', marginTop: 10, marginBottom: '-10px' }}>
                                <DoubleRightOutlined style={{ fontSize: 20, color: '#192C81' }} />
                            </p>
                        </Card>
                    </Col>
                    <Col md={4} xl={4}>
                        <Card
                            className="item-container"
                            hoverable
                            onClick={() => {
                                localStorage.setItem('advance-search', 'candidate');
                                localStorage.setItem(
                                    'advance-search-value',
                                    JSON.stringify({ application: 'have-paid' })
                                );
                                history.push(ADVANCE_SEARCH);
                            }}
                        >
                            <p className="lable-title">Have Paid</p>
                            <p className="lable-value">{data?.interview ? data.interview.paid_interview : 0}</p>
                            <p style={{ textAlign: 'center', marginTop: 10, marginBottom: '-10px' }}>
                                <DoubleRightOutlined style={{ fontSize: 20, color: '#192C81' }} />
                            </p>
                        </Card>
                    </Col>
                    <Col md={4} xl={4}>
                        <Card
                            className="item-container"
                            hoverable
                            onClick={() => {
                                localStorage.setItem('advance-search', 'candidate');
                                localStorage.setItem(
                                    'advance-search-value',
                                    JSON.stringify({ application: 'have-not-paid' })
                                );
                                history.push(ADVANCE_SEARCH);
                            }}
                        >
                            <p className="lable-title">Haven't Paid</p>
                            <p className="lable-value">{data?.interview ? data.interview.unpaid_interview : 0}</p>
                            <p style={{ textAlign: 'center', marginTop: 10, marginBottom: '-10px' }}>
                                <DoubleRightOutlined style={{ fontSize: 20, color: '#192C81' }} />
                            </p>
                        </Card>
                    </Col>
                    <Col md={4} xl={4}>
                        <Card
                            className="item-container"
                            hoverable
                            onClick={() => {
                                localStorage.setItem('advance-search', 'candidate');
                                localStorage.setItem(
                                    'advance-search-value',
                                    JSON.stringify({ application: 'sent-to-japan' })
                                );
                                history.push(ADVANCE_SEARCH);
                            }}
                        >
                            <p className="lable-title">Sent To Japan</p>
                            <p className="lable-value">{data?.interview ? data.interview.sent_to_japan : 0}</p>
                            <p style={{ textAlign: 'center', marginTop: 10, marginBottom: '-10px' }}>
                                <DoubleRightOutlined style={{ fontSize: 20, color: '#192C81' }} />
                            </p>
                        </Card>
                    </Col>
                    <Col md={4} xl={4}>
                        <Card
                            className="item-container"
                            hoverable
                            onClick={() => {
                                localStorage.setItem('advance-search', 'candidate');
                                localStorage.setItem(
                                    'advance-search-value',
                                    JSON.stringify({ application: 'back-from-japan' })
                                );
                                history.push(ADVANCE_SEARCH);
                            }}
                        >
                            <p className="lable-title">Come Back Japan</p>
                            <p className="lable-value">{data?.interview ? data.interview.back_from_japan : 0}</p>
                            <p style={{ textAlign: 'center', marginTop: 10, marginBottom: '-10px' }}>
                                <DoubleRightOutlined style={{ fontSize: 20, color: '#192C81' }} />
                            </p>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col md={24} xl={12}>
                        <Card className="item-container">
                            <h1 style={{ textAlign: 'center' }}>Candidate's Japanese Level</h1>
                            <Doughnut
                                data={dataJapaneseLevel}
                                options={chartOption('black', false)}
                                onElementsClick={(elem) => {
                                    if (elem.length > 0) {
                                        localStorage.setItem('advance-search', 'candidate');
                                        localStorage.setItem(
                                            'advance-search-value',
                                            JSON.stringify({ japanses_level: elem[0]['_index'] })
                                        );
                                        history.push(ADVANCE_SEARCH);
                                    }
                                }}
                            />
                        </Card>
                    </Col>
                    <Col md={24} xl={12}>
                        <Card className="item-container">
                            <h1 style={{ textAlign: 'center' }}>Gender</h1>
                            <Pie
                                data={dataGender}
                                options={chartOption('black')}
                                onElementsClick={(elem) => {
                                    if (elem.length > 0) {
                                        localStorage.setItem('advance-search', 'candidate');
                                        localStorage.setItem(
                                            'advance-search-value',
                                            JSON.stringify({ gender: elem[0]['_index'] })
                                        );
                                        history.push(ADVANCE_SEARCH);
                                    }
                                }}
                            />
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col md={24} xl={24}>
                        <Card className="item-container">
                            <h1 style={{ textAlign: 'center' }}>Completed info by Candidates </h1>
                            <HorizontalBar
                                data={dataPercentage}
                                options={chartOption('black')}
                                onElementsClick={(elem) => {
                                    if (elem.length > 0) {
                                        localStorage.setItem('advance-search', 'candidate');
                                        localStorage.setItem(
                                            'advance-search-value',
                                            JSON.stringify({ percentage: elem[0]['_index'] * 20 })
                                        );
                                        history.push(ADVANCE_SEARCH);
                                    }
                                }}
                            />
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col md={24} xl={24}>
                        <Card className="item-container">
                            <h1 style={{ textAlign: 'center' }}>Partners' Passed Interview Candidates</h1>
                            <Bar data={dataPartnerPassedCandidate} options={chartOption('black')} />
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col md={24} xl={24}>
                        <Card className="item-container">
                            <h1 style={{ textAlign: 'center' }}>Candidate's Interested</h1>
                            <Bar
                                data={dataInterestingJob}
                                options={chartOption('black')}
                                onElementsClick={(elem) => {
                                    if (elem.length > 0) {
                                        localStorage.setItem('advance-search', 'candidate');
                                        localStorage.setItem(
                                            'advance-search-value',
                                            JSON.stringify({ job_id: dataInterestingJobIds[elem[0]['_index']] })
                                        );
                                        history.push(ADVANCE_SEARCH);
                                    }
                                }}
                            />
                        </Card>
                    </Col>
                </Row>
            </DashboardWrapper>
        </Spin>
    );
};

export default Dashboard;
