import React from "react";
import { Row, Col, Modal, Form, message } from "antd";
import { LodoshaInput, LodoshaButton, LodoshaTextArea } from "../../components/UI/ui.style";
import API from "../../utils/api";

const Translate = ({ visible, setVisible, postJob, setPostJob }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [translate, setTranslate] = React.useState();

  React.useEffect(() => {
    const handleFetchTranslate = async () => {
      const responselanguage = await API().get(`/language/name?country=khmer`);
      const languageId = responselanguage.data.data.id;
      const responseTranslate = await API().get(
        `/postjob/language/getPostJobLanguage?language_id=${languageId}&post_job_id=${postJob.id}`
      );

      if (responseTranslate.data.errorCode === 404) {
        setTranslate((prevState) => ({ ...prevState, language_id: languageId, post_job_id: postJob.id }));
      } else {
        setTranslate(responseTranslate.data.data);
        form.setFieldsValue(responseTranslate.data.data);
      }
    };

    postJob?.id && handleFetchTranslate();
  }, [postJob, form]);

  const handleChange = (name, value) => {
    // console.log(name, value);
    setTranslate((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  const handleSubmit = async (values) => {
    setLoading(false);
    // console.log(translate);
    try {
      const response = translate.post_job_id
        ? await API().post(`/postjob/language/create`, translate)
        : await API().patch(`/postjob/language/${translate.id}`, translate);
      // console.log(response);
      if (response?.data?.errorCode === 0) {
        setLoading(false);
        message.success(`${translate.post_job_id ? `Created ` : `Updated`} successfully!`);
        setTranslate();
        setPostJob();
        setVisible(false);
      } else {
        setLoading(false);
        message.error(`Failed, ${response.data.message}`);
      }
    } catch (error) {
      setLoading(false);
      message.error(`Uh oh, request failed!`);
    }
  };

  const items = [
    { type: "text", label: "Job Title", name: "job_title", value: postJob?.job_title },
    { type: "input", label: "Salary Description", name: "salary", value: postJob?.salary },
    {
      type: "text",
      label: "Working Hour Description",
      name: "working_hour",
      value: postJob?.working_hour,
    },
    {
      type: "text",
      label: "Working Day Description",
      name: "working_day",
      value: postJob?.working_day,
    },
    {
      type: "textarea",
      label: "Job Description",
      name: "job_description",
      value: postJob?.job_description,
    },
    {
      type: "textarea",
      label: "Job Responsibility",
      name: "job_resposibility",
      value: postJob?.job_resposibility,
    },
    {
      type: "textarea",
      label: "Job Requirement",
      name: "job_requirement",
      value: postJob?.job_requirement,
    },
  ];

  const translateItems = [
    {
      type: "text",
      label: "Job Title",
      name: "job_title",
      value: translate ? translate.job_title : "",
      require: true,
    },
    { type: "text", label: "Salary Description", name: "salary", value: translate ? translate.salary : "" },
    {
      type: "text",
      label: "Working Hour Description",
      name: "working_hour",
      value: translate ? translate.working_hour : "",
    },
    {
      type: "text",
      label: "Working Day Description",
      name: "working_day",
      value: translate ? translate.working_day : "",
    },
    {
      type: "textarea",
      label: "Job Description",
      name: "job_description",
      value: translate ? translate.job_description : "",
    },
    {
      type: "textarea",
      label: "Job Responsibility",
      name: "job_resposibility",
      value: translate ? translate.job_resposibility : "",
    },
    {
      type: "textarea",
      label: "Job Requirement",
      name: "job_requirement",
      value: translate ? translate.job_requirement : "",
    },
  ];

  return (
    <Modal visible={visible} maskClosable={false} title="Translate" onCancel={handleCancel} width={768} footer={null}>
      <Row gutter={24}>
        <Col span={12}>
          <Form layout="vertical">
            <h2 style={{ marginBottom: 16 }}>Base Information</h2>

            {items.map((item) => (
              <Form.Item key={item.name} name={item.name} label={item.label} initialValue={item.value}>
                {item.type === "textarea" ? <LodoshaTextArea disabled /> : <LodoshaInput disabled />}
              </Form.Item>
            ))}
          </Form>
        </Col>
        <Col span={12}>
          <Form form={form} layout="vertical">
            <h2 style={{ marginBottom: 16 }}>Translate Information</h2>

            {translateItems.map((item) => (
              <Form.Item
                key={item.name}
                name={item.name}
                label={item.label}
                rules={[{ required: item.require, message: "Data required!" }]}
              >
                {item.type === "textarea" ? (
                  <LodoshaTextArea onChange={(e) => handleChange(item.name, e.target.value)} />
                ) : (
                  <LodoshaInput onChange={(e) => handleChange(item.name, e.target.value)} />
                )}
              </Form.Item>
            ))}
          </Form>
        </Col>
        <Col span={24} style={{ textAlign: "center", marginTop: 16 }}>
          <LodoshaButton key="cancel" style={{ marginRight: 16 }} htmlType="button" onClick={handleCancel}>
            Cancel
          </LodoshaButton>
          <LodoshaButton key="submit" loading={loading} type="primary" htmlType="button" onClick={handleSubmit}>
            {translate?.id ? "Update" : "Create"}
          </LodoshaButton>
        </Col>
      </Row>
    </Modal>
  );
};

export default Translate;
