import styled from '@emotion/styled';

const ELearningWrapper = styled.div`
    .container {
        box-shadow: 4px 3px 5px 0px rgba(49, 46, 46, 0.2);
        -webkit-box-shadow: 4px 3px 5px 0px rgba(49, 46, 46, 0.2);
        -moz-box-shadow: 4px 3px 5px 0px rgba(49, 46, 46, 0.2);
    }
    .menu-container {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .title {
        text-align: center;
        margin-top: 0.5rem;
    }
    .select {
        text-transform: capitalize !important;
    }

    .pdf-column {
        display: flex;
        align-items: center;
    }

    .pdf-icon {
        color: #152688;
        font-size: 25px;
        margin-left: 10px;
    }
`;

export { ELearningWrapper };
