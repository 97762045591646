import React from "react";
import { Row, Col, Form, Modal, Button, Input, message } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { LodoshaButton } from "../components/UI/ui.style";
import API from "../utils/api";

const Password = ({ visible, setVisible, data = {} }) => {
  const [loading, setLoading] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState("");
  const [account, setAccount] = React.useState();
  const [isAccountLoading, setIsAccountLoading] = React.useState(false);
  const [isMoodleLoading, setIsMoodleLoading] = React.useState(false);

  React.useEffect(() => {
    const handleFetchAccount = async () => {
      try {
        const response = await API().get(`/user/account?id=${data.id}&role=${data.type}`);
        if (response.data.errorCode === 0) {
          setAccount(response.data.data);
        } else {
          setAccount();
        }
      } catch (error) {
        // console.log(error);
      }
    };

    data.id && visible && handleFetchAccount();
  }, [data, visible]);

  const handleSendPassword = async (newData) => {
    setLoading(true);
    const response = await API().post(`/user/sendPassword`, newData);
    // console.log('response: ', response);
    setLoading(false);
    if (response && response.data.errorCode === 0) {
      message.success("Account and Password sent.");
      setVisible(false);
    } else if (response && response.data.errorCode === 403) {
      message.error("Account login with facebook not allow to sent.");
    } else {
      message.error("Account and Password not sent.");
    }
  };

  const handleCreateAccount = async (event) => {
    event.preventDefault();
    setIsAccountLoading(true);
    try {
      const response = await API().post(`/user/account`, { id: data.id, role: data.type });
      // console.log("response: ", response);
      if (response && response.data.errorCode === 0) {
        setAccount(response.data.data);
        message.success("Create successfully!");
      } else {
        message.error("Failed, can not create!");
      }
      setIsAccountLoading(false);
    } catch (error) {
      // console.log(error);
      setIsAccountLoading(false);
    }
  };

  const hanldeCreateMoodle = async (event) => {
    event.preventDefault();
    setIsMoodleLoading(true);
    try {
      const response = await API().post(`/user/moodle/${data.id}`);
      // console.log(response);
      if (response && response.data.status === 200) {
        account["moodle_user_id"] = response.data.moodle_id;
        message.success("Create successfuly!");
      } else {
        message.error("Failed, can not create!");
      }
      setIsMoodleLoading(false);
    } catch (error) {
      // console.log(error);
      setIsMoodleLoading(false);
    }
  };

  const handleChangePasswordAndSend = (event) => {
    event.preventDefault();
    let newData = data;
    newData["new_password"] = newPassword;
    newData["send"] = true;
    handleSendPassword(newData);
    setNewPassword("");
  };

  const handleChangePassword = (event) => {
    event.preventDefault();
    let newData = data;
    newData["new_password"] = newPassword;
    //newData['send'] = false;
    handleSendPassword(newData);
    setNewPassword("");
  };

  const handleRandomPassword = (event) => {
    event.preventDefault();
    visible && setNewPassword(Math.random().toString(36).substring(6));
  };

  const handleCancel = () => {
    setNewPassword("");
    setVisible(false);
  };

  return (
    <Modal width={768} maskClosable={false} visible={visible} title="" onCancel={handleCancel} footer={null}>
      <Row gutter={[40, 40]}>
        <Col span={12} style={{ flex: 1 }}>
          <h2>Account Information</h2>
          <p>Email: {account?.email || "N/A"}</p>
          <p>Phone: {account?.phone || "N/A"}</p>
          <p>Facebook ID: {account?.fb_id || "N/A"}</p>
          <div>
            <LodoshaButton
              type="primary"
              loading={isAccountLoading}
              disabled={account?.email || account?.phone || account?.fb_id}
              onClick={handleCreateAccount}
            >
              {"Create Account"}
            </LodoshaButton>
          </div>
        </Col>

        {data && data.type === "employee" && (
          <Col span={12} style={{ flex: 1 }}>
            <h2>Moodle</h2>
            <p>Moodle ID: {account?.moodle_user_id || "N/A"}</p>
            <div style={{ position: "absolute", bottom: 0 }}>
              <LodoshaButton
                type="primary"
                style={{ position: "absolute", bottom: 0 }}
                loading={isMoodleLoading}
                disabled={account && account.moodle_user_id ? true : false}
                onClick={hanldeCreateMoodle}
              >
                {"Create Moodle"}
              </LodoshaButton>
            </div>
          </Col>
        )}

        <Col span={12} style={{ flex: 1 }}>
          <h2>Send Password</h2>
          <Form layout="vertical">
            <Form.Item label="New Password:">
              <Row gutter={16}>
                <Col span={20}>
                  <Input
                    type="text"
                    placeholder="Password"
                    style={{ marginRight: 16 }}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </Col>
                <Col span={4}>
                  <Button title="New Password" type="primary" icon={<SyncOutlined />} onClick={handleRandomPassword} />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item style={{ position: "absolute", bottom: 0 }}>
              <LodoshaButton
                type="primary"
                disabled={!newPassword}
                loading={loading}
                onClick={handleChangePasswordAndSend}
              >
                {"Change & Send"}
              </LodoshaButton>
              <LodoshaButton
                type="primary"
                style={{ marginLeft: 16 }}
                disabled={!newPassword}
                loading={loading}
                onClick={handleChangePassword}
              >
                {"Change"}
              </LodoshaButton>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default Password;
