import React, { useState, useMemo } from 'react';

import { LodoshaButton } from '../../../components/UI/ui.style';

//api
import { postNewJLPTContent, updateJLPTContent } from '../../../utils/api/E-Learning/jlpt';

//ant design
import { Modal, Row, Col, Form, message, Input } from 'antd';
import MarkdownGenerator from '../../../components/MarkdownGenerator';

//constant & utils

function ContentModal({ title, visible, setVisible, onRefresh, setPostData, postData, isUpdate, isCreate }) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { TextArea } = Input;
    const [data, setData] = useState(postData);

    const jlptContentFormFields = useMemo(
        () => [
            {
                label: 'Japanese',
                type: 'text',
                name: 'japanese',
                value: data?.japanese,
                span: 12,
                required: true,
            },
            {
                label: 'Khmer',
                type: 'text',
                name: 'khmer',
                value: data?.khmer,
                span: 12,
                required: true,
            },
            {
                label: 'Explanation',
                type: 'text',
                name: 'explanation',
                value: data?.explanation,
                span: 24,
                required: true,
            },
        ],
        [data]
    );

    //close modal
    const handleCancel = (e) => {
        setVisible(false);
        setPostData([]);
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        setData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const response = isCreate ? await postNewJLPTContent(data) : isUpdate ? await updateJLPTContent(data) : {};
            if (response && response.errorCode !== 0) {
                setLoading(false);
                message.error(`Oh oh, something went wrong.`, response.message);
                return;
            }
            if (response) {
                message.success(isCreate ? 'New content added.' : isUpdate ? 'Successfully updated content' : '');
                setLoading(false);
                onRefresh();
                handleCancel();
            } else {
                message.error(`Uh oh, ${isCreate ? 'add new content' : isUpdate ? 'updated content' : ''} failed!`);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            message.error(`Uh oh, ${isCreate ? 'add new content' : isUpdate ? 'updating content' : ''} failed!`);
        }
    };

    return (
        <Modal width={998} title={title} visible={visible} onCancel={handleCancel} maskClosable={false} footer={null}>
            <Form layout="vertical" form={form} onFinish={() => handleSubmit()}>
                <Row gutter={24}>
                    {jlptContentFormFields?.map((field, index) => {
                        const { type, label, name, value, required, disabled, span } = field;
                        return (
                            <Col span={span} key={index}>
                                <Form.Item
                                    rules={[{ required: required, message: 'Data required!' }]}
                                    label={`${label}:`}
                                    name={name}
                                    initialValue={value}
                                >
                                    <TextArea
                                        type={type}
                                        disabled={disabled && true}
                                        onChange={handleChange}
                                        value={value}
                                        name={name}
                                    />
                                </Form.Item>
                            </Col>
                        );
                    })}
                    <Col span={24}>
                        <MarkdownGenerator data={data} setData={setData} isUpdate={isUpdate} />
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
                        <LodoshaButton
                            key="cancel"
                            style={{ marginRight: 16 }}
                            htmlType="button"
                            onClick={handleCancel}
                        >
                            Cancel
                        </LodoshaButton>
                        <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                            {isCreate ? 'Add New' : isUpdate ? 'Update' : ''}
                        </LodoshaButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default ContentModal;
