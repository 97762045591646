import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Button, Alert, Checkbox } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { LodoshaInput } from '../../components/UI/ui.style';
import { LogInWrapper } from './login.style';
import API from '../../utils/api';
import logo from '../../assets/logo2.png';

const LogIn = () => {
    let history = useHistory();
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (localStorage.email !== '' && localStorage.remember) {
            // Decrypt
            const ciphertext = localStorage.password;
            const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
            const password = bytes.toString(CryptoJS.enc.Utf8);
            form.setFieldsValue({
                email: localStorage.email,
                password,
                remember: localStorage.remember,
            });
        }
    }, [form, secretKey]);

    const handleFinish = async ({ email, password, remember }) => {
        const response = await API().post(`/account/login`, { email, password });
        const domain = process.env.DOMAIN;
        
        if (response.data.token && response.data.middleware_token) {
            Cookies.set('backoffice_token', response.data.token, { expires: 1, domain: domain });
            Cookies.set('middleware_token', response.data.middleware_token, { expires: 1, domain: domain });
            if (email !== '' && remember) {
                // Encrypt
                const ciphertext = CryptoJS.AES.encrypt(password, secretKey).toString();
                localStorage.setItem('email', email);
                localStorage.setItem('password', ciphertext);
                localStorage.setItem('remember', remember);
            }

            if (!remember) {
                localStorage.removeItem('email');
                localStorage.removeItem('password');
                localStorage.removeItem('remember');
            }

            localStorage.setItem('lodosha_user_name', response.data.data['name']);
            localStorage.setItem('lodosha_position', response.data.data['position']);

            setLoading(false);
            history.push('/');
        } else {
            setLoading(false);
            setError(response.data);
        }
    };

    return (
        <LogInWrapper>
            <div className="container">
                <div className="logo">
                    <img src={logo} alt="Lodosha Logo" style={{ width: '50%', height: '50%' }} />
                </div>
                {error && <Alert message="Failed" description="Invalid email or password!" type="error" showIcon />}
                <Form form={form} layout="vertical" initialValues={{ remember: false }} onFinish={handleFinish}>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email!',
                            },
                        ]}
                    >
                        <LodoshaInput
                            className="input"
                            prefix={<MailOutlined style={{ marginRight: 4 }} />}
                            placeholder="Enter your email"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <LodoshaInput
                            className="input"
                            prefix={<LockOutlined style={{ marginRight: 4 }} />}
                            type="password"
                            placeholder="Enter your password"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                    </Form.Item>

                    <Form.Item>
                        <Button size="large" type="primary" htmlType="submit" className="button" loading={loading}>
                            Log In
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </LogInWrapper>
    );
};

export default LogIn;