import React, { useState, useEffect } from "react";
import { Modal, Steps } from "antd";

const StatusPopUp = ({ setVisible, visible, data, setStatus, status, name, handleSaveStatus, errorStatueStep }) => {
  const steps = data.map((d) => <Steps.Step key={d.value} title={d.text} />);

  const [currentStatus, setCurrentStatus] = useState(1);
  const [updateStatus, setUpdateStatus] = useState(1);
  const [stepStatus, setStepStatus] = useState("process");

  useEffect(() => {
    setUpdateStatus(status - 1);
    setCurrentStatus(status - 1);

    if (status === errorStatueStep) {
      setStepStatus("error");
    } else {
      setStepStatus("process");
    }
  }, [status, errorStatueStep]);

  // on step change
  const onChange = (current) => {
    if (current !== 0) {
      setUpdateStatus(current);
    }

    if (current === errorStatueStep - 1) {
      setStepStatus("error");
    } else if (current > 0) {
      setStepStatus("process");
    }
  };

  const handleSave = (e) => {
    if (currentStatus !== updateStatus) {
      handleSaveStatus(updateStatus + 1);
      setStatus(updateStatus + 1);
    }
  };

  return (
    <Modal
      maskClosable={false}
      width="80%"
      title={`CHANGE STATUS OF '${name ? name : "N/A"}'`}
      visible={visible}
      onOk={handleSave}
      okText="Save"
      okButtonProps={{ style: { height: 38, width: 108 } }}
      cancelButtonProps={{ style: { height: 38, width: 108 } }}
      onCancel={(e) => {
        setVisible(false);
      }}
    >
      <Steps
        type="navigation"
        status={stepStatus}
        current={updateStatus}
        onChange={onChange}
        style={{ width: "100%", marginBottom: 60 }}
      >
        {steps}
      </Steps>
    </Modal>
  );
};

export default StatusPopUp;
