
import API from '../api';

export const getEmployerEmployees = (employerId, page = 1) => {
    const url = page === 1 ? `/employer-employee?employer_id=${employerId}` : `/employer-employee?employer_id=${employerId}&offset=${(page - 1) * 15}`;
    return API().get(url)
            .then(response => {
                return response.data;
            })
            .catch(err => { 
                console.log('err: ', err) 
            });
};

export const createEmployerEmployee = (data) => {
    return API().post(`/employer-employee`, data)
            .then(response => {
                return response.data;
            }).catch(err => {
                console.log('err: ', err) 
            })
}

export const deleteEmployerEmployee = (id) => {
    return API().delete(`/employer-employee/${id}`)
            .then(response => {
                return response.data;
            }).catch(err => {
                console.log('err: ', err) 
            })
}
export const searchEmployees = (page=1, keyword, fields, employer_id ) => {
    const url = page === 1 ? `/employer-employee/search-fields?keyword=${keyword}&fields=${fields}&employer_id=${employer_id}` : `/employer-employee/search-fields?offset=${(page - 1) * 15}&keyword=${keyword}&fields=${fields}&employer_id=${employer_id}`;
    return API().get(url)
            .then(response => {
                return response.data;
            })
            .catch(err => { 
                console.log('err: ', err) 
            });
};

export const updateStatus = (id, status) => {
    const url = `/employer-employee/status/${id}`;
    return API().patch(url, { status: status })
            .then(response => {
                return response.data;
            })
            .catch(err => { 
                console.log('err: ', err) 
            });
};