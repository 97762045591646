import React, { useEffect } from 'react';
import { Form, message, Modal, Space, Select } from 'antd';
import { LodoshaButton, LodoshaTextArea, LodoshaDatePicker, LodoshaSelect } from '../../../components/UI/ui.style';
import { createCallHistory, updateCallHistory } from '../../../utils/api/candidate';

import moment from 'moment';

const { Option } = Select;

const ModalUpsertCallHistory = ({ visible, setVisible, callHistory, handleFetchCallHistory }) => {
    const [form] = Form.useForm();

    const dateFormat = 'DD-MM-YYYY';

    useEffect(() => {
        if (visible) {
            form.setFieldsValue({
                summary: callHistory.summary || '',
                follow_up_date: callHistory.follow_up_date ? moment(callHistory.follow_up_date, dateFormat) : null
            });
        }
    }, [form, callHistory, visible]);

    const handleCancel = () => {
        form.resetFields();
        setVisible(false);
    };

    const handleSubmit = async (fieldsValue) => {

        const values = {
            ...fieldsValue,
            follow_up_date: fieldsValue['follow_up_date'] ? fieldsValue['follow_up_date'].format(dateFormat) : null,
        };

        if (values['type'] === '1') {
            if (values['summary'] === '') {
                message.error("Summary Required!")
                return
            }
        }

        if (callHistory?.id) {
            const res = await updateCallHistory(callHistory.id, values);
            if (res.errorCode > 0) {
                message.error(res.message);
                return;
            }
            handleFetchCallHistory();
        } else {
            const data = values;
            data['employee'] = callHistory.employee;
            data['caller_name'] = localStorage.getItem('lodosha_user_name') ? localStorage.getItem('lodosha_user_name') : 'N/A';

            const res = await createCallHistory(data);
            if (res.errorCode > 0) {
                message.error(res.message);
                return;
            }

            handleFetchCallHistory();
        }
        handleCancel()
    };

    return (
        <Modal
            maskClosable={false}
            visible={visible}
            title={callHistory?.id ? 'Update Call' : 'Create New Call'}
            onCancel={handleCancel}
            footer={null}
        >
            <Form form={form} layout="vertical" initialValues={{ caller: null, summary: '' }} onFinish={handleSubmit}>
                <Form.Item
                    name="summary"
                    label="Summary Call:"
                >
                    <LodoshaTextArea placeholder="Enter your summary" />
                    
                </Form.Item>
                <Form.Item
                    name="type"
                    label="Type:"
                    rules={[{ required: true, message: 'Call type required!' }]}
                >
                    <LodoshaSelect
                        placeholder="Select an option"
                    >
                        <Option key="1" value="1">
                        Called
                        </Option>
                        <Option key="2" value="2">
                        Missed Call
                        </Option>
                        <Option key="3" value="3">
                        Can't Contact
                        </Option>
                    </LodoshaSelect>
                    
                </Form.Item>
                <Form.Item
                    name="follow_up_date"
                    label="Follow Up Date:"
                >
                    <LodoshaDatePicker />
                    
                </Form.Item>

                <Form.Item style={{ textAlign: 'center', marginTop: 24 }}>
                    <Space direction="horizontal" size="middle">
                        <LodoshaButton onClick={handleCancel}>Cancel</LodoshaButton>
                        <LodoshaButton type="primary" htmlType="submit">
                            {callHistory?.id ? 'Update' : 'Create'}
                        </LodoshaButton>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalUpsertCallHistory;
