import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Form, Spin, Modal, message, Input, Space } from 'antd';
import { LodoshaButton } from '../../components/UI/ui.style';
import {
    getEmployeeLanguage,
    createEmployeeLanguage,
    updateEmployeeLanguage,
} from '../../utils/api/candidate_language';
import { getLanguage } from '../../utils/api/language';
import { updateStatus } from '../../utils/api/candidate';
import { LodoshaInput } from '../../components/UI/ui.style';

const Translate = ({ visible, setVisible, employee = {}, onRefresh }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [language, setLanguage] = useState({});

    const handleFetchData = useCallback(async () => {
        setLoading(true);
        try {
            const languageCode = await getLanguage('japan');
            const languageId = languageCode && languageCode.data.id;
            const languageData = await getEmployeeLanguage(employee.id, languageId);

            const langData =
                languageData.errorCode === 404
                    ? { language_id: languageId, employee_id: employee.id, id: '' }
                    : languageData.data;

            form.resetFields();
            form.setFieldsValue(langData);

            setLanguage(langData);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error(`Uh Oh, request failed!`);
        }
    }, [employee, form]);

    useEffect(() => {
        if (visible) {
            employee.id && handleFetchData();
        }
    }, [visible, employee, handleFetchData]);

    const handleCancel = () => {
        setVisible(false);
    };

    const handleSubmit = async (values) => {
        var res = null;

        if (language.id === '') {
            values['employee_id'] = language.employee_id;
            values['language_id'] = language.language_id;
            res = await createEmployeeLanguage(values);
        } else {
            res = await updateEmployeeLanguage(language.id, values);
        }

        // update status Publish
        if (res && res.errorCode === 0) {
            await updateStatus(employee.id, '3');
            setVisible(false);
            onRefresh();
        }
    };

    const baseItems = [
        { label: 'Name', type: 'text', value: employee?.name },
        { label: 'Address', type: 'textarea', value: employee?.address },
    ];

    const languageItems = [
        { label: 'Name', type: 'text', name: 'name', value: language?.name, required: true },
        { label: 'Address', type: 'textarea', name: 'address', value: language?.address },
    ];

    return (
        <Modal
            visible={visible}
            maskClosable={false}
            title="Translate"
            onCancel={handleCancel}
            width={768}
            footer={null}
        >
            <Spin tip="Loading..." spinning={loading}>
                <Form form={form} layout="vertical" onFinish={handleSubmit}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <h2>Base Information</h2>
                            {baseItems.map((item, index) => (
                                <Form.Item key={`item-${index}`} label={item.label}>
                                    {item.type === 'text' ? (
                                        <LodoshaInput value={item.value} disabled={true} />
                                    ) : (
                                        <Input.TextArea rows={4} value={item.value} disabled={true} />
                                    )}
                                </Form.Item>
                            ))}
                        </Col>
                        <Col span={12}>
                            <h2>Language Information</h2>
                            {languageItems.map((language) => (
                                <Form.Item
                                    key={language.name}
                                    label={language.label}
                                    name={language.name}
                                    initialValue=""
                                    rules={[
                                        {
                                            required: language.required,
                                            message: 'Data required!',
                                        },
                                    ]}
                                >
                                    {language.type === 'text' ? <Input /> : <Input.TextArea rows={4} />}
                                </Form.Item>
                            ))}
                        </Col>
                    </Row>
                    <Form.Item style={{ textAlign: 'center', marginTop: 16 }}>
                        <Space direction="horizontal" size="middle">
                            <LodoshaButton key="cancel" htmlType="button" onClick={handleCancel}>
                                Cancel
                            </LodoshaButton>
                            <LodoshaButton key="submit" type="primary" htmlType="submit">
                                {'Save'}
                            </LodoshaButton>
                        </Space>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

export default Translate;
