
import API from '../api';

export const getPartnerEmployees = (partnerId, page = 1) => {
    const url = page === 1 ? `/partner-employee?partner_id=${partnerId}` : `/partner-employee?partner_id=${partnerId}&offset=${(page - 1) * 15}`;
    return API().get(url)
            .then(response => {
                return response.data;
            })
            .catch(err => { 
                console.log('err: ', err) 
            });
};

export const createPartnerEmployee = (data) => {
    return API().post(`/partner-employee`, data)
            .then(response => {
                return response.data;
            }).catch(err => {
                console.log('err: ', err) 
            })
}

export const deletePartnerEmployee = (id) => {
    return API().delete(`/partner-employee/${id}`)
            .then(response => {
                return response.data;
            }).catch(err => {
                console.log('err: ', err) 
            })
}
export const searchEmployees = (page=1, keyword, fields, partner_id ) => {
    const url = page === 1 ? `/partner-employee/search-fields?keyword=${keyword}&fields=${fields}&partner_id=${partner_id}` : `/partner-employee/search-fields?offset=${(page - 1) * 15}&keyword=${keyword}&fields=${fields}&partner_id=${partner_id}`;
    return API().get(url)
            .then(response => {
                return response.data;
            })
            .catch(err => { 
                console.log('err: ', err) 
            });
};

export const updateStatus = (id, status) => {
    const url = `/partner-employee/status/${id}`;
    return API().patch(url, { status: status })
            .then(response => {
                return response.data;
            })
            .catch(err => { 
                console.log('err: ', err) 
            });
};