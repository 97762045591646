import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router';

//antd
import { message, Space, Button, Modal, Table } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

//utils and constant
import { deleteLesson, getLessonsByChapter } from '../../../utils/api/E-Learning/jlpt';
import { ELEARNING } from '../../../constants/routes';
import { lessonTypeToText, jlptSubTypeToText } from '../../../utils/data';
import LessonModal from './LessonModal';
import { swapLessonOrder } from '../../../utils/api/E-Learning/jlpt';

//componetns
import { Container, LodoshaButton } from '../../../components/UI/ui.style';
import NextPrevActionButtonGroup from '../E-LearningComponents/NextPrevActionButtonGroup';
import SwapLessonPopover from '../E-LearningComponents/SwapLessonPopover';
import { getELearningItems } from '../../../utils';

export default function LessonList() {
    const [visible, setVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [postData, setPostData] = useState({});
    const [lessons, setLessons] = useState([]);

    const { menuid, chapterid } = useParams();

    const history = useHistory();

    const handleFetchJLPTLessons = useCallback(
        async (clickedItemId) => {
            try {
                setLoading(true);
                const response = await getLessonsByChapter(clickedItemId ? clickedItemId : chapterid);
                if (response) {
                    if (response.errorCode !== 0) {
                        setLoading(false);
                        message.error(`Couldn't get JLPT lessons.${response.errorCode}`);
                        return;
                    }

                    setLessons(response ? response.data : []);
                    setLoading(false);
                    return;
                } else {
                    setLoading(false);
                    message.error(`Couldn't get JLPT lessons. Response is undefined`);
                    return;
                }
            } catch (error) {
                message.error("Couldn't get JLPT lessons.");
                setLoading(false);
            }
        },
        [chapterid]
    );

    const columns = [
        {
            title: 'NAME',
            dataIndex: 'name',
            fixed: 'left',
            width: '25%',
        },
        { title: 'NAME_JP', dataIndex: 'name_jp', width: '25%' },
        { title: 'TYPE', dataIndex: 'typeName', align: 'center', width: '12.5%' },
        { title: 'SUB-TYPE', dataIndex: 'sub_type_display', align: 'center', width: '12.5%' },
        {
            title: 'ACTIONS',
            dataIndex: 'actions',
            align: 'center',
            width: '20%',
            fixed: 'right',
            render: (text, row, index) => (
                <Space>
                    <Button type="primary" title="Edit" onClick={(e) => handleEdit(e, row)} icon={<EditOutlined />} />
                    <SwapLessonPopover
                        onRefresh={handleFetchJLPTLessons}
                        onSwapLesson={swapLessonOrder}
                        lessons={lessons}
                        selectedLessonId={row.id}
                    />
                    <Button
                        type="danger"
                        title="Delete"
                        onClick={(e) => handleDelete(e, row.id)}
                        icon={<DeleteOutlined />}
                    />
                </Space>
            ),
        },
    ];

    const data = useMemo(
        () =>
            lessons &&
            lessons.map((lesson, index) => ({
                ...lesson,
                key: index,
                name: lesson.name,
                name_jp: lesson.name_jp,
                type: lesson.type,
                typeName: lessonTypeToText[lesson.type - 1],
                sub_type: lesson.sub_type,
                sub_type_display: jlptSubTypeToText[lesson.sub_type],
                order: lesson.order,
            })),
        [lessons]
    );

    const Upsert = useCallback(
        () =>
            visible && (
                <LessonModal
                    title="Add New JLPT Lesson"
                    onRefresh={handleFetchJLPTLessons}
                    isCreate
                    visible={visible}
                    setPostData={setPostData}
                    postData={postData}
                    setVisible={setVisible}
                />
            ),
        [visible, postData, handleFetchJLPTLessons]
    );

    const Update = useCallback(
        () =>
            updateVisible && (
                <LessonModal
                    title="Update JLPT Lesson"
                    onRefresh={handleFetchJLPTLessons}
                    isUpdate
                    visible={updateVisible}
                    setPostData={setPostData}
                    postData={postData}
                    setVisible={setUpdateVisible}
                />
            ),
        [updateVisible, postData, handleFetchJLPTLessons]
    );

    const handleDelete = (e, id) => {
        e.stopPropagation();
        Modal.confirm({
            title: 'Are you sure you want to delete this post?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await deleteLesson(id);
                    if (response && response.errorCode === 0) {
                        message.success(`Deleted successfully!`);
                        handleFetchJLPTLessons();
                    } else {
                        message.error(`Couldn't delete JLPT lesson, ${response.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request failed!`);
                }
            },
            onCancel() {},
        });
    };

    const handleEdit = (e, row) => {
        e.stopPropagation();
        setUpdateVisible(true);
        setPostData(row);
    };

    const handleRow = (record, index) => {
        return {
            onClick: (e) => {
                e.stopPropagation();
                //set lessons to localstorage
                try {
                    const eLearningItems = getELearningItems(lessons, 'name');
                    localStorage.setItem('e-learning-lessons', JSON.stringify(eLearningItems));
                } catch (error) {
                    message.error('Set to local storage failed');
                }

                history.push(`${ELEARNING}/${menuid}/${chapterid}/${record.id}`);
            },
        };
    };

    //auto order
    useEffect(() => {
        let lastOrder = lessons.length > 0 ? lessons[lessons.length - 1]?.order + 1 : 1;
        setPostData((prev) => ({ ...prev, order: lastOrder, type: 7, chapter_id: chapterid }));
    }, [visible, lessons, chapterid]);

    useEffect(() => {
        handleFetchJLPTLessons();
    }, [handleFetchJLPTLessons]);

    return (
        <Container>
            <div className="btn-margin-bottom">
                <NextPrevActionButtonGroup
                    onClickAction={handleFetchJLPTLessons}
                    currentItemId={chapterid}
                    localStorageKey="e-learning-chapters"
                />
            </div>
            <LodoshaButton type="primary" className="btn-margin-bottom" onClick={() => setVisible(true)}>
                <PlusOutlined />
                Add New
            </LodoshaButton>
            <Upsert />
            <Update />
            <Table
                loading={loading}
                pagination={false}
                bordered
                dataSource={data}
                columns={columns}
                rowKey="key"
                onRow={handleRow}
            />
        </Container>
    );
}
