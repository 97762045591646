import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Row, Col, Space, Button, Popconfirm, Empty, List, message, Spin } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { LodoshaButton } from '../../../components/UI/ui.style';
import { getCallHistory, deleteCallHistory } from '../../../utils/api/candidate';
import { ListItem, DateTime, DateFollowUp } from './call-history.style';

import queryString from 'query-string';
import moment from 'moment';
import ModalCallHistory from './ModalUpsertCallHistory';
import getTextDisplay, { dataType } from '../../../utils/data';

const dateFormat = 'DD-MM-YYYY h:mm A';

const CallHistory = () => {
    let history = useHistory();
    let location = useLocation();
    let { id: employeeId } = useParams();
    
    const page = location.search ? Number(queryString.parse(location.search).page) : 1;
    const initialState = {
        caller: null,
        summary: '',
        id: null,
        employee: employeeId,
        type: null
    };

    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [callHistories, setCallHistories] = useState([]);
    const [callHistory, setCallHistory] = useState(initialState);
    const [total, setTotal] = useState(0);

    const handleFetchCallHistory = useCallback(
        async (page = 1) => {
            setLoading(true);
            const result = await getCallHistory(page, employeeId);

            if (result?.data) {
                setCallHistories(result.data);
                setTotal(result.total);
            }
            setLoading(false);
        },
        [employeeId]
    );

    useEffect(() => {
        handleFetchCallHistory(page);
    }, [handleFetchCallHistory, page]);

    const handleEdit = (item) => {
        setCallHistory({ ...initialState, ...item });
        setVisible(true);
    };

    const handleDelete = async (item) => {
        const result = await deleteCallHistory(item.id);
        if (result.errorCode === 0) {
            message.success('Deleted Successfully');
            setCallHistories((prevState) => prevState.filter((state) => state.id !== item.id));
        } else {
            message.error(result.message);
        }
    };

    const handleCreate = () => {
        setCallHistory(initialState);
        setVisible(true);
    };

    const handleChangePage = (page) => {
        history.replace(`${location.pathname}?page=${page === 1 ? '' : page}`);
    };

    return (
        <Spin tip="Loading..." spinning={loading}>
            <Row style={{ marginBottom: 24 }} type="flex" justify="space-between">
                <LodoshaButton type="primary" icon={<PlusOutlined />} onClick={handleCreate}>
                    New
                </LodoshaButton>
                <p style={{marginLeft: 20, float: 'right'}}>Called: {callHistories ? callHistories.filter(it => it.type === 1).length : 0}  ·  Not Response: {callHistories ? callHistories.filter(it => it.type === 2).length : 0}  ·  Can't Contact: {callHistories ? callHistories.filter(it => it.type === 3).length : 0} </p>
                <ModalCallHistory
                    visible={visible}
                    setVisible={setVisible}
                    callHistory={callHistory}
                    handleFetchCallHistory={handleFetchCallHistory}
                />
            </Row>
            <Row>
                <Col span={24}>
                    {callHistories.length ? (
                        <List
                            itemLayout="horizontal"
                            split={false}
                            pagination={{
                                current: page,
                                defaultCurrent: 1,
                                defaultPageSize: 15,
                                total: total,
                                onChange: handleChangePage,
                            }}
                            dataSource={callHistories}
                            renderItem={(item) => (
                                <ListItem
                                    extra={
                                        <Space direction="horizontal" size="small">
                                            <Button
                                                type="primary"
                                                icon={<EditOutlined />}
                                                onClick={() => handleEdit(item)}
                                            />
                                            <Popconfirm
                                                placement="topLeft"
                                                title="Are you sure?"
                                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                onConfirm={() => handleDelete(item)}
                                                onCancel={() => {}}
                                                okText="Yes"
                                                okType="danger"
                                                cancelText="No"
                                            >
                                                <Button danger type="primary" icon={<DeleteOutlined />} />
                                            </Popconfirm>
                                        </Space>
                                    }
                                >
                                    <List.Item.Meta
                                        title={
                                            <Space align="baseline" direction="horizontal" size="middle">
                                                <h3>{item.caller_name}</h3>
                                                {item?.created_at ? (
                                                    <DateTime>{moment(item.created_at).format(dateFormat)}</DateTime>
                                                ) : null}
                                                <i>{getTextDisplay(item?.type, dataType.CALL_TYPE)}</i>

                                               {item?.follow_up_date ? (
                                                     <span>Follow Up: <DateFollowUp>{moment(item.follow_up_date).format("DD-MM-YYYY")}</DateFollowUp></span>
                                                ) : null}
                                            </Space>
                                        }
                                        description={item.summary}
                                    />
                                </ListItem>
                            )}
                        />
                    ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                </Col>
            </Row>
        </Spin>
    );
};

export default CallHistory;
