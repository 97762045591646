import styled from '@emotion/styled';
import { List } from 'antd';

const ListItem = styled(List.Item)`
    border: 1px solid ${(props) => props.theme.lightgrey};
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 8px;

    &::last-child {
        margin-bottom: 0;
    }
`;

const DateTime = styled.span`
    font-size: 12px;
    color: ${(props) => props.theme.grey};
`;

const DateFollowUp = styled.span`
    font-size: 14px;
    color: #811919;
`;

export { ListItem, DateTime, DateFollowUp };
