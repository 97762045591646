import React, { useState } from 'react';
import { Modal, Button, Spin, Form, Row, Col, message, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const FilePopUp = ({ visible, handleUploadToServer, onCancel, loading }) => {
    const [form] = Form.useForm();
    const { Dragger } = Upload;
    const [data, setData] = useState();
    const [fileProfile, setFileProfile] = useState([]);
    const [filePhoto, setFilePhoto] = useState([]);
    const [filePension, setFilePension] = useState([]);
    const [fileAttachment, setFileAttachment] = useState([]);
    const [fileIntroductionVideo, setFileIntroductionVideo] = useState([]);

    const handleCancel = () => {
        setData({});
        form.resetFields();
        setFileAttachment([]);
        setFileIntroductionVideo([]);
        setFilePension([]);
        setFilePhoto([]);
        setFileProfile([]);
        onCancel();
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let files = fileAttachment.concat(fileProfile, filePhoto, filePension, fileIntroductionVideo);

        if (files.length > 0) {
            handleUploadToServer(data, files, () => {
                handleCancel();
            });
        } else {
            message.error('Category and File required!');
        }
    };

    const handleChangeFile = (info, fieldname) => {
        let fileList = [...info.fileList];
        // 1. Allow in list file only one
        fileList = fileList.slice(-1);
        // 2. Read from response and show file link
        fileList = fileList.map((file) => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
            }
            return file;
        });

        // important when uploading to server
        if (fileList.length > 0) {
            fileList[0].originFileObj.fieldname = fieldname;
        }

        switch (fieldname) {
            case 'profile': {
                setFileProfile(fileList);
                break;
            }
            case 'photo': {
                setFilePhoto(fileList);
                break;
            }
            case 'introduction_video': {
                setFileIntroductionVideo(fileList);
                break;
            }
            case 'pension': {
                setFilePension(fileList);
                break;
            }
            case 'attachment': {
                setFileAttachment(fileList);
                break;
            }
            default:
        }
    };

    // Avoid after drop, it auto upload to server
    function beforeUpload() {
        return false;
    }

    const handleFinish = (values) => {
        // handleSubmit(e);
    };

    return (
        <Modal
            width={720}
            maskClosable={false}
            title="New File Attachment"
            visible={visible}
            onCancel={() => {
                handleCancel();
            }}
            footer={[
                <Button
                    key="cancel"
                    type="danger"
                    onClick={() => {
                        handleCancel();
                    }}
                >
                    Cancel
                </Button>,
                <Button key="ok" type="primary" disabled={loading} onClick={handleSubmit}>
                    Save
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical" onFinish={handleFinish}>
                <Spin tip="Loading..." spinning={loading}>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item label={<h4 style={{ fontWeight: 'bold', marginBottom: '0' }}>Profile</h4>}>
                                <Dragger
                                    name="profile"
                                    multiple={false}
                                    onChange={(info) => handleChangeFile(info, 'profile')}
                                    fileList={fileProfile}
                                    beforeUpload={beforeUpload}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={<h4 style={{ fontWeight: 'bold', marginBottom: '0' }}>Photo</h4>}>
                                <Dragger
                                    name="photo"
                                    multiple={false}
                                    onChange={(info) => handleChangeFile(info, 'photo')}
                                    fileList={filePhoto}
                                    beforeUpload={beforeUpload}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={<h4 style={{ fontWeight: 'bold', marginBottom: '0' }}>Introduction Video</h4>}
                            >
                                <Dragger
                                    name="introduction_video"
                                    multiple={false}
                                    onChange={(info) => handleChangeFile(info, 'introduction_video')}
                                    fileList={fileIntroductionVideo}
                                    beforeUpload={beforeUpload}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={<h4 style={{ fontWeight: 'bold', marginBottom: '0' }}>Pension</h4>}>
                                <Dragger
                                    name="pension"
                                    multiple={false}
                                    onChange={(info) => handleChangeFile(info, 'pension')}
                                    fileList={filePension}
                                    beforeUpload={beforeUpload}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={<h4 style={{ fontWeight: 'bold', marginBottom: '0' }}>Attachment</h4>}>
                                <Dragger
                                    name="attachment"
                                    multiple={false}
                                    onChange={(info) => handleChangeFile(info, 'attachment')}
                                    fileList={fileAttachment}
                                    beforeUpload={beforeUpload}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        </Modal>
    );
};

export default FilePopUp;
