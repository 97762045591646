import { createAPIELearningMedia } from '../../api';

export const uploadCultureImage = (data) => {
    const config = { 'content-type': 'multipart/form-data' };
    return createAPIELearningMedia()
        .post(`/admin-media/upload-image`, data, config)
        .then((response) => response.data)
        .catch((err) => console.log('uploadImage', err));
};

export const deleteCultureImages = (data) => {
    console.log('data', data);
    return createAPIELearningMedia()
        .delete(`/admin-media/delete-image/${data}`)
        .then((response) => response.data)
        .catch((err) => console.log('deleteCultureImages', err));
};
