import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';

import { message, Space, Table, Button } from 'antd';
import { KeyOutlined, UserSwitchOutlined, EditOutlined } from '@ant-design/icons';

import AppContext from '../../contexts/AppContext';

//constants & utils
import * as ROUTES from '../../constants/routes';
import { getUsers } from '../../utils/api/user';
import { userTypeToText } from '../../utils/data';

//components
import ModalUserType from './ModalUserType';
import ModalPassword from './ModalPassword';
import { Container } from '../../components/UI/ui.style';
import SearchUser from './Search';
import ModalInfo from './ModalInfo';

const timeout_typing_state = { typingTimeout: 0 };

export default function Users() {
    const { handleUnauthorizedUser } = useContext(AppContext);

    const history = useHistory();
    const location = useLocation();

    const isSubscribed = useRef(true);

    const page = location.search
        ? queryString.parse(location.search).page
            ? Number(queryString.parse(location.search).page)
            : 1
        : 1;
    const qKeyword = location.search ? queryString.parse(location.search).keyword : '';

    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState([]);
    const [visiblePassword, setVisiblePassword] = useState(false);
    const [visibleUserRole, setVisibleUserRole] = useState(false);
    const [visibleInfo, setVisibleInfo] = useState(false);
    const [keyword, setKeyword] = useState(qKeyword);
    const [timeoutTyping, setTimeoutTyping] = useState(timeout_typing_state);

    const dataSource = useMemo(
        () =>
            users &&
            users.map((user, index) => ({
                ...user,
                no: (page - 1) * 15 + index + 1,
                key: index,
                email: user.email,
                phone: user.phone,
                membership_name: user.membership_name,
                role: user?.role?.name,
                expiration_date: user.date ? moment(user?.date).format('DD-MM-YYYY') : null,
                typeToText: user.type && userTypeToText[user.type],
            })),
        [users, page]
    );

    const columns = [
        {
            dataIndex: 'no',
            title: '#',
            width: '5%',
            align: 'center',
        },
        {
            dataIndex: 'phone',
            title: 'PHONE',
            width: '10%',
        },
        {
            dataIndex: 'email',
            title: 'EMAIL',
            width: '15%',
        },
        {
            dataIndex: 'role',
            title: 'ROLE',
            width: '15%',
            align: 'center',
            render: (text, row, index) => <span style={{ textTransform: 'capitalize' }}>{text && text}</span>,
        },
        {
            dataIndex: 'membership_name',
            title: 'MEMBERSHIP TYPE',
            width: '18%',
            align: 'center',
            render: (text, row, index) => <span style={{ textTransform: 'capitalize' }}>{text && text}</span>,
        },
        {
            dataIndex: 'expiration_date',
            title: 'EXPIRATION DATE',
            width: '17%',
            align: 'center',
        },
        {
            dataIndex: 'actions',
            title: '',
            width: '10%',
            align: 'center',
            fixed: 'right',
            render: (text, row, index) => (
                <Space>
                    <Button
                        icon={<UserSwitchOutlined />}
                        type="primary"
                        onClick={(e) => handleChangeUserType(e, row)}
                    />
                    <Button icon={<EditOutlined />} type="primary" onClick={(e) => handleEditInfo(e, row)} />
                    <Button icon={<KeyOutlined />} type="primary" onClick={(e) => handleEdit(e, row)} />
                </Space>
            ),
        },
    ];

    const initQueryURL = useCallback(
        (name, value, page = 1) => {
            let currentQuery = {};
            currentQuery['page'] = page;

            if (keyword) {
                currentQuery['keyword'] = keyword;
            }

            if (value) {
                currentQuery[name] = value;
            } else {
                delete currentQuery[name];
            }

            let endPoint = history.location;
            endPoint.search = queryString.stringify(currentQuery);
            history.replace(endPoint);
        },
        [keyword, history]
    );

    const handleFetchUsers = useCallback(
        async (page, keyword) => {
            setLoading(true);
            try {
                const response = await getUsers(page, keyword);
                if (response.data.status === 401) {
                    message.error(`Unauthorized`);
                    handleUnauthorizedUser();
                    return;
                }

                if (response) {
                    if (response.errorCode === 0) {
                        setTotal(response.total);
                        setUsers(response.data);
                        setLoading(false);
                        return;
                    } else {
                        message.error(`Uh oh,couldn't get users. ${response.message}`);
                        setLoading(false);
                        return;
                    }
                } else {
                    message.error(`Uh oh, something went wrong.`);
                    setLoading(false);
                    return;
                }
            } catch (error) {
                setLoading(false);
                message.error(`Uh oh,something went wrong,${error}`);
            }
        },
        [handleUnauthorizedUser]
    );

    const handleEditInfo = (e, row) => {
        setUser(row);
        setVisibleInfo(true);
    };

    const handleEdit = (e, item) => {
        setVisiblePassword(true);
        setUser(item);
    };

    const handleChangeUserType = (e, row) => {
        if (row.employeeId && row.type) {
            setVisibleUserRole(true);
            setUser(row);
        } else {
            message.info('This feature is only for Candidate/Employee');
        }
    };

    const handleChangePagination = (page, pageSize) => {
        const endPoint = `${ROUTES.USERS}?page=${page}`;
        history.replace(endPoint);
        handleFetchUsers(page);
    };

    const handleSearch = (value) => {
        setKeyword(value);

        if (timeoutTyping.typingTimeout) {
            clearTimeout(timeoutTyping.typingTimeout);
        }

        setTimeoutTyping({
            typingTimeout: setTimeout(() => {
                handleFetchUsers(1, value);
            }, 1000),
        });
    };

    const UpdateUserRole = useCallback(
        () =>
            visibleUserRole && (
                <ModalUserType
                    user={user}
                    visible={visibleUserRole}
                    setVisible={setVisibleUserRole}
                    setUser={setUser}
                />
            ),
        [user, visibleUserRole]
    );

    const UpdateUserInfo = useCallback(
        () =>
            visibleInfo && (
                <ModalInfo visible={visibleInfo} setVisible={setVisibleInfo} user={user} setUser={setUser} />
            ),
        [user, visibleInfo]
    );

    useEffect(() => {
        initQueryURL('', '', page);
    }, [page, initQueryURL]);

    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchUsers(1, qKeyword);
        }
        return () => {
            isSubscribed.current = false;
        };
    }, [handleFetchUsers, qKeyword, initQueryURL]);

    return (
        <Container>
            <SearchUser keyword={keyword} onSearch={handleSearch} />
            <ModalPassword visible={visiblePassword} setVisible={setVisiblePassword} setUser={setUser} user={user} />
            <UpdateUserRole />
            <UpdateUserInfo />
            <Table
                columns={columns}
                dataSource={dataSource}
                loading={loading}
                bordered
                pagination={{
                    total: total,
                    onChange: handleChangePagination,
                    pageSize: 15,
                    defaultPageSize: 15,
                    current: page,
                    defaultCurrent: 1,
                }}
            />
        </Container>
    );
}
