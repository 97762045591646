import React, { useState, useMemo } from 'react';

//api
import { updateQuestion, postNewJLPTExercise } from '../../../../utils/api/E-Learning/question';

//ant design
import { Modal, Row, Col, Form, message, Input } from 'antd';

//components
import { LodoshaButton } from '../../../../components/UI/ui.style';

export default function ExerciseModal({
    title,
    visible,
    setVisible,
    onRefresh,
    setPostData,
    postData,
    isCreate,
    isUpdate,
}) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(postData);

    const { TextArea } = Input;

    const questionFormFields = useMemo(
        () => [
            {
                label: 'Title',
                type: 'text',
                name: 'title',
                value: data?.title,
                span: 24,
                required: true,
            },

            {
                label: 'Choice One',
                type: 'text',
                name: 'choice_one',
                value: data?.choice_one,
                span: 24,
                required: true,
            },
            {
                label: 'Choice Two',
                type: 'text',
                name: 'choice_two',
                value: data?.choice_two,
                span: 24,
                required: true,
            },
            {
                label: 'Choice Three',
                type: 'text',
                name: 'choice_three',
                value: data?.choice_three,
                span: 24,
                required: true,
            },
            {
                label: 'Choice Four',
                type: 'text',
                name: 'choice_four',
                value: data?.choice_four,
                span: 24,
                required: true,
            },
            {
                label: 'Answer',
                type: 'text',
                name: 'answer',
                value: data?.answer,
                span: 24,
                required: true,
            },
        ],
        [data]
    );

    //close modal
    const handleCancel = (e) => {
        setVisible(false);
        setPostData({});
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        setData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleSubmit = async () => {
        try {
            console.log(data);
            setLoading(true);

            const response = isCreate ? await postNewJLPTExercise(data) : isUpdate ? await updateQuestion(data) : {};
            if (response && response.errorCode !== 0) {
                setLoading(false);
                message.error(`Oh oh, something went wrong. ${response.message}`);
                return;
            }

            if (response) {
                response && message.success(`Successfully ${isCreate ? 'create' : 'update'} exercise`);
                setLoading(false);
                onRefresh();
                handleCancel();
            } else {
                setLoading(false);
                message.error(`Uh oh, ${isCreate ? 'create' : 'update'} exercise failed!`);
            }
        } catch (error) {
            setLoading(false);
            message.error(`Uh oh, updating exercise failed!`);
        }
    };

    return (
        <Modal
            width={768}
            title={title}
            visible={visible}
            centered
            onCancel={handleCancel}
            maskClosable={false}
            footer={null}
        >
            <Form layout="vertical" form={form} onFinish={() => handleSubmit()}>
                <Row gutter={24}>
                    {questionFormFields?.map((field, index) => {
                        const { type, label, name, value, required, disabled, span } = field;
                        return (
                            <Col span={span} key={index}>
                                <Form.Item
                                    rules={[{ required: required, message: 'Data required!' }]}
                                    label={`${label}:`}
                                    name={name}
                                    initialValue={value}
                                >
                                    <TextArea
                                        type={type}
                                        disabled={disabled && true}
                                        onChange={handleChange}
                                        value={value}
                                        name={name}
                                    />
                                </Form.Item>
                            </Col>
                        );
                    })}
                    <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
                        <LodoshaButton
                            key="cancel"
                            style={{ marginRight: 16 }}
                            htmlType="button"
                            onClick={handleCancel}
                        >
                            Cancel
                        </LodoshaButton>
                        <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                            {isCreate ? 'Create' : isUpdate ? 'Update' : ''}
                        </LodoshaButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
