import createAPI from '../api';

export const createPartnerJob = (data) => {
    return createAPI()
        .post('/partner-job', data)
        .then((response) => response.data)
        .catch((err) => err);
};

export const deletePartnerJob = (id) => {
    return createAPI()
        .delete(`/partner-job/${id}`)
        .then((response) => response.data)
        .catch((err) => err);
};

export const getPartnerJobsByPartnerId = (partnerId) => {
    return createAPI()
        .get(`/partner-job/${partnerId}`)
        .then((response) => response.data)
        .catch((err) => err);
};
