import React, { useCallback, useMemo, useState, useEffect, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import ReactAudioPlayer from 'react-audio-player';

//antd
import { Button, Space, message, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

//components
import { LodoshaButton } from '../../../../components/UI/ui.style';
import SentenceModal from './SentenceModal';
import SortableTable from '../../E-LearningComponents/SortableTable';
import DragHandler from '../../E-LearningComponents/DragHandler';

import AppContext from '../../../../contexts/AppContext';

//utils
import {
    deleteSentence,
    getJapaneseContentSentences,
    updateSentenceOrder,
} from '../../../../utils/api/E-Learning/sentence';
import { getNewLastOrder } from '../../../../utils';

export default function SentenceList() {
    const { handleUnauthorizedUser } = useContext(AppContext);

    const [postData, setPostData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sentences, setSentences] = useState([]);

    const { contentid, chapterid } = useParams();
    const isSubscribed = useRef(true);

    const data = useMemo(
        () =>
            sentences &&
            sentences.map((sentence, index) => ({
                ...sentence,
                no: index + 1,
                key: sentence.id,
                khmer: sentence.khmer,
                japan: sentence.japan,
                english: sentence.english,
                image: sentence.image,
                sound: sentence.sound,
            })),
        [sentences]
    );

    const columns = [
        { title: '#', dataIndex: 'no', width: '5%', align: 'center', fixed: 'left', render: () => <DragHandler /> },
        { title: 'KHMER', dataIndex: 'khmer', width: '15%', align: 'center' },
        { title: 'JAPAN', dataIndex: 'japan', width: '15%', align: 'center' },
        { title: 'ENGLISH', dataIndex: 'english', width: '15%', align: 'center' },
        {
            title: 'IMAGE',
            dataIndex: 'image',
            width: '15%',
            align: 'center',
            render: (text, row, index) => {
                return row.image ? (
                    <img alt="existing" src={row.image} style={{ width: '75px', height: '50px' }} />
                ) : null;
            },
        },
        {
            title: 'SOUND',
            dataIndex: 'sound',
            width: '25%',
            align: 'center',
            render: (text, row, index) => (row.sound ? <ReactAudioPlayer controls src={row.sound} /> : null),
        },
        {
            title: '',
            width: '10%',
            align: 'center',
            fixed: 'right',
            render: (text, row, index) => {
                return (
                    <Space size="small">
                        <Button title="Edit" type="primary" icon={<EditOutlined />} onClick={(e) => handleEdit(row)} />
                        <Button
                            title="Delete"
                            type="danger"
                            icon={<DeleteOutlined />}
                            onClick={(e) => handleDelete(row.id)}
                        />
                    </Space>
                );
            },
        },
    ];

    const handleFetchSentences = useCallback(async () => {
        try {
            setLoading(true);
            const response = await getJapaneseContentSentences(contentid);
            if (response) {
                if (response.errorCode !== 0) {
                    setLoading(false);
                    message.error(`Could not get content sentences , ${response.message}`);
                    return;
                }

                setSentences(response?.data);
                setLoading(false);
                return;
            } else {
                setLoading(false);
                message.error(`Could not get content sentences. Response is undefined`);
                return;
            }
        } catch (error) {
            setLoading(false);
            message.error(`Could not get content sentences.`);
            return;
        }
    }, [contentid]);

    const handleEdit = (sentence) => {
        //chapterId for file path
        setPostData(() => ({ ...sentence, chapterId: chapterid }));
        setUpdateVisible(true);
    };

    const handleDelete = async (sentenceId) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this sentence?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await deleteSentence(sentenceId);
                    if (response && response.errorCode === 0) {
                        message.success(`Deleted successfully!`);
                        handleFetchSentences();
                    } else {
                        if (response.data.status === 401) {
                            message.error(`Unauthorized`);
                            handleUnauthorizedUser();
                            return;
                        }
                        message.error(`Failed, ${response.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request failed!`, error);
                }
            },
            onCancel() {},
        });
    };

    const Upsert = useCallback(
        () =>
            visible && (
                <SentenceModal
                    postData={postData}
                    setPostData={setPostData}
                    onRefresh={handleFetchSentences}
                    isCreate
                    title="Add New Sentence"
                    visible={visible}
                    setVisible={setVisible}
                />
            ),
        [visible, postData, handleFetchSentences]
    );

    const Update = useCallback(
        () =>
            updateVisible && (
                <SentenceModal
                    postData={postData}
                    setPostData={setPostData}
                    onRefresh={handleFetchSentences}
                    isUpdate
                    title="Update Sentence"
                    visible={updateVisible}
                    setVisible={setUpdateVisible}
                />
            ),
        [updateVisible, postData, handleFetchSentences]
    );

    //fetch sentences using contentId
    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchSentences();
        }
        return () => {
            isSubscribed.current = false;
        };
    }, [handleFetchSentences]);

    useEffect(() => {
        return () => (isSubscribed.current = true);
    }, [contentid]);

    useEffect(() => {
        let lastOrder = getNewLastOrder(sentences);
        setPostData((prev) => ({ ...prev, order: lastOrder, contentId: contentid, chapterId: chapterid }));
    }, [sentences, contentid, visible, chapterid]);

    return (
        <React.Fragment>
            <LodoshaButton size="small" type="primary" className="new-sentence-btn" onClick={() => setVisible(true)}>
                <PlusOutlined /> Add New Sentence
            </LodoshaButton>
            <Upsert />
            <Update />
            <SortableTable
                data={data}
                columns={columns}
                setLoading={setLoading}
                loading={loading}
                onUpdateOrder={updateSentenceOrder}
                scrollX={1440}
                size="small"
            />
        </React.Fragment>
    );
}
