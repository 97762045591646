import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { message, Row, Modal, Space, Button } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import { deleteMembershipType, getMemberships, updateMembershipOrder } from '../../utils/api/membership';
import { getNewLastOrder } from '../../utils';
import { membershipTypeToText } from '../../utils/data';

//component
import { Container, LodoshaButton } from '../../components/UI/ui.style';
import SortableTable from '../E-Learning/E-LearningComponents/SortableTable';
import MembershipModal from './MembershipModal';
import DragHandler from '../E-Learning/E-LearningComponents/DragHandler';

export default function Memberships() {
    const [visible, setVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [memberships, setMemberships] = useState([]);
    const [postData, setPostData] = useState();

    const isSubscribed = useRef(true);

    const handleFetchMemberships = useCallback(async () => {
        try {
            setLoading(true);
            const response = await getMemberships();
            if (response && response.errorCode !== 0) {
                setLoading(false);
                message.error(
                    `Uh oh, couldn't get memberships. errorCode: ${response.errorCode}.errorMsg: ${response.message}`
                );
                return;
            }
            setMemberships(response && response.data ? response.data : []);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            message.error(`fetchMemberships err :${error.message}`);
        }
    }, []);

    const data = useMemo(
        () =>
            memberships &&
            memberships.map((value, index) => ({ ...value, dType: membershipTypeToText[value?.type], key: index })),
        [memberships]
    );

    const columns = [
        {
            title: '#',
            dataIndex: 'no',
            width: '5%',
            align: 'center',
            render: () => <DragHandler />,
        },
        {
            title: 'NAME',
            dataIndex: 'name',
            width: '20%',
        },
        {
            title: 'PRICE',
            dataIndex: 'price',
            width: '16%',
            align: 'center',
        },
        {
            title: 'VIEW COUNT',
            dataIndex: 'view_count',
            width: '16%',
            align: 'center',
        },
        {
            title: 'INTERVIEW COUNT',
            dataIndex: 'interview_count',
            width: '16%',
            align: 'center',
        },
        {
            title: 'TYPE',
            dataIndex: 'dType',
            width: '16%',
            align: 'center',
        },
        {
            title: 'ACTIONS',
            dataIndex: 'actions',
            align: 'center',
            width: '15%',
            render: (text, row, index) => (
                <Space size="small" direction="horizontal">
                    <Button title="Edit" type="primary" icon={<EditOutlined />} onClick={(e) => handleEdit(e, row)} />
                    <Button
                        title="Delete"
                        type="danger"
                        icon={<DeleteOutlined />}
                        onClick={(e) => handleDelete(e, row.id)}
                    />
                </Space>
            ),
        },
    ];

    const Upsert = useCallback(
        () =>
            visible && (
                <MembershipModal
                    visible={visible}
                    setVisible={setVisible}
                    title="Add New Type of Membership"
                    isCreate
                    onRefresh={handleFetchMemberships}
                    postData={postData}
                    setPostData={setPostData}
                />
            ),
        [visible, handleFetchMemberships, postData]
    );

    const Update = useCallback(() => {
        return (
            updateVisible && (
                <MembershipModal
                    visible={updateVisible}
                    setVisible={setUpdateVisible}
                    title="Update Membership"
                    postData={postData}
                    setPostData={setPostData}
                    isUpdate
                    onRefresh={handleFetchMemberships}
                />
            )
        );
    }, [updateVisible, handleFetchMemberships, postData]);

    const handleDelete = (e, id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this membership?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await deleteMembershipType(id);
                    if (response && response.errorCode === 0) {
                        message.success(`Deleted successfully!`);
                        handleFetchMemberships();
                    } else {
                        message.error(`Couldn't delete membership, ${response.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request failed!`);
                }
            },
            onCancel() {},
        });
    };

    const handleEdit = (e, row) => {
        e.stopPropagation();
        setPostData(row);
        setUpdateVisible(true);
    };

    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchMemberships();
        }
        return () => {
            isSubscribed.current = false;
        };
    }, [handleFetchMemberships]);

    useEffect(() => {
        let lastOrder = getNewLastOrder(memberships);
        setPostData((prev) => ({ ...prev, order: lastOrder }));
    }, [memberships, visible]);

    return (
        <Container>
            <Row style={{ marginBottom: 32 }}>
                <LodoshaButton type="primary" icon={<PlusOutlined />} onClick={() => setVisible(true)}>
                    New
                </LodoshaButton>
                <Upsert />
                <Update />
            </Row>

            <SortableTable
                loading={loading}
                setLoading={setLoading}
                data={data}
                columns={columns}
                onUpdateOrder={updateMembershipOrder}
            />
        </Container>
    );
}
