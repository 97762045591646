
import API from '../api';

export const getCandidateCalls = (page) => {
    const endpoint =
    page === 1
        ? `/employee-history/call/all`
        : `/employee-history/call/all?offset=${(page - 1) * 15}`;

    return API().get(endpoint)
            .then(response => {
                return response.data;
            })
            .catch(err => { 
                console.log('err: ', err) 
            });
};

export const searchCandidateCalls = (page, keyword, caller_id, created_at, follow_up_date, type, from_date, to_date) => {
    // const createdAt = created_at ? created_at.format("YYYY-MM-DD") : null;
    // const followUpDate = follow_up_date ? follow_up_date.format("YYYY-MM-DD") : null;
    const createdAt = created_at;
    const followUpDate = follow_up_date;

    const endpoint =
        page === 1
            ? `/employee-history/call/search?keyword=${keyword}&caller_id=${caller_id}&created_at=${createdAt}&follow_up_date=${followUpDate}&type=${type}&from_date=${from_date}&to_date=${to_date}`
            : `/employee-history/call/search?offset=${(Number(page) - 1) * 15}&keyword=${keyword}&caller_id=${caller_id}&created_at=${createdAt}&follow_up_date=${followUpDate}&&type=${type}&from_date=${from_date}&to_date=${to_date}`;

    return API().get(endpoint)
            .then(response => {
                return response.data;
            })
            .catch(err => { 
                console.log('err: ', err) 
            });
};