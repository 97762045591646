import React from "react";
import { Row, Col, Modal, Form, DatePicker, Popconfirm, Table, message, Button } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { LodoshaInput, LodoshaButton } from "../../components/UI/ui.style";
import API from "../../utils/api";

const dateFormat = "DD-MM-YYYY";

const Background = ({ visible, setVisible, employee = {} }) => {
  const [form] = Form.useForm();
  const [backgrounds, setBackgrounds] = React.useState([]);
  const [background, setBackground] = React.useState();

  const handleFetchBackgrounds = React.useCallback(async (id) => {
    try {
      const response = await API().get(`/employee/background?employee_id=${id}`);
      // console.log(response.data);
      response?.data?.data && setBackgrounds(response.data.data);
    } catch (error) {
      message.error(`Uh oh, request background failed!`);
    }
  }, []);

  React.useEffect(() => {
    employee?.id && visible && handleFetchBackgrounds(employee.id);
  }, [employee, handleFetchBackgrounds, visible]);

  const handleEdit = (item) => {
    setBackground(item);
    form.setFieldsValue({
      from: moment(item.from, dateFormat),
      to: moment(item.to, dateFormat),
      school_name: item.school_name,
      workplace_name: item.workplace_name,
    });
  };

  const handleSubmit = async (fieldsValue) => {
    const values = {
      ...fieldsValue,
      from: fieldsValue["from"].format(dateFormat),
      to: fieldsValue["to"].format(dateFormat),
    };
    // console.log(values);
    try {
      if (background && background.id) {
        const response = await API().patch(`/employee/background/${background.id}`, values);
        // console.log("update", response);
        if (response.data.errorCode === 0) {
          handleFetchBackgrounds(employee.id);
          form.resetFields();
          message.success(`Updated successfuly!`);
          setBackground();
        }
      } else {
        const response = await API().post(`/employee/background/create`, { ...values, employee_id: employee.id });
        // console.log("create", response);
        if (response.data.errorCode === 0) {
          setBackgrounds([...backgrounds, response.data.data]);
          form.resetFields();
          message.success(`Created successfuly!`);
        }
      }
    } catch (error) {
      message.error(`Uh oh, request failed!`);
    }
  };

  const handleReset = () => {
    form.resetFields();
    setBackground();
  };

  const handleDeleteBackground = async (id) => {
    form.resetFields();
    try {
      const response = await API().delete(`/employee/background/${id}`);
      if (response && response.data.errorCode === 0) {
        handleFetchBackgrounds(employee.id);
        message.success(`Deleted successfully!`);
      } else {
        message.error(`Failed! ${response.data.message}`);
      }
    } catch (error) {
      message.error(`Uh oh, request failed!`);
    }
  };

  const columns = [
    { title: "From", dataIndex: "from", key: "from" },
    { title: "To", dataIndex: "to", key: "to" },
    { title: "School", dataIndex: "school_name", key: "school_name" },
    { title: "Workplace", dataIndex: "workplace_name", key: "workplace_name" },
    {
      title: "",
      key: "actions",
      render: (item) => (
        <>
          <Button
            type="primary"
            icon={<EditOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => handleEdit(item)}
          ></Button>
          <Popconfirm
            title="Are you sure？"
            okText="Yes"
            okType="danger"
            cancelText="No"
            onConfirm={() => handleDeleteBackground(item.id)}
          >
            <Button type="danger" icon={<DeleteOutlined />} />
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <Modal
      width={768}
      maskClosable={false}
      visible={visible}
      title="Background"
      onCancel={() => setVisible(false)}
      footer={[
        <LodoshaButton style={{ height: 38, width: 108 }} key="back" onClick={() => setVisible(false)}>
          Done
        </LodoshaButton>,
      ]}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name="from" label="From:" rules={[{ required: true, message: "Data required!" }]}>
              <DatePicker style={{ width: "100%" }} size="large" format={dateFormat} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="to" label="To:" rules={[{ required: true, message: "Data required!" }]}>
              <DatePicker style={{ width: "100%" }} size="large" format={dateFormat} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="school_name" label="School:">
              <LodoshaInput size="large" placeholder="Enter your school" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="workplace_name" label="Workplace:">
              <LodoshaInput size="large" placeholder="Enter your workplace" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ textAlign: "center", marginTop: 16 }}>
          <LodoshaButton style={{ marginRight: 8, height: 38, width: 108 }} htmlType="button" onClick={handleReset}>
            Cancel
          </LodoshaButton>
          <LodoshaButton type="primary" htmlType="submit" style={{ height: 38, width: 108 }}>
            {background?.id ? "Update" : "Create"}
          </LodoshaButton>
        </Form.Item>
      </Form>
      <Table
        rowKey={(dataSource) => dataSource.id}
        columns={columns}
        dataSource={backgrounds}
        // scroll={{ x: 900 }}
        pagination={false}
      />
    </Modal>
  );
};

export default Background;
