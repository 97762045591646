import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

//antd
import { message, Space, Button, Modal } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

//components
import SectionTypeModal from './SectionTypeModal';
import { LodoshaButton,Container } from '../../../components/UI/ui.style';

//utils and constants
import { getSectionType, deleteSectionType, updateSectionTypeOrder } from '../../../utils/api/E-Learning/exam';
import { getFromLocalStoage } from '../../../utils';
import SortableTable from '../E-LearningComponents/SortableTable';
import DragHandler from '../E-LearningComponents/DragHandler';
import { ExternalTypes } from '../../../utils/data';

export default function SectionTypeList({ setSelectedSectionTypeId, selectedSectionTypeId }) {
    const [visible, setVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sectionTypes, setSectionTypes] = useState([]);
    const [postData, setPostData] = useState({});

    const { menuid, examId } = useParams();

    const isSubscribed = useRef(true);

    const data = useMemo(
        () =>
            sectionTypes &&
            sectionTypes.map((data, index) => ({
                ...data,
                key: data.id,
                no: index + 1,
                section: data.section,
                type: data.type,
                order: data.order,
            })),
        [sectionTypes]
    );

    const columns = [
        { title: '#', dataIndex: 'no', width: '5%', align: 'center', render: () => <DragHandler /> },
        {
            title: 'SECTION',
            dataIndex: 'sectionToText',
            width: '30%',
            render: (text, row) => (
                <a
                    href="#question_table"
                    style={{ textDecoration: 'underline', width: '500px', textTransform: 'capitalize' }}
                >
                    {row.section}
                </a>
            ),
        },
        { title: 'TYPE', dataIndex: 'type', width: '50%' },
        {
            title: '',
            dataIndex: 'actions',
            align: 'center',
            width: '10%',
            render: (text, row, index) => (
                <Space size="small" direction="horizontal">
                    <Button title="Edit" type="primary" icon={<EditOutlined />} onClick={(e) => handleEdit(e, row)} />
                    <Button
                        title="Delete"
                        type="danger"
                        icon={<DeleteOutlined />}
                        onClick={(e) => handleDelete(e, row.id)}
                    />
                </Space>
            ),
        },
    ];

    const handleFetchSectionTypes = useCallback(async () => {
        setLoading(true);
        const response = await getSectionType();
        console.log(response);
        if (response && response.errorCode !== 0) {
            setLoading(false);
            message.error(`Couldn't get section types, please try again,${response.errorCode}`);
            return;
        }

        setSectionTypes(response?.data);
        setLoading(false);
    }, []);

    const handleEdit = (e, row) => {
        e.stopPropagation();
        setPostData(row);
        setUpdateVisible(true);
    };

    const handleRow = (record, index) => {
        return {
            onClick: (e) => {
                e.stopPropagation();
                setSelectedSectionTypeId(record.id);
            },
        };
    };

    const handleDelete = (e, id) => {
        e.stopPropagation();
        Modal.confirm({
            title: 'Are you sure delete this section type?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await deleteSectionType(id);
                    if (response && response.errorCode === 0) {
                        message.success(`Deleted successfully!`);
                        handleFetchSectionTypes();
                    } else {
                        message.error(`Failed, ${response.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request failed!`);
                }
            },
            onCancel() {},
        });
    };

    const Upsert = useCallback(
        () =>
            visible && (
                <SectionTypeModal
                    postData={postData}
                    setPostData={setPostData}
                    setVisible={setVisible}
                    visible={visible}
                    onRefresh={handleFetchSectionTypes}
                    title="Add New Section and Type"
                    isCreate
                />
            ),
        [visible, postData, handleFetchSectionTypes]
    );

    const Update = useCallback(
        () =>
            updateVisible && (
                <SectionTypeModal
                    postData={postData}
                    setPostData={setPostData}
                    setVisible={setUpdateVisible}
                    visible={updateVisible}
                    isUpdate
                    onRefresh={handleFetchSectionTypes}
                    title="Update Section and Type"
                />
            ),
        [updateVisible, postData, handleFetchSectionTypes]
    );

    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchSectionTypes();
        }
        return () => {
            isSubscribed.current = false;
        };
    }, [handleFetchSectionTypes]);

    useEffect(() => {
        const getLastOrder = () => {
            const selectedLevel = getFromLocalStoage('selected_level');

            //multiply lastOrder by 100 (for order arrangement)
            let lastOrder = (sectionTypes && sectionTypes.length > 0 ? sectionTypes.length + 1 : 1) * 100;

            setPostData((prev) => ({
                ...prev,
                order: lastOrder,
                external_id: examId,
                level: selectedLevel,
                external_type: ExternalTypes.EXAM_QUESTION,
            }));
        };
        getLastOrder();
    }, [sectionTypes, visible, menuid, examId]);

    return (
        <Container id="section-type-table">
            <Space className="new-sentence-btn">
                <LodoshaButton type="primary" onClick={() => setVisible(true)}>
                    <PlusOutlined />
                    Add New
                </LodoshaButton>
                <a href="#question_table">
                    <LodoshaButton disabled={!selectedSectionTypeId && true} type="dashed">
                        Jump to Questions
                    </LodoshaButton>
                </a>
            </Space>
            <SortableTable
                loading={loading}
                setLoading={setLoading}
                columns={columns}
                data={data}
                isSectionType
                handleRow={handleRow}
                onUpdateOrder={updateSectionTypeOrder}
            />
            <Upsert />
            <Update />
        </Container>
    );
}
