import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from 'react';
import queryString from 'query-string';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { Table, Tooltip } from 'antd';

import AppContext from '../../contexts/AppContext';

//components
import { Container } from '../../components/UI/ui.style';
import { searchCandidateCalls } from '../../utils/api/candidate_history';

import Search from './Search';

import * as ROUTES from '../../constants/routes';
import { callActionTypesToText } from '../../utils/data';

const timeout_typing_state = { typingTimeout: 0 };

const CandidateCall = () => {
    const { dataStaffs = [] } = useContext(AppContext);
    let history = useHistory();
    let location = useLocation();

    const page = location.search ? Number(queryString.parse(location.search).page) : 1;
    const qKeyword = location.search ? queryString.parse(location.search).keyword : '';
    const qCallerId = location.search ? queryString.parse(location.search).callerId : '';
    const qCreatedAt = location.search ? queryString.parse(location.search).createdAt : '';
    const qFollowUpDate = location.search ? queryString.parse(location.search).followUpDate : '';
    const qFromDate = location.search ? queryString.parse(location.search).fromDate : '';
    const qToDate = location.search ? queryString.parse(location.search).toDate : '';
    const qType = location.search ? queryString.parse(location.search).type : null;

    const dateTimeFormat = 'DD-MM-YYYY h:mm A';
    const dateFormat = 'DD-MM-YYYY';

    const isSubscribed = useRef(true);

    const [loading, setLoading] = useState(false);
    const [callHistories, setCallHistories] = useState([]);
    const [totalcallHistories, setTotalCallHistories] = useState(0);

    // this will show if user select Caller and total call > 0
    const [selectedCaller, setSelectedCaller] = useState({});

    const [timeoutTyping, setTimeoutTyping] = useState(timeout_typing_state);
    const [keyword, setKeyword] = useState(qKeyword);
    const [callerId, setCallerId] = useState(qCallerId);
    const [createdAt, setCreatedAt] = useState(qCreatedAt);
    const [followUpDate, setFollowUpDate] = useState(qFollowUpDate);
    const [type, setType] = useState(qType);
    const [toDate, setToDate] = useState(qToDate);
    const [fromDate, setFromDate] = useState(qFromDate);

    const handleFetchSearch = useCallback(
        async (page, keyWord, callerId, createdAt, followUpDate, type, fromDate, toDate) => {
            setLoading(true);
            const response = await searchCandidateCalls(
                page,
                keyWord,
                callerId,
                createdAt,
                followUpDate,
                type,
                fromDate,
                toDate
            );
            response.data && setCallHistories(response.data);
            if (callerId) {
                const caller =
                    dataStaffs && dataStaffs.length > 0 && dataStaffs.find((staff) => staff.value === callerId);
                caller && setSelectedCaller(caller);
            }

            setTotalCallHistories(response.total);
            setLoading(false);
        },
        [dataStaffs]
    );

    const handleChangePagination = (page) => {
        // history.replace(`${ROUTES.CANDIDATE_CALL}${page !== 1 ? `?page=${page}` : ''}`);
        let endPoint = `${ROUTES.CANDIDATE_CALL}?page=${page}`;

        if (keyword) {
            endPoint = `${endPoint}&keyword=${keyword}`;
        }

        if (callerId) {
            endPoint = `${endPoint}&callerId=${callerId}`;
        }

        if (createdAt) {
            endPoint = `${endPoint}&createdAt=${createdAt}`;
        }

        if (followUpDate) {
            endPoint = `${endPoint}&followUpDate=${followUpDate}`;
        }

        if (type) {
            endPoint = `${endPoint}&type=${type}`;
        }

        if (fromDate) {
            endPoint = `${endPoint}&fromDate=${fromDate}`;
        }

        if (toDate) {
            endPoint = `${endPoint}&toDate=${toDate}`;
        }

        history.replace(endPoint);
    };

    const dataSource = useMemo(
        () =>
            callHistories &&
            callHistories.map((callHistory, index) => ({
                ...callHistory,
                key: callHistory.id,
                no: index + 1 + (page - 1) * 15,
                candidate_name: callHistory.employee.name,
                candidate_phone: callHistory.employee.phone,
                candidate_age: callHistory.employee.age,
                candidate_job: callHistory.employee.want_job,
                candidate_to_japan: callHistory.employee.ever_to_japan
                    ? callHistory.employee.ever_to_japan === 1
                        ? 'ធ្លាប់'
                        : 'មិនធ្លាប់'
                    : 'មិនធ្លាប់',
                created_at: moment(callHistory.created_at).format(dateTimeFormat),
                follow_up_date: callHistory.follow_up_date
                    ? moment(callHistory.follow_up_date).format(dateFormat)
                    : '-',
            })),
        [callHistories, page]
    );

    const columns = [
        { title: '#', dataIndex: 'no', key: 'no', width: '4%', fixed: 'left' },
        {
            title: 'CANDIDATE',
            key: 'candidate-name',
            width: '11%',
            render: (item) => (
                <Tooltip
                    placement="bottomRight"
                    title={
                        <div>
                            <span>- អាយុ: {item.candidate_age}</span>
                            <br />
                            <span>- ការងារចង់ធ្វើ: {item.candidate_job}</span>
                        </div>
                    }
                >
                    {' '}
                    <u>{item['candidate_name']}</u>
                </Tooltip>
            ),
        },
        { title: 'PHONE', dataIndex: 'candidate_phone', key: 'candidate-phone', width: '10%' },
        {
            title: 'RESULT',
            dataIndex: 'type',
            key: 'type',
            width: '10%',
            render: (text, item, index) => {
                return <div>{item.type ? callActionTypesToText[item.type - 1] : item.type}</div>;
            },
        },
        { title: 'TO JAPAN', dataIndex: 'candidate_to_japan', key: 'candidate-to-japan', width: '10%' },
        { title: 'SUMMARY', dataIndex: 'summary', key: 'summary', width: '23%' },
        { title: 'CALLER', dataIndex: 'caller_name', key: 'caller-name', width: '11%' },
        { title: 'CALL DATE TIME', dataIndex: 'created_at', key: 'created-at', width: '14%' },
        { title: 'FOLLOW UP ON', dataIndex: 'follow_up_date', key: 'follow-up-date', width: '12%' },
    ];

    const handleSearch = (value) => {
        // console.log(value);
        setKeyword(value);

        if (timeoutTyping.typingTimeout) {
            clearTimeout(timeoutTyping.typingTimeout);
        }

        setTimeoutTyping({
            typingTimeout: setTimeout(() => {
                handleFetchSearch(page, value, callerId, createdAt, followUpDate, type, fromDate, toDate);
            }, 1000),
        });
    };

    const handleFilter = (name, value) => {
        if (name === 'caller') {
            setCallerId(value);
            handleFetchSearch(keyword, value, createdAt, followUpDate, type, fromDate, toDate);
        } else if (name === 'created_at') {
            const createdAt = value ? value.format('YYYY-MM-DD') : null;
            setCreatedAt(createdAt);
            handleFetchSearch(keyword, callerId, createdAt, followUpDate, type, fromDate, toDate);
        } else if (name === 'follow_up_date') {
            const followUpDate = value ? value.format('YYYY-MM-DD') : null;
            handleFetchSearch(keyword, callerId, createdAt, followUpDate, type, fromDate, toDate);
            setFollowUpDate(followUpDate);
        } else if (name === 'type') {
            setType(value);
            handleFetchSearch(keyword, callerId, createdAt, followUpDate, value, fromDate, toDate);
        } else if (name === 'from_date') {
            const fromDate = value ? value.format('YYYY-MM-DD') : null;
            setFromDate(fromDate);
            handleFetchSearch(keyword, callerId, createdAt, followUpDate, type, fromDate, toDate);
        } else if (name === 'to_date') {
            const toDate = value ? value.format('YYYY-MM-DD') : null;
            setToDate(toDate);
            handleFetchSearch(keyword, callerId, createdAt, followUpDate, type, fromDate, toDate);
        }

        initQueryURL(name, value);
    };

    const initQueryURL = useCallback(
        (name, value, page = 1) => {
            let currentQuery = {};
            currentQuery['page'] = page;

            if (keyword) {
                currentQuery['keyword'] = keyword;
            }

            if (callerId) {
                currentQuery['callerId'] = callerId;
            }

            if (createdAt) {
                currentQuery['createdAt'] = createdAt;
            }

            if (followUpDate) {
                currentQuery['followUpDate'] = followUpDate;
            }

            if (type) {
                currentQuery['type'] = type;
            }

            if (fromDate) {
                currentQuery['fromDate'] = fromDate;
            }

            if (toDate) {
                currentQuery['toDate'] = toDate;
            }

            if (value) {
                currentQuery[name] = value;
            } else {
                delete currentQuery[name];
            }

            let endPoint = history.location;
            endPoint.search = queryString.stringify(currentQuery);
            history.replace(endPoint);
        },
        [callerId, history, createdAt, followUpDate, keyword, type, fromDate, toDate]
    );

    const handleRow = (record, rowIndex) => {
        return {
            onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                history.push(`${ROUTES.CANDIDATES_DETAIL}/${record.employee.id}`, record);
            }, // click row
        };
    };

    useEffect(() => {
        initQueryURL('', '', page);
        handleFetchSearch(page, qKeyword, qCallerId, qCreatedAt, qFollowUpDate, type, fromDate, toDate);
        // handleFetchCallHistories(page);
        return () => {
            isSubscribed.current = false;
        };
    }, [page, qKeyword, qCallerId, qCreatedAt, qFollowUpDate, type, fromDate, toDate, handleFetchSearch, initQueryURL]);

    return (
        <Container>
            <Search
                keyword={keyword}
                callerId={callerId}
                createdAt={createdAt}
                followUpDate={followUpDate}
                type={type}
                fromDate={fromDate}
                toDate={toDate}
                onSearch={handleSearch}
                onFilter={handleFilter}
            />

            {callerId && selectedCaller && (
                <div style={{ marginBottom: '1.5rem' }}>
                    Selected Caller : <span style={{fontWeight:"bold"}}>{`${selectedCaller?.text}`}</span> <br/>
                    Total Call: <span style={{fontWeight:"bold"}}>{totalcallHistories}</span>
                </div>
            )}

            <Table
                bordered
                loading={loading}
                dataSource={dataSource}
                columns={columns}
                onRow={handleRow}
                scroll={{ x: 1300 }}
                pagination={{
                    onChange: handleChangePagination,
                    current: page,
                    defaultCurrent: 1,
                    defaultPageSize: 15,
                    total: totalcallHistories,
                }}
            />
        </Container>
    );
};

export default CandidateCall;
