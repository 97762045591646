import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Container, LodoshaButton } from '../../../components/UI/ui.style';
import { useHistory, useLocation, Switch, Route } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';

//api
import {
    deleteNotification,
    getNewsNotifcations,
    updateNotificationOrder,
} from '../../../utils/api/E-Learning/news_management';

//antd
import { Button, message, Space, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import AppContext from '../../../contexts/AppContext';

//components
import NewsManagementModal from './NewsManagementModal';
import DragHandler from '../E-LearningComponents/DragHandler';
import SortableTable from '../E-LearningComponents/SortableTable';
import { ELEARNING_NEWS_MANAGEMENT_USERS } from '../../../constants/routes';
import Breadcrumb from '../E-LearningComponents/Breadcrumb';
import FilteredUsers from './FilteredUsers';
import { getNewLastOrder } from '../../../utils';
import { notificationTypeToText } from '../../../utils/data';

export default function NewsManagementPage() {
    const { handleUnauthorizedUser } = useContext(AppContext);

    const [newsNotifications, setNewsNotifications] = useState([]);
    const [loading, setLoading] = useState(false);
    const [postData, setPostData] = useState({});
    const [visible, setVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);

    const isSubscribed = useRef(true);

    const history = useHistory();
    const { pathname } = useLocation();

    //extract menuid from url
    const menuid = pathname?.split('/')[2];

    //main menu table will hide when notification text is selected
    const shouldMainMenuShow = pathname.split('/')[3] ? false : true;

    const handleFetchNewsNotifications = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getNewsNotifcations();
            if (response && response.errorCode !== 0) {
                if (response.data.status === 401) {
                    message.error(`Unauthorized`);
                    handleUnauthorizedUser();
                    return;
                }
                message.error(`Uh oh, fetch news notifications failed,please try again.${response.message}`);
                setLoading(false);
                return;
            }
            setNewsNotifications(response?.data);
            setLoading(false);
        } catch (error) {
            message.error(`Uh oh, couldn't get news notifications,${error.message}`);
            setLoading(false);
        }
    }, [handleUnauthorizedUser]);

    const columns = [
        {
            title: '#',
            dataIndex: '',
            render: () => <DragHandler />,
            width: '5%',
            align: 'center',
        },
        {
            title: 'TITLE',
            dataIndex: 'title',
            width: '20%',
        },
        {
            title: 'BODY',
            dataIndex: 'body',
        },
        {
            title: 'TYPE',
            dataIndex: 'typeToText',
            width: '13%',
            align: 'center',
        },
        {
            width: '12%',
            title: 'TOTAL SENT',
            align: 'center',
            dataIndex: 'total_sent',
        },
        {
            title: 'ACTIONS',
            width: '10%',
            align: 'center',
            fixed: 'right',
            render: (text, row, index) => (
                <Space size="small" direction="horizontal">
                    <Button title="Edit" type="primary" icon={<EditOutlined />} onClick={(e) => handleEdit(e, row)} />
                    <Button
                        title="Delete"
                        type="danger"
                        icon={<DeleteOutlined />}
                        onClick={(e) => handleDelete(e, row.id)}
                    />
                </Space>
            ),
        },
    ];

    const data = useMemo(
        () =>
            newsNotifications &&
            newsNotifications.map((n, index) => ({
                ...n,
                key: n.id,
                title: n.title,
                total_sent: n.total_notification_sent,
                failed: n.failed,
                success: n.success,
                body: n.body,
                typeToText: notificationTypeToText[n.type],
            })),
        [newsNotifications]
    );

    const Upsert = useCallback(
        () =>
            visible && (
                <NewsManagementModal
                    postData={postData}
                    setPostData={setPostData}
                    visible={visible}
                    setVisible={setVisible}
                    title="Add New News Notification"
                    onRefresh={handleFetchNewsNotifications}
                    isCreate
                />
            ),
        [handleFetchNewsNotifications, postData, visible]
    );

    const Update = useCallback(
        () =>
            updateVisible && (
                <NewsManagementModal
                    postData={postData}
                    setPostData={setPostData}
                    visible={updateVisible}
                    setVisible={setUpdateVisible}
                    title="Update News Notification"
                    onRefresh={handleFetchNewsNotifications}
                    isUpdate
                />
            ),
        [handleFetchNewsNotifications, postData, updateVisible]
    );

    const handleRow = (record, index) => {
        return {
            onClick: (e) => {
                e.stopPropagation();
                localStorage.setItem(
                    'notification-info',
                    JSON.stringify({
                        title: record.title,
                        body: record.body,
                        type: record.type,
                        failed: record.failed,
                        success: record.success,
                        id: record.id,
                        attachment: record.attachment,
                        is_sent: record.is_sent,
                        image: record?.image ? record.image : '',
                    })
                );
                history.push(`${ELEARNING_NEWS_MANAGEMENT_USERS}`);
            },
        };
    };

    const handleEdit = (e, row) => {
        e.stopPropagation();
        setPostData(row);
        setUpdateVisible(true);
    };

    const handleDelete = (e, id) => {
        e.stopPropagation();
        Modal.confirm({
            title: 'Are you sure you want to delete this question?',
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const response = await deleteNotification(id);
                    if (response && response.errorCode === 0) {
                        message.success(`Successfully Deleted Notification!`);
                        handleFetchNewsNotifications();
                    } else {
                        message.error(`Couldn't delete news notification, ${response.message}`);
                    }
                } catch (error) {
                    message.error(`Uh oh, request failed!`);
                }
            },
            onCancel() {},
        });
    };

    useEffect(() => {
        if (isSubscribed.current) {
            handleFetchNewsNotifications();
        }
        return () => (isSubscribed.current = false);
    }, [handleFetchNewsNotifications]);

    useEffect(() => {
        let lastOrder = getNewLastOrder(newsNotifications);
        setPostData((prev) => ({ ...prev, order: lastOrder }));
    }, [newsNotifications, visible]);

    return (
        <div>
            <Breadcrumb menuid={menuid} />
            {shouldMainMenuShow && (
                <Container>
                    <LodoshaButton type="primary" className="btn-margin-bottom" onClick={() => setVisible(true)}>
                        <PlusOutlined />
                        Add Notification
                    </LodoshaButton>
                    <Upsert />
                    <Update />
                    <SortableTable
                        loading={loading}
                        onUpdateOrder={updateNotificationOrder}
                        setLoading={setLoading}
                        data={data}
                        columns={columns}
                        handleRow={handleRow}
                        scrollX={1280}
                    />
                </Container>
            )}
            <Switch>
                <Route path={ROUTES.ELEARNING_NEWS_MANAGEMENT_USERS} exact>
                    <FilteredUsers />
                </Route>
            </Switch>
        </div>
    );
}
