import React, { useState, useMemo } from 'react';

//api
import { postNewJapaneseContent, updateJapaneseContent } from '../../../utils/api/E-Learning/japanese';

//ant design
import { Modal, Row, Col, Form, message, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

//components
import { LodoshaInput, LodoshaButton } from '../../../components/UI/ui.style';

import ModalWrapper from '../E-LearningComponents/modal.style';
import MarkdownGenerator from '../../../components/MarkdownGenerator';

export default function ContentModal({
    title,
    visible,
    setVisible,
    onRefresh,
    setPostData,
    postData = {},
    isUpdate,
    isCreate,
}) {
    const [form] = Form.useForm();
    const { Dragger } = Upload;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(postData);
    const [showDragger, setShowDragger] = useState({ wordImage: false, characterImage: false, writeGif: false });

    const japaneseContentFormFields = useMemo(
        () => [
            {
                type: 'text',
                label: 'Character',
                name: 'character',
                value: data?.character,
                span: 8,
                required: true,
            },
            {
                type: 'text',
                label: 'Word JP',
                name: 'word_jp',
                value: data?.word_jp,
                span: 8,
                required: true,
            },
            {
                type: 'text',
                label: 'Word KH',
                name: 'word_kh',
                value: data?.word_kh,
                span: 8,
                required: true,
            },
            {
                type: 'text',
                label: 'Read (Kanji only)',
                name: 'how_to_read',
                value: data?.how_to_read,
                span: 12,
            },
            {
                type: 'text',
                label: 'Hiragana (Kanji only)',
                name: 'hiragana',
                value: data?.hiragana,
                span: 12,
            },
            {
                type: 'text',
                label: 'Write Youtube',
                name: 'write_youtube',
                value: data?.write_youtube,
                span: 24,
                required: false,
            },
        ],
        [data]
    );

    //one useState contains one file
    const [characterImage, setCharacterImage] = useState([]);
    const [characterSound, setCharacterSound] = useState([]);
    const [wordImage, setWordImage] = useState([]);
    const [wordSound, setWordSound] = useState([]);
    const [writeGif, setWriteGift] = useState([]);

    //close modal
    const handleCancel = (e) => {
        setVisible(false);
        setPostData({});
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        setData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const beforeUpload = () => {
        return false;
    };

    const handleChangeFile = (info, fieldname) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        fileList = fileList.map((file) => {
            if (file.response) {
                file.url = file.response.url;
            }
            return file;
        });
        // important when uploading to server
        if (fileList.length > 0) {
            fileList[0].originFileObj.fieldname = fieldname;
        }

        switch (fieldname) {
            case 'character-image': {
                setCharacterImage(fileList);
                break;
            }
            case 'character-voice': {
                setCharacterSound(fileList);
                break;
            }
            case 'word-image': {
                setWordImage(fileList);
                break;
            }
            case 'word-voice': {
                setWordSound(fileList);
                break;
            }
            case 'video-how-to-write': {
                setWriteGift(fileList);
                break;
            }
            default:
        }
    };

    const handleOnMouseLeave = (key) => {
        setShowDragger((prev) => ({
            ...prev,
            [key]: false,
        }));
    };

    const handleOnMouseEnter = (key) => {
        setShowDragger((prev) => ({
            ...prev,
            [key]: true,
        }));
    };

    const handleSubmit = async () => {
        let tempFiles = [];
        //combine all files into array and send to api
        tempFiles = tempFiles
            .concat(characterImage)
            .concat(characterSound)
            .concat(wordImage)
            .concat(wordSound)
            .concat(writeGif);

        try {
            setLoading(true);
            const response = isCreate
                ? await postNewJapaneseContent(data, tempFiles)
                : isUpdate
                ? await updateJapaneseContent(data, tempFiles)
                : {};

            if (response && response.errorCode !== 0) {
                setLoading(false);
                message.error(`Oh oh, something went wrong, ${response.message}`);
                return;
            }

            if (response) {
                response &&
                    message.success(isCreate ? 'New content added.' : isUpdate ? 'Successfully updated content' : '');
                setLoading(false);
                onRefresh();
                handleCancel();
            } else {
                setLoading(false);
                message.error(`Uh oh, ${isCreate ? 'add new content' : isUpdate ? 'updating content' : ''} failed!`);
            }
        } catch (error) {
            setLoading(false);
            message.error(`Uh oh, ${isCreate ? 'add new content' : isUpdate ? 'updating content' : ''} failed!`);
        }
    };

    return (
        <Modal
            width={768}
            title={title}
            visible={visible}
            onCancel={handleCancel}
            maskClosable={false}
            footer={null}
            centered
        >
            <ModalWrapper>
                <Form layout="vertical" form={form} onFinish={() => handleSubmit()}>
                    <Row gutter={24}>
                        {japaneseContentFormFields?.map((field, index) => {
                            const { type, label, name, value, required, disabled, span } = field;
                            return (
                                <Col span={span} key={index}>
                                    <Form.Item
                                        rules={[{ required: required, message: 'Data required!' }]}
                                        label={`${label}:`}
                                        name={name}
                                        initialValue={value}
                                    >
                                        <LodoshaInput
                                            type={type}
                                            disabled={disabled && true}
                                            onChange={handleChange}
                                            value={value}
                                            name={name}
                                        />
                                    </Form.Item>
                                </Col>
                            );
                        })}

                        <Col span={8}>
                            {/* character image  */}
                            <Form.Item
                                label={<h4 style={{ fontWeight: 'bold', marginBottom: '0' }}>Character Image</h4>}
                            >
                                {data.character_image ? (
                                    <div
                                        className="image-preview-container"
                                        onMouseLeave={() => handleOnMouseLeave('characterImage')}
                                        onMouseEnter={() => handleOnMouseEnter('characterImage')}
                                    >
                                        <img src={data.character_image} className="content-image" alt="preview" />
                                        <div
                                            className={`${
                                                (showDragger.characterImage || characterImage.length > 0) &&
                                                `show-upload`
                                            } image-upload`}
                                        >
                                            <Dragger
                                                name="character-image"
                                                beforeUpload={beforeUpload}
                                                fileList={characterImage}
                                                onChange={(info) => handleChangeFile(info, 'character-image')}
                                                accept="image/*"
                                            >
                                                <p className="ant-upload-drag-icon">
                                                    <InboxOutlined />
                                                </p>
                                                <p className="ant-upload-text">
                                                    Click or drag file to this area to upload
                                                </p>
                                                <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                            </Dragger>
                                        </div>
                                    </div>
                                ) : (
                                    <Dragger
                                        name="character-image"
                                        beforeUpload={beforeUpload}
                                        fileList={characterImage}
                                        onChange={(info) => handleChangeFile(info, 'character-image')}
                                        accept="image/*"
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                    </Dragger>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            {/* word image  */}
                            <Form.Item label={<h4 style={{ fontWeight: 'bold', marginBottom: '0' }}>Word Image</h4>}>
                                {data?.word_image ? (
                                    <div
                                        className="image-preview-container"
                                        onMouseLeave={() => handleOnMouseLeave('wordImage')}
                                        onMouseEnter={() => handleOnMouseEnter('wordImage')}
                                    >
                                        <img src={data.word_image} className="content-image" alt="preview" />
                                        <div
                                            className={`${
                                                (showDragger.wordImage || wordImage.length > 0) && `show-upload`
                                            } image-upload`}
                                        >
                                            <Dragger
                                                name="word-image"
                                                beforeUpload={beforeUpload}
                                                fileList={wordImage}
                                                onChange={(info) => handleChangeFile(info, 'word-image')}
                                                accept="image/*"
                                            >
                                                <p className="ant-upload-drag-icon">
                                                    <InboxOutlined />
                                                </p>
                                                <p className="ant-upload-text">
                                                    Click or drag file to this area to upload
                                                </p>
                                                <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                            </Dragger>
                                        </div>
                                    </div>
                                ) : (
                                    <Dragger
                                        name="word-image"
                                        beforeUpload={beforeUpload}
                                        fileList={wordImage}
                                        onChange={(info) => handleChangeFile(info, 'word-image')}
                                        accept="image/*"
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                    </Dragger>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            {/* write gif */}
                            <Form.Item label={<h4 style={{ fontWeight: 'bold', marginBottom: '0' }}>Write Gif</h4>}>
                                {data?.write_gif ? (
                                    <div
                                        className="image-preview-container"
                                        onMouseLeave={() => handleOnMouseLeave('writeGif')}
                                        onMouseEnter={() => handleOnMouseEnter('writeGif')}
                                    >
                                        <img src={data?.write_gif} className="content-image" alt="preview" />
                                        <div
                                            className={`${
                                                (showDragger.writeGif || writeGif.length > 0) && `show-upload`
                                            } image-upload`}
                                        >
                                            <Dragger
                                                name="video-how-to-write"
                                                beforeUpload={beforeUpload}
                                                fileList={writeGif}
                                                onChange={(info) => handleChangeFile(info, 'video-how-to-write')}
                                                accept="image/gif "
                                            >
                                                <p className="ant-upload-drag-icon">
                                                    <InboxOutlined />
                                                </p>
                                                <p className="ant-upload-text">
                                                    Click or drag file to this area to upload
                                                </p>
                                                <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                            </Dragger>
                                        </div>
                                    </div>
                                ) : (
                                    <Dragger
                                        name="video-how-to-write"
                                        beforeUpload={beforeUpload}
                                        fileList={writeGif}
                                        onChange={(info) => handleChangeFile(info, 'video-how-to-write')}
                                        accept="image/gif "
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                    </Dragger>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={<h4 style={{ fontWeight: 'bold', marginBottom: '0' }}>Character Sound</h4>}
                            >
                                <Dragger
                                    name="character-voice"
                                    beforeUpload={beforeUpload}
                                    fileList={characterSound}
                                    onChange={(info) => handleChangeFile(info, 'character-voice')}
                                    accept="audio/mp3"
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={<h4 style={{ fontWeight: 'bold', marginBottom: '0' }}>Word Sound</h4>}>
                                <Dragger
                                    name="word-voice"
                                    beforeUpload={beforeUpload}
                                    fileList={wordSound}
                                    onChange={(info) => handleChangeFile(info, 'word-voice')}
                                    accept="audio/mp3 "
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                </Dragger>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <MarkdownGenerator data={data} setData={setData} isUpdate={isUpdate} />
                        </Col>

                        {/* *********** */}
                        <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
                            <LodoshaButton
                                key="cancel"
                                style={{ marginRight: 16 }}
                                htmlType="button"
                                onClick={handleCancel}
                            >
                                Cancel
                            </LodoshaButton>
                            <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                                {isCreate ? 'Add New' : isUpdate ? 'Update' : ''}
                            </LodoshaButton>
                        </Col>
                    </Row>
                </Form>
            </ModalWrapper>
        </Modal>
    );
}
