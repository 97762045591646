import React from 'react';
import { Modal, Form, Space, message } from 'antd';
import { LodoshaInput, LodoshaButton } from '../../components/UI/ui.style';
import API from '../../utils/api';

const ModalUpsert = ({ visible, setVisible, job, onFetchJobs }) => {
    const [form] = Form.useForm();

    const handleCancel = () => {
        setVisible(false);
    };

    const handleSubmit = async (values) => {
        const response =
            job && job.id ? await API().patch(`/job/${job.id}`, values) : await API().post(`/job/create`, values);

        if (response.data?.errorCode === 0) {
            message.success(`${job?.id ? 'Updated' : 'Created'} successfully!`);
            setVisible(false);
            onFetchJobs();
        } else {
            message.error(response.data.message);
        }
    };

    const items = [
        { label: 'Name', name: 'name', value: job?.name, required: true },
        { label: 'Japan Name', name: 'name_jp', value: job?.name_jp, required: true },
    ];

    return (
        <Modal
            visible={visible}
            maskClosable={false}
            title={job?.id ? 'Update' : 'Create'}
            onCancel={handleCancel}
            width={576}
            footer={null}
        >
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                {items.map((item) => (
                    <Form.Item
                        key={item.name}
                        name={item.name}
                        label={item.label + ':'}
                        initialValue={item.value}
                        rules={[{ required: item.required, message: 'Data required!' }]}
                    >
                        <LodoshaInput />
                    </Form.Item>
                ))}
                <Form.Item style={{ textAlign: 'center' }}>
                    <Space direction="horizontal" size="middle">
                        <LodoshaButton htmlType="button" onClick={handleCancel}>
                            Cancel
                        </LodoshaButton>
                        <LodoshaButton type="primary" htmlType="submit">
                            {job?.id ? 'Update' : 'Create'}
                        </LodoshaButton>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalUpsert;
