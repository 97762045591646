import React from 'react';
import { ThemeProvider } from 'emotion-theming';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import * as ROUTES from './constants/routes';
import PrivateRoute from './components/PrivateRoute';
import LogIn from './containers/Account/Login';
import Dashboard from './containers/Dashboard';

import FavoritesRequest from './containers/FavoritesRequest';
import FavoriteRequest from './containers/FavoritesRequest/Favorite';

import Employees from './containers/Employees';
import Employee from './containers/Employees/Employee';
import Employers from './containers/Employers';
import Employer from './containers/Employers/Employer';
import EmployerEmployees from './containers/Employers/EmployerEmployees';

import PostJobs from './containers/PostJobs';
import PostJob from './containers/PostJobs/PostJob';
import PostJobEmployees from './containers/PostJobs/PostJobEmployees';

import Partners from './containers/Partners';
import Partner from './containers/Partners/Partner';
import PartnerProducts from './containers/Partners/PartnerProducts';
import PartnerEmployees from './containers/Partners/PartnerEmployees';

import HospitalsPage from './containers/Hospitals/index';
import HospitalEmployees from './containers/Hospitals/HospitalEmployees';
import HospitalFees from './containers/Hospitals/Hospital/HospitalFees';
import HospitalPage from './containers/Hospitals/Hospital';

import Jobs from './containers/Jobs';
import Career from './containers/Career';
import Staffs from './containers/Staffs';
import Memberships from './containers/Membership';
import Blogs from './containers/Blogs';
import Users from './containers/User';
// import ChatCandidates from "./containers/ChatAboutCandidates";
// import ChatInGeneral from "./containers/ChatInGeneral";

//E-learning Feature
import ELearningPage from './containers/E-Learning';
import JapaneseClass from './containers/E-Learning/JapaneseClass/Index';
import Conversation from './containers/E-Learning/Conversation/Index';
import Skills from './containers/E-Learning/14Skills';
import Culture from './containers/E-Learning/Culture/Index';
import Exam from './containers/E-Learning/Exam/index';
import JLPTPage from './containers/E-Learning/JLPT/Index';
import NewsManagementPage from './containers/E-Learning/NewsManagement/Index';
import FuriganaGeneratorPage from './containers/E-Learning/FuriganaGenerator';

import Account from './containers/Account/ChangePassword';
import ManageAutoDescription from './containers/ManageAutoDescription/index';
import AdvanceSearchPage from './containers/AdvanceSearch';
import CandidateCall from './containers/CandidateCall';
import NotFound from './components/NotFound';
import SuggestedCandidates from './containers/PostJobs/SuggestedCandidates';
import MarkdownSampleCreator from './containers/E-Learning/MarkdownSampleCreator';
import PartnerFees from './containers/Partners/PartnerFees';

const theme = {
    primary: '#152688',
    secondary: '',
    danger: '#ff4d4f',
    grey: 'rgba(0, 0, 0, 0.45)',
    lightgrey: '#d3d3d3',
};

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Switch>
                    <Route exact path={ROUTES.SIGN_IN}>
                        <LogIn />
                    </Route>
                    <PrivateRoute exact path={ROUTES.HOME}>
                        <Dashboard />
                    </PrivateRoute>
                    <PrivateRoute exact path={ROUTES.FAVORITES}>
                        <FavoritesRequest />
                    </PrivateRoute>
                    <PrivateRoute exact path={`${ROUTES.FAVORITES_DETAIL}/:id`}>
                        <FavoriteRequest />
                    </PrivateRoute>
                    <PrivateRoute path={ROUTES.CANDIDATES_DETAIL}>
                        <Employee />
                    </PrivateRoute>
                    <PrivateRoute exact path={ROUTES.CANDIDATES}>
                        <Employees />
                    </PrivateRoute>
                    <PrivateRoute exact path={`${ROUTES.COMPANY_CANDIDATES}/:id`}>
                        <EmployerEmployees />
                    </PrivateRoute>
                    <PrivateRoute exact path={`${ROUTES.COMPANIES_DETAIL}/:id`}>
                        <Employer />
                    </PrivateRoute>
                    <PrivateRoute exact path={ROUTES.COMPANIES}>
                        <Employers />
                    </PrivateRoute>

                    <PrivateRoute path={ROUTES.POSTJOB_SUGGESTED_CANDIDATES}>
                        <SuggestedCandidates />
                    </PrivateRoute>
                    <PrivateRoute path={ROUTES.POSTJOB_CANDIDATES}>
                        <PostJobEmployees />
                    </PrivateRoute>
                    <PrivateRoute exact path={`${ROUTES.POSTJOBS_DETAIL}/:id`}>
                        <PostJob />
                    </PrivateRoute>
                    <PrivateRoute exact path={ROUTES.POSTJOBS}>
                        <PostJobs />
                    </PrivateRoute>

                    <PrivateRoute exact path={`${ROUTES.PARTNER_PRODUCTS}/:id`}>
                        <PartnerProducts />
                    </PrivateRoute>
                    <PrivateRoute exact path={`${ROUTES.PARTNER_CANDIDATES}/:id`}>
                        <PartnerEmployees />
                    </PrivateRoute>
                    <PrivateRoute exact path={ROUTES.PARTNERS}>
                        <Partners />
                    </PrivateRoute>
                    <PrivateRoute exact path={`${ROUTES.PARTNER_FEES}/:id`}>
                        <PartnerFees />
                    </PrivateRoute>
                    <PrivateRoute path={`${ROUTES.PARTNERS_DETAIL}`}>
                        <Partner />
                    </PrivateRoute>

                    <PrivateRoute exact path={ROUTES.HOSPITALS}>
                        <HospitalsPage />
                    </PrivateRoute>
                    <PrivateRoute exact path={`${ROUTES.HOSPITAL_EMPLOYEES}/:id`}>
                        <HospitalEmployees />
                    </PrivateRoute>
                    <PrivateRoute exact path={`${ROUTES.HOSPITAL_FEES}/:id`}>
                        <HospitalFees />
                    </PrivateRoute>
                    <PrivateRoute path={`${ROUTES.HOSPITAL_DETAIL}`}>
                        <HospitalPage />
                    </PrivateRoute>

                    <PrivateRoute path={ROUTES.ADVANCE_SEARCH}>
                        <AdvanceSearchPage />
                    </PrivateRoute>

                    <PrivateRoute exact path={ROUTES.CANDIDATE_CALL}>
                        <CandidateCall />
                    </PrivateRoute>

                    <PrivateRoute exact path={ROUTES.USERS}>
                        <Users />
                    </PrivateRoute>

                    <PrivateRoute path={ROUTES.JOBS}>
                        <Jobs />
                    </PrivateRoute>
                    <PrivateRoute path={ROUTES.CAREER}>
                        <Career />
                    </PrivateRoute>
                    <PrivateRoute path={ROUTES.STAFFS}>
                        <Staffs />
                    </PrivateRoute>
                    <PrivateRoute path={ROUTES.MEMBERSHIPS}>
                        <Memberships />
                    </PrivateRoute>
                    <PrivateRoute path={ROUTES.BLOGS}>
                        <Blogs />
                    </PrivateRoute>

                    {/* <PrivateRoute exact path={ROUTES.MESSAGES_CANDIDATES}> */}
                    {/* <ChatCandidates /> */}
                    {/* </PrivateRoute> */}
                    {/* <PrivateRoute exact path={ROUTES.MESSAGES_GENERAL}> */}
                    {/* <ChatInGeneral /> */}
                    {/* </PrivateRoute> */}

                    <PrivateRoute path={ROUTES.CHANGE_PASSWORD}>
                        <Account />
                    </PrivateRoute>
                    <PrivateRoute path={ROUTES.MANAGE_AUTO_DESCRIPTION} exact>
                        <ManageAutoDescription />
                    </PrivateRoute>

                    {/* e-learning */}
                    <PrivateRoute exact path={ROUTES.ELEARNING}>
                        <ELearningPage />
                    </PrivateRoute>
                    <PrivateRoute path={ROUTES.ELEARNING_JAPANESE_CLASS}>
                        <JapaneseClass />
                    </PrivateRoute>
                    <PrivateRoute path={ROUTES.ELEARNING_CONVERSATION}>
                        <Conversation />
                    </PrivateRoute>
                    <PrivateRoute path={ROUTES.ELEARNING_14SKILLS}>
                        <Skills />
                    </PrivateRoute>
                    <PrivateRoute path={ROUTES.ELEARNING_EXAM}>
                        <Exam />
                    </PrivateRoute>
                    <PrivateRoute path={ROUTES.ELEARNING_CULTURE}>
                        <Culture />
                    </PrivateRoute>
                    {/* <PrivateRoute exact path={ROUTES.ELEARNING_PRE_INTERVIEW}>
                        <PreInterview />
                    </PrivateRoute> */}
                    <PrivateRoute path={ROUTES.ELEARNING_JLPT}>
                        <JLPTPage />
                    </PrivateRoute>

                    {/* tools */}
                    <PrivateRoute path={ROUTES.ELEARNING_NEWS_MANAGEMENT}>
                        <NewsManagementPage />
                    </PrivateRoute>
                    <PrivateRoute path={ROUTES.ELEARNING_FURIGANA_GENERATOR}>
                        <FuriganaGeneratorPage />
                    </PrivateRoute>
                    <PrivateRoute path={ROUTES.ELEARNING_MARKDOWN_SAMPLE}>
                        <MarkdownSampleCreator />
                    </PrivateRoute>

                    <Route path="*">
                        <NotFound />
                    </Route>
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;
