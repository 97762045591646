import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { message, Row, Spin, Table, Popconfirm, Button, Space, Col, Select } from 'antd';

//components
import ModalUpsert from './ModalUpsert';
import { Container, LodoshaButton } from '../../components/UI/ui.style';
import { deleteAutoDescription, getAutoDescriptionsByType } from '../../utils/api/auto_description';
import { autoDescriptionTypeToText } from '../../utils/data';
// import SelectOptionItem from './SelectOptionItem';

export default function Index() {
    const { Option } = Select;
    const [type, setType] = useState(1);
    const [autoDescriptions, setAutoDescriptions] = useState([]);
    const [autoDescription, setAutoDescription] = useState({});
    const [visibleInsert, setVisibleInsert] = useState(false);
    const [visibleUpdate, setVisibleUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(1);

    const handleFetchAutoDescriptions = useCallback(async (type) => {
        setLoading(true);
        try {
            const response = await getAutoDescriptionsByType(type);
            if (response) {
                if (response.errorCode === 0) {
                    setAutoDescriptions(response.data);
                } else {
                    message.error(`Something went wrong: ${response?.message}`);
                }
            } else {
                message.error('Response is undefined');
            }
            setLoading(false);
        } catch (error) {
            message.error(error?.message);
            setLoading(false);
        }
    }, []);

    const handleEdit = (data) => {
        setAutoDescription(data);
        setVisibleUpdate(true);
    };

    const handleDelete = async (id) => {
        setLoading(true);
        try {
            const result = await deleteAutoDescription(id);
            if (result) {
                if (result.errorCode === 0) {
                    message.info(`Deleted Successfully`);
                    setAutoDescriptions((prev) => prev.filter((item) => item.id !== id));
                } else {
                    message.error(`Something went wrong: ${result?.message}`);
                }
            } else {
                message.error('Response is undefined');
            }
            setLoading(false);
        } catch (error) {
            message.error(error?.message);
            setLoading(false);
        }
    };

    const handleChangeDescriptionType = (value, option) => {
        setType(value);
    };

    const dataSource = useMemo(
        () => autoDescriptions && autoDescriptions.map((sO, index) => ({ ...sO, key: index, index: index + 1 })),
        [autoDescriptions]
    );

    const columns = [
        {
            width: '5%',
            title: '',
            dataIndex: 'index',
            key: 'index',
            align: 'center',
        },
        {
            title: 'Name EN',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Name KH',
            dataIndex: 'name_kh',
            key: 'name_kh',
        },
        {
            title: 'Name JP',
            dataIndex: 'name_jp',
            key: 'name_jp',
        },
        {
            width: '10%',
            align: 'center',
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record, index) => (
                <Space align="center" size="small">
                    <Button
                        style={{ borderRadius: '5px' }}
                        icon={<EditOutlined />}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(record);
                        }}
                    />
                    <Popconfirm title="Are you sure?" onConfirm={() => handleDelete(record.id)}>
                        <Button
                            style={{ borderRadius: '5px' }}
                            icon={<DeleteOutlined />}
                            type="danger"
                            loading={loading}
                        />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const Upsert = useCallback(
        () =>
            visibleInsert && (
                <ModalUpsert
                    isCreate
                    autoDescription={autoDescription}
                    setAutoDescription={setAutoDescription}
                    setVisible={setVisibleInsert}
                    visible={visibleInsert}
                    order={order}
                    type={type}
                    setAutoDescriptions={setAutoDescriptions}
                />
            ),
        [autoDescription, visibleInsert, order, type]
    );

    const Update = useCallback(
        () =>
            visibleUpdate && (
                <ModalUpsert
                    autoDescription={autoDescription}
                    setAutoDescription={setAutoDescription}
                    visible={visibleUpdate}
                    setVisible={setVisibleUpdate}
                    setAutoDescriptions={setAutoDescriptions}
                />
            ),
        [autoDescription, visibleUpdate]
    );

    useEffect(() => {
        handleFetchAutoDescriptions(type);
    }, [handleFetchAutoDescriptions, type]);

    useEffect(() => {
        const lastOrder = autoDescriptions.length > 0 ? autoDescriptions[autoDescriptions.length - 1].order + 1 : 1;
        setOrder(lastOrder);
    }, [autoDescriptions, visibleInsert]);

    return (
        <Container>
            <Upsert />
            <Update />
            <Row style={{ marginBottom: 32 }}>
                <Col style={{ marginRight: '1rem' }}>
                    <LodoshaButton type="primary" icon={<PlusOutlined />} onClick={() => setVisibleInsert(true)}>
                        New
                    </LodoshaButton>
                </Col>
                <Col span={8}>
                    <Select
                        onChange={(value, option) => handleChangeDescriptionType(value, option)}
                        defaultValue={1}
                        size="large"
                        style={{ width: '100%' }}
                    >
                        {autoDescriptionTypeToText.map((item, index) => (
                            <Option value={item.type} key={index}>
                                {item.name}
                            </Option>
                        ))}
                    </Select>
                </Col>
            </Row>
            <Spin spinning={loading}>
                <Table dataSource={dataSource} columns={columns} bordered pagination={false} />
            </Spin>
        </Container>
    );
}
