import React, { useState, useMemo } from 'react';
import { LodoshaInput, LodoshaButton } from '../../../components/UI/ui.style';

//api
import { updateChapter, postNewChapter } from '../../../utils/api/E-Learning/skills';

//ant design
import { Modal, Row, Col, Form, message, Select } from 'antd';

//constants
import { skillTypes, menuToText } from '../../../utils/data';

//components
import BannerUploader from '../E-LearningComponents/BannerUploader';

export default function ChapterModal({
    title,
    visible,
    setVisible,
    onRefresh,
    setPostData,
    postData,
    isUpdate,
    isCreate,
}) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(postData);
    const [file, setFile] = useState();

    const { Option } = Select;

    const chapterFormFields = useMemo(
        () => [
            {
                type: 'text',
                label: 'Name',
                name: 'name',
                value: data?.name,
                required: true,
                span: 12,
            },
            {
                type: 'text',
                label: 'Menu',
                name: 'menu',
                value: data.menuName ? data.menuName : menuToText[data?.menu - 1],
                disabled: true,
                span: 12,
            },
            {
                type: 'text',
                label: 'Type',
                name: 'type',
                value: data?.type,
                span: 24,
                optionList: skillTypes,
                isSelect: true,
                required: true,
            },
        ],
        [data]
    );

    const handleCancel = (e) => {
        setVisible(false);
        setPostData({});
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        setData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleChangeSkillType = (value) => {
        setData((prev) => ({ ...prev, type: value }));
    };

    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            for (const key in data) {
                formData.append(key, data[key]);
            }
            formData.append('chapter-image', file);

            const response = isCreate
                ? await postNewChapter(data.menu, formData)
                : isUpdate
                ? await updateChapter(data.id, formData)
                : {};
            if (response && response.errorCode !== 0) {
                setLoading(false);
                message.error(`Oh oh, something went wrong. ${response.message}`);
                return;
            }
            if (response) {
                message.success(isCreate ? 'New chapter added.' : isUpdate ? 'Successfully updated chapter' : '');
                setLoading(false);
                onRefresh();
                handleCancel();
            } else {
                message.error(`Uh oh, ${isCreate ? 'add new chapter' : isUpdate ? 'updated chapter' : ''} failed!`);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            message.error(`Uh oh, ${isCreate ? 'add new chapter' : isUpdate ? 'updated chapter' : ''} failed!`);
        }
    };

    return (
        <Modal
            width={768}
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
                    <div>{title}</div>
                    <BannerUploader imageId={data && data.image_id} setFile={setFile} />
                </div>
            }
            visible={visible}
            onCancel={handleCancel}
            maskClosable={false}
            footer={null}
        >
            <Form layout="vertical" form={form} onFinish={() => handleSubmit()}>
                <Row gutter={24}>
                    {chapterFormFields?.map((field, index) => {
                        const { type, label, name, value, required, disabled, span, isSelect, optionList } = field;
                        return (
                            <Col span={span} key={index}>
                                <Form.Item
                                    rules={[{ required: required, message: 'Data required!' }]}
                                    label={`${label}:`}
                                    name={name}
                                    initialValue={value}
                                >
                                    {isSelect ? (
                                        <Select
                                            placeholder="Please select: "
                                            name={name}
                                            size="large"
                                            disabled={disabled}
                                            onChange={handleChangeSkillType}
                                        >
                                            {optionList?.map((option, index) => (
                                                <Option value={option.value} key={index}>
                                                    {option.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    ) : (
                                        <LodoshaInput
                                            type={type}
                                            disabled={disabled && true}
                                            onChange={handleChange}
                                            value={value}
                                            name={name}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        );
                    })}
                    <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
                        <LodoshaButton
                            key="cancel"
                            style={{ marginRight: 16 }}
                            htmlType="button"
                            onClick={handleCancel}
                        >
                            Cancel
                        </LodoshaButton>
                        <LodoshaButton key="submit" loading={loading} type="primary" htmlType="submit">
                            {isCreate ? 'Add New' : isUpdate ? 'Update' : ''}
                        </LodoshaButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
