import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PageHeader } from 'antd';

import { getBreadcrumbName } from '../../../utils/index';

export default function Breadcrumb({ menuid }) {
    const { pathname } = useLocation();

    const pathSnippets = pathname?.split('/').filter((i) => i);

    const breadcrumbNameMap = {
        //e-learning menu
        '/e-learning/1': 'Japanese Class',
        '/e-learning/2': '14 Skills',
        '/e-learning/3': 'Conversation',
        '/e-learning/4': 'Exam',
        '/e-learning/5': 'Culture',
        '/e-learning/6': 'Pre-Interview',
        '/e-learning/7': 'JLPT Level',
        //other feature
        '/e-learning/news-management': 'Notifications',
        '/e-learning/furigana-generator': 'Furigana Generator',
    };

    const routes = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        return {
            path: url,
            breadcrumbName: getBreadcrumbName(url, menuid, breadcrumbNameMap),
        };
    });
    const itemRender = (route, params, routes, paths) => {
        const last = routes.indexOf(route) === routes.length - 1;
        return last ? (
            <Link to={route.path} style={{ color: '#182088' }}>
                {route.breadcrumbName}
            </Link>
        ) : (
            <Link to={route.path}>{route.breadcrumbName}</Link>
        );
    };

    return (
        <PageHeader
            breadcrumb={{ itemRender, routes: routes.slice(1, routes.length) }}
            style={{
                margin: '16px 0',
                borderRadius: 10,
                boxShadow: '#eee 2px 2px 1px 1px',
                background: '#fff',
            }}
        />
    );
}
