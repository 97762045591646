import React, { useState } from 'react';
import { Modal, Row, Col, Button, Input } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const ModalGenarateLike = ({ visible, setVisible, partner }) => {
    const [schoolId, setSchoolid] = useState();

    const handleCancel = () => {
        setVisible(false);
    };

    const handleRandom = () => {
        setSchoolid(`${process.env.BASE_URL_WEBSITE}student-register/${partner.id}`);
    };

    const copyUrl = () => {
        navigator.clipboard.writeText(schoolId);
    };

    return (
        <Modal maskClosable={true} width={820} visible={visible} onCancel={handleCancel} footer="">
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={24}>
                            <h1 style={{ textAlign: 'center', Color: '#1c1c1c', fontSize: '32px',fontWeight: 'bold' }}>Get Link</h1>
                        </Col>
                        <Col span={24}>
                            <Input
                                style={{ width: '100%', border: '1px solid #d3d3d3', height: '40px' }}
                                placeholder="Url students register"
                                value={schoolId}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={24} style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                    <Button type="primary" className="btn-random" style={{ height: '40px' }} onClick={handleRandom}>
                        Random Link
                    </Button>
                    <Button
                        className="btn-random"
                        title="Copy link"
                        style={{
                            border: 'none',
                            background: 'none',
                            height: '40px',
                            fontSize: '26px',
                            padding: '0px',
                            marginLeft: '10px',
                        }}
                        onClick={copyUrl}
                    >
                        <CopyOutlined />
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};

export default ModalGenarateLike;
