import React, { useContext } from "react";
import Search from "../../components/Search";
import AppContext from '../../contexts/AppContext';

const PostJobsSearch = ({ keyword, jobId, onSearch, onFilter }) => {

  const { dataJobs } = useContext(AppContext);

  const items = [
    { label: "Search:", type: "text", value: keyword, onSearch },
    { label: "Job:", type: "select", name: 'job', value: jobId, options: dataJobs, onSelect: (value) => onFilter("job", value) },
  ];

  return <Search items={items} />;
};

export default PostJobsSearch;
